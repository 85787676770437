import {useMemo, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './anomaly-game-panel.module.scss';
import classNames from 'classnames';
import {
  getAnomalyGameNetworkRequest,
  addLabelAnomalyGameNetworkRequest,
} from '../../../../http/anomaly-game.network-requests';
import {AnalysisFileIcon, FancyHeader, ModalLayout} from 'ui-components';
import {composition, HttpClientContext} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {LineChart, Button, useRemoteSourceStated} from 'ui-components';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricValueType} from '../../../../objects/models/metric.model';
import TransKeys from 'translations';

interface OwnProps {
  onClose?: () => void;
}

type AllProps = OwnProps;

const AnomalyGamePanelComponent = (props: AllProps) => {
  const {onClose} = props;
  const http = useContext(HttpClientContext);
  const {t} = useTranslation();

  const {
    source: anomaly,
    exec: getRandomAnomaly,
    isLoading: isLoadingRandomAnomaly,
  } = useRemoteSourceStated({
    networkRequest: getAnomalyGameNetworkRequest,
  });

  useEffect(() => {
    getRandomAnomaly();
  }, [getRandomAnomaly]);

  const handleOnClick = useCallback(
    async (e, anomaly, score) => {
      e.stopPropagation();
      if (score !== undefined && !!anomaly) {
        const data = {
          modelSampleSeriesId: anomaly.id,
          modelSampleId: anomaly.modelSampleId,
          signalId: anomaly.signal.id,
          score,
          datetime: anomaly.datetime,
        };
        await http.exec(addLabelAnomalyGameNetworkRequest(data));
      }

      getRandomAnomaly();
    },
    [getRandomAnomaly, http]
  );

  const granularityParser = granularity => {
    return t(TransKeys.GENERAL.LABELS.GRANULARITY[granularity.toUpperCase()]);
  };

  const chartProps: any = useMemo(() => {
    if (!anomaly) {
      return;
    }

    const samples_data = anomaly.samples.map((s, i) => ({
      x: s.datetime,
      y: anomaly.valueType === MetricValueType.PERCENTAGE ? 100 * s.value : s.value,
      isTrend: i === anomaly.samples.length - 1,
    }));

    const datasets: any[] = [
      {
        id: 'samples',
        label: t(TransKeys.MODELS[anomaly.modelType.toUpperCase()]),
        data: samples_data,
      },
    ];
    if (anomaly.hasDenominator) {
      const denominator_data = anomaly.samples.map(s => ({
        x: s.datetime,
        y: s.denominator,
      }));
      datasets.push({
        id: 'denominators',
        label: anomaly.entity,
        data: denominator_data,
        yAxis: 'secondary',
      });
    }

    return {
      key: 'anomaly_model_series_' + anomaly.id,
      datasets,
      displayedDatasetIds: ['samples', 'goal'],
      options: {
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: anomaly.granularity,
        },
        yLabelSuffix: anomaly.valueType === MetricValueType.PERCENTAGE ? '%' : undefined,
        secondaryYSuffix: undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        errorBar: true,
        xLabel: 'date', // xlabel
        colors: ['#B8ADF9', '#9AB9F3'],
      },
    };
  }, [anomaly, t]);

  return (
    <div className={classes.AnomalyRunFormContainer}>
      <ModalLayout>
        <FancyHeader title={'Anomaly Game'} icon={AnalysisFileIcon} onClose={onClose} />
        <div className={classes.AnomalyRunForm}>
          {isLoadingRandomAnomaly && <GenericLoading />}
          {anomaly && (
            <div style={{width: '100%'}}>
              <p style={{marginBottom: 0}}>Rules:</p>
              <ul style={{marginTop: 0}}>
                <li>
                  Anomaly is a <b>spike (jump) or a begining of new level-shift</b>, not a long-term
                  trend
                </li>
                <li>
                  Anomaly can be <span style={{color: 'green'}}>raise</span> or{' '}
                  <span style={{color: 'red'}}>decline</span>
                </li>
                <li>Don't over-think, judge only the last point (wrapped with the purple box)</li>
              </ul>
              <h2>
                {isLoadingRandomAnomaly
                  ? 'Loading Next ...'
                  : anomaly.signal.name + ' (' + granularityParser(anomaly.granularity) + ')'}
              </h2>
              <div className={classes.LineChartContainer}>
                <LineChart {...chartProps} />
              </div>
              <div className={classes.Actions}>
                <Button className={classes.Button} onClick={e => handleOnClick(e, anomaly, -1)}>
                  👍&nbsp;&nbsp;&nbsp;Not Anomaly
                </Button>
                <Button
                  className={classNames(classes.Button, classes.Skip)}
                  onClick={e => handleOnClick(e, anomaly, 0)}
                >
                  😶&nbsp;&nbsp;&nbsp;Not Sure
                </Button>
                <Button className={classes.Button} onClick={e => handleOnClick(e, anomaly, 1)}>
                  👎&nbsp;&nbsp;&nbsp;Anomaly
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalLayout>
    </div>
  );
};

const AnomalyGamePanel = composition<AllProps>(
  AnomalyGamePanelComponent,
  withModalInactiveSourceHandler
);

export {AnomalyGamePanel};

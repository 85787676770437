import classNames from 'classnames';
import classes from './system-text.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {FC} from 'react';

interface OwnProps {
  className?: string;
  children: any;
}

type AllProps = OwnProps;

export const SystemText = (props: AllProps) => {
  const {children, className} = props;

  return <span className={classNames(classes.SystemText, className)}>{children}</span>;
};

export const MissingData: FC<{}> = (props: {}) => {
  const {t} = useTranslation();
  return <SystemText>{t(TransKeys.GENERAL.LABELS.MISSING_DATA)}</SystemText>;
};

export enum DocumentElementType {
  SMART_TABLE = 'SmartTableFigure',
  IMAGE = 'ImageFigure',
  TIP_BOX = 'TipBoxFigure',
  INFO_BOX_FIGURE = 'InfoBoxFigure',
  RCA_HEADER = 'RCAHeaderFigure',
  RCA_COMPARED_HEADER = 'RCAComparedHeaderFigure',
  MARKDOWN = 'MarkdownFigure',
  SENTENCE_FIGURE = 'SentenceFigure',
  GRID_LAYOUT = 'GridLayout',
  LOADING_STATE_FIGURE = 'LoadingStateFigure',
  // DEPRECATED - use RELEASE_IMPACT_STEPS_FIGURE instead - not supported in Spotnix
  STEPS_BLOCK = 'StepsBlock',
  RELEASE_IMPACT_STEPS_FIGURE = 'ReleaseImpactStepsFigure',
  ERROR = 'ErrorFigure',
  TIME_TO_EVENT = 'TimeToEventFigure',
  RETENTION_TABLE_FIGURE = 'RetentionTableFigure',
  BLOCK = 'Block',
  CONTENT_WRAPPER = 'ContentWrapper',
  SECTION = 'Section',
  TITLE_SECTION = 'TitleSection',
  DOCUMENT = 'Document',
  CHART = 'ChartFigure',
  SELECT_BLOCK = 'SelectBlock',
  TOP_LEVEL_SELECT_BLOCK = 'TopLevelSelectBlock',
  SWITCH_BLOCK = 'SwitchBlock',
  PHENOMENAS_FIGURE = 'PhenomenasFigure',
  DATA_VALIDATION = 'DataValidationFigure',
  RCA_TRENDS = 'RCATrendsFigure',
  FUNNEL_OVERVIEW_FIGURE = 'FunnelOverviewFigure',
  KPI_SEGMENTATION_FIGURE = 'KPISegmentationFigure',
  FUNNEL_SEGMENTATION_FIGURE = 'FunnelSegmentationFigure',
  RETENTION_OVERVIEW_FIGURE = 'RetentionOverviewFigure',
  RETENTION_SEGMENTATION_FIGURE = 'RetentionSegmentationFigure',
  ANALYSIS_FIGURE = 'AnalysisFigure',
  HABIT_MOMENT_FIGURE = 'HabitMomentFigure',
  OPTIONS_PLACER = 'OptionsPlacer',
  HEATMAP = 'HeatmapFigure',
  CHECKLIST = 'CheckListFigure',
  COMMAND_BUTTONS = 'CommandButtonsFigure',
  POWER_USERS_OVERVIEW_FIGURE = 'PowerUsersOverviewFigure',
  POWER_USERS_SEGMENTATION_FIGURE = 'PowerUsersSegmentationFigure',
  CONTENT_FUNNEL_OVERVIEW_FIGURE = 'ContentFunnelOverviewFigure',
  RCA_FIGURE = 'RCAFigure',
  RCA_FUNNEL_FIGURE = 'RCAFunnelFigure',
  RCA_SEGMENTATION_FIGURE = 'RCASegmentationFigure',
  RCA_POPULATION_EXPLAINER_FIGURE = 'RCAPopulationExplainerFigure',
  RCA_SEASONALITY_EXPLAINER_FIGURE = 'RCASeasonalityExplainerFigure',
  RCA_EXPERIMENT_EXPLAINER_FIGURE = 'RCAExperimentExplainerFigure',
  RCA_ENTITY_LEVEL_EXPLAINER_FIGURE = 'RCAEntityLevelExplainerFigure',
  RCA_OVERVIEW_FIGURE = 'RCAOverviewFigure',
  RCA_KPI_TREE_EXPLAINER_FIGURE = 'RCAKPITreeExplainerFigure',
  KPI_OVERVIEW_FIGURE = 'KPIOverviewFigure',
  RELEASE_IMPACT_SEGMENTATION_FIGURE = 'ReleaseImpactSegmentationFigure',

  // CompositeSegmentsFigure variations
  COMPOSITE_SEGMENTS_FIGURE = 'CompositeSegmentsFigure',
  FUNNEL_COMPOSITE_SEGMENTS_FIGURE = 'FunnelCompositeSegmentsFigure',
  TREND_COMPOSITE_SEGMENTS_FIGURE = 'TrendCompositeSegmentsFigure',
  RCA_COMPOSITE_SEGMENTS_FIGURE = 'RCACompositeSegmentsFigure',

  // TreatmentAdoptionSimulationFigure variations
  TREATMENT_ADOPTION_SIMULATION_FIGURE = 'TreatmentAdoptionSimulationFigure',
  GOAL_DRIVERS_SIMULATION_FIGURE = 'GoalDriversSimulationFigure',
  JOURNEY_DRIVERS_SIMULATION_FIGURE = 'JourneyDriversSimulationFigure',
  CORRELATION_DRIVERS_SIMULATION_FIGURE = 'CorrelationDriversSimulationFigure',
  ENGAGEMENT_DRIVERS_SIMULATION_FIGURE = 'EngagementDriversSimulationFigure',
  FEATURE_IMPACT_FIGURE = 'FeatureImpactFigure',
  TREATMENT_ADOPTION_SIMULATION_MODE_SWITCH_WRAPPER_FIGURE = 'TreatmentAdoptionSimulationModeSwitchWrapperFigure',

  // Experiments
  EXPERIMENT_CONTEXT_FIGURE = 'ExperimentContextFigure',
  EXPERIMENT_CONCLUSION_FIGURE = 'ExperimentConclusionFigure',

  // Experiments - AB Test
  AB_TEST_CONTEXT_WRAPPER_FIGURE = 'ABTestContextWrapperFigure', // DEPRECATED - use EXPERIMENT_CONTEXT_FIGURE instead
  AB_TEST_CONCLUSION_FIGURE = 'ABTestConclusionFigure', // DEPRECATED - use EXPERIMENT_CONCLUSION_FIGURE instead
  AB_TEST_SUMMARY_FIGURE = 'ABTestSummaryFigure',

  // Experiments - Release Impact
  RELEASE_IMPACT_SUMMARY_FIGURE = 'ReleaseImpactSummaryFigure',
  RELEASE_IMPACT_CALCULATION_BREAKDOWN_FIGURE = 'ReleaseImpactCalculationBreakdownFigure',
  RELEASE_IMPACT_FEATURES_SUMMARY_FIGURE = 'ReleaseImpactFeaturesSummaryFigure',
  RELEASE_IMPACT_FEATURE_EFFECT_ON_METRIC_FIGURE = 'ReleaseImpactFeatureEffectOnMetricFigure',

  _WINDOW_BLOCK = '_WINDOW_BLOCK', // for internal use only
  // External figures
  _FEEDBACK = '_FEEDBACK', // for internal use only
  _EXECUTIVE_SUMMARY_FIGURE = '_EXECUTIVE_SUMMARY_FIGURE', // for internal use only
  _EXECUTIVE_SUMMARY_FIGURE_V2 = '_EXECUTIVE_SUMMARY_FIGURE_V2', // for internal use only
  _KPI_TREE_FIGURE = '_KPI_TREE_FIGURE', // for internal use only
  _METRIC_SUGGESTIONS_FIGURE = '_METRIC_SUGGESTIONS_FIGURE', // for internal use only

  // Remote figures
  REMOTE_RCA_KPI_TREE_EXPLAINER_FIGURE = 'RemoteRCAKPITreeExplainerFigure',
}

export interface DocumentElement<D = void, O = void, C = void> {
  id?: string;
  version?: number;
  type: DocumentElementType;
  debug?: any;
  data?: D;
  options?: O;
  children?: C[];
  isHidden?: boolean;
  [other: string]: any;
}

export interface SmartDocumentOptions {
  showTableOfContent?: boolean;
  tableOfContentTitle?: string;
}

export interface SmartDocument
  extends DocumentElement<void, SmartDocumentOptions, DocumentElement> {
  type: DocumentElementType.DOCUMENT;
  version: 1;
  title?: string;
  documents?: SmartDocument[];
  globalParameters?: any;
}

import {SmartSelectorPanel, IconButton, InfoIcon, SmartSelectorOption} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from '../../smart-selector.module.scss';
import classNames from 'classnames';
import InvalidSignalWarning from '../../../../../dmp/components/invalid-signal-warning/invalid-signal-warning.component';
import {ValidationStatus} from '../../../../../../objects/models/signal.model';

interface OwnProps {
  label?: string;
  data: any;
  onSignalInfo: (signalId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

export const FunnelPreview = (props: AllProps) => {
  const {label, data, onSignalInfo, className} = props;
  const {t} = useTranslation();

  return (
    <SmartSelectorPanel
      className={className}
      label={label}
      title={data.name}
      description={data.description}
    >
      {data.steps.map((s, i) => (
        <SmartSelectorOption
          className={classNames(s.disabled && classes.DisabledOption)}
          key={s.id}
          label={t(TransKeys.GENERAL.LABELS.STEP_COUNT, {count: i + 1})}
          title={
            <div className={classes.WithInfo}>
              <span className={classes.Title}>{s.name}</span>
              <IconButton
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHOW_DEFINITION)}
                size={'small'}
                onClick={() => onSignalInfo(s.id)}
                icon={InfoIcon}
              />
              {s.signalValidationStatus === ValidationStatus.ERROR && (
                <InvalidSignalWarning
                  title={t(TransKeys.FUNNELS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
                />
              )}
            </div>
          }
          description={s.description}
        />
      ))}
    </SmartSelectorPanel>
  );
};

import classNames from 'classnames';
import classes from './explore-viewer.module.scss';
import {ExploreDataResponse} from '../../../../../../objects/models/exploration.model.ts';
import {useMemo} from 'react';
import {TIME_FORMATS} from '../../../../../../constants/time-formats.ts';
import {LineChart} from 'ui-components';
import {HomepageGranularityOptions} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/components/homepage-granularity-options/homepage-granularity-options.component.tsx';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model.ts';
import {HomepageTimeframeOptions} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/components/homepage-timeframe-options/homepage-timeframe-options.component.tsx';
import {UseModelSampleTimeframeResponse} from '../../../../../homepage/components/homepage-summary/hooks/homepage-summary.hooks.ts';
import {BookOpenCoverIcon} from 'ui-components/src';

interface Props {
  granularity: ModelSeriesGranularity;
  onChangeGranularity: (granularity: ModelSeriesGranularity) => void;
  exploreData: ExploreDataResponse;
  timeframeProps: UseModelSampleTimeframeResponse;
  disabled?: boolean;
  className?: string;
}

export const ExploreViewer = (props: Props) => {
  const {granularity, onChangeGranularity, exploreData, disabled, className} = props;
  const {items} = exploreData;

  const chartData = useMemo(() => {
    const datasets = items.map(item => {
      return {
        id: item.identifier,
        label: item.identifier,
        data: item.samples[0].samples.map(sample => ({
          x: sample.datetime,
          y: sample.value,
          upper: sample.upper,
          lower: sample.lower,
        })),
      };
    });

    return {
      datasets,
      options: {
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: items[0].granularity,
        },
        // yLabelSuffix: item.isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        xLabel: 'Date',
      },
    } as any;
  }, [items]);

  return (
    <div className={classNames(classes.ExploreViewer, className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>Untitled Question</div>
        <div className={classes.SQLViewerButton}>
          <BookOpenCoverIcon />
          <span>View SQL</span>
        </div>
        <div className={classes.Spacer} />
        <div className={classes.Actions}>actions</div>
      </div>
      <div className={classes.Options}>
        <HomepageGranularityOptions
          granularity={granularity || items[0].granularity}
          onChange={onChangeGranularity}
          options={items[0].availableGranularities}
          disabled={disabled}
        />
        <HomepageTimeframeOptions {...props.timeframeProps} disabled={disabled} />
      </div>
      <div className={classes.ChartWrapper}>
        <LineChart {...chartData} />
      </div>
    </div>
  );
};

import {useMemo} from 'react';
import {values} from 'lodash';
import {number2k} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {ModelPageHeader} from '../../../../modules/shared/components/layout/model-page-header/model-page-header.component';
import {FunnelPage} from '../../../../objects/models/funnel-page.model';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';

interface FunnelPageHeaderProps {
  funnel: FunnelPage;
  onViewDefinition: () => void;
}

export const FunnelPageHeader = (props: FunnelPageHeaderProps) => {
  const {funnel, onViewDefinition} = props;
  const {t} = useTranslation();
  const valueAvg = useMemo(() => {
    if (!funnel) {
      return;
    }

    let v = Math.random();
    if (ModelSeriesGranularity.WEEK in funnel.valueAvg) {
      v = funnel.valueAvg[ModelSeriesGranularity.WEEK];
    } else {
      v = values(funnel.valueAvg)[0];
    }
    return `${number2k(v * 100)}%`;
  }, [funnel]);

  return (
    <ModelPageHeader
      title={funnel.name}
      value={valueAvg}
      valueDescription={t(TransKeys.METRIC_PAGE.HEADER.VALUE_DESCRIPTION)}
      description={funnel.shortDescription}
      onViewDefinition={onViewDefinition}
      subtitleItems={[
        <span>
          {t(TransKeys.FUNNEL_PAGE.HEADER.BOUNDING_DAYS, {
            boundingDays: funnel.defaultBoundingDays,
          })}
        </span>,
      ]}
    />
  );
};

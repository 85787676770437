import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import {updateUserNotificationSettingsNetworkRequest} from '../../http/user-notification-config.network-request';
import {getProductData, modelUpdated} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import HttpClient from '../../services/http-client.service';
import {UserNotificationsConfigActionType} from './notifications-config.actions';
import {showToastMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {ToastType} from '../../objects/system/toast-type.enum';
import {removeSlackFromProductNetworkRequest} from '../../http/slack-channels.network-requests';

export const updateUserNotificationSettingEpic: Epic = createRequestEpic(
  {
    types: [UserNotificationsConfigActionType.UPDATE_USER_NOTIFICATIONS_CONFIG],
    actionKey: ActionKey.UPDATE_USER_NOTIFICATIONS_CONFIG,
    request: updateUserNotificationSettingsNetworkRequest,
    onSuccess: data => [
      modelUpdated(data, ModelKey.UPDATE_USER_NOTIFICATIONS_CONFIG),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.UPDATE_NOTIFICATIONS_CONFIG_SUCCESS),
        ToastType.SUCCESS
      ),
    ],
    onError: () => [
      showToastMessage(i18n.t(TransKeys.TOASTS.UPDATE_NOTIFICATIONS_CONFIG_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export const removeSlackFromProductEpic: Epic = createRequestEpic(
  {
    types: [UserNotificationsConfigActionType.REMOVE_SLACK_FROM_PRODUCT],
    actionKey: ActionKey.REMOVE_SLACK_FROM_PRODUCT,
    request: removeSlackFromProductNetworkRequest,
    onSuccess: data => [
      showToastMessage(i18n.t('Slack was successfully removed'), ToastType.SUCCESS),
      getProductData(),
    ],
    onError: () => [
      showToastMessage(i18n.t('Error while removing slack from product'), ToastType.ERROR),
    ],
  },
  HttpClient
);

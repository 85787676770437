import {createContext, useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import {
  FavoriteSegmentsMap,
  FavoriteSegmentsMapByTeam,
  GlobalDocumentData,
} from './global-document-data.types';
import {keyBy} from 'lodash';
import {exists} from 'front-core';

interface OwnProps extends GlobalDocumentData {
  children: any;
}

export interface IGlobalDocumentDataContext extends GlobalDocumentData {
  selectedFavoriteSegmentsMap: FavoriteSegmentsMap;
  favoriteSegmentsMapByTeam: FavoriteSegmentsMapByTeam;
}

export const GlobalDocumentDataContext = createContext<IGlobalDocumentDataContext>({
  analysisResultId: undefined,
  analysisId: undefined,
  analysisTypeId: undefined,
  analysisTypeName: '',
  analysisName: '',
  defaultTeamId: undefined,
  analysisRunParameters: {},
  analysisParameters: {},
  analysisResultStatus: undefined,
  favoriteSegments: {},
  selectedFavoriteSegmentsMap: {},
  favoriteSegmentsMapByTeam: {},
  teams: [],
  followUpAnalyses: [],
  isFollowUp: undefined,
  teamId: undefined,
  setTeamId: undefined,
  teamSelectorDisabled: false,
});

const DEFAULT_SEGMENT_PREFERENCES = {
  excludeCompositeSegments: false,
  excludeNonInformativeSegments: false,
};

export const GlobalDocumentDataContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    analysisResultId,
    analysisId,
    analysisTypeId,
    analysisTypeName,
    analysisName,
    analysisRunParameters,
    analysisParameters,
    analysisResultStatus,
    isFollowUp,
    followUpAnalyses,
    defaultTeamId = null,
    teams,
    setTeamId: setTeamIdFromProps,
    teamId: teamIdFromProps,
    favoriteSegments = {},
    segmentPreferences = DEFAULT_SEGMENT_PREFERENCES,
    teamSelectorDisabled,
    children,
  } = props;
  const [teamId_, setTeamId_] = useState<number>(teamIdFromProps);
  const favoriteSegmentsMapByTeam = useMemo(() => {
    const res = {};
    for (const fs in favoriteSegments) {
      res[fs] = keyBy(favoriteSegments[fs], 'signalId');
    }
    return res;
  }, [favoriteSegments]);

  const teamId = useMemo(() => teamIdFromProps || teamId_, [teamIdFromProps, teamId_]);
  const selectedFavoriteSegmentsMap = useMemo(
    () => favoriteSegmentsMapByTeam[teamId] || {},
    [favoriteSegmentsMapByTeam, teamId]
  );

  const setTeamId = useCallback(
    (tId: number) => {
      if (setTeamIdFromProps) {
        setTeamIdFromProps(tId);
      }
      setTeamId_(tId);
    },
    [setTeamIdFromProps, setTeamId_]
  );

  const contextValue = useMemo(
    () => ({
      analysisResultId,
      analysisId,
      analysisTypeId,
      analysisTypeName,
      analysisName,
      analysisRunParameters,
      analysisParameters,
      analysisResultStatus,
      favoriteSegments,
      selectedFavoriteSegmentsMap,
      favoriteSegmentsMapByTeam,
      defaultTeamId,
      followUpAnalyses,
      isFollowUp,
      teams,
      teamId: !exists(teamId) ? null : teamId,
      setTeamId,
      segmentPreferences,
      teamSelectorDisabled,
    }),
    [
      analysisResultId,
      analysisId,
      analysisTypeId,
      analysisTypeName,
      analysisName,
      analysisRunParameters,
      analysisParameters,
      analysisResultStatus,
      favoriteSegments,
      selectedFavoriteSegmentsMap,
      favoriteSegmentsMapByTeam,
      defaultTeamId,
      followUpAnalyses,
      isFollowUp,
      teams,
      teamId,
      setTeamId,
      segmentPreferences,
      teamSelectorDisabled,
    ]
  );

  return (
    <GlobalDocumentDataContext.Provider value={contextValue}>
      {children}
    </GlobalDocumentDataContext.Provider>
  );
};

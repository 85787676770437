import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AnalysisFileIcon} from 'ui-components';
import {ActionKey} from '../../../../constants/action-key';
import TransKeys from 'translations';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {
  createScheduleAnalysis,
  deleteScheduleAnalysis,
  editScheduleAnalysis,
} from '../../../../store/analyses/analyses.actions';
import {scheduleAnalysisDTOValidator} from '../../../../objects/dto/schedule-analysis.dto';
import {CRON_DEFAULT_VALUES} from '../../../../constants/cron-exp.consts';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../objects/models/analysis-result.model';
import classes from './schdule-analysis-panel.module.scss';
import CronPanelFormComponent from '../../../shared/components/general/cron-panel-form/cron-panel-form.component';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  analysisResult?: AnalysisResult;
  onClose: () => void;
  panelId?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.ANALYSIS_FORM__ANALYSIS_RESULT;

const ScheduleAnalysisPanelComponent = (props: AllProps) => {
  const {analysisResult, onClose, panelId, disabled} = props;
  const {t} = useTranslation();

  const defaultValues = useMemo(
    () => ({
      cronExp: analysisResult?.cronExp || CRON_DEFAULT_VALUES.WEEKLY,
      analysisId: analysisResult?.analysisId,
      analysisResultId: analysisResult?.id,
    }),
    [analysisResult]
  );

  const editMode = useMemo(() => Boolean(analysisResult?.cronExp), [analysisResult?.cronExp]);

  const actionHandlers = useMemo(
    () => ({
      edit: editScheduleAnalysis,
      create: createScheduleAnalysis,
      delete: deleteScheduleAnalysis,
    }),
    []
  );

  const onDeleteDataParam = useMemo(
    () => ({
      analysisId: analysisResult.analysisId,
      analysisResultId: analysisResult.id,
    }),
    [analysisResult]
  );

  const texts = useMemo(
    () => ({
      title: t(TransKeys.SCHEDULE_ANALYSIS[editMode ? 'EDIT_TITLE' : 'CREATE_TITLE']),
      subTitleCta: t(
        TransKeys.SCHEDULE_ANALYSIS[editMode ? 'EDIT_SUBTITLE_CTA' : 'CREATE_SUBTITLE_CTA']
      ),
      deleteBtn: t(TransKeys.GENERAL.ACTIONS.DELETE),
      submitBtn: t(TransKeys.GENERAL.ACTIONS[editMode ? 'EDIT_SCHEDULE' : 'SCHEDULE']),
      modalBody: t(TransKeys.SCHEDULE_ANALYSIS.NON_COMPLETED_MESSAGE),
    }),
    [t, editMode]
  );

  const isCompleted = useMemo(
    () => analysisResult.status === AnalysisResultStatus.COMPLETED,
    [analysisResult]
  );

  const formHiddenInputsNames = useMemo(() => ['analysisId', 'analysisResultId'], []);

  return (
    <CronPanelFormComponent
      onClose={onClose}
      panelId={panelId}
      defaultValues={defaultValues}
      resolverSchema={scheduleAnalysisDTOValidator}
      loadingKeys={[
        ActionKey.CREATE_SCHEDULE_ANALYSIS,
        ActionKey.EDIT_SCHEDULE_ANALYSIS,
        ActionKey.DELETE_SCHEDULE_ANALYSIS,
      ]}
      editMode={editMode}
      actionsHandlers={actionHandlers}
      onDeleteDataParam={onDeleteDataParam}
      texts={texts}
      showModalFooter={isCompleted}
      showForm={isCompleted}
      formHiddenInputsNames={formHiddenInputsNames}
      icon={AnalysisFileIcon}
      showWeekly
      showDaily
      showMonthly
      additionalInfo={<div className={classes.Notice}>{t(TransKeys.SCHEDULE_ANALYSIS.NOTICE)}</div>}
      disabled={disabled}
    />
  );
};

export const ScheduleAnalysisPanel = composition<AllProps>(
  ScheduleAnalysisPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore<AllProps>({
    analysisResult: {
      selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
      actionKey: SELECTED_ANALYSIS_RESULT_KEY,
      request: getAnalysisResultNetworkRequest,
      mapPayloadFromProps: props => props[ANALYSIS_RESULT_ID_PATH_PARAM],
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
    },
  })
);

import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from './homepage-subscription-list-panel-empty-state.module.scss';
import {HomepageQuickSubscriptionForm} from '../../../../components/homepage-summary/components/homepage-quick-subscription-form/homepage-quick-subscription-form.component';

type HomepageSubscriptionListPanelEmptyStateProps = {
  title?: string;
  onOpenAdvancedOptions: VoidFunction;
};

export const HomepageSubscriptionListPanelEmptyState = (
  props: HomepageSubscriptionListPanelEmptyStateProps
) => {
  const {onOpenAdvancedOptions, title} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.HomepageSubscriptionListPanelEmptyState}>
      {title && <div className={classes.Message}>{title}</div>}
      <div className={classes.Content}>
        <div className={classes.Title}>
          {t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.EMTY_STATE.TITLE)}
        </div>
        <div className={classes.FormContainer}>
          <HomepageQuickSubscriptionForm />
        </div>
        <div className={classes.AddSubscriptionButton} onClick={onOpenAdvancedOptions}>
          {t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.EMTY_STATE.OPEN_ADVANCED_OPTIONS)}
        </div>
      </div>
    </div>
  );
};

import {QueryBuilderFactory, SqlElementType} from 'ui-components';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {get} from 'lodash';
import {exists} from 'front-core';

const extractFromTemplateItemQueryBuilder = element => {
  const type = get(element, 'type');
  if (type === SqlElementType.TABLE_COLUMN) {
    return get(element, 'table_id');
  } else if (type === SqlElementType.AND_CONDITION) {
    return get(element, 'conditions.0.left.table_id');
  }
};

export function extractTableIdFromSignalDefinition(signalDefinition) {
  // Check if the current object is of type "TableColumn" and has a table_id
  if (!exists(signalDefinition)) {
    return undefined;
  }
  const builderComponentName =
    signalDefinition[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];
  switch (builderComponentName) {
    case 'RevenueQueryBuilder':
      if (SqlElementType.CASES) {
        const candidate = get(signalDefinition, 'cases.0.0.conditions.0');
        return extractFromTemplateItemQueryBuilder(candidate);
      } else {
        return undefined;
      }
    case 'UsageQueryBuilder':
      return extractFromTemplateItemQueryBuilder(signalDefinition);
    case 'RateQueryBuilder':
      const nominator = get(signalDefinition, 'parameters.0.element');
      const denominator = get(signalDefinition, 'parameters.1.element');
      const nominatorTableId = extractFromTemplateItemQueryBuilder(nominator);
      const denominatorTableId = extractFromTemplateItemQueryBuilder(denominator);
      if (nominatorTableId === denominatorTableId) {
        return nominatorTableId;
      }
      return undefined;
    default:
      return undefined;
  }
}

interface ModelEventPropertyDefinition {
  column: string;
  table_id: number;
  type: SqlElementType.TABLE_COLUMN;
  [PARAMETERS_METADATA_KEY]?: any;
}

export const createModelEventPropertyModel = (
  property: ModelEventPropertyDefinition,
  id: number = null
) => {
  const definition = QueryBuilderFactory.createTableColumn();
  definition.table_id = property.table_id;
  definition.column = property.column;
  definition[PARAMETERS_METADATA_KEY] = property[PARAMETERS_METADATA_KEY] || {};

  return {
    definition,
    id,
  };
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {exists, number2k} from 'front-core';
import {ArrowNarrowUpIcon} from '../../../../../simple/controls/icons/icons.component';

interface OwnProps {
  value: number;
  isPercentage?: boolean;
  outOfValue?: number;
  className?: string;
}

type AllProps = OwnProps;

export const ImpactValueDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {value, outOfValue, className, isPercentage = false} = props;

  return (
    <div className={classNames(classes.ImpactPPDisplay, className)}>
      <ArrowNarrowUpIcon className={classNames(classes.ArrowIcon, value < 0 && classes.Negative)} />
      {number2k(value)}
      {isPercentage ? '%' : ''}
      {exists(outOfValue) ? (
        <span className={classNames(classes.Secondary)}>
          <span className={classes.VS}>/</span>
          <span>{number2k(outOfValue)}</span>
          {isPercentage ? '%' : ''}
        </span>
      ) : null}
    </div>
  );
};

import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {signalSmartSelectorNetworkRequest} from '../../../../http/smart-selector.network-requests';
import classes from './smart-selector.module.scss';
import {SignalPreview} from './components/previews/signal-preview.component';
import {getSignalNetworkRequest} from '../../../../http/signals.network-requests';
import {capitalize, isArray, isEmpty, isObject} from 'lodash';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {SignalSmartSelectorKey} from './signal-smart-selector.component';
import {TableColumnPreview} from './components/previews/table-column-preview.component';
import {TableEventPreview} from './components/previews/table-event-preview.component';
import {getTableEventNetworkRequest} from '../../../../http/table-events.network-requests';
import {getTableColumnNetworkRequest} from '../../../../http/table-columns.network-requests';
import {getMetricTypeName, getMilestoneTypeName} from '../../../../constants/options';
import {SmartSelectorSharedProps} from './smart-selector.shared';
import {useTeamFilter} from '../../../../core/contexts/team-filter.context';
import {TeamFilterHelper} from './components/helpers/team-filter-helper.component';
import {AnyEventPreview} from './components/previews/any-event-preview.component.tsx';
import {getTableNetworkRequest} from '../../../../http/tables.network-requests.ts';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

export enum SelectorModelType {
  SIGNAL = 'signal',
  EVENT = 'event',
  ANY_EVENT = 'any_event',
  COLUMN = 'column',
}

const GET_MODEL_NR_MAPPING = {
  [SelectorModelType.SIGNAL]: getSignalNetworkRequest,
  [SelectorModelType.EVENT]: getTableEventNetworkRequest,
  [SelectorModelType.ANY_EVENT]: getTableNetworkRequest,
  [SelectorModelType.COLUMN]: getTableColumnNetworkRequest,
};

interface OwnProps extends Omit<SmartSelectorSharedProps, 'onChange' | 'filters'> {
  valueType: SelectorModelType;
  allowTypes?: SelectorModelType[];
  onChange?: (value: number, valueType: SelectorModelType, item: any) => void;
  signalFilters?: any;
  signalsInclude?: SignalSmartSelectorKey[];
  columnFilters?: any;
  eventFilters?: any;
}

type AllProps = OwnProps;

const fixFilters = (filters: any) => {
  if (!filters) {
    return undefined;
  }
  if (isArray(filters) && filters.length) {
    return filters;
  } else if (isObject(filters) && !isEmpty(filters)) {
    return [filters];
  }
  return undefined;
};

export const AdvancedSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    valueType,
    signalFilters,
    columnFilters,
    eventFilters,
    onChange: onChangeFromProps,
    onSignalInfo,
    className,
    disabled,
    error,
    signalsInclude,
    allowTypes,
    clearable,
    fullWidth,
    emptyState,
    helperText,
  } = props;
  const {t} = useTranslation();
  const {
    teamId,
    teamName,
    ignore: ignoreTeamFilter,
    isActive: isActiveTeamFilter,
  } = useTeamFilter();
  const selectorRef = useRef<any>(null);
  const isLoadingGetModel = useRef(false);
  const http = useContext(HttpClientContext);
  const [selectedModel, setSelectedModel] = useState<any>(null);
  const labelValue = useMemo(() => {
    if (value && selectedModel) {
      return selectedModel.name;
    }
  }, [value, selectedModel]);
  const prefix = useMemo(() => {
    if (valueType === SelectorModelType.COLUMN) {
      return 'Property';
    }
    if (valueType === SelectorModelType.EVENT) {
      return 'Event';
    }
    if (valueType === SelectorModelType.ANY_EVENT) {
      return 'Any Event';
    }
    if (valueType === SelectorModelType.SIGNAL && selectedModel) {
      const relatedTo = selectedModel.relatedTo;
      if (!exists(relatedTo)) {
        return null;
      }
      const modelName = t(TransKeys.MODELS[relatedTo.type.toUpperCase()]);
      if (modelName === 'KPI') {
        return modelName;
      }
      if (selectedModel.relatedTo.type === 'funnel') {
        return 'Funnel Step';
      }
      return capitalize(modelName);
    }
  }, [valueType, selectedModel, t]);
  const onChange = useCallback(
    (value: number, valueType?: SelectorModelType, item?: any) => {
      selectorRef.current?.close();
      onChangeFromProps(value, valueType, item);
    },
    [onChangeFromProps]
  );
  const sources: SmartSelectorSource[] = useMemo(
    () =>
      [
        {
          key: SignalSmartSelectorKey.METRICS,
          name: `KPIs`,
          tab: 'Saved',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.signalId, SelectorModelType.SIGNAL, item),
          helperText: isActiveTeamFilter ? (
            <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
          ) : undefined,
          renderItemPreview: item => (
            <SignalPreview
              label={'KPI'}
              data={{
                ...item,
                type: getMetricTypeName(item.type),
              }}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.SEGMENTS,
          name: `Segments`,
          tab: 'Saved',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.signalId, SelectorModelType.SIGNAL, item),
          helperText: isActiveTeamFilter ? (
            <TeamFilterHelper teamName={teamName} onIgnore={ignoreTeamFilter} />
          ) : undefined,
          renderItemPreview: item => (
            <SignalPreview
              label={'Segment'}
              data={item}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.EVENT_GROUPS,
          name: `Event Groups`,
          tab: 'Saved',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.signalId, item),
          renderItemPreview: item => (
            <SignalPreview
              label={'Event Group'}
              data={item}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.FEATURES,
          name: 'Features',
          tab: 'Saved',
          childrenPath: 'children',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.id, SelectorModelType.SIGNAL, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Feature ${item.overrideName?.toLowerCase()}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.CONTENTS,
          name: 'Contents',
          tab: 'Saved',
          childrenPath: 'children',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.id, SelectorModelType.SIGNAL, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Content ${item.overrideName?.toLowerCase()}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.FUNNELS,
          name: 'Funnels',
          tab: 'Saved',
          childrenPath: 'steps',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.id, SelectorModelType.SIGNAL, item),
          renderItemPreview: item => (
            <SignalPreview
              label={`Funnel ${item.chip}`}
              data={item}
              onInfo={() => onSignalInfo(item.id)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.MILESTONES,
          name: `Milestones`,
          tab: 'Saved',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.signalId, SelectorModelType.SIGNAL, item),
          renderItemPreview: item => (
            <SignalPreview
              label={'Milestone'}
              data={{...item, type: getMilestoneTypeName(item.type)}}
              onInfo={() => onSignalInfo(item.signalId)}
            />
          ),
        },
        {
          key: SignalSmartSelectorKey.SIGNALS,
          name: 'All',
          tab: 'Saved',
          filter: SelectorModelType.SIGNAL,
          onSelect: item => onChange(item.id, SelectorModelType.SIGNAL, item),
          renderItemPreview: item => (
            <SignalPreview label={'All'} data={item} onInfo={() => onSignalInfo(item.id)} />
          ),
        },
        {
          key: 'any_event',
          name: 'Any Table Event',
          tab: 'Events',
          filter: SelectorModelType.ANY_EVENT,
          onSelect: item => onChange(item.id, SelectorModelType.ANY_EVENT, item),
          renderItemPreview: item => <AnyEventPreview label={'Any Table Event'} data={item} />,
        },
        {
          key: 'events',
          name: 'Events',
          tab: 'Events',
          filter: SelectorModelType.EVENT,
          onSelect: item => onChange(item.id, SelectorModelType.EVENT, item),
          renderItemPreview: item => <TableEventPreview label={'Event'} data={item} />,
        },
        {
          key: 'columns',
          name: 'Property',
          tab: 'Property',
          filter: SelectorModelType.COLUMN,
          onSelect: item => onChange(item.id, SelectorModelType.COLUMN, item),
          renderItemPreview: item => <TableColumnPreview label={'Column'} data={item} />,
        },
      ].filter(l => {
        if (
          signalsInclude &&
          l.filter === SelectorModelType.SIGNAL &&
          signalsInclude.indexOf(l.key as any) === -1
        ) {
          return false;
        }
        if (allowTypes && allowTypes.indexOf(l.filter) === -1) {
          return false;
        }
        return true;
      }),
    [
      onChange,
      onSignalInfo,
      signalsInclude,
      allowTypes,
      ignoreTeamFilter,
      teamName,
      isActiveTeamFilter,
    ]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      return await http.exec(
        signalSmartSelectorNetworkRequest({
          keys,
          page: parameters.page,
          q: parameters.query,
          limit: signalsInclude?.length > 1 ? 5 : 10,
          signalFilters: fixFilters(signalFilters),
          columnFilters: fixFilters(columnFilters),
          eventFilters: fixFilters(eventFilters),
          teamId: isActiveTeamFilter ? teamId : undefined,
        })
      );
    },
    [http, columnFilters, eventFilters, signalFilters, signalsInclude, teamId, isActiveTeamFilter]
  );
  const getModel = useCallback(
    async (value: number, valueType: SelectorModelType) => {
      isLoadingGetModel.current = true;
      const nr = GET_MODEL_NR_MAPPING[valueType];
      if (!nr) {
        return;
      }
      try {
        const model = await http.exec(nr(value));
        setSelectedModel(model);
      } catch (e) {
        onChange && onChange(null);
        setSelectedModel(null);
      }
      isLoadingGetModel.current = false;
    },
    [http, setSelectedModel, onChange]
  );
  useEffect(() => {
    if (isLoadingGetModel.current === true) {
      return;
    }
    if (value && selectedModel?.id !== value) {
      getModel(value, valueType);
    }
  }, [value, valueType, getModel, selectedModel, isLoadingGetModel]);

  return (
    <SmartSelectorLayout
      id={'signal-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      prefix={prefix ? `${prefix} /` : undefined}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
      helperText={helperText}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
      />
    </SmartSelectorLayout>
  );
};

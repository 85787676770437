import {BaseMessageRendererProps} from '../../message-viewer.types';
import classes from './help-center-message.module.scss';
import TransKeys from 'translations';
import {LoopsHelpCenterBackedAnswerMessageData} from '../../../../../../../../objects/models/ai-chat.model';
import {useTranslation} from 'react-i18next';
import {Markdown} from '../../../markdown/markdown.component';
import {capitalize} from 'lodash';

type Props = Pick<BaseMessageRendererProps<LoopsHelpCenterBackedAnswerMessageData>, 'message'>;

export const HelpCenterMessage = (props: Props) => {
  const {message} = props;
  const {t} = useTranslation();
  return (
    <div className={classes.HelpCenterMessage}>
      <Markdown message={message.content.response} />
      <div className={classes.ReadMoreSection}>
        {message.content.references && message.content.references.length > 0 && (
          <>
            <div className={classes.ReadMoreTitle}>
              {capitalize(t(TransKeys.GENERAL.LABELS.READ_MORE))}
            </div>
            <ol className={classes.ReferencesList}>
              {message.content.references.map((reference, index) => (
                <li key={index}>
                  <a href={reference.url} target="_blank" rel="noopener noreferrer">
                    {reference.name}
                  </a>
                </li>
              ))}
            </ol>
          </>
        )}
      </div>
    </div>
  );
};

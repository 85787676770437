import {useCurrentUser} from '../../../../../core/hooks/use-user.hook';
import {MemberDTO} from '../../../../../objects/dto/members-settings.dto';
import {Avatar, TooltipIfOverflow} from 'ui-components';
import classes from './member-cell.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {getMemberFullNameOrEmail} from '../../../member.utils.ts';

type MemberNameCellProps = {
  member: Pick<MemberDTO, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>;
};

export const MemberNameCell = (props: MemberNameCellProps) => {
  const {member} = props;
  const currentUser = useCurrentUser();
  const name = getMemberFullNameOrEmail(member);
  const {t} = useTranslation();

  return (
    <div className={classes.MemberNameCell}>
      <Avatar variant="secondary" name={name} />
      <div className={classes.Wrapper}>
        <div>
          <span className={classes.Name}>{name}</span>
          {currentUser.id === member.id && (
            <span>{t(TransKeys.ACCOUNT_SETTINGS.MEMBERS.TABLE.MEMBER_NAME_IS_USER)}</span>
          )}
        </div>
        <TooltipIfOverflow title={member.email}>
          <span className={classes.Email}>{member.email}</span>
        </TooltipIfOverflow>
      </div>
    </div>
  );
};

import {Experiment} from '../../../../../../../../../objects/models/experiment.model';
import {AnalysisTypeId} from '../../../../../../../../../constants/analysis-type-id';
import {TrendChip} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {isEmpty, values} from 'lodash';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import classes from './experiment-primary-kpi-cell.module.scss';

interface OwnProps {
  experiment: Experiment;
}

type AllProps = OwnProps;

interface ExperimentVariant {
  uplift: number;
  name: string;
  isControl: boolean;
  isWinner: boolean;
  probabilityToWin: number;
}

const getResultTooltipTextTxKey = (isSignificant: boolean, uplift: number) =>
  !isSignificant || uplift === 0 ? 'INSIGNIFICANT' : 'POSITIVE_OR_NEGATIVE';

const ReleaseImpactTrendChip = (props: AllProps) => {
  const {experiment: exp} = props;
  const {t} = useTranslation();
  const postReleaseVariant: ExperimentVariant = useMemo(
    () => exp.lastCompletedAnalysisResult.outputs.post,
    [exp]
  );
  // If uplift in release is null: "Waiting for more data"
  if (postReleaseVariant.uplift === null) {
    return null;
  }
  return (
    <Tooltip
      title={t(
        TransKeys.EXPERIMENTS.TABLE.HEADERS.RESULT.RELEASE_IMPACT[
          getResultTooltipTextTxKey(postReleaseVariant.isWinner, postReleaseVariant.uplift)
        ].HELPER_TEXT,
        {impact: `${postReleaseVariant.uplift}%`}
      )}
      placement={'top'}
      interactive={false}
    >
      <TrendChip
        value={postReleaseVariant.uplift}
        isSignificant={postReleaseVariant.isWinner}
        higherIsBetter={true}
        size={'xsmall'}
      />
    </Tooltip>
  );
};

const ABTestTrendChip = (props: AllProps) => {
  const {experiment: exp} = props;
  const {t} = useTranslation();
  const outputsValues: ExperimentVariant[] = useMemo(
    () => values(exp.lastCompletedAnalysisResult.outputs),
    [exp]
  );
  const highestProbabilityToWinVariant = useMemo(() => {
    const initialVariant = outputsValues.find(o => !o.isControl);
    return outputsValues.reduce((acc, curr) => {
      if (curr.isControl) {
        return acc;
      }
      return curr.probabilityToWin > acc.probabilityToWin ? curr : acc;
    }, initialVariant);
  }, [outputsValues]);

  const tooltipTitle = useMemo(
    () =>
      t(
        TransKeys.EXPERIMENTS.TABLE.HEADERS.RESULT.AB_TEST[
          getResultTooltipTextTxKey(
            highestProbabilityToWinVariant.isWinner,
            highestProbabilityToWinVariant.uplift
          )
        ].HELPER_TEXT,
        {
          variantName: highestProbabilityToWinVariant.name,
          impact: t(
            TransKeys.GENERAL.LABELS[
              highestProbabilityToWinVariant.uplift > 0 ? 'POSITIVE' : 'NEGATIVE'
            ]
          ),
        }
      ),
    [t, highestProbabilityToWinVariant]
  );

  return (
    <Tooltip title={tooltipTitle} placement={'top'} interactive={false}>
      <TrendChip
        value={highestProbabilityToWinVariant.uplift}
        isSignificant={highestProbabilityToWinVariant.isWinner}
        higherIsBetter={true}
        size={'xsmall'}
      />
    </Tooltip>
  );
};

const ANALYSIS_TYPE_ID_TO_EXPERIMENT_RESULT_COLUMN_COMPONENT_MAP = {
  [AnalysisTypeId.A_B_TEST]: ABTestTrendChip,
  [AnalysisTypeId.RELEASE_IMPACT]: ReleaseImpactTrendChip,
};

export const ExperimentTrendChip = (props: AllProps) => {
  const {experiment: exp} = props;

  const hasUplift = useMemo(
    () =>
      exp?.lastCompletedAnalysisResult?.outputs &&
      values(exp?.lastCompletedAnalysisResult?.outputs).find(o => 'uplift' in o),
    [exp]
  );

  // If outputs empty or no key uplift: Empty
  if (
    !exp.lastCompletedAnalysisResult?.outputs ||
    isEmpty(exp.lastCompletedAnalysisResult.outputs)
  ) {
    return null;
  }

  if (!hasUplift) {
    return null;
  }
  const Component =
    ANALYSIS_TYPE_ID_TO_EXPERIMENT_RESULT_COLUMN_COMPONENT_MAP[exp.analysis.analysisTypeId];

  return (
    <span className={classes.UpliftChip}>
      <Component experiment={exp} />
    </span>
  );
};

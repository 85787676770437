import * as React from 'react';
import classNames from 'classnames';
import classes from './select.module.scss';
import {
  APICallInputOptions,
  CloseIcon,
  DropdownButton,
  EnumInputOption,
  EnumInputOptions,
} from '../../../../index';
import {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import sharedClasses from '../../../shared.module.scss';
import {Popover} from '@material-ui/core';
import {EnumDialog} from '../../dialogs/enum-dialog.component';
import {APICallDialog} from '../../dialogs/api-call-dialog.component';
import {SelectedOption} from '../../inputs.types';
import {HttpClientContext} from 'front-core';
import {extractValue} from '../../../../hooks/use-remote-source';
import {InputButton} from '../../related/input-button/input-button.component';
import {isArray} from 'lodash';
import pluralize from 'pluralize';

export interface SelectAction {
  title: string;
  onClick: () => void;
  icon: any;
  hidden?: boolean;
}

type ValueType = string | number;

export interface SelectComponentProps {
  value: ValueType | Array<ValueType>;
  options: EnumInputOptions | APICallInputOptions;
  placeholder?: any;
  prefix?: string;
  multiSelectItemName?: string;
  onChange: (value: ValueType) => void;
  className?: string;
  fullWidth?: boolean;
  dropdownButtonClassName?: string;
  searchable?: boolean;
  disabled?: boolean;
  error?: boolean;
  multi?: boolean;
  clearable?: boolean;
  capitalize?: boolean;
  sortValues?: boolean;
  selectionHelper?: any;
  popoverDirection?: string;
  disablePortal?: boolean;
  icon?: any;
  renderLabel?: (value: ValueType) => ReactNode;
  actions?: Array<SelectAction>;
  fitContent?: boolean;
  hideButtonLabel?: boolean;
  dropdownButtonIconPosition?: 'start' | 'end';
}

type AllProps = SelectComponentProps;

export const Select: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    prefix,
    options,
    placeholder,
    onChange,
    renderLabel,
    multi,
    multiSelectItemName,
    icon,
    className,
    fullWidth,
    dropdownButtonClassName,
    searchable,
    disabled,
    error,
    clearable,
    capitalize,
    sortValues,
    selectionHelper,
    fitContent,
    popoverDirection,
    hideButtonLabel,
    dropdownButtonIconPosition,
    actions: actionsFromProps,
    disablePortal,
  } = props;
  const httpClient = useContext(HttpClientContext);
  const [selectedOptions, setSelectedOptions] = useState<any[]>(null);
  const [enumOptions, setEnumOptions] = useState<EnumInputOption[]>((options as any).options || []);
  const isAPISelect = useMemo(() => Boolean((options as any).networkRequest), [options]);
  const selectionKey = useMemo(() => (options as any).selectionKey || 'id', [options]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const getOption = useCallback(
    async (value: SelectedOption) => {
      if (!('networkRequest' in options)) {
        return;
      }
      if (!value || (isArray(value) && value.length === 0)) {
        return;
      }
      const nr = options.networkRequest(undefined, {
        [selectionKey]: value,
      });
      const res: any = await httpClient.exec(nr);
      setSelectedOptions(
        res.map(i => ({
          label: extractValue(i, options.labelAttributePath),
          value: extractValue(i, options.valueAttributePath),
        }))
      );
    },
    [httpClient, options, selectionKey]
  );
  const pluralItemName = useMemo(
    () => (multiSelectItemName ? pluralize(multiSelectItemName) : undefined),
    [multiSelectItemName]
  );
  const handleClick = useCallback(
    event => {
      if (disabled) {
        return;
      }
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [disabled]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const onDialogValueChange = useCallback(
    value => {
      if (!multi) {
        setAnchorEl(null);
      }
      if (isArray(value) && value.length === 0) {
        onChange(null);
        return;
      }
      onChange(value);
    },
    [onChange, setAnchorEl, multi]
  );
  const renderActions = useMemo(
    () =>
      [
        ...(actionsFromProps || []),
        {
          title: '',
          onClick: () => onDialogValueChange(null),
          icon: CloseIcon,
          hidden:
            !clearable ||
            value === null ||
            value === undefined ||
            (multi && (value as any).length === 0),
        },
      ]
        .filter(a => !a.hidden)
        .map((a, idx) => (
          <InputButton
            key={idx}
            icon={a.icon}
            text={a.title}
            className={classes.AdditionalAction}
            onClick={e => {
              e.stopPropagation();
              a.onClick();
            }}
          />
        )),
    [actionsFromProps, clearable, multi, onDialogValueChange, value]
  );
  const labelOption = useMemo(() => {
    if (selectedOptions?.length === 1) {
      return selectedOptions[0];
    }
    if (selectedOptions?.length > 1) {
      return {
        label: `${selectedOptions.length} ${pluralItemName ? `${pluralItemName} ` : ''}selected`,
      };
    }
    return null;
  }, [selectedOptions, pluralItemName]);

  useEffect(() => {
    if (value === selectedOptions?.[0]?.value) {
      return;
    }
    const selected = enumOptions?.filter(o => {
      return multi && isArray(value) ? value.indexOf(o.value) > -1 : value === o.value;
    });
    if (selected.length === 0 && isAPISelect) {
      getOption(value as any);
      setSelectedOptions(null);
    } else if (selected.length === 0) {
      setSelectedOptions(null);
    } else {
      setSelectedOptions(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, multi, enumOptions, isAPISelect]);
  useEffect(() => {
    setEnumOptions((options as any).options || []);
  }, [options]);

  const open = Boolean(anchorEl);

  return (
    <div className={classNames(classes.Select, fullWidth && classes.FullWidth, className)}>
      <DropdownButton
        className={classNames(fitContent && classes.fitContent, dropdownButtonClassName)}
        error={error}
        iconPosition={dropdownButtonIconPosition}
        hideText={hideButtonLabel}
        label={renderLabel ? renderLabel(value as any) : labelOption?.label}
        placeholder={placeholder}
        onClick={handleClick}
        icon={labelOption?.icon || icon}
        isOpen={open}
        prefix={prefix}
        fullWidth={fullWidth}
        capitalize={capitalize}
        disabled={disabled}
        actions={disabled ? undefined : renderActions}
      />
      <Popover
        className={sharedClasses.BlankPaperAllowOverflow}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal={disablePortal}
        classes={{
          paper: sharedClasses.BlankPaperAllowOverflow,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: popoverDirection as any,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: popoverDirection as any,
        }}
      >
        {!isAPISelect && (
          <EnumDialog
            options={{options: enumOptions}}
            value={value as any}
            onSubmit={onDialogValueChange}
            searchable={searchable}
            sortValues={sortValues}
            multi={multi}
            selectionHelper={selectionHelper}
            mode={'onChange'}
            fitContent={fitContent}
          />
        )}
        {isAPISelect && (
          <APICallDialog
            value={value as any}
            options={options as any}
            onSubmit={onDialogValueChange}
            onOptionsChange={setEnumOptions}
            multi={multi}
            mode={'onChange'}
          />
        )}
      </Popover>
    </div>
  );
};

Select.defaultProps = {
  sortValues: true,
  clearable: true,
  placeholder: 'Select',
  dropdownButtonIconPosition: 'start',
  popoverDirection: 'left',
};

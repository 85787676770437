import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {
  AdvancedSmartSelector,
  SelectorModelType,
} from '../../smart-selector/advanced-smart-selector.component';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {cloneDeep, get} from 'lodash';
import {
  ConditionOperator,
  LiteralValueType,
  QueryBuilderFactory,
  SqlElementType,
} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import classes from './template-item-query-builder.module.scss';
import {TableColumnSmartSelector} from '../../smart-selector/table-column-smart-selector.component';
import {del, set} from 'object-path-immutable';
import {OpValueSelector} from '../shared-builder-parts/op-value-selector.component';
import {mapLiteralByOp} from '../../../../../utils/query-builder.utils';
import {exists} from 'front-core';
import {TableEvent} from '../../../../../objects/models/table-event.model';
import {VALIDATE_QUERY_WITH_API_TEST_NAME} from '../../../../../objects/dto/query-builder.dto.ts';
import {Table} from '../../../../../objects/models/table.model.ts';

interface OwnProps {
  query: any;
  onChange: (value: any) => void;
  allowTypes?: SelectorModelType[];
  excludeAnyEvent?: boolean;
  signalFilters?: any;
  signalsInclude?: SignalSmartSelectorKey[];
  onSignalInfo?: (value: string | number) => void;
  columnFilters?: any;
  eventFilters?: any;
  errors?: any;
  disabled?: boolean;
  clearable?: boolean;
  prefix?: string;
  placeholder?: string;
  className?: string;
  helperText?: string;
}

type AllProps = OwnProps;

export const createTableEventCondition = (tableEvent: TableEvent) => {
  const query = QueryBuilderFactory.createAndCondition();
  query.conditions = [];
  // create conditions
  const condition = QueryBuilderFactory.createCondition();
  condition.left = QueryBuilderFactory.createTableColumn();
  condition.left.table_id = tableEvent.tableId;
  condition.left.column = 'event_name';
  condition.op = ConditionOperator.EQ;
  condition.right = QueryBuilderFactory.createLiteral();
  condition.right.value_type = LiteralValueType.STRING;
  condition.right.value = tableEvent.name;
  // add root condition
  query.conditions.push(condition);
  query[PARAMETERS_METADATA_KEY] = {
    [TABLE_EVENT_ID_KEY]: tableEvent.id,
    [DISPLAY_NAME_KEY]: tableEvent.name,
  };
  return query;
};

export const createAnyEventCondition = (table: Table) => {
  const query = QueryBuilderFactory.createAndCondition();
  query.conditions = [];
  // create conditions
  const condition = QueryBuilderFactory.createCondition();
  condition.left = QueryBuilderFactory.createTableColumn();
  condition.left.table_id = table.id;
  condition.left.column = 'event_name';
  condition.op = ConditionOperator.IS_NOT_NULL;
  condition.right = QueryBuilderFactory.createLiteral();
  condition.right.value_type = LiteralValueType.NULL;
  condition.right.value = null;
  // add root condition
  query.conditions.push(condition);
  query[PARAMETERS_METADATA_KEY] = {
    [DISPLAY_NAME_KEY]: table.name,
  };
  return query;
};

const {TABLE_COLUMN_ID_KEY, TABLE_EVENT_ID_KEY, TABLE_COLUMN_TYPE_KEY, DISPLAY_NAME_KEY} =
  METADATA_KEY;

const TABLE_COLUMN_FILTERS = {
  literalType: [
    LiteralValueType.INTEGER,
    LiteralValueType.FLOAT,
    LiteralValueType.STRING,
    LiteralValueType.BOOLEAN,
    LiteralValueType.DATE,
  ],
};

const PLACEHOLDERS = {
  [SelectorModelType.COLUMN]: 'Select property',
  [SelectorModelType.EVENT]: 'Select event',
  [SelectorModelType.SIGNAL]: 'Select saved',
};

export const TemplateItemQueryBuilder = (props: AllProps) => {
  const {
    query,
    onChange: onChangeFromProps,
    errors,
    placeholder,
    disabled,
    signalFilters,
    signalsInclude,
    columnFilters,
    eventFilters,
    allowTypes,
    clearable,
    onSignalInfo,
    prefix,
    helperText,
    excludeAnyEvent,
    className,
  } = props;
  const allowTypesForSmartSelector = useMemo(() => {
    if (!exists(allowTypes)) {
      return undefined;
    }
    const at = [...allowTypes];
    if (at.includes(SelectorModelType.EVENT) && !excludeAnyEvent) {
      at.push(SelectorModelType.ANY_EVENT);
    }
    return at;
  }, [allowTypes, excludeAnyEvent]);
  const placeHolder = useMemo(() => {
    if (!exists(allowTypes) || allowTypes.length > 1) {
      return placeholder || 'Select';
    }
    return PLACEHOLDERS[allowTypes[0]];
  }, [allowTypes, placeholder]);
  const rootError = useMemo(
    () =>
      Boolean(
        errors?.message ||
          exists(errors?.signal_id) ||
          exists(errors?.table_id) ||
          exists(errors?.type) ||
          errors?.conditions?.message
      ) && errors?.type !== VALIDATE_QUERY_WITH_API_TEST_NAME,
    [errors]
  );
  const rootParams = useMemo(() => {
    if (get(query, 'type') === SqlElementType.SIGNAL_COLUMN) {
      return {
        value: get(query, `signal_id`),
        valueType: SelectorModelType.SIGNAL,
      };
    }
    if (get(query, 'type') === SqlElementType.TABLE_COLUMN) {
      return {
        value: get(query, `${PARAMETERS_METADATA_KEY}.${TABLE_COLUMN_ID_KEY}`),
        valueType: SelectorModelType.COLUMN,
      };
    }
    if (get(query, 'type') === SqlElementType.AND_CONDITION) {
      const condition = get(query, 'conditions.0');
      if (condition.op === ConditionOperator.IS_NOT_NULL) {
        return {
          value: condition.left.table_id,
          valueType: SelectorModelType.ANY_EVENT,
        };
      }
      return {
        value: get(query, `${PARAMETERS_METADATA_KEY}.${TABLE_EVENT_ID_KEY}`),
        valueType: SelectorModelType.EVENT,
      };
    }
    return {value: null, valueType: null};
  }, [query]);
  const onChange = useCallback(
    newQuery => {
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...(newQuery[PARAMETERS_METADATA_KEY] || {}),
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TemplateItemQueryBuilder',
      };
      onChangeFromProps(newQuery);
    },
    [onChangeFromProps]
  );
  const onSelectorChange = useCallback(
    (valueId: number, valueType: SelectorModelType, item: any) => {
      let query: any = {};
      if (valueType === SelectorModelType.SIGNAL) {
        query = QueryBuilderFactory.createSignalColumn();
        query.signal_id = valueId;
        query[PARAMETERS_METADATA_KEY] = {
          [DISPLAY_NAME_KEY]: item.name,
        };
      } else if (valueType === SelectorModelType.COLUMN) {
        query = QueryBuilderFactory.createTableColumn();
        query.table_id = item.tableId;
        query.column = item.name;
        query[PARAMETERS_METADATA_KEY] = {
          [TABLE_COLUMN_ID_KEY]: valueId,
          [DISPLAY_NAME_KEY]: item.name,
        };
      } else if (valueType === SelectorModelType.EVENT) {
        query = createTableEventCondition(item);
      } else if (valueType === SelectorModelType.ANY_EVENT) {
        query = createAnyEventCondition(item);
      }
      onChange(query);
    },
    [onChange]
  );
  const onAddWhere = useCallback(() => {
    const newQuery = cloneDeep(query);
    const condition = QueryBuilderFactory.createCondition();
    condition.left = QueryBuilderFactory.createTableColumn();
    condition.left.table_id = get(newQuery, 'conditions.0.left.table_id');
    condition.left.column = '';
    condition.op = ConditionOperator.EQ;
    condition.right = QueryBuilderFactory.createLiteral();
    condition.right.value = null;
    condition.right.value_type = null;
    newQuery.conditions.push(condition);
    onChange(newQuery);
  }, [query, onChange]);
  const onRemoveWhere = useCallback(
    (conditionIdx: number) => {
      const newQ = del(query, `conditions.${conditionIdx}`);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onTableColumnChange = useCallback(
    (conditionIdx: number, tableColumnId: number, item: any) => {
      const condition = cloneDeep(get(query, `conditions.${conditionIdx}`));
      condition.left.column = item.name;
      condition.right.value_type = item.literalType;
      if (item.literalType === LiteralValueType.DATE) {
        condition.op = ConditionOperator.LT;
      }
      condition[PARAMETERS_METADATA_KEY] = {
        [TABLE_COLUMN_ID_KEY]: tableColumnId,
        [TABLE_COLUMN_TYPE_KEY]: item.literalType,
      };
      const newQ = set(query, `conditions.${conditionIdx}`, condition);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onColumnOpChanged = useCallback(
    (conditionIdx: number, op: ConditionOperator) => {
      const condition = cloneDeep(get(query, `conditions.${conditionIdx}`));
      condition.op = op;
      condition.right.value = null;
      condition.right = mapLiteralByOp(condition.right, op);
      const newQ = set(query, `conditions.${conditionIdx}`, condition);
      onChange(newQ);
    },
    [query, onChange]
  );
  const onColumnValueChanged = useCallback(
    (conditionIdx: number, literalValue: any) => {
      const condition = cloneDeep(get(query, `conditions.${conditionIdx}`));
      condition.right.value = literalValue;
      const newQ = set(query, `conditions.${conditionIdx}`, condition);
      onChange(newQ);
    },
    [query, onChange]
  );
  const showWhere = useMemo(
    () => [SelectorModelType.EVENT, SelectorModelType.ANY_EVENT].includes(rootParams.valueType),
    [rootParams]
  );
  const hasWheres = useMemo(() => showWhere && query.conditions.length > 1, [query, showWhere]);
  const isAnyEvent = useMemo(
    () => rootParams.valueType === SelectorModelType.ANY_EVENT,
    [rootParams]
  );
  return (
    <div className={classNames(classes.TemplateItemBuilder, className)}>
      <div className={classes.MainSelectorWrapper}>
        {prefix && <div className={classes.Prefix}>{prefix}</div>}
        <AdvancedSmartSelector
          className={classes.MainSelector}
          placeholder={placeHolder}
          onChange={onSelectorChange}
          value={rootParams.value}
          valueType={rootParams.valueType}
          error={rootError}
          signalFilters={signalFilters}
          signalsInclude={signalsInclude}
          columnFilters={columnFilters}
          eventFilters={eventFilters}
          allowTypes={allowTypesForSmartSelector}
          onSignalInfo={onSignalInfo}
          disabled={disabled}
          clearable={clearable}
          helperText={helperText}
        />
        {!disabled && showWhere && (
          <div className={classes.Button} onClick={() => onAddWhere()}>
            + where
          </div>
        )}
      </div>
      {hasWheres &&
        query.conditions
          .filter((_, idx) => idx !== 0)
          .map((c, cIdx) => (
            <div key={cIdx} className={classes.WhereRow}>
              <div className={classes.Item}>{cIdx >= 1 && 'and'} where</div>
              <div className={classes.Item}>
                <TableColumnSmartSelector
                  placeholder={PLACEHOLDERS[SelectorModelType.COLUMN]}
                  value={c[PARAMETERS_METADATA_KEY]?.[TABLE_COLUMN_ID_KEY]}
                  filters={{
                    tableId: c.left?.table_id,
                    ...TABLE_COLUMN_FILTERS,
                    // allow to select event_name column on any event
                    ignore_event_name_column: !isAnyEvent,
                  }}
                  // +1 because of filter
                  error={Boolean(get(errors, `conditions.${cIdx + 1}.left.column`))}
                  onChange={(v, tableColumn) => onTableColumnChange(cIdx + 1, v, tableColumn)}
                  disabled={disabled}
                />
              </div>
              {exists(c[PARAMETERS_METADATA_KEY]?.[TABLE_COLUMN_ID_KEY]) && (
                <OpValueSelector
                  className={classes.Item}
                  op={c.op}
                  type={c[PARAMETERS_METADATA_KEY][TABLE_COLUMN_TYPE_KEY]}
                  value={c.right?.value}
                  // +1 because of filter
                  error={get(errors, `conditions.${cIdx + 1}.right.value`)}
                  onValueChange={v => onColumnValueChanged(cIdx + 1, v)}
                  onOpChange={op => onColumnOpChanged(cIdx + 1, op)}
                  disabled={disabled}
                />
              )}
              {!disabled && (
                <div className={classes.Actions}>
                  <div className={classes.Button} onClick={() => onRemoveWhere(cIdx + 1)}>
                    remove
                  </div>
                </div>
              )}
            </div>
          ))}
    </div>
  );
};

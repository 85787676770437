import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classes from './segment-categories-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {isEmpty, values} from 'lodash';
import {exists} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {hasErrors} from '../../../../../../utils/general.utils';
import {SegmentCategoriesForAnalysisSmartSelector} from '../../../../../shared/core/smart-selector/segment-catetories-for-analysis-smart-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';

interface SchemaKeysMapping {
  included_segments_tag: string;
  included_segments_signals: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  included_segments_tag: 'included_segments_tag',
  included_segments_signals: 'included_segments_signals',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext?: TableEntity;
  className?: string;
}

type AllProps = OwnProps;

export const SegmentCategoriesSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    onChange: onChange_,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    entityContext,
    value: data,
    variant,
    className,
  } = props;
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const value = useMemo(() => {
    const tags = data[schemaKeysMapping.included_segments_tag]?.split(',').filter(o => !!o) || [];
    return [...tags, ...(data[schemaKeysMapping.included_segments_signals] || [])];
  }, [data, schemaKeysMapping]);
  const onChange = useCallback(
    v => {
      const includedSegmentsTag =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'string').join(',') : undefined;
      const includedSegmentsSignals =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'number') : undefined;
      onChange_({
        [schemaKeysMapping.included_segments_tag]: includedSegmentsTag,
        [schemaKeysMapping.included_segments_signals]: includedSegmentsSignals,
      });
    },
    [onChange_, schemaKeysMapping]
  );
  const filters = useMemo(
    () => ({
      entityContext,
      entityBinding: TableEntityBinding.ONE_WAY,
    }),
    [entityContext]
  );

  const renderContent = () => {
    return (
      <SegmentCategoriesForAnalysisSmartSelector
        value={value}
        onChange={onChange}
        label={t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_CATEGORIES.SELECT_PLACEHOLDER)}
        filters={filters}
        selectedText={t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_CATEGORIES.SELECTED_TEXT)}
        error={hasError}
      />
    );
  };

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent();
  }

  return (
    <ParameterInputWrapper
      title={
        title === undefined ? t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_CATEGORIES.TITLE) : title
      }
      subTitle={
        subTitle === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_CATEGORIES.SUB_TITLE)
          : subTitle
      }
      className={classNames(classes.FeatureInteractionSelector, className)}
      helperText={
        helperText === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.SEGMENT_CATEGORIES.HELPER_TEXT)
          : helperText
      }
      error={hasError}
    >
      {renderContent()}
    </ParameterInputWrapper>
  );
};

import {NRC} from './index.ts';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {ExplorationDataRequestDTO} from '../objects/dto/exploration.dto.ts';

export const postExplorationDataNetworkRequest: NRC<ExplorationDataRequestDTO, void> = (
  data: ExplorationDataRequestDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/explore`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

import {useCallback, useEffect, useMemo, useState} from 'react';
import {composition} from 'front-core';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../constants/app-routes';
import {useNavigate} from 'react-router';
import {NumberParam, useQueryParam} from 'use-query-params';
import classes from './view-metric-series-result.module.scss';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleErrorSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {ModelKey} from '../../../../constants/model-key';
import {AnalysisResultStatus} from '../../../../objects/models/analysis-result.model';
import {EmptyState} from '../../../shared/components/general/override';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AdvancedDocument} from 'ui-components';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component';
import PageLayout from '../../../shared/components/layout/page-layout';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_ANALYSIS_RESULT_KEY = SharedSelectionKeys.VIEW_METRIC_SERIES_RESULT__RESULT;

const SHOW_RESULT_STATUS = [AnalysisResultStatus.COMPLETED, AnalysisResultStatus.FAILED];

const ViewMetricSeriesResultComponent = (props: AllProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [docTitle, setDocTitle] = useState<string>(null);
  const [analysisResultId] = useQueryParam(ANALYSIS_RESULT_ID_PATH_PARAM, NumberParam);
  // Selectors
  const analysisResult = useSelector(state =>
    getSingleSelectedSelector(SELECTED_ANALYSIS_RESULT_KEY, state)
  );
  const isLoadingResult = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_ANALYSIS_RESULT_KEY)(state)
  );
  const error = useSelector(state => getSingleErrorSelector(SELECTED_ANALYSIS_RESULT_KEY, state));
  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_ANALYSIS_RESULT_KEY,
        actionKey: SELECTED_ANALYSIS_RESULT_KEY,
        request: getAnalysisResultNetworkRequest,
        modelKey: ModelKey.ANALYSIS_RESULT,
      })
    );
    // clean up
    return () => {
      dispatch(removeSelected(SELECTED_ANALYSIS_RESULT_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    // When analysis result id changes we need to get it
    analysisResultId && dispatch(getSelected(SELECTED_ANALYSIS_RESULT_KEY, analysisResultId));
  }, [analysisResultId, dispatch]);
  const showResults = useMemo(
    () => (analysisResult ? SHOW_RESULT_STATUS.indexOf(analysisResult?.status) > -1 : false),
    [analysisResult]
  );
  const onDocPageChange = useCallback(
    (doc: AdvancedDocument) => {
      if (doc.id !== analysisResult?.rootDocumentId) {
        setDocTitle(doc.title || 'Deep dive');
        return;
      }
      setDocTitle(null);
    },
    [analysisResult]
  );

  return (
    <PageLayout.Layout>
      <PageHeader
        title={docTitle}
        crumbs={[
          {
            name: t(TransKeys.HEALTH_MONITOR.HEADER.TITLE),
            navigateTo: AppRoutes.healthMonitor(),
          },
        ]}
      />
      <PageLayout.Body noPadding isLoading={isLoadingResult}>
        {showResults && (
          <AnalysisResults
            analysisResult={analysisResult}
            isLoading={isLoadingResult}
            onPageChange={onDocPageChange}
            navigationDisabled
          />
        )}
        {!showResults && !error && !isLoadingResult && (
          <div className={classes.EmptyStateWrapper}>
            <EmptyState
              title={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.TITLE)}
              subTitle={t(TransKeys.VIEW_ANALYSIS.WAITING_FOR_RESULTS.SUB_TITLE)}
              buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
              onClick={_ => navigate(-1)}
            />
          </div>
        )}
        {error && (
          <div className={classes.EmptyStateWrapper}>
            <EmptyState
              title={t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.TITLE)}
              subTitle={t(TransKeys.VIEW_ANALYSIS.EMPTY_STATE_404.SUB_TITLE)}
              buttonText={t(TransKeys.GENERAL.ACTIONS.BACK)}
              onClick={_ => navigate(-1)}
            />
          </div>
        )}
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ViewMetricSeriesResult = composition<OwnProps>(ViewMetricSeriesResultComponent);

export default ViewMetricSeriesResult;

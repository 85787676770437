import classNames from 'classnames';
import classes from './main-table-structure.module.scss';
import {useTranslation} from 'react-i18next';
import {Filters} from 'ui-components';
import {AcceptedSelectionKey, TableColumn} from '../../components/general/table/table.component';
import {RemoteListConfig} from '../../../../store/remote-lists/remote-list.types';
import {GenericLoading} from '../../components/general/generic-loading/generic-loading.component';
import {MainHeader} from '../../components/layout/main-header/main-header.component';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import {useCallback} from 'react';
import {GridTable} from '../../components/general/grid-table/grid-table.component';

interface OwnProps {
  title?: string;
  helperTextTitle?: any;
  icon?: any;
  config: RemoteListConfig;
  filtersDef?: any[];
  defaultFilters?: any;
  emptyStateTranslationPath: any;
  columns: TableColumn[];
  itemsPerPage?: number;
  pagination?: boolean;
  allowSelection?: boolean;
  selectedKeys?: AcceptedSelectionKey[];
  onSelectedKeysChange?: (selection: AcceptedSelectionKey[]) => void;
  onRowClicked?: (item: any) => void;
  headerRenderRight?: any;
  syncQueryFilters?: boolean;
  paginationMode?: 'pages' | 'load-more';
  className?: string;
}

type AllProps = OwnProps;

export const MainTableStructure = (props: AllProps) => {
  const {
    defaultFilters,
    icon: Icon,
    title,
    helperTextTitle,
    columns,
    emptyStateTranslationPath,
    filtersDef,
    config,
    pagination = true,
    paginationMode = 'pages',
    syncQueryFilters = true,
    allowSelection,
    selectedKeys,
    onSelectedKeysChange,
    onRowClicked,
    headerRenderRight = null,
    className,
    itemsPerPage = 10,
  } = props;
  const {t} = useTranslation();
  const {isLoading, onFiltersChange, listsData, onPageChange, onLoadMore, onSort, filters} =
    useRemoteList({
      config,
      filtersDef,
      defaultFilters,
      itemsPerPage,
      syncQueryFilters,
    });
  const onPageChange_ = useCallback(
    (_, page) => (paginationMode === 'pages' ? onPageChange(page + 1) : onLoadMore()),
    [onPageChange, onLoadMore, paginationMode]
  );

  return (
    <div className={classNames(classes.MainTableStructure, className)}>
      <div className={classes.Header}>
        <MainHeader
          title={title}
          helperText={helperTextTitle}
          icon={Icon}
          isLoading={isLoading}
          renderRight={<div className={classes.ActionsWrapper}>{headerRenderRight}</div>}
        />
      </div>
      <div className={classes.Filters}>
        <Filters
          selected={filters}
          defaultFilters={defaultFilters}
          hideDefaultFilters
          onChange={onFiltersChange}
          onClearAll={() => onFiltersChange(defaultFilters)}
          freeSearchFilterKey={'q'}
          maxWidth={'unset'}
          filters={filtersDef}
          className={classes.FiltersSelector}
          disabled={isLoading}
        />
      </div>
      <div className={classes.TableWrapper}>
        {isLoading && <GenericLoading />}
        {listsData && (
          <GridTable
            className={classes.Table}
            data={listsData.list}
            dataKey={'id'}
            columns={columns}
            pagination={isLoading ? false : pagination}
            paginationMode={paginationMode}
            onPageChange={onPageChange_}
            total={listsData.meta?.total}
            page={listsData.meta?.page - 1}
            perPage={itemsPerPage}
            allowSelection={allowSelection}
            onSort={(_, key) => onSort(key)}
            order={filters?.order}
            orderBy={filters?.orderBy}
            selectedKeys={selectedKeys}
            onSelectedKeysChange={onSelectedKeysChange}
            onRowClicked={onRowClicked}
            emptyStateProps={
              !isLoading
                ? {
                    title: t(emptyStateTranslationPath.TITLE),
                    subTitle: t(emptyStateTranslationPath.SUB_TITLE),
                    text: t(emptyStateTranslationPath.TEXT),
                  }
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

import {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {PanelsContext} from '../contexts/panels.context';
import {PanelKey} from '../../constants/panels';
import TransKeys from 'translations';
import {useAnalysisRuntime} from '../../modules/analyses/hooks/use-analysis-runtime.hook';
import {AnalysisResult} from '../../objects/models/analysis-result.model';
import {Experiment, ExperimentStatus} from '../../objects/models/experiment.model';
import {AnyAction} from 'redux';
import {exists} from 'front-core';
import {useDispatch} from 'react-redux';
import {rerunExperimentLastCompletedAnalysisResultConfirmed} from '../../store/experiments/experiments.actions';
import {rerunAnalysisResultWithOpportunitiesConfirmed} from '../../store/analyses/analyses.actions';

type RerunConfirmedAction = (id: number, onApprove: () => void) => AnyAction;
type RerunDispatcherFunction = (modelId: number, analysisTypeId: number) => AnyAction;

function useRerun(rerunConfirmedAction: RerunConfirmedAction): RerunDispatcherFunction {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {getEstimatedRuntimeFormatted} = useAnalysisRuntime();

  const onRerunApprove = useCallback(
    (analysisTypeId: number) =>
      openSecondaryPanel(PanelKey.SIMPLE_TEXT_PANEL, {
        title: t(TransKeys.GENERAL.LABELS.THANK_YOU_EMOJI),
        content: `${t(TransKeys.GENERAL.LABELS.WORKING_ON_IT)} ${getEstimatedRuntimeFormatted(
          analysisTypeId
        )}`,
      }),
    [openSecondaryPanel, t, getEstimatedRuntimeFormatted]
  );

  return useCallback(
    (modelId: number, analysisTypeId: number) =>
      dispatch(rerunConfirmedAction(modelId, () => onRerunApprove(analysisTypeId))),
    [dispatch, rerunConfirmedAction, onRerunApprove]
  );
}

export function useRerunExperiment() {
  const {t} = useTranslation();
  const onRerun_ = useRerun(rerunExperimentLastCompletedAnalysisResultConfirmed);
  const getExperimentRerunHelperText = useCallback(
    (exp: Experiment) => {
      let rerunHelperText;
      if (!exists(exp.lastCompletedAnalysisResult?.id)) {
        rerunHelperText = t(TransKeys.EXPERIMENT.RERUN_HELPER_TEXT.NO_LAST_COMPLETED);
      }
      if (exp.reRunningAnalysisResult) {
        rerunHelperText = t(TransKeys.EXPERIMENT.RERUN_HELPER_TEXT.CURRENTLY_RERUNNING);
      }
      return rerunHelperText;
    },
    [t]
  );
  const shouldDisableRerun = useCallback((exp: Experiment) => {
    if (exp.reRunningAnalysisResult) {
      return true;
    }
    if (!exists(exp.lastCompletedAnalysisResult?.id)) {
      return true;
    }
    if (exp.status === ExperimentStatus.DONE) {
      return true;
    }
    return false;
  }, []);
  const onRerunExperiment = useCallback(
    (exp: Experiment) => onRerun_(exp.id, exp.analysis.analysisTypeId),
    [onRerun_]
  );

  return {
    onRerun: onRerunExperiment,
    getExperimentRerunHelperText,
    shouldDisableRerun,
  };
}

type onRerunFallbackCallback = (ar: AnalysisResult) => void;
export function useRerunAnalysisResult(fallback?: onRerunFallbackCallback) {
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const onRerun_ = useCallback(
    (analysisResultId: number) =>
      openSecondaryPanel(PanelKey.ANALYSIS_RERUN_PANEL, {analysisResultId}),
    [openSecondaryPanel]
  );

  const onRerunAnalysisResultWithOpportunity = useCallback(
    (analysisRes: AnalysisResult, onSuccess: (ar: AnalysisResult) => void) =>
      dispatch(
        rerunAnalysisResultWithOpportunitiesConfirmed(analysisRes.id, () => onSuccess(analysisRes))
      ),
    [dispatch]
  );

  const onRerunAnalysisResult = useCallback(
    (ar: AnalysisResult): any => {
      if (ar.opportunitiesCount) {
        const fallback_ = fallback && ((ar: AnalysisResult) => fallback(ar));
        return onRerunAnalysisResultWithOpportunity(ar, fallback_);
      }
      return onRerun_(ar.id);
    },
    [fallback, onRerunAnalysisResultWithOpportunity, onRerun_]
  );

  return {
    onRerun: onRerunAnalysisResult,
  };
}

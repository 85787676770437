import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import {modelCreated, notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {ToastType} from '../../objects/system/toast-type.enum';
import HttpClient from '../../services/http-client.service';
import {
  createFunnelAnalysisConversionFollowUpsNetworkRequest,
  createGoalDriversAnalysisImproveFeatureAdoptionFollowUpsNetworkRequest,
  createRCAFromLineChartFollowUpsNetworkRequest,
  createRetentionAnalysisInvestigateBucketFollowUpsNetworkRequest,
  postSegmentationExplainersFollowUpNetworkRequest,
} from '../../http/follow-ups.network-requests';
import {FollowUpActionsActionType} from './follow-up-actions.actions';
import {ModelKey} from '../../constants/model-key';
import {demoProductLimitedActionsFilter} from '../store.utils';

const defaultSharedFollowCallbacks = {
  onSuccess: (_, payload) => [
    showToastMessage(i18n.t(TransKeys.TOASTS.FOLLOW_UPS_CREATED_SUCCESS), ToastType.SUCCESS),
    notifyEvent(AmplitudeEvent.FOLLOW_UPS_CREATED_SUCCESS, payload),
    modelCreated({}, ModelKey.ANALYSIS_FOLLOWUP),
  ],
  onError: err => [
    showToastMessage(i18n.t(TransKeys.TOASTS.FOLLOW_UPS_CREATED_ERROR), ToastType.ERROR),
  ],
};

export const createRCAFromLineChartFollowUpActionsEpic: Epic = createRequestEpic(
  {
    types: [FollowUpActionsActionType.RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS,
    request: createRCAFromLineChartFollowUpsNetworkRequest,
    ...defaultSharedFollowCallbacks,
  },
  HttpClient
);

export const createFunnelAnalysisConversionFollowUpActionsEpic: Epic = createRequestEpic(
  {
    types: [FollowUpActionsActionType.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS,
    request: createFunnelAnalysisConversionFollowUpsNetworkRequest,
    ...defaultSharedFollowCallbacks,
  },
  HttpClient
);

export const createGoalAnalysisDriversAnalysisFollowUpActionsEpic: Epic = createRequestEpic(
  {
    types: [
      FollowUpActionsActionType.CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS,
    ],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS,
    request: createGoalDriversAnalysisImproveFeatureAdoptionFollowUpsNetworkRequest,
    ...defaultSharedFollowCallbacks,
  },
  HttpClient
);

export const createRetentionAnalysisInvestigateBucketFollowUpActionsEpic: Epic = createRequestEpic(
  {
    types: [
      FollowUpActionsActionType.CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS,
    ],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS,
    request: createRetentionAnalysisInvestigateBucketFollowUpsNetworkRequest,
    ...defaultSharedFollowCallbacks,
  },
  HttpClient
);

export const postSegmentationExplainersFollowUpActionsEpic: Epic = createRequestEpic(
  {
    types: [FollowUpActionsActionType.CREATE_RCA_FROM_SEGMENTATION_EXPLAINER_FOLLOW_UP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_RCA_FROM_SEGMENTATION_EXPLAINER,
    request: postSegmentationExplainersFollowUpNetworkRequest,
    onSuccess: (_, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.FOLLOW_UPS_CREATED_SUCCESS), ToastType.SUCCESS),
      notifyEvent(AmplitudeEvent.FOLLOW_UPS_CREATED_SUCCESS, payload),
      modelCreated({analysisResultId: payload.analysisResultId}, ModelKey.ANALYSIS_FOLLOWUP),
    ],
    onError: err => [
      showToastMessage(i18n.t(TransKeys.TOASTS.FOLLOW_UPS_CREATED_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {KPIsSelector} from '../../components/ui-selectors/kpis-selector/kpis-selector.component';
import {FeaturesSignalSelector} from '../../components/ui-selectors/features-signal-selector/features-selector.component';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {StatisticalSignificanceSelector} from '../../components/ui-selectors/statistical-significance-selector/statistical-significance-selector.component';
import {SingleDateSelector} from '../../components/ui-selectors/single-date-selector/single-date-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {
  AggregationMode,
  SignalDataType,
  SignalType,
} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {ReleaseImpactMeasurementTimelineSelector} from '../../components/ui-selectors/release-impact-measurement-timeline-selector/release-impact-measurement-timeline-selector.component.tsx';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component.tsx';
import {MultipleDatesSelector} from '../../components/ui-selectors/multiple-dates-selector/multiple-dates-selector.component.tsx';
import {SignalSmartSelectorKey} from '../../../../shared/core/smart-selector/signal-smart-selector.component.tsx';

export const releaseDateSelectorSchemaMapping = {
  date_parameter: 'release_date',
};
const gradualReleaseSelectorSchemaMapping = {
  gradual_release_query: 'gradual_release_query',
  gradual_versions: 'gradual_versions',
  pre_release_date: 'pre_release_date',
  post_release_date: 'post_release_date',
  days_before: 'days_before',
  max_days_from_release: 'max_days_from_release',
  measurement_timeline_mode: 'measurement_timeline_mode',
  release_date: releaseDateSelectorSchemaMapping.date_parameter,
};

export const kpiSelectorSchemaMapping = {
  primary_kpi: 'goal',
  secondary_kpis: 'secondary_goal',
};
const featuresSelectorSchemaMapping = {
  features: 'features',
};
const treatmentsSelectorMap = {
  treatments_tag: 'feature_usage_tag',
  treatments_signals: 'treatments_signals',
};
const statisticalSignificanceSchemaMapping = {
  statistical_significance: 'experiment_threshold',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const otherImportantDaysSchemaMapping = {
  parameter_key: 'additional_event_dates',
};

const EXCLUDE_TEMPLATES = ['dod', 'wow', 'mom', 'dau', 'wau', 'mau', 'l7', 'l28'];
export const createKPISelectorFiltersFor109 = (entityContext: TableEntity) => [
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entityBinding: TableEntityBinding.TWO_WAY,
    entityContext: entityContext,
    exclude_templates: EXCLUDE_TEMPLATES,
    with_population_filter: true,
  },
  {
    type: SignalType.MEASURE,
    data_type: [SignalDataType.DECIMAL, SignalDataType.INTEGER],
    entityBinding: TableEntityBinding.ONE_WAY,
    entityContext: entityContext,
    exclude_templates: EXCLUDE_TEMPLATES,
    aggregation_mode: [
      AggregationMode.SUM,
      AggregationMode.SUM_FROM_X,
      AggregationMode.SUM_FROM_TOTAL,
    ],
    with_population_filter: true,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entityBinding: TableEntityBinding.ONE_WAY,
    entityContext: entityContext,
    exclude_templates: EXCLUDE_TEMPLATES,
    exclude_tag: ['join_date'],
    with_population_filter: true,
  },
  {
    templates: ['bounded_action_nu', 'rate'],
    with_population_filter: true,
  },
];

const INCLUDE_SIGNAL_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.SIGNALS,
];

export const Analysis109Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {t} = useTranslation();
  const {productEntities, defaultTableEntity} = useProductData();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {[entitySchemaMapping.entity]: entityContext} = parameters;
  const kpiSignalFilters = useMemo(
    () => createKPISelectorFiltersFor109(entityContext),
    [entityContext]
  );
  useEffect(() => {
    registerDefaultHandler('analysis_109', parameters => {
      const defaults = {};
      defaults[statisticalSignificanceSchemaMapping.statistical_significance] = 0.9;
      defaults[gradualReleaseSelectorSchemaMapping.days_before] = 28;
      defaults[gradualReleaseSelectorSchemaMapping.max_days_from_release] = 28;
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[treatmentsSelectorMap.treatments_tag] = 'feature_usage';
      return defaults;
    });
    return () => {
      removeDefaultHandler('analysis_109');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);
  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        kpiSelectorSchemaMapping.primary_kpi,
        kpiSelectorSchemaMapping.secondary_kpis,
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, []);
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <SingleDateSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.HELPER_TEXT)}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={releaseDateSelectorSchemaMapping}
        allowFutureDates={false}
      />
      <ReleaseImpactMeasurementTimelineSelector
        onChange={changeParametersValue}
        schemaKeysMapping={gradualReleaseSelectorSchemaMapping}
        value={parameters}
        errors={errors}
        entityContext={entityContext}
        className={classes.Parameter}
      />
      <KPIsSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={kpiSelectorSchemaMapping}
        filters={kpiSignalFilters}
        errors={errors}
        onSignalInfo={onSignalInfo}
        include={INCLUDE_SIGNAL_SELECTOR}
      />
      <FeaturesSignalSelector
        value={parameters}
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.HELPER_TEXT)}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={featuresSelectorSchemaMapping}
        errors={errors}
        onSignalInfo={onSignalInfo}
      />
      <StatisticalSignificanceSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={statisticalSignificanceSchemaMapping}
        errors={errors}
        className={classes.Parameter}
      />
      {entityContext && (
        <SegmentFilterSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.HELPER_TEXT)}
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <MultipleDatesSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.ADDITIONAL_EVENT_DATES_SELECTOR.TITLE)}
          subTitle={t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_109.ADDITIONAL_EVENT_DATES_SELECTOR.SUBTITLE
          )}
          value={parameters}
          onChange={changeParametersValue}
          className={classes.Parameter}
          schemaKeysMapping={otherImportantDaysSchemaMapping}
          errors={errors}
        />
        <TreatmentSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.TREATMENT_SELECTOR.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.TREATMENT_SELECTOR.SUBTITLE)}
          value={parameters}
          onChange={changeParametersValue}
          className={classes.Parameter}
          schemaKeysMapping={treatmentsSelectorMap}
          errors={errors}
          entityContext={entityContext}
        />
      </ExtendedParameters>
    </div>
  );
};

import classNames from 'classnames';
import classes from './time-granularity-selector.module.scss';
import {useCallback, useMemo} from 'react';
import {Select} from 'ui-components';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {hasErrors} from '../../../../../../utils/general.utils';
import {values} from 'lodash';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {TIME_UNIT_OPTIONS} from '../../../../../../constants/options';

const OPTIONS = TIME_UNIT_OPTIONS.options;

interface SchemaKeysMapping {
  time_granularity: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  time_granularity: 'time_granularity',
};

interface OwnProps {
  title?: string;
  onChange: (parameters: any) => void;
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  subTitle?: string;
  helperText?: string;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export const TimeGranularitySelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    onChange,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    value: data,
    className,
  } = props;
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const value = useMemo(
    () => data[schemaKeysMapping.time_granularity],
    [data, schemaKeysMapping.time_granularity]
  );
  const options = useMemo(() => ({options: OPTIONS}), []);
  const onOptionSelected = useCallback(
    (value: string) => onChange({[schemaKeysMapping.time_granularity]: value}),
    [schemaKeysMapping, onChange]
  );

  return (
    <ParameterInputWrapper
      title={
        title === undefined ? t(TransKeys.ANALYSIS_FORMS.SHARED.TIME_GRANULARITY.TITLE) : title
      }
      subTitle={
        subTitle === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.TIME_GRANULARITY.SUB_TITLE)
          : subTitle
      }
      className={classNames(classes.FeatureInteractionSelector, className)}
      helperText={helperText}
      error={hasError}
    >
      <div className={classes.Row}>
        <Select
          value={value}
          onChange={onOptionSelected as any}
          options={options}
          searchable={false}
          sortValues={false}
          clearable={false}
          capitalize={false}
        />
      </div>
    </ParameterInputWrapper>
  );
};

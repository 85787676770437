import * as React from 'react';
import {useCallback, useLayoutEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import TransKeys from 'translations';
import {useDocumentTracking} from './use-document-tracking.hook';
import {DocumentElementType} from '../types';
import classes from '../components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.module.scss';
import {useDocumentTranslation} from './use-document-translation.hook';
import {LoopsIcon} from '../../../simple/controls/icons/icons.component';
import {ToggleSwitch} from '../../../forms/inputs/toggle-switch/toggle-switch.component';
import {useDocQuery} from './use-doc-query.hook';
import classNames from 'classnames';
import {Chip, ChipVariant} from '../../../simple/data-display/chip/chip.component';
import {Tooltip} from '@material-ui/core';
import {PendoAnchors, usePendoAnchor} from '../../../../hooks/use-pendo-anchor.hook';
import {ANALYSIS_CONTENT_TITLE_CTA_ELEMENT_ID} from '../components/base-viewers/analysis-figure-viewer/components/analysis-content-title/analysis-content-title.component';

interface UseSimulatorModeProps {
  figureId: string;
  elementType: DocumentElementType;
  disabled?: boolean;
  disabledTooltip?: string;
  label?: string;
  isCasual?: boolean;
  afterModeChange?: (mode: SimulationMode) => void;
  helperText?: string;
  queryPrefix?: string;
}

export enum SimulationMode {
  DATA = 'data',
  SIMULATOR = 'simulator',
}

export const useSimulatorMode = (props: UseSimulatorModeProps) => {
  const {t} = useDocumentTranslation();
  const {
    figureId,
    elementType,
    disabled,
    disabledTooltip,
    helperText,
    isCasual,
    label = t(TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.MODES.SIMULATOR_MODE),
    afterModeChange,
    queryPrefix,
  } = props;
  const ref = usePendoAnchor(PendoAnchors.SIMULATOR_MODE_SWITCH);
  const {trackModeChanged} = useDocumentTracking(figureId, elementType);
  const {query: mode, setQuery: setMode} = useDocQuery<SimulationMode>(
    'simulator_mode',
    SimulationMode.DATA,
    queryPrefix
  );
  const [anchor, setAnchor] = useState<HTMLDivElement>(null);
  const onModeChange = useCallback(
    (mode: SimulationMode) => {
      setMode(mode);
      trackModeChanged(mode);
      afterModeChange && afterModeChange(mode);
    },
    [setMode, trackModeChanged, afterModeChange]
  );
  const tooltipTitle = disabled ? disabledTooltip || '' : helperText || '';

  useLayoutEffect(() => {
    setAnchor(document.getElementById(ANALYSIS_CONTENT_TITLE_CTA_ELEMENT_ID) as HTMLDivElement);
  }, [setAnchor]);

  return {
    mode,
    onModeChange,
    button: anchor
      ? (createPortal(
          <Tooltip title={tooltipTitle} placement={'top'} interactive={false}>
            <div
              className={classNames(
                classes.SimulatorSwitch,
                disabled && classes.Disabled,
                mode === SimulationMode.SIMULATOR && classes.Active
              )}
              ref={ref}
            >
              <ToggleSwitch
                value={mode === SimulationMode.SIMULATOR}
                onChange={v => onModeChange(v ? SimulationMode.SIMULATOR : SimulationMode.DATA)}
                label={label}
                disabled={disabled}
                icon={LoopsIcon}
              />
              {isCasual && (
                <Chip
                  label={t(TransKeys.DOCUMENT_VIEWER.LABELS.CAUSAL)}
                  variant={ChipVariant.CAUSAL}
                  className={classes.Chip}
                  size={'xsmall'}
                  bold
                />
              )}
            </div>
          </Tooltip>,
          anchor
        ) as any)
      : null,
  };
};

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {UsersActionType} from './users.actions';
import {inviteUsersNetworkRequest} from '../../http/users.network-requests';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {errorExtractor} from '../store.utils';

export const inviteUsersEpic: Epic = createRequestEpic(
  {
    types: [UsersActionType.INVITE_USERS],
    actionKey: ActionKey.INVITE_USERS,
    request: inviteUsersNetworkRequest,
    onSuccess: (_, payload) => [
      showToastMessage(i18n.t(TransKeys.TOASTS.INVITE_USERS_SUCCESS), ToastType.SUCCESS),
      notifyEvent(AmplitudeEvent.INVITE_USERS_SUCCESS, {
        emails: payload.emails,
      }),
    ],
    onError: (err_, payload) => {
      const err = errorExtractor(err_);
      if (err.errorCode === 1001) {
        return [
          showToastMessage(err.message, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.INVITE_USERS_ERROR, {
            emails: payload.emails,
          }),
        ];
      }
      return [
        showToastMessage(i18n.t(TransKeys.TOASTS.INVITE_USERS_ERROR), ToastType.ERROR),
        notifyEvent(AmplitudeEvent.INVITE_USERS_ERROR, {
          emails: payload.emails,
        }),
      ];
    },
  },
  HttpClient
);

import classes from '../app-navigator.module.scss';
import {AppNavigatorLinkItem} from '../app-navigator.component';
import classNames from 'classnames';
import {Badge} from 'ui-components';
import {Tooltip} from '@material-ui/core';
import React from 'react';
import {BetterNavLink} from '../../../../shared/core/override/better-nav-link.component';

export interface OwnProps extends AppNavigatorLinkItem {
  collapsed: boolean;
}

type AllProps = OwnProps;

const AppNavigatorLink = (props: AllProps) => {
  const {label, disabled, route, count, icon: Icon, collapsed} = props;
  const displayBadge = count !== undefined && count > 0;

  return (
    <Tooltip title={collapsed ? label : ''} placement={'right'}>
      <div>
        <BetterNavLink
          className={classNames(classes.NavItem, disabled && classes.Disabled)}
          activeClassName={classes.Active}
          onClick={e => {
            if (disabled) {
              e.preventDefault();
            }
          }}
          to={route}
        >
          <Badge badgeContent={count || 0} invisible={!collapsed}>
            <Icon className={classes.Icon} />
          </Badge>
          <span className={classes.Label}>{label}</span>
          {displayBadge && (
            <span className={classes.LabelBadge}>
              <span className={classes.BadgeCounter}>{count}</span>
            </span>
          )}
        </BetterNavLink>
      </div>
    </Tooltip>
  );
};

export default AppNavigatorLink;

import {useTranslation} from 'react-i18next';
import classes from './metric-info-tab.module.scss';
import yup from '../../../../../../config/yup.config';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {sharedClasses} from '../../../../../shared';
import {Button} from 'ui-components';
import TransKeys from 'translations';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../../../shared/form/components/shared-form-input.component';
import classNames from 'classnames';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {MonitoredMetricDTO} from '../../../../../../objects/dto/health-monitor.dto';

interface OwnProps {
  onSubmit: (data: Partial<MonitoredMetricDTO>) => void;
  onBack?: () => void;
  data: Partial<MonitoredMetricDTO>;
  submitText?: string;
  className?: string;
}

type AllProps = OwnProps;

const validator = yup.object().shape({
  name: yup.string().required(),
  shortDescription: yup.string().nullable(),
});

export const MetricInfoTab = (props: AllProps) => {
  const {data, onSubmit, onBack, submitText = 'Save', className} = props;
  const {t} = useTranslation();

  const formMethods = useForm({
    defaultValues: data as any,
    resolver: yupResolver(validator.noUnknown()),
  });
  const {handleSubmit} = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        onKeyDown={preventSubmitOnEnter}
        className={classNames(sharedClasses.NoOverflow, className)}
      >
        <FormStep
          footer={
            <>
              {onBack && (
                <Button variant={'outlined'} type={'button'} onClick={onBack}>
                  {t(TransKeys.GENERAL.ACTIONS.BACK)}
                </Button>
              )}
              <Button onClick={handleSubmit(onSubmit)}>{submitText}</Button>
            </>
          }
        >
          <div className={classes.AnalysisInfoTab}>
            <TabHeader
              className={classes.Header}
              title={t(TransKeys.CREATE_MONITORED_METRIC_FORM.STEPS.METRIC_INFO_TITLE)}
              subTitle={t(TransKeys.CREATE_MONITORED_METRIC_FORM.STEPS.METRIC_INFO_SUBTITLE)}
            />
            <div className={classes.Block}>
              <TextFormInput
                required
                placeholder={t(
                  TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_INFO.NAME.PLACEHOLDER
                )}
                label={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_INFO.NAME.LABEL)}
                name={'name'}
              />
            </div>
            <div className={classes.Block}>
              <TextareaFormInput
                placeholder={t(
                  TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_INFO.NOTES.PLACEHOLDER
                )}
                label={t(TransKeys.CREATE_MONITORED_METRIC_FORM.TABS.METRIC_INFO.NOTES.LABEL)}
                name={'shortDescription'}
              />
            </div>
          </div>
        </FormStep>
      </form>
    </FormProvider>
  );
};

import {Button, ModalLayout, PartyHornIcon, Select, UsersDuotoneIcon} from 'ui-components';
import {useDefaultAnalysisFolderId} from '../../../../analyses/hooks/use-default-analysis-folder-id.hook';
import classes from './rca-segmentation-follow-up-panel.module.scss';
import {useCallback, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {createRCAFromSegmentationExplainerFollowUpActions} from '../../../../../store/follow-up-actions/follow-up-actions.actions';
import {useDispatch} from 'react-redux';
import {useAmplitude} from '../../../../../core/hooks/amplitude.hook';
import {AnalysisFolderSmartSelector} from '../../../core/smart-selector/analysis-folder-smart-selector.component';
import {withModalInactiveSourceHandler} from '../../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {composition, toSnakeCase} from 'front-core';
import {
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_RESULT_ID_PATH_PARAM,
  ANALYSIS_RUN_PARAMETERS_KEY,
  ANALYSIS_TYPE_ID_PATH_PARAM,
} from '../../../../../constants/app-routes';
import {withLoadBefore} from '../../../../../core/hoc/with-load-before.hoc';
import {getSegmentationExplainersFollowUpAnalaysisDataNetworkRequest} from '../../../../../http/follow-ups.network-requests';
import classNames from 'classnames';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../constants/panels';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import {FOLLOW_UP_ANALYSIS_RESULT_ID_PARAM} from '../../../../analyses/panels/analysis-form-panel/analysis-form-panel.component';
import {AmplitudeEvent} from '../../../../../constants/amplitude-event';
import {formatDateRange} from '../../../../insights/pages/subscription-report-page/subscription-report-page.utils';
import {SegmentFilter} from '../../../../../objects/models/analysis-result.model';
import {GenericLoading} from '../../../components/general/generic-loading/generic-loading.component';

type FollowUpSegmentData = {
  [ANALYSIS_RESULT_ID_PATH_PARAM]: string;
  segmentationExplainersFollowUpData?: {
    parameters: any;
    segmentClassOptionLabels?: string[];
    runParameters: any;
    metricName: string;
    segmentGroupName?: string;
    compositeLabel?: string;
  };
  selectedSegmentFilters: SegmentFilter[];
};

interface AllProps extends FollowUpSegmentData {
  onClose: () => void;
}

export const RCASegmentationFollowUpPanelComponent = (props: AllProps) => {
  const {
    onClose,
    selectedSegmentFilters,
    [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
    segmentationExplainersFollowUpData: {
      metricName,
      parameters,
      runParameters,
      segmentClassOptionLabels,
      segmentGroupName,
      compositeLabel,
    } = {},
  } = props;

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const analysisFolderId = useDefaultAnalysisFolderId();
  const [followupAnalysisFolderId, setFollowupAnalysisFolderId] =
    useState<number>(analysisFolderId);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedSegmentClass, setSelectedSegmentClass] = useState(() => {
    if (selectedSegmentFilters.length === 1) {
      return segmentClassOptionLabels.find(
        segmentClass => segmentClass === selectedSegmentFilters[0].classes[0]
      );
    }
    return undefined;
  });

  const isComposite = selectedSegmentFilters.length > 1;

  const title = useMemo(() => {
    return t(TransKeys.FOLLOW_UP_ANALYSIS.RCA_SEGMENTATION.TITLE, {
      kpiName: metricName,
      fromToLabel: formatDateRange(runParameters.startDateAnomaly, parameters.timeAggregation),
    });
  }, [t, metricName, runParameters, parameters]);

  const onSelectSegmentClass = useCallback(
    (newSelectedSegmentClass: string) => {
      const result = segmentClassOptionLabels.find(
        segmentClass => segmentClass === newSelectedSegmentClass
      );
      if (result) {
        setSelectedSegmentClass(result);
      }
    },
    [segmentClassOptionLabels]
  );

  const onFollowupRcaSegmentationExplainers = useCallback(() => {
    notify(AmplitudeEvent.USER_QUICK_CREATED_FOLLOW_UP_FOR_RCA_SEGMENTATION);

    const segmentFilters = isComposite
      ? selectedSegmentFilters
      : [
          {
            signalId: selectedSegmentFilters[0].signalId,
            classes: [selectedSegmentClass],
          },
        ];

    setIsSubmitting(true);
    dispatch(
      createRCAFromSegmentationExplainerFollowUpActions(
        {
          analysisResultId,
          segmentFilters,
          analysisFolderId: followupAnalysisFolderId,
        },
        () => {
          setShowSuccessMessage(true);
          setIsSubmitting(false);
        },
        () => {
          setIsSubmitting(false);
        }
      )
    );
  }, [
    notify,
    isComposite,
    selectedSegmentFilters,
    selectedSegmentClass,
    dispatch,
    analysisResultId,
    followupAnalysisFolderId,
  ]);

  const onEditFullAnalysisSettings = useCallback(() => {
    openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RCA_ANALYSIS,
      [ANALYSIS_PARAMETERS_KEY]: toSnakeCase(parameters),
      [ANALYSIS_RUN_PARAMETERS_KEY]: toSnakeCase(runParameters),
      [FOLLOW_UP_ANALYSIS_RESULT_ID_PARAM]: analysisResultId,
    });
    onClose();
  }, [onClose, openPrimaryPanel, parameters, runParameters, analysisResultId]);

  const footer = (
    <div
      className={classNames(classes.Footer, {
        [classes.FooterSuccess]: showSuccessMessage,
      })}
    >
      {showSuccessMessage ? (
        <Button onClick={onClose}>{t(TransKeys.GENERAL.LABELS.DONE)}</Button>
      ) : (
        <>
          <AnalysisFolderSmartSelector
            disabled={isSubmitting}
            value={followupAnalysisFolderId}
            onChange={(analysisFolderId: number) => setFollowupAnalysisFolderId(analysisFolderId)}
            defaultFolderId={analysisFolderId}
          />
          <Button disabled={isSubmitting} onClick={onFollowupRcaSegmentationExplainers}>
            {t(TransKeys.GENERAL.ACTIONS.RUN)}
          </Button>
        </>
      )}
    </div>
  );

  const renderContent = () => {
    if (showSuccessMessage) {
      return (
        <div className={classes.AnalysisRunningMessageContainer}>
          <PartyHornIcon className={classes.SuccessIcon} />
          <div className={classes.Title}>
            {t(TransKeys.FOLLOW_UP_ANALYSIS.RCA_SEGMENTATION.SUCCESS_MESSAGE.TITLE)}
          </div>
          <div className={classes.SubTitle}>
            {t(TransKeys.FOLLOW_UP_ANALYSIS.RCA_SEGMENTATION.SUCCESS_MESSAGE.SUB_TITLE)}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={classes.Header}>
          <div className={classes.TopIconContainer}>
            <UsersDuotoneIcon className={classes.Icon} />
          </div>
          <div className={classes.Title}>{title}</div>
        </div>
        {isSubmitting && <GenericLoading />}
        <div className={classes.ContentWrapper}>
          <div className={classes.Label}>For Segment</div>
          {isComposite ? (
            <div className={classes.Label}>{compositeLabel}</div>
          ) : (
            <Select
              fitContent
              disabled={isSubmitting}
              clearable={false}
              value={selectedSegmentClass}
              options={{
                options: segmentClassOptionLabels.map(segmentClass => ({
                  label: `${segmentGroupName} > ${segmentClass}`,
                  value: segmentClass,
                })),
              }}
              onChange={onSelectSegmentClass}
            />
          )}
          <div
            className={classes.EditFullAnalysisBtn}
            onClick={!isSubmitting ? onEditFullAnalysisSettings : null}
          >
            {t(TransKeys.FOLLOW_UP_ANALYSIS.RCA_SEGMENTATION.EDIT_FULL_ANALYSIS_SETTINGS)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.Panel}>
      <ModalLayout footer={footer} className={classes.Panel}>
        {renderContent()}
      </ModalLayout>
    </div>
  );
};

const createSelectedKey = (key: string) => `RCA_SEGMENTATION_FOLLOW_UP_PANEL/${key}`;

export const RCASegmentationFollowUpPanel = composition<AllProps>(
  RCASegmentationFollowUpPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore<AllProps>({
    segmentationExplainersFollowUpData: {
      selectedKey: createSelectedKey('DATA'),
      actionKey: createSelectedKey('DATA'),
      request: getSegmentationExplainersFollowUpAnalaysisDataNetworkRequest,
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
      mapPayloadFromProps: props => ({
        analysisResultId: props[ANALYSIS_RESULT_ID_PATH_PARAM],
        segmentFilters: props.selectedSegmentFilters,
      }),
    },
  })
);

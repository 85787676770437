import {useTranslation} from 'react-i18next';
import classes from './experiment-subscription-tab.module.scss';
import yup from '../../../../../../config/yup.config';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TransKeys from 'translations';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import classNames from 'classnames';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {Experiment} from '../../../../../../objects/models/experiment.model';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {sharedClasses} from '../../../../../shared';
import {Button, LabelWrapper} from 'ui-components';
import CronExpGroupControl from '../../../../../shared/components/general/cron-panel-form/cron-exp-group-control/cron-exp-group-control.component';
import {CRON_DEFAULT_VALUES} from '../../../../../../constants/cron-exp.consts';
import {useCallback, useMemo} from 'react';
import {searchUsersNetworkRequest} from '../../../../../../http/users.network-requests';
import {MultiSelectInput} from '../../../../../shared/form/components/shared-form-input.component';
import {StandardCheckBox} from '../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';

interface OwnProps {
  onSubmit: (data: Partial<Experiment>) => void;
  onBack?: () => void;
  data: Partial<Experiment>;
  submitText?: string;
  className?: string;
}

type AllProps = OwnProps;

const validator = yup.object().shape({
  subscribeUser: yup.boolean().required(),
  subscribers: yup.array().of(yup.number()).nullable(),
  cronExp: yup.string().required(),
});

export const ExperimentSubscriptionTab = (props: AllProps) => {
  const {data, onSubmit, onBack, submitText, className} = props;
  const {t} = useTranslation();
  const formMethods = useForm({
    defaultValues: {
      subscribeUser: true,
      cronExp: CRON_DEFAULT_VALUES.WEEKLY,
      subscribers: [],
      ...(data as any),
    },
    resolver: yupResolver(validator),
  });
  const {handleSubmit, setValue, watch} = formMethods;
  const subscribeUser = watch('subscribeUser');

  const subscribersOptionsProp = useMemo(
    () => ({
      networkRequest: searchUsersNetworkRequest,
      labelAttributePath: 'fullName',
      valueAttributePath: 'id',
      requestPayload: {limit: 1000},
    }),
    []
  );

  const onSubscribeUser = useCallback(
    () => setValue('subscribeUser', !subscribeUser),
    [setValue, subscribeUser]
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onKeyDown={preventSubmitOnEnter}
        className={classNames(sharedClasses.NoOverflow, className)}
      >
        <FormStep
          footer={
            <>
              {onBack && (
                <Button variant={'outlined'} type={'button'} onClick={onBack}>
                  {t(TransKeys.GENERAL.ACTIONS.BACK)}
                </Button>
              )}
              <Button onClick={handleSubmit(onSubmit)}>{submitText}</Button>
            </>
          }
        >
          <div className={classes.ExperimentSubscriptionTab}>
            <TabHeader
              className={classes.Header}
              title={t(TransKeys.GENERAL.ACTIONS.SUBSCRIBE)}
              subTitle={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.SUBTITLE)}
            />
            <div className={classes.Block}>
              <div className={classes.Subscribers}>
                <ParameterInputWrapper
                  subTitle={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.WHO.SUBTITLE)}
                  title={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.WHO.TITLE)}
                >
                  <div className={classes.SubscribersInputs}>
                    <LabelWrapper label={''}>
                      <div className={classes.InlineCheckbox}>
                        <StandardCheckBox checked={subscribeUser} onChange={onSubscribeUser} />
                        <div className={classes.Label}>
                          {t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.SUBSCRIBE_ME)}
                        </div>
                      </div>
                    </LabelWrapper>
                    <LabelWrapper
                      label={t(
                        TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.SUBSCRIBE_OTHERS
                      )}
                    >
                      <MultiSelectInput
                        label={''}
                        name={'subscribers'}
                        options={subscribersOptionsProp}
                        placeholder={t(TransKeys.SHARE_RESOURCE_FORM.INPUTS.RECIPIENTS.PLACEHOLDER)}
                      />
                    </LabelWrapper>
                  </div>
                </ParameterInputWrapper>
                <ParameterInputWrapper
                  subTitle={t(
                    TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.WHEN.SUBTITLE
                  )}
                  title={t(TransKeys.EXPERIMENT_FORM.STEPS.EXPERIMENT_SUBSCRIPTION.WHEN.TITLE)}
                >
                  <Controller
                    name={'cronExp'}
                    render={({field}) => (
                      <CronExpGroupControl
                        value={subscribeUser ? field.value : ''}
                        onChange={field.onChange}
                        showWeekly
                        showDaily
                        className={classes.CronSchedule}
                      />
                    )}
                  />
                </ParameterInputWrapper>
              </div>
            </div>
          </div>
        </FormStep>
      </form>
    </FormProvider>
  );
};

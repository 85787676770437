import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './homepage-summary.module.scss';
import {HomepageModel} from '../../../../objects/models/homepage.model';
import {
  Button,
  CampaignIcon,
  CircleInfoLightIcon,
  KPIIcon,
  PlusLightIcon,
  useLocalStorage,
  useRemoteSourceStated,
} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';
import {useDispatch} from 'react-redux';
import {
  addModelToHomepage,
  removeModelFromHomepage,
  reorderHomepage,
} from '../../../../store/homepage/homepage.actions';
import {
  AppRoutes,
  CATEGORY_ID_PATH_PARAM,
  GOAL_ID_PATH_PARAM,
  HOMEPAGE_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
  TEAM_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {PanelKey} from '../../../../constants/panels';
import {CoreActionsType, notifyEvent} from '../../../../store/core/core.actions';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {getHomepageDataNetworkRequest} from '../../../../http/homepage.network-requests';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {Metric} from '../../../../objects/models/metric.model';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {HomepageList} from './components/homepage-list/homepage-list.component';
import {HomepageMetricViewer} from './components/homepage-model-samples-viewer/homepage-metric-viewer/homepage-metric-viewer.component';
import {exists} from 'front-core';
import {NumberParam, StringParam, useQueryParam} from 'use-query-params';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {LocalStorageKey} from '../../../../constants/local-storage-key';
import {TeamFilterProvider} from '../../../../core/contexts/team-filter.context';
import {useNavigate} from 'react-router';
import {Funnel} from '../../../../objects/models/funnel.model';
import {HomepageFunnelViewer} from './components/homepage-model-samples-viewer/homepage-funnel-viewer/homepage-funnel-viewer.component';
import {transformModelName} from './homepage-summary.utils';
import {HomepageSelector} from './components/homepage-selector/homepage-selector.component';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {debounceTime, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const ACTIONS = [
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_DELETED,
];

const MODELS = [
  ModelKey.HOMEPAGE,
  ModelKey.USER_SETTINGS,
  ModelKey.ANNOTATION,
  ModelKey.GOAL,
  ModelKey.HOMEPAGE_SUBCRIPTION,
];
type ListenerParameters = {
  homepageId: number;
  searchValue: string;
};
type HomepageTriggerData = {
  homepageId: number;
  filters?: {q: string};
};
const HOMEPAGE_SUBSCRIBE_BUTTON_FAKE_CLASS = 'homepage-subscribe-button';
const homepageTriggerDebouncedSubject = new Subject<HomepageTriggerData>();
const homepageTriggerSubject = new Subject<HomepageTriggerData>();
const DEBOUNCE_TIME = 250;

export const HomepageSummary = (props: AllProps) => {
  const {className} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useAmplitude();
  const listenerParametersRef = useRef<ListenerParameters>(null);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {productId, defaultHomepageId, actualTeams, defaultSource, userSettings} = useProductData();
  const [selectedHomepageId, setSelectedHomepageId] = useLocalStorage<number>(
    `${productId}_${LocalStorageKey.SELECTED_HOMEPAGE_ID}`,
    defaultHomepageId
  );
  const {
    source: homepageData,
    exec: getHomepageDataExec,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageDataNetworkRequest,
    initialValue: {},
  });
  const {
    items: itemsFromData = [],
    homepageItems = [],
    lastUpdate,
    isUserSubscribed,
  } = homepageData;
  const items = useMemo(
    () =>
      itemsFromData.map(i => ({
        ...i,
        name: transformModelName(i),
      })),
    [itemsFromData]
  );
  const addMetricButtonRef = useRef<any>(null);
  const [selectedModelId, setSelectedModelId] = useQueryParam('kpi', NumberParam);
  const [selectedModelType, setSelectedModelType] = useQueryParam<ModelSampleSeriesModel>(
    'type',
    StringParam as any
  );
  const [searchValue, setSearchValue] = useState('');
  const showSubscription = useFeatureIsOn(FeatureFlag.HOMEPAGE_SUBSCRIPTION as string);
  const teamId = useMemo(
    () => actualTeams.find(t => t.homepageId === selectedHomepageId)?.id || null,
    [actualTeams, selectedHomepageId]
  );

  const onSelectItem = useCallback(
    (item: Partial<HomepageModel>) => {
      setSelectedModelId(item.id);
      setSelectedModelType(item.modelType);
    },
    [setSelectedModelId, setSelectedModelType]
  );
  const reviewedModel = useMemo(
    () =>
      items.find(item => item.id === selectedModelId && item.modelType === selectedModelType) ||
      items[0],
    [items, selectedModelId, selectedModelType]
  );
  const onAddModel = useCallback(
    (modelId: number, modelType: ModelSampleSeriesModel) =>
      dispatch(
        addModelToHomepage({
          modelId,
          modelType,
          homepageId: selectedHomepageId,
        })
      ),
    [dispatch, selectedHomepageId]
  );
  const onRemoveModel = useCallback(
    (modelId: number, modelType: ModelSampleSeriesModel) =>
      dispatch(
        removeModelFromHomepage({
          modelId,
          modelType,
          homepageId: selectedHomepageId,
        })
      ),
    [dispatch, selectedHomepageId]
  );
  const onAddAnnotation = useCallback(
    () => openSecondaryPanel(PanelKey.ANNOTATION_FORM_PANEL),
    [openSecondaryPanel]
  );
  const onCreateEditGoal = useCallback(
    (metricId: number, goalId?: number) => {
      openSecondaryPanel(PanelKey.GOAL_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
        [GOAL_ID_PATH_PARAM]: goalId,
      });
      dispatch(
        notifyEvent(AmplitudeEvent.GOAL_MODAL_TRIGGERED_FROM_HOMEPAGE, {
          metric_id: metricId,
        })
      );
    },
    [openSecondaryPanel, dispatch]
  );
  const onCreateMetric = useCallback(
    categoryId => {
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [CATEGORY_ID_PATH_PARAM]: categoryId,
        onSuccess: (metric: Metric) => onAddModel(metric.id, ModelSampleSeriesModel.METRIC),
      });
    },
    [onAddModel, openSecondaryPanel]
  );
  const onCreateFunnel = useCallback(() => {
    openSecondaryPanel(PanelKey.FUNNEL_FORM_PANEL, {
      onSuccess: (funnel: Funnel) => onAddModel(funnel.id, ModelSampleSeriesModel.FUNNEL),
    });
  }, [onAddModel, openSecondaryPanel]);
  const onReorderMetrics = useCallback(
    pivotIds =>
      dispatch(
        reorderHomepage({
          homepageId: selectedHomepageId,
          pivotIds,
        })
      ),
    [dispatch, selectedHomepageId]
  );
  const onEditHomepageSubscription = useCallback(() => {
    notify(AmplitudeEvent.USER_OPENED_HOMEPAGE_SUBSCRIPTIONS, {homepageId: selectedHomepageId});
    openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL, {
      [HOMEPAGE_ID_PATH_PARAM]: selectedHomepageId,
    });
  }, [notify, openSecondaryPanel, selectedHomepageId]);
  const onSearchValueChange = useCallback(
    (term: string) => {
      setSelectedModelId(null);
      setSelectedModelType(null);
      setSearchValue(term);
    },
    [setSearchValue, setSelectedModelId, setSelectedModelType]
  );
  const onViewMetric = useCallback(
    (metricId: number) =>
      navigate(
        AppRoutes.viewMetric(metricId, {
          [TEAM_ID_PATH_PARAM]: teamId,
        })
      ),
    [navigate, teamId]
  );
  const onViewFunnel = useCallback(
    (funnelId: number) =>
      navigate(
        AppRoutes.viewFunnel(funnelId, {
          [TEAM_ID_PATH_PARAM]: teamId,
        })
      ),
    [navigate, teamId]
  );
  const onViewSegmentation = useCallback(
    (metricId: number) => {
      openSecondaryPanel(PanelKey.METRIC_SEGMENTATION_OVERTIME_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
      });
      dispatch(
        notifyEvent(AmplitudeEvent.SEGMENTATION_MODAL_TRIGGERED_FROM_HOMEPAGE, {
          metric_id: metricId,
        })
      );
    },
    [openSecondaryPanel, dispatch]
  );
  const lastUpdateText = useMemo(() => {
    if (!exists(lastUpdate)) {
      return;
    }
    const lastUpdateAgo = moment.utc(lastUpdate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).fromNow();
    let text = `Updated ${lastUpdateAgo}`;
    if (defaultSource.daysDelayed) {
      text += ` (delayed ${defaultSource.daysDelayed} day(s))`;
    }
    return text;
  }, [lastUpdate, defaultSource]);

  useEffect(() => {
    if (!items || exists(selectedModelId)) {
      return;
    }
    items[0] && onSelectItem(items[0]);
  }, [items, selectedModelId, onSelectItem]);

  // We use ref to store the network request parameters to avoid re-trigger effects
  listenerParametersRef.current = {
    homepageId: selectedHomepageId,
    searchValue,
  };
  // listen to store actions to trigger homepage data fetch
  useEffect(() => {
    const listener = async action => {
      const {modelKey} = action.payload;
      if (MODELS.indexOf(modelKey) === -1) {
        return;
      }
      switch (modelKey) {
        case ModelKey.HOMEPAGE:
          homepageTriggerSubject.next({
            homepageId: listenerParametersRef.current.homepageId,
            filters: {q: listenerParametersRef.current.searchValue},
          });
          if (action.type === CoreActionsType.MODEL_CREATED) {
            setSelectedModelId(action.payload.data?.modelId);
            setSelectedModelType(action.payload.data?.modelType);
          }
          if (action.type === CoreActionsType.MODEL_DELETED) {
            setSelectedModelId(null);
            setSelectedModelType(null);
          }
          break;
        case ModelKey.USER_SETTINGS:
        case ModelKey.ANNOTATION:
        case ModelKey.HOMEPAGE_SUBCRIPTION:
        case ModelKey.GOAL:
          homepageTriggerSubject.next({
            homepageId: listenerParametersRef.current.homepageId,
            filters: {q: listenerParametersRef.current.searchValue},
          });
          break;
      }
    };
    dispatch(registerActionListener(ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(ACTIONS, listener));
    };
  }, [dispatch, setSelectedModelId, setSelectedModelType]);
  // Subjects subscriptions
  useEffect(() => {
    const debouncedSubs = homepageTriggerDebouncedSubject
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        switchMap(data => getHomepageDataExec(data.homepageId, data.filters))
      )
      .subscribe();
    const regularSubs = homepageTriggerSubject
      .pipe(switchMap(data => getHomepageDataExec(data.homepageId, data.filters)))
      .subscribe();
    return () => {
      debouncedSubs.unsubscribe();
      regularSubs.unsubscribe();
    };
  }, [getHomepageDataExec]);
  // trigger get homepage on search value change
  useEffect(() => {
    homepageTriggerDebouncedSubject.next({
      homepageId: listenerParametersRef.current.homepageId,
      filters: {q: searchValue},
    });
  }, [searchValue]);
  // trigger get homepage on homepage change
  useEffect(() => {
    homepageTriggerSubject.next({
      homepageId: selectedHomepageId,
    });
  }, [selectedHomepageId]);

  return (
    <TeamFilterProvider teamId={teamId}>
      <div className={classNames(classes.HomepageSummary, className)}>
        <div className={classes.Header}>
          <div className={classes.TitleWrapper}>
            <KPIIcon className={classes.Icon} />
            <div className={classes.Title}>
              <span>{t(TransKeys.HOMEPAGE.SECTIONS.TOP_METRICS.TITLE)}</span>
            </div>
            {
              <HomepageSelector
                selectedHomepageId={selectedHomepageId}
                onChange={setSelectedHomepageId}
              />
            }
          </div>
          <div className={classes.Actions}>
            <Button
              onClick={onAddAnnotation}
              variant={'outlined'}
              icon={PlusLightIcon}
              helperText={t(TransKeys.HOMEPAGE.ACTIONS.ADD_ANNOTATION_TOOLTIP)}
              className={classes.AddAnnotation}
              disabled={isLoading}
            >
              {t(TransKeys.HOMEPAGE.ACTIONS.ADD_ANNOTATION)}
            </Button>
            {showSubscription && (
              <Button
                variant={'outlined'}
                icon={CampaignIcon}
                className={classNames(classes.Subscribe, HOMEPAGE_SUBSCRIBE_BUTTON_FAKE_CLASS)}
                onClick={onEditHomepageSubscription}
                disabled={isLoading || homepageItems.length === 0}
              >
                Subscriptions
              </Button>
            )}
          </div>
        </div>
        <div className={classes.Content}>
          {!homepageData && isLoading && <GenericLoading />}
          <div className={classes.List}>
            <HomepageList
              homepageId={selectedHomepageId}
              searchValue={searchValue}
              isUserSubscribed={isUserSubscribed}
              onSearchValueChange={onSearchValueChange}
              items={items}
              selectedModelId={selectedModelId}
              selectedModelType={selectedModelType}
              onSelectItem={(id: number, modelType: ModelSampleSeriesModel) =>
                onSelectItem({id, modelType})
              }
              homepageItems={homepageItems}
              onAddModel={onAddModel}
              onRemoveModel={onRemoveModel}
              onCreateMetric={onCreateMetric}
              onCreateFunnel={onCreateFunnel}
              onReorderMetrics={onReorderMetrics}
              addMetricButtonRef={addMetricButtonRef}
            />
          </div>
          <div className={classes.ItemViewer}>
            {isLoading && <GenericLoading />}
            {reviewedModel && reviewedModel.modelType === ModelSampleSeriesModel.METRIC && (
              <HomepageMetricViewer
                className={classes.HomepageModelViewer}
                metricId={reviewedModel?.id}
                onCreateEditGoal={(goalId: number) => onCreateEditGoal(reviewedModel.id, goalId)}
                onViewMetric={() => onViewMetric(reviewedModel.id)}
                onViewMetricOpportunities={() => onViewMetric(reviewedModel.id)}
                onViewSegmentation={() => onViewSegmentation(reviewedModel.id)}
                configuration={userSettings}
                isLoading={isLoading}
              />
            )}
            {reviewedModel && reviewedModel.modelType === ModelSampleSeriesModel.FUNNEL && (
              <HomepageFunnelViewer
                className={classes.HomepageModelViewer}
                funnelId={reviewedModel?.id}
                onViewFunnel={() => onViewFunnel(reviewedModel.id)}
                // onViewFunnelOpportunities={console.log}
                configuration={userSettings}
                isLoading={isLoading}
              />
            )}
            {lastUpdate && (
              <div className={classes.LastUpdate}>
                <CircleInfoLightIcon className={classes.InfoIcon} />
                <span>{lastUpdateText}</span>
              </div>
            )}
            {!exists(searchValue) && items.length === 0 && !isLoading && (
              <div className={classes.EmptyState}>
                <span className={classes.CTA} onClick={() => addMetricButtonRef.current.click()}>
                  {t(TransKeys.HOMEPAGE.ACTIONS.EMPTY_STATE_TEXT)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </TeamFilterProvider>
  );
};

import {useContext, useEffect} from 'react';
import {
  FancyBlock,
  GlobalDocumentDataContext,
  useRemoteSourceStated,
  ExecutiveSummaryRenderer,
} from 'ui-components';
import {getResultExecutiveSummeryNetworkRequest} from '../../../../../../http/insights.network-requests.ts';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {GenericLoading} from '../../../../components/general/generic-loading/generic-loading.component.tsx';
import TransKeys from 'translations';
import classes from './app-executive-summery-v2-viewer.module.scss';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../constants/feature-flags.ts';

interface OwnProps {
  withWrapper?: boolean;
  parameters?: any;
  className?: string;
}

type AllProps = OwnProps;

const LoadingComponent = ({withWrapper, className}: {withWrapper: boolean; className?: string}) => {
  const {t} = useTranslation();

  const content = (
    <div
      className={classNames(
        classes.ExecutiveSummaryContainer,
        classes.Loading,
        !withWrapper ? className : undefined
      )}
    >
      <GenericLoading />
    </div>
  );

  if (!withWrapper) {
    return content;
  }

  return (
    <FancyBlock
      title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)}
      collapsible={true}
      className={className}
    >
      {content}
    </FancyBlock>
  );
};

export const AppExecutiveSummeryV2Viewer = (props: AllProps) => {
  const {parameters, withWrapper = true, className} = props;
  const {analysisResultId} = useContext(GlobalDocumentDataContext);
  const {t} = useTranslation();
  const show = useFeatureIsOn(FeatureFlag.RELEASE_IMPACT_EXECUTIVE_SUMMARY as string);
  const {
    source: summary,
    exec: getSummary,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getResultExecutiveSummeryNetworkRequest,
  });

  useEffect(() => {
    getSummary({analysisResultId, parameters});
  }, [getSummary, analysisResultId, parameters]);

  if (!show) {
    return null;
  }

  if (isLoading) {
    return <LoadingComponent withWrapper={withWrapper} className={className} />;
  }

  if (!summary) {
    return null;
  }

  return (
    <FancyBlock
      title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)}
      collapsible={true}
      addPadding
      className={className}
    >
      <div className={classes.Wrapper}>
        <ExecutiveSummaryRenderer executiveSummary={summary} />
      </div>
    </FancyBlock>
  );
};

import {useMemo} from 'react';
import {getSampleWoWChange} from '../../../../homepage/components/homepage-summary/homepage-summary.utils.ts';
import {RCAMode} from 'ui-components';
import {ModelSample} from '../../../../../objects/models/model-sample-series.model.ts';

export const useTrendForTree = (
  lastSample: ModelSample,
  previousSample: ModelSample,
  higherIsBetter: boolean,
  rcaMode: RCAMode
) => {
  const trend = useMemo(() => {
    const trendByLoops =
      getSampleWoWChange(lastSample, lastSample?.expectedValue, higherIsBetter) || ({} as any);
    const trendComparedToPrevious =
      getSampleWoWChange(lastSample, previousSample?.value, higherIsBetter) || ({} as any);
    if (rcaMode === RCAMode.LOOPS_ALGO) {
      return trendByLoops;
    }
    return {
      ...trendComparedToPrevious,
      isSignificant:
        rcaMode === RCAMode.COMPARE_TO_DATE
          ? trendComparedToPrevious.isSignificant
          : trendByLoops.isSignificant,
    };
  }, [lastSample, previousSample, higherIsBetter, rcaMode]);

  return lastSample ? trend : null;
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './subscription-report-page.module.scss';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  ArrowRightLightIcon,
  ChevronUpRegularIcon,
  getPercentageValueFormatter,
  LoopsIconPurple,
  TooltipIfOverflow,
  UpliftDisplay,
  useMountStateRef,
  useRemoteSourceStated,
  WavePulseRegularIcon,
} from 'ui-components';
import {getSubscriptionReportNetworkRequest} from '../../../../http/insights.network-requests.ts';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import moment from 'moment/moment';
import {keyBy} from 'lodash';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component.tsx';
import {useProductData} from '../../../../core/hooks/use-product-data.hook.ts';
import {SubscriptionReportKPIItem} from './subscription-report-kpi-item/subscription-report-kpi-item.component.tsx';
import {
  formatDateRange,
  generateIdForMetric,
  generateXoXLabel,
  ROOT_ELEMENT_ID,
  toWeeklySummaryRowItem,
} from './subscription-report-page.utils.ts';
import {WeeklyReport, WeeklySummaryItem} from './subscription-report-page.types.ts';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types.ts';
import {exists} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {CircularProgress} from '@material-ui/core';
import {PDF_READY_ELEMENT_ID} from '../../../../constants/pdf-generation.consts.ts';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model.ts';

interface Props {
  className?: string;
}

export const SUBSCRIPTION_REPORT_DATE_QUERY_PARAM = 'date';
export const SUBSCRIPTION_REPORT_SUBSCRIPTION_ID_PARAM = 'sid';
export const SUBSCRIPTION_REPORT_GRANULARITY_PARAM = 'granularity';

const DELAY = 500;

export const SubscriptionReportPage = (props: Props) => {
  const {className} = props;
  const [date] = useQueryParam<string>(SUBSCRIPTION_REPORT_DATE_QUERY_PARAM, StringParam);
  const [subscriptionId] = useQueryParam<string>(
    SUBSCRIPTION_REPORT_SUBSCRIPTION_ID_PARAM,
    StringParam
  );
  const mounted = useMountStateRef();
  const [granularity] = useQueryParam<string>(SUBSCRIPTION_REPORT_GRANULARITY_PARAM, StringParam);
  const {logo, name: productName} = useProductData();
  const {t} = useTranslation();
  const [isCompleted, setIsCompleted] = useState(false);
  const {
    source: data,
    exec,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getSubscriptionReportNetworkRequest,
    onSuccess: () => setTimeout(() => mounted.current && setIsCompleted(true), DELAY),
    onError: () => setTimeout(() => mounted.current && setIsCompleted(true), DELAY),
  });
  const weeklyReport: WeeklyReport = data as WeeklyReport;
  const {items: summaryItems, teamId, subscriptionDate, subscriptionData} = weeklyReport || {};
  const itemsMap = useMemo(() => keyBy(summaryItems, 'metricId'), [summaryItems]);
  const formattedDate = useMemo(() => {
    if (!subscriptionDate) {
      return;
    }
    let format = 'DD MMM YYYY';
    if (granularity === 'month') {
      format = 'MMMM YYYY';
    }
    return moment(subscriptionDate).format(format);
  }, [subscriptionDate, granularity]);
  const items = useMemo(() => {
    if (!summaryItems) {
      return [] as WeeklySummaryItem[];
    }
    return summaryItems.map(i =>
      toWeeklySummaryRowItem(i, granularity as ModelSeriesGranularity)
    ) as WeeklySummaryItem[];
  }, [summaryItems, granularity]);
  const itemsToDisplay = useMemo(() => items.filter(i => i.isReady && i.hasInsights), [items]);
  const hasAnomaly = useMemo(() => (items || []).filter(i => i.isAnomaly).length > 0, [items]);
  const columns: TableColumn<WeeklySummaryItem>[] = useMemo(
    () => [
      {
        title: '',
        width: '4rem',
        key: 'anomaly',
        hidden: !hasAnomaly,
        render: item =>
          item.isAnomaly && (
            <div
              className={classNames(
                classes.AnomalyColumn,
                !item.positiveUplift && classes.Negative
              )}
            >
              <WavePulseRegularIcon />
            </div>
          ),
      },
      {
        title: t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.NAME),
        width: '1.5fr',
        key: 'name',
        render: item => (
          <div className={classes.NameColumn}>
            <TooltipIfOverflow title={item.name}>
              <span className={classes.Name}>{item.name}</span>
            </TooltipIfOverflow>
          </div>
        ),
      },
      {
        title: t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.LAST_VALUE),
        key: 'value',
        cellClassName: item =>
          item.isReady ? undefined : classNames(classes.NotReady, hasAnomaly && classes.HasAnomaly),
        render: item => {
          if (item.isReady) {
            return (
              <div className={classes.ValueColumn}>
                <span className={classes.Date}>
                  {formatDateRange(item.datetime, item.granularity)}:
                </span>
                <span className={classes.Value}>
                  {getPercentageValueFormatter(item.isPercentageValue)(item.value || 0)}
                </span>
              </div>
            );
          }
          if (!exists(item.analysisResultId)) {
            return <div className={classes.MissingData}>Missing RCA</div>;
          }
          return (
            <div className={classes.MissingData}>
              <CircularProgress
                variant={'indeterminate'}
                disableShrink
                size={16}
                thickness={4}
                color={'inherit'}
              />
              <div>{t(TransKeys.SUBSCRIPTION_REPORT_PAGE.WORKING_ON_IT)}</div>
            </div>
          );
        },
      },
      {
        title: t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.PREV_VALUE),
        key: 'expectedValue',
        shouldRender: item => item.isReady,
        render: item => (
          <div className={classes.ValueColumn}>
            <span className={classes.Date}>
              {formatDateRange(item.datetime, item.granularity, true)}:
            </span>
            <span className={classes.Value}>
              {getPercentageValueFormatter(item.isPercentageValue)(item.expectedValue || 0)}
            </span>
          </div>
        ),
      },
      {
        title: t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.CHANGE, {
          xox: generateXoXLabel(granularity),
        }),
        key: 'uplift',
        width: '12rem',
        shouldRender: item => item.isReady,
        render: item => {
          if (item.uplift === undefined) {
            return <div className={classes.MutedText}>-</div>;
          }
          return (
            <UpliftDisplay
              className={classes.Uplift}
              uplift={item.uplift}
              higherIsBetter={item.higherIsBetter}
            />
          );
        },
      },
      {
        title: t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.EXPLAINERS),
        key: 'explainersCount',
        width: '18rem',
        shouldRender: item => item.isReady,
        render: item => {
          if (item.explainersText === undefined) {
            return <div className={classes.MutedText}>-</div>;
          }
          return (
            <div
              className={classNames(
                classes.ExplainersColumn,
                item.explainersCount === 0 && classes.NoExplainers
              )}
            >
              {item.explainersText}
            </div>
          );
        },
      },
      {
        title: '',
        key: 'link',
        align: 'right',
        width: '12rem',
        shouldRender: item => item.isReady,
        render: item => {
          if (!item.hasInsights) {
            return null;
          }
          return (
            <div className={classes.ActionColumn}>
              <a href={`#${generateIdForMetric(item.metricId)}`} className={classes.MoreInfo}>
                <span>{t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SEE_MORE_INFO)}</span>
                <ArrowRightLightIcon />
              </a>
            </div>
          );
        },
      },
    ],
    [granularity, hasAnomaly, t]
  );
  const onGoToTopClick = useCallback(() => {
    document.getElementById(ROOT_ELEMENT_ID).scrollIntoView({behavior: 'smooth'});
  }, []);

  useEffect(() => {
    exec({subscriptionId, date, granularity});
  }, [subscriptionId, date, granularity, exec]);

  return (
    <>
      <div className={classNames(classes.SubscriptionReportPage, className)}>
        {weeklyReport && (
          <div className={classes.GoToTop} onClick={onGoToTopClick}>
            <ChevronUpRegularIcon />
          </div>
        )}
        <div className={classes.Page}>
          <div className={classes.Header} id={ROOT_ELEMENT_ID}>
            <div className={classes.ProductLogo}>
              <img src={logo} alt={'logo'} />
            </div>
            <div className={classes.Title}>
              {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.TITLE, {
                account_name: productName,
                date: formattedDate,
                subscription_granularity: t(
                  TransKeys.GENERAL.LABELS.GRANULARITY_FREQUENCY[granularity.toUpperCase()]
                ),
              })}
            </div>
            <div className={classes.SubTitle}>
              <LoopsIconPurple className={classes.LoopsIcon} />
              <span>{t(TransKeys.SUBSCRIPTION_REPORT_PAGE.LOOPS_SUBTITLE)}</span>
            </div>
          </div>
          {isLoading && <GenericLoading />}
          {weeklyReport && (
            <>
              <div className={classes.MainTable}>
                <div className={classes.Title}>
                  {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE_TITLE)}
                </div>
                <GridTable
                  columns={columns}
                  data={items}
                  dataKey={'metricId'}
                  rowClassName={classes.ZebraRow}
                />
                <div className={classes.Legend}>
                  <div className={classNames(classes.LegendItem, classes.AnomalyColumn)}>
                    <WavePulseRegularIcon />
                    <span className={classes.Text}>
                      - {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.LEGEND.POSITIVE_ANOMALY)}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      classes.LegendItem,
                      classes.Negative,
                      classes.AnomalyColumn
                    )}
                  >
                    <WavePulseRegularIcon />
                    <span className={classes.Text}>
                      - {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.LEGEND.NEGATIVE_ANOMALY)}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {itemsToDisplay.map((item, index) => (
          <div key={item.metricId} className={classes.Page}>
            <SubscriptionReportKPIItem
              data={itemsMap[item.metricId]}
              teamId={teamId}
              subscriptionId={Number(subscriptionId)}
              subscriptionData={subscriptionData}
              id={generateIdForMetric(item.metricId)}
              className={classes.MetricItem}
            />
          </div>
        ))}
      </div>
      {isCompleted && <div id={PDF_READY_ELEMENT_ID} />}
    </>
  );
};

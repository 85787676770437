import React, {useCallback, useMemo} from 'react';
import classes from './set-resource-usefulness-form-panel.module.scss';
import {Button, FancyHeader, ModalLayout} from 'ui-components';
import {NotUsefulReasonsOptions, UsefulResourceType} from '../../../../objects/models/useful.model';
import TransKeys from 'translations';
import {values} from 'lodash';
import {useTranslation} from 'react-i18next';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormProvider, useForm} from 'react-hook-form';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  EnumFormInput,
  TextareaFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {UsefulDTO, usefulResourceDTOValidator} from '../../../../objects/dto/useful.dto';
import {useIsUseful} from '../../../../core/hooks/user-reactions.hook';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import i18n from 'i18next';

interface OwnProps {
  onClose: () => void;
  panelId?: string;
  type: UsefulResourceType;
  status: boolean;
  model: any;
}

type AllProps = OwnProps;

const USEFULNESS_CONFIG = () => ({
  [UsefulResourceType.PHENOMENA]: {
    options: values(NotUsefulReasonsOptions).map(o => ({
      label: i18n.t(
        TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.INPUTS.REASON.REASONS_OPTIONS_VALUES[o]
      ),
      value: o,
    })),
  },
});

export const SetResourceUsefulnessFormPanel = (props: AllProps) => {
  const {onClose, type, status, model} = props;
  const {t} = useTranslation();

  const formMethods = useForm<UsefulDTO>({
    defaultValues: {
      more: '',
      status,
      type,
    },
    resolver: yupResolver(usefulResourceDTOValidator),
  });

  const {handleSubmit} = formMethods;
  const options = useMemo(() => ({options: USEFULNESS_CONFIG()[type]?.options || []}), [type]);

  const createUserUsefulReaction = useIsUseful(type);
  const onSubmit = useCallback(
    data => {
      createUserUsefulReaction(
        model,
        {
          status: data.status,
          reason: data.reason,
          more: data.more,
        },
        {
          onSuccess: () => {
            onClose();
            return [];
          },
        }
      );
    },
    [createUserUsefulReaction, onClose, model]
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_USER_REACTION)(state)
  );

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button
              className={classNames(classes.Btn, classes.CancelBtn)}
              variant={'outlined'}
              onClick={onClose}
            >
              {t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.BUTTONS.CANCEL)}
            </Button>
            <Button className={classes.Btn} onClick={handleSubmit(onSubmit)} disabled={isLoading}>
              {t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.BUTTONS.SUBMIT)}
            </Button>
          </div>
        }
      >
        <div className={classes.Usefulness}>
          <FancyHeader
            title={t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.HEADING)}
            onClose={onClose}
            className={classes.UsefulnessHeader}
          />
          <div className={classes.FormWrapper}>
            {isLoading && <GenericLoading />}
            <FormProvider {...formMethods}>
              <form
                className={classes.Form}
                onKeyDown={preventSubmitOnEnter}
                onSubmit={handleSubmit(onSubmit)}
              >
                <span className={classes.Cta}>
                  {t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.CTA, {
                    model: type,
                  })}
                </span>
                <FormHiddenInputs names={['type', 'status']} />
                <EnumFormInput
                  options={options}
                  label={t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.INPUTS.REASON.LABEL)}
                  name={'reason'}
                  sortValues={false}
                />
                <TextareaFormInput
                  label={t(TransKeys.SET_RESOURCE_USEFULNESS_FORM.NOT_USEFUL.INPUTS.MORE.LABEL)}
                  name={'more'}
                />
              </form>
            </FormProvider>
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

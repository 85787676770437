import {useCallback, useMemo, useContext} from 'react';
import {WorkflowDTO, workflowValidator} from '../../../../../../objects/dto/workflow.dto';
import classes from './workflow-parameters-tab.module.scss';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import {Button, LabelWrapper, Select} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {TextFormInput} from '../../../../../shared/form/components/shared-form-input.component';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {EntitySelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {SegmentFilterSelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {WorkflowGranularity, WorkflowType} from '../../../../../../objects/models/workflow.model';
import {values} from 'lodash';
import {MetricSmartSelector} from '../../../../../shared/core/smart-selector/metric-smart-selector/metric-smart-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {SqlInput} from '../../../../../shared/form/components/sql-input/sql-input.component';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';
import {AttributesSelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/attributes-selector/attributes-selector.component';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';
import {Metric} from '../../../../../../objects/models/metric.model';

interface OwnProps {
  data: Partial<WorkflowDTO>;
  onSubmit: (data: Partial<WorkflowDTO>) => void;
  onBack?: () => void;
  onSignalInfo?: (value: string | number) => void;
}

type AllProps = OwnProps;

const createGroupByFilters = entityContext => ({
  tag: ['entity_property', 'segment'],
  entityContext: entityContext,
  entityBinding: TableEntityBinding.ONE_WAY,
});

export const WorkflowParametersTab = (props: AllProps) => {
  const {data, onSubmit, onSignalInfo, onBack} = props;
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {productEntities, defaultTableEntity} = useProductData();

  const formMethods = useForm({
    defaultValues: {
      parameters: {
        attributes: data.parameters?.attributes || [null],
      },
      entity: data.entity || defaultTableEntity,
      granularity: data.granularity || WorkflowGranularity.WEEKLY,
      ...data,
    } as WorkflowDTO,
    resolver: yupResolver(workflowValidator.noUnknown()) as any,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
    control,
  } = formMethods;
  const parameters = watch('parameters');
  const entityContext = watch('entity');
  const workflowType = watch('type');

  const onEntityChange = useCallback(
    entity => {
      setValue('entity', entity, {shouldValidate: true});
      setValue('parameters', {}, {shouldValidate: true});
    },
    [setValue]
  );
  const granularityOptions = useMemo(
    () =>
      values(WorkflowGranularity).map(o => ({
        label: t(TransKeys.GENERAL.LABELS.GRANULARITY[o.toUpperCase()]),
        value: o,
      })),
    [t]
  );
  const onCreateKPI = useCallback(() => {
    openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
      onSuccess: (metric: Metric) => {
        setValue('parameters.metric', metric.id, {shouldValidate: true});
      },
    });
  }, [openSecondaryPanel, setValue]);
  const groupByFilters = useMemo(() => createGroupByFilters(entityContext), [entityContext]);

  const renderParametersByType = () => {
    switch (workflowType) {
      case WorkflowType.KPI_REACH:
        return (
          <ParameterInputWrapper
            title={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.KPI.TITLE)}
            subTitle={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.KPI.SUB_TITLE)}
            helperText={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.KPI.HELPER_TEXT)}
            className={classes.Parameter}
          >
            <Controller
              control={control}
              name={'parameters.metric'}
              render={({field, fieldState}) => (
                <LabelWrapper
                  label={''}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                >
                  <MetricSmartSelector
                    value={field.value}
                    placeholder={'Select KPI'}
                    onChange={v => field.onChange(v)}
                    onCreate={onCreateKPI}
                    filters={{
                      entityContext: entityContext,
                      entityBinding: TableEntityBinding.DEFAULT,
                    }}
                    error={Boolean(fieldState.error)}
                  />
                </LabelWrapper>
              )}
            />
          </ParameterInputWrapper>
        );
      case WorkflowType.CUSTOM_SQL:
        return (
          <ParameterInputWrapper
            title={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.SQL_DEFINITION.TITLE)}
            subTitle={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.SQL_DEFINITION.SUB_TITLE)}
            helperText={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.SQL_DEFINITION.HELPER_TEXT)}
            className={classes.Parameter}
          >
            <Controller
              render={({field, fieldState}) => (
                <SqlInput
                  value={field.value}
                  placeholder={t(TransKeys.TABLE_FORM.INPUTS.DEFINITION.QUERY.PLACEHOLDER)}
                  onChange={field.onChange}
                  minHeight="14rem"
                  className={classes.SQLInput}
                  error={Boolean(fieldState.error)}
                  border
                />
              )}
              name={'parameters.sql'}
              control={control}
            />
          </ParameterInputWrapper>
        );
    }
    return null;
  };

  return (
    <FormStep
      footer={
        <>
          <Button variant={'outlined'} type={'button'} onClick={onBack}>
            {t(TransKeys.GENERAL.ACTIONS.BACK)}
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>{t(TransKeys.GENERAL.ACTIONS.NEXT)}</Button>
        </>
      }
    >
      <FormProvider {...formMethods}>
        <div className={classes.WorkflowParametersTab}>
          <div className={classes.SectionTitle}>
            {t(TransKeys.WORKFLOW_FORM_PANEL.SECTION_TITLES.WORKFLOW_SETUP)}
          </div>
          <ParameterInputWrapper
            title={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.NAME.TITLE)}
            className={classes.Parameter}
          >
            <TextFormInput
              label={''}
              error={Boolean(errors?.name)}
              name={'name'}
              placeholder={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.NAME.PLACEHOLDER)}
            />
          </ParameterInputWrapper>
          <Controller
            control={control}
            name={'entity'}
            render={({field, fieldState}) => (
              <EntitySelector
                productEntities={productEntities}
                onChange={params => onEntityChange(params.entity)}
                value={{entity: field.value}}
                className={classes.Parameter}
              />
            )}
          />
          {renderParametersByType()}
          <ParameterInputWrapper
            title={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GRANULARITY.TITLE)}
            subTitle={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GRANULARITY.SUB_TITLE)}
            helperText={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GRANULARITY.HELPER_TEXT)}
            className={classes.Parameter}
          >
            <Controller
              control={control}
              name={'granularity'}
              render={({field, fieldState}) => (
                <Select
                  options={{options: granularityOptions}}
                  value={field.value}
                  onChange={v => field.onChange(v)}
                  searchable={false}
                  clearable={false}
                  sortValues={false}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </ParameterInputWrapper>
          <SegmentFilterSelector
            onChange={v =>
              setValue('parameters.population_filter', v.population_filter, {
                shouldValidate: true,
              })
            }
            entityContext={entityContext}
            value={parameters}
            errors={errors.parameters}
          />
          <div className={classes.SectionTitle}>
            {t(TransKeys.WORKFLOW_FORM_PANEL.SECTION_TITLES.DASHBOARD_SETUP)}
          </div>
          <AttributesSelector
            value={parameters}
            subTitle={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.ATTRIBUTES.SUB_TITLE)}
            onChange={v =>
              setValue('parameters.attributes', v.attributes, {
                shouldValidate: true,
              })
            }
            errors={errors.parameters}
            entityContext={entityContext as TableEntity}
            className={classes.Parameter}
          />
          <ParameterInputWrapper
            title={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GROUP_BY.TITLE)}
            subTitle={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GROUP_BY.SUB_TITLE)}
            helperText={t(TransKeys.WORKFLOW_FORM_PANEL.INPUTS.GROUP_BY.HELPER_TEXT)}
            className={classes.Parameter}
          >
            <Controller
              control={control}
              name={'parameters.group_by'}
              render={({field, fieldState: {error}}) => (
                <SignalSmartSelector
                  onChange={field.onChange}
                  value={field.value}
                  error={Boolean(error)}
                  placeholder={'Select segment groups'}
                  filters={groupByFilters}
                  include={[SignalSmartSelectorKey.SIGNALS]}
                  onSignalInfo={onSignalInfo}
                />
              )}
            />
          </ParameterInputWrapper>
        </div>
      </FormProvider>
    </FormStep>
  );
};

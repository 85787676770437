import classNames from 'classnames';
import classes from './funnel-page.module.scss';
import {useCallback, useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {NumberParam, StringParam} from 'serialize-query-params';
import {AppRoutes, FUNNEL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import TransKeys from 'translations';
import {
  getFunnelPageNetworkRequest,
  getRCAForFunnelByDate,
} from '../../../../http/funnels.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component.tsx';
import PageLayout from '../../../../modules/shared/components/layout/page-layout';
import {ChartPyramidDuotoneIcon, EditIcon, MoreIcon, useRemoteSourceStated} from 'ui-components';
import {useQueryParam} from 'use-query-params';
import {FunnelPageHeader} from '../../components/funnel-page-header/funnel-page-header.component';
import {HomepageFunnelViewer} from '../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-funnel-viewer/homepage-funnel-viewer.component.tsx';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useDispatch} from 'react-redux';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {ModelKey} from '../../../../constants/model-key';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {exists, HttpClientContext} from 'front-core';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {
  FUNNEL_PAGE_DATE_QUERY_PARAM,
  SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID,
} from './funnel-page.consts';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import queryString from 'query-string';
import type {FunnelPage as FunnelPageDTO} from '../../../../objects/models/funnel-page.model';
import {RCADatePicker} from '../../../metrics/pages/metric-page/components/rca-date-picker/rca-date-picker.component.tsx';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component.tsx';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {Action, Subject} from '../../../../constants/permissions';
import {PanelKey} from '../../../../constants/panels';
import {FunnelPageRCA} from './components/funnel-page-rca/funnel-page-rca.component';

type FunnelPageComponentProps = {
  funnelId: number;
};

const getInitialDate = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed[FUNNEL_PAGE_DATE_QUERY_PARAM];
};

const FunnelPageComponent = (props: FunnelPageComponentProps) => {
  const {funnelId} = props;
  const {t} = useTranslation();
  const http = useContext(HttpClientContext);

  const {openPrimaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const [rcaDate, setRCADate] = useQueryParam<string>(FUNNEL_PAGE_DATE_QUERY_PARAM, StringParam);
  const [selectedRCAFunnelStepTargetSignalId, setSelectedRCAFunnelStepTargetSignalId] =
    useQueryParam<number>(SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID, NumberParam);

  const {
    source,
    exec: getFunnelPage,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getFunnelPageNetworkRequest,
  });

  const {
    source: analysisResult,
    exec: getRCA,
    isLoading: isLoadingResult,
  } = useRemoteSourceStated({
    networkRequest: getAnalysisResultNetworkRequest,
  });

  const onChangeResultId = useCallback(resultId => getRCA(resultId), [getRCA]);
  // workaround to get type definition
  const funnel = source as FunnelPageDTO;
  const {userSettings} = useProductData();

  const onChangeRCADate = useCallback(
    async (date: string) => {
      const result = await http.exec(
        getRCAForFunnelByDate({
          funnelId,
          granularity: ModelSeriesGranularity.WEEK,
          date: moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
      const {analysisResultId} = result as unknown as {
        analysisResultId: number;
      };

      onChangeResultId(analysisResultId);
    },
    [funnelId, onChangeResultId, http]
  );

  useEffect(() => {
    funnelId && getFunnelPage(funnelId);
  }, [funnelId, getFunnelPage]);
  useEffect(() => {
    rcaDate && onChangeRCADate(rcaDate);
  }, [rcaDate, onChangeRCADate]);

  const onUserChangeRCADate = useCallback(
    (date: string) => {
      if (date !== rcaDate) {
        setRCADate(date);
      }
    },
    [rcaDate, setRCADate]
  );

  useEffect(() => {
    let requestedDate = rcaDate;
    if (rcaDate) {
      requestedDate = rcaDate;
    } else if (funnel && funnel.analysisResultId) {
      requestedDate = funnel.rcaDate;
    } else if (funnel && funnel.maxAnalysisResultDate) {
      requestedDate = funnel.maxAnalysisResultDate;
    } else if (funnel && funnel.maxSampleDate) {
      requestedDate = funnel.maxSampleDate;
    }

    if (requestedDate) {
      // initialLoad.current = true;
      const formattedDate = moment
        .utc(requestedDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
        .format(TIME_FORMATS.PARAMETER_DATE_FORMAT);
      setRCADate(formattedDate, 'replaceIn');
    }
  }, [funnel, setRCADate, rcaDate]);

  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.FUNNEL && data.id === funnelId) {
        getFunnelPage(funnelId);
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getFunnelPage, funnelId]);

  const onSelectRCATargetFunnelStep = useCallback(
    (targetSignalId: number) => {
      setSelectedRCAFunnelStepTargetSignalId(targetSignalId);
    },
    [setSelectedRCAFunnelStepTargetSignalId]
  );
  const initialDateRef = useRef(getInitialDate());
  const onDocumentResolved = useCallback(() => {
    if (!exists(initialDateRef.current)) {
      return;
    }
    setTimeout(() => {
      initialDateRef.current = null;
    }, 0);
  }, [initialDateRef]);
  const {can} = usePermissions();
  const hasPermissionToEditFunnel = can(Subject.FUNNEL, Action.EDIT);

  const onCreateEdit = useCallback(() => {
    openPrimaryPanel(PanelKey.FUNNEL_FORM_PANEL, {
      [FUNNEL_ID_PATH_PARAM]: funnel?.id,
      ...funnel,
    });
  }, [openPrimaryPanel, funnel]);
  const showFunnel = useCallback(() => {
    funnel &&
      openPrimaryPanel(PanelKey.VIEW_FUNNEL_PANEL, {
        [FUNNEL_ID_PATH_PARAM]: funnel?.id,
      });
  }, [openPrimaryPanel, funnel]);
  const handleEdit = hasPermissionToEditFunnel && (() => onCreateEdit());

  if (!funnel || isLoading) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PageHeader
        title={funnel.name}
        crumbs={[
          {
            name: 'Funnels',
            icon: <ChartPyramidDuotoneIcon />,
            navigateTo: AppRoutes.funnels(),
          },
        ]}
        actions={[
          funnel && (
            <RCADatePicker
              selected={rcaDate}
              onChange={onUserChangeRCADate}
              maxDate={funnel.maxAnalysisResultDate}
              granularity={ModelSeriesGranularity.WEEK}
              isLoading={isLoadingResult}
            />
          ),
          <ModelActionsDropdown
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
            iconSize={'large'}
            actions={[
              {
                key: 'edit',
                icon: EditIcon,
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: handleEdit,
              },
            ]}
          />,
        ]}
      />
      <PageLayout.Body noPadding isLoading={false}>
        <div className={classes.FunnelPage}>
          <div className={classes.Main}>
            <div className={classes.ContentSection}>
              <FunnelPageHeader funnel={funnel} onViewDefinition={showFunnel} />
              <div className={classes.GraphViewContainer}>
                <HomepageFunnelViewer
                  variant={'contained'}
                  showHeader={false}
                  funnelId={funnelId}
                  configuration={userSettings}
                />
              </div>
            </div>
            <div className={classNames(classes.ContentSection, classes.RCAContent)}>
              <FunnelPageRCA
                onSelectRCATargetFunnelStep={onSelectRCATargetFunnelStep}
                targetSignalId={selectedRCAFunnelStepTargetSignalId}
                analysisResult={analysisResult}
                onDocumentResolved={onDocumentResolved}
                isLoadingResult={isLoadingResult}
              />
            </div>
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

type FunnelPagePathParams = {
  [FUNNEL_ID_PATH_PARAM]: string;
};

export const FunnelPage = (props: any) => {
  const {[FUNNEL_ID_PATH_PARAM]: funnelIdFromParams} = useParams<FunnelPagePathParams>();

  return (
    <FunnelPageComponent
      {...props}
      key={funnelIdFromParams}
      funnelId={Number(funnelIdFromParams)}
    />
  );
};

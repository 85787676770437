import classNames from 'classnames';
import classes from './item-list.module.scss';
import {CloseIcon, IconButton} from 'ui-components';
import {useMemo} from 'react';

interface OwnProps {
  items: any[];
  renderItem: (item: any, idx: number) => any;
  onRemoveItem?: (idx: number) => void;
  onAddItem?: () => void;
  min1Item?: boolean;
  maxItems?: number;
  disabled?: boolean;
  withPlaceholder?: boolean;
  inline?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ItemList = (props: AllProps) => {
  const {
    items: items_,
    renderItem,
    onAddItem,
    onRemoveItem,
    disabled,
    min1Item = true,
    maxItems,
    inline,
    withPlaceholder,
    className,
  } = props;

  const items = useMemo(() => items_ || [], [items_]);
  const showRemove = useMemo(() => {
    if (!min1Item) {
      return true;
    }
    return items.length > 1 && !disabled;
  }, [items, min1Item, disabled]);
  const showAdd = useMemo(() => {
    if (disabled || !onAddItem) {
      return false;
    }
    if (maxItems && items.length >= maxItems) {
      return false;
    }
    if (withPlaceholder && items.length === 0) {
      return false;
    }
    return true;
  }, [disabled, items.length, maxItems, onAddItem, withPlaceholder]);

  return (
    <div className={classNames(classes.ItemList, inline && classes.Inline, className)}>
      {withPlaceholder && items.length === 0 && (
        <div className={classes.Row}>{renderItem(null, 0)}</div>
      )}
      {items.map((item, idx) => (
        <div key={item} className={classes.Row}>
          {renderItem(item, idx)}
          {showRemove && onRemoveItem && (
            <div className={classes.Actions}>
              <IconButton
                icon={CloseIcon}
                onClick={_ => onRemoveItem(idx)}
                className={classes.Action}
                tooltipText={'Remove'}
              />
            </div>
          )}
        </div>
      ))}
      {showAdd && (
        <div className={classes.AddButton} onClick={_ => onAddItem()}>
          + Add
        </div>
      )}
    </div>
  );
};

import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {useCallback, useMemo, useState} from 'react';
import {isEmpty, keys} from 'lodash';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import classes from '../../homepage-subscription-panel.module.scss';
import TransKeys from 'translations';
import {
  GranularitiesByModelId,
  HOMEPAGE_UNIT_SUBSCRIPTION_UNIT_TO_SERIES_GRANULARITY,
  HomepageSampledModelType,
  HomepageSettings,
  HomepageSubscriptionPushType,
  HomepageSubscriptionSampledModelSettings,
  HomepageSubscriptionUnit,
} from '../../../../../../objects/models/homepage.model';
import {SampledModelsSettingsList} from '../sampled-model-settings-list/sampled-model-settings-list.component';
import {FeatureFlag} from '../../../../../../constants/feature-flags';
import {exists} from 'front-core';
import {ChevronRightRegularIcon, CircleInfoRegularIcon, ToggleSwitch} from 'ui-components';
import classNames from 'classnames';

interface SampledModelsPushSettings {
  metrics: HomepageSubscriptionSampledModelSettings;
  funnels?: HomepageSubscriptionSampledModelSettings;
}

export interface SubscriptionConfigProps {
  homepageSettings: HomepageSettings;
  homepageSubscriptionUnit: HomepageSubscriptionUnit;
  sampledModelsPushSettings: SampledModelsPushSettings;
}

const SETTINGS_MAP = {
  [HomepageSubscriptionUnit.MONTHLY]: {
    [HomepageSampledModelType.METRIC]: 'monthlyMetricSettings',
    [HomepageSampledModelType.FUNNEL]: 'monthlyFunnelSettings',
  },
  [HomepageSubscriptionUnit.WEEKLY]: {
    [HomepageSampledModelType.METRIC]: 'weeklyMetricSettings',
    [HomepageSampledModelType.FUNNEL]: 'weeklyFunnelSettings',
  },
  [HomepageSubscriptionUnit.DAILY]: {
    [HomepageSampledModelType.METRIC]: 'dailyMetricSettings',
    [HomepageSampledModelType.FUNNEL]: 'dailyFunnelSettings',
  },
};

const activeAttribute = {
  [HomepageSubscriptionUnit.DAILY]: 'isDailyActive',
  [HomepageSubscriptionUnit.WEEKLY]: 'isWeeklyActive',
  [HomepageSubscriptionUnit.MONTHLY]: 'isMonthlyActive',
};

const filterByGranularity = (
  modelGranularities: GranularitiesByModelId,
  homepageSubscriptionUnit: HomepageSubscriptionUnit
) => {
  return Object.entries(modelGranularities)
    .filter(([_, granularities]) =>
      granularities.includes(
        HOMEPAGE_UNIT_SUBSCRIPTION_UNIT_TO_SERIES_GRANULARITY[homepageSubscriptionUnit]
      )
    )
    .map(([id, _]) => Number(id));
};

const sortModelsSettings = (
  modelsSettings: HomepageSubscriptionSampledModelSettings,
  sampledModelsIds: number[]
) => {
  if (isEmpty(modelsSettings)) {
    return [];
  }
  return Object.values(modelsSettings).sort(
    (a, b) => sampledModelsIds.indexOf(a.id) - sampledModelsIds.indexOf(b.id)
  );
};

const calculateSelectedCount = (
  settings: HomepageSubscriptionSampledModelSettings,
  relevantIds: Set<number>
) => {
  const total = Object.keys(settings).filter(k => relevantIds.has(Number(k))).length;
  let selected = 0;
  for (const [id, pushType] of Object.entries(settings)) {
    if (pushType === HomepageSubscriptionPushType.NEVER || !exists(pushType)) {
      continue;
    }
    if (!relevantIds.has(Number(id))) {
      continue;
    }
    selected++;
  }
  return {total, selected};
};

export const SubscriptionSampledModelsConfig = (props: SubscriptionConfigProps) => {
  const {homepageSettings, homepageSubscriptionUnit, sampledModelsPushSettings} = props;
  const {t} = useTranslation();
  const {setValue, watch} = useFormContext();
  const isFunnelFeatureEnabled = useFeatureIsOn(FeatureFlag.FUNNEL_HOMEPAGE_SUBSCRIPTION as string);
  const [isOpen, setIsOpen] = useState(false);
  const translationPath =
    TransKeys[`${homepageSubscriptionUnit.toUpperCase()}_SUBSCRIPTION_CONFIG`];

  const relevantForUnit = useMemo(() => {
    return {
      [HomepageSampledModelType.METRIC]: new Set(
        filterByGranularity(homepageSettings.metricGranularities, homepageSubscriptionUnit)
      ),
      [HomepageSampledModelType.FUNNEL]: new Set(
        filterByGranularity(homepageSettings.funnelGranularities, homepageSubscriptionUnit)
      ),
    };
  }, [homepageSubscriptionUnit, homepageSettings]);
  const metricsSettingsName =
    SETTINGS_MAP[homepageSubscriptionUnit][HomepageSampledModelType.METRIC];
  const funnelsSettingsName =
    SETTINGS_MAP[homepageSubscriptionUnit][HomepageSampledModelType.FUNNEL];
  const metricsSettings = watch(metricsSettingsName);
  const funnelsSettings = watch(funnelsSettingsName);
  const isActive = watch(activeAttribute[homepageSubscriptionUnit]);

  const sampledModelSettings = useMemo(
    () => ({
      [HomepageSampledModelType.METRIC]: metricsSettings,
      [HomepageSampledModelType.FUNNEL]: funnelsSettings,
    }),
    [metricsSettings, funnelsSettings]
  );
  const sampledModelDataForList = useMemo(
    () => ({
      [HomepageSampledModelType.METRIC]: sortModelsSettings(
        sampledModelsPushSettings.metrics,
        homepageSettings.metrics
      ).filter(a => relevantForUnit[HomepageSampledModelType.METRIC].has(a.id)),
      [HomepageSampledModelType.FUNNEL]: sortModelsSettings(
        sampledModelsPushSettings.funnels,
        homepageSettings.funnels
      ).filter(a => relevantForUnit[HomepageSampledModelType.FUNNEL].has(a.id)),
    }),
    [
      sampledModelsPushSettings.metrics,
      sampledModelsPushSettings.funnels,
      homepageSettings.metrics,
      homepageSettings.funnels,
      relevantForUnit,
    ]
  );
  const onChangeAllSampledModels = useCallback(
    (pushType: HomepageSubscriptionPushType) => {
      const updateAll = (settingsName, currentSettings) => {
        setValue(
          settingsName,
          keys(currentSettings).reduce((acc, id) => {
            acc[id] = pushType;
            return acc;
          }, {})
        );
      };
      updateAll(metricsSettingsName, metricsSettings);
      updateAll(funnelsSettingsName, funnelsSettings);
    },
    [metricsSettings, funnelsSettings, setValue, metricsSettingsName, funnelsSettingsName]
  );
  const onActiveChange = useCallback(() => {
    const newIsActive = !isActive;
    setValue(activeAttribute[homepageSubscriptionUnit], newIsActive);
    setIsOpen(newIsActive);
  }, [homepageSubscriptionUnit, isActive, setValue]);
  const onChange = useCallback(
    (modelId, type, pushType) => {
      const settingsName = SETTINGS_MAP[homepageSubscriptionUnit][type];
      setValue(settingsName, {
        ...sampledModelSettings[type],
        [modelId]: pushType,
      });
    },
    [homepageSubscriptionUnit, sampledModelSettings, setValue]
  );
  const selectedSampledModelsCounts = useMemo(() => {
    const metricsCount = calculateSelectedCount(
      metricsSettings,
      relevantForUnit[HomepageSampledModelType.METRIC]
    );
    if (!isFunnelFeatureEnabled || isEmpty(funnelsSettings)) {
      return metricsCount;
    }
    const funnelsCount = calculateSelectedCount(
      funnelsSettings,
      relevantForUnit[HomepageSampledModelType.FUNNEL]
    );
    return {
      total: metricsCount.total + funnelsCount.total,
      selected: metricsCount.selected + funnelsCount.selected,
    };
  }, [metricsSettings, funnelsSettings, isFunnelFeatureEnabled, relevantForUnit]);
  const hiddenCount = useMemo(() => {
    return (
      homepageSettings.metrics.length +
      homepageSettings.funnels.length -
      (sampledModelDataForList[HomepageSampledModelType.METRIC].length +
        sampledModelDataForList[HomepageSampledModelType.FUNNEL].length)
    );
  }, [sampledModelDataForList, homepageSettings]);

  return (
    <div
      className={classNames(classes.Block, isOpen && classes.Open, !isActive && classes.NotActive)}
    >
      <div className={classes.Header}>
        <div className={classes.Title} onClick={() => setIsOpen(!isOpen)}>
          <div className={classes.Active}>
            <ToggleSwitch
              tooltipText={`Activate ${homepageSubscriptionUnit} subscription`}
              value={isActive}
              onChange={onActiveChange}
              size={'small'}
            />
          </div>
          <span className={classes.Text}>
            {t(translationPath.METRIC_LIST.TITLE)}
            <span className={classes.Count}>
              ({`${selectedSampledModelsCounts.selected} \\ ${selectedSampledModelsCounts.total}`})
            </span>
          </span>
          <ChevronRightRegularIcon className={classes.Chevron} onClick={() => setIsOpen(!isOpen)} />
        </div>
        {t(translationPath.METRIC_LIST.SUB_TITLE) && (
          <div className={classes.Description}>{t(translationPath.METRIC_LIST.SUB_TITLE)}</div>
        )}
      </div>
      <div className={classes.MetricSettings}>
        {selectedSampledModelsCounts.total > hiddenCount && (
          <SampledModelsSettingsList
            disabled={!isActive}
            sampledModelsData={sampledModelDataForList}
            settings={sampledModelSettings}
            onChange={onChange}
            onChangeAll={onChangeAllSampledModels}
            unit={homepageSubscriptionUnit}
          />
        )}
        {hiddenCount > 0 && (
          <div className={classes.Note}>
            <CircleInfoRegularIcon /> Note {hiddenCount} KPIs in this homepage are not relevant for{' '}
            {homepageSubscriptionUnit} subscription
          </div>
        )}
      </div>
    </div>
  );
};

import classes from './workflow-header.module.scss';
import {Workflow, WorkflowStatus} from '../../../../../../objects/models/workflow.model';
import {AnalysisResult} from '../../../../../../objects/models/analysis-result.model';
import TransKeys from 'translations';
import {AppRoutes} from '../../../../../../constants/app-routes';
import {PageHeader} from '../../../../../shared/components/layout/page-header/page-header.component';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {formatDate} from '../../../../../../utils/general.utils';
import {isEmpty} from 'lodash';
import {Parameter} from '../../../../../shared/components/general/parameter/parameter.component';
import {AnalysisParameters} from '../../../../../analyses/components/analysis-parameters/analysis-parameters.component';
import {AppSources} from '../../../../../../constants/app-sources';
import {CirclePlayLightIcon, CircleStopLightIcon, MoreIcon, Select, TrashIcon} from 'ui-components';
import {ModelActionsDropdown} from '../../../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useWorkflowActions} from '../../../workflows-main/hooks/use-workflow-actions.hook';

interface OwnProps {
  workflow?: Workflow;
  analysisResult?: AnalysisResult;
  onChangeAnalysisResult: (analysisResultId: number) => void;
}

type AllProps = OwnProps;

const formatDatesForUI = (startDate: string, endDate: string) => {
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const WorkflowHeader = (props: AllProps) => {
  const {workflow, analysisResult, onChangeAnalysisResult} = props;
  const {t} = useTranslation();
  const {setWorkflowStatus, onDelete} = useWorkflowActions();

  const instanceOptions = useMemo(() => {
    if (!workflow) {
      return [];
    }
    return workflow.instances.map(i => ({
      value: i.analysisResultId,
      label: formatDatesForUI(i.runParameters.start_date, i.runParameters.end_date),
    }));
  }, [workflow]);

  return (
    <PageHeader
      title={workflow?.name}
      crumbs={[
        {
          name: t(TransKeys.WORKFLOWS.HEADER.TITLE),
          navigateTo: AppRoutes.workflows(),
        },
      ]}
      actions={
        <ModelActionsDropdown
          iconSize={'large'}
          actions={[
            {
              key: 'update-status',
              title:
                workflow?.status === WorkflowStatus.ACTIVE
                  ? t(TransKeys.WORKFLOWS.ACTIONS.STOP_WORKFLOW)
                  : t(TransKeys.WORKFLOWS.ACTIONS.REACTIVE_WORKFLOW),
              icon:
                workflow?.status === WorkflowStatus.ACTIVE
                  ? CircleStopLightIcon
                  : CirclePlayLightIcon,
              onClick: _ =>
                setWorkflowStatus(
                  workflow?.id,
                  workflow.status === WorkflowStatus.ACTIVE
                    ? WorkflowStatus.STOPPED
                    : WorkflowStatus.ACTIVE
                ),
            },
            {
              key: 'delete',
              title: t(TransKeys.GENERAL.ACTIONS.DELETE),
              icon: TrashIcon,
              onClick: _ => onDelete(workflow.id),
            },
          ]}
          label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
          icon={MoreIcon}
          iconDropdown
        />
      }
      subHeaderItems={[
        <Parameter
          label={t(TransKeys.GENERAL.LABELS.TYPE)}
          value={analysisResult?.analysisTypeName}
        />,
        <Select
          value={analysisResult?.id}
          options={{options: instanceOptions}}
          onChange={onChangeAnalysisResult as any}
          dropdownButtonClassName={classes.DropdownButton}
          clearable={false}
          sortValues={false}
          searchable={instanceOptions.length > 5}
          prefix={t(TransKeys.GENERAL.LABELS.TIME_FRAME)}
        />,
        !isEmpty(analysisResult) && (
          <AnalysisParameters
            label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
            analysisResultId={analysisResult.id}
            appSource={AppSources.VIEW_ANALYSIS_RESULT__VIEW_PARAMETERS}
            border={false}
          />
        ),
      ]}
    />
  );
};

import * as React from 'react';
import {TreatmentAdoptionSimulationFigure} from '../../../../types';
import {
  BaseTreatmentAdoptionSimulationViewer,
  MaxPotentialMode,
} from './base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.component';
import TransKeys from 'translations';

// todo: change interface to FeatureImpactFigure
interface OwnProps extends TreatmentAdoptionSimulationFigure {
  className?: string;
}

type AllProps = OwnProps;

export const FeatureImpactViewer: React.FC<AllProps> = (props: AllProps) => {
  return (
    <BaseTreatmentAdoptionSimulationViewer
      {...props}
      hasSimulationMode={false}
      maxPotentialMode={MaxPotentialMode.IMPACT}
      transKeysPrefix={TransKeys.DOCUMENT_VIEWER.GOAL_DRIVERS_SIMULATION}
    />
  );
};

import {createContext, useCallback, useMemo} from 'react';
import * as React from 'react';
import {CommandType, DocumentCommand} from '../types';
import {ModelType} from '../../../../consts/model-type';

export interface IDocumentCommandEmitterContext {
  emitEvent: (event: DocumentCommand<any>) => void;
  onAnalysisResultClick: (analysisId: number, analysisResultId: number) => void;
  onSignalClick: (signalId: number) => void;
}

export const DocumentCommandEmitterContext = createContext<IDocumentCommandEmitterContext>({
  emitEvent: undefined,
  onSignalClick: undefined,
  onAnalysisResultClick: undefined,
});

interface OwnProps {
  onEvent: (event: DocumentCommand<any>) => void;
  children: any;
}

export const DocumentCommandEmitterContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {onEvent, children} = props;

  const onSignalClick = useCallback(
    signalId =>
      onEvent({
        type: CommandType.REF,
        payload: {
          type: ModelType.SIGNAL,
          refId: signalId,
        },
      }),
    [onEvent]
  );

  const onAnalysisResultClick = useCallback(
    (analysisId: number, analysisResultId: number) => {
      onEvent({
        type: CommandType.REF,
        payload: {
          type: ModelType.ANALYSIS_RESULT,
          refId: analysisResultId,
          parameters: {analysisId},
        },
      });
    },
    [onEvent]
  );

  const contextValue = useMemo(
    () => ({
      emitEvent: onEvent,
      onSignalClick,
      onAnalysisResultClick,
    }),
    [onEvent, onSignalClick, onAnalysisResultClick]
  );

  return (
    <DocumentCommandEmitterContext.Provider value={contextValue}>
      {children}
    </DocumentCommandEmitterContext.Provider>
  );
};

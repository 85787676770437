import classNames from 'classnames';
import {
  GlobalDocumentDataContext,
  RCAMode,
  RemoteFigureElement,
  RemoteRCAKPITreeExplainerFigureParameters,
  useRemoteSourceStated,
} from 'ui-components';
import classes from './remote-rca-kpi-tree-explainer-viewer.module.scss';
import {useContext, useEffect, useRef} from 'react';
import {
  ModelSeriesGranularity,
  SeriesDatetimeAttribute,
} from '../../../../../../objects/models/model-sample-series.model.ts';
import {TreeVariant} from '../../../../../metrics/components/kpi-tree/kpi-tree.consts.ts';
import {KPITree} from '../../../../../metrics/components/kpi-tree/kpi-tree.component.tsx';
import {getRCATreeForMetricNetworkRequest} from '../../../../../../http/metric-tree.network-requests.ts';
import {GenericLoading} from '../../../../components/general/generic-loading/generic-loading.component.tsx';

interface Props extends RemoteFigureElement {
  className?: string;
}

export const RemoteRCAKPITreeExplainerViewer = (props: Props) => {
  const {parameters, className} = props;
  const {teamId} = useContext(GlobalDocumentDataContext);
  const teamIdRef = useRef(teamId);
  const isInitiated = useRef(false);
  const {
    source: teamIdFromServer,
    exec: getRCAMetricTreeForMetric,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getRCATreeForMetricNetworkRequest,
  });

  useEffect(() => {
    getRCAMetricTreeForMetric({
      metricId: parameters.metricId,
      teamId: teamIdRef.current,
    });
    isInitiated.current = true;
  }, [getRCAMetricTreeForMetric, parameters.metricId]);

  if (isLoading || isInitiated.current === false) {
    return <GenericLoading />;
  }

  return (
    <div className={classNames(classes.RemoteRCAKPITreeExplainerViewer, className)}>
      <KPITree
        metricId={parameters.metricId}
        toDate={parameters.analyzedDate}
        datetimeAttribute={SeriesDatetimeAttribute.DATETIME}
        granularity={parameters.granularity as ModelSeriesGranularity}
        variant={TreeVariant.SIMPLE}
        teamId={teamIdFromServer}
        rcaParameters={parameters as RemoteRCAKPITreeExplainerFigureParameters}
        rcaMode={parameters.mode as RCAMode}
        autoLayout
      />
    </div>
  );
};

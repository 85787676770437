import classNames from 'classnames';
import classes from './monitored-metric-header.module.scss';
import {ActionsDropdown, AnalysisFileIcon, EditIcon, MoreIcon, TrashIcon} from 'ui-components';
import TransKeys from 'translations';
import {
  MonitoredMetric,
  MonitoredMetricType,
} from '../../../../../objects/models/health-monitor.model';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {
  getLastSampleTrendValue,
  getLastSampleValue,
} from '../../../../../utils/metric-series.utils';
import {exists} from 'front-core';
import {last} from 'lodash';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';

interface OwnProps {
  monitoredMetric: Partial<MonitoredMetric>;
  onEdit?: () => void;
  onDelete?: () => void;
  onRequestAnalysis: (monitoredMetric: Partial<MonitoredMetric>) => void;
  className?: string;
}

type AllProps = OwnProps;

export const MonitoredMetricHeader = (props: AllProps) => {
  const {monitoredMetric, onEdit, onDelete, onRequestAnalysis, className} = props;
  const {t} = useTranslation();
  const lastSampleValue = useMemo(
    () => (monitoredMetric ? getLastSampleValue(monitoredMetric as any) : undefined),
    [monitoredMetric]
  );
  const lastTrend = useMemo(
    () => (monitoredMetric ? getLastSampleTrendValue(monitoredMetric as any) : undefined),
    [monitoredMetric]
  );
  const showActions = useMemo(() => exists(onEdit), [onEdit]);
  const dateString = useMemo(() => {
    const lastSample = last(monitoredMetric.samples);
    if (!lastSample || !monitoredMetric.cadence) {
      return;
    }
    const startDate = moment(lastSample.date, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
    let endDate = startDate.clone().add(1, monitoredMetric.cadence.toLowerCase() as any);
    if (monitoredMetric.cadence !== 'DAY') {
      endDate = endDate.subtract(1, 'd');
    }
    return `${startDate.format(TIME_FORMATS.READABLE_DATE)} - ${endDate.format(
      TIME_FORMATS.READABLE_DATE
    )}`;
  }, [monitoredMetric]);

  return (
    <div className={classNames(classes.MonitoredMetricHeader, className)}>
      <div className={classes.TitleWrapper}>
        <div className={classes.Title}>{monitoredMetric.name}</div>
        <div className={classes.Actions}>
          {showActions && (
            <ActionsDropdown
              className={classes.Button}
              iconSize={'large'}
              actions={[
                {
                  key: 'edit',
                  title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                  onClick: () => onEdit && onEdit(),
                  icon: EditIcon,
                },
                {
                  key: 'delete',
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete && onDelete(),
                  icon: TrashIcon,
                },
                {
                  key: 'run_rca',
                  title: t(TransKeys.HEALTH_MONITOR.ACTIONS.RUN_RCA),
                  onClick: () => {
                    onRequestAnalysis(monitoredMetric);
                  },
                  icon: AnalysisFileIcon,
                  disabled: monitoredMetric.metricType !== MonitoredMetricType.KPI,
                  helperText:
                    monitoredMetric.metricType !== MonitoredMetricType.KPI
                      ? t(
                          TransKeys.HEALTH_MONITOR.ACTIONS
                            .RUN_RCA_ON_NON_KPI_MONITORED_METRIC_MESSAGE
                        )
                      : '',
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          )}
        </div>
      </div>
      {lastSampleValue && (
        <div className={classes.Infos}>
          <div className={classes.Item}>
            <span className={classNames(classes.Text, classes.Bold)}>
              {t(TransKeys.GENERAL.LABELS.VALUE)}
            </span>
            <span className={classNames(classes.Text, classes.Bold)}>{lastSampleValue}</span>
            {lastTrend && <span className={classNames(classes.Text)}>vs {lastTrend.expected}</span>}
          </div>
          <div className={classes.Separator} />
          <div className={classNames(classes.Item, classes.Gray)}>
            <span className={classNames(classes.Text, classes.Bold)}>{dateString}</span>
          </div>
        </div>
      )}
      {monitoredMetric.shortDescription && (
        <div className={classes.Description}>{monitoredMetric.shortDescription}</div>
      )}
    </div>
  );
};

import {DataPipeline} from '../models/data-pipeline.model.ts';
import yup from '../../config/yup.config.ts';
import httpClientService from '../../services/http-client.service.ts';
import {validateNameNetworkRequest} from '../../http/validations.network-requests.ts';

export type DataPipelineDTO = Partial<DataPipeline>;

export interface ReorderDataPipelineDTO {
  orderedIds: number[];
}

export const dataPipelineValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .matches(
      /^[\w_]{4,}$/,
      'Name must contain at least 4 letters and can contain only letters, and underscores'
    )
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'data-pipeline',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  createSql: yup.string().required(),
  updateSql: yup.string().nullable(),
});

import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';
import {
  FancyBlock,
  RCAMode,
  TeamSelector,
  useRemoteSourceStated,
  UserGroupLightIcon,
} from 'ui-components';
import TransKeys from 'translations';
import {getRCAInsightsExecutiveSummeryNetworkRequest} from '../../../../../../../../http/insights.network-requests';
import {GenericLoading} from '../../../../../../components/general/generic-loading/generic-loading.component';
import classes from './rca-executive-summery.module.scss';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../../../../../constants/app-routes';
import {AnalysisResultStatus} from '../../../../../../../../objects/models/analysis-result.model';
import {RCAExecutiveSummaryOrchestrator} from './components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import {Team} from '../../../../../../../../objects/models/team.model';
import {RCA_SERIALIZATION_ERROR_CODE} from '../../../../../../../insights/rca/rca-insights.consts.ts';

interface OwnProps {
  mode: RCAMode;
  resultId: number;
  resultStatus: AnalysisResultStatus;
  teamId: number;
  setTeamId: (teamId: number) => void;
  teams: Team[];
  enableScrollToElements?: boolean;
  withWrapper?: boolean;
  funnelTargetSignalId?: number;
  subscriptionId?: number;
  teamSelectorDisabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const LoadingComponent = ({withWrapper}: {withWrapper: boolean}) => {
  const {t} = useTranslation();

  const content = (
    <div className={classNames(classes.RCAExecutiveSummaryContainer, classes.Loading)}>
      <GenericLoading />
    </div>
  );

  if (!withWrapper) {
    return content;
  }

  return (
    <FancyBlock title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)} collapsible={true}>
      {content}
    </FancyBlock>
  );
};

export const RCAExecutiveSummery = (props: AllProps) => {
  const {
    [ANALYSIS_RESULT_ID_PATH_PARAM]: resultId,
    mode,
    resultStatus,
    teamId,
    setTeamId,
    teams,
    withWrapper = true,
    funnelTargetSignalId,
    subscriptionId,
    teamSelectorDisabled,
    enableScrollToElements = true,
    className,
  } = props;
  const analysisResultIdRef = useRef<number>(resultId);
  analysisResultIdRef.current = resultId;
  const [deserializationError, setDeserializationError] = useState<boolean | null>(null);

  const {t} = useTranslation();
  const {
    source: executiveSummary,
    exec: getExecutiveSummary,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getRCAInsightsExecutiveSummeryNetworkRequest,
    onError: err => {
      const errorCode = get(err, 'data.error_code');
      if (errorCode === RCA_SERIALIZATION_ERROR_CODE) {
        setDeserializationError(true);
      }
    },
  });

  const showLoading = useMemo(
    () => isLoading || !executiveSummary || resultStatus === AnalysisResultStatus.RUNNING,
    [isLoading, executiveSummary, resultStatus]
  );
  const showTeamSelector = teams.length > 0;
  const teamOptions = useMemo(
    () => [
      {
        id: null,
        name: 'All',
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );

  useEffect(() => {
    const params = {
      resultId: analysisResultIdRef.current,
      teamId,
      funnelTargetSignalId,
      subscriptionId,
    };
    getExecutiveSummary(params);
  }, [getExecutiveSummary, teamId, subscriptionId, funnelTargetSignalId]);

  if (
    deserializationError ||
    [AnalysisResultStatus.RUNNING, AnalysisResultStatus.COMPLETED].includes(resultStatus) === false
  ) {
    return null;
  }

  if (showLoading) {
    return <LoadingComponent withWrapper={withWrapper} />;
  }

  const content = (
    <div className={classNames(classes.RCAExecutiveSummaryContainer, className)}>
      <div className={classes.TeamSelectorWrapper}>
        {showTeamSelector && (
          <TeamSelector
            value={teamId}
            onChange={setTeamId ? setTeamId : undefined}
            teams={teamOptions}
            clearable={false}
            withPrefix
            disabled={teamSelectorDisabled}
          />
        )}
      </div>
      <RCAExecutiveSummaryOrchestrator
        executiveSummary={executiveSummary}
        mode={mode}
        enableScrollToElements={enableScrollToElements}
      />
    </div>
  );

  if (!withWrapper) {
    return content;
  }

  return (
    <FancyBlock title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)} collapsible={true}>
      {content}
    </FancyBlock>
  );
};

export enum ActionKey {
  // APP
  DONT_CARE = 'DONT_CARE',
  APP_FIRST_ACTION = 'APP_FIRST_ACTION',
  INITIAL_LOADING = 'INITIAL_LOADING',
  LOGIN = 'LOGIN',
  GET_APP_STATUS_COUNTERS = 'GET_APP_STATUS_COUNTERS',
  GET_PRODUCT_DATA = 'GET_PRODUCT_DATA',
  CHANGE_DEFAULT_TEAM = 'CHANGE_DEFAULT_TEAM',
  GET_USER_ONBOARDING = 'GET_USER_ONBOARDING',
  DISMISS_USER_ONBOARDING = 'DISMISS_USER_ONBOARDING',

  LOGOUT = 'LOGOUT',
  MOVE_TO_RESET_PASSWORD = 'MOVE_TO_RESET_PASSWORD',
  GET_AUTH_USER = 'GET_AUTH_USER',
  GET_HUBSPOT_VISITOR_TOKEN = 'GET_HUBSPOT_VISITOR_TOKEN',

  CREATE_EXPERIMENT = 'CREATE_EXPERIMENT',
  PATCH_EXPERIMENT = 'PATCH_EXPERIMENT',
  UPDATE_EXPERIMENT = 'UPDATE_EXPERIMENT',
  DELETE_EXPERIMENT = 'DELETE_EXPERIMENT',
  CREATE_GOAL = 'CREATE_GOAL',
  UPDATE_GOAL = 'UPDATE_GOAL',
  DELETE_GOAL = 'DELETE_GOAL',

  CREATE_FUNNEL = 'CREATE_FUNNEL',
  UPDATE_FUNNEL = 'UPDATE_FUNNEL',
  DELETE_FUNNEL = 'DELETE_FUNNEL',
  PUBLISH_FUNNEL = 'PUBLISH_FUNNEL',

  CREATE_FEATURE = 'CREATE_FEATURE',
  UPDATE_FEATURE = 'UPDATE_FEATURE',
  DELETE_FEATURE = 'DELETE_FEATURE',
  CREATE_CONTENT = 'CREATE_CONTENT',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  DELETE_CONTENT = 'DELETE_CONTENT',
  RESCAN_CONTENT = 'RESCAN_CONTENT',
  CREATE_SEGMENT = 'CREATE_SEGMENT',
  UPDATE_SEGMENT = 'UPDATE_SEGMENT',
  RESCAN_SEGMENT = 'RESCAN_SEGMENT',
  VALIDATE_SEGMENT = 'VALIDATE_SEGMENT',
  UPDATE_FAVORITES_SEGMENTS = 'UPDATE_FAVORITES_SEGMENTS',
  ADD_SEGMENT_TO_TEAM = 'ADD_SEGMENT_TO_TEAM',
  DELETE_SEGMENT = 'DELETE_SEGMENT',
  CREATE_SEGMENT_CATEGORY = 'CREATE_SEGMENT_CATEGORY',
  UPDATE_SEGMENT_CATEGORY = 'UPDATE_SEGMENT_CATEGORY',
  DELETE_SEGMENT_CATEGORY = 'DELETE_SEGMENT_CATEGORY',
  SWAP_SEGMENT_CATEGORY = 'SWAP_SEGMENT_CATEGORY',
  CREATE_METRIC = 'CREATE_METRIC',
  UPDATE_METRIC = 'UPDATE_METRIC',
  DELETE_METRIC = 'DELETE_METRIC',
  RESAMPLE_METRIC = 'RESAMPLE_METRIC',
  VALIDATE_METRIC = 'VALIDATE_METRIC',
  ADD_INPUT_METRIC = 'ADD_INPUT_METRIC',
  REMOVE_INPUT_METRIC = 'REMOVE_INPUT_METRIC',
  CHANGE_INPUT_METRIC_RELATIONSHIP_TYPE = 'CHANGE_INPUT_METRIC_RELATIONSHIP_TYPE',
  ADD_METRIC_TO_TREE = 'ADD_METRIC_TO_TREE',
  REMOVE_METRIC_FROM_TREE = 'REMOVE_METRIC_FROM_TREE',
  SET_KPI_TREE_METRIC_FORMULA = 'SET_KPI_TREE_METRIC_FORMULA',
  SET_KPI_TREE_METRIC_POSITIONS = 'SET_KPI_TREE_METRIC_POSITIONS',
  UPDATE_TEAM_METRICS = 'UPDATE_TEAM_METRICS',
  ADD_METRIC_TO_TEAM = 'ADD_METRIC_TO_TEAM',

  SET_METRIC_OWNER = 'SET_METRIC_OWNER',
  SET_METRIC_NORTH_STAR = 'SET_METRIC_NORTH_STAR',
  PUBLISH_METRIC = 'PUBLISH_METRIC',
  CREATE_METRIC_CATEGORY = 'CREATE_METRIC_CATEGORY',
  UPDATE_METRIC_CATEGORY = 'UPDATE_METRIC_CATEGORY',
  DELETE_METRIC_CATEGORY = 'DELETE_METRIC_CATEGORY',
  SWAP_METRIC_CATEGORY = 'SWAP_METRIC_CATEGORY',

  CREATE_MILESTONE = 'CREATE_MILESTONE',
  UPDATE_MILESTONE = 'UPDATE_MILESTONE',
  DELETE_MILESTONE = 'DELETE_MILESTONE',
  VALIDATE_MILESTONE = 'VALIDATE_MILESTONE',

  CREATE_ANALYSIS_FOLDER = 'CREATE_ANALYSIS_FOLDER',
  UPDATE_ANALYSIS_FOLDER = 'UPDATE_ANALYSIS_FOLDER',
  CHANGE_ANALYSIS_FOLDER_ICON = 'CHANGE_ANALYSIS_FOLDER_ICON',
  DELETE_ANALYSIS_FOLDER = 'DELETE_ANALYSIS_FOLDER',

  CREATE_EVENT_GROUP = 'CREATE_EVENT_GROUP',
  UPDATE_EVENT_GROUP = 'UPDATE_EVENT_GROUP',
  DELETE_EVENT_GROUP = 'DELETE_EVENT_GROUP',
  VALIDATE_EVENT_GROUP = 'VALIDATE_EVENT_GROUP',
  CREATE_ANNOTATION = 'CREATE_ANNOTATION',
  UPDATE_ANNOTATION = 'UPDATE_ANNOTATION',
  UPDATE_ANNOTATION_STATUS = 'UPDATE_ANNOTATION_STATUS',
  DELETE_ANNOTATION = 'DELETE_ANNOTATION',
  CREATE_TABLE = 'CREATE_TABLE',
  UPDATE_TABLE = 'UPDATE_TABLE',
  TRIGGER_TABLE_DISCOVERY = 'TRIGGER_TABLE_DISCOVERY',
  TRIGGER_ALL_TABLES_DISCOVERY = 'TRIGGER_ALL_TABLES_DISCOVERY',
  TRIGGER_SCAN_ALL_TYPES_OF_TABLES = 'TRIGGER_SCAN_ALL_TYPES_OF_TABLES',
  REQUEST_ANALYSIS = 'REQUEST_ANALYSIS',
  EDIT_ANALYSIS = 'EDIT_ANALYSIS',
  QUICK_RUN_FOR_ADMINS = 'QUICK_RUN_FOR_ADMINS',
  CREATE_SCHEDULE_ANALYSIS = 'CREATE_SCHEDULE_ANALYSIS',
  EDIT_SCHEDULE_ANALYSIS = 'EDIT_SCHEDULE_ANALYSIS',
  DELETE_SCHEDULE_ANALYSIS = 'DELETE_SCHEDULE_ANALYSIS',
  UPDATE_ANALYSIS_RESULT = 'UPDATE_ANALYSIS_RESULT',
  DELETE_ANALYSIS_RESULT = 'DELETE_ANALYSIS_RESULT',
  CHANGE_ANALYSIS_PARAMETERS = 'CHANGE_ANALYSIS_PARAMETERS',
  RERUN_ANALYSIS_RESULT = 'RERUN_ANALYSIS_RESULT',
  STOP_ANALYSIS_RESULT = 'STOP_ANALYSIS_RESULT',
  CREATE_USER_REACTION = 'CREATE_USER_REACTION',
  DELETE_USER_REACTION = 'DELETE_USER_REACTION',
  SHARE_RESOURCE = 'SHARE_RESOURCE',
  DELETE_SHARE_RESOURCE = 'DELETE_SHARE_RESOURCE',
  SET_RESOURCE_USEFUL = 'SET_RESOURCE_USEFUL',
  CREATE_EXPERIMENT_SUBSCRIPTION = 'CREATE_EXPERIMENT_SUBSCRIPTION',
  EDIT_EXPERIMENT_SUBSCRIPTION = 'EDIT_EXPERIMENT_SUBSCRIPTION',
  DELETE_EXPERIMENT_SUBSCRIPTION = 'DELETE_EXPERIMENT_SUBSCRIPTION',
  RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT = 'RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT',
  CREATE_MONITORED_METRIC = 'CREATE_MONITORED_METRIC',
  EDIT_MONITORED_METRIC = 'EDIT_MONITORED_METRIC',
  REMOVE_MONITORED_METRIC = 'REMOVE_MONITORED_METRIC',
  CREATE_HEALTH_MONITOR_GROUP = 'CREATE_HEALTH_MONITOR_GROUP',
  EDIT_HEALTH_MONITOR_GROUP = 'EDIT_HEALTH_MONITOR_GROUP',
  REMOVE_HEALTH_MONITOR_GROUP = 'REMOVE_HEALTH_MONITOR_GROUP',
  INITIALIZE_HEALTH_MONITOR = 'INITIALIZE_HEALTH_MONITOR',
  CREATE_HEALTH_MONITOR_SUBSCRIPTION = 'CREATE_HEALTH_MONITOR_SUBSCRIPTION',
  EDIT_HEALTH_MONITOR_SUBSCRIPTION = 'EDIT_HEALTH_MONITOR_SUBSCRIPTION',
  DELETE_HEALTH_MONITOR_SUBSCRIPTION = 'DELETE_HEALTH_MONITOR_SUBSCRIPTION',
  GET_USER_SETTINGS = 'GET_USER_SETTINGS',
  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  CHANGE_TEAM_FOR_MEMBER = 'CHANGE_TEAM_FOR_MEMBER',
  CHANGE_PERMISSION_ROLE_FOR_MEMBER = 'CHANGE_PERMISSION_ROLE_FOR_MEMBER',
  UPDATE_USER_NOTIFICATIONS_CONFIG = 'UPDATE_USER_NOTIFICATIONS_CONFIG',
  REMOVE_SLACK_FROM_PRODUCT = 'REMOVE_SLACK_FROM_PRODUCT',
  TRIGGER_SOURCE_TABLE_DISCOVERY = 'TRIGGER_SOURCE_TABLE_DISCOVERY',
  MOVE_ANALYSIS_TO_FOLDER = 'MOVE_ANALYSIS_TO_FOLDER',
  INVITE_USERS = 'INVITE_USERS',
  CREATE_FEEDBACK = 'CREATE_FEEDBACK',
  CREATE_USER_ACTIVITY = 'CREATE_USER_ACTIVITY',
  UPDATE_USER_ONBOARDING_QUESTIONNAIRE = 'UPDATE_USER_ONBOARDING_QUESTIONNAIRE',
  ON_USER_ONBOARDING_QUESTIONNAIRE_COMPLETED = 'ON_USER_BOARDING_QUESTIONNAIRE_COMPLETED',
  CREATE_RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS = 'CREATE_RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS',
  CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS = 'CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS',
  CREATE_GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS = 'CREATE_GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS',
  CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS = 'CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS',
  CREATE_RCA_FROM_SEGMENTATION_EXPLAINER = 'CREATE_RCA_FROM_SEGMENTATION_EXPLAINER',
  MOVE_ANALYSIS_FOLDER = 'MOVE_ANALYSIS_FOLDER',

  ADD_MODEL_TO_HOMEPAGE = 'ADD_MODEL_TO_HOMEPAGE',
  REMOVE_MODEL_FROM_HOMEPAGE = 'REMOVE_MODEL_FROM_HOMEPAGE',
  UPDATE_HOMEPAGE_SUBSCRIPTION = 'UPDATE_HOMEPAGE_SUBSCRIPTION',
  CREATE_HOMEPAGE_SUBSCRIPTION = 'CREATE_HOMEPAGE_SUBSCRIPTION',
  DELETE_HOMEPAGE_SUBSCRIPTION = 'DELETE_HOMEPAGE_SUBSCRIPTION',
  REORDER_HOMEPAGE = 'REORDER_HOMEPAGE',
  TEST_HOMEPAGE_SUBSCRIPTION = 'TEST_HOMEPAGE_SUBSCRIPTION',
  RESAMPLE_MODEL = 'RESAMPLE_MODEL',

  LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS',
  MARK_NOTIFICATIONS_AS_SEEN = 'MARK_NOTIFICATIONS_AS_SEEN',
  MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ',

  CREATE_EXPERIMENT_AUTOMATION = 'CREATE_EXPERIMENT_AUTOMATION',
  UPDATE_EXPERIMENT_AUTOMATION = 'UPDATE_EXPERIMENT_AUTOMATION',
  DELETE_EXPERIMENT_AUTOMATION = 'DELETE_EXPERIMENT_AUTOMATION',

  UPDATE_EXPERIMENT_TEAM = 'UPDATE_EXPERIMENT_TEAM',
  SET_EXPERIMENT_OWNER = 'SET_EXPERIMENT_OWNER',

  CREATE_WORKFLOW = 'CREATE_WORKFLOW',
  EXPORT_WORKFLOW = 'EXPORT_WORKFLOW',
  UPDATE_WORKFLOW = 'UPDATE_WORKFLOW',
  DELETE_WORKFLOW = 'DELETE_WORKFLOW',
  SET_WORKFLOW_OWNER = 'SET_WORKFLOW_OWNER',
  UPDATE_WORKFLOW_TEAM = 'UPDATE_WORKFLOW_TEAM',
  UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS',
  RENAME_WORKFLOW = 'RENAME_WORKFLOW',

  CREATE_TEAM = 'CREATE_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  DELETE_TEAM = 'DELETE_TEAM',

  CREATE_SOURCE = 'CREATE_SOURCE',

  CREATE_DATA_PIPELINE = 'CREATE_DATA_PIPELINE',
  UPDATE_DATA_PIPELINE = 'UPDATE_DATA_PIPELINE',
  DELETE_DATA_PIPELINE = 'DELETE_DATA_PIPELINE',
  REORDER_DATA_PIPELINE = 'REORDER_DATA_PIPELINE',
}

import {
  FunnelAnalysisConversionFollowUpActionsDTO,
  GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  RCAFromLineChartFollowUpActionsDTO,
  RCAFromSegmentationExplainerFollowUpActionsDTO,
} from '../../objects/dto/follow-up-actions.dto';
import {action, OnErrorActionHook, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import {t} from 'i18next';
import TransKeys from 'translations';

export enum FollowUpActionsActionType {
  RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS = '@@follow_up_actions/RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS',
  CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS',
  CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS',
  CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS = '@@follow_up_actions/CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS',
  CREATE_RCA_FROM_SEGMENTATION_EXPLAINER_FOLLOW_UP = '@@follow_up_actions/CREATE_RCA_FROM_SEGMENTATION_EXPLAINER_FOLLOW_UP',
}

export const createRCAFromLineChartFollowUpAction = (
  data: RCAFromLineChartFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(FollowUpActionsActionType.RCA_FROM_LINE_CHART_FOLLOW_UP_ACTIONS, data, {
    onSuccess,
  });

export const createFunnelAnalysisConversionFollowUpActions = (
  data: FunnelAnalysisConversionFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(FollowUpActionsActionType.CREATE_FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS, data, {
    onSuccess,
  });

export const createGoalDriversAnalysisImproveFeatureAdoptionFollowUpActions = (
  data: GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(
    FollowUpActionsActionType.CREATE_GOAL_ANALYSIS_DRIVERS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS,
    data,
    {
      onSuccess,
    }
  );

export const createRetentionAnalysisInvestigateBucketFollowUpActions = (
  data: RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(
    FollowUpActionsActionType.CREATE_RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS,
    data,
    {
      onSuccess,
    }
  );

export const createRCAFromSegmentationExplainerFollowUpActionsConfirmed = (
  data: RCAFromSegmentationExplainerFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  showConfirmationMessage(
    {
      title: t(TransKeys.CONFIRMATIONS.CREATE_RCA_FOLLOW_UP_FROM_SEGMENT_EXPLAINER.TITLE),
      content: t(TransKeys.CONFIRMATIONS.CREATE_RCA_FOLLOW_UP_FROM_SEGMENT_EXPLAINER.CONTENT),
      approveBtn: t(TransKeys.GENERAL.ACTIONS.CREATE),
    },
    () => [createRCAFromSegmentationExplainerFollowUpActions(data, onSuccess)]
  );

export const createRCAFromSegmentationExplainerFollowUpActions = (
  data: RCAFromSegmentationExplainerFollowUpActionsDTO,
  onSuccess?: OnSuccessActionHook,
  onError?: OnErrorActionHook
) =>
  action(FollowUpActionsActionType.CREATE_RCA_FROM_SEGMENTATION_EXPLAINER_FOLLOW_UP, data, {
    onSuccess,
    onError,
  });

import {useCallback, useRef, useState} from 'react';
import {ActionsDropdown, MoreIcon} from 'ui-components';
import classes from './thread-list-item.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

type ThreadListItemProps = {
  title: string;
  onEdit?: (newTitle: string) => void;
  onDelete?: () => void;
};

export const ThreadListItem = (props: ThreadListItemProps) => {
  const {title, onEdit, onDelete} = props;
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const inputRef = useRef<HTMLInputElement>();
  const {t} = useTranslation();

  const onEditMode = useCallback(() => {
    setEditMode(true);
    setInputValue(title);
    setTimeout(() => inputRef.current.focus(), 0);
  }, [setEditMode, title]);

  const onBlur = useCallback(() => {
    if (inputValue === '' || inputValue === title) {
      setEditMode(false);
      return;
    }
    onEdit(inputValue);
    setEditMode(false);
  }, [inputValue, onEdit, title]);

  return (
    <>
      {editMode ? (
        <input
          ref={inputRef}
          className={classes.EditThreadTitleInput}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onBlur={() => onBlur()}
        />
      ) : (
        <span className={classes.Title}>{title}</span>
      )}
      <ActionsDropdown
        iconDropdown
        border={false}
        className={classes.MoreActions}
        icon={MoreIcon}
        actions={[
          {key: 'rename', title: t(TransKeys.GENERAL.ACTIONS.RENAME), onClick: () => onEditMode()},
          {key: 'delete', title: t(TransKeys.GENERAL.ACTIONS.DELETE), onClick: () => onDelete()},
        ]}
      />
    </>
  );
};

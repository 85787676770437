import {useMemo, useState} from 'react';
import {RCASegmentationFigureOptions, MetricSampleData} from '../../../../../../types';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import pluralize from 'pluralize';

export enum ChartMode {
  KPI_VALUE,
  ENTITIES_COUNT,
}

export const formatNumber = (value: number, isPercentage: boolean) =>
  isPercentage ? value * 100 : value;

export const useSegmentChartOvertimeChartData = (config: {
  selectedSegmentKeys: string[];
  segmentsByKey: {[key: string]: UnifiedRCASegmentationItem};
  options: RCASegmentationFigureOptions;
  metricSamples: MetricSampleData[];
  chartMode: ChartMode;
  granularity: string;
  showOvertimeChart: boolean;
  showAllPopulation: boolean;
}) => {
  const {
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
    showOvertimeChart,
  } = config;

  return useMemo(() => {
    if (!showOvertimeChart) {
      return null;
    }
    const segments = selectedSegmentKeys.map(k => segmentsByKey[k]).filter(s => s);
    const kpiDataset =
      metricSamples && showAllPopulation
        ? {
            id: 'kpi',
            label: options.kpiName,
            data: metricSamples.map(s => ({
              x: s.datetime,
              y:
                chartMode === ChartMode.KPI_VALUE
                  ? formatNumber(s.value, options.isPercentageValue)
                  : s.entityCount,
            })),
          }
        : undefined;
    const segmentsDatasets = segments
      // We filter out segments that are event properties because they don't have samples (and we don't want to show them in the chart)
      .filter(s => !s.isEventProperty)
      .map(segment => {
        const data = segment.samples.map(s => ({
          x: s.datetime,
          y:
            chartMode === ChartMode.KPI_VALUE
              ? formatNumber(s.value, options.isPercentageValue)
              : s.entityCount,
        }));
        return {
          id: segment.key,
          label: segment.name,
          data,
        };
      });
    const datasets = kpiDataset ? [kpiDataset, ...segmentsDatasets] : segmentsDatasets;

    if (datasets.length === 0) {
      return null;
    }

    return {
      datasets: datasets,
      options: {
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: granularity,
        },
        disableLegend: true,
        yLabelSuffix:
          chartMode === ChartMode.KPI_VALUE
            ? options.isPercentageValue
              ? '%'
              : undefined
            : undefined,
        yAxisMaxTicks: 5,
        xLabel: 'date',
      },
    } as any;
  }, [
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
    showOvertimeChart,
  ]);
};

export const useSegmentChart = (props: {
  selectedSegmentKeys: string[];
  segmentsByKey: {[key: string]: UnifiedRCASegmentationItem};
  viewMode: RCASegmentationViewMode;
  showAllPopulation: boolean;
  metricSamples: MetricSampleData[];
  options: RCASegmentationFigureOptions;
  granularity: string;
  showOvertimeChart: boolean;
}) => {
  const {
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    granularity,
    showAllPopulation,
    showOvertimeChart,
  } = props;

  const [chartMode, setChartMode] = useState(ChartMode.KPI_VALUE);

  const chartData = useSegmentChartOvertimeChartData({
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
    showOvertimeChart,
  });
  const chartOptions = useMemo(
    () => [
      {
        label: 'KPI Value',
        onClick: () => setChartMode(ChartMode.KPI_VALUE),
        isActive: chartMode === ChartMode.KPI_VALUE,
      },
      {
        label: `# ${pluralize(options.entity)}`,
        onClick: () => setChartMode(ChartMode.ENTITIES_COUNT),
        isActive: chartMode === ChartMode.ENTITIES_COUNT,
      },
    ],
    [chartMode, options.entity]
  );

  return {
    chartData,
    chartOptions,
    chartMode,
    setChartMode,
  };
};

import classNames from 'classnames';
import classes from './exploration-controller.module.scss';
import {Button, IconButton, PlusRegularIcon} from 'ui-components';
import {PopulationSegmentQueryBuilder} from '../../../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component.tsx';
import {ControlBox} from '../control-box/control-box.component.tsx';
import {DEFAULT_EXPLORE_DATA} from '../../exploration-main.component.tsx';
import {useCallback, useMemo, useState} from 'react';
import {exists} from 'front-core';
import {EntityPicker} from '../../../../../dmp/components/entity-picker/entity-picker.component.tsx';
import {TableEntity} from '../../../../../../objects/models/table.model.ts';
import {MetricItemBuilder} from '../metric-item-builder/metric-item-builder.component.tsx';
import {get} from 'lodash';
import {ExploreData, ExploreItem} from '../../../../../../objects/dto/exploration.dto.ts';

interface Props {
  exploreData: ExploreData;
  onChange: (data: ExploreData) => void;
  onSubmit: () => void;
  errors?: any;
  disabled?: any;
  className?: string;
}

export const ExplorationController = (props: Props) => {
  const {exploreData, errors, onChange, onSubmit, disabled, className} = props;
  const [showFilter, setShowFilter] = useState(false);

  const onChangePopulationFilter = useCallback(
    (query: any) =>
      onChange({
        ...exploreData,
        populationFilter: query,
      }),
    [exploreData, onChange]
  );
  const onChangeEntity = useCallback(
    (entity: TableEntity) =>
      onChange({
        ...DEFAULT_EXPLORE_DATA,
        entity,
      }),
    [onChange]
  );
  const addDisabled = useMemo(() => exploreData.items.some(item => item === null), [exploreData]);
  const onAddMetric = useCallback(
    () => onChange({...exploreData, items: [...exploreData.items, null]}),
    [exploreData, onChange]
  );
  const onChangeItem = useCallback(
    (item: ExploreItem, index: number) => {
      const items = [...exploreData.items];
      items[index] = item;
      onChange({...exploreData, items});
    },
    [exploreData, onChange]
  );
  const onRemoveItem = useCallback(
    (idx: number) => {
      const items = [...exploreData.items];
      items.splice(idx, 1);
      onChange({...exploreData, items});
    },
    [exploreData, onChange]
  );

  return (
    <div className={classNames(classes.ExplorationController, className)}>
      <div className={classes.Main}>
        {/*Entity*/}
        <div className={classes.Section}>
          <div className={classes.Header}>
            <div className={classes.Title}>Entity</div>
            <div className={classes.Actions}>
              <EntityPicker
                value={exploreData.entity}
                onChange={onChangeEntity}
                editMode
                variant={'select'}
              />
            </div>
          </div>
        </div>
        {/*METRICS*/}
        <div className={classNames(classes.Section, exists(errors?.items) && classes.SectionError)}>
          <div className={classes.Header}>
            <div className={classes.Title}>Metrics</div>
            <div className={classes.Actions}>
              <IconButton
                icon={PlusRegularIcon}
                onClick={onAddMetric}
                size={'small'}
                tooltipText={'Add metric'}
                disabled={addDisabled}
              />
            </div>
          </div>
          <div className={classes.Content}>
            {exploreData.items.map((item, idx) => (
              <ControlBox>
                <MetricItemBuilder
                  index={idx}
                  item={item}
                  entity={exploreData.entity}
                  onChangeItem={v => onChangeItem(v, idx)}
                  onRemoveItem={() => onRemoveItem(idx)}
                  errors={get(errors, `items.${idx}`)}
                />
              </ControlBox>
            ))}
          </div>
        </div>
        {/*Filter*/}
        <div
          className={classNames(
            classes.Section,
            exists(errors?.populationFilter) && classes.SectionError
          )}
        >
          <div className={classes.Header}>
            <div className={classes.Title}>Filter</div>
          </div>
          <div className={classes.Content}>
            <ControlBox>
              {!exists(exploreData.populationFilter) && !showFilter && (
                <div className={classes.FilterEmptyState}>
                  <span>All users</span>
                  <span className={classes.Add} onClick={() => setShowFilter(true)}>
                    +Add filter
                  </span>
                </div>
              )}
              {(exists(exploreData.populationFilter) || showFilter) && (
                <PopulationSegmentQueryBuilder
                  query={exploreData.populationFilter}
                  onChange={onChangePopulationFilter}
                  entityContext={exploreData.entity}
                  errors={errors?.populationFilter}
                />
              )}
            </ControlBox>
          </div>
        </div>
        {/*Group BY*/}
        <div
          className={classNames(
            classes.Section,
            exists(errors?.groupBySignalIds) && classes.SectionError
          )}
        >
          <div className={classes.Header}>
            <div className={classes.Title}>Group By</div>
            <div className={classes.Actions}>
              <IconButton
                icon={PlusRegularIcon}
                onClick={console.log}
                size={'small'}
                tooltipText={'Add group by'}
              />
            </div>
          </div>
          <div className={classes.Content}>
            <span className={classes.EmptyStateText}>Group by empty state text</span>
          </div>
        </div>
      </div>
      <div className={classes.Footer}>
        <Button onClick={onSubmit} size={'large'} className={classes.Submit} disabled={disabled}>
          Submit
        </Button>
      </div>
    </div>
  );
};

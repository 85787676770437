import {useCallback, useContext, useEffect, useMemo} from 'react';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getSlackChannelsNetworkRequest} from '../../../../http/slack-channels.network-requests';
import classes from './homepage-subscription-panel.module.scss';
import {Button, FancyHeader, ModalLayout, RSSIcon, useRemoteSourceStated} from 'ui-components';
import {FormProvider, useForm} from 'react-hook-form';
import {
  HomepageSettings,
  HomepageSubscribedSegmentsType,
  HomepageSubscription,
  HomepageSubscriptionInitiatedFrom,
  HomepageSubscriptionUnit,
  HomepageSubscriptionVIA,
} from '../../../../objects/models/homepage.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {SlackChannel} from '../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useDispatch, useSelector} from 'react-redux';
import {
  createHomepageSubscription,
  updateHomepageSubscription,
} from '../../../../store/homepage/homepage.actions';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {
  getHomepageSettingsNetworkRequest,
  getHomepageSubscriptionNetworkRequest,
} from '../../../../http/homepage.network-requests';
import {composition, exists, OnSuccessActionHook} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {
  HOMEPAGE_ID_PATH_PARAM,
  SUBSCRIPTION_ID_PATH_PARAM,
  TEAM_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {Team} from '../../../../objects/models/team.model';
import {SubscriptionSampledModelsConfig} from './components/subscription-sampled-models-config/subscription-sampled-models-config.component.tsx';
import {createDefaultSubscription} from './homepage-subscription-panel.utils.ts';
import {HomepageSelector} from '../../components/homepage-summary/components/homepage-selector/homepage-selector.component.tsx';
import {Divider} from '@material-ui/core';
import {HomepageQuickSubscriptionForm} from '../../components/homepage-summary/components/homepage-quick-subscription-form/homepage-quick-subscription-form.component.tsx';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook.ts';
import {SubscribedSegmentsSelector} from './components/subscribed-segments-selector/subscribed-segments-selector.component.tsx';
import {PanelKey} from '../../../../constants/panels.ts';
import {PanelsContext} from '../../../../core/contexts/panels.context.tsx';
import {createHomepageSubscriptionValidator} from '../../../../objects/dto/homepage-subscription.dto.ts';
import {SegmentBreakdownSelector} from './components/segment-breakdown-selector/segment-breakdown-selector.component.tsx';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags.ts';

interface OwnProps {
  [HOMEPAGE_ID_PATH_PARAM]: number;
  [SUBSCRIPTION_ID_PATH_PARAM]?: number;
  onClose?: () => void;
  panelId?: string;
  homepageSettings: HomepageSettings;
  subscription?: HomepageSubscription;
  channels?: SlackChannel[];
  data: Partial<HomepageSubscription>;
  onSubmit?: (data: Partial<HomepageSubscription>) => void;
  onSuccess?: OnSuccessActionHook;
  cloneMode?: boolean;
  disabled?: boolean;
}

type AllProps = OwnProps;

const HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY = 'HOMEPAGE/PRODUCT_SLACK_CHANNELS';
const HOMEPAGE_SUBSCRIPTION_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SUBSCRIPTION';
const HOMEPAGE_SETTINGS_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SETTINGS';
const RECIPIENTS_ELEMENT_ID = 'recipients_element';

const HomepageSubscriptionPanelComponent = (props: AllProps) => {
  const {
    [HOMEPAGE_ID_PATH_PARAM]: homepageId,
    subscription,
    data = {},
    onSubmit: onSubmitFromProps,
    onSuccess,
    homepageSettings,
    onClose,
    cloneMode,
    disabled,
  } = props;
  const {teams} = useProductData();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const showBreakdown = useFeatureIsOn(FeatureFlag.SUBSCRIPTION_SEGMENT_BREAKDOWN as string);
  const currentUser = useCurrentUser();

  const {
    source: homepageSampledModels,
    exec: getSampledModels,
    isLoading: isLoadingSampledModels,
  } = useRemoteSourceStated({
    networkRequest: multiLoaderNetworkRequest,
  });

  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);
  const defaultEmails = useMemo(() => ({emails: [currentUser?.email]}), [currentUser]);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...createDefaultSubscription(homepageSettings),
      ...defaultEmails,
      ...data,
      ...subscription,
      ...overrideData,
    } as any,
    resolver: yupResolver(createHomepageSubscriptionValidator(currentUser.email)),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {isSubmitting, isValidating},
  } = formMethods;
  const subscribedSegmentsType = watch('subscribedSegmentsType');
  const breakdownSegmentIds = watch('breakdownSegmentIds');
  const excludeNonInformativeSegments = watch('excludeNonInformativeSegments');
  const excludeCompositeSegments = watch('excludeCompositeSegments');
  const editMode = Boolean(watch('id'));
  const selectedTeam: Team = useMemo(
    () => teams.find(t => t.homepageId === homepageId),
    [homepageId, teams]
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.UPDATE_HOMEPAGE_SUBSCRIPTION)(state)
  );
  const isDisabled = Boolean(isLoading || isSubmitting || isValidating || disabled);
  const showLoading = Boolean(isLoadingSampledModels || isLoading);
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose?.();
        openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_SUCCESS_PANEL, {
          subscription: res,
          homepageId,
          action: editMode ? 'edited' : 'created',
        });
      };

      const via = [];

      if (exists(data.emails?.[0]?.trim())) {
        via.push(HomepageSubscriptionVIA.MAIL);
      }

      if (data.slackChannels?.length > 0) {
        via.push(HomepageSubscriptionVIA.SLACK);
      }
      const action = editMode ? updateHomepageSubscription : createHomepageSubscription;

      const moreData = !editMode
        ? {initiatedFrom: HomepageSubscriptionInitiatedFrom.CUSTOM_FORM}
        : {};

      dispatch(action({...data, via, ...moreData, homepageId}, onActionSuccess));
    },
    [dispatch, onClose, onSuccess, openSecondaryPanel, onSubmitFromProps, editMode, homepageId]
  );

  useEffect(() => {
    if (homepageSettings && (homepageSettings.metrics.length || homepageSettings.funnels.length)) {
      getSampledModels({
        metrics: homepageSettings.metrics,
        funnels: homepageSettings.funnels,
      });
    }
  }, [getSampledModels, homepageSettings]);
  const onTeamSegmentsClick = useCallback(() => {
    openSecondaryPanel(PanelKey.FAVORITES_SEGMENTS_PANEL, {
      [TEAM_ID_PATH_PARAM]: selectedTeam.id,
    });
  }, [openSecondaryPanel, selectedTeam]);
  const onError = useCallback(errors => {
    if ('emails' in errors || 'slackChannels' in errors) {
      const element = document.getElementById(RECIPIENTS_ELEMENT_ID);
      element && element.scrollIntoView({behavior: 'smooth'});
    }
  }, []);

  return (
    <div className={classes.HomepageSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={isDisabled} onClick={handleSubmit(onSubmit, onError)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {showLoading && <GenericLoading />}
        <FancyHeader icon={RSSIcon} title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)} />
        <FormProvider {...formMethods}>
          <div className={classes.Content}>
            <div className={classes.HomepageSelectorContainer}>
              <div className={classes.HomepageSelectorLabel}>Homepage</div>
              <HomepageSelector selectedHomepageId={homepageId} viewOnly />
            </div>
            <Divider dir="horizontal" />
            <div className={classes.Block} id={RECIPIENTS_ELEMENT_ID}>
              <div className={classes.Header}>
                <div className={classes.Title}>Select where to send KPI updates to</div>
                <HomepageQuickSubscriptionForm />
              </div>
            </div>
            <Divider dir="horizontal" />
            {!showLoading && homepageSampledModels && (
              <>
                <SubscriptionSampledModelsConfig
                  homepageSettings={homepageSettings}
                  sampledModelsPushSettings={homepageSampledModels}
                  homepageSubscriptionUnit={HomepageSubscriptionUnit.WEEKLY}
                />
                <Divider dir="horizontal" />
                <SubscriptionSampledModelsConfig
                  homepageSettings={homepageSettings}
                  sampledModelsPushSettings={homepageSampledModels}
                  homepageSubscriptionUnit={HomepageSubscriptionUnit.MONTHLY}
                />
                <Divider dir="horizontal" />
                <SubscriptionSampledModelsConfig
                  homepageSettings={homepageSettings}
                  sampledModelsPushSettings={homepageSampledModels}
                  homepageSubscriptionUnit={HomepageSubscriptionUnit.DAILY}
                />
                <Divider dir="horizontal" />
                <SubscribedSegmentsSelector
                  subscribedSegmentsType={
                    subscribedSegmentsType as unknown as HomepageSubscribedSegmentsType
                  }
                  onChangeSubscribedSegmentsType={type => setValue('subscribedSegmentsType', type)}
                  team={selectedTeam}
                  onTeamSegmentsClick={onTeamSegmentsClick}
                  excludeNonInformativeSegments={excludeNonInformativeSegments}
                  onChangeExcludeNonInformativeSegments={value =>
                    setValue('excludeNonInformativeSegments', value)
                  }
                  excludeCompositeSegments={excludeCompositeSegments}
                  onChangeExcludeCompositeSegments={value =>
                    setValue('excludeCompositeSegments', value)
                  }
                />
                {showBreakdown && (
                  <SegmentBreakdownSelector
                    breakdownSegmentIds={breakdownSegmentIds}
                    onChangeBreakdownSegmentIds={value => setValue('breakdownSegmentIds', value)}
                    className={classes.SegmentBreakdownSelector}
                  />
                )}
                <div className={classes.SpaceBottom} />
              </>
            )}
          </div>
        </FormProvider>
      </ModalLayout>
    </div>
  );
};

export const HomepageSubscriptionPanel = composition<AllProps>(
  HomepageSubscriptionPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    channels: {
      selectedKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      actionKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      request: getSlackChannelsNetworkRequest,
    },
    homepageSettings: {
      selectedKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      actionKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      request: getHomepageSettingsNetworkRequest,
      mapPayloadFromProps: props => props[HOMEPAGE_ID_PATH_PARAM],
    },
    subscription: {
      shouldCall: props => exists(props[SUBSCRIPTION_ID_PATH_PARAM]),
      selectedKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      actionKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      request: getHomepageSubscriptionNetworkRequest,
      mapPayloadFromProps: props => ({
        homepageId: props[HOMEPAGE_ID_PATH_PARAM],
        subscriptionId: props[SUBSCRIPTION_ID_PATH_PARAM],
      }),
    },
  })
);

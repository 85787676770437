import {RCAInsightsSentenceKeys} from './rca-insights.model.ts';
import {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter} from './segment-sentences/spike-drop-over-under-performing-segment-share-increase-decrease-and-kpi-increase-decrease.component.tsx';
import {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter} from './segment-sentences/spike-drop-over-under-performing-segment-share-increase-decrease.component.tsx';
import {SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter} from './segment-sentences/spike-drop-over-under-performing-increased-decreased-kpi.component.tsx';
import {NoExplainersFormatter} from './segment-sentences/no-explainers.component.tsx';
import {NotSignificantChangeKPIAndUsersFormatter} from './segment-sentences/not-significant-change-kpi-and-users.component.tsx';
import {NotSignificantChangeKPIFormatter} from './segment-sentences/not-significant-change-kpi.component.tsx';
import {NotSignificantUsersChangeFormatter} from './segment-sentences/not-significant-users-change.component.tsx';
import {SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter} from './population-size-sentences/spike-drop-population-size-and-sampled-model-increase-decrease.component.tsx';
import {ExperimentImpactSentenceFormatter} from './experiment-sentences/experiment-impact.component.tsx';
import {InputKpiImpactSentenceFormatter} from './input-kpi-sentences/input-kpi-impact.component.tsx';
import {EntityExplainerSentenceFormatter} from './entity-explainer-sentence/entity-explainer-sentence.component.tsx';
import {SeasonalityExplainerSentenceFormatter} from './seasonality-explainer-sentence/seasonality-explainer-sentence.component.tsx';

export const RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING = {
  // Segment Explainers sentences start
  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE]:
    SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI]:
    SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter,
  [RCAInsightsSentenceKeys.NO_EXPLAINERS]: NoExplainersFormatter,
  // Segment Explainers sentences end
  // Interesting Segments sentences start
  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS]:
    NotSignificantChangeKPIAndUsersFormatter,
  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_CHANGE_KPI]: NotSignificantChangeKPIFormatter,
  [RCAInsightsSentenceKeys.NOT_SIGNIFICANT_USERS_CHANGE]: NotSignificantUsersChangeFormatter,
  // Interesting Segments sentences end
  // Population Size sentences start
  [RCAInsightsSentenceKeys.SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASE]:
    SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE]:
    SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASED]:
    SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter,
  [RCAInsightsSentenceKeys.DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE]:
    SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter,
  // Population Size sentences end
  // Experiment Impact sentences start
  [RCAInsightsSentenceKeys.EXPERIMENT_IMPACT]: ExperimentImpactSentenceFormatter,
  // Experiment Impact sentences end
  // Input KPI Impact sentences start
  [RCAInsightsSentenceKeys.INPUT_KPI_IMPACT]: InputKpiImpactSentenceFormatter,
  [RCAInsightsSentenceKeys.ENTITY_IMPACT]: EntityExplainerSentenceFormatter,
  [RCAInsightsSentenceKeys.SEASONALITY_IMPACT]: SeasonalityExplainerSentenceFormatter,
};

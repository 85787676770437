import classNames from 'classnames';
import classes from './subscription-report-kpi-item.module.scss';
import {useMemo} from 'react';
import {
  formatDateRange,
  generateXoXLabel,
  ROOT_ELEMENT_ID,
  toWeeklySummaryRowItem,
} from '../subscription-report-page.utils.ts';
import {WeeklyReportItem, WeeklyReportSubscriptionData} from '../subscription-report-page.types.ts';
import {
  ArrowNarrowUpIcon,
  ArrowUpRightFromSquareRegularIcon,
  ChevronUpRegularIcon,
  FancyBlock,
  getPercentageValueFormatter,
  LineChart,
  RCAMode,
} from 'ui-components';
import {RCAExecutiveSummaryOrchestrator} from '../../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {AppRoutes} from '../../../../../constants/app-routes.ts';
import {TIME_FORMATS} from '../../../../../constants/time-formats.ts';
import moment from 'moment/moment';
import {RCA_DATE_FORMAT} from 'ui-components/src/components/complex/document-viewer/components/viewers/rca-analysis/rca-overview-viewer/rca-overview-utils.ts';
import {SubscriptionSegmentationBreakdown} from './subscription-segmentation-breakdown/subscription-segmentation-breakdown.component.tsx';

interface Props {
  id: string;
  data: WeeklyReportItem;
  subscriptionData: WeeklyReportSubscriptionData;
  teamId?: number;
  subscriptionId: number;
  className?: string;
}

const GOOD_COLOR = 'green';
const BAD_COLOR = 'red';

export const SubscriptionReportKPIItem = (props: Props) => {
  const {id, data, teamId, subscriptionId, className} = props;
  const item = useMemo(() => toWeeklySummaryRowItem(data), [data]);
  const {t} = useTranslation();

  const chartData = useMemo(() => {
    const dataset = {
      id: `metric_${data.metricId}`,
      label: data.insights.sampledModelXoxOverview.sampledModelName,
      data: data.rcaOverviewMetric.samples.map(s => {
        let markColor = undefined;
        let isSignificantPositive = undefined;

        if (s.value > s.upper) {
          markColor = item.higherIsBetter ? GOOD_COLOR : BAD_COLOR;
          isSignificantPositive = true;
        } else if (s.value < s.lower) {
          markColor = item.higherIsBetter ? BAD_COLOR : GOOD_COLOR;
          isSignificantPositive = false;
        }
        const additions = {};
        if (
          data.analyzedDate &&
          moment
            .utc(data.analyzedDate, RCA_DATE_FORMAT)
            .isSame(moment.utc(s.datetime, RCA_DATE_FORMAT))
        ) {
          additions['isTrend'] = true;
          additions['trendDescription'] = 'ANALYZED TREND';
          additions['trendColor'] = '#AB55F8';
        }

        return {
          x: s.datetime,
          y: item.isPercentageValue ? s.value * 100 : s.value,
          upper: item.isPercentageValue ? s.upper * 100 : s.upper,
          lower: item.isPercentageValue ? s.lower * 100 : s.lower,
          markColor,
          isSignificantPositive,
          ...additions,
        };
      }),
    };

    return {
      datasets: [dataset],
      options: {
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: data.rcaOverviewMetric.granularity,
        },
        yLabel: item.name,
        yLabelSuffix: item.isPercentageValue ? '%' : undefined,
        errorBar: true,
        yAxisMaxTicks: 5,
        xLabel: 'Date',
        showLegend: false,
      },
    } as any;
  }, [data, item]);
  const url = useMemo(() => {
    const query = {
      date: item.datetime,
      granularity: item.granularity,
      rcaMode: RCAMode.COMPARE_TO_DATE,
      subscriptionId,
    };
    if (teamId) {
      query['teamId'] = teamId;
    }
    const path = AppRoutes.viewMetric(data.metricId, query);
    return `${window.location.origin}${path.pathname + '?' + path.search}`;
  }, [data.metricId, item.datetime, item.granularity, teamId, subscriptionId]);

  return (
    <div className={classNames(classes.SubscriptionReportKPIItem, className)} id={id}>
      <div className={classes.Header}>
        <div className={classes.Title}>{item.name}</div>
        <div className={classes.Actions}>
          <a href={`#${ROOT_ELEMENT_ID}`} className={classes.NavigateTop}>
            <ChevronUpRegularIcon />
          </a>
          <a className={classes.Button} href={url}>
            <span>{t(TransKeys.SUBSCRIPTION_REPORT_PAGE.KPI_ITEM.SEE_FULL_REPORT)}</span>
            <ArrowUpRightFromSquareRegularIcon />
          </a>
        </div>
      </div>
      <div className={classes.Stats}>
        <div className={classes.StatItem}>
          <div className={classes.Label}>
            {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.LAST_VALUE)}
          </div>
          <div className={classes.Content}>
            <span className={classes.Date}>
              {formatDateRange(item.datetime, item.granularity)}:
            </span>
            <span className={classes.Value}>
              {getPercentageValueFormatter(item.isPercentageValue)(item.value)}
            </span>
          </div>
        </div>
        <div className={classes.StatItem}>
          <div className={classes.Label}>
            {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.PREV_VALUE)}
          </div>
          <div className={classes.Content}>
            <span className={classes.Date}>
              {formatDateRange(item.datetime, item.granularity, true)}:
            </span>
            <span className={classes.Value}>
              {getPercentageValueFormatter(item.isPercentageValue)(item.expectedValue)}
            </span>
          </div>
        </div>
        <div className={classes.StatItem}>
          <div className={classes.Label}>
            {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.SUMMARY_TABLE.HEADERS.CHANGE, {
              xox: generateXoXLabel(item.granularity),
            })}
          </div>
          <div className={classes.Content}>
            <ArrowNarrowUpIcon
              className={classNames(
                classes.Arrow,
                item.uplift < 0 && classes.Flip,
                !item.positiveUplift && classes.Negative
              )}
            />
            <span className={classes.Value}>{getPercentageValueFormatter(true)(item.uplift)}</span>
          </div>
        </div>
      </div>
      <FancyBlock title={t(TransKeys.SUBSCRIPTION_REPORT_PAGE.KPI_ITEM.EXEC_SUMMARY_TITLE)}>
        <div className={classes.ExecutiveSummary}>
          <RCAExecutiveSummaryOrchestrator
            mode={RCAMode.COMPARE_TO_DATE}
            executiveSummary={data.insights}
          />
        </div>
      </FancyBlock>
      <FancyBlock
        title={t(TransKeys.SUBSCRIPTION_REPORT_PAGE.KPI_ITEM.GRAPH_TITLE)}
        className={classes.Chart}
      >
        <div className={classes.ChartWrapper}>{chartData && <LineChart {...chartData} />}</div>
      </FancyBlock>
      {data.segmentBreakdownData.length > 0 && <SubscriptionSegmentationBreakdown data={data} />}
    </div>
  );
};

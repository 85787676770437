import {useCallback, useMemo, useState} from 'react';
import classes from './quick-run-analyses-panel.module.scss';
import {
  AnalysisFileIcon,
  FancyHeader,
  ModalLayout,
  PrimaryTabs,
  WizardStepper,
} from 'ui-components';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {quickRunForAdmins} from '../../../../store/analyses/analyses.actions';
import TransKeys from 'translations';
import {WizardCompletedTab} from '../analysis-form-panel/components/wizard-completed-tab/wizard-completed-tab.component';
import {useTranslation} from 'react-i18next';
import {QuickRunParametersStep} from './quick-run-parameters-step/quick-run-parameters-step.component';
import {QuickRunConfigurationStep} from './quick-run-configuration-step/quick-run-configuration-step.component';
import {QUICK_RUN_ANALYSES_CONFIG} from './quick-run-config.utils';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';

interface OwnProps {
  onClose?: () => void;
}

type AllProps = OwnProps;

enum WizardStep {
  SET_PARAMETERS = 'SET_PARAMETERS',
  EDIT_CONFIGURATION = 'EDIT_CONFIGURATION',
  DONE = 'DONE',
}

const wizardLabels = [
  {
    label: 'Set Parameters',
    value: WizardStep.SET_PARAMETERS,
  },
  {
    label: 'Configuration',
    value: WizardStep.EDIT_CONFIGURATION,
  },
  {
    label: 'Done!',
    value: WizardStep.DONE,
  },
];

const DEFAULT_VALUES: any = {
  runType: '',
  signalId: null,
  analysisFolderId: null,
  runParameters: {},
  populationFilter: null,
};

const QuickRunAnalysesPanelComponent = (props: AllProps) => {
  const {onClose} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.QUICK_RUN_FOR_ADMINS)(state)
  );
  const {defaultTableEntity} = useProductData();
  const [activeIndex, setActiveIndex] = useState(0);
  const [formData, setFormData] = useState({
    ...DEFAULT_VALUES,
    entity: defaultTableEntity,
  });
  const onWizardStepClick = useCallback(
    (step: WizardStep) => {
      setActiveIndex(wizardLabels.findIndex(l => l.value === step));
    },
    [setActiveIndex]
  );
  const onSubmit = useCallback(
    data => dispatch(quickRunForAdmins(data, () => onClose())),
    [dispatch, onClose]
  );
  const onSubmitParameters = useCallback(
    data => {
      setFormData(formData => ({
        ...formData,
        ...data,
        analysesConfiguration: QUICK_RUN_ANALYSES_CONFIG[data.runType],
      }));
    },
    [setFormData]
  );
  const onCompleteForm = useCallback(
    data => {
      onSubmit(data);
      onWizardStepClick(WizardStep.DONE);
    },
    [onSubmit, onWizardStepClick]
  );
  const isLastStep = useMemo(
    () => wizardLabels[activeIndex]?.value === WizardStep.DONE,
    [activeIndex]
  );
  const containerStyle = useMemo(() => ({height: '70rem', width: '80rem'}), []);

  return (
    <div style={containerStyle}>
      <ModalLayout>
        <FancyHeader title={'Quick run analyses'} icon={AnalysisFileIcon} onClose={onClose} />
        <div className={classes.QuickRunAnalysesForm}>
          <div className={classes.WizardSteps}>
            {!isLastStep && (
              <WizardStepper
                className={classes.FullWidth}
                activeValue={wizardLabels[activeIndex]?.value}
                lastCompletedValue={
                  activeIndex > 0 ? wizardLabels[activeIndex - 1]?.value : undefined
                }
                labels={wizardLabels}
                onClick={onWizardStepClick as any}
              />
            )}
          </div>
          {isLoading && <GenericLoading />}
          <PrimaryTabs
            className={classes.Tabs}
            selected={wizardLabels[activeIndex].value}
            hideTabsButtons
            tabs={[
              {
                key: WizardStep.SET_PARAMETERS,
                render: () => (
                  <QuickRunParametersStep
                    data={formData}
                    onSubmit={data => {
                      onSubmitParameters(data);
                      onWizardStepClick(WizardStep.EDIT_CONFIGURATION);
                    }}
                  />
                ),
              },
              {
                key: WizardStep.EDIT_CONFIGURATION,
                render: () => (
                  <QuickRunConfigurationStep
                    data={formData}
                    onSubmit={data => {
                      onCompleteForm(data);
                    }}
                  />
                ),
              },
              {
                key: WizardStep.DONE,
                render: () => (
                  <WizardCompletedTab
                    title={'Congratulations, your analyses are now running!'}
                    actions={[
                      {
                        text: t(TransKeys.GENERAL.ACTIONS.CLOSE),
                        onClick: onClose,
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

const QuickRunAnalysesPanel = composition<AllProps>(
  QuickRunAnalysesPanelComponent,
  withModalInactiveSourceHandler
);

export {QuickRunAnalysesPanel};

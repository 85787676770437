import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getFeatureNetworkRequest} from '../../../../http/features.network-requests';
import {FEATURE_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useMemo, useState} from 'react';
import {
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  FeatureIcon,
  Chip,
  PrimaryTabs,
} from 'ui-components';
import TransKeys from 'translations';
import {Feature} from '../../../../objects/models/feature.model';
import classes from './feature-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get, values} from 'lodash';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {
  generateFeatureTabs,
  getInitialActiveTabForFeature,
} from '../feature-form-panel/feature-form-panel.component';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import {ViewModelSeries} from '../../components/view-model-series/view-model-series.component';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';
import {useHiddenFlag} from '../../../../core/hooks/use-hidden-flag.hook';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {ValidationStatus} from '../../../../objects/models/signal.model';

interface OwnProps {
  feature: Feature;
  onClose?: () => void;
  [SIGNAL_ID_PATH_PARAM]?: number;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_FEATURE_KEY = SharedSelectionKeys.FEATURE_VIEW__FEATURE;
const generateSignalDefinitionKey = key => key + 'SignalDefinition';
const generateSignalIdKey = key => key + 'SignalId';
const generateSignalKey = key => key + 'Signal';

export const FeatureViewPanelComponent = (props: AllProps) => {
  const {onClose, feature, [SIGNAL_ID_PATH_PARAM]: signalId, initialTab} = props;
  const {t} = useTranslation();
  const showHidden = useHiddenFlag();
  // const {openPrimaryPanel} = useContext(PanelsContext);
  const [selectedFunnelTab, setSelectedFunnelTab] = useState<string>(
    getInitialActiveTabForFeature(feature, signalId)
  );
  const [selectedTabKey, setSelectedTabKey] = useState(initialTab || 'query');
  // const onRunKPIAnalysis = useCallback((signalId: number) => {
  //   openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
  //     [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.KPI_ANALYSIS,
  //     parameters: {
  //       goal: signalId,
  //       entity: feature.entity,
  //       analysis_mode: MetricType.USAGE,
  //     },
  //   });
  // }, [openPrimaryPanel, feature]);

  const renderFunnelTab = (tabKey: string) => {
    const query = feature[generateSignalDefinitionKey(tabKey)];
    const signalId = feature[generateSignalIdKey(tabKey)];

    return (
      <div className={classes.FeatureTab} key={tabKey}>
        <PrimaryTabs
          key={tabKey}
          selected={selectedTabKey}
          onChange={setSelectedTabKey}
          tabs={[
            {
              label: 'Definition',
              key: 'query',
              render: () => (
                <div className={classes.TabWrapper}>
                  <QueryDefinition query={query} entityContext={feature.entity} />
                </div>
              ),
            },
            {
              label: 'SQL',
              key: 'sql',
              render: () => (
                <div className={classes.TabWrapper}>
                  <SignalSql signalId={signalId} />
                </div>
              ),
            },
            {
              label: `Values (${feature.totalWarnsCount} Warnings)`,
              key: 'samples',
              hide: !showHidden,
              render: () => (
                <div className={classes.TabWrapper}>
                  <ViewModelSeries
                    modelType={ModelSampleSeriesModel.FEATURE}
                    modelId={feature.id}
                    entity={feature.entity}
                    signalId={signalId}
                    createdOn={feature.createdOn}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  };
  const featureTabs = useMemo(() => generateFeatureTabs(), []);
  const funnelTabs = values(featureTabs).map(tab => ({
    key: tab.key,
    title: tab.title,
    helperText: tab.helperText,
    render: () => renderFunnelTab(tab.key),
    disabled: !feature[generateSignalIdKey(tab.key)],
    warningText:
      feature[generateSignalKey(tab.key)]?.validationStatus === ValidationStatus.ERROR
        ? t(TransKeys.FEATURES.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)
        : null,
  }));

  return (
    <div className={classes.FeatureViewPanelContainer}>
      <ModalLayout>
        <div className={classes.FeatureViewPanel}>
          <FancyHeader
            icon={FeatureIcon}
            title={t(TransKeys.MODELS.FEATURE)}
            onClose={onClose}
            className={classes.Header}
            caps
          />
          <div className={classes.Body}>
            <div className={classes.Block}>
              <div className={classes.Name}>{feature.name}</div>
              {feature.shortDescription && (
                <div className={classes.Description}>{feature.shortDescription}</div>
              )}
            </div>
            {feature.tags.length > 0 && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.TAGS)}>
                  <FlexHorizontal spacing>
                    {feature.tags.map(t => (
                      <Chip label={t} caps={false} key={t} />
                    ))}
                  </FlexHorizontal>
                </LabelWrapper>
              </div>
            )}
            <div className={classes.Block}>
              <LabelWrapper label={t(TransKeys.GENERAL.LABELS.DEFINITION)}>
                <FunnelTabs
                  tabs={funnelTabs}
                  onTabChange={setSelectedFunnelTab}
                  selectedKey={selectedFunnelTab}
                />
              </LabelWrapper>
            </div>
            {feature.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={feature.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const FeatureViewPanel = composition<AllProps>(
  FeatureViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.FEATURE,
    modalIcon: FeatureIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'feature.errorCode'),
  }),
  withLoadBefore({
    feature: {
      selectedKey: SELECTED_FEATURE_KEY,
      actionKey: SELECTED_FEATURE_KEY,
      request: getFeatureNetworkRequest,
      mapPayloadFromProps: props => props[FEATURE_ID_PATH_PARAM],
      shouldCall: props => props[FEATURE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default FeatureViewPanel;

import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './contents-main.module.scss';
import {queryFilter, tableEntityFilterGenerator} from '../../../../constants/filters';
import {contentToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {getContentsNetworkRequest} from '../../../../http/contents.network-requests';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {Content} from '../../../../objects/models/content.model';
import {
  Button,
  CopyIcon,
  EditIcon,
  HoverHelperTip,
  IconButton,
  MoreIcon,
  RadarLightIcon,
  SquareListDuotoneIcon,
  TrashIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {useDispatch, useSelector} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {CONTENT_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {isNumber, keys} from 'lodash';
import {generateLastModified} from '../../../../utils/history.utils';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {
  deleteContentConfirmed,
  rescanContentConfirmed,
} from '../../../../store/content/content.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {Action, Subject} from '../../../../constants/permissions';
import {Permission} from '../../../../core/components/permission.component';
import {withStopPropagation} from '../../../../utils/general.utils';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {AppSources} from '../../../../constants/app-sources';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import SampleClassesColumn from '../../components/sample-classes-column/sample-classes-column.component';
import {exists} from 'front-core';
import {getListCount} from '../../../../store/store.selectors';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import InvalidSignalWarning from '../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `CONTENTS_MAIN/CONTENTS`;

const getContentBrokenSignals = (content: Content) => {
  const brokenSignals = [];
  if (content.usageSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(content.usageSignal);
  }
  if (content.exposureSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(content.exposureSignal);
  }
  if (content.intentSignal?.validationStatus === ValidationStatus.ERROR) {
    brokenSignals.push(content.intentSignal);
  }
  return brokenSignals;
};

export const ContentsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {productEntities, productEntitiesMap} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const user = useCurrentUser();
  const notify = useAmplitude();
  const {can} = usePermissions();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.CONTENTS.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter()];
    if (keys(productEntities).length > 0) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getContentsNetworkRequest,
      onError: err => [contentToastCreator('GET_ERROR')],
      modelKey: ModelKey.CONTENT,
    }),
    []
  );
  const showContent = useCallback(
    (content: Content) => {
      const parameters = {[CONTENT_ID_PATH_PARAM]: content.id};
      const brokenContentSignals = getContentBrokenSignals(content);
      if (brokenContentSignals.length > 0) {
        if (!isDemoProduct) {
          parameters['initialTab'] = QuerySqlTabsNames.SQL;
        }
        parameters[SIGNAL_ID_PATH_PARAM] = brokenContentSignals[0].id;
      }

      openPrimaryPanel(PanelKey.VIEW_CONTENT_PANEL, parameters, PanelType.MODAL);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onDelete = useCallback(
    (content: Content) => dispatch(deleteContentConfirmed(content.id)),
    [dispatch]
  );
  const onRescan = useCallback(
    (content: Content) => dispatch(rescanContentConfirmed(content.id)),
    [dispatch]
  );

  const onCreateEdit = useCallback(
    (content?: Content, parameters = {}) => {
      openPrimaryPanel(
        PanelKey.CONTENT_FORM_PANEL,
        {
          [CONTENT_ID_PATH_PARAM]: content?.id,
          [SOURCE_PROP_KEY]: AppSources.CONTENTS_MAIN,
          ...parameters,
        },
        PanelType.MODAL
      );
      let amplitudeEvent = '';
      const amplitudePayload = {
        userId: user.id,
      };
      if (!content) {
        amplitudeEvent = AmplitudeEvent.CONTENT_CREATE_CLICKED;
      } else if (content && parameters.cloneMode) {
        amplitudeEvent = AmplitudeEvent.CONTENT_DUPLICATE_CLICKED;
        amplitudePayload['contentId'] = content.id;
      } else if (content && !parameters.cloneMode) {
        amplitudeEvent = AmplitudeEvent.CONTENT_EDIT_CLICKED;
        amplitudePayload['contentId'] = content.id;
      }
      notify(amplitudeEvent as AmplitudeEvent, amplitudePayload);
    },
    [openPrimaryPanel, user, notify]
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (content: Content) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <Title className={classes.Title} text={content.name} caps={false} />
            {content.shortDescription && <HoverHelperTip title={content.shortDescription} />}
            {getContentBrokenSignals(content).length > 0 && (
              <InvalidSignalWarning
                title={t(TransKeys.CONTENTS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </FlexHorizontal>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '16rem',
        sortable: true,
        hidden: keys(productEntities).length < 2,
        render: (content: Content) => (
          <FlexVertical spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              text={productEntitiesMap[content.entity]?.name}
              icon={getEntityIcon(content.entity)}
            />
          </FlexVertical>
        ),
      },
      {
        key: 'samples',
        title: 'samples',
        width: '40rem',
        render: (content: Content) => {
          const sampleClasses = [
            {
              samples: content.exposureClasses,
              count: content.exposureTotalDistinctValues,
            },
            {
              samples: content.intentClasses,
              count: content.intentTotalDistinctValues,
            },
            {
              samples: content.usageClasses,
              count: content.usageTotalDistinctValues,
            },
          ].filter(c => exists(c.samples));

          if (sampleClasses.length === 0) {
            return null;
          }
          const {samples, count} = sampleClasses[0];

          return (
            <SampleClassesColumn
              sampleClasses={samples}
              count={count}
              onMoreClick={() => showContent(content)}
              entityCreatedOn={content.createdOn}
            />
          );
        },
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: true,
        width: '16rem',
        render: (content: Content) => {
          const modifier = generateLastModified(content.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(content.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        sticky: 'right',
        align: 'right',
        render: (content: Content) => (
          <div className={classes.Actions}>
            <Permission subject={Subject.CONTENT} action={Action.EDIT}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                onClick={withStopPropagation(() => onCreateEdit(content))}
              />
            </Permission>
            <Permission subject={Subject.CONTENT} action={Action.CREATE}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                icon={CopyIcon}
                onClick={withStopPropagation(() => onCreateEdit(content, {cloneMode: true}))}
              />
            </Permission>
            <ModelActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'delete',
                  hide: !can(Subject.CONTENT, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(content),
                  icon: TrashIcon,
                },
                {
                  key: 'rescan',
                  title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                  onClick: () => onRescan(content),
                  icon: RadarLightIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [t, can, onCreateEdit, onDelete, productEntities, productEntitiesMap, onRescan, showContent]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.CONTENT) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(
      registerActionListener(
        [CoreActionsType.MODEL_CREATED, CoreActionsType.MODEL_UPDATED],
        listener
      )
    );
    return () => {
      dispatch(
        removeActionListener(
          [CoreActionsType.MODEL_CREATED, CoreActionsType.MODEL_UPDATED],
          listener
        )
      );
    };
  }, [dispatch, config]);

  return (
    <MainTableStructure
      className={classes.ContentsMain}
      title={title}
      helperTextTitle={t(TransKeys.CONTENTS.HEADER.HELPER_TEXT)}
      icon={SquareListDuotoneIcon}
      onRowClicked={content => showContent(content)}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      headerRenderRight={
        <Permission subject={Subject.CONTENT} action={Action.CREATE}>
          <Button onClick={() => onCreateEdit()}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_CONTENT)}
          </Button>
        </Permission>
      }
      emptyStateTranslationPath={TransKeys.CONTENTS.EMPTY_STATE}
      itemsPerPage={50}
    />
  );
};

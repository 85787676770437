import {InputKpiImpactSentenceExplainer} from '../rca-insights.model.ts';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {
  SentencePart,
  ChangeInValueSentencePart,
  ScrollToSectionSentencePart,
  SentenceContainer,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import sharedClasses from '../../insights.module.scss';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';
import {useMemo} from 'react';
import {calculateUplift} from 'front-core';
import {ChangeDescriptionAlias} from '../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import {CHANGE_DESCRIPTION_ALIAS_MAP} from '../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import {RCAElementType} from 'ui-components';

interface OwnProps extends InputKpiImpactSentenceExplainer {}

type AllProps = OwnProps;

export const InputKpiImpactSentenceFormatter = (props: AllProps) => {
  const {sentenceParts, formattedSentenceParts: fsp, onViewOnPage, explainabilityLabel} = props;
  const uplift = useMemo(
    () => calculateUplift(sentenceParts.inputKpiValue, sentenceParts.inputKpiExpectedValue),
    [sentenceParts.inputKpiValue, sentenceParts.inputKpiExpectedValue]
  );

  const changeDescriptionAlias = useMemo(() => {
    const alias = CHANGE_DESCRIPTION_ALIAS_MAP[sentenceParts?.changeDescription] || '';
    if (alias === ChangeDescriptionAlias.SPIKE) {
      return `${alias}d`;
    } else if (alias === ChangeDescriptionAlias.DROP) {
      return `${alias}ped`;
    } else {
      // We should never reach this point
      return 'changed';
    }
  }, [sentenceParts]);
  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      {explainabilityLabel && (
        <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      )}
      <RCAExplainersIcon variant={RCAExplainersIconVariant.INPUT_KPI_EXPLAINER} />
      <SentencePart bold text={fsp.inputKpiName} />
      <SentencePart text={`${changeDescriptionAlias} by`} />
      <ChangeInValueSentencePart
        higherIsBetter={sentenceParts.higherIsBetter}
        changeInValue={uplift}
      />
      <SentencePart
        text={`which influenced the ${fsp.sampledModelType}'s ${changeDescriptionAlias}`}
      />
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType.KPI_TREE_EXPLAINER)}
        />
      )}
    </SentenceContainer>
  );
};

import {values} from 'lodash';
import {
  AnomalyMode,
  HomepageSubscribedSegmentsType,
  HomepageSubscriptionPushType,
  HomepageSubscriptionUnit,
} from '../models/homepage.model.ts';
import {exists} from 'front-core';
import i18n from '../../config/i18n.config.tsx';
import TransKeys from 'translations';
import yup from '../../config/yup.config.ts';

const weeklyAndDailySubscriptionPushTypeValues = Object.values(HomepageSubscriptionPushType);
const dailySpecificPushTypeValues = [
  HomepageSubscriptionPushType.SIGNIFICANT_CHANGE,
  HomepageSubscriptionPushType.NEVER,
];

function createSubscriptionSettingsSchema(allowedValues, isRequired = true) {
  return yup.lazy(obj => {
    const validationSchema = {};
    for (const modelId of Object.keys(obj || {})) {
      validationSchema[modelId] = isRequired
        ? yup.string().required().oneOf(allowedValues)
        : yup.string().oneOf(allowedValues);
    }
    if (isRequired) {
      return yup.object().shape(validationSchema).required();
    }
    return yup.object().shape(validationSchema).nullable();
  });
}

const sampledModelSubscriptionPushTypeSettings = {
  monthlyMetricSettings: createSubscriptionSettingsSchema(weeklyAndDailySubscriptionPushTypeValues),
  weeklyMetricSettings: createSubscriptionSettingsSchema(weeklyAndDailySubscriptionPushTypeValues),
  monthlyFunnelSettings: createSubscriptionSettingsSchema(
    weeklyAndDailySubscriptionPushTypeValues,
    false
  ),
  weeklyFunnelSettings: createSubscriptionSettingsSchema(
    weeklyAndDailySubscriptionPushTypeValues,
    false
  ),
  dailyMetricSettings: createSubscriptionSettingsSchema(dailySpecificPushTypeValues),
  dailyFunnelSettings: createSubscriptionSettingsSchema(dailySpecificPushTypeValues, false),
};

export const createEmailAndSlackFormValidator = (userEmail?: string) => {
  return {
    emails: yup
      .array()
      .of(
        yup
          .string()
          .email()
          .test(
            'user-email',
            i18n.t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.EMTY_STATE.FORM.EMAIL_INPUT.ERROR),
            v => !v || v === userEmail
          )
      )
      .max(
        1,
        i18n.t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.EMTY_STATE.FORM.EMAIL_INPUT.ERROR)
      ),
    slackChannels: yup.array().of(yup.string()).optional(),
  };
};

export const createHomepageSubscriptionValidator = (userEmail?: string) => {
  return yup
    .object()
    .shape({
      id: yup.number().optional().nullable(),
      isActive: yup.boolean().required(),
      anomalyMode: yup.string().required().oneOf(values(AnomalyMode)),
      anomalyThreshold: yup.number().nullable(),
      subscribedSegmentsType: yup.string().required().oneOf(values(HomepageSubscribedSegmentsType)),
      isMonthlyActive: yup.boolean().required(),
      isWeeklyActive: yup.boolean().required(),
      isDailyActive: yup.boolean().required(),
      ...createEmailAndSlackFormValidator(userEmail),
      ...sampledModelSubscriptionPushTypeSettings,
      breakdownSegmentIds: yup.array().of(yup.number()).required(),
      excludeCompositeSegments: yup.boolean().required(),
      excludeNonInformativeSegments: yup.boolean().required(),
    })
    .test({
      name: 'at-least-one-required',
      test: function (obj) {
        const isValid =
          (obj.emails && exists(obj.emails[0]?.trim())) ||
          (obj.slackChannels && obj.slackChannels.length > 0);
        if (isValid) {
          return true;
        }
        return this.createError({
          message: 'Must fill either email or slack channels',
          path: 'emails',
        });
      },
    });
};

export const homepageTestSubscriptionDTOValidator = yup.object().shape({
  sampleDate: yup.string().required(),
  homepageSubscriptionId: yup.number().required(),
  messageType: yup.string().required().oneOf(values(HomepageSubscriptionUnit)),
});

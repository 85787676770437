import classNames from 'classnames';
import classes from './health-monitor-welcome.module.scss';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {greetings} from '../../../../../../utils/general.utils';
import TransKeys from 'translations';
import {DatePickerInput, OptionsDropdown, PulseIcon} from 'ui-components';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {CalcSeriesMode} from '../../../../../../constants/metric-series.consts';
import {values} from 'lodash';
import {useCalcSeriesModeHelpers} from '../../../../../../core/hooks/metric-series.hooks';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  userFirstName: string;
  date: string;
  onDateChange: (date: string) => void;
  mode: CalcSeriesMode;
  setMode: (mode: CalcSeriesMode) => void;
  className?: string;
}

type AllProps = OwnProps;
const maxDate = new Date();

export const HealthMonitorWelcome = (props: AllProps) => {
  const {userFirstName, date, onDateChange, setMode, mode, className} = props;
  const {t} = useTranslation();
  const greet = useMemo(() => greetings(), []);
  const modeOptions = useMemo(
    () =>
      values(CalcSeriesMode).map(v => ({
        value: v,
        label: t(TransKeys.GENERAL.ENUMS.CALC_SERIES_MODE[v]),
        disabled: v === CalcSeriesMode.PERIOD_VS_LAST_PERIOD,
      })),
    [t]
  );
  const modeHelperText = useCalcSeriesModeHelpers(mode);

  return (
    <div className={classNames(classes.HealthMonitorWelcome, className)}>
      <div className={classes.Title}>
        {greet}, {userFirstName}
      </div>
      <div className={classes.SubTitle}>
        <span>{t(TransKeys.HEALTH_MONITOR.HEADER.STATUS_SENTENCE)}</span>
        <DatePickerInput
          fullWidth={false}
          className={classes.DatePickerInput}
          value={date}
          onChange={onDateChange}
          maxDate={maxDate}
          dateFormat={TIME_FORMATS.READABLE_DATE}
        />
      </div>
      <div className={classes.Actions}>
        <Tooltip title={modeHelperText} interactive placement={'top'}>
          <div className={classes.Action}>
            <OptionsDropdown
              label={'trend'}
              icon={PulseIcon}
              options={modeOptions}
              value={mode}
              onChange={setMode}
              popoverDirection={'left'}
              size={'large'}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

import {ExtractSegmentData} from '../../../../../shared/segmentation/segment-filters/segment-filters.component';
import {Sorting} from 'front-core';

export const PRIMARY_SORT: Sorting = {
  order: 'desc',
  orderBy: 'isSignificant',
};
export const EXTRACT_SEGMENT_DATA: ExtractSegmentData = {
  signalIdDataKey: 'signalId',
  segmentGroupNameDataKey: 'segmentGroupName',
  segmentNameDataKey: 'segmentName',
  shareDataKey: 'share',
  ignoreTeamFilterDataKey: 'ignoreTeamFilter',
  segmentKeysDataKey: 'segmentKeys',
};
export const MAX_SIMILAR_TO_SHOW = 3;

import classNames from 'classnames';
import classes from './rate-query-builder.module.scss';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {
  LabelWrapper,
  LiteralValueType,
  QueryBuilderFactory,
  AggregationFunction,
  Select,
} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {cloneDeep, get, startCase} from 'lodash';
import {set} from 'object-path-immutable';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  entityContext?: TableEntity;
  onSignalInfo?: (value: string | number) => void;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const createRateInitialQuery = () => {
  const q = QueryBuilderFactory.createTemplate();
  q.template = 'rate';
  // Create aggregation
  const agg = QueryBuilderFactory.createAggregation();
  agg.function = AggregationFunction.SUM;
  agg.element = null;
  // Set aggregation
  q.parameters = [cloneDeep(agg), cloneDeep(agg)];
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RateQueryBuilder',
  };
  return q;
};

const ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN, SelectorModelType.EVENT];

const createSignalFilters = (entityContext = undefined) => ({
  type: SignalType.MEASURE,
  data_type: [SignalDataType.INTEGER, SignalDataType.DECIMAL, SignalDataType.BOOLEAN],
  entity_binding: TableEntityBinding.TWO_WAY,
  exclude_templates: ['dau', 'wau', 'mau', 'l7', 'l28'],
  entityContext,
});

const createColumnFilters = (entityContext = undefined) => ({
  table_type: [TableType.EVENTS, TableType.STATE],
  literal_type: [LiteralValueType.INTEGER, LiteralValueType.FLOAT, LiteralValueType.BOOLEAN],
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

const createEventFilters = (entityContext = undefined) => ({
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

enum RatePart {
  NOMINATOR = 0,
  DENOMINATOR = 1,
}

const getAggFunctions = (functions: AggregationFunction[]) => {
  const options = functions.map(v => ({
    label: startCase(v.replace('-', ' ')),
    value: v,
  }));
  return {options};
};

const NOMINATOR_AGG_OPTIONS = getAggFunctions([
  AggregationFunction.SUM,
  AggregationFunction.MAX,
  AggregationFunction.COUNT_DISTINCT,
]);
const DENOMINATOR_AGG_OPTIONS = getAggFunctions([
  AggregationFunction.SUM,
  AggregationFunction.MAX,
  AggregationFunction.AVG,
  AggregationFunction.COUNT_DISTINCT,
]);

export const RateQueryBuilder = (props: AllProps) => {
  const {
    query: queryFromProps,
    onChange,
    onSignalInfo,
    entityContext,
    errors,
    disabled,
    className,
  } = props;
  const query = useMemo(() => queryFromProps || createRateInitialQuery(), [queryFromProps]);
  const signalFilters = useMemo(() => createSignalFilters(entityContext), [entityContext]);
  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(() => createEventFilters(entityContext), [entityContext]);

  const onChangeParameter = useCallback(
    (selectedValue, path) => {
      let newQuery = query ? {...query} : createRateInitialQuery();
      newQuery = set(newQuery, path, selectedValue);
      onChange && onChange(newQuery);
    },
    [query, onChange]
  );

  const renderPart = (part: RatePart) => {
    const aggElement = get(query, `parameters.${part}`);
    const options = part === RatePart.NOMINATOR ? NOMINATOR_AGG_OPTIONS : DENOMINATOR_AGG_OPTIONS;

    return (
      <div className={classes.Row}>
        <div className={classes.Item}>
          <Select
            className={classes.Select}
            error={Boolean(get(errors, `parameters.${part}.function`))}
            value={get(aggElement, 'function')}
            onChange={value => onChangeParameter(value, `parameters.${part}.function`)}
            options={options}
            placeholder={'Function'}
            searchable={false}
            sortValues={false}
            clearable={false}
            disabled={disabled}
            capitalize={false}
          />
        </div>
        <div className={classes.Item}>
          <TemplateItemQueryBuilder
            query={get(aggElement, 'element')}
            onChange={v => onChangeParameter(v, `parameters.${part}.element`)}
            onSignalInfo={onSignalInfo}
            columnFilters={columnFilters}
            signalFilters={signalFilters}
            eventFilters={eventFilters}
            allowTypes={ALLOW_TYPES}
            errors={get(errors, `parameters.${part}.element`)}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.RateQueryBuilder, className)}>
      <div className={classes.Row}>
        <LabelWrapper label={`Nominator`}>{renderPart(RatePart.NOMINATOR)}</LabelWrapper>
      </div>
      <div className={classes.Row}>
        <LabelWrapper label={`Denominator`}>{renderPart(RatePart.DENOMINATOR)}</LabelWrapper>
      </div>
    </div>
  );
};

import {useMemo, useCallback, useRef} from 'react';
import {
  TableType,
  TableEntity,
  TableEntityBinding,
} from '../../../../../objects/models/table.model';
import {
  LiteralValueType,
  LabelWrapper,
  Select,
  QueryBuilderFactory,
  SqlElementType,
  ToggleSwitch,
} from 'ui-components';
import {
  TableColumnQueryBuilder,
  createTableColumnInitialQuery,
} from '../table-column-query-builder/table-column-query-builder.component';
import {
  TableEventsValueQueryBuilder,
  createTableEventsValueInitialQuery,
} from '../table-events-value-query-builder/table-events-value-query-builder.component';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import classes from './revenue-query-builder.module.scss';
import pluralize from 'pluralize';
import TransKeys from '../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';

const queryTypeOptions = {
  options: [
    {
      key: 'TableEventsValueQueryBuilder',
      value: 'TableEventsValueQueryBuilder',
      label: 'Event/Transaction Property',
    },
    {
      key: 'TableColumnQueryBuilder',
      value: 'TableColumnQueryBuilder',
      label: 'State Table Column',
    },
  ],
};

interface OwnProps {
  query: any;
  onChange: (value: any) => void;
  errors?: any;
  filters?: any;
  disabled?: boolean;
  entityContext: TableEntity;
}

type AllProps = OwnProps;

export const createRevenueInitialQuery = (subBuilderType = undefined) => {
  let q = {};

  if (subBuilderType === 'TableEventsValueQueryBuilder') {
    q = createTableEventsValueInitialQuery();
  } else if (subBuilderType === 'TableColumnQueryBuilder') {
    q = createTableColumnInitialQuery();
  }

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RevenueQueryBuilder',
    [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: subBuilderType,
  };

  return q;
};

const transformToFillNull = (query: any) => {
  const fillNullQuery = QueryBuilderFactory.createFillNull();
  fillNullQuery.elements = [query];
  fillNullQuery.default = {type: 'Literal', value: 0, value_type: 'integer'};
  return fillNullQuery;
};

const COLUMN_FILTERS = {
  literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT],
};

const SUB_BUILDER_PATH = `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY}`;

export const RevenueQueryBuilder = (props: AllProps) => {
  const {
    errors,
    onChange: onChangeFromProps,
    query: queryFromProps,
    disabled,
    entityContext,
  } = props;
  const {t} = useTranslation();
  const shouldWrapWithFillNull = useRef(queryFromProps?.type === SqlElementType.FILL_NULL);

  const subBuilder = useMemo(() => get(queryFromProps, SUB_BUILDER_PATH), [queryFromProps]);
  const query = useMemo(() => {
    if (queryFromProps?.type === SqlElementType.FILL_NULL) {
      return queryFromProps.elements[0];
    }
    return queryFromProps;
  }, [queryFromProps]);

  const eventTablesFilters = useMemo(
    () => ({
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );
  const stateTablesFilters = useMemo(
    () => ({
      type: TableType.STATE,
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
    }),
    [entityContext]
  );

  const handleChange = useCallback(
    (changedQuery: any) => {
      let newQuery = {...changedQuery};
      if (shouldWrapWithFillNull.current) {
        newQuery = transformToFillNull(newQuery);
      }
      newQuery[PARAMETERS_METADATA_KEY] = {
        ...(newQuery[PARAMETERS_METADATA_KEY] || {}),
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'RevenueQueryBuilder',
        [METADATA_KEY.SUB_BUILDER_COMPONENT_NAME_KEY]: get(
          changedQuery,
          SUB_BUILDER_PATH,
          subBuilder
        ),
      };
      onChangeFromProps(newQuery);
    },
    [shouldWrapWithFillNull, subBuilder, onChangeFromProps]
  );

  const toggleFillZeros = useCallback(() => {
    shouldWrapWithFillNull.current = !shouldWrapWithFillNull.current;
    handleChange(query);
  }, [handleChange, query]);

  const setSubBuilder = useCallback(
    value => handleChange(createRevenueInitialQuery(value)),
    [handleChange]
  );

  return (
    <div className={classes.Container}>
      <LabelWrapper label={`State where the revenue value is stored`}>
        <Select
          placeholder={'Select'}
          value={subBuilder}
          onChange={setSubBuilder}
          searchable={false}
          clearable={false}
          options={queryTypeOptions}
          disabled={disabled}
        />
      </LabelWrapper>
      {subBuilder === 'TableEventsValueQueryBuilder' && (
        <LabelWrapper label={`Select the relevant events`}>
          <TableEventsValueQueryBuilder
            query={query}
            filters={eventTablesFilters}
            columnFilters={COLUMN_FILTERS}
            thenText={'And take the assignment property from'}
            multiEvents={false}
            onChange={handleChange}
            errors={errors}
            disabled={disabled}
          />
        </LabelWrapper>
      )}
      {subBuilder === 'TableColumnQueryBuilder' && (
        <LabelWrapper label={`Select the relevant table and column`}>
          <TableColumnQueryBuilder
            query={query}
            tableFilters={stateTablesFilters}
            columnFilters={COLUMN_FILTERS}
            onChange={handleChange}
            errors={errors}
            disabled={disabled}
          />
        </LabelWrapper>
      )}
      <LabelWrapper
        label={t(TransKeys.METRIC_FORM.INPUTS.FILL_ZEROES.TITLE)}
        className={classes.FillZerosLabel}
      >
        <div className={classes.FillZerosContainer}>
          <ToggleSwitch
            disabled={disabled}
            value={queryFromProps.type === SqlElementType.FILL_NULL}
            onChange={toggleFillZeros}
          />
          <div className={classes.FillZerosHelperText}>
            {t(TransKeys.METRIC_FORM.INPUTS.FILL_ZEROES.HELPER_TEXT, {
              entity: pluralize(entityContext),
            })}
          </div>
        </div>
      </LabelWrapper>
    </div>
  );
};

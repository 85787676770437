import {Button, ShieldCheckSolidIcon} from 'ui-components';
import classes from './footer.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

type FooterProps = {
  disabledCTAButton?: boolean;
  onClickSubmit: VoidFunction;
  buttonText: string;
};

export const Footer = (props: FooterProps) => {
  const {onClickSubmit, disabledCTAButton: disabledButton, buttonText} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.WizardFooter}>
      <Button
        disabled={disabledButton}
        onClick={onClickSubmit}
        size="large"
        variant="contained"
        className={classes.ContinueButton}
      >
        {buttonText}
      </Button>
      <div className={classes.FooterText}>
        <ShieldCheckSolidIcon className={classes.ShieldCheckIcon} />
        <span>{t(TransKeys.SOURCE_WIZARD.FOOTER.ENCRYPTION_DESCRIPTION)}</span>
      </div>
    </div>
  );
};

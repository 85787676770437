import {composition, exists} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getSegmentNetworkRequest} from '../../../../http/segments.network-requests';
import {SEGMENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useMemo, useState} from 'react';
import {
  Chip,
  FancyHeader,
  LabelWrapper,
  ModalLayout,
  PrimaryTabs,
  UsersIcon,
  PrimaryTab,
  TeamIconChip,
} from 'ui-components';
import TransKeys from 'translations';
import {Segment} from '../../../../objects/models/segment.model';
import classes from './segment-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import SampleClassesTable from '../../components/sample-classes-table/sample-classes-table.component';
import {getEntityIcon} from '../../../../constants/entity.consts';

export enum SegmentViewPanelTabs {
  SEGMENT_CLASSES = 'segmentClasses',
  QUERY_DEFINITION = 'query',
  SQL = 'sql',
}

interface OwnProps {
  segment: Segment;
  onClose?: () => void;
  initialTab?: SegmentViewPanelTabs;
}

type AllProps = OwnProps;

const SELECTED_SEGMENT_KEY = SharedSelectionKeys.SEGMENT_VIEW__SEGMENT;

export const SegmentViewPanelComponent = (props: AllProps) => {
  const {onClose, segment, initialTab} = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const [selectedTabKey, setSelectedTabKey] = useState(() => {
    if (initialTab) {
      return initialTab;
    }
    return segment.classes?.length > 0
      ? SegmentViewPanelTabs.SEGMENT_CLASSES
      : SegmentViewPanelTabs.QUERY_DEFINITION;
  });
  const segmentTabLabel = useMemo(() => {
    let suffix = '';
    if (exists(segment.totalDistinctValues) && segment.totalDistinctValues > 0) {
      suffix = ` (${segment.totalDistinctValues})`;
    } else if (segment.classes?.length > 0) {
      suffix = ` (${segment.classes.length})`;
    }
    return `${t(TransKeys.GENERAL.LABELS.SEGMENT_CLASSES)} ${suffix}`;
  }, [t, segment]);

  const tabs: PrimaryTab[] = useMemo(
    () => [
      {
        key: SegmentViewPanelTabs.SEGMENT_CLASSES,
        label: segmentTabLabel,
        render: () => (
          <SampleClassesTable
            classes={segment.classes}
            entity={segment.entity}
            sampleClassesTitle={t(TransKeys.SEGMENT_CLASSES_TABLE_TAB.COLUMNS.SEGMENTS)}
            emptyStateText={t(
              TransKeys.SEGMENTS.SEGMENT_VIEW_PANEL.TABS.SEGMENT_GROUPS.EMPTY_STATE.TEXT
            )}
            noResultFoundText={t(
              TransKeys.SEGMENTS.SEGMENT_VIEW_PANEL.TABS.SEGMENT_GROUPS.NO_GROUPS_EMPTY_STATE
            )}
          />
        ),
      },
      {
        label: 'Definition',
        key: SegmentViewPanelTabs.QUERY_DEFINITION,
        render: () => (
          <div className={classes.TabWrapper}>
            <QueryDefinition query={segment.signalDefinition} entityContext={segment.entity} />
          </div>
        ),
      },
      {
        label: 'SQL',
        key: SegmentViewPanelTabs.SQL,
        render: () => (
          <div className={classes.TabWrapper}>
            <SignalSql signalId={segment.signalId} />
          </div>
        ),
        hide: !exists(segment.signalId),
      },
    ],
    [t, segment, segmentTabLabel]
  );

  return (
    <div className={classes.SegmentViewPanelContainer}>
      <ModalLayout>
        <div className={classes.SegmentViewPanel}>
          <FancyHeader
            icon={UsersIcon}
            title={t(TransKeys.MODELS.SEGMENT)}
            onClose={onClose}
            caps
          />
          <div className={classes.Body}>
            <div className={classes.Head}>
              <div className={classes.Title}>
                <div className={classes.Name}>
                  {segment.categoryId && (
                    <span className={classes.Category}>{segment.categoryName} /</span>
                  )}
                  {segment.name}
                  {segment.isConfounder && (
                    <Chip
                      size={'small'}
                      label={'Confounder'}
                      className={classes.Chip}
                      helperText={t(TransKeys.SEGMENTS.CONFOUNDER_HELPER_TEXT)}
                    />
                  )}
                </div>
                <div className={classes.Teams}>
                  {segment.teams.map(team => (
                    <TeamIconChip {...team} size={'medium'} key={team.id} showTooltip />
                  ))}
                </div>
              </div>
              {segment.shortDescription && (
                <div className={classes.Description}>{segment.shortDescription}</div>
              )}
            </div>
            {segment.entity && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)} fullWidth={false}>
                  <TitleWithIcon
                    text={productEntitiesMap[segment.entity].name}
                    icon={getEntityIcon(segment.entity)}
                  />
                </LabelWrapper>
              </div>
            )}
            {segment.signalDefinition && (
              <div className={classes.Block}>
                <PrimaryTabs
                  selected={selectedTabKey}
                  onChange={v => setSelectedTabKey(v as SegmentViewPanelTabs)}
                  tabs={tabs}
                  sticky
                />
              </div>
            )}
            {segment.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={segment.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const SegmentViewPanel = composition<AllProps>(
  SegmentViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.SEGMENT,
    modalIcon: UsersIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'segment.errorCode'),
  }),
  withLoadBefore({
    segment: {
      selectedKey: SELECTED_SEGMENT_KEY,
      actionKey: SELECTED_SEGMENT_KEY,
      request: getSegmentNetworkRequest,
      mapPayloadFromProps: props => props[SEGMENT_ID_PATH_PARAM],
      shouldCall: props => props[SEGMENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default SegmentViewPanel;

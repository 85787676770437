import {action, ActionHooks, OnSuccessActionHook} from 'front-core';
import {
  ExperimentDTO,
  SetExperimentOwnerDTO,
  SetExperimentTeamDTO,
} from '../../objects/dto/experiment.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {ExperimentSubscriptionDTO} from '../../objects/dto/experiment-subscription.dto';

export enum ExperimentsActionType {
  CREATE_EXPERIMENT = '@@experiments/CREATE_EXPERIMENT',
  PATCH_EXPERIMENT = '@@experiments/PATCH_EXPERIMENT',
  UPDATE_EXPERIMENT = '@@experiments/UPDATE_EXPERIMENT',
  UPDATE_EXPERIMENT_TEAM = '@@experiments/UPDATE_EXPERIMENT_TEAM',
  DELETE_EXPERIMENT = '@@experiments/DELETE_EXPERIMENT',
  SET_EXPERIMENT_OWNER = '@@experiments/SET_EXPERIMENT_OWNER',
  CREATE_EXPERIMENT_SUBSCRIPTION = '@@experiments/CREATE_EXPERIMENT_SUBSCRIPTION',
  EDIT_EXPERIMENT_SUBSCRIPTION = '@@experiments/EDIT_EXPERIMENT_SUBSCRIPTION',
  DELETE_EXPERIMENT_SUBSCRIPTION = '@@experiments/DELETE_EXPERIMENT_SUBSCRIPTION',
  RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT = '@@experiments/RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT',
}

export const createExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.CREATE_EXPERIMENT, data, hooks);

export const patchExperimentConfirmed = (data: ExperimentDTO) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.UPDATE_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.UPDATE_EXPERIMENT.CONTENT),
    },
    () => [patchExperiment(data)]
  );

export const patchExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.PATCH_EXPERIMENT, data, hooks);

export const updateExperimentConfirmed = onApprove =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.EDIT_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.EDIT_EXPERIMENT.CONTENT),
    },
    () => {
      onApprove();
      return [];
    }
  );

export const setExperimentOwner = (data: SetExperimentOwnerDTO, onSuccess?: OnSuccessActionHook) =>
  action(ExperimentsActionType.SET_EXPERIMENT_OWNER, data, {onSuccess});

export const updateExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.UPDATE_EXPERIMENT, data, hooks);

export const updateExperimentTeam = (data: SetExperimentTeamDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.UPDATE_EXPERIMENT_TEAM, data, hooks);

export const deleteExperiment = (id: number) => action(ExperimentsActionType.DELETE_EXPERIMENT, id);

export const deleteExperimentConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT.CONTENT),
    },
    () => [deleteExperiment(id)]
  );

export const createExperimentSubscription = (
  data: ExperimentSubscriptionDTO,
  hooks?: ActionHooks
) => action(ExperimentsActionType.CREATE_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const editExperimentSubscription = (data: ExperimentSubscriptionDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.EDIT_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const deleteExperimentSubscription = (
  data: Omit<ExperimentSubscriptionDTO, 'cronExp'>,
  hooks?: ActionHooks
) => action(ExperimentsActionType.DELETE_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const rerunExperimentLastCompletedAnalysisResultConfirmed = (
  id: number,
  onApprove: () => void
) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.CONTENT),
    },
    () => {
      onApprove();
      return [rerunExperimentLastCompletedAnalysisResult(id)];
    }
  );

export const rerunExperimentLastCompletedAnalysisResult = (id: number) =>
  action(ExperimentsActionType.RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT, id);

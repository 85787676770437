import {
  ActivationKPIGroupIcon,
  ConversionKPIIcon,
  CustomKPIIcon,
  EngagementKPIGroupIcon,
  MonetizationKPIGroupIcon,
  RateKPIIcon,
  RetentionKPIIcon,
  SideMenuItem,
} from 'ui-components';
import i18n from 'i18next';
import TransKeys from '../../../../constants/translation-keys.ts';
import {getMetricTypeName} from '../../../../constants/options.ts';
import {MetricType} from '../../../../objects/models/metric.model.ts';

export const getMetricMenuItems = (withCustom: boolean = true): SideMenuItem[] => {
  const res = [
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.RETENTION),
      value: 'retentionGroup',
      icon: RetentionKPIIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.RETENTION),
          value: MetricType.RETENTION,
        },
        {
          label: getMetricTypeName(MetricType.WOW),
          value: MetricType.WOW,
        },
        {
          label: getMetricTypeName(MetricType.MOM),
          value: MetricType.MOM,
        },
        {
          label: getMetricTypeName(MetricType.DOD),
          value: MetricType.DOD,
        },
      ],
    },
    {
      label: getMetricTypeName(MetricType.CONVERSION),
      value: MetricType.CONVERSION,
      icon: ConversionKPIIcon,
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.ACTIVATION),
      value: 'activationGroup',
      icon: ActivationKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.HABIT_MOMENT),
          value: MetricType.HABIT_MOMENT,
        },
      ],
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.ENGAGEMENT),
      value: 'engagementGroup',
      icon: EngagementKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.DAU),
          value: MetricType.DAU,
        },
        {
          label: getMetricTypeName(MetricType.WAU),
          value: MetricType.WAU,
        },
        {
          label: getMetricTypeName(MetricType.MAU),
          value: MetricType.MAU,
        },
        {
          label: getMetricTypeName(MetricType.L7),
          value: MetricType.L7,
        },
        {
          label: getMetricTypeName(MetricType.L28),
          value: MetricType.L28,
        },
        {
          label: getMetricTypeName(MetricType.USAGE),
          value: MetricType.USAGE,
        },
        {
          label: getMetricTypeName(MetricType.BEHAVIORAL_CHURN),
          value: MetricType.BEHAVIORAL_CHURN,
        },
      ],
    },
    {
      label: i18n.t(TransKeys.METRIC_FORM.SIDE_MENU.GROUP_LABEL.MONITIZATION),
      value: 'monetizationGroup',
      icon: MonetizationKPIGroupIcon,
      children: [
        {
          label: getMetricTypeName(MetricType.REVENUE),
          value: MetricType.REVENUE,
        },
        {
          label: getMetricTypeName(MetricType.BOUNDED_REVENUE),
          value: MetricType.BOUNDED_REVENUE,
        },
        {
          label: getMetricTypeName(MetricType.PAYMENT_RETENTION),
          value: MetricType.PAYMENT_RETENTION,
        },
      ],
    },
    {
      label: getMetricTypeName(MetricType.RATE),
      value: MetricType.RATE,
      icon: RateKPIIcon,
    },
  ];
  if (withCustom) {
    res.push({
      label: getMetricTypeName(MetricType.CUSTOM_SQL),
      value: MetricType.CUSTOM_SQL,
      icon: CustomKPIIcon,
    });
  }
  return res;
};

export const getDefaultHigherIsBetter = (metricType: MetricType) => {
  if (metricType === MetricType.REVENUE_CHURN || metricType === MetricType.BEHAVIORAL_CHURN) {
    return false;
  }
  return true;
};

import {DefaultDataColumn} from './components/data-columns/default-data-column.component';
import {ColumnType} from '../../../types';
import {LabelValueDataColumn} from './components/data-columns/label-value-data-column.component';
import {ProgressDataColumn} from './components/data-columns/progress-data-column.component';
import {ConfidenceIntervalDataColumn} from './components/data-columns/confidence-interval-data-column.component';
import {NumericDataColumn} from './components/data-columns/numeric-data-column.component';
import {BooleanDataColumn} from './components/data-columns/boolean-data-column.component';
import {ChangeDataColumn} from './components/data-columns/change-data-column.component';
import {InfoDataColumn} from './components/data-columns/info-data-column.component';
import {DateDataColumn} from './components/data-columns/date-data-column.component';
import {GradientDataColumn} from './components/data-columns/gradient-data-column.component';
import {RenderDataColumn} from './components/data-columns/render-column';
import {CommandDataColumn} from './components/data-columns/command-data-column.component';
import {CommandType} from '../../../types';
import {SentenceDataColumn} from './components/data-columns/sentence-data-column.component';
import {VersusDataColumn} from './components/data-columns/versus-data-column.component';
import {TrendDataColumn} from './components/data-columns/trend-data-column.component';
import {ArrowRightIcon} from '../../../../../simple/controls/icons/icons.component';
import {SequenceDataColumn} from './components/data-columns/sequence-data-column.component';
import {DurationDataColumn} from './components/data-columns/duration-data-column.component';
import {OutOfDataColumn} from './components/data-columns/out-of-data-column.component';
import {ShareOfTotalColumn} from './components/data-columns/share-of-total-data-column.component';
import {AdoptionRateDataColumn} from './components/data-columns/adoption-rate-data-column.component';
import {ViewFigureDataColumn} from './components/data-columns/view-figure-column.component';

export const TABLE_DATA_COLUMN_BY_TYPE: any = {
  [ColumnType.DEFAULT]: DefaultDataColumn,
  [ColumnType.NUMERIC]: NumericDataColumn,
  [ColumnType.BOOLEAN]: BooleanDataColumn,
  [ColumnType.DATE]: DateDataColumn,
  [ColumnType.INFO]: InfoDataColumn,
  [ColumnType.CHANGE]: ChangeDataColumn,
  [ColumnType.LABEL_VALUE]: LabelValueDataColumn,
  [ColumnType.PROGRESS]: ProgressDataColumn,
  [ColumnType.CONFIDENCE_INTERVAL]: ConfidenceIntervalDataColumn,
  [ColumnType.GRADIENT]: GradientDataColumn,
  [ColumnType.COMMAND]: CommandDataColumn,
  [ColumnType.SENTENCE]: SentenceDataColumn,
  [ColumnType.VERSUS]: VersusDataColumn,
  [ColumnType.OUT_OF]: OutOfDataColumn,
  [ColumnType.SHARE_OF_TOTAL]: ShareOfTotalColumn,
  [ColumnType.TREND]: TrendDataColumn,
  [ColumnType.SEQUENCE]: SequenceDataColumn,
  [ColumnType.DURATION]: DurationDataColumn,
  [ColumnType.ADOPTION_RATE]: AdoptionRateDataColumn,
  [ColumnType.VIEW_FIGURE]: ViewFigureDataColumn,
  [ColumnType._RENDER_COLUMN]: RenderDataColumn,
};

export const COMMAND_ICON: any = {
  [CommandType.NAVIGATION]: ArrowRightIcon,
  [CommandType.REF]: ArrowRightIcon,
};

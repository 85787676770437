import dagre from 'dagre';
import {MetricSeries} from '../../../../objects/models/metric-page.model';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
// @ts-ignore
import {Node} from '@xyflow/react/dist/esm/types/nodes';
import {
  DEFAULT_GRANULARITY,
  EdgeType,
  DEFAULT_MARKER_END,
  INSIGNIFICANT_CORRELATION_COLOR,
  KPI_GROUP_NODE_HEIGHT,
  KPI_GROUP_NODE_WIDTH,
  KPI_NODE_HEIGHT,
  KPI_NODE_WIDTH,
  MAX_CORRELATION_BAD_COLOR,
  MAX_CORRELATION_GOOD_COLOR,
  NodeType,
  NUMBER_OF_METRICS_FOR_ADD_INPUT_METRIC,
  TreeVariant,
} from './kpi-tree.consts';
import {exists, number2k} from 'front-core';
import {MetricTree} from '../../../../objects/models/metric-tree.model';
import {keyBy, range} from 'lodash';
import {colorAlphaTransformer} from 'ui-components';

export const generateIdForMetric = (metricId: number) => `metric-${metricId}`;
export const generateIdForMetricGroup = (metricId: number) => `metric-group-of-${metricId}`;
export const generateIdForAddInputMetric = (metricId: number, order = 0) =>
  `add-input-to-${metricId}-${order}`;

export const getSeries = (
  series: MetricSeries[],
  granularity = DEFAULT_GRANULARITY,
  forceGranularity = false
) => {
  const selected = series.find(s => s.granularity === granularity);
  if (selected || forceGranularity) {
    return selected;
  }
  return series[0];
};

export const createNodes = (
  tree: MetricTree,
  treeVariant: TreeVariant,
  granularity?: ModelSeriesGranularity,
  forceGranularity = false
): Node[] => {
  const nodes: Node[] = [];
  for (const m of tree.metrics) {
    nodes.push({
      id: generateIdForMetric(m.id),
      type: NodeType.METRIC,
      position: m.position,
      connectable: true,
      data: {
        id: m.id,
        name: m.name,
        signalId: m.signalId,
        valueType: m.valueType,
        granularity: getSeries(m.series, granularity, forceGranularity)?.granularity,
        samples: getSeries(m.series, granularity, forceGranularity)?.samples || [],
        numberOfChildren: m.numberOfChildren || 0,
        numberOfParents: m.numberOfParents || 0,
        suggestedCount: m.suggestedCount,
        higherIsBetter: m.higherIsBetter,
        isTemporary: m.id === tree.rootMetricId && tree.rootExistsInTree === false,
        rcaData: m.rcaData,
        formula: m.formula,
      },
    });

    if (
      tree.rootMetricId &&
      m.id !== tree.rootMetricId &&
      treeVariant === TreeVariant.SIMPLE &&
      m.numberOfChildren > 0
    ) {
      nodes.push({
        id: generateIdForMetricGroup(m.id),
        type: NodeType.METRIC_GROUP,
        position: {x: 0, y: 0},
        data: {
          rootMetricId: m.id,
          count: m.numberOfChildren,
          rcaData: m.rcaData
            ? {
                childrenExplainability: m.rcaData?.childrenExplainability,
              }
            : undefined,
        },
      });
    }

    if (
      tree.rootMetricId &&
      m.numberOfChildren < NUMBER_OF_METRICS_FOR_ADD_INPUT_METRIC &&
      m.id === tree.rootMetricId &&
      treeVariant === TreeVariant.SIMPLE
    ) {
      range(NUMBER_OF_METRICS_FOR_ADD_INPUT_METRIC - m.numberOfChildren).forEach(idx => {
        nodes.push({
          id: generateIdForAddInputMetric(m.id, idx),
          position: {x: 0, y: 0},
          type: NodeType.ADD_INPUT_METRIC,
          data: {
            rootMetricId: m.id,
          },
        });
      });
    }
  }
  return nodes;
};

export const createdEdges = (tree: MetricTree, treeVariant: TreeVariant) => {
  const edges = [];
  const metricMap = keyBy(tree.metrics, 'id');

  for (const e of tree.edges) {
    edges.push({
      id: `edge-${e.rootMetricId}-${e.inputMetricId}`,
      source: generateIdForMetric(e.inputMetricId),
      target: generateIdForMetric(e.rootMetricId),
      type: EdgeType.METRIC_CONNECTION,
      animated: true,
      data: {
        correlation: e.correlation,
        isSignificantCorrelation: e.isSignificantCorrelation,
        relationshipType: e.relationshipType,
        sourceMetric: metricMap[e.inputMetricId],
        targetMetric: metricMap[e.rootMetricId],
      },
      style: {
        strokeWidth: 3,
      },
      markerEnd: DEFAULT_MARKER_END,
    });

    if (
      tree.rootMetricId &&
      e.inputMetricId !== tree.rootMetricId &&
      treeVariant === TreeVariant.SIMPLE
    ) {
      edges.push({
        id: `edge-${e.inputMetricId}-group`,
        source: generateIdForMetricGroup(e.inputMetricId),
        target: generateIdForMetric(e.inputMetricId),
        animated: true,
        style: {
          strokeWidth: 2,
        },
        markerEnd: DEFAULT_MARKER_END,
      });
    }
  }

  if (tree.rootMetricId) {
    const rootMetric = tree.metrics.find(m => m.id === tree.rootMetricId);
    if (rootMetric && rootMetric.numberOfChildren < 2) {
      range(NUMBER_OF_METRICS_FOR_ADD_INPUT_METRIC - rootMetric.numberOfChildren).forEach(idx => {
        edges.push({
          id: `edge-${rootMetric.id}-input-${idx}`,
          source: generateIdForAddInputMetric(rootMetric.id, idx),
          target: generateIdForMetric(rootMetric.id),
          animated: true,
          style: {
            strokeWidth: 2,
          },
          markerEnd: DEFAULT_MARKER_END,
        });
      });
    }
  }

  return edges;
};

const getNodeDimensions = (node: Node) => {
  if (node.type === NodeType.METRIC || node.type === NodeType.ADD_INPUT_METRIC) {
    return {width: KPI_NODE_WIDTH, height: KPI_NODE_HEIGHT};
  }
  if (node.type === NodeType.METRIC_GROUP) {
    return {width: KPI_GROUP_NODE_WIDTH, height: KPI_GROUP_NODE_HEIGHT};
  }
};

// reference: https://reactflow.dev/learn/layouting/layouting#dagre
export const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({rankdir: 'TB', edgesep: 10, ranksep: 80, nodesep: 80});
  nodes.forEach(node => {
    dagreGraph.setNode(node.id, getNodeDimensions(node));
  });
  edges.forEach(edge => {
    dagreGraph.setEdge(edge.target, edge.source);
  });
  dagre.layout(dagreGraph);

  const newNodes = nodes.map(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const dimensions = getNodeDimensions(node);
    const newNode = {
      ...node,
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - dimensions.width / 2,
        y: nodeWithPosition.y - dimensions.height / 2,
      },
    };

    return newNode;
  });

  return {nodes: newNodes, edges};
};

export const formatMetricValue = function (value: number, isPercentageValue: boolean) {
  if (!exists(value)) {
    return '-';
  }
  if (isPercentageValue) {
    return number2k(value * 100) + '%';
  }
  return number2k(value);
};

export const correlationToAlpha = (correlation: number) => {
  return Math.min(1, Math.max(0.4, Math.abs(correlation)));
};

export const correlationToText = (correlation: number) => {
  if (exists(correlation)) {
    return `${correlation > 0 ? '+' : ''}${number2k(correlation)}`;
  }
  return null;
};

export const getCorrelationBackgroundColor = (correlation: number, isSignificant: boolean) => {
  const alpha = correlationToAlpha(correlation);
  if (correlation === 0 || !isSignificant) {
    return INSIGNIFICANT_CORRELATION_COLOR;
  }
  if (correlation < 0) {
    return colorAlphaTransformer(MAX_CORRELATION_BAD_COLOR, alpha, true);
  }
  if (correlation > 0) {
    return colorAlphaTransformer(MAX_CORRELATION_GOOD_COLOR, alpha, true);
  }
};

export const getCorrelationTextColor = (correlation: number, isSignificant: boolean) => {
  if (isSignificant) {
    return Math.abs(correlation) > 0.6 ? '#fff' : '#000';
  }
  return '#000';
};

import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, modelUpdated} from '../core/core.actions';
import {DataPipelineActionType} from './data-pipelines.actions.ts';
import {ModelKey} from '../../constants/model-key';
import {dataPipelineToastCreator} from '../toasts.actions';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {
  demoProductLimitedActionsFilter,
  errorExtractor,
  getDMPToastErrorMessage,
} from '../store.utils';
import {
  createDataPipelineNetworkRequest,
  deleteDataPipelineNetworkRequest,
  editDataPipelineNetworkRequest,
  reorderDataPipelineNetworkRequest,
} from '../../http/data-pipelines.network-requests.ts';

export const createDataPipelineEpic: Epic = createRequestEpic(
  {
    types: [DataPipelineActionType.CREATE_DATA_PIPELINE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_DATA_PIPELINE,
    request: createDataPipelineNetworkRequest,
    onSuccess: dataPipeline => [
      dataPipelineToastCreator('CREATE_SUCCESS'),
      modelCreated(dataPipeline, ModelKey.DATA_PIPELINE),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'data_pipeline');
      if (toastMessage) {
        return [showToastMessage(toastMessage, ToastType.ERROR)];
      }
      return [dataPipelineToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const updateDataPipelineEpic: Epic = createRequestEpic(
  {
    types: [DataPipelineActionType.UPDATE_DATA_PIPELINE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_DATA_PIPELINE,
    request: editDataPipelineNetworkRequest,
    onSuccess: dataPipeline => [
      dataPipelineToastCreator('UPDATE_SUCCESS'),
      modelUpdated(dataPipeline, ModelKey.DATA_PIPELINE),
    ],
    onError: err_ => {
      const err = errorExtractor(err_);
      const toastMessage = getDMPToastErrorMessage(err, 'data_pipeline');
      if (toastMessage) {
        return [showToastMessage(toastMessage, ToastType.ERROR)];
      }
      return [dataPipelineToastCreator('CREATE_ERROR')];
    },
  },
  HttpClient
);

export const deleteDataPipelineEpic: Epic = createRequestEpic(
  {
    types: [DataPipelineActionType.DELETE_DATA_PIPELINE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_DATA_PIPELINE,
    request: deleteDataPipelineNetworkRequest,
    onSuccess: (res, payload) => [
      dataPipelineToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.DATA_PIPELINE),
    ],
    onError: err => [dataPipelineToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const reorderDataPipelineEpic: Epic = createRequestEpic(
  {
    types: [DataPipelineActionType.REORDER_DATA_PIPELINE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REORDER_DATA_PIPELINE,
    request: reorderDataPipelineNetworkRequest,
    onSuccess: (res, payload) => [modelUpdated({}, ModelKey.DATA_PIPELINE)],
    onError: err => [dataPipelineToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

import * as React from 'react';
import classNames from 'classnames';
import {ArrowIconUp} from '../../../../../simple/controls/icons/icons.component';
import {exists, number2k} from 'front-core';
import classes from './shared-columns-content.module.scss';
import {useMemo} from 'react';

interface OwnProps {
  uplift: number;
  insignificant?: boolean;
  higherIsBetter?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const UpliftDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {uplift, insignificant, higherIsBetter = true, className} = props;

  const isNegative = useMemo(() => {
    if (higherIsBetter) {
      return uplift < 0;
    }
    return uplift > 0;
  }, [higherIsBetter, uplift]);

  if (!exists(uplift)) {
    return <div className={classNames(classes.Uplift, className)}>{'-'}</div>;
  }

  return (
    <div
      className={classNames(
        classes.UpliftDisplay,
        isNegative && classes.Negative,
        uplift < 0 && classes.Flip,
        insignificant && classes.Insignificant,
        className
      )}
    >
      <ArrowIconUp className={classes.Icon} />
      {`${uplift > 0 ? '+' : ''}${number2k(uplift * 100)}%`}
    </div>
  );
};

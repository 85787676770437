import {DATA_PIPELINE_ID_PATH_PARAM} from '../../../../constants/app-routes.ts';
import {DataPipeline} from '../../../../objects/models/data-pipeline.model.ts';
import {composition, exists} from 'front-core';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc.tsx';
import TransKeys from 'translations';
import {AppTabs, FancyHeader, GearCodeLightIcon, ModalLayout} from 'ui-components';
import {get} from 'lodash';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc.tsx';
import classes from './data-pipeline-view-panel.module.scss';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys.ts';
import {getDataPipelineNetworkRequest} from '../../../../http/data-pipelines.network-requests.ts';
import {useTranslation} from 'react-i18next';
import {useMemo, useState} from 'react';
import classNames from 'classnames';
import {DataPipelineTabKey} from '../data-pipeline-form-panel/data-pipeline-form-panel.component.tsx';
import {SQLViewer} from '../../../shared/components/general/sql-viewer/sql-viewer.component.tsx';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component.tsx';

interface Props {
  dataPipeline: DataPipeline;
  onClose?: () => void;
  [DATA_PIPELINE_ID_PATH_PARAM]?: number;
}

const SELECTED_DATA_PIPELINE_KEY = SharedSelectionKeys.DATA_PIPELINE_VIEW__DATA_PIPELINE;

export const DataPipelineViewPanelComponent = (props: Props) => {
  const {dataPipeline, onClose} = props;
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState(DataPipelineTabKey.CREATE_SQL);

  const tabs = useMemo(() => {
    return [
      {
        key: DataPipelineTabKey.CREATE_SQL,
        label: 'Create SQL',
      },
      {
        key: DataPipelineTabKey.UPDATE_SQL,
        label: 'Update SQL',
        disabled: !exists(dataPipeline.updateSql),
      },
    ];
  }, [dataPipeline]);

  return (
    <div className={classes.Container}>
      <ModalLayout>
        <div className={classes.DataPipelineViewPanel}>
          <FancyHeader
            icon={GearCodeLightIcon}
            title={t(TransKeys.MODELS.DATA_PIPELINE)}
            onClose={onClose}
            caps
          />
          <div className={classes.Body}>
            <div className={classNames(classes.Block, classes.NameBlock)}>
              <div className={classes.Name}>{dataPipeline.name}</div>
            </div>
            <div className={classNames(classes.Block, classes.SQLBlocks)}>
              <AppTabs
                tabs={tabs}
                selected={selectedTab}
                onChange={setSelectedTab as any}
                fullWidth
              />
              {selectedTab === DataPipelineTabKey.CREATE_SQL && (
                <SQLViewer sql={dataPipeline.createSql} />
              )}
              {selectedTab === DataPipelineTabKey.UPDATE_SQL && (
                <SQLViewer sql={dataPipeline.updateSql} />
              )}
            </div>
            {dataPipeline.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={dataPipeline.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const DataPipelineViewPanel = composition<Props>(
  DataPipelineViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.DATA_PIPELINE,
    modalIcon: GearCodeLightIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'dataPipeline.errorCode'),
  }),
  withLoadBefore({
    dataPipeline: {
      selectedKey: SELECTED_DATA_PIPELINE_KEY,
      actionKey: SELECTED_DATA_PIPELINE_KEY,
      request: getDataPipelineNetworkRequest,
      mapPayloadFromProps: props => props[DATA_PIPELINE_ID_PATH_PARAM],
      shouldCall: props => props[DATA_PIPELINE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default DataPipelineViewPanel;

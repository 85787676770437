import {
  ActionsDropdown,
  Avatar,
  EditIcon,
  EnvelopeLightIcon,
  MoreIcon,
  SlackIcon,
  TrashIcon,
  ClipboardListLightIcon,
} from 'ui-components';
import {
  HomepageSubscription,
  HomepageSubscriptionVIA,
} from '../../../../../../objects/models/homepage.model';
import classes from './subscription-list-item.module.scss';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {getHomepageSubscriptionCounts} from '../../../../components/homepage-summary/homepage-summary.utils';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model.ts';
import {useIsAdmin} from '../../../../../../core/hooks/use-is-admin.hook.ts';

type SubscriptionListItemProps = {
  subscription: HomepageSubscription;
  onEdit?: (subscriptionId: number) => void;
  onDelete?: (subscriptionId: number) => void;
  onViewSubscriptionReport?: (subscriptionId: number, granularity: ModelSeriesGranularity) => void;
  onCreateSubscriptionTest: (homepageId: number, subscriptionId: number) => void;
};

export const SubscriptionListItem = (props: SubscriptionListItemProps) => {
  const {subscription, onEdit, onDelete, onViewSubscriptionReport, onCreateSubscriptionTest} =
    props;
  const {t} = useTranslation();
  const isAdmin = useIsAdmin();

  const {dailyCount, weeklyCount} = useMemo(() => {
    const {dailyMetricsCount, dailyFunnelsCount, weeklyMetricsCount, weeklyFunnelsCount} =
      getHomepageSubscriptionCounts(subscription);

    return {
      dailyCount: dailyMetricsCount + dailyFunnelsCount,
      weeklyCount: weeklyMetricsCount + weeklyFunnelsCount,
    };
  }, [subscription]);

  const actions = useMemo(() => {
    return [
      {
        title: t(TransKeys.GENERAL.ACTIONS.EDIT),
        icon: EditIcon,
        onClick: () => onEdit(subscription.id),
      },
      {
        title: t(TransKeys.GENERAL.ACTIONS.DELETE),
        icon: TrashIcon,
        onClick: () => onDelete(subscription.id),
      },
      {
        title: 'View weekly report',
        icon: ClipboardListLightIcon,
        onClick: () => onViewSubscriptionReport(subscription.id, ModelSeriesGranularity.WEEK),
        hide: !isAdmin,
      },
      {
        title: 'View monthly report',
        icon: ClipboardListLightIcon,
        onClick: () => onViewSubscriptionReport(subscription.id, ModelSeriesGranularity.MONTH),
        hide: !isAdmin,
      },
      {
        title: 'Create subscription test manager',
        icon: ClipboardListLightIcon,
        onClick: () => onCreateSubscriptionTest(subscription.homepageId, subscription.id),
        hide: !isAdmin,
      },
    ];
  }, [
    t,
    isAdmin,
    onEdit,
    subscription.id,
    subscription.homepageId,
    onDelete,
    onViewSubscriptionReport,
    onCreateSubscriptionTest,
  ]);

  return (
    <div className={classes.SubscriptionListItem}>
      <div className={classes.Header}>
        <div className={classes.Description}>
          {subscription.via.includes(HomepageSubscriptionVIA.SLACK) && (
            <div className={classes.SubscriptionNameLine}>
              <SlackIcon className={classes.Icon} />
              <div>#{subscription.slackChannelsData[0]?.channelName}</div>
            </div>
          )}
          {subscription.via.includes(HomepageSubscriptionVIA.MAIL) && (
            <div className={classes.SubscriptionNameLine}>
              <EnvelopeLightIcon />
              <div>{subscription.user.email}</div>
            </div>
          )}
        </div>
        <ActionsDropdown icon={MoreIcon} iconDropdown border={false} actions={actions} />
      </div>
      <div className={classes.SubscriptionFooter}>
        <div className={classes.InfoItem}>
          <div className={classes.Label}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.ITEM.FOOTER.WEEKLY_METRICS_COUNT.LABEL)}
          </div>
          <div className={classes.MetricCount}>{weeklyCount} KPIs</div>
        </div>
        <div className={classes.InfoItem}>
          <div className={classes.Label}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.ITEM.FOOTER.DAILY_METRICS_COUNT.LABEL)}
          </div>
          <div className={classes.MetricCount}>{dailyCount} KPIs</div>
        </div>
        <div className={classes.InfoItem}>
          <div className={classes.Label}>{t(TransKeys.GENERAL.LABELS.CREATED_BY)}</div>
          <Avatar size="extra-small" name={subscription.user.fullName} />
        </div>
      </div>
    </div>
  );
};

import {
  AnomalyMode,
  HomepageMetric,
  HomepageModel,
} from '../../../../../objects/models/homepage.model';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ModelSample,
  ModelSampleSeries,
  ModelSampleSeriesModel,
  ModelSeriesGranularity,
} from '../../../../../objects/models/model-sample-series.model';
import {capitalize, keyBy, last, range, takeRight, values} from 'lodash';
import {exists, number2k} from 'front-core';
import {MetricValueType} from '../../../../../objects/models/metric.model';
import {
  extractXLabelFromMetric,
  getDefaultSeries,
  getSampleWoWChange,
} from '../homepage-summary.utils';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {ChartConfig, MetricGoalAtTime} from '../homepage-summary.types';
import {useNavigate} from 'react-router';
import {DateRange, RCAMode, useLocalStorage} from 'ui-components';
import {AppRoutes, TEAM_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {
  METRIC_PAGE_DATE_QUERY_PARAM,
  METRIC_PAGE_GRANULARITY_QUERY_PARAM,
} from '../../../../metrics/pages/metric-page/metric-page.component';
import {getMinimumDate} from '../../../../../utils/general.utils';
import {ConfidenceIntervalConfig} from '../../../../../objects/models/user-settings.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {
  HomepageModelSamplesViewerFilters,
  TimeframeOption,
} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.types';
import {getDateFromTimeframeOption} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.utils';
import {
  DEFAULT_CHART_CONFIG,
  DEFAULT_TIMEFRAME,
  HOMEPAGE_CHART_CONFIG,
  SHOW_ALL_POINTS_END_DATE,
} from '../components/homepage-model-samples-viewer/homepage-model-samples-viewer.consts';

export const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;

export const useDefaultSeries = (
  model: HomepageModel,
  granularity = DEFAULT_GRANULARITY
): ModelSampleSeries => {
  return useMemo(() => getDefaultSeries(model, granularity), [model, granularity]);
};

export const useHomepageModelData = (model: HomepageModel, granularity = DEFAULT_GRANULARITY) => {
  const reviewedSeries = useDefaultSeries(model, granularity);
  const nonPartialSamples = useMemo(
    () => (reviewedSeries?.samples || []).filter(s => s.isPartial === false) || [],
    [reviewedSeries]
  );
  const [prevSample, lastSample] = useMemo(
    () => takeRight(nonPartialSamples || [], 2),
    [nonPartialSamples]
  );
  const lastSampleValues = useMemo(
    () =>
      takeRight(
        (nonPartialSamples || []).map(s => s.value),
        5
      ),
    [nonPartialSamples]
  );
  const renderValue = useMemo(() => {
    if (!model) {
      return;
    }
    if (!lastSample) {
      return;
    }
    switch (model.modelType) {
      case ModelSampleSeriesModel.FUNNEL:
        return `${number2k(lastSample.value * 100)}%`;
      case ModelSampleSeriesModel.METRIC:
        if ((model as HomepageMetric).valueType === MetricValueType.PERCENTAGE) {
          return `${number2k(lastSample.value * 100)}%`;
        }
        return number2k(lastSample.value);
    }
  }, [lastSample, model]);
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, prevSample, model?.higherIsBetter),
    [lastSample, prevSample, model]
  );
  const {minSampleDate, maxSampleDate} = useMemo(
    () => ({
      minSampleDate: model?.minSampleDate ? moment.utc(model.minSampleDate).toDate() : undefined,
      maxSampleDate: model?.maxSampleDate ? moment.utc(model.maxSampleDate).toDate() : undefined,
    }),
    [model]
  );

  return {
    reviewedSeries,
    lastSample,
    prevSample,
    lastSampleValues,
    renderValue,
    trend,
    minSampleDate,
    maxSampleDate,
  };
};

export const useRequestRCAForSample = (
  configuration: ConfidenceIntervalConfig,
  granularity: ModelSeriesGranularity,
  teamId?: number
) => {
  const navigate = useNavigate();

  const rcaMode = useMemo(
    () =>
      configuration.anomalyMode === AnomalyMode.LOOPS_ALGO
        ? RCAMode.LOOPS_ALGO
        : RCAMode.COMPARE_TO_DATE,
    [configuration]
  );

  const rerouteToMetricPage = useCallback(
    (metricId: number, datetime: string, granularity: ModelSeriesGranularity) => {
      const metricPageQueryParams = {
        rcaMode,
        [METRIC_PAGE_DATE_QUERY_PARAM]: moment
          .utc(datetime)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        [METRIC_PAGE_GRANULARITY_QUERY_PARAM]: granularity,
        [TEAM_ID_PATH_PARAM]: teamId,
      };

      return navigate(AppRoutes.viewMetric(metricId, metricPageQueryParams));
    },
    [navigate, rcaMode, teamId]
  );

  const requestRCAForSample = useCallback(
    async (metric: HomepageMetric, sample: ModelSample) => {
      rerouteToMetricPage(metric.id, sample.datetime, granularity);
    },
    [granularity, rerouteToMetricPage]
  );

  return {
    requestRCAForSample,
  };
};

export const useMetricGoalData = (metric: HomepageMetric): MetricGoalAtTime[] => {
  const defaultSeries = useDefaultSeries(metric);
  return useMemo(() => {
    if (!metric || !defaultSeries || !metric.goal) {
      return [];
    }
    const lastSample = last(defaultSeries.samples);
    const nonPartialSamples = defaultSeries.samples.filter(s => s.isPartial === false);
    const metricTimeDelta = moment
      .utc(lastSample.sampleDatetime)
      .diff(moment.utc(lastSample.datetime), 'days');
    const sampleByDate = keyBy(nonPartialSamples, 'datetime');
    const goalStart = moment.utc(metric.goal.startDate).subtract(metricTimeDelta, 'days');
    const goalEnd = moment.utc(metric.goal.endDate).subtract(metricTimeDelta, 'days');
    let startOfGoalSample = nonPartialSamples.find(s =>
      moment.utc(s.datetime).isSameOrAfter(goalStart)
    );
    if (!exists(startOfGoalSample)) {
      startOfGoalSample = nonPartialSamples[nonPartialSamples.length - 1];
    }
    const goalValue = startOfGoalSample.expectedValue;
    const diff = goalEnd.diff(goalStart, defaultSeries.granularity);
    const totalRange = metric.goal.value - goalValue;
    const dailyGoal = totalRange / diff;
    return range(diff + 1).map(i => {
      const datetime = goalStart.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
      const x = {
        datetime,
        value: goalValue + dailyGoal * i,
        matchingSampleValue: sampleByDate[datetime]?.value,
      };
      goalStart.add(1, defaultSeries.granularity);
      return x;
    });
  }, [defaultSeries, metric]);
};

export const useChartXLabelForMetric = (
  metric: HomepageMetric,
  granularity: ModelSeriesGranularity
) => {
  const {productEntitiesMap} = useProductData();
  const [xLabel, setXLabel] = useState<string>('');
  const getXLabel = useCallback(async () => {
    if (!metric) {
      return;
    }
    const xLabel = await extractXLabelFromMetric(
      metric,
      productEntitiesMap[metric.entity].name,
      granularity
    );
    setXLabel(capitalize(xLabel));
  }, [metric, productEntitiesMap, granularity]);

  useEffect(() => {
    getXLabel();
  }, [getXLabel]);

  return xLabel;
};

const HOMEPAGE_MODEL_GRANULARITY_STORAGE_KEY = 'homepage-model-granularity';

export const useModelGranularity = (
  metric: HomepageMetric,
  defaultGranularity: ModelSeriesGranularity = ModelSeriesGranularity.WEEK
) => {
  const [granularity, setGranularity] = useLocalStorage<ModelSeriesGranularity>(
    HOMEPAGE_MODEL_GRANULARITY_STORAGE_KEY,
    defaultGranularity
  );
  const granularityOptions = useMemo(() => {
    if (!metric) {
      return [];
    }
    return Array.from(new Set(metric.series.map(s => s.granularity))).sort(
      (a, b) =>
        values(ModelSeriesGranularity).indexOf(a) - values(ModelSeriesGranularity).indexOf(b)
    );
  }, [metric]);

  const granularity_ = useMemo(() => {
    if (granularityOptions.includes(granularity)) {
      return granularity;
    } else if (granularityOptions.includes(defaultGranularity)) {
      return defaultGranularity;
    } else {
      return granularityOptions[0];
    }
  }, [defaultGranularity, granularity, granularityOptions]);

  return {
    granularity: granularity_,
    setGranularity,
    granularityOptions,
  };
};

interface UseModelSampleTimeframeConfig {
  toDate?: string;
}

export interface UseModelSampleTimeframeResponse {
  filters: HomepageModelSamplesViewerFilters;
  isCustomRange: boolean;
  showAllPartialPoints: boolean;
  onTimeframeOptionSelected: (option: TimeframeOption) => void;
  onCustomDatesSelected: (value: DateRange) => void;
  onChangeShowPartialPoints: (show: boolean) => void;
  isTimeframeSelected: (option: TimeframeOption) => boolean;
}

export const useModelSampleTimeframe = (
  config?: UseModelSampleTimeframeConfig
): UseModelSampleTimeframeResponse => {
  const {toDate} = config || {};
  const {defaultSource} = useProductData();
  const [filters, setFilters] = useState<HomepageModelSamplesViewerFilters>(() => ({
    fromDate: getDateFromTimeframeOption(
      getMinimumDate([toDate, defaultSource.lastValidDate]),
      DEFAULT_TIMEFRAME
    ),
    toDate: toDate || getDateFromTimeframeOption(defaultSource.lastValidDate),
  }));
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [showAllPartialPoints, setShowAllPartialPoints] = useState(false);

  const changeFilters = useCallback(
    (newFilters: HomepageModelSamplesViewerFilters) =>
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      })),
    [setFilters]
  );
  const onTimeframeOptionSelected = useCallback(
    (option: TimeframeOption) => {
      setFilters(filters => ({
        ...filters,
        fromDate: getDateFromTimeframeOption(defaultSource.lastValidDate, option),
        toDate: showAllPartialPoints
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      }));
      setIsCustomRange(false);
    },
    [setFilters, setIsCustomRange, showAllPartialPoints, defaultSource.lastValidDate]
  );
  const onCustomDatesSelected = useCallback(
    (value: DateRange) => {
      changeFilters({
        fromDate: moment
          .utc(value.from)
          .startOf('d')
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        toDate: moment.utc(value.to).startOf('d').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
      });
      setIsCustomRange(true);
    },
    [changeFilters]
  );
  const onChangeShowPartialPoints = useCallback(
    (show: boolean) => {
      setShowAllPartialPoints(show);
      changeFilters({
        toDate: show
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      });
    },
    [setShowAllPartialPoints, changeFilters, defaultSource.lastValidDate]
  );
  const isTimeframeSelected = useCallback(
    (option: TimeframeOption) => {
      const value = getDateFromTimeframeOption(defaultSource.lastValidDate, option);
      return value === filters.fromDate && !isCustomRange;
    },
    [defaultSource.lastValidDate, filters, isCustomRange]
  );

  return {
    filters,
    isCustomRange,
    showAllPartialPoints,
    onTimeframeOptionSelected,
    onCustomDatesSelected,
    onChangeShowPartialPoints,
    isTimeframeSelected,
  };
};

export const useHomepageChartConfig = () => {
  const [chartConfig, setChartConfig] = useLocalStorage<ChartConfig>(
    HOMEPAGE_CHART_CONFIG,
    DEFAULT_CHART_CONFIG
  );

  return {
    chartConfig,
    setChartConfig,
  };
};

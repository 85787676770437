import classes from '../milestone-form-panel.module.scss';
import {CheckIcon} from 'ui-components';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  title: string;
  isDefined: boolean;
}

type AllProps = OwnProps;

export const MilestoneTabLabel = (props: AllProps) => {
  const {title, isDefined} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.TabTitle}>
      <div className={classes.TabTitleText}>{title}</div>
      <Tooltip title={t(TransKeys.MILESTONE_FORM.TYPE_ALREADY_DEFINED)}>
        <CheckIcon className={classNames(classes.TabTitleIcon, !isDefined && classes.Hidden)} />
      </Tooltip>
    </div>
  );
};

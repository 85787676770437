import * as React from 'react';
import classes from './hover-helper-tip.module.scss';
import {Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {
  CircleExclamationSolidIcon,
  CircleInfoRegularIcon,
  MemoRegularIcon,
} from '../../controls/icons/icons.component';
import {sanitize} from 'front-core';
import {useMemo} from 'react';
import parse from 'html-react-parser';

export enum HelperTipType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
  MEMO = 'MEMO',
}

interface OwnProps {
  title: NonNullable<React.ReactNode> | string;
  className?: string;
  icon?: any;
  iconClassNames?: string;
  type?: HelperTipType;
  small?: boolean;
}

type AllProps = OwnProps;

const ICONS_FOR_TYPE = {
  [HelperTipType.INFO]: CircleInfoRegularIcon,
  [HelperTipType.WARN]: CircleExclamationSolidIcon,
  [HelperTipType.ERROR]: CircleExclamationSolidIcon,
  [HelperTipType.MEMO]: MemoRegularIcon,
};

export const HoverHelperTip: React.FC<AllProps> = (props: AllProps) => {
  const {
    title: titleFromProps,
    className,
    icon,
    type = HelperTipType.INFO,
    small,
    iconClassNames,
  } = props;
  const Icon = icon ? icon : ICONS_FOR_TYPE[type];

  const title = useMemo(() => {
    const parsed = parse(sanitize(titleFromProps));
    if (typeof parsed !== 'string') {
      return titleFromProps;
    }
    return parsed;
  }, [titleFromProps]);

  return (
    <Tooltip
      interactive={typeof title !== 'string'}
      title={title}
      placement={'top'}
      className={classNames(classes.HelperText, small && classes.Small, classes[type], className)}
    >
      <Icon className={classNames(iconClassNames, classes.HelperIcon)} />
    </Tooltip>
  );
};

import * as React from 'react';
import {ExplainabilityLabel, ExplainabilityLabelDisplay} from 'ui-components';
import classes from './rca-explainers-explainability-label-display.module.scss';
import {exists} from 'front-core';

interface OwnProps {
  label: ExplainabilityLabel;
}

type AllProps = OwnProps;

export const RCAExplainersExplainabilityLabelDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {label} = props;

  return (
    <ExplainabilityLabelDisplay
      helperText={''}
      label={label}
      isSignificant={exists(label)}
      className={classes.RCAExplainersExplainabilityLabelClassName}
    />
  );
};

import {useCallback, useContext, useMemo, useState} from 'react';
import {composition, exists} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getTableNetworkRequest} from '../../../../http/tables.network-requests';
import {getAllTableColumnsNetworkRequest} from '../../../../http/table-columns.network-requests';
import {
  DATA_PIPELINE_ID_PATH_PARAM,
  TABLE_COLUMN_ID_PATH_PARAM,
  TABLE_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  FancyHeader,
  InlineLabel,
  LabelWrapper,
  ModalLayout,
  PrimaryTabs,
  TableIcon,
  TextButton,
} from 'ui-components';
import TransKeys from 'translations';
import {Table, TableDefinitionType} from '../../../../objects/models/table.model';
import {TableColumn} from '../../../../objects/models/table-column.model';
import classes from './table-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {SQLViewer} from '../../../shared/components/general/sql-viewer/sql-viewer.component';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {TableEventsTable} from './components/table-events-table/table-events-table.component';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useDispatch} from 'react-redux';
import {getSelected} from '../../../../store/selected/selected.actions';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  table: Table;
  tableColumns: TableColumn[];
  onClose?: () => void;
}

type AllProps = OwnProps;

const SELECTED_TABLE_KEY = SharedSelectionKeys.TABLE_VIEW__TABLE;
const SELECTED_TABLE_COLUMNS_KEY = SharedSelectionKeys.TABLE_VIEW__TABLE_COLUMNS;

const TableViewPanelComponent = (props: AllProps) => {
  const {table, tableColumns, onClose} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {productEntitiesMap} = useProductData();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const [selectedTabKey, setSelectedTabKey] = useState(
    exists(tableColumns) && tableColumns.length > 0 ? 'columns' : 'definition'
  );

  const sql = useMemo(() => {
    if (table.definition.type === TableDefinitionType.QUERY && table.definition.query) {
      return table.definition.query;
    }
    if (table.definition.type === TableDefinitionType.TABLE && table.definition.name) {
      return `SELECT * FROM ${table.definition.prefix ? table.definition.prefix + '.' : ''}${
        table.definition.name
      }`;
    }
  }, [table]);

  const onCreateSegment = useCallback(
    (tableColumn: TableColumn) =>
      openSecondaryPanel(PanelKey.SEGMENT_FORM_PANEL, {
        [TABLE_COLUMN_ID_PATH_PARAM]: tableColumn.id,
        onSuccess: () => {
          dispatch(
            getSelected(SELECTED_TABLE_COLUMNS_KEY, {
              tableId: table.id,
              ignoreEventNameColumn: false,
            })
          );
        },
      }),
    [openSecondaryPanel, dispatch, table?.id]
  );
  const onViewDataPipeline = useCallback(
    dataPipelineId =>
      openSecondaryPanel(PanelKey.DATA_PIPELINE_VIEW_PANEL, {
        [DATA_PIPELINE_ID_PATH_PARAM]: dataPipelineId,
      }),
    [openSecondaryPanel]
  );

  const renderTableDefinition = () => {
    return (
      <div className={classes.TabWrapper}>
        <div className={classes.TableDefinition}>
          <LabelWrapper label={t(TransKeys.TABLE.TAB_DEFINITION.LABELS.NAME)} mutedLabel>
            {table.definition.name}
          </LabelWrapper>
          <LabelWrapper label={t(TransKeys.TABLE.TAB_DEFINITION.LABELS.TYPE)} mutedLabel>
            {t(TransKeys.TABLES.TYPE[table.type.toUpperCase()])}
          </LabelWrapper>
          {table.parameters.entity_id && (
            <LabelWrapper
              label={`${t(TransKeys.TABLES.ENTITY[table.entity.toUpperCase()])} ${t(
                TransKeys.TABLE.TAB_DEFINITION.LABELS.ENTITY_ID
              )}`}
              mutedLabel
            >
              {table.parameters.entity_id}
            </LabelWrapper>
          )}
          {table.parameters.event_name && (
            <LabelWrapper label={t(TransKeys.TABLE.TAB_DEFINITION.LABELS.EVENT_NAME)} mutedLabel>
              {table.parameters.event_name}
            </LabelWrapper>
          )}
          {table.parameters.timestamp && (
            <LabelWrapper label={t(TransKeys.TABLE.TAB_DEFINITION.LABELS.TIMESTAMP)} mutedLabel>
              {table.parameters.timestamp}
            </LabelWrapper>
          )}
        </div>
        {table.dataPipelines.length > 0 && (
          <div className={classes.TableDefinition}>
            <LabelWrapper
              label={t(TransKeys.TABLE.TAB_DEFINITION.LABELS.DATA_PIPELINES)}
              mutedLabel
            >
              <div className={classes.List}>
                {table.dataPipelines.map(dataPipeline => (
                  <TextButton onClick={() => onViewDataPipeline(dataPipeline.id)}>
                    {dataPipeline.name}
                  </TextButton>
                ))}
              </div>
            </LabelWrapper>
          </div>
        )}
        {sql && (
          <LabelWrapper label={'SQL'} mutedLabel>
            <SQLViewer sql={sql} />
          </LabelWrapper>
        )}
      </div>
    );
  };

  return (
    <div className={classes.TableViewPanelContainer}>
      <ModalLayout>
        <div className={classes.TableViewPanel}>
          <FancyHeader
            icon={TableIcon}
            title={t(TransKeys.MODELS.TABLE)}
            onClose={onClose}
            caps
            className={classes.Header}
          />
          <div className={classes.Body}>
            <div className={classes.HeaderBlock}>
              <div className={classes.Title}>{table.name}</div>
              {table.shortDescription && (
                <div className={classes.Description}>{table.shortDescription}</div>
              )}
            </div>
            <div className={classes.Block}>
              <FlexHorizontal spacing verticalAlignCenter>
                <InlineLabel
                  labelClassName={classes.InlineLabel}
                  icon={getEntityIcon(table.entity)}
                  label={productEntitiesMap[table.entity]?.name}
                />
                <InlineLabel
                  labelClassName={classes.InlineLabel}
                  label={t(TransKeys.TABLES.TYPE[table.type.toUpperCase()])}
                />
              </FlexHorizontal>
            </div>
            <div className={classes.Block}>
              <PrimaryTabs
                selected={selectedTabKey}
                onChange={setSelectedTabKey}
                sticky
                tabs={[
                  {
                    label: t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.TITLE),
                    key: 'columns',
                    render: () => (
                      <TableEventsTable
                        tableType={table.type}
                        tableColumns={tableColumns}
                        tableParameters={table.parameters}
                        onCreateSegment={table.isComputed ? null : onCreateSegment}
                      />
                    ),
                    hide: !(tableColumns.length > 0),
                  },
                  {
                    label: t(TransKeys.TABLES.PANEL.VIEW.DEFINITION.TITLE),
                    key: 'definition',
                    render: renderTableDefinition,
                    hide: !exists(sql),
                  },
                ]}
              />
            </div>
            {table.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={table.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const TableViewPanel = composition<AllProps>(
  TableViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.TABLE,
    modalIcon: TableIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'table.errorCode'),
  }),
  withLoadBefore({
    table: {
      selectedKey: SELECTED_TABLE_KEY,
      actionKey: SELECTED_TABLE_KEY,
      request: getTableNetworkRequest,
      mapPayloadFromProps: props => props[TABLE_ID_PATH_PARAM],
      shouldCall: props => props[TABLE_ID_PATH_PARAM] !== undefined,
    },
    tableColumns: {
      selectedKey: SELECTED_TABLE_COLUMNS_KEY,
      actionKey: SELECTED_TABLE_COLUMNS_KEY,
      request: getAllTableColumnsNetworkRequest,
      mapPayloadFromProps: props => ({
        tableId: props[TABLE_ID_PATH_PARAM],
        ignoreEventNameColumn: false,
      }),
      shouldCall: props => props[TABLE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default TableViewPanel;

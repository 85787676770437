import {
  ExperimentsCounts,
  ExperimentStatus,
  ExperimentType,
} from '../../../../../../objects/models/experiment.model';
import {SearchInput, SwitchActions, TeamSelector} from 'ui-components';
import {FlexHorizontal} from '../../../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import classes from './experiments-list-filters.module.scss';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ExperimentFiltersDTO} from '../../../../../../objects/dto/experiment-filters.dto';
import {Divider} from '@material-ui/core';
import {useThrottle} from '../../../../../../core/hooks/use-throttle.hook.ts';

type ExperimentsListFiltersProps = {
  filters: ExperimentFiltersDTO;
  onChangeFilter: (newFilters: ExperimentFiltersDTO) => void;
  onSort: (key: string) => void;
  counts: ExperimentsCounts;
  disabled?: boolean;
};

// const sortLabels: Record<ExperimentFiltersDTO['orderBy'], string> = {
//   status: 'Status',
//   startDate: 'Start Date',
// };

export const ExperimentsListFilters = (props: ExperimentsListFiltersProps) => {
  const {counts, filters, onChangeFilter, disabled} = props;
  const {actualTeams: teams} = useProductData();
  const [searchValue, setSearchValue] = useState(filters.q || '');
  const {status, teamId, type} = filters;
  const onChangeFilterRef = useRef<any>(onChangeFilter);
  onChangeFilterRef.current = onChangeFilter;

  const changeSearchInFilters = useCallback(
    value => onChangeFilterRef.current({q: value}),
    [onChangeFilterRef]
  );
  const subject = useThrottle<string>(changeSearchInFilters);
  const handleChangeFilter = useCallback(
    (filters: Partial<ExperimentFiltersDTO>) => onChangeFilter(filters),
    [onChangeFilter]
  );
  useEffect(() => {
    subject.next(searchValue);
  }, [subject, searchValue]);

  return (
    <FlexHorizontal fullWidth spaceBetween>
      <div className={classes.FiltersContainer}>
        {teams.length > 0 && (
          <TeamSelector
            disabled={disabled}
            teams={teams}
            value={teamId}
            onChange={(teamId: number) => handleChangeFilter({teamId})}
            clearable
            size="small"
            withPrefix
            hideButtonLabel
          />
        )}
        <SwitchActions
          label="Status"
          disabled={disabled}
          showActionsLabel
          actions={[
            {
              label: 'All',
              count: counts.status.all ?? 0,
              onClick: _ => handleChangeFilter({status: null}),
              isActive: !status,
            },
            {
              label: 'Running',
              count: counts.status[ExperimentStatus.IN_PROGRESS] ?? 0,
              isActive: status === ExperimentStatus.IN_PROGRESS,
              onClick: _ => handleChangeFilter({status: ExperimentStatus.IN_PROGRESS}),
            },
            {
              label: 'Completed',
              count: counts.status[ExperimentStatus.DONE] ?? 0,
              isActive: status === ExperimentStatus.DONE,
              onClick: _ => handleChangeFilter({status: ExperimentStatus.DONE}),
            },
          ]}
        />
        <Divider orientation="vertical" className={classes.Divider} />
        <SwitchActions
          label="Type"
          disabled={disabled}
          showActionsLabel
          actions={[
            {
              label: 'All',
              count: counts.type.all ?? 0,
              onClick: _ => handleChangeFilter({type: null}),
              isActive: !type,
            },
            {
              label: 'A/B Test',
              count: counts.type[ExperimentType.AB] ?? 0,
              onClick: _ => handleChangeFilter({type: ExperimentType.AB}),
              isActive: type === ExperimentType.AB,
            },
            {
              label: 'Release Analysis',
              count: counts.type[ExperimentType.PRE_POST] ?? 0,
              onClick: _ => handleChangeFilter({type: ExperimentType.PRE_POST}),
              isActive: type === ExperimentType.PRE_POST,
            },
          ]}
        />
        {/* Hide until design is finalized */}
        {/* <Divider orientation={'vertical'} className={classes.Divider} /> */}
        {/* <ActionsDropdown */}
        {/*   disabled={disabled} */}
        {/*   icon={ArrowDownArrowUpIcon} */}
        {/*   label={ */}
        {/*     <div className={classes.SortLabel}> */}
        {/*       <span className={classes.Label}>Sort By:</span> */}
        {/*       <span className={classes.OrderBy}>{sortLabels[orderBy]}</span> */}
        {/*     </div> */}
        {/*   } */}
        {/*   actions={[ */}
        {/*     {title: 'Status', onClick: () => onSort('status')}, */}
        {/*     {title: 'Start Date', onClick: () => onSort('startDate')}, */}
        {/*   ]} */}
        {/* /> */}
      </div>
      <div>
        <SearchInput
          placeholder={'Search'}
          value={searchValue}
          onChange={setSearchValue}
          className={classes.SearchInput}
        />
      </div>
    </FlexHorizontal>
  );
};

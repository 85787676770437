import {NRC} from './index';
import {RequestType, toCamelCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import TransKeys from 'translations';
import i18n from '../config/i18n.config';
import {camelCase} from 'camel-case';

const multiResponseToSmartSelector = res => {
  const response = toCamelCase(res.data, ['signal_definition']);
  for (const key in res.data) {
    const ccKey = camelCase(key);
    response[key] = {
      data: response[ccKey]['data'],
      ...response[ccKey]['meta'],
    };
  }
  return response;
};

const signalSmartSelectorTransformer = res => {
  const response = multiResponseToSmartSelector(res);
  for (const key in response) {
    switch (key) {
      case 'funnels':
        response[key].data = response[key].data.map(f => ({
          ...f,
          steps: f.steps.map((s, idx) => ({
            ...s,
            chip: `step ${idx + 1}`,
          })),
        }));
        break;
      case 'features':
      case 'contents':
        response[key].data = response[key].data.map(f => {
          const children = [];
          f.usageSignal &&
            children.push({
              ...f.usageSignal,
              overrideName: i18n.t(TransKeys.GENERAL.LABELS.USAGE),
            });
          f.exposureSignal &&
            children.push({
              ...f.exposureSignal,
              overrideName: i18n.t(TransKeys.GENERAL.LABELS.EXPOSURE),
            });
          f.intentSignal &&
            children.push({
              ...f.intentSignal,
              overrideName: i18n.t(TransKeys.GENERAL.LABELS.INTENT),
            });
          f.activationSignal &&
            children.push({
              ...f.activationSignal,
              overrideName: i18n.t(TransKeys.GENERAL.LABELS.ACTIVATION),
            });
          f.churnSignal &&
            children.push({
              ...f.churnSignal,
              overrideName: i18n.t(TransKeys.GENERAL.LABELS.CHURN),
            });
          return {
            id: f.id,
            name: f.name,
            description: f.description,
            children,
          };
        });
        break;
    }
  }
  return response;
};

const singleResponseToSmartSelector = res => {
  const response = toCamelCase(res.data, ['signal_definition']);
  return {
    data: response['data'],
    ...response['meta'],
  };
};

export const signalSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/smart-selector/signals`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: signalSmartSelectorTransformer,
});

export const tableEventSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-events`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const tableColumnSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/table-columns`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const tableSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tables`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const sourceTablesSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/source-tables`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const sourceTableColumnsSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/source-table-columns`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const funnelSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/funnels`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const featureSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/features`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const contentSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/contents`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const metricSeriesSmartSelectorNetworkRequest: NRC<void, any> = payload => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-series`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const segmentCategoriesSmartSelectorNetworkRequest: NRC<void, any> = payload => ({
  method: RequestType.GET,
  relativeUrl: `/app/segment-categories`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => res.data,
});

export const metricCategoriesSmartSelectorNetworkRequest: NRC<void, any> = payload => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-categories`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => res.data,
});

export const tagSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/tags/smart-selector`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: multiResponseToSmartSelector,
});

export const metricSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metrics`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

export const segmentSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/segments`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

// Trees
export const treatmentSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/smart-selector/treatments`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const confoundersSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/smart-selector/confounders`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const segmentCategoriesForAnalysisSmartSelectorNetworkRequest: NRC<void, any> = (
  payload: any
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/smart-selector/segment-categories`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const userSmartSelectorNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/users`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: singleResponseToSmartSelector,
});

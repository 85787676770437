import {Epic} from 'redux-observable';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {createRequestEpic} from 'front-core';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {HomepageActionType} from './homepage.actions';
import {
  addModelToHomepageNetworkRequest,
  createHomepageSubscriptionNetworkRequest,
  deleteHomepageSubscriptionNetworkRequest,
  removeModelFromHomepageNetworkRequest,
  reorderHomepageNetworkRequest,
  sendHomepageSubscriptionTestNetworkRequest,
  updateHomepageSubscriptionNetworkRequest,
} from '../../http/homepage.network-requests';
import {ModelKey} from '../../constants/model-key';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {homepageSubscriptionToastCreator} from '../toasts.actions';

export const addMetricToHomepageEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.ADD_MODEL_TO_HOMEPAGE],
    actionKey: ActionKey.ADD_MODEL_TO_HOMEPAGE,
    request: addModelToHomepageNetworkRequest,
    onSuccess: (_, data) => [
      modelCreated(data, ModelKey.HOMEPAGE),
      notifyEvent(AmplitudeEvent.ADD_MODEL_TO_HOMEPAGE, data),
    ],
    onError: err_ => [showToastMessage('Error adding KPI to homepage', ToastType.ERROR)],
  },
  HttpClient
);

export const removeMetricFromHomepageEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.REMOVE_MODEL_FROM_HOMEPAGE],
    actionKey: ActionKey.REMOVE_MODEL_FROM_HOMEPAGE,
    request: removeModelFromHomepageNetworkRequest,
    onSuccess: (_, data) => [
      modelDeleted(data, ModelKey.HOMEPAGE),
      notifyEvent(AmplitudeEvent.REMOVE_MODEL_FROM_HOMEPAGE, data),
    ],
    onError: err_ => [showToastMessage('Error adding KPI to homepage', ToastType.ERROR)],
  },
  HttpClient
);

export const updateHomepageSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.UPDATE_HOMEPAGE_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_HOMEPAGE_SUBSCRIPTION,
    request: updateHomepageSubscriptionNetworkRequest,
    onSuccess: configuration => [
      homepageSubscriptionToastCreator('UPDATE_SUCCESS'),
      modelUpdated(configuration, ModelKey.HOMEPAGE_SUBCRIPTION),
      notifyEvent(AmplitudeEvent.UPDATE_HOMEPAGE_SUBSCRIPTION, configuration),
    ],
    onError: err_ => [homepageSubscriptionToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const createHomepageSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.CREATE_HOMEPAGE_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_HOMEPAGE_SUBSCRIPTION,
    request: createHomepageSubscriptionNetworkRequest,
    onSuccess: configuration => [
      homepageSubscriptionToastCreator('CREATE_SUCCESS'),
      modelCreated(configuration, ModelKey.HOMEPAGE_SUBCRIPTION),
      notifyEvent(AmplitudeEvent.CREATE_HOMEPAGE_SUBSCRIPTION, configuration),
    ],
    onError: err_ => [homepageSubscriptionToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const deleteHomepageSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.DELETE_HOMEPAGE_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_HOMEPAGE_SUBSCRIPTION,
    request: deleteHomepageSubscriptionNetworkRequest,
    onSuccess: configuration => [
      homepageSubscriptionToastCreator('DELETE_SUCCESS'),
      modelDeleted(configuration, ModelKey.HOMEPAGE_SUBCRIPTION),
      notifyEvent(AmplitudeEvent.DELETE_HOMEPAGE_SUBSCRIPTION, configuration),
    ],
    onError: err_ => [homepageSubscriptionToastCreator('DELETE_ERROR')],
  },
  HttpClient
);
export const reorderHomepageMetricsEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.REORDER_HOMEPAGE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REORDER_HOMEPAGE,
    request: reorderHomepageNetworkRequest,
    onSuccess: () => [notifyEvent(AmplitudeEvent.REORDER_HOMEPAGE)],
    onError: err_ => [showToastMessage('Error while updating homepage', ToastType.ERROR)],
  },
  HttpClient
);

export const testHomepageSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.TEST_HOMEPAGE_SUBSCRIPTION],
    actionKey: ActionKey.TEST_HOMEPAGE_SUBSCRIPTION,
    request: sendHomepageSubscriptionTestNetworkRequest,
    onSuccess: () => [showToastMessage('Subscription test manager created', ToastType.SUCCESS)],
    onError: () => [
      showToastMessage('Error while trying to create subscription test manager', ToastType.ERROR),
    ],
  },
  HttpClient
);

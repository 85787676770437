import {InterestingXoXSentence, NotSignificantChangeKPI} from '../rca-insights.model.ts';
import {
  SegmentAnalyzedPeriodMeasuredValueSentenceParts,
  SentenceContainer,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends InterestingXoXSentence {
  formattedSentenceParts: NotSignificantChangeKPI;
}
type AllProps = OwnProps;

const NotSignificantChangeKPIFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;

  return (
    <SentenceContainer>
      <SegmentAnalyzedPeriodMeasuredValueSentenceParts
        segmentViewData={sentenceParts.segmentViewData}
        conjugatedPerformance={formattedSentenceParts.presentTensePerformance}
        changeInValue={sentenceParts.changeInValue}
        higherIsBetter={sentenceParts.higherIsBetter}
        segmentValue={sentenceParts.value}
        segmentExpectedValue={sentenceParts.expectedValue}
      />
    </SentenceContainer>
  );
};

export {NotSignificantChangeKPIFormatter};

import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './funnel-segmentation-tables.module.scss';
import {
  AnalysisAlgoMode,
  DocumentElementType,
  FunnelSegmentationFigureOptions,
  FunnelSegmentationItemType,
  FunnelSegmentationStep,
} from '../../../../../../types';
import {exists, getNextSort, sortData, Sorting, withStopPropagation} from 'front-core';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {SimulatorItemType, useSimulator} from '../../../../../../hooks/use-simulator.hook';
import {flatten, get, groupBy, max, range} from 'lodash';
import {FlexibleTable} from '../../../../../shared/general/flexible-table/flexible-table.component';
import TransKeys from 'translations';
import {VersusDisplay} from '../../../../../shared/display-columns/versus-display.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import pluralize from 'pluralize';
import {SegmentDisplay} from '../../../../../shared/display-columns/segment-display.component';
import {ExtendedFunnelSegmentationItem} from '../../funnel-segmentation-viewer.component';
import {
  ArrowRightLongLightIcon,
  RocketLaunchLightIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {Tooltip} from '@material-ui/core';
import {PercentageAbsDisplay} from '../../../../../shared/display-columns/percentage-abs-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {PlaceholderTextDisplay} from '../../../../../shared/display-columns/placeholder-text-display.component';
import {useDocQuery} from '../../../../../../hooks/use-doc-query.hook';
import {AppTabs} from '../../../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {
  DocumentMessage,
  DocumentMessageType,
} from '../../../../../shared/general/document-message/document-message.component';
import {IconButton} from '../../../../../../../../simple/controls/icon-button/icon-button.component';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {createPortal} from 'react-dom';

interface OwnProps {
  figureId: string;
  options: FunnelSegmentationFigureOptions;
  steps: FunnelSegmentationStep[];
  items: ExtendedFunnelSegmentationItem[];
  showSimulationControls?: boolean;
  selectedStepIndex: number;
  goalValue: number;
  onFollowUp: (goal: number, refDate: number, segment: number, segmentClass: string) => void;
  mode: AnalysisAlgoMode;
  className?: string;
  simulationFilterControlsRef: React.RefObject<HTMLElement>;
}

type AllProps = OwnProps;

const DEFAULT_SORT: Sorting = {
  order: 'asc',
  orderBy: 'maxPotentialRank',
};
const OTHER_TAB_SORT: Sorting = {
  order: 'desc',
  orderBy: 'goalInSegment',
};
const PRIMARY_SORT: Sorting = {
  order: 'desc',
  orderBy: 'isInformative',
};

const OTHER_SORT_OPTIONS = [
  {label: 'Max Potential', value: 'maxPotentialRank'},
  {label: 'PP Uplift', value: 'ppUpliftRank'},
  {label: 'PCT Uplift', value: 'pctUpliftRank'},
];

const TYPE_MAPPING = {
  [FunnelSegmentationItemType.POSITIVE]: FunnelSegmentationItemType.POSITIVE,
  [FunnelSegmentationItemType.NO_RECOMMENDATION]: FunnelSegmentationItemType.NO_RECOMMENDATION,
  [FunnelSegmentationItemType.INSIGNIFICANT_POSITIVE]: FunnelSegmentationItemType.INSIGNIFICANT,
};

export const FunnelSegmentationSimulatorMode: React.FC<AllProps> = React.memo((props: AllProps) => {
  const {
    goalValue,
    showSimulationControls,
    items: itemsFromProps,
    steps,
    selectedStepIndex,
    options,
    figureId,
    onFollowUp,
    mode,
    simulationFilterControlsRef,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {trackInput} = useDocumentTracking(
    figureId,
    DocumentElementType.FUNNEL_SEGMENTATION_FIGURE
  );
  const [showOnlyInteresting, setShowOnlyInteresting] = useState(false);
  const segmentPerStep = useMemo(
    () =>
      flatten(
        itemsFromProps.map(s => {
          const res = [];
          const segment = {...s};
          if (mode === 'causation') {
            segment.potentialLift = segment.potentialLiftCausation;
            segment.potentialLiftUpper = segment.potentialLiftUpperCausation;
            segment.potentialLiftLower = segment.potentialLiftLowerCausation;
            segment.type = segment.typeCausation;
            segment.helperText = segment.helperTextCausation;
            segment.helperTextFunnelCompletion = segment.helperTextFunnelCompletionCausation;
            segment.funnelCompletionType = segment.funnelCompletionTypeCausation;
          }
          for (const stepIdx of range(steps.length - 1)) {
            const type = segment.type[stepIdx];
            res.push({
              ...segment,
              key: `${s.key}_${stepIdx}`,
              simulationType: SimulatorItemType.POSITIVE,
              potentialLift: segment.potentialLift[stepIdx],
              potentialLiftUpper: get(segment.potentialLiftUpper, stepIdx),
              potentialLiftLower: get(segment.potentialLiftLower, stepIdx),
              maxPotentialRank: segment.maxPotentialRank?.[stepIdx],
              ppUpliftRank: segment.ppUpliftRank?.[stepIdx],
              pctUpliftRank: segment.pctUpliftRank?.[stepIdx],
              value: segment.segmentConversions[stepIdx],
              goalInSegment: segment.segmentConversions[stepIdx],
              goalInNonSegment: segment.nonSegmentConversions[stepIdx],
              type_: TYPE_MAPPING[type],
              count: segment.countInSegment[0],
              startStepIndex: stepIdx,
              endStepIndex: stepIdx + 1,
              isInterestingOpportunity: segment.isInterestingOpportunity?.[stepIdx],
              goal: steps[stepIdx + 1].signalId,
              refDate: steps[stepIdx].signalId,
              segment: segment.signalId[0],
              segmentClass: segment.segmentName[0],
              helperText: segment.helperText[stepIdx],
              info: segment.info ? segment.info[stepIdx] : '',
            });
          }
          return res;
        })
      )
        .filter(s => {
          return exists(selectedStepIndex) ? s.startStepIndex === selectedStepIndex : true;
        })
        .filter(s => (showOnlyInteresting ? s.isInterestingOpportunity === true : s)),
    [itemsFromProps, mode, steps, selectedStepIndex, showOnlyInteresting]
  );
  const itemsGroupByType = useMemo(() => groupBy(segmentPerStep, 'type_'), [segmentPerStep]);
  const defaultSelectedTab = useMemo(
    () =>
      [
        FunnelSegmentationItemType.POSITIVE,
        FunnelSegmentationItemType.INSIGNIFICANT,
        FunnelSegmentationItemType.NO_RECOMMENDATION,
      ].find(v => itemsGroupByType[v] && itemsGroupByType[v].length > 0),
    [itemsGroupByType]
  );
  const {query: selectedType, setQuery: setSelectedType} = useDocQuery<FunnelSegmentationItemType>(
    figureId,
    defaultSelectedTab,
    'type'
  );
  const [sorting, setSorting] = useState<Sorting>(
    selectedType === FunnelSegmentationItemType.POSITIVE ? DEFAULT_SORT : OTHER_TAB_SORT
  );
  const {items, onItemChangeValue} = useSimulator({
    items: itemsGroupByType[selectedType] || [],
    goalValue: goalValue,
  });
  const sortedItems = useMemo(() => {
    return sortData(items, sorting, PRIMARY_SORT);
  }, [items, sorting]);
  const kpiMaxPotential = useMemo(() => max(items.map(i => i.maxPotential)), [items]);
  const onDragComplete = useCallback(
    (controlType: 'master' | 'conversion', value) =>
      trackInput(controlType, {value, inputType: 'slider'}),
    [trackInput]
  );
  const tabs = useMemo(
    () =>
      [
        FunnelSegmentationItemType.POSITIVE,
        FunnelSegmentationItemType.INSIGNIFICANT,
        FunnelSegmentationItemType.NO_RECOMMENDATION,
      ].map(type => ({
        key: type,
        label: `${t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABS[type.toUpperCase()]
        )} (${itemsGroupByType[type]?.length || 0})`,
        disabled: !exists(itemsGroupByType[type]) || itemsGroupByType[type].length === 0,
      })),
    [itemsGroupByType, t]
  );
  const onSort = useCallback(
    (key: string) => {
      const specialSortColumns = OTHER_SORT_OPTIONS.map(s => s.value);
      if (specialSortColumns.includes(key)) {
        setSorting({order: 'asc', orderBy: key});
      } else {
        setSorting(getNextSort(key, sorting));
      }
    },
    [sorting]
  );
  const columns = useMemo(
    () =>
      [
        {
          key: ['segmentGroupName', 'segmentName'],
          title: [
            t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME.LABEL),
            t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT.LABEL),
          ],
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT.HELPER_TEXT
          ),
          sortable: true,
          weight: 2,
          render: item => (
            <SegmentDisplay
              onClick={() => onSignalClick(item.signalId[0])}
              group={item.segmentGroupName[0]}
              segment={item.segmentName[0]}
              helperText={item.helperText}
            />
          ),
        },
        {
          key: 'startStepIndex',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.FUNNEL_PART.LABEL
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.FUNNEL_PART
              .HELPER_TEXT
          ),
          weight: 0.8,
          sortable: true,
          render: item => {
            return (
              <div className={classes.FunnelPart}>
                <Tooltip title={steps[item.startStepIndex].name} placement={'top'}>
                  <span
                    onClick={() => onSignalClick(steps[item.startStepIndex].signalId)}
                    className={classes.Step}
                  >
                    {item.startStepIndex + 1}
                  </span>
                </Tooltip>
                <ArrowRightLongLightIcon className={classes.Arrow} />
                <Tooltip title={steps[item.endStepIndex].name} placement={'top'}>
                  <span
                    onClick={() => onSignalClick(steps[item.endStepIndex].signalId)}
                    className={classes.Step}
                  >
                    {item.endStepIndex + 1}
                  </span>
                </Tooltip>
              </div>
            );
          },
        },
        {
          key: 'share',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X.LABEL,
            {
              entity: pluralize(options.entity),
            }
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X
              .HELPER_TEXT,
            {
              entity: pluralize(options.entity),
            }
          ),
          weight: 1.2,
          sortable: true,
          render: item => (
            <PercentageAbsDisplay
              percentage={item.share}
              abs={item.count}
              unit={pluralize(options.entity)}
            />
          ),
        },
        {
          key: 'goalInSegment',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT.LABEL
          ),
          weight: 1,
          sortable: true,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT
              .HELPER_TEXT
          ),
          render: item => (
            <VersusDisplay
              key={item.key}
              value={item.goalInSegment}
              vsValue={item.goalInNonSegment}
              isPercentageValue={true}
              vsLabel={t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_NON_SEGMENT
                  .LABEL
              )}
            />
          ),
        },
        {
          key: 'change',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS
              .TARGET_CONVERSION_RATE.LABEL,
            {entity: options.entity}
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS
              .TARGET_CONVERSION_RATE.HELPER_TEXT,
            {entity: options.entity}
          ),
          types: [FunnelSegmentationItemType.POSITIVE],
          weight: 2,
          render: item => {
            if (exists(item.maxChange) && item.maxChange > 0) {
              return (
                <SimulatorSlider
                  min={0}
                  max={1000}
                  displayValue={item.newValue * 100}
                  value={item.change * 100}
                  defaultValue={item.defaultChange * 100}
                  onChange={v => onItemChangeValue(item.key, exists(v) ? v / 100 : null)}
                  onChangeCommitted={v => onDragComplete('conversion', exists(v) ? v / 100 : null)}
                  allowedMax={item.maxChange * 100}
                  suffix={'%'}
                  sign={'+'}
                  allowedMaxHelper={t(
                    TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.SLIDER_MAX_HELPER
                  )}
                />
              );
            }
            return (
              <PlaceholderTextDisplay>
                {t(
                  TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE
                    .TARGET_CONVERSION_RATE_0_OR_100
                )}
              </PlaceholderTextDisplay>
            );
          },
        },
        {
          key: 'maxPotential',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI.LABEL
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI
              .HELPER_TEXT
          ),
          weight: 1.5,
          sortable: true,
          types: [FunnelSegmentationItemType.POSITIVE, FunnelSegmentationItemType.INSIGNIFICANT],
          render: item => {
            const isInsignificant = selectedType === FunnelSegmentationItemType.INSIGNIFICANT;
            return (
              <GradientLabelDisplay
                value={item.potential * 100}
                min={goalValue * 100}
                max={kpiMaxPotential * 100}
                tooltipLabel={t(
                  TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.LABELS
                    .CONFIDENCE_INTERVAL
                )}
                valueUpper={item.potentialUpper * 100}
                valueLower={item.potentialLower * 100}
                mode={isInsignificant ? 'range' : 'default'}
                suffix={'%'}
                showDiff
              />
            );
          },
        },
        {
          key: 'message',
          title: t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO.LABEL
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO.HELPER_TEXT
          ),
          weight: 3,
          types: [FunnelSegmentationItemType.NO_RECOMMENDATION],
          render: item => item.info,
        },
        {
          key: 'actions',
          title: '',
          width: '6.6rem',
          types: [FunnelSegmentationItemType.POSITIVE],
          render: item => (
            <IconButton
              size={'large'}
              tooltipText={t(
                TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.ACTIONS.HOT_TO_IMPROVE
              )}
              onClick={withStopPropagation(() =>
                onFollowUp(item.goal, item.refDate, item.segment, item.segmentClass)
              )}
              icon={RocketLaunchLightIcon}
            />
          ),
        },
      ].filter(c => (c.types ? c.types.indexOf(selectedType) > -1 : true)),
    [
      t,
      options,
      onSignalClick,
      steps,
      onItemChangeValue,
      onDragComplete,
      selectedType,
      goalValue,
      kpiMaxPotential,
      onFollowUp,
    ]
  );
  const instructions = t(
    TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.INSTRUCTIONS[selectedType?.toUpperCase()]
  );

  return (
    <>
      <AppTabs
        className={classes.Tabs}
        tabs={tabs}
        selected={selectedType}
        onChange={tab => setSelectedType(tab as any)}
        fullWidth
      />
      {instructions && <div className={classes.Instructions}>{instructions}</div>}
      {simulationFilterControlsRef.current &&
        createPortal(
          showSimulationControls && (
            <>
              <Select
                value={sorting.orderBy}
                prefix="Sort by"
                onChange={onSort}
                options={{options: OTHER_SORT_OPTIONS}}
                clearable={false}
                searchable={false}
                sortValues={false}
              />
              <Checkbox
                onChange={(newValue: boolean) => setShowOnlyInteresting(newValue)}
                label="Show only interesting"
                checked={showOnlyInteresting}
                border
              />
            </>
          ),
          simulationFilterControlsRef.current
        )}
      <FlexibleTable
        // this key is used to re-trigger initial sort of FlexibleTable
        key={items.length}
        className={classNames(classes.FunnelSegmentationSimulatorMode, className)}
        rowClassName={classes.SimulatorRow}
        columns={columns}
        data={sortedItems}
        onSort={onSort}
        sorting={sorting}
        afterHeadersRenderer={() =>
          selectedType === FunnelSegmentationItemType.INSIGNIFICANT &&
          mode === AnalysisAlgoMode.CAUSATION ? (
            <DocumentMessage
              text={t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.LABELS.INSIGNIFICANT)}
              type={DocumentMessageType.WARN}
            />
          ) : null
        }
      />
    </>
  );
});

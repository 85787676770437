import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {createModelEventPropertyModel} from './event-properties-fields-utils';
import {TableColumn} from '../../../../objects/models/table-column.model';
import classes from './event-properties-fields-selector.module.scss';
import {IconButton, TrashCanLightIcon} from 'ui-components';
import classNames from 'classnames';
import {TemplateItemQueryBuilder} from '../../../shared/core/query-builders/template-item-query-builder/template-item-query-builder.component';
import {SelectorModelType} from '../../../shared/core/smart-selector/advanced-smart-selector.component';
import {exists} from 'front-core';

interface OwnProps {
  className?: string;
  disabledHelperText?: string;
  eventsTableId?: number;
}

type AllProps = OwnProps;

const ALLOWED_SELECTOR_MODEL_TYPES = [SelectorModelType.COLUMN];

export const EventPropertiesSelectorFields = (props: AllProps) => {
  const {eventsTableId, disabledHelperText, className} = props;
  const {control, setValue} = useFormContext();
  const {
    fields: modelEventPropertiesData,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'eventProperties',
    keyName: 'key',
  });
  const prevEventsTableId = useRef<number | undefined>(eventsTableId);
  const disabled = useMemo(() => !exists(eventsTableId), [eventsTableId]);
  const columnFilters = useMemo(
    () => ({
      tableId: eventsTableId,
    }),
    [eventsTableId]
  );
  const onAdd = useCallback(
    property => {
      const newValue = createModelEventPropertyModel(property);
      append(newValue);
    },
    [append]
  );
  const onChange = useCallback(
    (property: any, fieldName: string, id?: number) => {
      const updateValue = createModelEventPropertyModel(property, id);
      setValue(fieldName, updateValue);
    },
    [setValue]
  );

  useEffect(() => {
    if (eventsTableId && prevEventsTableId.current && prevEventsTableId.current !== eventsTableId) {
      prevEventsTableId.current = eventsTableId;
      setValue('eventProperties', []);
    }
  }, [eventsTableId, setValue]);

  const renderEmptyModelPropertySelect = () => (
    <div className={classes.ModelEventPropertiesSelectorField}>
      <TemplateItemQueryBuilder
        query={null}
        helperText={disabledHelperText}
        disabled={disabled}
        onChange={item => onAdd(item as TableColumn)}
        allowTypes={ALLOWED_SELECTOR_MODEL_TYPES}
        columnFilters={columnFilters}
      />
    </div>
  );

  return (
    <div className={classNames(classes.ModelEventPropertiesSelectorFieldsContainer, className)}>
      {modelEventPropertiesData.length === 0 && renderEmptyModelPropertySelect()}
      {modelEventPropertiesData.length > 0 &&
        // Render mapped fields when modelEventPropertiesData has items
        modelEventPropertiesData.map((field, index) => {
          const isLastField = index === modelEventPropertiesData.length - 1;
          const fieldName = `eventProperties[${index}]`;
          return (
            <>
              <div
                key={`model-event-property-data-field-${field.key}`}
                className={classes.ModelEventPropertiesSelectorField}
              >
                <Controller
                  name={fieldName}
                  control={control}
                  render={({field}) => (
                    <TemplateItemQueryBuilder
                      className={classes.PropertySelector}
                      query={field.value.definition}
                      disabled={disabled}
                      onChange={definition => onChange(definition, fieldName, field.value.id)}
                      allowTypes={ALLOWED_SELECTOR_MODEL_TYPES}
                      columnFilters={columnFilters}
                    />
                  )}
                />
                <IconButton
                  border={false}
                  size={'small'}
                  icon={TrashCanLightIcon}
                  onClick={() => remove(index)}
                  disabled={disabled}
                />
              </div>
              {isLastField && renderEmptyModelPropertySelect()}
            </>
          );
        })}
    </div>
  );
};

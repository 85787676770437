import * as React from 'react';
import {useContext, useMemo} from 'react';
import classNames from 'classnames';
import {
  RCAEntityLevelExplainerFigure,
  RCAEntityLevelExplainerFigureAttribute,
  RCAEntityLevelExplainerFigureItem,
  SignalDataType,
} from '../../../../types';
import classes from './rca-entity-level-explainer-viewer.module.scss';
import {
  FlexibleTable,
  FlexibleTableClasses,
  FlexibleTableColumn,
} from '../../../shared/general/flexible-table/flexible-table.component';
import {calculateUplift, number2k} from 'front-core';
import moment from 'moment';
import {TooltipIfOverflow} from '../../../../../../simple/generic/tooltips/tooltips.component';
import {Button} from '../../../../../../simple/controls/button/button.component';
import {ArrowRightLightIcon} from '../../../../../../simple/controls/icons/icons.component';
import {keyBy} from 'lodash';
import {ImpactPPDisplay} from '../../../shared/display-columns/impact-pp-display.component';
import {ExplainabilityLabelDisplay} from '../../../../../../simple/data-display/explainability-label-display/explainability-label-display.component';
import {VersusWithUpliftDisplay} from '../../../shared/display-columns/versus-with-uplift-display.component';
import {useRCAVsLabels} from '../rca-figure/hooks/use-rca-vs-labels.hook';
import {EntityLevelExplainerModal} from './components/entity-level-explainer-modal/entity-level-explainer-modal.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';

interface Props extends RCAEntityLevelExplainerFigure {
  className?: string;
}

interface AttributeValueRendererProps {
  attribute: RCAEntityLevelExplainerFigureAttribute;
  item: any;
  bold?: boolean;
}

const AttributeValueRenderer = (props: AttributeValueRendererProps) => {
  const {attribute, item, bold = false} = props;
  const renderValue = useMemo(() => {
    const value = item[attribute.key];
    if (
      attribute.valueType === SignalDataType.DECIMAL ||
      attribute.valueType === SignalDataType.INTEGER
    ) {
      return attribute.isPercentageValue ? `${number2k(value * 100)}%` : number2k(value);
    }
    if (attribute.valueType === SignalDataType.TIMESTAMP) {
      return moment.utc(value).format('YYYY-MM-DD');
    }
    if (attribute.valueType === SignalDataType.BOOLEAN) {
      return value ? 'Yes' : 'No';
    }
    return value;
  }, [item, attribute]);

  return (
    <TooltipIfOverflow title={renderValue}>
      <span className={classNames(classes.AttributeRenderer, bold && classes.Bold)}>
        {renderValue}
      </span>
    </TooltipIfOverflow>
  );
};

const TransPrefix = TransKeys.DOCUMENT_VIEWER.RCA_ENTITY_EXPLAINER;

export const RCAEntityLevelExplainerViewer: React.FC<Props> = (props: Props) => {
  const {data, options, className} = props;
  const {items, attributes, populationTrend} = data;
  const vsLabels = useRCAVsLabels(options.entity);
  const {t} = useDocumentTranslation();

  const [selectedItem, setSelectedItem] = React.useState<RCAEntityLevelExplainerFigureItem>(null);
  const itemsById = useMemo(() => keyBy(items, 'id'), [items]);
  const tableData = useMemo(() => {
    return items.map(i => ({
      key: i.id.toString(),
      ...i,
      ...i.attributeValues,
      uplift: calculateUplift(i.value, i.expectedValue),
    }));
  }, [items]);
  const columns: FlexibleTableColumn<any>[] = useMemo(
    () => [
      {
        key: 'id',
        title: `${options.entity} ID`,
        width: '12rem',
        render: (item: any) => (
          <TooltipIfOverflow title={item.id}>
            <span className={classes.AttributeRenderer}>{item.id}</span>
          </TooltipIfOverflow>
        ),
      },
      ...attributes.map((a, idx) => ({
        key: a.key,
        weight: 1,
        title: a.name,
        render: (item: any) => (
          <AttributeValueRenderer attribute={a} item={item} bold={idx === 0} />
        ),
      })),
      {
        key: 'value',
        width: '16rem',
        title: t(TransPrefix.TABLE.HEADERS.KPI_VALUE.TITLE),
        helperText: t(TransPrefix.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT),
        render: (item: any) => (
          <VersusWithUpliftDisplay
            value={item.value}
            vsValue={item.expectedValue}
            isPercentageValue={options.isPercentageValue}
            {...vsLabels.value}
          />
        ),
      },
      {
        key: 'explainabilityLabel',
        width: '14rem',
        title: t(TransPrefix.TABLE.HEADERS.EXPLAINABILITY.TITLE),
        helperText: t(TransPrefix.TABLE.HEADERS.EXPLAINABILITY.HELPER_TEXT),
        render: (item: any) => (
          <ExplainabilityLabelDisplay
            label={item.explainabilityLabel}
            isSignificant={item.isSignificant}
            modelName={`this ${options.entity}`}
          />
        ),
      },
      {
        key: 'ctcPp',
        width: '16rem',
        title: t(TransPrefix.TABLE.HEADERS.IMPACT_IN_PP.TITLE),
        helperText: t(TransPrefix.TABLE.HEADERS.IMPACT_IN_PP.HELPER_TEXT),
        render: (item: any) => <ImpactPPDisplay value={item.ctcPp} outOfValue={populationTrend} />,
      },
      {
        key: 'actions',
        align: 'right',
        render: (item: any) => (
          <div className={FlexibleTableClasses.ShowOnHover}>
            <Button onClick={() => setSelectedItem(itemsById[item.id])}>
              Deep dive <ArrowRightLightIcon />
            </Button>
          </div>
        ),
      },
    ],
    [options, attributes, t, vsLabels.value, populationTrend, itemsById]
  );

  return (
    <>
      <FlexibleTable
        data={tableData}
        columns={columns}
        className={className}
        onRowClicked={item => setSelectedItem(itemsById[item.id])}
      />
      {selectedItem && (
        <EntityLevelExplainerModal
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
          title={selectedItem.attributeValues[attributes[0].key]}
          options={options}
        />
      )}
    </>
  );
};

import {TIME_FORMATS} from '../constants/time-formats';
import {DateIcon} from 'ui-components';
import moment from 'moment';
import {exists} from 'front-core';

export const createStartDateComparingProps = (timeGranularity: 'day' | 'week' | 'month') => ({
  dateInputFormat: TIME_FORMATS.PARAMETER_DATE_FORMAT,
  dateFormat: timeGranularity === 'month' ? 'MMM YY' : 'DD/MM/YYYY',
  showMonthYearPicker: timeGranularity === 'month',
  showWeekPicker: timeGranularity === 'week',
  locale: timeGranularity === 'week' ? 'en-GB' : undefined,
  icon: DateIcon,
  fullWidth: false,
  clearable: false,
  utc: true,
});

export const dateToLabel = (value: string, granularity: 'day' | 'week' | 'month') => {
  const valueMoment = moment.utc(value);
  if (!exists(value)) {
    return '';
  }
  if (granularity === 'day') {
    return valueMoment.format(TIME_FORMATS.READABLE_DATE_NO_YEAR);
  }
  if (granularity === 'week') {
    const end = moment(value).add(6, 'days');
    return `${valueMoment.format(TIME_FORMATS.READABLE_DATE_NO_YEAR)} - ${end.format(
      TIME_FORMATS.READABLE_DATE_NO_YEAR
    )}`;
  }
  if (granularity === 'month') {
    return valueMoment.format(TIME_FORMATS.READABLE_MONTH);
  }
};

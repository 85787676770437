import {BaseMessageRendererProps} from '../../message-viewer.types';
import {Markdown} from '../../../markdown/markdown.component';
interface Props extends Pick<BaseMessageRendererProps<string>, 'message'> {
  className?: string;
}

export const MarkdownMessage = (props: Props) => {
  const {message, className} = props;

  return <Markdown message={message.content} className={className} />;
};

import {useCallback, useState} from 'react';
import {SourceWizardHeader} from './components/source-wizard-header/source-wizard-header.component';
import {ConnectionSuccessScreen} from './components/connection-success-screen/connection-success-screen.component';
import {ConnectionFailedScreen} from './components/connection-failed-screen/connection-failed-screen.component';
import {ImagesResource} from '../../../../assets/images';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {SourceWizardPageLayout} from './components/source-wizard-page-layout/source-wizard-page-layout.component';
import {SourceWizard} from './source-wizard.component';

export const SourceWizardPage = () => {
  const {t} = useTranslation();
  const {defaultSource} = useProductData();
  const [showIllustration, setShowIllustation] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessUnsupported, setIsSuccessUnsupported] = useState(false);
  const [error, setError] = useState(null);

  const onHideIllustration = useCallback(() => setShowIllustation(false), [setShowIllustation]);

  const handleConnectionSucceeded = useCallback((unsupportedSource?: boolean) => {
    unsupportedSource ? setIsSuccessUnsupported(true) : setIsSuccess(true);
  }, []);

  const handleConnectionFailed = useCallback((error: Error) => {
    setError(error);
  }, []);

  const resetWizard = useCallback(() => {
    setShowIllustation(true);
    setIsSuccess(false);
    setIsSuccessUnsupported(false);
    setError(null);
  }, [setShowIllustation, setIsSuccess, setIsSuccessUnsupported]);

  if (error) {
    return <ConnectionFailedScreen error={error.data} onBack={resetWizard} />;
  }

  if (isSuccessUnsupported || isSuccess || !!defaultSource?.id) {
    return <ConnectionSuccessScreen unsupportedSource={isSuccessUnsupported} />;
  }

  return (
    <SourceWizardPageLayout>
      <SourceWizardHeader
        illustration={<img src={ImagesResource.app.sourceWizardIllustration} />}
        title={t(TransKeys.SOURCE_WIZARD.WELCOME_VIEW.TITLE)}
        subtitle={t(TransKeys.SOURCE_WIZARD.WELCOME_VIEW.SUBTITLE)}
        showIllustration={showIllustration}
      />
      <SourceWizard
        onSelectDataSource={onHideIllustration}
        onSuccess={handleConnectionSucceeded}
        onError={handleConnectionFailed}
      />
    </SourceWizardPageLayout>
  );
};

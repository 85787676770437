import React, {useContext, useMemo} from 'react';
import classes from './release-impact-features-summary-viewer.module.scss';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {ExperimentContext} from '../../../../contexts/experiment.context';
import {CommandType, ReleaseImpactFeatureSummaryFigure} from '../../../../types';
import classNames from 'classnames';
import {calculateUplift, number2k, safeDivision, withStopPropagation} from 'front-core';
import {ModelType} from '../../../../../../../consts/model-type';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {TooltipIfOverflow} from '../../../../../../simple/generic/tooltips/tooltips.component';
import {
  FlexibleTable,
  FlexibleTableColumn,
} from '../../../shared/general/flexible-table/flexible-table.component';
import {TrendChip} from '../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {range} from 'lodash';
import {ArrowRightLightIcon} from '../../../../../../simple/controls/icons/icons.component';
import TransKeys from 'translations';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';

interface OwnProps extends ReleaseImpactFeatureSummaryFigure {
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_FEATURES_SUMMARY_FIGURE;

export const ReleaseImpactFeaturesSummaryViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    data: {items},
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {metrics} = useContext(ExperimentContext);

  const tableData: any[] = useMemo(() => {
    return items.map(item => {
      const adoptionPre = safeDivision(item.adoptionAbsNaiveControl, item.groupSizeNaiveControl);
      const adoptionPost = safeDivision(item.adoptionAbsPostRelease, item.groupSizePostRelease);
      const adoptionUplift = calculateUplift(adoptionPost, adoptionPre);

      return {
        ...item,
        adoptionPre,
        adoptionPost,
        adoptionUplift,
        viewFeatureCommand: {
          type: CommandType.REF,
          payload: {
            type: ModelType.SIGNAL,
            refId: item.signalId,
          },
        },
      };
    });
  }, [metrics, items]);
  const metricByIndex = useMemo(() => tableData[0].metrics.map(i => i.signalId), [tableData]);

  const tableColumns = useMemo(() => {
    const baseColumns: FlexibleTableColumn<any>[] = [
      {
        key: 'feature',
        title: t(TRANS_KEYS_PREFIX.HEADERS.FEATURE.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.FEATURE.SUB_TITLE),
        weight: 1,
        render: (item: any) => (
          <div className={classes.FeatureName}>
            <TooltipIfOverflow title={item.name}>
              <div
                onClick={
                  item.signalId
                    ? withStopPropagation(() => emitEvent(item.viewFeatureCommand))
                    : undefined
                }
                className={classNames(classes.Text, item.signalId && classes.Clickable)}
              >
                {item.name}
              </div>
            </TooltipIfOverflow>
          </div>
        ),
      },
      {
        key: 'adoption',
        title: t(TRANS_KEYS_PREFIX.HEADERS.ADOPTION.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.ADOPTION.SUB_TITLE),
        weight: 1,
        render: item => (
          <div className={classes.Adoption}>
            <span className={classes.Value}>{number2k(item.adoptionPost * 100)}%</span>
            <TrendChip
              value={item.adoptionUplift * 100}
              size={'small'}
              className={classes.TrendChip}
              inf={item.adoptionPre === 0}
            />
          </div>
        ),
      },
    ];

    range(Math.min(2, metricByIndex.length)).forEach(i => {
      baseColumns.push({
        key: `metric_${i}`,
        title: t(TRANS_KEYS_PREFIX.HEADERS.METRIC.TITLE, {
          name: metrics[metricByIndex[i]].name,
        }),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.METRIC.SUB_TITLE),
        weight: 1,
        render: item => {
          const featureMetric = item.metrics[i];
          const metricData = metrics[featureMetric.signalId];

          return (
            <div className={classes.MetricValue}>
              <span className={classes.Value}>
                {number2k(
                  metricData.isPercentageValue
                    ? featureMetric.goalInAdoptersPost * 100
                    : featureMetric.goalInAdoptersPost
                )}
                {metricData.isPercentageValue ? '%' : ''}
              </span>
              <span className={classes.VS}>vs.</span>
              <span className={classes.SecondaryValue}>
                {number2k(
                  metricData.isPercentageValue
                    ? featureMetric.goalInNonAdoptersPost * 100
                    : featureMetric.goalInNonAdoptersPost
                )}
                {metricData.isPercentageValue ? '%' : ''}
              </span>
            </div>
          );
        },
      });
    });

    baseColumns.push({
      key: 'actions',
      title: '',
      width: '16rem',
      render: item => (
        <div className={classes.DeepDive} onClick={() => emitEvent(item.command)}>
          <span>{t(TRANS_KEYS_PREFIX.DEEP_DIVE_CTA)}</span>
          <ArrowRightLightIcon />
        </div>
      ),
    });

    return baseColumns;
  }, [metrics, metricByIndex, emitEvent, t]);

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.TITLE)}
      className={classNames(classes.ReleaseImpactSummaryFigure, className)}
    >
      <FlexibleTable columns={tableColumns} data={tableData} />
    </DocumentTitleWrapper>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import {PartType} from './executive-summary.types';
import classes from './executive-summary.module.scss';

interface Props {
  type: PartType;
  [other: string]: any;
  className?: string;
}

const PlainTextPart: React.FC<Props> = (props: Props) => {
  const {text, className} = props;

  return <span className={className}>{text}</span>;
};

const BoldTextPart: React.FC<Props> = (props: Props) => {
  const {text, className} = props;

  return <span className={classNames(classes.BoldTextPart, className)}>{text}</span>;
};

const ChangeTextPart: React.FC<Props> = (props: Props) => {
  const {text, color, className} = props;

  return (
    <span className={classNames(classes.ChangeTextPart, classes[color], className)}>{text}</span>
  );
};

const TYPE_MAPPING = {
  [PartType.PLAIN_TEXT]: PlainTextPart,
  [PartType.BOLD_TEXT]: BoldTextPart,
  [PartType.CHANGE_TEXT]: ChangeTextPart,
};

export const SentencePart: React.FC<Props> = (props: Props) => {
  const {type} = props;

  const Component = TYPE_MAPPING[type];
  if (!Component) {
    return null;
  }

  return <Component {...props} className={classNames(classes.SentencePart, props.className)} />;
};

import {useCallback, useMemo} from 'react';
import {Navigate, Routes, Route, useLocation} from 'react-router';
import {StringParam, useQueryParam} from 'use-query-params';
import Login from './components/login/login.component';
import ForgotPasswordForm from './components/forgot-password/forgot-password-form.component';
import SetPasswordForm from './components/set-password/set-password-form.component';
import Core from '../core/core.component';
import {Guard} from 'front-core';
import {AppRoutes} from '../../constants/app-routes';
import SignUpForm from './components/sign-up/sign-up-form.component';
import InviteSignupForm from './components/invite-signup/invite-signup-form.component';
import {useCurrentUser} from '../../core/hooks/use-user.hook';
import {useProductData} from '../../core/hooks/use-product-data.hook';
import {useIsAdmin} from '../../core/hooks/use-is-admin.hook';
import {IntegrationStatus} from '../../objects/models/product-data.model';
import {Homepage} from '../homepage/pages/homepage-main/homepage-main.component.tsx';
import DMP from '../dmp/dmp.component';
import {Settings} from '../settings/settings.component';
import ExperimentsMain from '../experiments/pages/experiments-main/experiments-main.component.tsx';
import ViewExperiment from '../experiments/pages/view-experiment/view-experiment.component.tsx';
import {AnalysesMain} from '../analyses/pages/analyses-main/analyses-main.component.tsx';
import ViewAnalysis from '../analyses/pages/view-analysis/view-analysis.component.tsx';
import ViewPhenomena from '../analyses/pages/view-phenomena/view-phenomena.component.tsx';
import ViewOpportunity from '../opportunities/pages/view-opportunity/view-opportunity.component.tsx';
import OpportunitiesMain from '../opportunities/pages/opportunities-main/opportunities-main.component.tsx';
import {WorkflowsMain} from '../workflows/pages/workflows-main/workflows-main.component.tsx';
import {ViewWorkflow} from '../workflows/pages/view-workflow/view-workflow.component.tsx';
import ViewMetricSeriesResult from '../health-monitor/pages/view-metric-series-result/view-metric-series-result.component.tsx';
import {HealthMonitor} from '../health-monitor/pages/health-monitor/health-monitor.component.tsx';
import {KPITreePage} from '../metrics/pages/kpi-tree-page/kpi-tree-page.component.tsx';
import {MetricPage} from '../metrics/pages/metric-page/metric-page.component.tsx';
import {FunnelPage} from '../funnels/pages/funnel-page/funnel-page.component.tsx';
import {ChatBotMain} from '../chat-bot/pages/chat-bot-main/chat-bot-main.component.tsx';
import {FeaturesMain} from '../dmp/pages/features-main/features-main.component.tsx';
import {ContentsMain} from '../dmp/pages/contents-main/contents-main.component.tsx';
import {FunnelsMain} from '../dmp/pages/funnels-main/funnels-main.component.tsx';
import {SegmentsMain} from '../dmp/pages/segments-main/segments-main.component.tsx';
import {MetricsMain} from '../dmp/pages/metrics-main/metrics-main.component.tsx';
import {EventGroupsMain} from '../dmp/pages/event-groups-main/event-groups-main.component.tsx';
import {AnnotationsMain} from '../dmp/pages/annotations-main/annotations-main.component.tsx';
import {TablesMain} from '../dmp/pages/tables-main/tables-main.component.tsx';
import {TableEventsMain} from '../dmp/pages/table-events-main/table-events-main.component.tsx';
import {MilestonesMain} from '../dmp/pages/milestones-main/milestones-main.component.tsx';
import {UserNotifications} from '../settings/pages/user-notifications/user-notifications.component.tsx';
import {UserPermissions} from '../settings/pages/user-permissions/user-permissions.component.tsx';
import {MembersSettings} from '../settings/pages/members-settings/members-settings.component.tsx';
import {UserOther} from '../settings/pages/user-other/user-other.component.tsx';
import {TeamsSettings} from '../settings/pages/teams-settings/teams-settings.component.tsx';
import {ExploreWorkflows} from '../workflows/pages/explore-users/explore-users.component.tsx';
import {SubscriptionReportPage} from '../insights/pages/subscription-report-page/subscription-report-page.component.tsx';
import {DataPipelinesMain} from '../dmp/pages/data-pipelines-main/data-pipelines-main.component.tsx';
import {ExplorationMain} from '../exploration/pages/exploration-main/exploration-main.component.tsx';

const AppRouting = (props: {}) => {
  const user = useCurrentUser();
  const [token] = useQueryParam('token', StringParam);
  const tokenExists = useMemo(() => Boolean(token), [token]);
  const location = useLocation();
  const redirectToLoginPath = useMemo(() => {
    if (!location.search) {
      return '/login';
    }
    return `/login?redirectTo=${location.pathname}${location.search}`;
  }, [location]);
  const {integrationStatus} = useProductData();
  const isAdmin = useIsAdmin();
  const isActiveRoute = useMemo(
    () => isAdmin || integrationStatus === IntegrationStatus.ACTIVE,
    [isAdmin, integrationStatus]
  );
  const onIntegrationStatusNotActive = useCallback(
    () => <Navigate to={AppRoutes.dmp().pathname} />,
    []
  );

  return (
    <Routes>
      <Route
        key={'requestAccess'}
        path={AppRoutes.signUp().pathname}
        element={
          <Guard isActive={true} onNotActive={() => <Navigate to={'/'} />}>
            <SignUpForm />
          </Guard>
        }
      />
      <Route
        key={'forgotPassword'}
        path={AppRoutes.forgotPassword().pathname}
        element={
          <Guard isActive={true} onNotActive={() => <Navigate to={'/'} />}>
            <ForgotPasswordForm />
          </Guard>
        }
      />
      <Route
        key={'resetPassword'}
        path={AppRoutes.resetPassword().pathname}
        element={
          <Guard isActive={!user && tokenExists} onNotActive={() => <Navigate to={'/'} />}>
            <SetPasswordForm />
          </Guard>
        }
      />
      <Route
        key={'setPassword'}
        path={AppRoutes.setPassword().pathname}
        element={
          <Guard isActive={!user && tokenExists} onNotActive={() => <Navigate to={'/'} />}>
            <SetPasswordForm />
          </Guard>
        }
      />
      <Route
        key={'signUp'}
        path={AppRoutes.signup().pathname}
        element={
          <Guard isActive={!user && tokenExists} onNotActive={() => <Navigate to={'/'} />}>
            <InviteSignupForm />
          </Guard>
        }
      />
      <Route
        key={'login'}
        path={AppRoutes.login().pathname}
        element={
          <Guard isActive={user === undefined} onNotActive={() => <Navigate to={'/'} />}>
            <Login />
          </Guard>
        }
      />
      <Route
        key={'core'}
        path={AppRoutes.root().pathname}
        element={
          <Guard isActive={Boolean(user)} onNotActive={() => <Navigate to={redirectToLoginPath} />}>
            <Core />
          </Guard>
        }
      >
        <Route
          path={AppRoutes.homepage().pathname}
          element={
            <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
              <Homepage />
            </Guard>
          }
        />
        <Route
          path={AppRoutes.subscriptionReport().pathname}
          element={
            <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
              <SubscriptionReportPage />
            </Guard>
          }
        />
        <Route path={AppRoutes.experiments().pathname}>
          <Route
            path={AppRoutes.viewExperiment().pathname}
            element={
              <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
                <ViewExperiment />
              </Guard>
            }
          />
          <Route
            path={AppRoutes.experiments().pathname}
            element={
              <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
                <ExperimentsMain />
              </Guard>
            }
          />
        </Route>
        <Route path={AppRoutes.exploration().pathname}>
          <Route path={AppRoutes.exploration().pathname} element={<ExplorationMain />} />
        </Route>
        <Route path={AppRoutes.analyses().pathname}>
          <Route path={AppRoutes.viewAnalysis().pathname} element={<ViewAnalysis />} />
          <Route path={AppRoutes.viewPhenomena().pathname} element={<ViewPhenomena />} />
          <Route path={AppRoutes.analyses().pathname} element={<AnalysesMain />} />
        </Route>
        <Route path={AppRoutes.opportunities().pathname}>
          <Route path={AppRoutes.viewOpportunity().pathname} element={<ViewOpportunity />} />
          <Route path={AppRoutes.opportunities().pathname} element={<OpportunitiesMain />} />
        </Route>
        <Route path={AppRoutes.workflows().pathname}>
          <Route path={AppRoutes.workflowsExplore().pathname} element={<ExploreWorkflows />} />
          <Route path={AppRoutes.viewWorkflow().pathname} element={<ViewWorkflow />} />
          <Route path={AppRoutes.workflows().pathname} element={<WorkflowsMain />} />
        </Route>
        <Route path={AppRoutes.healthMonitor().pathname}>
          <Route path={AppRoutes.healthMonitor().pathname} element={<HealthMonitor />} />
          <Route
            path={AppRoutes.viewMetricSeriesResult().pathname}
            element={<ViewMetricSeriesResult />}
          />
        </Route>
        <Route path={AppRoutes.metrics().pathname}>
          <Route
            path={AppRoutes.kpiTree().pathname}
            element={
              <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
                <KPITreePage />
              </Guard>
            }
          />
          <Route
            path={AppRoutes.viewMetric().pathname}
            element={
              <Guard isActive={isActiveRoute} onNotActive={onIntegrationStatusNotActive}>
                <MetricPage />
              </Guard>
            }
          />
        </Route>
        <Route path={AppRoutes.viewFunnel().pathname} element={<FunnelPage />} />
        <Route path={AppRoutes.chatBot().pathname} element={<ChatBotMain />} />
        <Route path={AppRoutes.dmp().pathname} element={<DMP />}>
          <Route path={AppRoutes.features().pathname} element={<FeaturesMain />} />
          <Route path={AppRoutes.contents().pathname} element={<ContentsMain />} />
          <Route path={AppRoutes.funnels().pathname} element={<FunnelsMain />} />
          <Route path={AppRoutes.segments().pathname} element={<SegmentsMain />} />
          <Route path={AppRoutes.kpis().pathname} element={<MetricsMain />} />
          <Route path={AppRoutes.eventGroups().pathname} element={<EventGroupsMain />} />
          <Route path={AppRoutes.annotations().pathname} element={<AnnotationsMain />} />
          <Route path={AppRoutes.views().pathname} element={<TablesMain />} />
          <Route path={AppRoutes.tableEvents().pathname} element={<TableEventsMain />} />
          <Route path={AppRoutes.milestones().pathname} element={<MilestonesMain />} />
          <Route path={AppRoutes.dataPipelines().pathname} element={<DataPipelinesMain />} />
          <Route path={AppRoutes.dmp().pathname} element={<Navigate to={AppRoutes.kpis()} />} />
        </Route>
        <Route path={AppRoutes.settings().pathname} element={<Settings />}>
          <Route
            path={AppRoutes.settingsNotifications().pathname}
            element={<UserNotifications />}
          />
          <Route path={AppRoutes.settingsPermissions().pathname} element={<UserPermissions />} />
          <Route path={AppRoutes.settingsOther().pathname} element={<UserOther />} />
          <Route path={AppRoutes.settingsMembers().pathname} element={<MembersSettings />} />
          <Route path={AppRoutes.settingsTeams().pathname} element={<TeamsSettings />} />
        </Route>
        <Route
          path={AppRoutes.root().pathname}
          element={<Navigate to={AppRoutes.homepage().pathname} />}
        />
      </Route>
    </Routes>
  );
};

// @ts-ignore
export default AppRouting;

import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {FlexibleTableClasses} from '../general/flexible-table/flexible-table.component';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {exists} from 'front-core';
import {getPercentageValueFormatter} from '../../../../../../utils/formatters';

interface OwnProps {
  value: number;
  vsValue: number;
  vsLabel?: string;
  isPercentageValue?: boolean;
  icon?: any;
  forceShowVersus?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const VersusDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    vsValue,
    vsLabel,
    isPercentageValue,
    icon: Icon,
    forceShowVersus = false,
    className,
  } = props;
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(isPercentageValue),
    [isPercentageValue]
  );

  return (
    <div className={classNames(classes.VersusDisplay, className)}>
      {Icon && <Icon className={classes.Icon} />}
      {valueFormatter(value)}
      {exists(vsValue) ? (
        <span
          className={classNames(
            !forceShowVersus && FlexibleTableClasses.ShowOnHover,
            classes.Secondary
          )}
        >
          <span className={classes.VS}>vs.</span>
          <Tooltip placement={'top'} title={vsLabel || ''} interactive={false}>
            <span>{valueFormatter(vsValue)}</span>
          </Tooltip>
        </span>
      ) : null}
    </div>
  );
};

import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './treatment-adoption-table.module.scss';
import _, {capitalize, compact, groupBy, max} from 'lodash';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';
import {HoverHelperTip} from '../../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {exists, getNextSort, number2k, sortData, Sorting} from 'front-core';
import {
  FlexibleTable,
  FlexibleTableClasses,
  FlexibleTableColumn,
} from '../../../../../shared/general/flexible-table/flexible-table.component';
import pluralize from 'pluralize';
import {
  AnalysisAlgoMode,
  CommandType,
  DocumentElementType,
  TreatmentAdoptionSimulationFigureOptions,
  TreatmentAdoptionSimulationItemType,
} from '../../../../../../types';
import {ModelType} from '../../../../../../../../../consts/model-type';
import {Button} from '../../../../../../../../simple/controls/button/button.component';
import {
  DeepDiveLightIcon,
  RocketLaunchLightIcon,
  RocketLaunchRegularIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {useSimulator} from '../../../../../../hooks/use-simulator.hook';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {VersusDisplay} from '../../../../../shared/display-columns/versus-display.component';
import {PercentageAbsDisplay} from '../../../../../shared/display-columns/percentage-abs-display.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {UpliftDisplay} from '../../../../../shared/display-columns/uplift-display.component';
import {
  ExtendedTreatmentAdoptionSimulationItem,
  MaxPotentialMode,
} from '../../base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.component';
import {getPercentageValueFormatter} from '../../../../../../../../../utils/formatters';
import {
  DocumentMessage,
  DocumentMessageType,
} from '../../../../../shared/general/document-message/document-message.component';
import {IconButton} from '../../../../../../../../simple/controls/icon-button/icon-button.component';
import TransKeys from 'translations';
import {createPortal} from 'react-dom';
import {combineFollowUpCommands} from './treatment-adoption-table.utils';

interface OwnProps {
  figureId: string;
  options: TreatmentAdoptionSimulationFigureOptions;
  selectedType: TreatmentAdoptionSimulationItemType;
  items: ExtendedTreatmentAdoptionSimulationItem[];
  goalValue: number;
  mode: AnalysisAlgoMode;
  footerRenderer?: () => any;
  tabActionsRef?: React.RefObject<HTMLDivElement>;
  transKeysPrefix: any;
  maxPotentialMode?: MaxPotentialMode;
  className?: string;
}

const separatorText = {
  arrow: '→',
  comma: ',',
  and: 'and',
  or: 'or',
};
const Separator = (props: {separator: 'arrow' | 'comma' | 'and' | 'or'}) => {
  return (
    <span className={classNames(classes.Separator, classes[props.separator])}>
      {separatorText[props.separator]}
    </span>
  );
};

const useSharedColumns = (
  goalValue: number,
  overallMaxPotential: number,
  overallMaxImpact: number,
  maxPotentialMode: MaxPotentialMode,
  options: TreatmentAdoptionSimulationFigureOptions,
  transKeysPrefix: any
) => {
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {t} = useDocumentTranslation();
  const treatmentName: FlexibleTableColumn<any> = useMemo(
    () => ({
      key: 'treatmentName',
      title: capitalize(options.treatmentModelName),
      weight: 2,
      sortable: true,
      render: item => (
        <div className={classNames(classes.ColumnValue, classes.Name)}>
          {item.treatmentName.map((t, tidx) => {
            const isLast = tidx === item.treatmentName.length - 1;
            const showSeparator = !isLast && item.treatmentName.length > 1;
            return (
              <React.Fragment key={tidx}>
                <TooltipIfOverflow title={item.treatmentName[tidx]}>
                  <div
                    onClick={
                      item.treatmentCommand[tidx]
                        ? () => emitEvent(item.treatmentCommand[tidx])
                        : undefined
                    }
                    className={classNames(
                      classes.Treatment,
                      item.treatmentCommand[tidx] && classes.Clickable
                    )}
                  >
                    <span className={classes.Text}>{t}</span>
                  </div>
                </TooltipIfOverflow>
                {showSeparator && <Separator separator={options.separator || 'comma'} />}
              </React.Fragment>
            );
          })}
          {item.helperText && <HoverHelperTip className={classes.Info} title={item.helperText} />}
        </div>
      ),
    }),
    [options, emitEvent]
  );
  const adoption: FlexibleTableColumn<any> = useMemo(
    () => ({
      key: 'adoption',
      title: t(transKeysPrefix.HEADERS.ADOPTION.LABEL),
      helperText: t(transKeysPrefix.HEADERS.ADOPTION.HELPER_TEXT),
      weight: 1.2,
      sortable: true,
      render: item => (
        <PercentageAbsDisplay
          percentage={item.adoption}
          abs={item.adoptionAbs}
          unit={pluralize(options.entity)}
        />
      ),
    }),
    [options.entity, t, transKeysPrefix]
  );
  const info: FlexibleTableColumn<any> = useMemo(
    () => ({
      key: 'info',
      title: t(transKeysPrefix.HEADERS.INFO.LABEL),
      helperText: t(transKeysPrefix.HEADERS.INFO.HELPER_TEXT),
      weight: 3,
      types: [TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION],
      render: item => item.info,
    }),
    [t, transKeysPrefix]
  );
  const renderPotential = useCallback(
    (
      potential: number,
      potentialUpper: number,
      potentialLower: number,
      actualType: TreatmentAdoptionSimulationItemType,
      maxPotentialMode?: MaxPotentialMode,
      showDiff = true
    ) => {
      // Swap upper & lower for negative effects
      let valueLower =
        options.isPercentageValue && exists(potentialLower) ? potentialLower * 100 : potentialLower;
      let valueUpper =
        options.isPercentageValue && exists(potentialUpper) ? potentialUpper * 100 : potentialUpper;
      if (
        actualType === TreatmentAdoptionSimulationItemType.NEGATIVE ||
        actualType === TreatmentAdoptionSimulationItemType.INSIGNIFICANT_NEGATIVE
      ) {
        const temp = valueLower;
        valueLower = valueUpper;
        valueUpper = temp;
      }
      const isInsignificant =
        [
          TreatmentAdoptionSimulationItemType.INSIGNIFICANT_NEGATIVE,
          TreatmentAdoptionSimulationItemType.INSIGNIFICANT_POSITIVE,
        ].indexOf(actualType) > -1;
      let min = options.isPercentageValue ? goalValue * 100 : goalValue;
      let max = options.isPercentageValue ? overallMaxPotential * 100 : overallMaxPotential;
      if (maxPotentialMode === MaxPotentialMode.IMPACT) {
        min = options.isPercentageValue ? 0 : 0;
        max = options.isPercentageValue ? overallMaxImpact * 100 : overallMaxImpact;
      }
      let suffix = options.isPercentageValue ? '%' : '';
      if (maxPotentialMode === MaxPotentialMode.IMPACT) {
        suffix = '';
      }

      return (
        <div className={classes.Inline}>
          <GradientLabelDisplay
            value={options.isPercentageValue ? potential * 100 : potential}
            valueLower={valueLower}
            valueUpper={valueUpper}
            min={min}
            max={max}
            mode={isInsignificant ? 'range' : 'default'}
            tooltipLabel={t(transKeysPrefix.LABELS.CONFIDENCE_INTERVAL)}
            suffix={suffix}
            showDiff={showDiff}
          />
          {maxPotentialMode === MaxPotentialMode.IMPACT && (
            <>
              <div className={classes.PP}>pp</div>
              <span className={classes.OutOfText}>
                / {getPercentageValueFormatter(options.isPercentageValue)(goalValue)}
              </span>
            </>
          )}
        </div>
      );
    },
    [
      goalValue,
      options.isPercentageValue,
      overallMaxImpact,
      overallMaxPotential,
      t,
      transKeysPrefix,
    ]
  );
  const simulatedPotential = useMemo(
    () => ({
      key: 'maxPotential', // to sort by maxPotential
      title: t(transKeysPrefix.HEADERS.POTENTIAL_KPI.LABEL),
      helperText: t(transKeysPrefix.HEADERS.POTENTIAL_KPI.HELPER_TEXT),
      weight: 1.5,
      sortable: true,
      types: [
        TreatmentAdoptionSimulationItemType.NEGATIVE,
        TreatmentAdoptionSimulationItemType.POSITIVE,
        TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
      ],
      render: item =>
        renderPotential(
          item.potential,
          item.potentialUpper,
          item.potentialLower,
          item.actualType,
          maxPotentialMode
        ),
    }),
    [renderPotential, maxPotentialMode, t, transKeysPrefix]
  );
  const maxPotential = useMemo(
    () => ({
      key: 'maxPotential',
      title: t(transKeysPrefix.HEADERS.MAX_POTENTIAL.LABEL),
      helperText: t(transKeysPrefix.HEADERS.MAX_POTENTIAL.HELPER_TEXT),
      weight: 1.5,
      sortable: true,
      types: [
        TreatmentAdoptionSimulationItemType.NEGATIVE,
        TreatmentAdoptionSimulationItemType.POSITIVE,
        TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
      ],
      hidden: maxPotentialMode !== MaxPotentialMode.POTENTIAL,
      mode: AnalysisAlgoMode.CAUSATION,
      render: item =>
        renderPotential(
          item.maxPotential,
          item.maxPotentialUpper,
          item.maxPotentialLower,
          item.actualType,
          MaxPotentialMode.POTENTIAL
        ),
    }),
    [renderPotential, maxPotentialMode, t, transKeysPrefix]
  );
  const maxImpact = useMemo(
    () => ({
      key: 'maxImpact',
      title: t(transKeysPrefix.HEADERS.MAX_IMPACT.LABEL),
      helperText: t(transKeysPrefix.HEADERS.MAX_IMPACT.HELPER_TEXT),
      weight: 1.5,
      sortable: true,
      types: [
        TreatmentAdoptionSimulationItemType.NEGATIVE,
        TreatmentAdoptionSimulationItemType.POSITIVE,
        TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
      ],
      mode: AnalysisAlgoMode.CAUSATION,
      hidden: maxPotentialMode !== MaxPotentialMode.IMPACT,
      render: item =>
        renderPotential(
          item.maxImpact,
          item.maxImpactUpper,
          item.maxImpactLower,
          item.actualType,
          MaxPotentialMode.IMPACT,
          false
        ),
    }),
    [maxPotentialMode, renderPotential, t, transKeysPrefix]
  );

  return {
    treatmentName,
    adoption,
    simulatedPotential,
    maxPotential,
    maxImpact,
    info,
  };
};

const DEFAULT_SORT_MAX_POTENTIAL: Sorting = {
  order: 'desc',
  orderBy: 'maxPotential',
};

const DEFAULT_SORT_MAX_IMPACT: Sorting = {
  order: 'desc',
  orderBy: 'maxImpact',
};

const DEFAULT_SORT_DATA_MODE_CORRELATION: Sorting = {
  order: 'desc',
  orderBy: 'uplift',
};

export const TreatmentAdoptionDataModeTable: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    selectedType,
    items,
    goalValue,
    mode,
    options,
    footerRenderer,
    transKeysPrefix,
    maxPotentialMode = MaxPotentialMode.POTENTIAL,
    className,
  } = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {t} = useDocumentTranslation();
  const itemsGroupByType = useMemo(() => groupBy(items, 'type'), [items]);
  const overallMaxPotential = useMemo(() => max(items.map(i => i.maxPotential)), [items]);
  const overallMaxImpact = useMemo(() => max(items.map(i => i.maxImpact)), [items]);
  const {treatmentName, adoption, info, maxPotential, maxImpact} = useSharedColumns(
    goalValue,
    overallMaxPotential,
    overallMaxImpact,
    maxPotentialMode,
    options,
    transKeysPrefix
  );
  const gotoPhenomenaDoc = useCallback(
    (phenomenaDoc: string) =>
      emitEvent({
        type: CommandType.REF,
        payload: {
          type: ModelType.PHENOMENA_DOC,
          refId: phenomenaDoc,
        },
      }),
    [emitEvent]
  );
  const columns = useMemo(
    () =>
      [
        treatmentName,
        adoption,
        {
          key: 'goalInAdopters',
          title: t(transKeysPrefix.HEADERS.GOAL_IN_ADOPTERS.LABEL),
          subTitle: `vs. ${t(transKeysPrefix.HEADERS.GOAL_IN_NON_ADOPTERS.LABEL)}`,
          helperText: t(transKeysPrefix.HEADERS.GOAL_IN_ADOPTERS.HELPER_TEXT),
          weight: 1.2,
          sortable: true,
          types: [
            TreatmentAdoptionSimulationItemType.NEGATIVE,
            TreatmentAdoptionSimulationItemType.POSITIVE,
            TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
            TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION,
          ],
          render: item => (
            <VersusDisplay
              value={item.goalInAdopters}
              vsValue={item.goalInNonAdopters}
              isPercentageValue={options.isPercentageValue}
              vsLabel={t(transKeysPrefix.HEADERS.GOAL_IN_NON_ADOPTERS.LABEL)}
            />
          ),
        },
        {
          key: 'uplift',
          title: t(transKeysPrefix.HEADERS.UPLIFT.LABEL),
          helperText: t(transKeysPrefix.HEADERS.UPLIFT.HELPER_TEXT),
          weight: 1,
          sortable: true,
          types: [
            TreatmentAdoptionSimulationItemType.NEGATIVE,
            TreatmentAdoptionSimulationItemType.POSITIVE,
            TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
          ],
          mode: AnalysisAlgoMode.CORRELATION,
          render: item => {
            return <UpliftDisplay uplift={item.uplift} className={classes.ColumnValue} />;
          },
        },
        maxPotential,
        maxImpact,
        info,
        {
          key: 'deep_dive',
          title: '',
          weight: 0.5,
          render: item =>
            item.phenomenaDoc && (
              <div className={classNames(classes.ColumnValue, classes.DeepDive)}>
                <Button
                  className={FlexibleTableClasses.ShowOnHover}
                  onClick={() => gotoPhenomenaDoc(item.phenomenaDoc)}
                >
                  {t(transKeysPrefix.ACTIONS.DEEP_DIVE)}
                </Button>
              </div>
            ),
        },
      ].filter((c: any) => {
        const isRelevantType = c.types ? c.types.indexOf(selectedType) > -1 : true;
        const isRelevantMode = c.mode ? mode === c.mode : true;
        return isRelevantType && isRelevantMode;
      }),
    [
      treatmentName,
      adoption,
      t,
      transKeysPrefix,
      maxPotential,
      info,
      options,
      gotoPhenomenaDoc,
      selectedType,
      mode,
    ]
  );
  const emptyStateText = useMemo(
    () => t(transKeysPrefix.DATA_MODE_TABLE_EMPTY_STATE[mode.toUpperCase()]),
    [mode, t, transKeysPrefix]
  );
  const defaultSort = useMemo(() => {
    if (mode === AnalysisAlgoMode.CORRELATION) {
      return DEFAULT_SORT_DATA_MODE_CORRELATION;
    }
    if (maxPotentialMode === MaxPotentialMode.POTENTIAL) {
      return DEFAULT_SORT_MAX_POTENTIAL;
    }
    return DEFAULT_SORT_MAX_IMPACT;
  }, [maxPotentialMode, mode]);

  return (
    <FlexibleTable
      className={className}
      columns={columns}
      data={itemsGroupByType[selectedType] || []}
      defaultSort={defaultSort}
      emptyState={emptyStateText}
      afterHeadersRenderer={() =>
        selectedType === TreatmentAdoptionSimulationItemType.INSIGNIFICANT ? (
          <DocumentMessage
            type={DocumentMessageType.WARN}
            text={t(transKeysPrefix.LABELS.INSIGNIFICANT, {
              model: options.treatmentModelName,
            })}
          />
        ) : null
      }
      footerRenderer={footerRenderer}
    />
  );
};

export const TreatmentAdoptionSimulatorModeTable: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    goalValue,
    selectedType,
    mode,
    items: itemsFromProps,
    options,
    figureId,
    transKeysPrefix,
    footerRenderer,
    maxPotentialMode = MaxPotentialMode.POTENTIAL,
    tabActionsRef,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const [sorting, setSorting] = useState<Sorting>(DEFAULT_SORT_MAX_POTENTIAL);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {trackInput, trackItemClicked} = useDocumentTracking(
    figureId,
    DocumentElementType.TREATMENT_ADOPTION_SIMULATION_FIGURE
  );
  const {items, onItemChangeValue, changedValuesMap} = useSimulator({
    items: itemsFromProps,
    goalValue: goalValue,
    minValue: -1,
  });
  const itemsGroupByType = useMemo(
    () =>
      _(items)
        .groupBy(item => item.type)
        .mapValues(group => sortData(group, sorting))
        .value(),
    [items, sorting]
  );
  const overallMaxPotential = useMemo(() => max(items.map(i => i.maxPotential)), [items]);
  const overallMaxImpact = useMemo(() => max(items.map(i => i.maxImpact)), [items]);
  const {treatmentName, adoption, info, simulatedPotential} = useSharedColumns(
    goalValue,
    overallMaxPotential,
    overallMaxImpact,
    maxPotentialMode,
    options,
    transKeysPrefix
  );
  const onSort = useCallback(
    (columnKey: string) => setSorting(getNextSort(columnKey, sorting)),
    [sorting, setSorting]
  );
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(options.isPercentageValue),
    [options]
  );
  const onDragComplete = useCallback(
    value => trackInput('adoption', {value, inputType: 'slider'}),
    [trackInput]
  );
  const gotoPhenomenaDoc = useCallback(
    (phenomenaDoc: string) =>
      emitEvent({
        type: CommandType.REF,
        payload: {
          type: ModelType.PHENOMENA_DOC,
          refId: phenomenaDoc,
        },
      }),
    [emitEvent]
  );
  const columns = useMemo(
    () =>
      [
        treatmentName,
        adoption,
        {
          key: 'change',
          title: t(
            transKeysPrefix.HEADERS[
              selectedType === TreatmentAdoptionSimulationItemType.POSITIVE
                ? 'ADOPTION_CHANGE'
                : 'ADOPTION_CHANGE_NEGATIVE'
            ].LABEL
          ),
          helperText: t(
            transKeysPrefix.HEADERS[
              selectedType === TreatmentAdoptionSimulationItemType.POSITIVE
                ? 'ADOPTION_CHANGE'
                : 'ADOPTION_CHANGE_NEGATIVE'
            ].HELPER_TEXT
          ),
          weight: 1.5,
          sortable: true,
          types: [
            TreatmentAdoptionSimulationItemType.NEGATIVE,
            TreatmentAdoptionSimulationItemType.POSITIVE,
          ],
          render: item => {
            if (item.type === TreatmentAdoptionSimulationItemType.INSIGNIFICANT) {
              return `${number2k(item.change * 100)}%`;
            }

            let mul = 1;
            const inputProps = {
              allowedMax: item.maxChange * 100,
              max: 1000,
              min: 0,
            };
            if (item.type === TreatmentAdoptionSimulationItemType.NEGATIVE) {
              mul = -1;
              inputProps.allowedMax = 100;
              inputProps.max = 100;
            }
            return (
              <SimulatorSlider
                {...inputProps}
                displayValue={item.newValue * 100}
                value={item.change * 100 * mul}
                defaultValue={item.defaultChange * 100}
                onChange={v => onItemChangeValue(item.key, exists(v) ? (mul * v) / 100 : null)}
                onChangeCommitted={v => onDragComplete(exists(v) ? (mul * v) / 100 : null)}
                suffix={'%'}
                sign={mul === 1 ? '+' : '-'}
                allowedMaxHelper={t(transKeysPrefix.SLIDER_MAX_HELPER)}
              />
            );
          },
        },
        simulatedPotential,
        info,
        {
          key: 'actions',
          title: '',
          width: '11rem',
          render: item => (
            <div className={classes.Actions}>
              {item.followUpCommand && (
                <IconButton
                  size={'large'}
                  tooltipText={t(transKeysPrefix.ACTIONS.FOLLOW_UP_TEXT)}
                  icon={RocketLaunchLightIcon}
                  onClick={() => emitEvent(item.followUpCommand)}
                />
              )}
              {item.phenomenaDoc && (
                <IconButton
                  size={'large'}
                  tooltipText={t(transKeysPrefix.ACTIONS.DEEP_DIVE)}
                  onClick={() => gotoPhenomenaDoc(item.phenomenaDoc)}
                  icon={DeepDiveLightIcon}
                />
              )}
            </div>
          ),
        },
      ].filter((c: any) => {
        const isRelevantType = c.types ? c.types.indexOf(selectedType) > -1 : true;
        const isRelevantMode = c.mode ? mode === c.mode : true;
        return isRelevantType && isRelevantMode;
      }),
    [
      selectedType,
      treatmentName,
      adoption,
      info,
      valueFormatter,
      changedValuesMap,
      simulatedPotential,
      goalValue,
      mode,
      emitEvent,
      gotoPhenomenaDoc,
    ]
  );
  const emptyStateText = useMemo(
    () => t(transKeysPrefix.SIMULATION_MODE_TABLE_EMPTY_STATE[mode.toUpperCase()]),
    [mode, t]
  );
  const combinedFollowUpCommand = useMemo(() => {
    if (selectedType !== TreatmentAdoptionSimulationItemType.POSITIVE) {
      return null;
    }
    const commands = compact(
      itemsGroupByType[TreatmentAdoptionSimulationItemType.POSITIVE].map(
        item => item.followUpCommand
      )
    );
    return combineFollowUpCommands(commands);
  }, [itemsGroupByType, selectedType]);
  const onImproveAllAdoptionClick = useCallback(() => {
    emitEvent(combinedFollowUpCommand);
    trackItemClicked('improve_adoption_all', {});
  }, [combinedFollowUpCommand, emitEvent, trackItemClicked]);

  return (
    <>
      {combinedFollowUpCommand &&
        tabActionsRef.current &&
        createPortal(
          <Button
            className={classes.ImproveAdoptionButton}
            size={'large'}
            icon={RocketLaunchRegularIcon}
            onClick={onImproveAllAdoptionClick}
          >
            {t(
              TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.ACTIONS.FOLLOW_UP_TEXT
            )}
          </Button>,
          tabActionsRef.current
        )}
      <FlexibleTable
        className={className}
        columns={columns}
        data={itemsGroupByType[selectedType] || []}
        sorting={sorting}
        onSort={onSort}
        rowClassName={classes.SimulatorRow}
        emptyState={emptyStateText}
        footerRenderer={footerRenderer}
        afterHeadersRenderer={
          selectedType === TreatmentAdoptionSimulationItemType.INSIGNIFICANT
            ? () => (
                <DocumentMessage
                  type={DocumentMessageType.WARN}
                  text={t(transKeysPrefix.LABELS.INSIGNIFICANT, {
                    model: options.treatmentModelName,
                  })}
                />
              )
            : null
        }
      />
    </>
  );
};

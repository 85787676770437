import classNames from 'classnames';
import {WeeklyReportItem} from '../../subscription-report-page.types.ts';
import classes from './subscription-segmentation-breakdown.module.scss';
import {
  ExplainabilityLabelDisplay,
  VersusWithUpliftDisplay,
  getPercentageValueFormatter,
  UpliftDisplay,
} from 'ui-components';
import {useMemo} from 'react';
import {calculateUplift} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface Props {
  data: WeeklyReportItem;
  className?: string;
}

export const SubscriptionSegmentationBreakdown = (props: Props) => {
  const {data, className} = props;
  const {t} = useTranslation();
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(data.rcaOverviewMetric.isPercentageValue),
    [data]
  );

  return (
    <div className={classNames(classes.SubscriptionSegmentationBreakdown, className)}>
      <div className={classes.MainTitle}>
        {t(TransKeys.SUBSCRIPTION_REPORT_PAGE.KPI_ITEM.SEGMENT_BREAKDOWN_TITLE)}
      </div>
      <div className={classes.TableContainer}>
        <div className={classes.Headers}>
          <div className={classes.Header}>Segment</div>
          <div className={classes.Header}>Share</div>
          <div className={classes.Header}>Explainability</div>
          <div className={classes.Header}>Current value</div>
          <div className={classes.Header}>Previous value</div>
          <div className={classes.Header}>Change</div>
        </div>
        <div className={classes.Content}>
          {data.segmentBreakdownData.map(s => (
            <div className={classes.SegmentGroup}>
              <div className={classes.Title}>segment-group: {s.segmentName}</div>
              {s.segments.map(segment => (
                <div className={classes.Segment}>
                  <div className={classNames(classes.Cell, classes.SegmentNameCell)}>
                    {segment.segmentName}
                  </div>
                  <div className={classes.Cell}>
                    <VersusWithUpliftDisplay
                      value={segment.share}
                      vsValue={segment.expectedShare}
                      isPercentageValue={true}
                    />
                  </div>
                  <div className={classes.Cell}>
                    <ExplainabilityLabelDisplay
                      label={segment.explainabilityLabel}
                      isSignificant={segment.isSignificant}
                    />
                  </div>
                  <div className={classes.Cell}>{valueFormatter(segment.value)}</div>
                  <div className={classes.Cell}>{valueFormatter(segment.expectedValue)}</div>
                  <div className={classes.Cell}>
                    <UpliftDisplay
                      uplift={calculateUplift(segment.value, segment.expectedValue)}
                      higherIsBetter={data.rcaOverviewMetric.higherIsBetter}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

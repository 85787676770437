import TransKeys from 'translations';
import {ToggleOpenButton} from '../../../../../../../../simple/controls/toggle-open-button/toggle-open-button.component';
import {ExplainabilityLabelDisplay} from '../../../../../../../../simple/data-display/explainability-label-display/explainability-label-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useState, ReactNode} from 'react';
import {ExplainabilityLabel} from '../../../../../../types';
import classNames from 'classnames';
import classes from './collapsible-explainer.module.scss';

export type Props = {
  explainerDescription: ReactNode;
  explainabilityLabel?: ExplainabilityLabel;
  isSignificant: boolean;
  chart: ReactNode;
  modelName: string;
  className?: string;
};

export const CollapsibleExplainer = (props: Props) => {
  const {explainerDescription, explainabilityLabel, isSignificant, chart, modelName, className} =
    props;
  const {t} = useDocumentTranslation();
  const [showChart, setShowChart] = useState(false);

  return (
    <div className={classNames(classes.ExplainerHeader, className)}>
      <div
        className={classNames(
          classes.Header,
          isSignificant && explainabilityLabel && classes[explainabilityLabel]
        )}
      >
        <div className={classes.Main}>{explainerDescription}</div>
        <div className={classes.Explainability}>
          <span className={classes.Label}>
            {t(TransKeys.DOCUMENT_VIEWER.RCA_EXPLAINER_HEADER.EXPLAINABILITY_LABEL_PREFIX)}
          </span>
          <ExplainabilityLabelDisplay
            label={explainabilityLabel}
            isSignificant={isSignificant}
            modelName={`the change in ${modelName}`}
          />
        </div>
        <div className={classes.Actions}>
          <ToggleOpenButton
            onClick={() => setShowChart(!showChart)}
            isOpen={showChart}
            openLabel={t(TransKeys.DOCUMENT_VIEWER.RCA_EXPLAINER_HEADER.ACTIONS.HIDE_GRAPH)}
            closedLabel={t(TransKeys.DOCUMENT_VIEWER.RCA_EXPLAINER_HEADER.ACTIONS.SHOW_GRAPH)}
          />
        </div>
      </div>
      {showChart && chart}
    </div>
  );
};

import {ArrowRightIcon, DialogLayout} from 'ui-components';
import {ImagesResource} from '../../../../assets/images';
import classes from './homepage-ai-chat.module.scss';
import {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Popover} from '@material-ui/core';
import {isEmpty, isEqual} from 'lodash';
import {HttpClientContext} from 'front-core';
import {getAIChatSuggestionsNetworkRequest} from '../../../../http/ai-chart.network-requests';
import {useNavigate} from 'react-router';
import {AppRoutes} from '../../../../constants/app-routes';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';

const DEFAULT_MAX_SUGGESTIONS_TO_SHOW = 5;
export const HomepageAIChat = () => {
  const {t} = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const navigate = useNavigate();

  const http = useContext(HttpClientContext);
  const anchorEl = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const suggestionsExist = suggestions?.length > 0;

  const onFocus = useCallback(() => {
    if (isEmpty(inputRef.current?.value)) {
      setShowSuggestions(suggestionsExist);
    }
  }, [suggestionsExist]);

  const onBlur = useCallback(() => {
    setShowSuggestions(false);
    setShowAllSuggestions(false);
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoadingSuggestions(true);
      const res = (await http.exec(getAIChatSuggestionsNetworkRequest())) as unknown as {
        message: string;
      }[];
      setSuggestions(res.map((suggestion: {message: string}) => suggestion.message));
      setIsLoadingSuggestions(false);
    })();
  }, [http]);

  const onSelectSuggestion = useCallback((suggestion: string) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setInputValue(suggestion);
    setShowSuggestions(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (inputValue) {
      navigate(AppRoutes.chatBot({message: encodeURIComponent(inputValue)}));
    }
  }, [inputValue, navigate]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value?.length > 0) {
        setShowSuggestions(false);
      } else {
        setShowSuggestions(suggestionsExist);
      }
      setInputValue(v => (isEqual(v, e.target.value) ? v : e.target.value));
    },
    [suggestionsExist]
  );

  const onOpenSuggestions = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setShowSuggestions(true);
  }, []);

  return (
    <div className={classes.HomepageAIChat} ref={anchorEl}>
      <div className={classes.InputContainer}>
        <img src={ImagesResource.app.loopsAIIcon} alt="loops-ai-icon" />
        <input
          ref={inputRef}
          type="text"
          className={classes.Input}
          value={inputValue}
          onFocusCapture={onFocus}
          onChange={handleChange}
          onKeyDown={e => e.key === 'Enter' && onSubmit()}
          placeholder={t(TransKeys.HOMEPAGE.AI_CHAT_INPUT.PLACEHOLDER)}
        />
        {suggestions.length > 0 && (
          <div className={classes.SuggestionsButton} onClick={onOpenSuggestions}>
            {t(TransKeys.HOMEPAGE.AI_CHAT_INPUT.SUGGESTIONS_BUTTON.LABEL)}
          </div>
        )}
        <div
          className={classNames(classes.SubmitButton, {
            [classes.Disabled]: !inputValue,
          })}
          onClick={onSubmit}
        >
          <ArrowRightIcon />
        </div>
      </div>
      {showSuggestions && (
        <Popover
          disableAutoFocus={true}
          autoFocus={false}
          disableRestoreFocus={true}
          disableEnforceFocus={true}
          onBackdropClick={onBlur}
          open={showSuggestions}
          className={classes.BlankPaperAllowOverflow}
          anchorEl={anchorEl.current}
          disablePortal={false}
          classes={{
            paper: classes.BlankPaperAllowOverflow,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DialogLayout className={classes.Suggestions}>
            {!isLoadingSuggestions &&
              suggestions
                .filter(
                  (s, index) =>
                    showAllSuggestions ||
                    (!showAllSuggestions && index < DEFAULT_MAX_SUGGESTIONS_TO_SHOW)
                )
                .map(suggestion => (
                  <div
                    className={classes.Suggestion}
                    onClick={() => onSelectSuggestion(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
            {isLoadingSuggestions && <div className={classes.Suggestion}>Loading...</div>}
            {!showAllSuggestions && suggestions?.length > DEFAULT_MAX_SUGGESTIONS_TO_SHOW && (
              <div
                className={classNames(classes.Suggestion, classes.ShowAllSuggestions)}
                onClick={() => setShowAllSuggestions(true)}
              >
                {t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              </div>
            )}
          </DialogLayout>
        </Popover>
      )}
    </div>
  );
};

import {MetricSampleData, RCASegmentationFigureOptions} from '../../../../../../types';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useEffect, useMemo, useState, useCallback, useContext} from 'react';
import TransKeys from 'translations';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {useTopExplainersSmartTable} from '../../hooks/use-top-explainers-smart-table.hook';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import classNames from 'classnames';
import {SwitchActions} from '../../../../../../../../simple/controls/switch-actions/switch-actions.component';
import {useSegmentChart} from './use-segment-chart.hook';
import {
  CircleInfoRegularIcon,
  UsersIcon,
  XmarkLightIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {exists, sortData, Sorting} from 'front-core';
import {keyBy} from 'lodash';
import classes from './segment-explainers-modal.module.scss';
import {Button} from '../../../../../../../../simple/controls/button/button.component';
import {SearchInput} from '../../../../../../../../simple/controls/search-input/search-input.component';
import {RunAnalysisContainer} from '../run-analysis-container/run-analysis-container.component';
import {SegmentExplainersTableRCASegmentFollowUpData} from '../segment-explainers-table/components/explainers-table/components/explainers-table-item/explainers-table-item.component';
import {GlobalDocumentDataContext} from '../../../../../../contexts/global-document-data/global-document-data.context';

interface OwnProps {
  segments: UnifiedRCASegmentationItem[];
  selectedGroupSignalId: number;
  populationTrend: number;
  goalValue: number;
  viewMode: RCASegmentationViewMode;
  version: number;
  groupOptions: {label: string; value: number}[];
  onSelectGroup: (signalId: number) => void;
  options: RCASegmentationFigureOptions;
  initialSegmentKey?: string;
  granularity: string;
  metricSamples: MetricSampleData[];
  onClose: () => void;
  showOvertimeChart: boolean;
  onSignalClick: (signalId: number) => void;
  onViewFollowUpRCAForSegment: (analysisId: number, analysisResultId: number) => void;
  onRunFollowUpRCAForSegment: (data: SegmentExplainersTableRCASegmentFollowUpData) => void;
}

type AllProps = OwnProps;

const PRIMARY_ORDER: Sorting = {orderBy: 'isSignificant', order: 'desc'};
const SECONDARY_ORDER_EXPLAIN: Sorting = {
  orderBy: 'explainabilityScore',
  order: 'desc',
};
const SECONDARY_ORDER_CHANGE: Sorting = {
  orderBy: 'interestScore',
  order: 'desc',
};

export const SegmentExplainersModal = (props: AllProps) => {
  const {
    segments: segmentsFromProps,
    initialSegmentKey,
    selectedGroupSignalId,
    populationTrend,
    viewMode,
    version,
    onSignalClick,
    onSelectGroup,
    options,
    granularity,
    metricSamples,
    onViewFollowUpRCAForSegment,
    onRunFollowUpRCAForSegment,
    showOvertimeChart,
    goalValue,
  } = props;
  const {t} = useDocumentTranslation();
  const {isFollowUp} = useContext(GlobalDocumentDataContext);
  const [showInsignificant, setShowInsignificant] = useState(true);
  const [searchSegmentText, setSearchSegmentText] = useState<string | null>(null);
  const [selectedSegmentKeys, setSelectedSegmentKeys] = useState<string[]>(() => {
    const initialSelectedSegment = segmentsFromProps.find(s => s.key === initialSegmentKey);
    if (initialSelectedSegment) {
      return [initialSelectedSegment.key];
    }
    return [segmentsFromProps[0].key];
  });

  const segments: UnifiedRCASegmentationItem[] = useMemo(
    () =>
      sortData(
        segmentsFromProps,
        PRIMARY_ORDER,
        viewMode === RCASegmentationViewMode.EXPLAIN
          ? SECONDARY_ORDER_EXPLAIN
          : SECONDARY_ORDER_CHANGE
      )
        .map(s => ({
          ...s,
          name: s.segmentName,
        }))
        .filter(segment => {
          return (
            !searchSegmentText ||
            segment.segmentName.toLowerCase().includes(searchSegmentText.toLowerCase())
          );
        }),
    [segmentsFromProps, viewMode, searchSegmentText]
  );

  const segmentsByKey = useMemo(() => keyBy(segments, 'key'), [segments]);
  const selectedSegmentKeySet = useMemo(() => new Set(selectedSegmentKeys), [selectedSegmentKeys]);

  const showToggleInsignificantButton = useMemo(() => {
    const insignificantSegmentsLength = segments.filter(s => s.isSignificant === false).length;
    return insignificantSegmentsLength > 0 && insignificantSegmentsLength < segments.length;
  }, [segments]);

  const onSegmentClicked = useCallback(
    (key: string) => {
      if (selectedSegmentKeySet.has(key)) {
        selectedSegmentKeySet.delete(key);
      } else {
        selectedSegmentKeySet.add(key);
      }
      setSelectedSegmentKeys(Array.from(selectedSegmentKeySet));
    },
    [selectedSegmentKeySet, setSelectedSegmentKeys]
  );

  const tableFigure = useTopExplainersSmartTable({
    version,
    groupName: false,
    selectedSegmentKeys,
    onSelectSegment: onSegmentClicked,
    options,
    segments,
    populationTrend,
    showInsignificant,
    viewMode,
    goalValue,
    key: 'top-segment-explainers-modal',
  });

  const {chartData, chartOptions} = useSegmentChart({
    selectedSegmentKeys,
    segmentsByKey,
    showAllPopulation: true,
    viewMode,
    metricSamples,
    options,
    granularity,
    showOvertimeChart,
  });

  const segmentFilters = useMemo(() => {
    return [
      {
        signalId: selectedGroupSignalId,
        classes: [segmentsByKey[initialSegmentKey].segmentName],
      },
    ];
  }, [selectedGroupSignalId, segmentsByKey, initialSegmentKey]);

  const segmentLabel = `${segmentsByKey[initialSegmentKey].segmentGroupName} > ${segmentsByKey[initialSegmentKey].segmentName}`;

  const onSearchSegmentTextChange = useCallback(
    (text: string) => {
      setSearchSegmentText(exists(text) ? text : null);
    },
    [setSearchSegmentText]
  );

  useEffect(() => {
    setSelectedSegmentKeys(initialSegmentKey ? [initialSegmentKey] : [segments[0].key]);
  }, [initialSegmentKey, setSelectedSegmentKeys, segments]);

  return (
    <ModalWrapper
      isOpen={selectedGroupSignalId !== null}
      onClose={() => onSelectGroup(null)}
      width={'100rem'}
      height={'80rem'}
    >
      <div className={classes.SegmentExplainersModal}>
        <XmarkLightIcon onClick={() => onSelectGroup(null)} className={classes.CloseIcon} />
        <div className={classes.Header}>
          <div className={classes.HeaderTitle}>
            {t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.TITLE, {
              kpiName: options.kpiName,
              segmentLabel,
            })}
          </div>
          <Button
            className={classes.ViewSegmentInfoButton}
            icon={CircleInfoRegularIcon}
            onClick={() => onSignalClick(selectedGroupSignalId)}
            variant="outlined"
          >
            {t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL
                .VIEW_SEGMENT_INFO_LABEL
            )}
          </Button>
        </div>
        {!isFollowUp && (
          <RunAnalysisContainer
            segmentFilters={segmentFilters}
            segmentLabel={segmentLabel}
            kpiName={options.kpiName}
            onViewFollowUpRCAForSegment={onViewFollowUpRCAForSegment}
            onRunFollowUpRCAForSegment={onRunFollowUpRCAForSegment}
          />
        )}
        {showOvertimeChart && (
          <div className={classes.ChartContainer}>
            <div className={classes.ChartTitle}>
              {t(
                TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.CHART_TITLE
              )}
            </div>
            <div
              className={classNames(
                classes.ChartWrapper,
                options.hasDenominator && classes.HasOptions
              )}
            >
              {options.hasDenominator && (
                <div className={classes.Options}>
                  <SwitchActions actions={chartOptions} showActionsLabel />
                </div>
              )}
              <div className={classes.Chart}>
                {chartData && <LineChart {...chartData} key={selectedSegmentKeys.join('.')} />}
                {chartData === null && (
                  <div className={classes.EmptyState}>
                    <UsersIcon className={classes.EmptyStateIcon} />
                    {t(
                      TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL
                        .EMPTY_STATE
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={classes.Filters}>
          <SearchInput
            className={classes.SearchInput}
            value={searchSegmentText}
            onChange={onSearchSegmentTextChange}
            placeholder={t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL
                .SEARCH_SEGMENTS_INPUT.PLACEHOLDER
            )}
          />
          {showToggleInsignificantButton && (
            <Checkbox
              label={t(TransKeys.DOCUMENT_VIEWER.GENERAL.SHOW_INSIGNIFICANT_SEGMENTS)}
              checked={showInsignificant}
              onChange={setShowInsignificant}
              border
            />
          )}
        </div>
        <div className={classes.Table}>
          <ChildRenderer children_={tableFigure} />
        </div>
      </div>
    </ModalWrapper>
  );
};

import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model.ts';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats.ts';
import {groupBy, keys} from 'lodash';
import {ExplainabilityLabel} from 'ui-components';
import {MetricValueType} from '../../../../objects/models/metric.model.ts';
import {WeeklyReportItem, WeeklySummaryItem} from './subscription-report-page.types.ts';
import {exists} from 'front-core';

export const generateXoXLabel = (granularity: string) => {
  return `${granularity[0].toString()}o${granularity[0].toString()}`;
};

export const toWeeklySummaryRowItem = (
  item: WeeklyReportItem,
  granularityFromProps?: ModelSeriesGranularity
): WeeklySummaryItem => {
  if (item.insights === null) {
    return {
      metricId: item.metricId,
      name: item.name,
      datetime: item.datetime,
      granularity: granularityFromProps,
      analysisResultId: item.analysisResultId,
      isReady: item.errorCode === 2001,
      hasInsights: exists(item.insights),
    };
  }

  const explainersByLabel = groupBy(
    item.insights.compareToDate.explainableSentences,
    'explainabilityLabel'
  );
  let explainersText = (item.insights.compareToDate.explainableSentences.length || 0).toString();
  if (keys(explainersByLabel).length > 1) {
    explainersText += ` (${explainersByLabel[ExplainabilityLabel.HIGH].length} ${ExplainabilityLabel.HIGH}, ${explainersByLabel[ExplainabilityLabel.MEDIUM].length} ${ExplainabilityLabel.MEDIUM})`;
  } else if (item.insights.compareToDate.explainableSentences.length === 0) {
    explainersText = 'No explainers';
  } else {
    explainersText += ` (${keys(explainersByLabel)[0]})`;
  }

  const i: any = {
    metricId: item.metricId,
    name: item.name,
    datetime: item.datetime,
    granularity: item.insights.sampledModelXoxOverview.granularity,
    value: item.insights.sampledModelXoxOverview.lastSampleValue,
    expectedValue: item.insights.sampledModelXoxOverview.lastSampleExpectedValue,
    uplift: item.insights.sampledModelXoxOverview.xoxChangeValue,
    upliftAbs: Math.abs(item.insights.sampledModelXoxOverview.xoxChangeValue),
    higherIsBetter: item.insights.sampledModelXoxOverview.higherIsBetter,
    isPercentageValue:
      item.insights.sampledModelXoxOverview.sampledModelValueType === MetricValueType.PERCENTAGE,
    explainersText,
    explainersCount: item.insights.compareToDate.explainableSentences.length,
    isAnomaly: item.insights.sampledModelXoxOverview.isSignificantLoopsAlgo,
    analysisResultId: item.analysisResultId,
    isReady: true,
    hasInsights: true,
  };
  let isPositiveTrend = i.uplift >= 0;
  if (i.higherIsBetter === false) {
    isPositiveTrend = !isPositiveTrend;
  }
  i.positiveUplift = isPositiveTrend;
  return i;
};

export const formatDateRange = (
  date: string,
  granularity: ModelSeriesGranularity,
  prev = false
) => {
  const start = moment.utc(date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
  if (prev) {
    start.subtract(1, granularity);
  }
  const end = start.clone().add(1, granularity).subtract(1, 'day');
  return `${start.format(TIME_FORMATS.READABLE_DATE_NO_YEAR)} - ${end.format(TIME_FORMATS.READABLE_DATE_NO_YEAR)}`;
};

export const generateIdForMetric = (metricId: number) => `metric-${metricId}`;
export const ROOT_ELEMENT_ID = 'weekly-report-root';

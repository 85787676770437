import {
  ChangeDescription,
  RCASentence,
} from '../../../../../../../../../insights/rca/rca-insights.model.ts';
import {
  SampledModelSentences,
  SampledModelXoXOverview,
} from '../../../../../../../../../insights/rca/sampled-model-sentences/objects';
import {RCAMode} from 'ui-components';
import {useMemo} from 'react';
import {SampledModelXoXOverviewSection} from '../sampled-model-overview-xox-section/sampled-model-xox-overview.component.tsx';
import {SampledModelSentencesSection} from '../sampled-model-sentences-section/sampled-model-sentences-section.component.tsx';
import {useProductData} from '../../../../../../../../../../core/hooks/use-product-data.hook';
import classes from '../../rca-executive-summery.module.scss';
import * as React from 'react';
import {RCAInsightsSection} from '../rca-insights-section/rca-insights-section.component.tsx';

export enum ChangeDescriptionAlias {
  DROP = 'drop',
  SPIKE = 'spike',
}

export const CHANGE_DESCRIPTION_ALIAS_MAP = {
  [ChangeDescription.INCREASE]: ChangeDescriptionAlias.SPIKE,
  [ChangeDescription.DECREASE]: ChangeDescriptionAlias.DROP,
};

interface RankedRCAInsights {
  explainableSentences: RCASentence[];
  interestingXoxSentences: RCASentence[];
  entityExplainerSentences?: RCASentence[];
}

export interface RCAExecutiveSummary {
  sampledModelInsights: SampledModelSentences;
  sampledModelXoxOverview: SampledModelXoXOverview;
  compareToDate: RankedRCAInsights;
  loopsAlgo: RankedRCAInsights;
}

interface OwnProps {
  mode: RCAMode;
  executiveSummary: RCAExecutiveSummary;
  enableScrollToElements?: boolean;
}
type AllProps = OwnProps;

export const RCAExecutiveSummaryOrchestrator: React.FC<AllProps> = (props: AllProps) => {
  const {executiveSummary: executiveSummaryFromProps, mode, enableScrollToElements} = props;
  const {productEntitiesMap} = useProductData();

  const executiveSummary = useMemo(
    () => ({
      [RCAMode.COMPARE_TO_DATE]: executiveSummaryFromProps.compareToDate,
      [RCAMode.LOOPS_ALGO]: executiveSummaryFromProps.loopsAlgo,
      sampledModelInsights: executiveSummaryFromProps.sampledModelInsights,
      sampledModelXoxOverview: executiveSummaryFromProps.sampledModelXoxOverview,
    }),
    [executiveSummaryFromProps]
  );

  const granularity = useMemo(
    () => executiveSummary.sampledModelXoxOverview.granularity,
    [executiveSummary]
  );

  const changeDescriptionAlias = useMemo(() => {
    if (!executiveSummary.sampledModelXoxOverview) {
      return '';
    }
    return CHANGE_DESCRIPTION_ALIAS_MAP[
      executiveSummary.sampledModelXoxOverview.xoxChangeDescription
    ];
  }, [executiveSummary]);

  const {trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight} = useMemo(
    () => ({
      trendInsight: executiveSummary.sampledModelInsights?.trendInsight || null,
      periodAllTimeHighOrLowInsight:
        executiveSummary.sampledModelInsights?.periodAllTimeHighOrLowInsight || null,
      numberOfUsersInsight: executiveSummary.sampledModelInsights?.numberOfUsersInsight || null,
    }),
    [executiveSummary]
  );

  return (
    <>
      {executiveSummary.sampledModelXoxOverview && (
        <SampledModelXoXOverviewSection
          {...executiveSummary.sampledModelXoxOverview}
          changeDescriptionAlias={changeDescriptionAlias}
          className={classes.MaxWidth}
        />
      )}
      <SampledModelSentencesSection
        trendInsight={trendInsight}
        periodAllTimeHighOrLowInsight={periodAllTimeHighOrLowInsight}
        numberOfUsersInsight={numberOfUsersInsight}
        productEntities={productEntitiesMap}
      />
      {executiveSummary[mode] && (
        <RCAInsightsSection
          // This key ensures only the sentences relevant to the mode are being displayed
          key={mode}
          mode={mode}
          changeDescriptionAlias={changeDescriptionAlias}
          enableScrollToElements={enableScrollToElements}
          granularity={granularity}
          explainableSentences={executiveSummary[mode].explainableSentences}
          interestingXoxSentences={executiveSummary[mode].interestingXoxSentences}
          entityExplainerSentences={executiveSummary[mode].entityExplainerSentences}
        />
      )}
    </>
  );
};

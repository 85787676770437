import {action, OnSuccessActionHook} from 'front-core';
import {HomepageSubscription} from '../../objects/models/homepage.model';
import {
  ReorderHomepageDTO,
  UpdateHomepageModelDTO,
  DeleteHomepageSubscriptionDTO,
  HomepageSubscriptionTestDTO,
} from '../../objects/dto/homepage.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';

export enum HomepageActionType {
  ADD_MODEL_TO_HOMEPAGE = '@@homepage/ADD_MODEL_TO_HOMEPAGE',
  REMOVE_MODEL_FROM_HOMEPAGE = '@@homepage/REMOVE_MODEL_FROM_HOMEPAGE',
  UPDATE_HOMEPAGE_SUBSCRIPTION = '@@homepage/UPDATE_HOMEPAGE_SUBSCRIPTION',
  DELETE_HOMEPAGE_SUBSCRIPTION = '@@homepage/DELETE_HOMEPAGE_SUBSCRIPTION',
  CREATE_HOMEPAGE_SUBSCRIPTION = '@@homepage/CREATE_HOMEPAGE_SUBSCRIPTION',
  REORDER_HOMEPAGE = '@@homepage/REORDER_HOMEPAGE',
  TEST_HOMEPAGE_SUBSCRIPTION = '@@homepage/TEST_HOMEPAGE_SUBSCRIPTION',
}

export const addModelToHomepage = (data: UpdateHomepageModelDTO, onSuccess?: OnSuccessActionHook) =>
  action(HomepageActionType.ADD_MODEL_TO_HOMEPAGE, data, {onSuccess});

export const removeModelFromHomepage = (
  data: UpdateHomepageModelDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.REMOVE_MODEL_FROM_HOMEPAGE, data, {onSuccess});

export const updateHomepageSubscription = (
  data: Partial<HomepageSubscription>,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.UPDATE_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

export const deleteHomepageSubscription = (
  data: DeleteHomepageSubscriptionDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.DELETE_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

export const createHomepageSubscription = (
  data: Partial<HomepageSubscription>,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.CREATE_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

export const deleteHomepageSubscriptionConfirmed = (data: DeleteHomepageSubscriptionDTO) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_HOMEPAGE_SUBSCRIPTION.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_HOMEPAGE_SUBSCRIPTION.CONTENT),
    },
    () => [deleteHomepageSubscription(data)]
  );
export const reorderHomepage = (data: ReorderHomepageDTO, onSuccess?: OnSuccessActionHook) =>
  action(HomepageActionType.REORDER_HOMEPAGE, data, {onSuccess});

export const testHomepageSubscription = (
  data: HomepageSubscriptionTestDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.TEST_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

import {Button} from 'ui-components';

import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from './error-viewer.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import {useCallback, useContext} from 'react';
import {ANALYSIS_RESULT_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import classNames from 'classnames';
import {AppErrorFigureProps} from './app-error-viewer.component';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {PanelKey} from '../../../../../../constants/panels';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';

export function Error2004Viewer(props: AppErrorFigureProps) {
  const {analysisResultId} = props;
  const {t} = useTranslation();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const notify = useAmplitude();
  const onDuplicate = useCallback(() => {
    openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
      [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResultId,
    });
    notify(AmplitudeEvent.ANALYSIS_DUPLICATE_CLICKED, {
      analysis_result_id: analysisResultId,
      source: 'error-2004',
    });
  }, [openPrimaryPanel, analysisResultId, notify]);

  return (
    <div className={classes.ErrorFigure}>
      <div className={classes.Error}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.magnifyingGlass} alt={''} />
        </div>
        <div className={classes.Title}>{t(TransKeys.ANALYSIS_ERRORS.ERROR_2004.TITLE)}</div>
        <div className={classNames(classes.Title, classes.Light)}>
          {t(TransKeys.ANALYSIS_ERRORS.ERROR_2004.SUB_TITLE)}
        </div>
        <Button size={'large'} onClick={onDuplicate}>
          {t(TransKeys.ANALYSIS_ERRORS.ERROR_2004.ACTION)}
        </Button>
      </div>
    </div>
  );
}

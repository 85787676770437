import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {get} from 'lodash';
import {LiteralValueType, Select, TextInput, useQueryArray} from 'ui-components';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {ItemList} from '../components/item-list/item-list.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {limitNumberValue} from '../../../../../utils/general.utils';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import classes from './base-habit-moment-query-builder.module.scss';
import {REFERENCE_TYPE_OPTIONS} from './habit-moment-query-builder.component';
import {TIME_UNIT_PLURAL_OPTIONS} from '../../../../../constants/options';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';

interface SchemaKeysMapping {
  signals: string;
  ref_date: string;
  time_window_unit: string;
  time_window_n: string;
  reference_unit: string;
  reference_n: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  signals: 'signals',
  ref_date: 'ref_date',
  time_window_unit: 'time_window_unit',
  time_window_n: 'time_window_n',
  reference_unit: 'reference_unit',
  reference_n: 'reference_n',
};

interface OwnProps {
  value: any;
  schemaKeysMapping?: SchemaKeysMapping;
  onChange?: (parameters: any) => void;
  onSignalInfo?: (value: string | number) => void;
  entityContext?: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const INCLUDE_SIGNALS = [
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.EVENT_GROUPS,
  SignalSmartSelectorKey.SIGNALS,
];
const INCLUDE_REF_DATE = [
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.MILESTONES,
  SignalSmartSelectorKey.SIGNALS,
];

const SIGNAL_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.EVENT];
const REF_DATE_ALLOW_TYPES = [SelectorModelType.SIGNAL, SelectorModelType.COLUMN];

const createSignalFilters = entityContext => ({
  type: SignalType.MEASURE,
  data_type: [SignalDataType.BOOLEAN],
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});
const createColumnFilters = entityContext => ({
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: LiteralValueType.DATE,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});
const createEventFilters = entityContext => ({
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});
const createRefDateSignalFilters = entityContext => ({
  type: SignalType.DIMENSION,
  data_type: SignalDataType.TIMESTAMP,
  entity_binding: TableEntityBinding.DEFAULT,
  entityContext,
});

export const BaseHabitMomentQueryBuilder = (props: AllProps) => {
  const {
    value,
    errors,
    onChange,
    onSignalInfo,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    entityContext,
    disabled,
    className,
  } = props;
  const {getEntityName} = useProductData();

  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.signals, []),
    data => onChange && onChange({[schemaKeysMapping.signals]: data}),
    () => null
  );

  const onSignalChanged = useCallback(
    (signalDefinition: any, idx: number) => {
      const signalDefinitions = [...get(value, schemaKeysMapping.signals, [])];
      signalDefinitions[idx] = signalDefinition;
      onChange && onChange({[schemaKeysMapping.signals]: signalDefinitions});
    },
    [onChange, schemaKeysMapping, value]
  );

  const refDateFilters = useMemo(() => createRefDateSignalFilters(entityContext), [entityContext]);
  const signalFilters = useMemo(() => createSignalFilters(entityContext), [entityContext]);
  const columnFilters = useMemo(() => createColumnFilters(entityContext), [entityContext]);
  const eventFilters = useMemo(() => createEventFilters(entityContext), [entityContext]);

  return (
    <div className={classNames(classes.BaseHabitMomentQueryBuilder, className)}>
      <div className={classes.Row}>
        <div className={classes.Item}>{getEntityName(entityContext, false, true)} performs</div>
      </div>
      <div className={classes.Row}>
        <ItemList
          className={classes.ItemList}
          items={value[schemaKeysMapping.signals]}
          disabled={disabled}
          onRemoveItem={removeElement}
          onAddItem={addElement}
          min1Item
          renderItem={(item, idx) => (
            <TemplateItemQueryBuilder
              query={item}
              onChange={item => onSignalChanged(item, idx)}
              errors={errors?.[schemaKeysMapping.signals]?.[idx]}
              signalFilters={signalFilters}
              signalsInclude={INCLUDE_SIGNALS}
              allowTypes={SIGNAL_ALLOW_TYPES}
              eventFilters={eventFilters}
              disabled={disabled}
              onSignalInfo={onSignalInfo}
            />
          )}
        />
      </div>
      <div className={classes.Row}>
        <div className={classes.Item}>At least</div>
        <div className={classes.Item}>
          <TextInput
            className={classes.Input}
            value={value[schemaKeysMapping.reference_n]}
            error={Boolean(errors?.[schemaKeysMapping.reference_n])}
            onChange={value =>
              onChange &&
              onChange({
                [schemaKeysMapping.reference_n]: limitNumberValue(value as any, 1, 1000000),
              })
            }
            type={'number'}
            disabled={disabled}
          />
        </div>
        <div className={classes.Item}>
          <Select
            value={value[schemaKeysMapping.reference_unit]}
            onChange={value =>
              onChange &&
              onChange({
                [schemaKeysMapping.reference_unit]: value,
              })
            }
            error={Boolean(errors?.[schemaKeysMapping.time_window_unit])}
            options={REFERENCE_TYPE_OPTIONS}
            searchable={false}
            clearable={false}
            capitalize={false}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classes.Row}>
        <div className={classes.Item}>Within</div>
        <div className={classes.Item}>
          <TextInput
            className={classes.Input}
            value={value[schemaKeysMapping.time_window_n]}
            error={Boolean(errors?.[schemaKeysMapping.time_window_n])}
            onChange={value =>
              onChange &&
              onChange({
                [schemaKeysMapping.time_window_n]: limitNumberValue(value as any, 1, 50),
              })
            }
            type={'number'}
            disabled={disabled}
          />
        </div>
        <div className={classes.Item}>
          <Select
            value={value[schemaKeysMapping.time_window_unit]}
            onChange={value =>
              onChange &&
              onChange({
                [schemaKeysMapping.time_window_unit]: value,
              })
            }
            error={Boolean(errors?.[schemaKeysMapping.time_window_unit])}
            options={TIME_UNIT_PLURAL_OPTIONS}
            searchable={false}
            clearable={false}
            capitalize={false}
            disabled={disabled}
          />
        </div>
        <div className={classes.Item}>since</div>
        <div className={classes.Item}>
          <TemplateItemQueryBuilder
            onChange={value => onChange && onChange({[schemaKeysMapping.ref_date]: value})}
            query={value[schemaKeysMapping.ref_date]}
            errors={errors?.[schemaKeysMapping.ref_date]}
            signalFilters={refDateFilters}
            signalsInclude={INCLUDE_REF_DATE}
            columnFilters={columnFilters}
            eventFilters={eventFilters}
            disabled={disabled}
            allowTypes={REF_DATE_ALLOW_TYPES}
            onSignalInfo={onSignalInfo}
          />
        </div>
      </div>
    </div>
  );
};

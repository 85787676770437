import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './health-monitor-main.module.scss';
import TransKeys from 'translations';
import {
  ActionsDropdown,
  AnalysisFileIcon,
  Badge,
  BellIcon,
  Button,
  EditIcon,
  Link,
  MoreIcon,
  SkeletonLoading,
  TrashIcon,
  TrendChip,
} from 'ui-components';
import {CalcSeriesMode} from '../../../../../../constants/metric-series.consts';
import {HealthMonitorGroupList} from './health-monitor-group-list/health-monitor-group-list.component';
import {
  HealthMonitorModel,
  MonitoredMetric,
  MonitoredMetricType,
} from '../../../../../../objects/models/health-monitor.model';
import {useTranslation} from 'react-i18next';
import {exists} from 'front-core';
import {
  SimpleTable,
  TableColumn,
} from '../../../../../shared/components/general/table/table.component';
import {Title} from '../../../../../shared/components/general/title/title.component';
import {
  getLastSampleTrendValue,
  getLastSampleValue,
} from '../../../../../../utils/metric-series.utils';
import {Sparklines, SparklinesLine} from 'react-sparklines';
import {FlexHorizontal} from '../../../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {METRIC_SERIES_MOCK} from '../../../../../../constants/metric-series-mock';

interface OwnProps {
  healthMonitor: HealthMonitorModel;
  selectedMetricId?: number;
  mode?: CalcSeriesMode;
  onCreateMetric: (metricSeriesGroupId: number | null, isNorthStar?: boolean) => void;
  onViewMetric: (monitoredMetricId: number) => void;
  onDeleteMetric: (monitoredMetricId: number) => void;
  onEditMetric: (monitoredMetricId: number) => void;
  onRequestAnalysis: (monitoredMetric: MonitoredMetric) => void;
  onDeleteGroup: (groupId: number) => void;
  onCreateEditGroup: (groupId?: number) => void;
  emptyState?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HealthMonitorMain = (props: AllProps) => {
  const {
    healthMonitor,
    selectedMetricId,
    onCreateMetric,
    onViewMetric,
    onDeleteMetric,
    onEditMetric,
    onCreateEditGroup,
    onDeleteGroup,
    onRequestAnalysis,
    mode,
    emptyState,
    className,
  } = props;
  const {t} = useTranslation();
  const groups = useMemo(() => healthMonitor.groups || [], [healthMonitor]);
  const mainGroup = useMemo(() => groups.find(g => g.isMain === true), [groups]);
  const secondaryGroups = useMemo(() => groups.filter(g => !g.isMain), [groups]);
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.METRIC.TITLE),
        helperText: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.METRIC.HELPER_TEXT),
        width: '40%',
        render: (monitoredMetric: MonitoredMetric) => (
          <FlexHorizontal
            verticalAlignCenter
            spaceBetween
            fullWidth
            className={classes.TitleWrapper}
          >
            <Title
              className={classNames(
                classes.Title,
                !monitoredMetric.metricSeriesId && classes.Muted
              )}
              text={monitoredMetric.name}
              caps={false}
              size={'medium'}
              bold
            />
            {!monitoredMetric.metricSeriesId && (
              <span className={classes.Fetching}>{t(TransKeys.GENERAL.LABELS.FETCHING_DATA)}</span>
            )}
          </FlexHorizontal>
        ),
      },
      {
        key: 'value',
        title: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.VALUE.TITLE),
        helperText: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.VALUE.HELPER_TEXT),
        width: '10rem',
        render: (monitoredMetric: MonitoredMetric) => {
          if (!monitoredMetric.metricSeriesId) {
            return <SkeletonLoading />;
          }
          const value = getLastSampleValue(monitoredMetric);
          return <div className={classes.Value}>{value}</div>;
        },
      },
      {
        key: 'trend',
        title: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.TREND.TITLE),
        helperText: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.TREND.HELPER_TEXT),
        width: '12rem',
        render: (monitoredMetric: MonitoredMetric) => {
          if (!monitoredMetric.metricSeriesId) {
            return <SkeletonLoading />;
          }
          const value = getLastSampleTrendValue(monitoredMetric, mode);
          if (!value) {
            return null;
          }
          return (
            <TrendChip
              value={value.value}
              higherIsBetter={monitoredMetric.higherIsBetter}
              isSignificant={value.isSignificant}
              size={'small'}
            />
          );
        },
      },
      {
        key: 'last_measures',
        title: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.SAMPLES.TITLE),
        helperText: t(TransKeys.HEALTH_MONITOR.TABLE.HEADERS.SAMPLES.HELPER_TEXT),
        align: 'right',
        render: (monitoredMetric: MonitoredMetric) => {
          if (!monitoredMetric.metricSeriesId) {
            return <SkeletonLoading />;
          }
          const samples = (monitoredMetric.samples || []).map(s => s.value);
          return (
            <Sparklines height={40} margin={2} data={samples}>
              <SparklinesLine
                style={{fill: 'none', strokeWidth: 3}}
                color="rgba(137, 142, 168, 1)"
              />
            </Sparklines>
          );
        },
      },
      {
        key: 'alerts',
        title: '',
        align: 'center',
        width: '4rem',
        render: (monitoredMetric: MonitoredMetric) => {
          if (!exists(monitoredMetric.phenomenasCount) || monitoredMetric.phenomenasCount === 0) {
            return null;
          }
          return (
            <FlexHorizontal horizontalAlignCenter verticalAlignCenter>
              <Badge badgeContent={monitoredMetric.phenomenasCount} alert>
                <BellIcon className={classes.AlertIcon} />
              </Badge>
            </FlexHorizontal>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        align: 'right',
        width: '0',
        render: (monitoredMetric: MonitoredMetric) => {
          return (
            <ActionsDropdown
              actions={[
                {
                  key: 'edit',
                  title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                  onClick: () => onEditMetric(monitoredMetric.id),
                  icon: EditIcon,
                },
                {
                  key: 'delete',
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDeleteMetric(monitoredMetric.id),
                  icon: TrashIcon,
                },
                {
                  key: 'run_rca',
                  title: t(TransKeys.HEALTH_MONITOR.ACTIONS.RUN_RCA),
                  onClick: () => {
                    onRequestAnalysis(monitoredMetric);
                  },
                  icon: AnalysisFileIcon,
                  disabled: monitoredMetric.metricType !== MonitoredMetricType.KPI,
                  helperText:
                    monitoredMetric.metricType !== MonitoredMetricType.KPI
                      ? t(
                          TransKeys.HEALTH_MONITOR.ACTIONS
                            .RUN_RCA_ON_NON_KPI_MONITORED_METRIC_MESSAGE
                        )
                      : '',
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              buttonComponent={props => <MoreIcon {...props} className={classes.MoreIcon} />}
            />
          );
        },
      },
    ],
    [t, mode, onDeleteMetric, onEditMetric, onRequestAnalysis]
  );

  return (
    <div className={classNames(classes.HealthMonitorMain, className)}>
      <div className={classes.Section}>
        <div className={classes.Block}>
          <SimpleTable
            data={emptyState ? METRIC_SERIES_MOCK : mainGroup?.series || []}
            columns={columns}
            dataKey={'id'}
            onRowClicked={item => onViewMetric(item.id)}
            selectedItemKey={selectedMetricId}
          />
          <Link className={classes.AddMetricButton} onClick={() => onCreateMetric(null, false)}>
            + {t(TransKeys.GENERAL.ACTIONS.ADD_METRIC)}
          </Link>
        </div>
      </div>
      <div className={classes.Section}>
        {secondaryGroups.map((g, idx) => (
          <HealthMonitorGroupList
            key={g.id}
            group={g}
            columns={columns}
            onClick={id => onViewMetric(id)}
            onAddMetric={() => onCreateMetric(g.id)}
            onRemoveGroup={() => onDeleteGroup(g.id)}
            onEditGroup={() => onCreateEditGroup(g.id)}
            className={classes.MetricSeriesGroupList}
            selectedMetricId={selectedMetricId}
          />
        ))}
        <div className={classes.SectionTitle}>
          <Button variant={'outlined'} onClick={() => onCreateEditGroup()}>
            {t(TransKeys.GENERAL.ACTIONS.CREATE_GROUP)}
          </Button>
        </div>
      </div>
    </div>
  );
};

import TransKeys from 'translations';
import {useRCASegmentationFollowUpAnalysesForSegment} from '../../hooks/use-rca-segmentation-follow-up-analyses-for-segment.hook';
import {Button} from '../../../../../../../../simple/controls/button/button.component';
import {isEmpty} from 'lodash';
import {SegmentExplainersTableRCASegmentFollowUpData} from '../segment-explainers-table/components/explainers-table/components/explainers-table-item/explainers-table-item.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import classes from './run-analysis-container.module.scss';
import {useCallback} from 'react';
import {SegmentFilter} from '../../../../../../contexts/global-document-data/global-document-data.types';

type RunAnalysisContainerProps = {
  segmentFilters: SegmentFilter[];
  segmentLabel: string;
  kpiName: string;
  onViewFollowUpRCAForSegment: (analysisId: number, analysisResultId: number) => void;
  onRunFollowUpRCAForSegment: (data: SegmentExplainersTableRCASegmentFollowUpData) => void;
};

export const RunAnalysisContainer = (props: RunAnalysisContainerProps) => {
  const {
    segmentFilters,
    segmentLabel,
    kpiName,
    onViewFollowUpRCAForSegment,
    onRunFollowUpRCAForSegment,
  } = props;

  const {t} = useDocumentTranslation();
  const followUpAnalysesForSegment = useRCASegmentationFollowUpAnalysesForSegment(segmentFilters);
  const hasFollowUpRCA = !isEmpty(followUpAnalysesForSegment);

  const handleClick = useCallback(() => {
    if (hasFollowUpRCA) {
      onViewFollowUpRCAForSegment(
        followUpAnalysesForSegment[0].analysisId,
        followUpAnalysesForSegment[0].analysisResultId
      );
      return;
    }
    onRunFollowUpRCAForSegment({
      selectedSegmentFilters: segmentFilters,
    });
  }, [
    hasFollowUpRCA,
    followUpAnalysesForSegment,
    onViewFollowUpRCAForSegment,
    onRunFollowUpRCAForSegment,
    segmentFilters,
  ]);

  const label = hasFollowUpRCA
    ? t(
        TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.VIEW_ANALYSIS_BUTTON
          .LABEL
      )
    : t(
        TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.RUN_ANALYSIS_BUTTON
          .LABEL
      );

  return (
    <div className={classes.RunAnalysisContainer}>
      <div className={classes.RunAnalysisText}>
        {t(
          TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.RUN_ANALYSIS_TEXT,
          {
            kpiName,
            segmentLabel,
          }
        )}
      </div>
      <Button variant={'contained'} onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
};

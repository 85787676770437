import {FigureElement} from './figures.types';
import {DocumentElementType} from './document.types';
import {DocumentCommand} from './commands.types';
import {DocumentIconType} from './general.types';
import {Sorting} from 'front-core';

export enum ColumnType {
  DEFAULT = 'DEFAULT',
  LABEL_VALUE = 'LABEL_VALUE',
  NUMERIC = 'NUMERIC',
  CHANGE = 'CHANGE',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  INFO = 'INFO',
  PROGRESS = 'PROGRESS',
  SWITCH_COLUMN = 'SWITCH_COLUMN',
  CONFIDENCE_INTERVAL = 'CONFIDENCE_INTERVAL',
  GRADIENT = 'GRADIENT',
  COMMAND = 'COMMAND',
  SENTENCE = 'SENTENCE',
  VERSUS = 'VERSUS',
  OUT_OF = 'OUT_OF',
  SHARE_OF_TOTAL = 'SHARE_OF_TOTAL',
  TREND = 'TREND',
  SEQUENCE = 'SEQUENCE',
  DURATION = 'DURATION',
  ADOPTION_RATE = 'ADOPTION_RATE',
  VIEW_FIGURE = 'VIEW_FIGURE',
  _RENDER_COLUMN = '_RENDER_COLUMN', // internal use
}

export interface DefaultColumnOptions {
  valueDataKey?: string;
  commandDataKey?: string;
}

export interface LabelValueColumnOptions {
  labelDataKey?: string;
  labelDataTitle?: string;
  valueDataKey?: string;
  valueDataTitle?: string;
  commandDataKey?: string;
}

export interface ProgressColumnOptions {
  totalDataKey: string;
  totalDataTitle?: string;
  valueDataKey?: string;
  valueDataTitle?: string;
  gradient?: boolean;
}

export interface ConfidenceIntervalColumnOptions {
  lowerDataKey?: string;
  lowerDataTitle?: string;
  valueDataKey?: string;
  valueDataTitle?: string;
  upperDataKey?: string;
  upperDataTitle?: string;
  higherIsBetter?: boolean;
}

export interface ChangeColumnOptions {
  higherIsBetter?: boolean;
  threshold?: number;
  fromValueDataKey?: number;
  toValueDataKey?: number;
  fromToValueSuffix?: string;
}

export interface SwitchColumnOptions {
  columns: SmartTableFigureColumn<any>[];
}

export interface DateColumnOptions {
  dateFormat?: string;
  dateInputFormat?: string;
  endDateDataKey?: string;
  valueDataKey?: string;
}

export interface GradientColumnOptions {
  min?: number;
  zero?: number;
  max?: number;
  higherIsBetter?: boolean;
}

export interface NumericColumnOptions {
  lowerDataKey?: string;
  lowerDataTitle?: string;
  valueDataKey?: string;
  valueDataTitle?: string;
  upperDataKey?: string;
  upperDataTitle?: string;
  icon?: DocumentIconType;
}

export interface VersusColumnOptions {
  valueDataKey?: string;
  valueDataTitle?: string;
  versusDataKey: string;
  versusDataTitle?: string;
  mode?: 'change' | 'difference';
  higherIsBetter?: boolean;
  hideIcon?: boolean;
}

export interface OutOfColumnOptions {
  valueDataKey?: string;
  valueDataTitle?: string;
  outOfDataKey: string;
  outOfDataTitle?: string;
}

export interface ShareOfTotalColumnOptions {
  total: number;
  suffix?: string;
}

export interface TrendColumnOptions {
  valueDataKey?: string;
  valueDataTitle?: string;
  previousDataKey: string;
  previousDataTitle?: string;
  mode?: 'change' | 'difference';
  higherIsBetter?: boolean;
}

export interface SequenceColumnOptions {
  mode?: 'arrow' | 'comma';
  commandDataKey?: string;
}

export interface AdoptionRateOptions {
  valueDataKey: string;
  totalDataKey: string;
  totalSuffix?: string;
}

export interface CommandColumnOptions {
  buttonVariant?: 'primary' | 'secondary';
  buttonType?: 'icon' | 'button';
  helperTextDataKey?: string;
}

export interface ViewFigureColumnOptions {
  buttonText?: string;
  modalTitleDataKey?: string;
  figureDataKey: string;
  width?: string;
  height: string;
}

export interface ColumnOptions {
  sortable?: boolean;
  filterable?: boolean;
  width?: string;
  unit?: string;
  helperText?: string;
  placeholderText?: string;
  align?: 'left' | 'right' | 'center';
  bold?: boolean;
  color?: string;
  showOnHover?: boolean;
  borderRight?: boolean;
  noPadding?: boolean;
  suffixRight?: string | ((item: any) => string);
  helperTextDataKey?: string;
  icon?: DocumentIconType;
  // used in special cases when we want to export different data key (mostly for render columns)
  exportDataKey?: string;
}

export interface SmartTableFigureColumn<T> {
  key?: string;
  title: string;
  subTitle?: string;
  type: ColumnType;
  typeOptions?: T;
  secondary?: boolean;
  options?: ColumnOptions;
  // internal use
  asType?: ColumnType; // used for _render_column to mimic actual column behavior
  metadata?: any;
  render?: (item: any) => any;
  contentClassName?: string;
  hidden?: boolean;
}

export interface DataColumnOptions {
  color?: string;
  unit?: string;
}

export interface SmartTableFigureDataOptions {
  color?: string;
  columns?: {
    [columnKey: string]: DataColumnOptions;
  };
}

export interface TableEmptyState {
  title: string;
  subTitle?: string;
  buttonText?: string;
  buttonCommand?: DocumentCommand<any>;
}

export enum SmartTableFigurePaginationMode {
  PAGES = 'pages',
  TOP_X = 'top_x',
}

export interface SmartTableFigureOptions {
  perPage?: number; // initial limit
  pagination?: boolean;
  paginationMode?: SmartTableFigurePaginationMode;
  significantDataKey?: string;
  groupByDataKey?: string;
  groupByLabel?: string;
  partitionByDataKey?: string;
  partitionShowDataKey?: string;
  highlightIds?: string[];
  data?: {
    [dataKey: string]: SmartTableFigureDataOptions;
  };
  defaultSort?: Sorting;
  emptyState?: TableEmptyState | JSX.Element;
  bottomMessage?: any;
  headMessage?: any;
  stickyFooter?: boolean;
  clickCommandDataKey?: string;
}

export interface TablePartitionOptions {
  startClosed?: boolean;
  color?: string;
}

export interface TablePartition {
  key: string;
  name: string;
  options?: TablePartitionOptions;
  commands?: DocumentCommand<any>[];
}

export enum SPECIAL_SMART_TABLE_DATA_KEYS {
  DISABLED = '__disabled',
  ROW_CLASS_NAME = '__row_class_name',
}

export interface TableDataItem {
  // internal data items keys
  [SPECIAL_SMART_TABLE_DATA_KEYS.DISABLED]?: boolean; // disable onClick
  [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]?: string; // row class name per item
  // exposed data items keys
  [other: string]: any;
}

export interface SmartTableFigure extends FigureElement<TableDataItem[], SmartTableFigureOptions> {
  type: DocumentElementType.SMART_TABLE;
  columns: Array<SmartTableFigureColumn<any>>;
  partitions?: Array<TablePartition>;
  dataKey?: string;
}

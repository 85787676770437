import {useMemo} from 'react';
import {LabelWrapper, LiteralValueType, Select} from 'ui-components';
import classes from '../table-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useFormContext, Controller} from 'react-hook-form';
import {
  GroupByTimeStrategy,
  TableDefinition,
  TableDefinitionType,
  TableEntity,
  TableParameters,
  TableType,
} from '../../../../../objects/models/table.model';
import {sharedClasses} from '../../../../shared';
import {ApplicationError, exists} from 'front-core';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {SourceTableSmartSelector} from '../../../../shared/core/smart-selector/source-table-smart-selector.component';
import {SourceTableColumnSmartSelector} from '../../../../shared/core/smart-selector/source-table-column-smart-selector.component';
import {SourceTable} from '../../../../../objects/models/source-table.model';
import {SourceTableColumn} from '../../../../../objects/models/source-table-column.model';
import TableEntityTypeField from '../table-form-panel-shared-fields/table-entity-type-field.component';
import {TabHeader} from '../../../../shared/components/general/tab-header/tab-header.component';
import {contactSupport} from '../../../../../utils/general.utils';
import {NoteText} from '../../../../shared/components/general/note-text/note-text.component';
import {useCallback} from 'react';
import {isEmpty, values} from 'lodash';
import {AGG_TO_TEXT} from '../table-form-panel.component';
import TableIsMainField from '../table-form-panel-shared-fields/table-is-main-field.component';
import TableTypeInput from '../../../../shared/form/components/table-type-input/table-type-field.component';

interface OwnProps {
  setEntity: (entity: TableEntity) => void;
  setIsMain: (isMain: boolean) => void;
  resetParameters: () => void;
  resetMeta: () => void;
  setDefinition: (definition: Partial<TableDefinition>) => void;
  setParameters: (parameters: Partial<TableParameters>) => void;
  onSourceTableDiscovery: () => void;
  setMeta: (meta: any) => void;
  submitError: ApplicationError;
  editMode?: boolean;
}

type AllProps = OwnProps;

const TableTab = (props: AllProps) => {
  const {
    setEntity,
    setIsMain,
    resetParameters,
    resetMeta,
    setDefinition,
    setParameters,
    setMeta,
    onSourceTableDiscovery,
    submitError,
    editMode,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();

  const {
    formState: {errors},
    trigger,
    watch,
    setValue,
    control,
  } = useFormContext();
  const entity = watch('entity');
  const tableType = watch('type');
  const uiMetadata = watch('uiMetadata');
  const parameters = watch('parameters');
  const groupByTimeStrategyOptions = useMemo(
    () => ({
      options: values(GroupByTimeStrategy).map(v => ({
        value: v,
        label: AGG_TO_TEXT[v],
      })),
    }),
    []
  );

  const onSourceTableSelect = useCallback(
    (value: number, sourceTable: SourceTable) => {
      setMeta({
        sourceTableId: value,
        sourceTableType: sourceTable?.source?.type,
        sourceTableColumnEntityId: undefined,
        sourceTableColumnEventNameId: undefined,
        sourceTableColumnEventTimestampId: undefined,
      });
      setValue('sourceId', sourceTable?.source?.id);
      setDefinition({
        name: sourceTable?.name,
        prefix: sourceTable?.prefix,
      });
      resetParameters();
    },
    [setMeta, setValue, setDefinition, resetParameters]
  );
  const onEntityIdTableSourceColumnSelect = useCallback(
    (value, sourceTableColumn: SourceTableColumn) => {
      setMeta({
        sourceTableColumnEntityId: value,
      });
      setParameters({
        entity_id: sourceTableColumn?.name,
      });
    },
    [setMeta, setParameters]
  );
  const onEventNameIdTableSourceColumnSelect = useCallback(
    (value, sourceTableColumn) => {
      setMeta({
        sourceTableColumnEventNameId: value,
      });
      setParameters({
        event_name: sourceTableColumn?.name,
      });
    },
    [setMeta, setParameters]
  );
  const onEventTimestampIdTableSourceColumnSelect = useCallback(
    (value, sourceTableColumn) => {
      setMeta({
        sourceTableColumnEventTimestampId: value,
      });
      setParameters({
        timestamp: sourceTableColumn?.name,
      });
    },
    [setMeta, setParameters]
  );
  const onStartAtTableSourceColumnSelect = useCallback(
    (value, sourceTableColumn) => {
      setMeta({
        sourceTableColumnStartAtId: value,
      });
      setParameters({
        start_at: sourceTableColumn?.name,
      });
    },
    [setMeta, setParameters]
  );
  const onTypeChange = useCallback(
    (tableType: TableType) => {
      setValue('type', tableType);
      trigger('type');
      resetParameters();
      resetMeta();
    },
    [resetParameters, resetMeta, setValue, trigger]
  );

  const sourceTableEmptyState = useMemo(
    () => ({
      title: t(TransKeys.TABLE_FORM.SOURCE_TABLE_EMPTY_STATE.TITLE),
      subTitle: t(TransKeys.TABLE_FORM.SOURCE_TABLE_EMPTY_STATE.SUB_TITLE),
      buttonText: t(TransKeys.TABLE_FORM.SOURCE_TABLE_EMPTY_STATE.BUTTON_TEXT),
      onClick: onSourceTableDiscovery,
    }),
    [onSourceTableDiscovery, t]
  );

  return (
    <div className={classes.TableScopeTab} key={TableDefinitionType.TABLE}>
      {!isEmpty(submitError) && <div className={classes.Error}>{submitError.message}</div>}
      <TabHeader
        title={t(TransKeys.TABLE_FORM.TABS.TABLE_TAB.TITLE)}
        subTitle={t(TransKeys.TABLE_FORM.TABS.TABLE_TAB.SUB_TITLE)}
        size={'small'}
      />
      <div className={sharedClasses.Block}>
        <LabelWrapper
          error={exists(errors?.type)}
          label={t(TransKeys.TABLE_FORM.INPUTS.TABLE_TYPE.LABEL)}
          className={classes.LabelWrapper}
        >
          <Controller
            name={'type'}
            control={control}
            render={({field}) => (
              <TableTypeInput value={field.value} onChange={onTypeChange} disabled={editMode} />
            )}
          />
          {tableType === TableType.ENTITY_PROPERTIES && (
            <Controller
              name={'parameters.is_main'}
              control={control}
              render={({field}) => (
                <TableIsMainField
                  value={field.value as unknown as boolean}
                  onChange={setIsMain}
                  disabled={!uiMetadata?.sourceTableId}
                />
              )}
            />
          )}
        </LabelWrapper>
        <LabelWrapper
          label={t(TransKeys.TABLE_FORM.INPUTS.SOURCE_TABLE.LABEL)}
          className={classes.LabelWrapper}
        >
          <SourceTableSmartSelector
            error={exists(errors?.definition?.name?.message)}
            value={uiMetadata?.sourceTableId}
            placeholder={t(TransKeys.TABLE_FORM.INPUTS.SOURCE_TABLE.PLACEHOLDER)}
            onChange={onSourceTableSelect}
            emptyState={sourceTableEmptyState}
          />
        </LabelWrapper>
      </div>
      <div className={sharedClasses.Block}>
        {uiMetadata?.sourceTableId && tableType && (
          <>
            <Controller
              name={'entity'}
              control={control}
              render={({field}) => (
                <TableEntityTypeField
                  value={field.value}
                  onChange={setEntity}
                  error={exists(errors?.entity)}
                  disabled={editMode}
                />
              )}
            />
            <LabelWrapper
              label={t(TransKeys.TABLE_FORM.INPUTS.ENTITY_ID.LABEL, {
                entity: (entity && productEntitiesMap[entity].name.toLowerCase()) || 'entity',
              })}
              className={classes.LabelWrapper}
            >
              <SourceTableColumnSmartSelector
                error={exists(errors?.parameters?.entity_id)}
                value={uiMetadata?.sourceTableColumnEntityId}
                placeholder={t(TransKeys.TABLE_FORM.INPUTS.ENTITY_ID.PLACEHOLDER)}
                onChange={onEntityIdTableSourceColumnSelect}
                filters={{
                  sourceTableId: uiMetadata?.sourceTableId,
                  literalType: [
                    LiteralValueType.STRING,
                    LiteralValueType.INTEGER,
                    LiteralValueType.FLOAT,
                  ],
                }}
              />
            </LabelWrapper>
          </>
        )}
      </div>
      {tableType === TableType.EVENTS && uiMetadata?.sourceTableId && (
        <div className={sharedClasses.Block}>
          <LabelWrapper
            label={t(TransKeys.TABLE_FORM.INPUTS.EVENT_NAME.LABEL)}
            className={classes.LabelWrapper}
          >
            <SourceTableColumnSmartSelector
              error={exists(errors?.parameters?.event_name)}
              value={uiMetadata?.sourceTableColumnEventNameId}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.EVENT_NAME.PLACEHOLDER)}
              onChange={onEventNameIdTableSourceColumnSelect}
              filters={{
                sourceTableId: uiMetadata?.sourceTableId,
                literalType: LiteralValueType.STRING,
              }}
            />
          </LabelWrapper>
          <LabelWrapper
            label={t(TransKeys.TABLE_FORM.INPUTS.TIMESTAMP.LABEL)}
            className={classes.LabelWrapper}
          >
            <SourceTableColumnSmartSelector
              error={exists(errors?.parameters?.timestamp)}
              value={uiMetadata?.sourceTableColumnEventTimestampId}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.TIMESTAMP.PLACEHOLDER)}
              onChange={onEventTimestampIdTableSourceColumnSelect}
              filters={{
                sourceTableId: uiMetadata?.sourceTableId,
                literalType: LiteralValueType.DATE,
              }}
            />
          </LabelWrapper>
        </div>
      )}
      {tableType === TableType.STATE && uiMetadata?.sourceTableId && (
        <div className={sharedClasses.Block}>
          <LabelWrapper
            label={t(TransKeys.TABLE_FORM.INPUTS.START_AT.LABEL)}
            className={classes.LabelWrapper}
          >
            <SourceTableColumnSmartSelector
              error={exists(errors?.parameters?.start_at)}
              value={uiMetadata?.sourceTableColumnStartAtId}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.START_AT.PLACEHOLDER)}
              onChange={onStartAtTableSourceColumnSelect}
              filters={{
                sourceTableId: uiMetadata?.sourceTableId,
                literalType: LiteralValueType.DATE,
              }}
            />
          </LabelWrapper>
          <LabelWrapper
            label={t(TransKeys.TABLE_FORM.INPUTS.TIME_AGG.LABEL)}
            className={classes.LabelWrapper}
          >
            <Select
              error={exists(errors?.parameters?.group_by_time_strategy)}
              value={parameters?.group_by_time_strategy}
              onChange={(v: any) => setParameters({group_by_time_strategy: v})}
              options={groupByTimeStrategyOptions}
              placeholder={t(TransKeys.TABLE_FORM.INPUTS.TIME_AGG.PLACEHOLDER)}
              searchable={false}
              sortValues={false}
              clearable={false}
            />
          </LabelWrapper>
        </div>
      )}
      <NoteText
        text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
        buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
        onButtonClicked={contactSupport}
      />
    </div>
  );
};

export default TableTab;

import * as React from 'react';
import {useContext, useMemo} from 'react';
import {GlobalDocumentDataContext, RCAMode} from 'ui-components';
import {RCAExecutiveSummery} from './analyses-executive-summaries/rca-executive-summery/rca-executive-summery.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {AnalysisResultStatus} from '../../../../../../objects/models/analysis-result.model';
import {Team} from '../../../../../../objects/models/team.model';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../constants/feature-flags.ts';

interface OwnProps {
  className?: string;
  rcaMode?: RCAMode;
  funnelTargetSignalId?: number;
  subscriptionId?: number;
}

type AllProps = OwnProps;

export const AppExecutiveSummeryViewer = (props: AllProps) => {
  const {subscriptionId, rcaMode, className} = props;
  const data = useContext(GlobalDocumentDataContext);
  const showFunnelRCAExecSum = useFeatureIsOn(FeatureFlag.FUNNEL_RCA_EXEC_SUM as string);

  const rcaSharedProps = useMemo(
    () => ({
      mode: rcaMode,
      resultId: data.analysisResultId,
      resultStatus: data.analysisResultStatus as AnalysisResultStatus,
      teamId: data.teamId,
      setTeamId: data.setTeamId,
      teams: data.teams as Team[],
      subscriptionId: subscriptionId,
      teamSelectorDisabled: data.teamSelectorDisabled,
      className: className,
    }),
    [rcaMode, subscriptionId, data, className]
  );

  switch (data.analysisTypeId) {
    case AnalysisTypeId.RCA_ANALYSIS:
      return <RCAExecutiveSummery {...rcaSharedProps} />;
    case AnalysisTypeId.FUNNEL_RCA:
      return showFunnelRCAExecSum ? (
        <RCAExecutiveSummery
          {...rcaSharedProps}
          funnelTargetSignalId={props.funnelTargetSignalId}
        />
      ) : null;
    default:
      return null;
  }
};

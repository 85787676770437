import {
  EnvelopeLightIcon,
  LabelWrapper,
  SlackIcon,
  TextInput,
  useRemoteSourceStated,
} from 'ui-components';
import {FormLabelInput} from '../../../../../shared/form/components/form-label-input.component';
import classes from './homepage-quick-subscription-form.module.scss';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import {useCallback, useEffect} from 'react';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {exists} from 'front-core';
import {SubscribedSlackChannelsInput} from '../../../../panels/homepage-subscription-panel/components/subscribed-slack-channels-input/subscribed-slack-channels-input.component';
import {getSlackChannelsNetworkRequest} from '../../../../../../http/slack-channels.network-requests';
import {GenericLoading} from 'ui-components/src/components/simple/utils/generic-loading/generic-loading.component';

interface Props {}

export const HomepageQuickSubscriptionForm = (props: Props) => {
  const notify = useAmplitude();
  const currentUser = useCurrentUser();

  const notifyAmplitudeEvent = useCallback(
    (value: string) => {
      if (exists(value?.trim()) && value !== currentUser.email) {
        notify(AmplitudeEvent.ATTEMPT_CREATE_SUBSCRIPTION_WITH_OTHER_EMAIL);
      }
    },
    [notify, currentUser]
  );

  const {
    isLoading,
    exec: getSlackChannels,
    source: channels,
  } = useRemoteSourceStated({
    networkRequest: getSlackChannelsNetworkRequest,
  });

  useEffect(() => {
    getSlackChannels();
  }, [getSlackChannels]);

  if (isLoading) {
    return <GenericLoading />;
  }

  return (
    <div className={classes.HomepageQuickSubscriptionFormContainer}>
      <FormLabelInput
        name="emails"
        render={(field, fieldState) => (
          <TextInput
            placeholder={'Add email'}
            value={field.value}
            onChange={field.onChange}
            onBlur={notifyAmplitudeEvent}
            error={Boolean(fieldState.error)}
            required
            multiple
          />
        )}
        label={
          <div className={classes.InputLabel}>
            <EnvelopeLightIcon className={classes.Icon} /> Email
          </div>
        }
      />
      <LabelWrapper
        label={
          <div className={classes.InputLabel}>
            <SlackIcon className={classes.Icon} /> Slack
          </div>
        }
      >
        <SubscribedSlackChannelsInput channels={channels} />
      </LabelWrapper>
    </div>
  );
};

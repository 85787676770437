import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './tables-main.module.scss';
import {
  queryFilter,
  tableEntityFilterGenerator,
  tableTypeFilter,
} from '../../../../constants/filters';
import {tableToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {getTablesNetworkRequest} from '../../../../http/tables.network-requests';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {
  FlexHorizontal,
  FlexVertical,
} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {Table} from '../../../../objects/models/table.model';
import {
  Button,
  CopyIcon,
  EditIcon,
  HoverHelperTip,
  IconButton,
  MoreIcon,
  RadarLightIcon,
  TableListDuotoneIcon,
} from 'ui-components';
import {TableColumn} from '../../../shared/components/general/table/table.component';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {useDispatch, useSelector} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {TABLE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {isNumber, keys} from 'lodash';
import {generateLastModified} from '../../../../utils/history.utils';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import moment from 'moment';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {withStopPropagation} from '../../../../utils/general.utils';
import {withMetadata} from 'front-core';
import {
  triggerScanAllTypesOfTables,
  triggerTableDiscovery,
} from '../../../../store/tables/tables.actions';
import {AppSources, SOURCE_META_KEY} from '../../../../constants/app-sources';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import {Chip} from 'ui-components';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {getListCount} from '../../../../store/store.selectors';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `TABLES_MAIN/TABLES`;

export const TablesMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {productEntities, productEntitiesMap} = useProductData();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const user = useCurrentUser();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'updatedOn',
      order: 'desc',
      itemsPerPage: 50,
    }),
    []
  );
  const total = useSelector(state => getListCount(createListKey())(state));
  const title = useMemo(
    () => `${t(TransKeys.TABLES.HEADER.TITLE)} ${isNumber(total) ? ` (${total})` : ''}`,
    [t, total]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter(), tableTypeFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);

  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getTablesNetworkRequest,
      onError: err => [tableToastCreator('GET_ERROR')],
      modelKey: ModelKey.TABLE,
    }),
    []
  );

  const showTable = useCallback(
    (tableId: number) =>
      openPrimaryPanel(
        PanelKey.VIEW_TABLE_PANEL,
        {[TABLE_ID_PATH_PARAM]: tableId},
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );
  const onCreateEditTable = useCallback(
    (table, parameters: any = {}) => {
      openPrimaryPanel(
        PanelKey.TABLE_FORM_PANEL,
        {
          [TABLE_ID_PATH_PARAM]: table?.id,
          ...parameters,
        },
        PanelType.MODAL
      );
      if (!table) {
        notify(AmplitudeEvent.TABLE_CREATE_CLICKED, {
          userId: user.id,
        });
      }
      if (table && parameters.cloneMode) {
        notify(AmplitudeEvent.TABLE_DUPLICATE_CLICKED, {
          userId: user.id,
        });
      }
      if (table && !parameters.cloneMode) {
        notify(AmplitudeEvent.TABLE_EDIT_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onRescanTable = useCallback(
    table => {
      dispatch(
        withMetadata(triggerTableDiscovery(table.id), {
          [SOURCE_META_KEY]: AppSources.TABLE_FORM_PANEL,
        })
      );
    },
    [dispatch]
  );
  const onRescan = useCallback(() => {
    dispatch(
      withMetadata(triggerScanAllTypesOfTables(), {
        [SOURCE_META_KEY]: AppSources.TABLE_FORM_PANEL,
      })
    );
  }, [dispatch]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        sortable: true,
        render: (table: Table) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <Title className={classes.Title} text={table.name} caps={false} />
            {table.shortDescription && <HoverHelperTip title={table.shortDescription} />}
            {table.parameters.is_main && <Chip size={'small'} label={'main'} />}
          </FlexHorizontal>
        ),
      },
      {
        key: 'type',
        title: t(TransKeys.GENERAL.HEADERS.TYPE),
        width: '16rem',
        sortable: true,
        render: (table: Table) => (
          <FlexHorizontal spacing verticalAlignCenter>
            {t(TransKeys.TABLES.TYPE[table.type.toUpperCase()])}
          </FlexHorizontal>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '16rem',
        sortable: true,
        hidden: keys(productEntitiesMap).length < 2,
        render: (table: Table) => (
          <FlexHorizontal spacing verticalAlignCenter className={classes.TitleWrapper}>
            <TitleWithIcon
              className={classes.Entity}
              titleClassName={classes.Entity}
              icon={getEntityIcon(table.entity)}
              text={productEntitiesMap[table.entity]?.name}
            />
          </FlexHorizontal>
        ),
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        width: '16rem',
        sortable: true,
        render: (table: Table) => {
          const modifier = generateLastModified(table.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(table.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        sticky: 'right',
        align: 'right',
        render: (table: Table) => (
          <div className={classes.Actions}>
            <Permission subject={Subject.TABLE} action={Action.EDIT}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                icon={EditIcon}
                onClick={withStopPropagation(() => onCreateEditTable(table))}
              />
            </Permission>
            <Permission subject={Subject.FEATURE} action={Action.CREATE}>
              <IconButton
                className={classes.Button}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                icon={CopyIcon}
                onClick={withStopPropagation(() => onCreateEditTable(table, {cloneMode: true}))}
              />
            </Permission>
            <ModelActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'rescan',
                  title: t(TransKeys.GENERAL.ACTIONS.RESCAN_TABLE),
                  onClick: () => onRescanTable(table),
                  icon: RadarLightIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [t, onCreateEditTable, productEntitiesMap, onRescanTable]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.TABLE) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [dispatch, config]);

  return (
    <MainTableStructure
      className={classes.TablesMain}
      title={title}
      icon={TableListDuotoneIcon}
      onRowClicked={table => showTable(table.id)}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      helperTextTitle={t(TransKeys.TABLES.HEADER.HELPER_TEXT)}
      emptyStateTranslationPath={TransKeys.TABLES.EMPTY_STATE}
      headerRenderRight={
        <>
          <Permission subject={Subject.TABLE} action={Action.CREATE}>
            <Button onClick={onCreateEditTable}>{t(TransKeys.GENERAL.ACTIONS.CREATE_TABLE)}</Button>
          </Permission>
          <ModelActionsDropdown
            className={classes.HeaderDDButton}
            actions={[
              {
                key: 'rescan',
                title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                onClick: onRescan,
                icon: RadarLightIcon,
              },
            ]}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
        </>
      }
      itemsPerPage={50}
    />
  );
};

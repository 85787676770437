import {MetricSampleData, RCAMetric, RCAMode} from '../../../../types';
import {exists, safeDivision} from 'front-core';
import moment from 'moment';

const GOOD_COLOR = 'green';
const BAD_COLOR = 'red';

export const isSignificantValue = (value: number, lower: number, upper: number) =>
  exists(upper) && exists(lower) ? value > upper || value < lower : false;

export const RCA_DATE_FORMAT = 'YYYY-MM-DD';

export const generateMainDSForChart = (config: {
  samples: MetricSampleData[];
  name: string;
  isPercentageValue: boolean;
  higherIsBetter: boolean;
  analyzedDate?: string;
  comparedDate?: string;
  showMarkColors: boolean;
}) => {
  const {
    samples,
    name,
    isPercentageValue,
    analyzedDate,
    comparedDate,
    showMarkColors = true,
    higherIsBetter = true,
  } = config;
  const data = samples.map(d => {
    const additions = {};
    const isSignificant = isSignificantValue(d.value, d.lower, d.upper);
    const ciExists = exists(d.lower) && exists(d.upper);
    if (isSignificant && !d.isPartial && showMarkColors && ciExists) {
      if (d.value > d.upper) {
        additions['markColor'] = higherIsBetter ? GOOD_COLOR : BAD_COLOR;
        additions['pointTooltipTitle'] = '● Significant Spike';
      } else {
        additions['markColor'] = higherIsBetter ? BAD_COLOR : GOOD_COLOR;
        additions['pointTooltipTitle'] = '● Significant Drop';
      }
    }
    if (
      analyzedDate &&
      moment.utc(analyzedDate, RCA_DATE_FORMAT).isSame(moment.utc(d.datetime, RCA_DATE_FORMAT))
    ) {
      additions['isTrend'] = true;
      additions['trendDescription'] = 'ANALYZED TREND';
      additions['trendColor'] = '#AB55F8';
    }
    if (
      comparedDate &&
      moment.utc(comparedDate, RCA_DATE_FORMAT).isSame(moment.utc(d.datetime, RCA_DATE_FORMAT))
    ) {
      additions['isTrend'] = true;
      additions['trendDescription'] = 'BASELINE';
      additions['trendColor'] = '#45ACCC';
    }
    if (ciExists) {
      additions['upper'] = isPercentageValue ? d.upper * 100 : d.upper;
      additions['lower'] = isPercentageValue ? d.lower * 100 : d.lower;
    }
    return {
      x: d.datetime,
      y: isPercentageValue ? d.value * 100 : d.value,
      dashed: d.isPartial,
      metadata: {sample: d},
      clickable: !d.isPartial,
      ...additions,
    };
  });

  return {
    id: `value_${name.toLowerCase()}`,
    label: name,
    dashedDescription: 'Incomplete data',
    data,
  };
};

export const calcMetricUplift = (
  metric: RCAMetric,
  analyzedDate: string,
  comparedDate?: string,
  mode: RCAMode = RCAMode.LOOPS_ALGO
) => {
  let uplift;
  let isSignificant = false;
  let comparedDate_ = comparedDate;
  const analyzedSample = metric.samples.find(sample => {
    return moment
      .utc(sample.datetime, RCA_DATE_FORMAT)
      .isSame(moment.utc(analyzedDate, RCA_DATE_FORMAT));
  });
  if (!exists(comparedDate_) && analyzedSample?.datetime) {
    comparedDate_ = moment
      .utc(analyzedSample.datetime, RCA_DATE_FORMAT)
      .subtract(1, metric.granularity)
      .format(RCA_DATE_FORMAT);
  }
  const comparedSample = metric.samples.find(sample => {
    return moment
      .utc(sample.datetime, RCA_DATE_FORMAT)
      .isSame(moment.utc(comparedDate_, RCA_DATE_FORMAT));
  });
  if (mode === RCAMode.LOOPS_ALGO && analyzedSample) {
    isSignificant = isSignificantValue(
      analyzedSample.value,
      analyzedSample.lower,
      analyzedSample.upper
    );
  }
  if (comparedSample) {
    uplift = safeDivision(analyzedSample.value - comparedSample.value, comparedSample.value);
  }

  return {
    uplift,
    isSignificant,
  };
};

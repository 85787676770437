import classNames from 'classnames';
import classes from './select-kpi-message.module.scss';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import {UserChooseKPIMessageData} from '../../../../../../../../objects/models/ai-chat.model';
import {DropdownButton, PopoverWrapper, SmartSelector, useRemoteSourceStated} from 'ui-components';
import {multiLoaderNetworkRequest} from '../../../../../../../../http/multi-loader.network-requests';
import {GenericLoading} from '../../../../../../../shared/components/general/generic-loading/generic-loading.component';
import {useEffect, useRef} from 'react';
import {values} from 'lodash';
import {useMetricSelectorProps} from '../../../../../../../shared/core/smart-selector/metric-smart-selector/use-metric-selector-props.hook';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface Props extends BaseMessageRendererProps<UserChooseKPIMessageData> {
  className?: string;
}

export const SelectKPIMessage = (props: Props) => {
  const {message, onSubmitMessage, className} = props;
  const {t} = useTranslation();

  const selectorRef = useRef<any>();

  const {sources, load} = useMetricSelectorProps({
    onChange: (value, item) => {
      onSubmitMessage(item.name);
      selectorRef.current?.close();
    },
  });

  const {
    source: loadedResponse,
    exec: callMultiLoad,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: multiLoaderNetworkRequest,
    initialValue: {},
  });
  const metricOptions = values(loadedResponse?.metrics || {}) || [];

  useEffect(() => {
    callMultiLoad({
      metrics: message.content.metricIds,
    });
  }, [message.content.metricIds, callMultiLoad]);

  if (isLoading) {
    return <GenericLoading />;
  }

  return (
    <div className={classNames(classes.SelectKPIMessage, className)}>
      {/*todo translate*/}
      <div className={classes.Title}>Please Select a KPI</div>
      <div className={classes.Options}>
        {metricOptions.map(mo => (
          <div className={classes.Option} onClick={() => onSubmitMessage(mo.name)} key={mo.id}>
            {mo.name}
          </div>
        ))}
        <PopoverWrapper
          ref={selectorRef}
          popoverDirection={'left'}
          buttonRenderer={({onClick}) => (
            <DropdownButton
              onClick={onClick}
              label={t(TransKeys.GEN_AI_BOT.SELECT_KPI_MESSAGE.SELECT_OTHER_KPI)}
            />
          )}
        >
          <SmartSelector
            className={classes.Selector}
            sources={sources}
            load={load}
            withPreview={false}
          />
        </PopoverWrapper>
      </div>
    </div>
  );
};

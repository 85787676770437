import classNames from 'classnames';
import classes from './app-user-modal.module.scss';
import {
  Checkbox,
  DialogLayout,
  InviteUserMenuIcon,
  Menu,
  MenuItem,
  SettingsRegularIcon,
  ArrowRightFromBracketRegularIcon,
  BoxTapedRegularIcon,
  UsersIcon,
} from 'ui-components';
import {User, UserRole} from '../../../../objects/models/user.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {ProductSelect} from './product-select.component';
import {Link} from 'react-router';
import {AppRoutes} from '../../../../constants/app-routes';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {PermissionLabel} from '../../../shared/core/permissions/permission-label.component';
import {Team} from '../../../../objects/models/team.model';
import {TeamSelect} from './team-select.component';
import {useMemo} from 'react';

interface OwnProps {
  user: User;
  teams: Team[];
  defaultTeamId?: number;
  scopeProductId?: number;
  onScopeChange: (id: number) => void;
  onChangeDefaultTeam: (id: number) => void;
  onLogout: () => void;
  onInvite: () => void;
  adminPrivileges: boolean;
  onChangeAdminPrivileges: (value: boolean) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AppUserModal = (props: AllProps) => {
  const {
    user,
    teams,
    scopeProductId,
    defaultTeamId,
    onScopeChange,
    onChangeDefaultTeam,
    onLogout,
    onInvite,
    adminPrivileges,
    onChangeAdminPrivileges,
    className,
  } = props;
  const {t} = useTranslation();
  const {role} = usePermissions();
  const hasActualTeam = useMemo(
    () => teams.filter(t => t.isDefaultTeam === false).length > 0,
    [teams]
  );

  return (
    <DialogLayout className={classNames(className, classes.UserModal)}>
      <Menu>
        <MenuItem heading>
          <div className={classes.UserDisplay}>
            <div className={classes.Name}>
              {user.firstName} {user.lastName}
            </div>
            <PermissionLabel className={classes.Role} role={role} />
          </div>
        </MenuItem>
        <MenuItem subheading>{t(TransKeys.USER_PANEL.PRODUCT_LABEL)}</MenuItem>
        <MenuItem Icon={BoxTapedRegularIcon}>
          <ProductSelect
            user={user}
            scopeProductId={scopeProductId}
            onScopeChange={onScopeChange}
          />
        </MenuItem>
        {hasActualTeam && (
          <>
            <MenuItem subheading>{t(TransKeys.USER_PANEL.TEAM_LABEL)}</MenuItem>
            <MenuItem Icon={defaultTeamId ? undefined : UsersIcon}>
              <TeamSelect
                selectedTeamId={defaultTeamId}
                teams={teams}
                onTeamSelected={onChangeDefaultTeam}
              />
            </MenuItem>
          </>
        )}
        <MenuItem subheading>{t(TransKeys.GENERAL.LABELS.USER)}</MenuItem>
        <MenuItem Icon={InviteUserMenuIcon} onClick={onInvite}>
          {t(TransKeys.NAVIGATOR.INVITE_USERS)}
        </MenuItem>
        <Link to={AppRoutes.settingsNotifications().pathname}>
          <MenuItem Icon={SettingsRegularIcon} clickable>
            {t(TransKeys.GENERAL.LABELS.SETTINGS)}
          </MenuItem>
        </Link>
        <MenuItem Icon={ArrowRightFromBracketRegularIcon} onClick={onLogout}>
          {t(TransKeys.USER_PANEL.LOGOUT)}
        </MenuItem>
        {user.role === UserRole.ADMIN && (
          <div className={classes.AdminPrivileges}>
            <Checkbox
              checked={adminPrivileges}
              onChange={onChangeAdminPrivileges}
              label={'Admin privileges?'}
            />
          </div>
        )}
      </Menu>
    </DialogLayout>
  );
};

import {action, OnSuccessActionHook} from 'front-core';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {DataPipelineDTO, ReorderDataPipelineDTO} from '../../objects/dto/data-pipeline.dto';

export enum DataPipelineActionType {
  CREATE_DATA_PIPELINE = '@@data-pipelines/CREATE_DATA_PIPELINE',
  UPDATE_DATA_PIPELINE = '@@data-pipelines/UPDATE_DATA_PIPELINE',
  DELETE_DATA_PIPELINE = '@@data-pipelines/DELETE_DATA_PIPELINE',
  REORDER_DATA_PIPELINE = '@@data-pipelines/REORDER_DATA_PIPELINE',
}

export const createDataPipeline = (data: DataPipelineDTO, onSuccess?: OnSuccessActionHook) =>
  action(DataPipelineActionType.CREATE_DATA_PIPELINE, data, {onSuccess});

export const updateDataPipeline = (data: DataPipelineDTO, onSuccess?: OnSuccessActionHook) =>
  action(DataPipelineActionType.UPDATE_DATA_PIPELINE, data, {onSuccess});

export const deleteDataPipeline = (id: number) =>
  action(DataPipelineActionType.DELETE_DATA_PIPELINE, id);

export const deleteDataPipelineConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_DATA_PIPELINE.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_DATA_PIPELINE.CONTENT),
    },
    () => [deleteDataPipeline(id)]
  );

export const reorderDataPipeline = (data: ReorderDataPipelineDTO) =>
  action(DataPipelineActionType.REORDER_DATA_PIPELINE, data);

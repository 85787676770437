import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {exists, HttpClientContext} from 'front-core';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {
  AICMessageFeedback,
  AIConversationMessageType,
  AIConversationRole,
  AIConversationThread,
} from '../../../../objects/models/ai-chat.model';
import {
  askAIChatNetworkRequest,
  deleteAIChartThreadNetworkRequest,
  getAIChartThreadNetworkRequest,
  getAIChartThreadsNetworkRequest,
  sendAIChatMessageFeedbackNetworkRequest,
  updateAIChartThreadNetworkRequest,
} from '../../../../http/ai-chart.network-requests';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {ThreadsList} from './components/threads-list/threads-list.component';
import {ChatMessages} from './components/chat-messages/chat-messages.component';
import {UserTextInput} from './components/user-text-input/user-text-input.component';
import classes from './chat-bot-main.module.scss';
import {MODEL_PANEL_MAPPING} from './chat-bot.utils';
import {ModelType} from 'ui-components';
import {NumberParam, StringParam, useQueryParam} from 'use-query-params';
import {PanelKey} from '../../../../constants/panels';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {ImagesResource} from '../../../../assets/images';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook.ts';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const TEMP_ID = -1;

export const ChatBotMain = (props: AllProps) => {
  const http = useContext(HttpClientContext);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const isAdmin = useIsAdmin();
  const [message, setMessage] = useState('');
  const [selectedThreadId, setSelectedThreadId] = useQueryParam('id', NumberParam);
  const [messageQueryParam, setMessageQueryParam] = useQueryParam('message', StringParam);
  const [activeThread, setActiveThread_] = useState<AIConversationThread>(null);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [threads, setThreads] = useState(null);
  const initialSet = useRef(false);
  const onSubmitMessageRef = useRef(null);

  const setActiveThread = useCallback(
    thread => {
      setActiveThread_(thread);
      if (thread) {
        setSelectedThreadId(thread.id);
      } else {
        setSelectedThreadId(undefined);
      }
      setMessage('');
    },
    [setActiveThread_, setMessage, setSelectedThreadId]
  );
  const getThreads = useCallback(async () => {
    setIsLoadingThreads(true);
    const threads = await http.exec(getAIChartThreadsNetworkRequest());
    setThreads(threads as any);
    setIsLoadingThreads(false);
  }, [http, setThreads, setIsLoadingThreads]);
  const onSubmitMessage = useCallback(
    async message => {
      setMessage('');
      setActiveThread(thread => ({
        ...(thread || {}),
        messages: [
          ...(thread?.messages || []),
          {
            id: TEMP_ID,
            type: AIConversationMessageType.TEXT,
            role: AIConversationRole.USER,
            content: message,
            createdOn: moment.utc().format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          },
        ],
      }));
      setIsLoadingAnswer(true);
      const res: any = await http.exec(
        askAIChatNetworkRequest({
          aiConversionThreadId: activeThread?.id,
          message,
        })
      );
      setActiveThread(thread => ({
        ...(thread || {}),
        ...res.thread,
        messages: [...thread.messages.filter(m => m.id !== TEMP_ID), ...(res.newMessages as any)],
      }));
      setIsLoadingAnswer(false);
      if (activeThread === null) {
        getThreads();
      } else {
        setThreads(threads => threads.map(t => (t.id === activeThread.id ? res.thread : t)));
      }
    },
    [http, setActiveThread, setIsLoadingAnswer, setThreads, activeThread, getThreads]
  );
  onSubmitMessageRef.current = onSubmitMessage;

  const getThread = useCallback(
    async (threadId: number) => {
      const thread = await http.exec(getAIChartThreadNetworkRequest(threadId));
      setActiveThread(thread as any);
    },
    [setActiveThread, http]
  );
  const startNewSession = useCallback(() => setActiveThread(null), [setActiveThread]);
  const onMessageFeedback = useCallback(
    async (messageId: number, feedback: AICMessageFeedback) => {
      const message: any = await http.exec(
        sendAIChatMessageFeedbackNetworkRequest({
          aiConversationMessageId: messageId,
          feedback,
        })
      );
      setActiveThread(thread => ({
        ...thread,
        messages: thread.messages.map(m => (m.id === messageId ? message : m)),
      }));
    },
    [http, setActiveThread]
  );

  const onModelReference = useCallback(
    (modelId: number, modelType: ModelType) => {
      const info = MODEL_PANEL_MAPPING[modelType];
      openPrimaryPanel(info.panel, {
        [info.param]: modelId,
      });
    },
    [openPrimaryPanel]
  );
  const onRequestAnalysis = useCallback(
    (analysisTypeId: number, parameters: any) => {
      openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
        parameters: parameters,
      });
    },
    [openPrimaryPanel]
  );

  const onDeleteThread = useCallback(
    async (threadId: number) => {
      setActiveThread(null);
      await http.exec(deleteAIChartThreadNetworkRequest(threadId));
      setThreads(threads => threads.filter(t => t.id !== threadId));
    },
    [setActiveThread, setThreads, http]
  );

  const onEditTitle = useCallback(
    async (threadId: number, title: string) => {
      const {thread}: any = await http.exec(
        updateAIChartThreadNetworkRequest({
          threadId,
          title,
        })
      );
      setThreads(threads => threads.map(t => (t.id === threadId ? thread : t)));
    },
    [setThreads, http]
  );

  const inputDisabled = useMemo(
    () => exists(activeThread?.completedOn) || isLoadingAnswer,
    [activeThread, isLoadingAnswer]
  );

  useEffect(() => {
    getThreads();
  }, [getThreads]);

  useEffect(() => {
    if (messageQueryParam) {
      setMessageQueryParam(undefined);
      onSubmitMessageRef.current(decodeURIComponent(messageQueryParam));
    }
  }, [onSubmitMessageRef, messageQueryParam, setMessageQueryParam]);

  useEffect(() => {
    if (initialSet.current) {
      return;
    }
    let thread: any = null;
    if (selectedThreadId && threads && threads.length > 0) {
      thread = threads.find(t => t.id === selectedThreadId);
    }
    if (thread || isAdmin) {
      getThread(selectedThreadId);
    }
    if (threads) {
      initialSet.current = true;
    }
  }, [selectedThreadId, threads, getThread, isAdmin]);

  return (
    <div className={classes.ChatBotMain}>
      <div className={classes.ChatBotHeader}>
        <div className={classes.LoopsAIIcon}>
          <img src={ImagesResource.app.loopsAIAssistant} className={classes.LoopsAIAssistantIcon} />
        </div>
        <div className={classes.ChatbotHeaderTitle}>Loops Assistant</div>
      </div>
      <div className={classes.ChatBotMainContent}>
        <ThreadsList
          onNewSession={startNewSession}
          threads={threads || []}
          onSelectThread={getThread}
          selectedThreadId={activeThread?.id}
          className={classes.Threads}
          onDeleteThread={onDeleteThread}
          onEditThreadTitle={onEditTitle}
          isLoading={isLoadingThreads}
        />
        <div className={classes.ChatWrapper}>
          <ChatMessages
            thread={activeThread}
            className={classes.Messages}
            isLoading={isLoadingAnswer}
            onMessageFeedback={onMessageFeedback}
            onModelReference={onModelReference}
            onSubmitMessage={onSubmitMessage}
            onRequestAnalysis={onRequestAnalysis}
          />
          {!exists(activeThread?.completedOn) && (
            <UserTextInput
              className={classes.UserTextInput}
              value={message}
              onChange={setMessage}
              onSubmit={() => onSubmitMessage(message)}
              disabled={inputDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

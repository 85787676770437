import {SourceWizardHeader} from '../source-wizard-header/source-wizard-header.component';
import {SourceWizardPageLayout} from '../source-wizard-page-layout/source-wizard-page-layout.component';
import classes from './connection-success-screen.module.scss';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {ImagesResource} from '../../../../../../assets/images';

type ConnectionSuccessScreenProps = {
  unsupportedSource?: boolean;
};
export const ConnectionSuccessScreen = (props: ConnectionSuccessScreenProps) => {
  const {unsupportedSource} = props;
  const {t} = useTranslation();

  const unsupportedSourceContent = useMemo(() => {
    return {
      title: t(TransKeys.SOURCE_WIZARD.SUCCESSFUL_CONNECTION_SCREEN.UNSUPPORTED_SOURCE.TITLE),
      subtitle: t(TransKeys.SOURCE_WIZARD.SUCCESSFUL_CONNECTION_SCREEN.UNSUPPORTED_SOURCE.SUBTITLE),
    };
  }, [t]);

  const supportedSourceContent = useMemo(() => {
    return {
      title: t(TransKeys.SOURCE_WIZARD.SUCCESSFUL_CONNECTION_SCREEN.SUPPORTED_SOURCE.TITLE),
      subtitle: t(TransKeys.SOURCE_WIZARD.SUCCESSFUL_CONNECTION_SCREEN.SUPPORTED_SOURCE.SUBTITLE),
    };
  }, [t]);

  const {title, subtitle} = unsupportedSource ? unsupportedSourceContent : supportedSourceContent;

  return (
    <SourceWizardPageLayout contentClassName={classes.SuccessScreenContent}>
      <SourceWizardHeader
        title={title}
        subtitle={subtitle}
        illustration={<img src={ImagesResource.app.dataSourceConnectedSuccessfully} />}
      />
    </SourceWizardPageLayout>
  );
};

import {NRC} from './index.ts';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto.ts';
import {DataPipelineDTO, ReorderDataPipelineDTO} from '../objects/dto/data-pipeline.dto.ts';

export const getDataPipelinesNetworkRequest: NRC<void, any> = (payload: any) => ({
  method: RequestType.GET,
  relativeUrl: `/app/data-pipelines`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getDataPipelineNetworkRequest: NRC<void, any> = (
  dataPipelineId: number,
  query: any
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/data-pipelines/${dataPipelineId}`,
  query: query,
  queryTransformer: toSnakeCase,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const createDataPipelineNetworkRequest: NRC<DataPipelineDTO, void> = (
  data: DataPipelineDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/data-pipelines`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const editDataPipelineNetworkRequest: NRC<DataPipelineDTO, void> = (
  data: DataPipelineDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/data-pipelines/${data.id}`,
  body: data,
  bodyTransformer: data => toSnakeCase(data),
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteDataPipelineNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/data-pipelines/${id}`,
  responseTransformer: toCamelCase,
});

export const reorderDataPipelineNetworkRequest: NRC<ReorderDataPipelineDTO, void> = (
  data: ReorderDataPipelineDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/data-pipelines/reorder`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

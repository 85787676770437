import {useCallback, useMemo, useState} from 'react';
import {ConditionOperator, LiteralValueType, Select, WarningIcon} from 'ui-components';
import classes from './shared-builder-parts.module.scss';
import {LiteralValueSelector} from './literal-value-selector.component';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

const OP_OPTIONS_BY_LITERAL_TYPE = {
  [LiteralValueType.STRING]: [
    ConditionOperator.EQ,
    ConditionOperator.NE,
    ConditionOperator.LIKE,
    ConditionOperator.ILIKE,
    ConditionOperator.NLIKE,
    ConditionOperator.NILIKE,
    ConditionOperator.IN,
    ConditionOperator.NIN,
    ConditionOperator.IS_NULL,
    ConditionOperator.IS_NOT_NULL,
    ConditionOperator.NIN_OR_NULL,
  ],
  [LiteralValueType.INTEGER]: [
    ConditionOperator.EQ,
    ConditionOperator.NE,
    ConditionOperator.GT,
    ConditionOperator.GTE,
    ConditionOperator.LT,
    ConditionOperator.LTE,
    ConditionOperator.IN,
    ConditionOperator.NIN,
    ConditionOperator.IS_NULL,
    ConditionOperator.IS_NOT_NULL,
    ConditionOperator.NIN_OR_NULL,
  ],
  [LiteralValueType.FLOAT]: [
    ConditionOperator.EQ,
    ConditionOperator.NE,
    ConditionOperator.GT,
    ConditionOperator.GTE,
    ConditionOperator.LT,
    ConditionOperator.LTE,
    ConditionOperator.IN,
    ConditionOperator.NIN,
    ConditionOperator.IS_NULL,
    ConditionOperator.IS_NOT_NULL,
    ConditionOperator.NIN_OR_NULL,
  ],
  [LiteralValueType.DATE]: [
    ConditionOperator.EQ,
    ConditionOperator.GT,
    ConditionOperator.GTE,
    ConditionOperator.LT,
    ConditionOperator.LTE,
    ConditionOperator.IS_NULL,
    ConditionOperator.IS_NOT_NULL,
  ],
  [LiteralValueType.BOOLEAN]: [
    ConditionOperator.EQ,
    ConditionOperator.IS_NULL,
    ConditionOperator.IS_NOT_NULL,
  ],
};

const DATE_TO_OP = {
  [ConditionOperator.EQ]: 'at',
  [ConditionOperator.GT]: 'after',
  [ConditionOperator.GTE]: 'after or equals',
  [ConditionOperator.LT]: 'before',
  [ConditionOperator.LTE]: 'before or equals',
};

interface OwnProps {
  op: ConditionOperator;
  type: LiteralValueType;
  value: any;
  error?: boolean;
  onValueChange?: (value: any) => void;
  onOpChange?: (value: ConditionOperator) => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const NO_RIGHT_ELEMENT = [ConditionOperator.IS_NULL, ConditionOperator.IS_NOT_NULL];

const mapOpForType = (op: ConditionOperator, type: LiteralValueType): string => {
  if (type === LiteralValueType.BOOLEAN && op === ConditionOperator.EQ) {
    return 'is';
  }
  if (type === LiteralValueType.DATE) {
    return DATE_TO_OP[op] || op;
  }
  return op;
};

export const OpValueSelector = (props: AllProps) => {
  const {
    op,
    type,
    value,
    error,
    onOpChange,
    onValueChange: onValueChangeFromProps,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();
  const [showWarning, setShowWarning] = useState(false);

  const opOptions = useMemo(() => {
    if (!(type in OP_OPTIONS_BY_LITERAL_TYPE)) {
      return [];
    }
    return OP_OPTIONS_BY_LITERAL_TYPE[type].map(op => ({
      label: mapOpForType(op, type),
      value: op,
    }));
  }, [type]);
  const opSelectorOptions = useMemo(() => ({options: opOptions}), [opOptions]);
  const hideRight = useMemo(() => NO_RIGHT_ELEMENT.indexOf(op) > -1, [op]);
  const warningTooltipText = useMemo(() => {
    if (op === ConditionOperator.LIKE) {
      return t(TransKeys.OP_VALUE_SELECTOR.LIKE_OPERATOR_TOOLTIP_WARNING);
    }
    if (op === ConditionOperator.ILIKE) {
      return t(TransKeys.OP_VALUE_SELECTOR.ILIKE_OPERATOR_TOOLTIP_WARNING);
    }
  }, [op, t]);

  const onValueChange = useCallback(
    value => {
      setShowWarning(false);
      onValueChangeFromProps(value);
    },
    [setShowWarning, onValueChangeFromProps]
  );
  const onValueBlur = useCallback(() => {
    if (
      [ConditionOperator.LIKE, ConditionOperator.ILIKE, ConditionOperator.NILIKE].includes(op) &&
      value &&
      !value.includes('%')
    ) {
      setShowWarning(true);
    }
  }, [op, value]);

  return (
    <>
      <div className={classNames(classes.Item, className)}>
        {opOptions.length === 1 && `${mapOpForType(op, type)}`}
        {opOptions.length > 1 && (
          <Select
            value={op}
            dropdownButtonClassName={classes.OpBtn}
            onChange={onOpChange as any}
            options={opSelectorOptions}
            searchable={false}
            clearable={false}
            disabled={disabled}
            capitalize={false}
          />
        )}
      </div>
      {!hideRight && (
        <>
          <LiteralValueSelector
            className={className}
            value={value}
            type={type}
            op={op}
            onChange={onValueChange}
            error={error}
            disabled={disabled}
            onBlur={onValueBlur}
          />
          {showWarning && (
            <Tooltip placement={'top'} title={warningTooltipText}>
              <WarningIcon className={classes.Icon} />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

import {
  AggregationFunction,
  BinaryOperator,
  ConditionOperator,
  DateBinaryOperator,
  LiteralValueType,
  NumericFunctionType,
  Part,
  SqlElementType,
  WindowFunctionType,
  ExtendedSqlElementType,
} from './query-builder.types';
import {ConditionBuilder} from './builders/condition-builder.component';
import {AndOrConditionBuilder} from './builders/and-or-condition-builder.component';
import {LiteralBuilder} from './builders/literal-builder.component';
import {BinaryOpBuilder} from './builders/binary-op-builder.component';
import {DateBinaryOpBuilder} from './builders/date-binary-op-builder.component';
import {DatePartBuilder} from './builders/date-part-builder.component';
import {NullIfBuilder} from './builders/null-if-builder.component';
import {AggregationBuilder} from './builders/aggregation-builder.component';
import {CasesBuilder} from './builders/cases-builder.component';
import {CoalesceBuilder} from './builders/coalesce-builder.component';
import {NumericFunctionBuilder} from './builders/numeric-function-builder.component';
import {RawQueryBuilder} from './builders/raw-query-builder.component';
import {ImplicitColumnBuilder} from './builders/implicit-column-builder.component';
import {TableColumnBuilder} from './builders/table-column-builder.component';
import {SignalColumnBuilder} from './builders/signal-column-builder.component';
import {TransformerBuilder} from './builders/transformer-builder.component';
import {TemplateBuilder} from './builders/template-builder.component';
import {WindowBuilder} from './builders/window-builder.component';
import {SegmentConditionBuilder} from './extended-builders/segment-condition-builder.component';
import {FillNullBuilder} from './builders/fill-null-builder.component';

export const PAYLOAD_SPECIAL_KEY = 'ui_metadata';

export const CONDITION_TYPES = [
  ExtendedSqlElementType.SEGMENT_CONDITION,
  SqlElementType.CONDITION,
  SqlElementType.AND_CONDITION,
  SqlElementType.OR_CONDITION,
];

export const queryBuilderDefaultComponents: any = {
  [SqlElementType.CASES]: {
    component: CasesBuilder,
    name: 'Cases',
  },
  [SqlElementType.CONDITION]: {
    component: ConditionBuilder,
    name: 'Condition',
  },
  [SqlElementType.IMPLICIT_COLUMN]: {
    component: ImplicitColumnBuilder,
    name: 'Implicit Column',
  },
  [SqlElementType.LITERAL]: {
    component: LiteralBuilder,
    name: 'Literal',
  },
  [SqlElementType.OR_CONDITION]: {
    component: AndOrConditionBuilder,
    name: 'Or',
  },
  [SqlElementType.AND_CONDITION]: {
    component: AndOrConditionBuilder,
    name: 'And',
  },
  [SqlElementType.NULLIF]: {
    component: NullIfBuilder,
    name: 'Null If',
  },
  [SqlElementType.AGGREGATION]: {
    component: AggregationBuilder,
    name: 'Aggregation',
  },
  [SqlElementType.NUMERIC_FUNCTION]: {
    component: NumericFunctionBuilder,
    name: 'Numeric Function',
  },
  [SqlElementType.BINARY_OPERATION]: {
    component: BinaryOpBuilder,
    name: 'Binary Operation',
  },
  [SqlElementType.COALESCE]: {
    component: CoalesceBuilder,
    name: 'Coalesce',
  },
  [SqlElementType.FILL_NULL]: {
    component: FillNullBuilder,
    name: 'Fill Null',
  },
  [SqlElementType.DATE_PART]: {
    component: DatePartBuilder,
    name: 'Date Part',
  },
  [SqlElementType.DATE_BINARY_OPERATION]: {
    component: DateBinaryOpBuilder,
    name: 'Date Binary Operation',
  },
  [SqlElementType.RAW_QUERY]: {
    component: RawQueryBuilder,
    name: 'Raw Query',
  },
  [SqlElementType.TABLE_COLUMN]: {
    component: TableColumnBuilder,
    name: 'Table Column',
  },
  [SqlElementType.SIGNAL_COLUMN]: {
    component: SignalColumnBuilder,
    name: 'Signal Column',
  },
  [SqlElementType.TEMPLATE]: {
    component: TemplateBuilder,
    name: 'Template',
  },
  [SqlElementType.WINDOW]: {
    component: WindowBuilder,
    name: 'Window',
  },
  [SqlElementType.TRANSFORMER]: {
    component: TransformerBuilder,
    name: 'Transformer',
  },
  [ExtendedSqlElementType.SEGMENT_CONDITION]: {
    component: SegmentConditionBuilder,
    name: 'Segment',
  },
  [ExtendedSqlElementType.NULL]: {
    component: LiteralBuilder,
    name: 'Null',
  },
};

export const LiteralTypeName: any = {
  [LiteralValueType.STRING]: 'String',
  [LiteralValueType.STRING_LIST]: 'String List',
  [LiteralValueType.BOOLEAN]: 'Boolean',
  [LiteralValueType.DATE]: 'Date',
  [LiteralValueType.FLOAT]: 'Float',
  [LiteralValueType.FLOAT_LIST]: 'Float List',
  [LiteralValueType.INTEGER]: 'Integer',
  [LiteralValueType.INTEGER_LIST]: 'Integer List',
  [LiteralValueType.NULL]: 'Null',
};

export const BinaryOperatorName: any = {
  [BinaryOperator.ADD]: 'Add',
  [BinaryOperator.SUB]: 'Sub',
  [BinaryOperator.MUL]: 'Mul',
  [BinaryOperator.MOD]: 'Mod',
  [BinaryOperator.DIV]: 'Div',
  [BinaryOperator.POW]: 'Pow',
};

export const DateBinaryOperatorName: any = {
  [DateBinaryOperator.ADD]: 'Add',
  [DateBinaryOperator.SUB]: 'Sub',
  [DateBinaryOperator.DIFF]: 'Diff',
};

export const DatePartName: any = {
  [Part.DAY]: 'Day',
  [Part.WEEK]: 'Week',
  [Part.MONTH]: 'Month',
  [Part.YEAR]: 'Year',
};

export const NumericFunctionTypeName: any = {
  [NumericFunctionType.GREATEST]: 'Greatest',
  [NumericFunctionType.LEAST]: 'Least',
};

export const WindowFunctionTypeName: any = {
  [WindowFunctionType.SUM]: 'Sum',
  [WindowFunctionType.RANK]: 'Rank',
  [WindowFunctionType.DENSE_RANK]: 'Dense rank',
  [WindowFunctionType.FIRST_VALUE]: 'First value',
  [WindowFunctionType.LAST_VALUE]: 'Last Value',
  [WindowFunctionType.LAG]: 'Lag',
  [WindowFunctionType.LEAD]: 'lead',
};

export const ConditionOperatorName: any = {
  [ConditionOperator.EQ]: '==',
  [ConditionOperator.NE]: '!=',
  [ConditionOperator.IS]: 'is',
  [ConditionOperator.GT]: '>',
  [ConditionOperator.GTE]: '>=',
  [ConditionOperator.LT]: '<',
  [ConditionOperator.LTE]: '<=',
  [ConditionOperator.LIKE]: 'like',
  [ConditionOperator.ILIKE]: 'ilike',
  [ConditionOperator.NLIKE]: 'not like',
  [ConditionOperator.NILIKE]: 'not ilike',
  [ConditionOperator.IN]: 'in',
  [ConditionOperator.NIN]: 'not in',
  [ConditionOperator.BETWEEN]: 'between',
  [ConditionOperator.IS_NULL]: 'is null',
  [ConditionOperator.IS_NOT_NULL]: 'is not null',
  [ConditionOperator.NIN_OR_NULL]: 'not in or null',
};

export const AggregationFunctionName: any = {
  [AggregationFunction.MAX]: 'Max',
  [AggregationFunction.SUM]: 'Sum',
  [AggregationFunction.MIN]: 'Min',
  [AggregationFunction.AVG]: 'Avg',
  [AggregationFunction.COUNT_DISTINCT]: 'Count distinct',
};

export const BOOLEAN_OPTIONS = [
  {value: true, label: 'True'},
  {value: false, label: 'False'},
];

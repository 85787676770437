import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {Funnel, FunnelStep} from '../../../../objects/models/funnel.model';
import {PrimaryTabs} from 'ui-components';
import classes from './funnel-steps-definition.module.scss';
import TransKeys from 'translations';
import {useHiddenFlag} from '../../../../core/hooks/use-hidden-flag.hook';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import {ViewModelSeries} from '../../../dmp/components/view-model-series/view-model-series.component';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';

export type FunnelDefinitionProps = {
  funnel: Funnel | null;
  signalId?: number;
  initialTab?: string;
};

export const FunnelStepsDefinition = (props: FunnelDefinitionProps) => {
  const {funnel, signalId, initialTab} = props;

  const {t} = useTranslation();
  const showHidden = useHiddenFlag();
  const [selectedTabKey, setSelectedTabKey] = useState(initialTab ?? 'query');
  const [selectedFunnelTab, setSelectedFunnelTab] = useState<string>(
    signalId
      ? funnel.steps.find(s => s.id === signalId).id.toString()
      : funnel.steps[0].id.toString()
  );

  const funnelTabs = useMemo(
    () =>
      funnel?.steps.map((step: FunnelStep) => ({
        key: step.id.toString(),
        title: step.name,
        warningText:
          step.signalValidationStatus === ValidationStatus.ERROR
            ? t(TransKeys.FUNNELS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)
            : null,
        render: () => {
          const query = step.definition;
          const signalId = step.id;

          return (
            <PrimaryTabs
              key={signalId}
              selected={selectedTabKey}
              onChange={setSelectedTabKey}
              tabs={[
                {
                  label: 'Definition',
                  key: 'query',
                  render: () => (
                    <div className={classes.TabWrapper}>
                      <QueryDefinition query={query} entityContext={funnel.entity} />
                    </div>
                  ),
                },
                {
                  label: 'SQL',
                  key: 'sql',
                  render: () => (
                    <div className={classes.TabWrapper}>
                      <SignalSql signalId={signalId} />
                    </div>
                  ),
                },
                {
                  label: 'Samples (admin only)',
                  key: 'samples',
                  hide: !showHidden,
                  render: () => (
                    <div className={classes.TabWrapper}>
                      <ViewModelSeries
                        modelType={ModelSampleSeriesModel.FUNNEL}
                        modelId={funnel.id}
                        entity={funnel.entity}
                        signalId={signalId}
                      />
                    </div>
                  ),
                },
              ]}
            />
          );
        },
      })),
    [funnel, showHidden, selectedTabKey, t]
  );

  return (
    <FunnelTabs
      tabs={funnelTabs}
      onTabChange={setSelectedFunnelTab}
      selectedKey={selectedFunnelTab}
    />
  );
};

import {Tooltip} from '@material-ui/core';
import {exists} from 'front-core';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {TeamDTO} from '../../../../../../objects/dto/team.dto';
import {TeamIconChip, WarningIcon} from 'ui-components';
import classes from './team-name-cell.module.scss';

type TeamNameCellProps = {team: TeamDTO};

export const TeamNameCell = (props: TeamNameCellProps) => {
  const {team} = props;

  const {t} = useTranslation();

  const warningMessage = useMemo(() => {
    const hasSegments = exists(team.segmentsCount) && team.segmentsCount > 0;
    const hasMetrics = exists(team.metricsCount) && team.metricsCount > 0;

    if (!hasMetrics && !hasSegments) {
      return t(TransKeys.ACCOUNT_SETTINGS.TEAMS.WARNINGS.SEGMENTS_AND_METRICS_WARNING);
    }

    if (!hasSegments) {
      return t(TransKeys.ACCOUNT_SETTINGS.TEAMS.WARNINGS.SEGMENTS_WARNING);
    }

    if (!hasMetrics) {
      return t(TransKeys.ACCOUNT_SETTINGS.TEAMS.WARNINGS.METRICS_WARNING);
    }

    return null;
  }, [t, team]);

  return (
    <div className={classes.TeamNameCell}>
      <TeamIconChip {...team} />
      <span>{team.name}</span>
      <Tooltip title={warningMessage} hidden={!warningMessage}>
        <WarningIcon className={classes.WarningIcon} />
      </Tooltip>
    </div>
  );
};

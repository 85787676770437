import {action, OnSuccessActionHook} from 'front-core';
import {FeatureDTO} from '../../objects/dto/feature.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';

export enum FeaturesActionType {
  CREATE_FEATURE = '@@features/CREATE_FEATURE',
  UPDATE_FEATURE = '@@features/UPDATE_FEATURE',
  DELETE_FEATURE = '@@features/DELETE_FEATURE',
}

export const createFeature = (data: FeatureDTO, onSuccess: OnSuccessActionHook) =>
  action(FeaturesActionType.CREATE_FEATURE, data, {onSuccess});

export const updateFeature = (data: FeatureDTO, onSuccess: OnSuccessActionHook) =>
  action(FeaturesActionType.UPDATE_FEATURE, data, {onSuccess});

export const deleteFeature = (id: number) => action(FeaturesActionType.DELETE_FEATURE, id);

export const deleteFeatureConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_FEATURE.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_FEATURE.CONTENT),
    },
    () => [deleteFeature(id)]
  );

import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {
  LabelWrapper,
  Link,
  LiteralValueType,
  QueryBuilderFactory,
  SqlElementType,
} from 'ui-components';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {get, values} from 'lodash';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {TableColumnQueryBuilder} from '../../../../shared/core/query-builders/table-column-query-builder/table-column-query-builder.component';
import {SQLQueryBuilder} from '../../../../shared/core/query-builders/sql-query-builder/sql-query-builder.component';
import {StatisticalSignificanceSelector} from '../../components/ui-selectors/statistical-significance-selector/statistical-significance-selector.component';
import TransKeys from 'translations';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import {KPIsSelector} from '../../components/ui-selectors/kpis-selector/kpis-selector.component';
import {TableEventsValueQueryBuilder} from '../../../../shared/core/query-builders/table-events-value-query-builder/table-events-value-query-builder.component';
import {
  ExperimentScopeMode,
  ExperimentScopeSelector,
} from '../../components/ui-selectors/experiment-scope-selector/experiment-scope-selector.component';
import {SingleDateSelector} from '../../components/ui-selectors/single-date-selector/single-date-selector.component';
import {SimpleOptionsSelector} from '../../components/ui-selectors/simple-options-selector/simple-options-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {SimpleNumberSelector} from '../../components/ui-selectors/simple-number-selector/simple-number-selector.component';
import {Beacon} from '../../../../../config/helpscout.config';
import {SignalDataType} from '../../../../../objects/models/signal.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {exists} from 'front-core';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {MetricType} from '../../../../../objects/models/metric.model';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../constants/feature-flags';

const ANALYSIS_STRATEGY = {
  event: {
    value: 'event',
    label: 'Event property',
  },
  column: {
    value: 'column',
    label: 'Column',
  },
  sql: {
    value: 'sql',
    label: 'Custom SQL',
  },
};

export const VARIANT_QUERY_KEY = 'variant_query';
const minRunDaysSchemaMapping = {
  number_key: 'minimum_running_days',
};
const statisticalSignificanceSchemaMapping = {
  statistical_significance: 'experiment_threshold',
};
export const kpiSelectorSchemaMapping = {
  primary_kpi: 'conversion_event',
  secondary_kpis: 'secondary_conversion_events',
  bound_kpis_to_assignment: 'bound_kpis_to_assignment',
};
export const experimentSelectorSchemaMapping = {
  start_event_query: 'start_event_query',
  assignment_mode: 'assignment_mode',
  assignment_type: `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.STRATEGY}`,
};
const startDateSelectorSchemaMapping = {
  date_parameter: 'start_date',
};
const endDateSelectorSchemaMapping = {
  date_parameter: 'end_date',
};
const gradualReleaseSelectorSchemaMapping = {
  date_parameter: 'pre_release_date',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const conversionBeforeAssignmentSchemaMapping = {
  boolean_key: 'filter_users_who_converted_before_start_event',
};
const filterUsersWhoDidNotReachKPIReferenceDateSchemaMapping = {
  boolean_key: 'filter_users_who_did_not_reach_kpi_reference_date',
};
const boundKPIsSchemaMapping = {
  boolean_key: 'bound_kpis',
};
export const USER_END_DATE_KEY = 'user_end_date';

const MIN_RUN_DAYS_VALUE = 1;
const MAX_RUN_DAYS_VALUE = 30;

const createTableColumnFilters = (entityContext: undefined) => ({
  entityBinding: TableEntityBinding.DEFAULT,
  entity: entityContext,
});

// THIS BOUNDING SHOULD BE DEFAULT - PLEASE CONSULT WITH Guy / Alon before changing
const createTableEventFilters = (entityContext: undefined) => ({
  entityBinding: TableEntityBinding.DEFAULT,
  entityContext: entityContext,
});

export const createKPISelectorFiltersFor91 = (entityContext: TableEntity) => ({
  data_type: [
    SignalDataType.BOOLEAN,
    SignalDataType.TIMESTAMP,
    SignalDataType.INTEGER,
    SignalDataType.DECIMAL,
  ],
  entity_binding: TableEntityBinding.ONE_WAY,
  exclude_templates: [
    MetricType.DAU,
    MetricType.WAU,
    MetricType.MAU,
    MetricType.DOD,
    MetricType.MOM,
    MetricType.WOW,
    MetricType.L7,
    MetricType.L28,
    MetricType.RATE,
  ],
  entity_context: entityContext,
  exclude_tag: ['join_date', 'segment'],
  with_population_filter: true,
});

const extractAssignment = (assignmentQuery: any) => {
  if (!exists(assignmentQuery)) {
    return;
  }
  if (assignmentQuery.type !== SqlElementType.CASES) {
    return;
  }
  if (assignmentQuery.cases.length > 1) {
    return;
  }
  return assignmentQuery.cases[0][0];
};

export const Analysis91Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {
    errors,
    parameters,
    changeParametersValue,
    registerDefaultHandler,
    removeDefaultHandler,
    setAfterSubmitHandler,
  } = useContext(ParametersFormContext);
  const {productEntities, defaultTableEntity} = useProductData();
  const {t} = useTranslation();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const strategy = useMemo(
    () => get(parameters, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.STRATEGY}`),
    [parameters]
  );
  const assignmentOptions = useMemo(() => values(ANALYSIS_STRATEGY), []);
  const {[entitySchemaMapping.entity]: entityContext} = parameters;
  const kpiSignalFilters = useMemo(
    () => createKPISelectorFiltersFor91(entityContext),
    [entityContext]
  );
  const columnFilters = useMemo(
    () => ({
      literalType: [
        LiteralValueType.INTEGER,
        LiteralValueType.FLOAT,
        LiteralValueType.STRING,
        LiteralValueType.BOOLEAN,
      ],
    }),
    []
  );
  const exposeKPIBoundParameter = useFeatureIsOn(FeatureFlag.AB_TEST_KPI_BOUND as string);
  useEffect(() => {
    registerDefaultHandler('analysis_91', parameters => {
      const defaults: any = {
        [conversionBeforeAssignmentSchemaMapping.boolean_key]: false,
        [filterUsersWhoDidNotReachKPIReferenceDateSchemaMapping.boolean_key]: false,
        [boundKPIsSchemaMapping.boolean_key]: false,
        [PARAMETERS_METADATA_KEY]: {
          [METADATA_KEY.STRATEGY]: ANALYSIS_STRATEGY.event.value,
        },
      };
      switch (strategy) {
        case ANALYSIS_STRATEGY.column.value:
          defaults[VARIANT_QUERY_KEY] = QueryBuilderFactory.createTableColumn();
          defaults[experimentSelectorSchemaMapping.assignment_mode] = undefined;
          break;
        case ANALYSIS_STRATEGY.event.value:
          defaults[VARIANT_QUERY_KEY] = null;
          defaults[experimentSelectorSchemaMapping.assignment_mode] =
            ExperimentScopeMode.AS_ASSIGNMENT;
          break;
        default:
          defaults[VARIANT_QUERY_KEY] = null;
          defaults[experimentSelectorSchemaMapping.assignment_mode] = undefined;
          break;
      }
      defaults[statisticalSignificanceSchemaMapping.statistical_significance] = 0.9;
      defaults[minRunDaysSchemaMapping.number_key] = 7;
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      return defaults;
    });
    return () => {
      removeDefaultHandler('analysis_91');
    };
  }, [registerDefaultHandler, removeDefaultHandler, strategy, defaultTableEntity]);
  useEffect(() => {
    setAfterSubmitHandler(parameters => {
      const {
        [experimentSelectorSchemaMapping.assignment_mode]: experimentScopeMode,
        [VARIANT_QUERY_KEY]: variantQuery,
      } = parameters;
      const strategy = get(parameters, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.STRATEGY}`);
      if (
        strategy === ANALYSIS_STRATEGY.event.value &&
        experimentScopeMode === ExperimentScopeMode.AS_ASSIGNMENT
      ) {
        parameters[experimentSelectorSchemaMapping.start_event_query] =
          extractAssignment(variantQuery);
      }
      return parameters;
    });
  }, [setAfterSubmitHandler]);
  const enableBoundKpisToAssignment = useMemo(
    () =>
      parameters[experimentSelectorSchemaMapping.assignment_mode] ===
      ExperimentScopeMode.AS_ASSIGNMENT,
    [parameters]
  );

  const onChangeStrategy = useCallback(
    (strategy: string) => {
      changeParametersValue({
        [VARIANT_QUERY_KEY]: undefined,
        [experimentSelectorSchemaMapping.start_event_query]: undefined,
        [experimentSelectorSchemaMapping.assignment_mode]: undefined,
        [PARAMETERS_METADATA_KEY]: {[METADATA_KEY.STRATEGY]: strategy},
      });
    },
    [changeParametersValue]
  );
  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        VARIANT_QUERY_KEY,
        kpiSelectorSchemaMapping.primary_kpi,
        kpiSelectorSchemaMapping.secondary_kpis,
        kpiSelectorSchemaMapping.bound_kpis_to_assignment,
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const onLearnMore = useCallback(() => Beacon('article', '22', {type: 'sidebar'}), []);
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      hasError(errors, [
        'population_filter',
        ...values(endDateSelectorSchemaMapping),
        ...values(minRunDaysSchemaMapping),
        ...values(gradualReleaseSelectorSchemaMapping),
      ])
    );
  }, [isOpenAdvancedParams, errors]);

  const renderStrategy = () => {
    let render = null;
    const sharedProps = {
      query: parameters[VARIANT_QUERY_KEY],
      onChange: value => changeParametersValue({[VARIANT_QUERY_KEY]: value}),
      errors: errors?.[VARIANT_QUERY_KEY],
    };
    switch (strategy) {
      case ANALYSIS_STRATEGY.column.value:
        render = (
          <TableColumnQueryBuilder
            {...sharedProps}
            tableFilters={createTableColumnFilters(entityContext)}
            columnFilters={columnFilters}
          />
        );
        break;
      case ANALYSIS_STRATEGY.event.value:
        render = (
          <TableEventsValueQueryBuilder
            {...sharedProps}
            filters={createTableEventFilters(entityContext)}
            thenText={'And take the assignment property from'}
            multiEvents={false}
            multiSelection
            sameTypeThen
          />
        );
        break;
      case ANALYSIS_STRATEGY.sql.value:
        render = <SQLQueryBuilder {...sharedProps} />;
        break;
    }
    return (
      <ParameterInputWrapper
        title={'Assignment Definition'}
        subTitle={'Define your assignment'}
        className={classes.Parameter}
        error={Boolean(errors?.[VARIANT_QUERY_KEY])}
      >
        <LabelWrapper label={`${ANALYSIS_STRATEGY[strategy].label} definition`}>
          {render}
        </LabelWrapper>
      </ParameterInputWrapper>
    );
  };

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <SimpleOptionsSelector
        title={'Assignment type'}
        subTitle={
          <>
            State where the assignment value (Control, Variant A, etc.) of users is stored.
            <Link onClick={onLearnMore} className={classes.LearnMore}>
              Learn more.
            </Link>
          </>
        }
        className={classes.Parameter}
        options={assignmentOptions}
        value={{option_key: strategy}}
        errors={errors}
        onChange={op => onChangeStrategy(op.option_key)}
      />
      {strategy && renderStrategy()}

      <ExperimentScopeSelector
        value={parameters}
        title={'Experiment scope'}
        subTitle={'Define the scope of the experiment.'}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={experimentSelectorSchemaMapping}
        errors={errors}
        onSignalInfo={onSignalInfo}
      />

      <SingleDateSelector
        title={'Experiment start date'}
        subTitle={`What's the start date of the experiment?`}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={startDateSelectorSchemaMapping}
        allowFutureDates
      />

      <KPIsSelector
        value={parameters}
        title={'Experiment KPIs'}
        subTitle={'Define which KPIs will be measured in this experiment'}
        onChange={changeParametersValue}
        filters={kpiSignalFilters}
        className={classes.Parameter}
        schemaKeysMapping={kpiSelectorSchemaMapping}
        errors={errors}
        onSignalInfo={onSignalInfo}
        enableOverrideDefaultBounding={enableBoundKpisToAssignment}
      />

      <StatisticalSignificanceSelector
        title={'Statistical significance'}
        subTitle={'What statistical significance are you aiming to?'}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={statisticalSignificanceSchemaMapping}
        errors={errors}
        className={classes.Parameter}
      />

      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        {entityContext && (
          <SegmentFilterSelector
            title={'Experiment population (optional)'}
            subTitle={'Can be used to filter in only the population who was part of the experiment'}
            onChange={changeParametersValue}
            value={parameters}
            className={classes.Parameter}
            entityContext={entityContext}
            errors={errors}
          />
        )}
        <SingleDateSelector
          title={'Experiment end date (optional)'}
          subTitle={`If the experiment has already been concluded, set the end date of the experiment`}
          className={classes.Parameter}
          errors={errors}
          value={parameters}
          onChange={v =>
            changeParametersValue({
              ...v,
              // duplicate value to another key
              [USER_END_DATE_KEY]: v[endDateSelectorSchemaMapping.date_parameter],
            })
          }
          schemaKeysMapping={endDateSelectorSchemaMapping}
          clearable
        />
        <SimpleNumberSelector
          title={'Minimum Running Days (optional)'}
          subTitle={`Set a minimum number of days this experiment must run before it becomes valid`}
          className={classes.Parameter}
          errors={errors}
          schemaKeysMapping={minRunDaysSchemaMapping}
          value={parameters}
          onChange={changeParametersValue}
          postText={'Days'}
          minValue={MIN_RUN_DAYS_VALUE}
          maxValue={MAX_RUN_DAYS_VALUE}
        />
        <SingleDateSelector
          title={'Gradual Release (optional)'}
          subTitle={`If before starting this experiment you released it to some users, set when`}
          className={classes.Parameter}
          errors={errors}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={gradualReleaseSelectorSchemaMapping}
        />
        <SimpleBooleanSelector
          title={'Conversion before assignment'}
          subTitle={
            "Exclude users who converted before the assignment for the test, for bounded KPIs all users who didn't have the chance to be effect by the assignment will be filtered"
          }
          className={classes.Parameter}
          errors={errors}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={conversionBeforeAssignmentSchemaMapping}
        />
        <SimpleBooleanSelector
          title={'Filter users who didn’t reach reference date (Per KPI)'}
          subTitle={
            'For example: for conversion from trial to subscription, should the KPI be calculated out of assignment or out of users who started trial'
          }
          className={classes.Parameter}
          errors={errors}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={filterUsersWhoDidNotReachKPIReferenceDateSchemaMapping}
        />
        {exposeKPIBoundParameter && (
          <SimpleBooleanSelector
            title={'Exlclude non-mature users for bounded KPIs'}
            subTitle={
              "Exclude users who hadn't had enough time to reach goal from experiment, this is not mandatory for AB tests due to random assigment"
            }
            className={classes.Parameter}
            errors={errors}
            value={parameters}
            onChange={changeParametersValue}
            schemaKeysMapping={boundKPIsSchemaMapping}
          />
        )}
      </ExtendedParameters>
    </div>
  );
};

import {SeasonalityExplainerSentenceExplainer} from '../rca-insights.model.ts';
import {
  ScrollToSectionSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import sharedClasses from '../../insights.module.scss';
import {number2k, RCAElementType} from 'ui-components';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {formatPercentageValue} from '../../insights.utils.ts';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags.ts';

interface OwnProps extends SeasonalityExplainerSentenceExplainer {}

type AllProps = OwnProps;

export const SeasonalityExplainerSentenceFormatter = (props: AllProps) => {
  const {sentenceParts, onViewOnPage, explainabilityLabel} = props;
  const showSeasonalityExplainer = useFeatureIsOn(FeatureFlag.RCA_SEASONALITY_EXPLAINER as string);
  if (!showSeasonalityExplainer) {
    return null;
  }

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      <RCAExplainersIcon variant={RCAExplainersIconVariant.SEASONALITY_EXPLAINER} />
      <SentencePart text={`The KPI ${sentenceParts.changeDescription}d by`} />
      <SentencePart bold text={formatPercentageValue(sentenceParts.trendChangeValue)} />
      <SentencePart text={`last year, with seasonality accounting for`} />
      <SentencePart
        bold
        text={`${number2k(sentenceParts.explainabilityScore * 100 * sentenceParts.upliftValue)}pp`}
      />
      <SentencePart text={`out of the`} />
      <SentencePart bold text={formatPercentageValue(sentenceParts.upliftValue)} />
      <SentencePart text={`change`} />
      <ScrollToSectionSentencePart
        onViewOnPage={() => onViewOnPage(RCAElementType.SEASONALITY_EXPLAINER)}
      />
    </SentenceContainer>
  );
};

import {action, OnSuccessActionHook} from 'front-core';
import {SegmentDTO, UpdateFavoriteSegmentsDTO} from '../../objects/dto/segment.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {CreationInteractionPayload} from 'ui-components';
import {notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';

export enum SegmentsActionType {
  CREATE_SEGMENT = '@@segments/CREATE_SEGMENT',
  UPDATE_SEGMENT = '@@segments/UPDATE_SEGMENT',
  RESCAN_SEGMENT = '@@segments/RESCAN_SEGMENT',
  DELETE_SEGMENT = '@@segments/DELETE_SEGMENT',
  VALIDATE_SEGMENT = '@@segments/VALIDATE_SEGMENT',
  UPDATE_FAVORITES_SEGMENTS = '@@segments/UPDATE_FAVORITES_SEGMENTS',
  ADD_SEGMENT_TO_TEAM = '@@segments/ADD_SEGMENT_TO_TEAM',
}

export const createSegment = (data: SegmentDTO, onSuccess: OnSuccessActionHook) =>
  action(SegmentsActionType.CREATE_SEGMENT, data, {onSuccess});

export const updateSegment = (data: SegmentDTO, onSuccess: OnSuccessActionHook) =>
  action(SegmentsActionType.UPDATE_SEGMENT, data, {onSuccess});

export const deleteSegment = (id: number) => action(SegmentsActionType.DELETE_SEGMENT, id);

export const deleteSegmentConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SEGMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_SEGMENT.CONTENT),
    },
    () => [deleteSegment(id)]
  );

export const rescanSegment = (id: number, onSuccess: OnSuccessActionHook) =>
  action(SegmentsActionType.RESCAN_SEGMENT, id, {onSuccess});

export const rescanSegmentConfirmed = (id: number, onSuccess: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RESCAN_SEGMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RESCAN_SEGMENT.CONTENT),
    },
    () => [rescanSegment(id, onSuccess)]
  );

export const createPowerUsersSegment = (payload: CreationInteractionPayload) =>
  notifyEvent(AmplitudeEvent.POWER_USERS_SEGMENT_CREATION_REQUESTED, {
    value: payload,
  });

export const createPowerUsersSegmentConfirmed = (payload: CreationInteractionPayload) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.CREATE_POWER_USERS_SEGMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.CREATE_POWER_USERS_SEGMENT.CONTENT),
    },
    () => [createPowerUsersSegment(payload)]
  );

export const validateSegment = (id: number, onSuccess?: OnSuccessActionHook) =>
  action(SegmentsActionType.VALIDATE_SEGMENT, id, {onSuccess});

export const updateFavoriteSegments = (
  data: UpdateFavoriteSegmentsDTO,
  onSuccess?: OnSuccessActionHook
) => action(SegmentsActionType.UPDATE_FAVORITES_SEGMENTS, data, {onSuccess});

export const addSegmentToTeam = (segmentId: number, teamId: number) =>
  action(SegmentsActionType.ADD_SEGMENT_TO_TEAM, {segmentId, teamId});

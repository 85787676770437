import {ExplainabilityLabel, RCASeasonalityExplainerFigure} from '../../../../types';
import classes from './rca-seasonality-explainer-viewer.module.scss';
import {useContext, useMemo} from 'react';
import {RCAFigureViewerContext} from '../rca-figure/rca-figure-viewer.context';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {FancyBlock} from '../../../../../../simple/data-display/fancy-block/fancy-block.component';
import {LineChart} from '../../../../../charts/charts/line-chart/line-chart.component';
import {number2k} from 'front-core';
import {TrendChip} from '../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {CollapsibleExplainer} from '../rca-figure/components/collapsible-explainer/collapsible-explainer.component';

type Props = RCASeasonalityExplainerFigure;

export const RCASeasonalityExplainerViewer = (props: Props) => {
  const {data, options} = props;
  const {t} = useDocumentTranslation();
  const {uplift} = useContext(RCAFigureViewerContext);
  const {
    kpiSamples,
    trendChange,
    lastYearSamples,
    explainabilityLabel,
    explainabilityScore,
    isSignificant,
    isTrendChangeSignificant,
    granularity,
  } = data;

  const chartProps = useMemo(() => {
    if (kpiSamples.length === 0) {
      return;
    }
    const kpiDataset = kpiSamples.map(sample => ({
      x: sample.datetime,
      y: options.isPercentageValue ? sample.value * 100 : sample.value,
      dashed: sample.isPartial,
    }));

    const seasonalityDataset = lastYearSamples.map(sample => ({
      x: sample.matchingDatetime,
      actualX: sample.datetime,
      y: options.isPercentageValue ? sample.value * 100 : sample.value,
      dashed: sample.isPartial,
    }));

    const datasets = [
      {
        id: 'kpi',
        label: options.kpiName,
        data: kpiDataset,
      },
    ];

    if (seasonalityDataset?.length > 0) {
      datasets.push({
        id: 'seasonality',
        label: `${options.kpiName} (last year)`,
        data: seasonalityDataset,
      } as any);
    }

    const yLabelSuffix = options.isPercentageValue ? '%' : undefined;
    return {
      id: 'rca-seasonality',
      datasets,
      options: {
        yLabel: options.kpiName,
        xLabel: 'Date',
        yLabelSuffix,
        labels: {
          timeUnit: granularity,
          type: 'date',
          dateFormat: 'MMM D',
          capitalize: false,
        },
        yAxisMaxTicks: 5,
      },
    } as any;
  }, [kpiSamples, lastYearSamples, options, granularity]);

  const descriptionSeasonality = t(
    TransKeys.DOCUMENT_VIEWER.RCA_SEASONALITY_EXPLAINER_VIEWER.DESCRIPTION.SEASONALITY,
    {
      ppValue: number2k(uplift * explainabilityScore * 100),
      outOfValue: number2k(uplift * 100),
      explanationStrength:
        explainabilityLabel === ExplainabilityLabel.HIGH
          ? 'strong'
          : explainabilityLabel === ExplainabilityLabel.MEDIUM
            ? 'moderate'
            : 'low',
    }
  );

  const insignificantSuffix = t(
    TransKeys.DOCUMENT_VIEWER.RCA_SEASONALITY_EXPLAINER_VIEWER.DESCRIPTION.INSIGNIFICANT_SUFFIX
  );

  const description = (
    <span>
      {t(TransKeys.DOCUMENT_VIEWER.RCA_SEASONALITY_EXPLAINER_VIEWER.DESCRIPTION.PREFIX)}
      <TrendChip
        className={classes.TrendChip}
        value={trendChange * 100}
        higherIsBetter={options.higherIsBetter}
        isSignificant={isTrendChangeSignificant}
        size={'small'}
      />
      {isSignificant ? descriptionSeasonality : insignificantSuffix}
    </span>
  );

  return (
    <CollapsibleExplainer
      className={classes.RCASeasonalityExplainerViewer}
      modelName={'seasonality'}
      explainerDescription={description}
      explainabilityLabel={explainabilityLabel}
      isSignificant={isSignificant}
      chart={
        <FancyBlock
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_SEASONALITY_EXPLAINER_VIEWER.CHART_TITLE)}
        >
          <div className={classes.ChartWrapper}>{chartProps && <LineChart {...chartProps} />}</div>
        </FancyBlock>
      }
    />
  );
};

export enum PartType {
  PLAIN_TEXT = 'PlainText',
  BOLD_TEXT = 'BoldText',
  CHANGE_TEXT = 'ChangeText',
}

export interface BasePart {
  type: PartType;
}

export interface PlainTextPart extends BasePart {
  type: PartType.PLAIN_TEXT;
  text: string;
}

export interface BoldTextPart extends BasePart {
  type: PartType.BOLD_TEXT;
  text: string;
}

export interface ChangePart extends BasePart {
  type: PartType.CHANGE_TEXT;
  text: string;
}

export type Part = PlainTextPart | BoldTextPart | ChangePart;

export interface Sentence {
  parts: Part[];
  sentence: string;
}

export interface Section {
  title: string;
  type: string;
  isList: boolean;
  content: Sentence[];
}

export interface ExecutiveSummary {
  sections: Section[];
}

import * as React from 'react';

export interface OwnProps {
  type: string;
  className?: string;
}

export const UnsupportedViewer: React.FC<OwnProps> = (props: OwnProps) => {
  return <div className={props.className}>UNSUPPORTED TYPE [{props.type}]</div>;
};

import {useCallback, useContext, useMemo, useState} from 'react';
import {
  METRIC_HEALTH_MONITOR_ID_PATH_PARAM,
  HEALTH_MONITOR_GROUP_ID_PATH_PARAM,
  MONITORED_METRIC_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FancyHeader, ModalLayout, PrimaryTabs, PulseIcon, WizardStepper} from 'ui-components';
import classes from './monitored-metric-form-panel.module.scss';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {MonitoredMetricDTO} from '../../../../objects/dto/health-monitor.dto';
import {SelectMetricTab} from './components/select-metric-tab/select-metric-tab.component';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {composition, exists, OnSuccessActionHook} from 'front-core';
import TransKeys from 'translations';
import {MonitoredMetricType} from '../../../../objects/models/health-monitor.model';
import {PanelKey} from '../../../../constants/panels';
import {MetricInfoTab} from './components/metric-info-tab/metric-info-tab.component';
import {
  createMonitoredMetric,
  editMonitoredMetric,
} from '../../../../store/health-monitor/health-monitor.actions';
import {getMonitoredMetricNetworkRequest} from '../../../../http/health-monitor.network-requests';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';

interface OwnProps {
  monitoredMetric?: MonitoredMetricDTO;
  data: Partial<MonitoredMetricDTO>;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  isNorthStar: boolean;
  [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]?: number;
  [MONITORED_METRIC_ID_PATH_PARAM]?: number;
  [HEALTH_MONITOR_GROUP_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const createEmptyAnalysisResult = (): Partial<MonitoredMetricDTO> => ({
  name: '',
  shortDescription: '',
  metricType: MonitoredMetricType.KPI,
  parameters: {},
  metricSeriesGroupId: null,
});

const wizardLabels = [
  {
    label: 'Select Metric',
    value: 'SELECT_METRIC',
  },
  {
    label: 'Name it',
    value: 'METRIC_INFO',
  },
];

const SELECTED_MONITORED_METRIC_KEY = SharedSelectionKeys.MONITORED_METRIC_FORM__MONITORED_METRIC;

const MonitoredMetricFormPanelComponent = (props: AllProps) => {
  const {
    onClose,
    data = createEmptyAnalysisResult(),
    isNorthStar,
    monitoredMetric,
    onSuccess: onSuccessFromProps,
    [HEALTH_MONITOR_GROUP_ID_PATH_PARAM]: metricSeriesGroupId,
    [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]: metricHealthMonitorId,
  } = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {defaultTableEntity} = useProductData();
  const [activeIndex, setActiveIndex] = useState(0);
  const [formData, setFormData] = useState<MonitoredMetricDTO>({
    ...data,
    entity: defaultTableEntity,
    metricHealthMonitorId: data.metricHealthMonitorId || metricHealthMonitorId,
    metricSeriesGroupId: data.metricSeriesGroupId || metricSeriesGroupId,
    isNorthStar: exists(data.isNorthStar) ? data.isNorthStar : isNorthStar || false,
    analysisTypeId: data.analysisTypeId || AnalysisTypeId.KPI_ANALYSIS,
    ...monitoredMetric,
  } as any);
  const editMode = useMemo(() => formData.id !== undefined, [formData.id]);
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_MONITORED_METRIC,
      ActionKey.EDIT_MONITORED_METRIC
    )(state)
  );
  const onWizardStepClick = useCallback(
    (value: string) => {
      const labelIndex = wizardLabels.findIndex(l => l.value === value);
      setActiveIndex(labelIndex || 0);
    },
    [setActiveIndex]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );
  const isLastStep = useMemo(() => wizardLabels[activeIndex]?.value === 'DONE', [activeIndex]);
  const onSubmit = useCallback(
    data => {
      const onSuccess = (res, action) => {
        onSuccessFromProps && onSuccessFromProps(res, action);
        onClose();
      };
      dispatch(
        editMode ? editMonitoredMetric(data, onSuccess) : createMonitoredMetric(data, onSuccess)
      );
    },
    [dispatch, onClose, editMode, onSuccessFromProps]
  );

  return (
    <div className={classes.MonitoredMetricFormContainer}>
      <ModalLayout>
        <div className={classes.MonitoredMetricForm}>
          <FancyHeader
            title={'Select Metric'}
            icon={PulseIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.WizardSteps}>
            {!isLastStep && (
              <WizardStepper
                className={classes.FullWidth}
                activeValue={wizardLabels[activeIndex]?.value}
                lastCompletedValue={
                  activeIndex > 0 ? wizardLabels[activeIndex - 1]?.value : undefined
                }
                labels={wizardLabels}
                onClick={onWizardStepClick}
              />
            )}
          </div>
          {isLoading && <GenericLoading />}
          <PrimaryTabs
            className={classes.Tabs}
            selected={wizardLabels[activeIndex].value}
            hideTabsButtons
            tabs={[
              {
                key: 'SELECT_METRIC',
                render: () => (
                  <SelectMetricTab
                    data={formData}
                    submitText={t(TransKeys.GENERAL.ACTIONS.NEXT)}
                    onSignalInfo={onSignalInfo}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick('METRIC_INFO');
                    }}
                  />
                ),
              },
              {
                key: 'METRIC_INFO',
                render: () => (
                  <MetricInfoTab
                    data={formData}
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    submitText={t(TransKeys.GENERAL.LABELS.SELECT)}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onSubmit({...formData, ...data});
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export const MonitoredMetricFormPanel = composition<AllProps>(
  MonitoredMetricFormPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore<AllProps>({
    monitoredMetric: {
      selectedKey: SELECTED_MONITORED_METRIC_KEY,
      actionKey: SELECTED_MONITORED_METRIC_KEY,
      request: getMonitoredMetricNetworkRequest,
      mapPayloadFromProps: props => props[MONITORED_METRIC_ID_PATH_PARAM],
      shouldCall: props => props[MONITORED_METRIC_ID_PATH_PARAM] !== undefined,
    },
  })
);

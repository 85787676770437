import {AIConversationMessageType} from '../../../../../../objects/models/ai-chat.model';
import {MarkdownMessage} from './components/markdown-message/markdown-message.component';
import {SelectKPIMessage} from './components/select-kpi-message/select-kpi-message.component';
import {KPIGraphMessage} from './components/kpi-graph-message/kpi-graph-message.component';
import {RunAnalysesRecommendationMessage} from './components/run-analyses-recommendation-message/run-analyses-recommendation-message.component';
import {ShowSuggestionsMessage} from './components/show-suggestions-message/show-suggestions-message.component';
import {HelpCenterMessage} from './components/help-center-message/help-center-message.component';

export const MESSAGE_RENDERER_MAPPING = {
  [AIConversationMessageType.TEXT]: MarkdownMessage,
  [AIConversationMessageType.USER_CHOOSE_KPI]: SelectKPIMessage,
  [AIConversationMessageType.KPI_GRAPH]: KPIGraphMessage,
  [AIConversationMessageType.RUN_ANALYSES_RECOMMENDATION]: RunAnalysesRecommendationMessage,
  [AIConversationMessageType.SHOW_SUGGESTIONS]: ShowSuggestionsMessage,
  [AIConversationMessageType.LOOPS_HELP_CENTER_BACKED_ANSWER]: HelpCenterMessage,
} as const;

import {SampledModelXoXOverview} from '../../../../../../../../../insights/rca/sampled-model-sentences/objects';
import {useMemo} from 'react';
import {MetricValueType} from '../../../../../../../../../../objects/models/metric.model';
import {
  formatPercentageValue,
  formatPercentageValueWithPrefix,
} from '../../../../../../../../../insights/insights.utils';
import {number2k} from 'front-core';
import {
  SampledModelMeasuredItemCompoundNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components';
import sharedClasses from '../../rca-executive-summery.module.scss';
import {useTranslation} from 'react-i18next';
import classes from '../../rca-executive-summery.module.scss';

import TransKeys from 'translations';
import {HoverHelperTip} from 'ui-components';
import {ChangeDescriptionAlias} from '../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import classNames from 'classnames';
import {InfinityLightIcon} from 'ui-components';

enum ChangeFromPredictedDescription {
  BELLOW = 'below',
  ABOVE = 'above',
}

interface OwnProps extends SampledModelXoXOverview {
  changeDescriptionAlias: ChangeDescriptionAlias;
  showSubTitle?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const SampledModelXoXOverviewSection = (props: AllProps) => {
  const {
    sampledModelName,
    sampledModelValueType,
    sampledModelMeasuredItemCompoundName: compoundName,
    higherIsBetter,
    lastSampleValue: lastSampleValueFromProps,
    xoxChangeValue,
    lastValueAndLastExpectedValueByLoopsChange,
    xoxChangeDescription,
    xoxGranularity,
    changeDescriptionAlias,
    isSignificantLoopsAlgo,
    isSignificantXoX,
    showSubTitle = true,
    className,
  } = props;
  const {t} = useTranslation();

  const lastSampleValue = useMemo(
    () =>
      sampledModelValueType === MetricValueType.PERCENTAGE
        ? formatPercentageValue(lastSampleValueFromProps)
        : number2k(lastSampleValueFromProps),
    [lastSampleValueFromProps, sampledModelValueType]
  );
  const xoxChangeValueProps = useMemo(() => {
    const defaultProps = {
      bold: true,
      text:
        xoxChangeValue === null ? (
          <InfinityLightIcon />
        ) : (
          formatPercentageValueWithPrefix(xoxChangeValue)
        ),
    };

    if (!isSignificantXoX) {
      return defaultProps;
    }
    if (xoxChangeValue > 0) {
      return higherIsBetter ? {...defaultProps, positive: true} : {...defaultProps, negative: true};
    } else if (xoxChangeValue < 0) {
      return higherIsBetter ? {...defaultProps, negative: true} : {...defaultProps, positive: true};
    } else {
      return defaultProps;
    }
  }, [xoxChangeValue, higherIsBetter, isSignificantXoX]);
  const changeFromPredictedData = useMemo(() => {
    let styleProps = {};
    let changeFromPredicted = '';

    if (lastValueAndLastExpectedValueByLoopsChange > 0) {
      styleProps = higherIsBetter ? {positive: true} : {negative: true};
      changeFromPredicted = ChangeFromPredictedDescription.ABOVE;
    } else if (lastValueAndLastExpectedValueByLoopsChange < 0) {
      styleProps = higherIsBetter ? {negative: true} : {positive: true};
      changeFromPredicted = ChangeFromPredictedDescription.BELLOW;
    }
    return {
      styleProps,
      changeInReferenceToExpectedValueDescription: changeFromPredicted,
      formattedValue: formatPercentageValueWithPrefix(lastValueAndLastExpectedValueByLoopsChange),
    };
  }, [lastValueAndLastExpectedValueByLoopsChange, higherIsBetter]);

  return (
    <div className={classNames(sharedClasses.ExecutiveSummarySection, className)}>
      {showSubTitle && (
        <div className={classes.SubTitle}>
          {t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.METRIC_OVERVIEW_TITLE)}
        </div>
      )}
      <SentenceContainer>
        <SentencePart bold text={sampledModelName} />
        <SentencePart text="measured" />
        <SentencePart bold text={lastSampleValue} />
        <SentencePart text="in the analyzed period, reflecting" />
        <SentencePart {...xoxChangeValueProps} />
        <SentencePart text={`${xoxChangeDescription} ${xoxGranularity}.`} />
      </SentenceContainer>
      <SentenceContainer>
        <SentencePart text="In addition," />
        <SampledModelMeasuredItemCompoundNameSentencePart compoundName={compoundName} />
        <SentencePart text="was" />
        <SentencePart
          {...changeFromPredictedData.styleProps}
          bold
          text={changeFromPredictedData.formattedValue}
        />
        <SentencePart
          text={`${changeFromPredictedData.changeInReferenceToExpectedValueDescription} Loops prediction`}
        />
        <HoverHelperTip
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.LOOPS_PREDICTION_TOOLTIP, {
            changeFromPredicted: changeFromPredictedData.formattedValue,
          })}
          className={sharedClasses.VerticalCenter}
        />
        <SentencePart className={sharedClasses.RemoveLeftGap} text={`.`} />
        {isSignificantLoopsAlgo && (
          <>
            <SentencePart text={`This ${changeDescriptionAlias} is detected as an`} />
            <SentencePart bold text="anomaly" />
            <SentencePart text="by Loops." />
          </>
        )}
      </SentenceContainer>
    </div>
  );
};

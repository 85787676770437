import * as React from 'react';
import classNames from 'classnames';
import {ViewFigureColumnOptions} from '../../../../../types';
import {useMemo, useState} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists} from 'front-core';
import classes from './data-columns.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import {Button} from '../../../../../../../simple/controls/button/button.component';
import {ModalWrapper} from '../../../../shared/modal-wrapper/modal-wrapper.component';
import {ChildRenderer} from '../../../../core/child-renderer.component';
import {ModalLayout} from '../../../../../../../layouts/modal-layout/modal-layout.component';
import {FancyHeader} from '../../../../../../../simple/data-display/fancy-header/fancy-header.component';

interface OwnProps extends DataColumnProps<ViewFigureColumnOptions> {}

type AllProps = OwnProps;

const ViewFigureDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {buttonText, figureDataKey, modalTitleDataKey, width, height} = column.typeOptions;
  const [showModal, setShowModal] = useState(false);
  const figure = useMemo(() => data[figureDataKey], [data, figureDataKey]);
  const modalTitle = useMemo(() => data[modalTitleDataKey], [data, modalTitleDataKey]);

  return (
    <>
      <div className={classNames(classes.ViewFigureColumn, className)}>
        {figure && <Button onClick={() => setShowModal(true)}>{buttonText || 'view'}</Button>}
      </div>
      <ModalWrapper
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        width={width || '80rem'}
        height={height || '40rem'}
      >
        <ModalLayout
          className={classes.ViewFigureColumnModalWrapper}
          bodyClassName={classes.ModalBody}
        >
          {modalTitle && <FancyHeader title={modalTitle} className={classes.Header} />}
          <div className={classes.Content}>
            <ChildRenderer children_={figure} />
          </div>
        </ModalLayout>
      </ModalWrapper>
    </>
  );
};

const protector: Protector = (props: OwnProps) =>
  exists(props.data[getDataValueKey(props.column, 'figureDataKey')]);
export const ViewFigureDataColumn = withDataColumnProtector(protector)(
  ViewFigureDataColumnComponent
);

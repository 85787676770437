import * as React from 'react';
import {useMemo, useState} from 'react';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {
  ColumnType,
  DocumentElement,
  DocumentElementType,
  RCAEntityLevelExplainerFigureItem,
  RCAEntityLevelExplainerFigureOptions,
  SmartTableFigureColumn,
} from '../../../../../../types';
import classes from './entity-level-explainer-modal.module.scss';
import {keyBy} from 'lodash';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {calculateUplift, safeDivision} from 'front-core';
import {useRCAVsLabels} from '../../../rca-figure/hooks/use-rca-vs-labels.hook';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';

interface Props {
  title: string;
  item?: RCAEntityLevelExplainerFigureItem;
  options: RCAEntityLevelExplainerFigureOptions;
  onClose: () => void;
  className?: string;
}

const DEFAULT_SORT = {
  orderBy: 'uplift',
  order: 'desc',
};

const TransPrefix = TransKeys.DOCUMENT_VIEWER.RCA_ENTITY_EXPLAINER;

export const EntityLevelExplainerModal: React.FC<Props> = (props: Props) => {
  const {title, item, options, onClose} = props;
  const vsLabels = useRCAVsLabels(options.entity);
  const {t} = useDocumentTranslation();

  const [selectedSignalId, setSelectedSignalId] = useState(item.eventProperties[0].signalId);
  const eventPropertiesBySignalId = useMemo(
    () => keyBy(item.eventProperties, 'signalId'),
    [item.eventProperties]
  );
  const selectOptions = useMemo(
    () => item.eventProperties.map(ep => ({label: ep.eventPropertyName, value: ep.signalId})),
    [item.eventProperties]
  );
  const selectedEventProperty = eventPropertiesBySignalId[selectedSignalId];

  const tableData = useMemo(
    () =>
      selectedEventProperty.items.map(i => ({
        ...i,
        share: safeDivision(i.value, item.value, true) * 100,
        expectedShare: safeDivision(i.expectedValue, item.expectedValue, true) * 100,
        uplift: calculateUplift(i.value, i.expectedValue, true) * 100,
      })),
    [selectedEventProperty, item]
  );
  const tableColumns: SmartTableFigureColumn<any>[] = useMemo(
    () => [
      {
        key: 'name',
        type: ColumnType.DEFAULT,
        title: selectedEventProperty.eventPropertyName,
        options: {
          width: '50%',
          sortable: true,
          filterable: true,
        },
      },
      {
        key: 'switch_share',
        type: ColumnType.SWITCH_COLUMN,
        title: '',
        typeOptions: {
          columns: [
            {
              key: 'share',
              type: ColumnType.VERSUS,
              title: t(TransPrefix.TABLE.HEADERS.SHARE.TITLE),
              helperText: t(TransPrefix.TABLE.HEADERS.SHARE.HELPER_TEXT),
              options: {
                sortable: true,
                filterable: true,
                unit: '%',
              },
              typeOptions: {
                valueDataKey: 'share',
                versusDataKey: 'expectedShare',
                valueDataTitle: t(TransPrefix.TABLE.HEADERS.SHARE.TITLE),
                versusDataTitle: vsLabels.share.vsValueLabel,
                higherIsBetter: options.higherIsBetter,
              },
            },
            {
              key: 'count',
              type: ColumnType.VERSUS,
              title: t(TransPrefix.TABLE.HEADERS.COUNT.TITLE),
              helperText: t(TransPrefix.TABLE.HEADERS.COUNT.HELPER_TEXT),
              options: {
                sortable: true,
                filterable: true,
              },
              typeOptions: {
                valueDataKey: 'count',
                versusDataKey: 'expectedCount',
                valueDataTitle: t(TransPrefix.TABLE.HEADERS.COUNT.TITLE),
                versusDataTitle: vsLabels.count.vsValueLabel,
                higherIsBetter: options.higherIsBetter,
              },
            },
          ],
        },
      },
      {
        key: 'value',
        type: ColumnType.VERSUS,
        title: t(TransPrefix.TABLE.HEADERS.KPI_VALUE.TITLE),
        helperText: t(TransPrefix.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT),
        options: {
          sortable: true,
          filterable: true,
        },
        typeOptions: {
          valueDataKey: 'value',
          versusDataKey: 'expectedValue',
          valueDataTitle: t(TransPrefix.TABLE.HEADERS.KPI_VALUE.TITLE),
          versusDataTitle: vsLabels.value.vsValueLabel,
          higherIsBetter: options.higherIsBetter,
        },
      },
      {
        key: 'uplift',
        type: ColumnType.CHANGE,
        title: t(TransPrefix.TABLE.HEADERS.CHANGE.TITLE),
        helperText: t(TransPrefix.TABLE.HEADERS.CHANGE.HELPER_TEXT),
        options: {
          sortable: true,
          filterable: true,
        },
        typeOptions: {
          higherIsBetter: options.higherIsBetter,
        },
      },
    ],
    [selectedEventProperty, options, vsLabels, t]
  );
  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: `smart_table_${selectedEventProperty.signalId}`,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: {
          defaultSort: DEFAULT_SORT,
          pagination: false,
          stickyFooter: true,
        },
      }) as any,
    [tableColumns, tableData, selectedEventProperty]
  );
  return (
    <ModalWrapper isOpen={true} onClose={() => onClose()} width={'100rem'} height={'60rem'}>
      <div className={classes.SegmentExplainersModal}>
        <div className={classes.TitleWrapper}>
          <div className={classes.Title}>{title}</div>
          <div>view by</div>
          <Select
            dropdownButtonClassName={classes.Select}
            value={selectedSignalId}
            onChange={value => setSelectedSignalId(value as any)}
            options={{options: selectOptions}}
            sortValues={false}
            searchable={selectOptions.length > 5}
            clearable={false}
          />
        </div>
        <div className={classes.Table}>
          <ChildRenderer children_={tableFigure} />
        </div>
      </div>
    </ModalWrapper>
  );
};

import classNames from 'classnames';
import classes from './control-box.module.scss';

interface Props {
  children: any;
  className?: string;
}

export const ControlBox = (props: Props) => {
  const {children, className} = props;

  return <div className={classNames(classes.ControlBox, className)}>{children}</div>;
};

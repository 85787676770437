import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import classes from './analysis-parameters-tab.module.scss';
import classNames from 'classnames';
import {Button, TeamSelector, UserGroupLightIcon} from 'ui-components';
import {keyBy, keys, pick, values} from 'lodash';
import {AnalysisDTO} from '../../../../../../objects/dto/analysis.dto';
import {getAnalysisTypeNetworkRequest} from '../../../../../../http/analysis-types.network-requests';
import {yupResolver} from '@hookform/resolvers/yup';
import yup from '../../../../../../config/yup.config';
import {createParametersValidator} from '../../../../../../objects/dto/parameters.dto';
import {sharedClasses} from '../../../../../shared';
import {FormStep} from '../../../../../shared/components/layout/form-step/form-step.component';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {GenericParametersForm} from '../../../../analysis-forms/generic-parameters-form.component';
import {parametersFormModelConfig} from '../../../../../../constants/parameters-form';
import {FormHiddenInputs} from '../../../../../shared/form/components/form-hidden-inputs.component';
import {withLoadBefore} from '../../../../../../core/hoc/with-load-before.hoc';
import {AnalysisType} from '../../../../../../objects/models/analysis-type.model';
import {exists} from 'front-core';
import {PARAMETERS_METADATA_KEY} from '../../../../../../constants/parameters-saved-keys';
import {ParametersFormContextProvider} from '../../../../../shared/core/parameters-form/parameters-form.context';
import {preventSubmitOnEnter} from '../../../../../../utils/general.utils';
import {AnalysisFormProps} from '../../../../analysis-forms/analysis-forms.types';
import {EmptyState} from '../../../../../shared/components/general/override';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import ParameterInputWrapperClasses from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.module.scss';
import {refactorAnalysisParameters} from './refactor-analysis-parameters';
import {TabHeader} from '../../../../../shared/components/general/tab-header/tab-header.component';
import {
  ANALYSIS_FORM_MAP,
  ANALYSIS_VALIDATOR_MAP,
} from '../../../../analysis-forms/analysis-parameters/analysis-mapping';
import {useDemoProduct} from '../../../../../../core/hooks/use-demo-product.hook';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {TeamFilterProvider} from '../../../../../../core/contexts/team-filter.context';

interface OwnProps {
  analysisType?: AnalysisType;
  errors?: {[key: string]: any};
  onSubmit: (data: Partial<AnalysisDTO>) => void;
  onSwitchAnalysis?: (analysisTypeId: number) => void;
  onBack?: () => void;
  submitText?: string;
  data: Partial<AnalysisDTO>;
  onSignalInfo?: (value: string | number) => void;
  className?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const validator = yup.lazy(obj => {
  if (obj.analysisTypeId in ANALYSIS_VALIDATOR_MAP) {
    return ANALYSIS_VALIDATOR_MAP[obj.analysisTypeId];
  }
  const parametersSchema = obj.parametersSchema;

  let parametersValidator = yup.object().required();
  if (parametersSchema) {
    parametersValidator = createParametersValidator(parametersSchema);
  }

  const runParametersSchema = obj.runParametersSchema;
  let runParametersValidator = yup.object().required();
  if (parametersSchema) {
    runParametersValidator = createParametersValidator(runParametersSchema);
  }

  return yup.object().shape({
    parameters: parametersValidator,
    runParameters: runParametersValidator,
  });
});

const HIDDEN_KEYS = ['parameters', 'runParameters', 'parametersSchema', 'runParametersSchema'];
const SELECTED_KEY = 'ANALYSIS_PARAMETERS_TAB/ANALYSIS_TYPE';
export type AfterSubmitHandler = (parameters: any) => any;

const AnalysisParametersTabComponent = (props: AllProps) => {
  const {
    className,
    analysisType,
    errors: loadErrors,
    onSubmit: onSubmitFromProps,
    submitText = 'Next',
    onSwitchAnalysis,
    onBack,
    onSignalInfo,
    data,
  } = props;
  const {t} = useTranslation();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const [teamId, setTeamId] = useState(defaultTeamId);
  const analysisTypeId = data.analysisTypeId;
  const {demoProductValidator} = useDemoProduct();

  const afterSubmitHandler = useRef<(parameters) => any>();
  const setAfterSubmitHandler = useCallback(
    (handler: AfterSubmitHandler) => (afterSubmitHandler.current = handler),
    [afterSubmitHandler]
  );
  const formMethods = useForm({
    defaultValues: {
      analysisTypeId: analysisTypeId,
      // fix analysis parameters - front migration
      parameters: refactorAnalysisParameters(analysisTypeId, data.parameters),
      runParameters: data.runParameters,
    } as any,
    resolver: yupResolver(demoProductValidator || validator),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: {errors},
  } = formMethods;
  const parameters = watch('parameters');
  const runParameters = watch('runParameters');

  // Schemas
  const parametersSchema = useMemo(
    () =>
      keyBy(
        values({...(analysisType?.parametersSchema || {})}).filter(p => p.expose),
        'key'
      ),
    [analysisType]
  );
  const runParametersSchema = useMemo(
    () =>
      keyBy(
        values({...(analysisType?.runParametersSchema || {})}).filter(p => p.expose),
        'key'
      ),
    [analysisType]
  );
  const userSchema = useMemo(
    () => ({...parametersSchema, ...runParametersSchema}),
    [parametersSchema, runParametersSchema]
  );
  const paramsKeys = useMemo(
    () => [...keys(parametersSchema), PARAMETERS_METADATA_KEY],
    [parametersSchema]
  );
  const runParamsKeys = useMemo(() => keys(runParametersSchema), [runParametersSchema]);
  const allParameters = useMemo(
    () => ({...parameters, ...runParameters}),
    [parameters, runParameters]
  );
  // errorsLength - is a "hack" I had to make because for some reason clearErrors
  // is not updating errors instance, so allErrors is not re-evaluating.
  // adding a "dummy" dependency as the errors keys length solved it for now.
  const allErrors = {
    ...(errors?.parameters || {}),
    ...(errors?.runParameters || {}),
  };
  const onParametersChange = useCallback(
    newParameters => {
      setValue('parameters', pick(newParameters, paramsKeys));
      setValue('runParameters', pick(newParameters, runParamsKeys));
      // isSubmitted && trigger(['parameters', 'runParameters']);
    },
    [paramsKeys, runParamsKeys, setValue]
  );
  useEffect(() => {
    setValue('parametersSchema', parametersSchema);
  }, [parametersSchema, setValue]);
  useEffect(() => {
    setValue('runParametersSchema', runParametersSchema);
  }, [runParametersSchema, setValue]);
  const ParametersForm = useMemo(() => {
    if (analysisType) {
      return ANALYSIS_FORM_MAP[analysisType.id] || GenericParametersForm;
    }
  }, [analysisType]);
  const onSubmit = useCallback(
    data => {
      const submitData = pick(data, ['parameters', 'runParameters']);
      // add form mode to parameters ui_metadata
      submitData['parameters'] = {
        ...submitData['parameters'],
        [PARAMETERS_METADATA_KEY]: {
          ...(submitData['parameters'][PARAMETERS_METADATA_KEY] || {}),
        },
      };
      if (afterSubmitHandler.current) {
        const afterSubmitData = afterSubmitHandler.current({
          ...submitData['parameters'],
          ...submitData['runParameters'],
        });
        submitData['parameters'] = pick(afterSubmitData, paramsKeys);
        submitData['runParameters'] = pick(afterSubmitData, runParamsKeys);
      }
      onSubmitFromProps(submitData);
    },
    [onSubmitFromProps, afterSubmitHandler, paramsKeys, runParamsKeys]
  );
  const onError = useCallback(() => {
    // race condition
    setTimeout(() => {
      const elems = Array.from(document.getElementsByClassName(ParameterInputWrapperClasses.Error));
      if (elems.length > 0) {
        elems[0].scrollIntoView({behavior: 'smooth', block: 'end'});
      }
    }, 0);
  }, []);
  const teamOptions = useMemo(
    () => [
      {
        name: 'All',
        id: null,
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );
  const parametersFormProps: AnalysisFormProps = {
    className: classes.ParametersForm,
    allowFutureDates: false,
    onSignalInfo: onSignalInfo,
    onSwitchAnalysis: onSwitchAnalysis,
  };

  if (analysisTypeId === AnalysisTypeId.RETENTION_DRIVERS) {
    return (
      <EmptyState
        title={'Retention drivers analysis'}
        subTitle={
          'Retention drivers is now unified with goal drivers, please run goal drivers instead.'
        }
        buttonText={'Run goal drivers'}
        onClick={() => onSwitchAnalysis(AnalysisTypeId.GOAL_DRIVERS)}
      />
    );
  }
  if (loadErrors.analysisType) {
    return (
      <EmptyState
        title={'Analysis not found'}
        subTitle={'Sorry, we could not find this analysis.'}
        buttonText={'Back'}
        onClick={onBack}
      />
    );
  }

  return (
    <ParametersFormContextProvider
      parameters={allParameters}
      schema={userSchema}
      errors={allErrors}
      onChange={onParametersChange}
      setAfterSubmitHandler={setAfterSubmitHandler}
      schemaConfig={parametersFormModelConfig}
    >
      <TeamFilterProvider teamId={teamId}>
        <FormProvider {...formMethods}>
          <form onKeyDown={preventSubmitOnEnter} className={sharedClasses.NoOverflow}>
            <FormStep
              footer={
                <>
                  {onBack && (
                    <Button variant={'outlined'} type={'button'} onClick={onBack}>
                      {t(TransKeys.GENERAL.ACTIONS.BACK)}
                    </Button>
                  )}
                  <Button onClick={handleSubmit(onSubmit, onError)}>{t(submitText)}</Button>
                </>
              }
            >
              <div className={classNames(classes.AnalysisParametersTab, className)}>
                <div className={classes.Header}>
                  <TabHeader
                    className={classes.TabHeader}
                    title={analysisType?.staticName}
                    subTitle={analysisType?.shortDescription}
                    helperText={t(`ANALYSIS_FORMS.ANALYSIS_${analysisType?.id}.HELPER_TEXT`)}
                    renderRight={
                      teams.length > 0 ? (
                        <TeamSelector
                          className={classes.TeamSelector}
                          teams={teamOptions}
                          value={teamId}
                          onChange={setTeamId}
                          clearable={false}
                          withPrefix
                        />
                      ) : null
                    }
                  />
                </div>
                {ParametersForm && <ParametersForm {...parametersFormProps} />}
              </div>
              <FormHiddenInputs names={HIDDEN_KEYS} />
            </FormStep>
          </form>
        </FormProvider>
      </TeamFilterProvider>
    </ParametersFormContextProvider>
  );
};

export const AnalysisParametersTab = withLoadBefore<AllProps>({
  analysisType: {
    actionKey: SELECTED_KEY,
    selectedKey: SELECTED_KEY,
    request: getAnalysisTypeNetworkRequest,
    mapPayloadFromProps: props => props.data.analysisTypeId,
    shouldCall: props => exists(props.data.analysisTypeId),
  },
})(AnalysisParametersTabComponent);

import classNames from 'classnames';
import {ModelSeriesGranularity} from '../../../../../../../../objects/models/model-sample-series.model';
import classes from './homepage-granularity-options.module.scss';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {Select} from 'ui-components';

interface OwnProps {
  granularity: ModelSeriesGranularity;
  onChange: (granularity: ModelSeriesGranularity) => void;
  options: ModelSeriesGranularity[];
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageGranularityOptions = (props: AllProps) => {
  const {granularity, onChange, options, disabled, className} = props;
  const {t} = useTranslation();

  const granularitySelectOptions = useMemo(() => {
    if (!options) {
      return;
    }
    if (options.length < 2) {
      return;
    }
    return {
      options: options.map(g => ({
        label: t(TransKeys.GENERAL.LABELS.GRANULARITY[g.toUpperCase()]),
        value: g,
      })),
    };
  }, [t, options]);

  const granularityToRender = useMemo(() => {
    if (options.indexOf(granularity) === -1) {
      return options[0];
    }
    return granularity;
  }, [options, granularity]);

  if (!granularitySelectOptions) {
    return (
      <div className={classNames(classes.GranularityDisplay, className)}>
        {t(TransKeys.GENERAL.LABELS.GRANULARITY[granularityToRender.toUpperCase()])}
      </div>
    );
  }

  return (
    <Select
      dropdownButtonClassName={classes.GranularityDropdownButton}
      options={granularitySelectOptions}
      value={granularity}
      onChange={onChange as any}
      clearable={false}
      searchable={false}
      sortValues={false}
      disabled={disabled}
      fitContent
    />
  );
};

export const PARAMETERS_METADATA_KEY = 'ui_metadata';

export enum METADATA_KEY {
  BUILDER_COMPONENT_NAME_KEY = 'builder_component_name',
  SUB_BUILDER_COMPONENT_NAME_KEY = 'sub_builder_component_name',
  BUILDER_VERSION = 'builder_version',
  TABLE_COLUMN_ID_KEY = 'table_column_id',
  TABLE_COLUMN_TYPE_KEY = 'table_column_type',
  TABLE_COLUMN_DATA_TYPE_KEY = 'table_column_data_type',
  TABLE_COLUMN_LITERAL_TYPE_KEY = 'table_column_literal_type',
  TABLE_EVENT_ID_KEY = 'table_event_id_key',
  DISPLAY_NAME_KEY = 'display_name',
  PLAN_SIGNAL_ID_KEY = 'plan_signal_id',
  FIRST_PAYMENT_SIGNAL_ID_KEY = 'first_payment_signal_id',
  CHURN_SIGNAL_ID_KEY = 'churn_signal_id',
  // analyses
  METRIC_ID_KEY = 'metric_id',
  STRATEGY = 'strategy',
  FORM_MODE = 'form_mode',
}

import {Opportunity} from '../../../../objects/models/opportunity.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {
  ActionsDropdown,
  Button,
  ToggleIconButton,
  MoreIcon,
  ActionIcon,
  ShareIcon,
  IconButton,
  SnoozeIcon,
  ArchiveIcon,
  StarSolidIcon,
  StarLightIcon,
} from 'ui-components';
import {
  CrumbNavItem,
  PageHeader,
} from '../../../shared/components/layout/page-header/page-header.component';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../constants/app-routes';
import {Parameter} from '../../../shared/components/general/parameter/parameter.component';
import {get} from 'lodash';
import {formatDate} from '../../../../utils/general.utils';
import {AnalysisParameters} from '../../../analyses/components/analysis-parameters/analysis-parameters.component';

interface OwnProps {
  opportunity: Opportunity;
  onMoveToExploring: () => void;
  onDismiss: () => void;
  onSnooze: () => void;
  onShare: () => void;
  onSeeActions: () => void;
  onSeeKPI: () => void;
}

type AllProps = OwnProps;

export const OpportunityHeader = (props: AllProps) => {
  const {opportunity, onMoveToExploring, onDismiss, onSnooze, onShare, onSeeActions, onSeeKPI} =
    props;
  const {t} = useTranslation();
  // Computed
  const startDate = useMemo(
    () => formatDate(get(opportunity.analysisResult, 'runParameters.start_date')),
    [opportunity]
  );
  const endDate = useMemo(
    () => formatDate(get(opportunity.analysisResult, 'runParameters.end_date')),
    [opportunity]
  );
  const crumbs: CrumbNavItem[] = useMemo(
    () => [
      {
        name: t(TransKeys.OPPORTUNITIES.HEADER.TITLE),
        navigateTo: AppRoutes.opportunities(),
      },
    ],
    [t]
  );
  const dates = useMemo(() => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    }
    return startDate || endDate;
  }, [endDate, startDate]);

  return (
    <PageHeader
      title={opportunity.title}
      crumbs={crumbs}
      actions={
        <>
          <ToggleIconButton
            titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
            titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
            onChange={onMoveToExploring}
            isChecked={opportunity.isExplored}
            size={'large'}
            icon={StarLightIcon}
            checkedIcon={StarSolidIcon}
          />
          <IconButton
            tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
            icon={ShareIcon}
            onClick={() => onShare()}
            size={'large'}
          />
          <ActionsDropdown
            iconSize={'large'}
            actions={[
              {
                title: t(
                  TransKeys.GENERAL.ACTIONS[opportunity.snoozeUntil ? 'UN_SNOOZE' : 'SNOOZE']
                ),
                onClick: onSnooze,
                icon: SnoozeIcon,
              },
              {
                title: t(
                  TransKeys.GENERAL.ACTIONS[opportunity.isDismissed ? 'UNARCHIVE' : 'ARCHIVE']
                ),
                onClick: onDismiss,
                icon: ArchiveIcon,
              },
            ]}
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
          />
          {opportunity.actions.length > 0 && (
            <Button icon={ActionIcon} variant={'outlined'} onClick={onSeeActions}>
              {t(TransKeys.GENERAL.ACTIONS.SEE_ACTION_COUNT, {
                count: opportunity.actions?.length,
              })}
            </Button>
          )}
        </>
      }
      subHeaderItems={[
        <Parameter
          label={t(TransKeys.GENERAL.LABELS.ANALYSIS)}
          value={opportunity.analysisResult.userAnalysisName || opportunity.analysis.title}
          link={AppRoutes.viewAnalysis(opportunity.analysisId, {
            [ANALYSIS_RESULT_ID_PATH_PARAM]: opportunity.analysisResultId,
          })}
        />,
        onSeeKPI && opportunity.metric?.id && (
          <Parameter
            label={t(TransKeys.GENERAL.LABELS.KPI)}
            value={opportunity.metric.name}
            onClick={onSeeKPI}
          />
        ),
        <Parameter label={t(TransKeys.GENERAL.LABELS.TIME_FRAME)} value={dates} />,
        <AnalysisParameters
          label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
          analysisResultId={opportunity.analysisResultId}
          border={false}
        />,
      ]}
    />
  );
};

import {Epic} from 'redux-observable';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {createFeedbackNetworkRequest} from '../../http/feedbacks.network-requests';
import {notifyEvent} from '../core/core.actions';
import {createRequestEpic} from 'front-core';
import {FeedbacksActionType} from './feedbacks.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {Feedback} from '../../objects/models/feedback.model';

export const createFeedbackEpic: Epic = createRequestEpic(
  {
    types: [FeedbacksActionType.CREATE_FEEDBACK],
    actionKey: ActionKey.CREATE_FEEDBACK,
    request: createFeedbackNetworkRequest,
    onSuccess: (feedback: Feedback) => [
      notifyEvent(AmplitudeEvent.FEEDBACK_CREATED_SUCCESS, {
        id: feedback.id,
        modelType: feedback.modelType,
        modelId: feedback.modelId,
        key: feedback.key,
        isPositive: feedback.isPositive,
      }),
    ],
    onError: err_ => [
      showToastMessage(i18n.t(TransKeys.TOASTS.MARK_USEFUL_ERROR), ToastType.ERROR),
    ],
  },
  HttpClient
);

export enum RCASegmentationViewMode {
  EXPLAIN = 'explain',
  CHANGE = 'change',
}

export enum RCASegmentationUsersMode {
  SHARE = 'share',
  COUNT = 'count',
}

export enum RCAImpactMode {
  SHARE = 'share',
  VALUE = 'value',
}

import React from 'react';
import {useContext} from 'react';
import TransKeys from 'translations';
import {
  AlertIcon,
  ChartSineLightIcon,
  ChartUserIcon,
  FlaskIcon,
  GraphIcon,
  NetworkWiredLightIcon,
  UserCircleLightIcon,
  UserMagnifyingGlassLightIcon,
  UsersIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {
  RCAElements,
  RCAElementType,
  RCAMode,
  RCASeasonalityExplainerFigure,
} from '../../../../types';
import {renderDates} from './components/rca-header/rca-header.utils';
import {RCAFigureViewerContext} from './rca-figure-viewer.context';
import {values} from 'lodash';
import {exists} from 'front-core';

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_FIGURE;

type RCAElement = RCAElements[keyof RCAElements];
type ElementConfig = {
  label: string;
  subtitle?: string;
  key: RCAElementType;
  icon: React.ComponentType;
  shouldShow?: (element: RCAElement) => boolean;
  // value field is only used for navigation side menu, key is used for rendering
  value: RCAElementType;
};

export const useRCAElements = (rcaElements: RCAElements): ElementConfig[] => {
  const {t} = useDocumentTranslation();
  const {
    mode,
    rcaPeriods: {comparedDates},
  } = useContext(RCAFigureViewerContext);

  const subtitle =
    mode === RCAMode.LOOPS_ALGO
      ? `(${t(TransKeysPrefix.SECTIONS.SEGMENTATION.LOOPS_MODE.SUBTITLE)})`
      : `(${t(TransKeysPrefix.SECTIONS.SEGMENTATION.COMPARED_MODE.SUBTITLE)} ${renderDates({
          startDate: comparedDates.startDate,
          endDate: comparedDates.endDate,
        })})`;

  const elementsDict: Partial<Record<RCAElementType, ElementConfig>> = {
    [RCAElementType.KPI_TREE_EXPLAINER]: {
      // todo: translate
      label: 'KPI Tree',
      subtitle,
      key: RCAElementType.KPI_TREE_EXPLAINER,
      value: RCAElementType.KPI_TREE_EXPLAINER,
      icon: NetworkWiredLightIcon,
    },
    [RCAElementType.SEGMENTATION]: {
      label: t(TransKeysPrefix.SECTIONS.SEGMENTATION.TITLE),
      subtitle,
      key: RCAElementType.SEGMENTATION,
      value: RCAElementType.SEGMENTATION,
      icon: UsersIcon,
    },
    [RCAElementType.ENTITY_LABEL_EXPLAINER]: {
      label: 'Top Explaining Entity',
      subtitle,
      key: RCAElementType.ENTITY_LABEL_EXPLAINER,
      value: RCAElementType.ENTITY_LABEL_EXPLAINER,
      icon: UserCircleLightIcon,
    },
    [RCAElementType.POPULATION_EXPLAINER]: {
      label: t(TransKeysPrefix.SECTIONS.POPULATION.TITLE),
      subtitle,
      key: RCAElementType.POPULATION_EXPLAINER,
      value: RCAElementType.POPULATION_EXPLAINER,
      icon: ChartUserIcon,
    },
    [RCAElementType.SEASONALITY_EXPLAINER]: {
      label: t(TransKeysPrefix.SECTIONS.SEASONALITY.TITLE),
      subtitle,
      key: RCAElementType.SEASONALITY_EXPLAINER,
      value: RCAElementType.SEASONALITY_EXPLAINER,
      icon: ChartSineLightIcon,
      shouldShow: (element: RCASeasonalityExplainerFigure) => {
        return element.data.lastYearSamples?.length > 0;
      },
    },
    [RCAElementType.EXPERIMENTS_EXPLAINER]: {
      label: t(TransKeysPrefix.SECTIONS.EXPERIMENTS.TITLE),
      subtitle,
      key: RCAElementType.EXPERIMENTS_EXPLAINER,
      value: RCAElementType.EXPERIMENTS_EXPLAINER,
      icon: FlaskIcon,
    },
    // todo: remove this is deprecated
    [RCAElementType.POPULATION_SIZE]: {
      label: t(TransKeysPrefix.SECTIONS.POPULATION.TITLE),
      subtitle,
      key: RCAElementType.POPULATION_SIZE,
      value: RCAElementType.POPULATION_SIZE,
      icon: ChartUserIcon,
    },
    // todo: remove this is deprecated
    [RCAElementType.EXPERIMENTS]: {
      label: t(TransKeysPrefix.SECTIONS.EXPERIMENTS.TITLE),
      subtitle,
      key: RCAElementType.EXPERIMENTS,
      value: RCAElementType.EXPERIMENTS,
      icon: FlaskIcon,
    },
    [RCAElementType.ERRORS]: {
      label: t(TransKeysPrefix.SECTIONS.ERRORS.TITLE),
      subtitle,
      key: RCAElementType.ERRORS,
      value: RCAElementType.ERRORS,
      icon: AlertIcon,
    },
    [RCAElementType._CHANGING_SEGMENTS]: {
      label: t(TransKeysPrefix.SECTIONS.CHANGING_SEGMENTS.TITLE),
      subtitle,
      key: RCAElementType._CHANGING_SEGMENTS,
      value: RCAElementType._CHANGING_SEGMENTS,
      icon: UserMagnifyingGlassLightIcon,
    },
  };

  return [
    {
      label: 'Overview',
      key: null,
      value: RCAElementType.OVERVIEW,
      icon: GraphIcon,
    },
    ...values(elementsDict).filter(e => {
      const element = rcaElements[e.key];
      if (!exists(element)) {
        return false;
      }

      const shouldShow = e.shouldShow ? e.shouldShow(element) : true;
      return shouldShow;
    }),
    elementsDict[RCAElementType._CHANGING_SEGMENTS],
  ];
};

import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  DocumentElementType,
  RetentionSegmentationFigure,
  RetentionSegmentationItem,
  RetentionSegmentationItemType,
} from '../../../../types';
import classes from './retention-segmentation-viewer.module.scss';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {SimulationMode, useSimulatorMode} from '../../../../hooks/use-simulator-mode.hook';
import TransKeys from 'translations';
import {extendRetentionSegmentationItems} from './retention-segmentation-viewer.utils';
import {capitalize, groupBy, keys} from 'lodash';
import {exists} from 'front-core';
import {generateRetentionLabels} from '../retention-overview-viewer/retention-overview-viewer.utils';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {
  BucketLightIcon,
  UserGroupLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {ShareOfUsersFilter} from '../../../shared/filters/share-of-users-filter/share-of-users-filter.component';
import pluralize from 'pluralize';
import {RetentionSegmentationDataModeTable} from './components/retention-segmentation-tables/retention-segmentation-data-mode-table.component';
import {RetentionSegmentationSimulatorMode} from './components/retention-segmentation-tables/retention-segmentation-simulator-mode.component';
import {RetentionSegmentationModal} from './components/retention-segmentation-modal/retention-segmentation-modal.component';
import {calculateMinMaxShare} from '../../utils/segmentation.utils';

interface OwnProps extends RetentionSegmentationFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedRetentionSegmentationItem extends RetentionSegmentationItem {
  actualType: RetentionSegmentationItemType;
  share: number;
  type: RetentionSegmentationItemType[];
  uplift: number[];
  avgUplift: number[];
  goalInSegment: number[];
  goalInNonSegment: number[];
  bucketCount: number[];
  potentialLift: number[];
  potentialLiftUpper: number[];
  potentialLiftLower: number[];
  helperText?: string[];
  info: string[];
}

interface Filters {
  group?: string;
  selectedBucketIndex?: number;
  shareOfUsers?: {min: number; max: number};
}

export interface RetentionSegmentGroup {
  name: string;
  segments: ExtendedRetentionSegmentationItem[];
}

const DEFAULT_BUCKET = 1;

const DEFAULT_FILTERS: Filters = {
  group: null,
  selectedBucketIndex: DEFAULT_BUCKET,
  shareOfUsers: null,
};

const MIN_GROUPS_FOR_SEARCH = 6;

export const RetentionSegmentationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: dataFromProps, options, className} = props;
  const {t} = useDocumentTranslation();
  const {trackModalOpened, trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.RETENTION_SEGMENTATION_FIGURE
  );
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: DocumentElementType.FUNNEL_SEGMENTATION_FIGURE,
    isCasual: true,
    label: t(TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE_CAUSAL),
    helperText: t(
      TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE_CAUSAL_HELPER
    ),
    disabled: options.simulatorMode === false,
    disabledTooltip: 'Soon',
    afterModeChange: mode => {
      if (mode === SimulationMode.SIMULATOR) {
        onFilterChange({selectedBucketIndex: null});
      } else {
        onFilterChange({selectedBucketIndex: DEFAULT_BUCKET});
      }
    },
    queryPrefix: 'segmentation',
  });
  const [selectedGroup, setSelectedGroup] = useState<RetentionSegmentGroup>(null);
  const data = useMemo(
    () => ({
      ...dataFromProps,
      items: extendRetentionSegmentationItems(
        dataFromProps.items,
        dataFromProps.goalValue,
        dataFromProps.totalEntities
      ),
    }),
    [dataFromProps]
  );
  const itemsGroupedBySegmentGroup = useMemo(
    () => groupBy(data.items, 'segmentGroupName.0'),
    [data.items]
  );
  const tableData = useMemo(() => {
    let tableData = data.items;
    if (filters.group) {
      tableData = tableData.filter(i => i.segmentGroupName[0] === filters.group);
    }
    if (filters.shareOfUsers && mode === SimulationMode.SIMULATOR) {
      tableData = tableData.filter(i => {
        return (
          i.share >= filters.shareOfUsers.min / 100 && i.share <= filters.shareOfUsers.max / 100
        );
      });
    }
    return tableData;
  }, [data, filters.group, filters.shareOfUsers, mode]);
  const groupNames = useMemo(() => keys(itemsGroupedBySegmentGroup), [itemsGroupedBySegmentGroup]);
  const groupOptions = useMemo(() => groupNames.map(o => ({value: o, label: o})), [groupNames]);
  const buckets = useMemo(
    () => generateRetentionLabels(data.timeUnit, data.timeUnitN, data.allPopulation.buckets.length),
    [data]
  );
  const bucketOptions = useMemo(() => {
    return buckets.map((label, index) => ({
      label: capitalize(label),
      value: index,
    }));
  }, [buckets]);
  const simulatorModeBucketOptions = useMemo(
    () => [
      {
        value: null,
        label: t(TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.LABELS.ANY_BUCKET),
      },
      ...bucketOptions,
    ],
    [bucketOptions, t]
  );
  const shareOfUsersMinMaxValue = useMemo(() => calculateMinMaxShare(data.items), [data]);
  const onGroupSelected = useCallback(
    (groupName: string) => {
      if (!exists(groupName)) {
        setSelectedGroup(null);
        return;
      }
      const group = itemsGroupedBySegmentGroup[groupName];
      setSelectedGroup({
        name: groupName,
        segments: group,
      });
      trackModalOpened('view_segment_group', {name: groupName});
    },
    [itemsGroupedBySegmentGroup, trackModalOpened]
  );
  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );

  const renderFilters = () => {
    return (
      <div className={classes.Filters}>
        <Select
          key={mode}
          className={classes.Filter}
          dropdownButtonClassName={classes.Select}
          prefix={t(TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.SELECT_BUCKET_PREFIX)}
          value={filters.selectedBucketIndex}
          onChange={(v: any) => onFilterChange({selectedBucketIndex: v})}
          searchable={false}
          sortValues={false}
          clearable={false}
          icon={BucketLightIcon}
          options={{
            options: mode === SimulationMode.SIMULATOR ? simulatorModeBucketOptions : bucketOptions,
          }}
        />
        <Select
          className={classes.Filter}
          dropdownButtonClassName={classes.Select}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME.LABEL
          )}
          value={filters.group}
          onChange={(v: any) => onFilterChange({group: v})}
          searchable={groupNames.length > MIN_GROUPS_FOR_SEARCH}
          icon={UserGroupLightIcon}
          options={{
            options: groupOptions,
          }}
        />
        {mode === SimulationMode.SIMULATOR && (
          <ShareOfUsersFilter
            className={classes.Filter}
            {...shareOfUsersMinMaxValue}
            value={filters.shareOfUsers}
            onChange={v => onFilterChange({shareOfUsers: v})}
            placeholder={t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X
                .LABEL,
              {
                entity: pluralize(options.entity),
              }
            )}
          />
        )}
      </div>
    );
  };
  return (
    <div className={classNames(classes.RetentionSegmentationViewer, className)}>
      <div className={classes.TabsWrapper}>
        {renderFilters()}
        {simulatorButton}
      </div>
      <div className={classes.Content}>
        {mode === SimulationMode.DATA && (
          <RetentionSegmentationDataModeTable
            figureId={id}
            options={options}
            items={tableData}
            totalEntities={data.totalEntities}
            onSeeAll={onGroupSelected}
            selectedBucketIndex={filters.selectedBucketIndex}
          />
        )}
        {mode === SimulationMode.SIMULATOR && (
          <RetentionSegmentationSimulatorMode
            figureId={id}
            options={options}
            items={tableData}
            goalValue={data.goalValue}
            buckets={buckets}
            selectedBucketIndex={filters.selectedBucketIndex}
            // onFollowUp={onFollowUp}
          />
        )}
      </div>
      {selectedGroup && (
        <RetentionSegmentationModal
          figureId={id}
          selectedBucketIndex={filters.selectedBucketIndex}
          allPopulation={data.allPopulation}
          buckets={buckets}
          goalValue={data.goalValue}
          group={selectedGroup}
          groupOptions={groupNames}
          onSelectGroup={onGroupSelected}
          entity={options.entity}
          totalEntities={data.totalEntities}
        />
      )}
    </div>
  );
};

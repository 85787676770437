import classes from './message-suggestions.module.scss';
import {useCallback, useEffect, useState} from 'react';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {
  ArrowTrendDownDuotoneIcon,
  ShapesDuotoneIcon,
  useRemoteSourceStated,
  UsersDuotoneIcon,
  ArrowRotateRightLightIcon,
} from 'ui-components';
import {getAIChatSuggestionsNetworkRequest} from '../../../../../../http/ai-chart.network-requests';
import {sampleSize} from 'lodash';
import {
  AIChatMessageSuggestionDTO,
  AIChatMessageSuggestionType,
} from '../../../../../../objects/dto/ai-chat.dto';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface Props {
  onSelectSuggestion: (suggestion: AIChatMessageSuggestionDTO) => void;
}

const iconSuggestionMap = {
  [AIChatMessageSuggestionType.DROP]: <ArrowTrendDownDuotoneIcon className={classes.DropIcon} />,
  [AIChatMessageSuggestionType.USER]: <UsersDuotoneIcon className={classes.UsersIcon} />,
  [AIChatMessageSuggestionType.COMPARE]: <ShapesDuotoneIcon className={classes.CompareIcon} />,
};

export const MessageSuggestions = (props: Props) => {
  const {onSelectSuggestion} = props;
  const {t} = useTranslation();
  const [suggestionsToShow, setSuggestionsToShow] = useState([]);
  const {
    source: suggestions,
    exec: getSuggestions,
    isLoading: isLoadingSuggestions,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getAIChatSuggestionsNetworkRequest,
  });

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  const selectSuggestionsToShow = useCallback(() => {
    return setSuggestionsToShow(sampleSize(suggestions, 4));
  }, [suggestions]);

  useEffect(() => {
    suggestions && selectSuggestionsToShow();
  }, [suggestions, selectSuggestionsToShow]);

  return (
    <div className={classes.MessageSuggestions}>
      {isLoadingSuggestions && <GenericLoading />}
      <div className={classes.Suggestions}>
        {suggestionsToShow.map(suggestion => (
          <div className={classes.Suggestion} onClick={() => onSelectSuggestion(suggestion)}>
            <div className={classes.SuggestionIconContainer}>
              {iconSuggestionMap[suggestion.type]}
            </div>
            <div className={classes.SuggestionMessage}>{suggestion.message}</div>
          </div>
        ))}
      </div>
      <div className={classes.RegenerateSuggestionsContainer}>
        <div className={classes.RenegerateSuggestionsBtn} onClick={selectSuggestionsToShow}>
          <ArrowRotateRightLightIcon className={classes.Icon} />
          <div>{t(TransKeys.GENERAL.ACTIONS.REGENERATE)}</div>
        </div>
      </div>
    </div>
  );
};

import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model';
import {useCallback, useMemo} from 'react';
import {get} from 'lodash';
import {useQueryArray} from 'ui-components';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../../shared/core/smart-selector/signal-smart-selector.component';

interface SchemaKeysMapping {
  attributes: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  attributes: 'attributes',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext: TableEntity;
  onSignalInfo?: (value: number) => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const createGroupByFilters = entityContext => ({
  tag: ['entity_property', 'segment'],
  entityContext: entityContext,
  entityBinding: TableEntityBinding.ONE_WAY,
});

export const AttributesSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    onChange,
    value,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    entityContext,
    onSignalInfo,
    disabled,
    variant,
    errors,
    className,
  } = props;
  const {t} = useTranslation();
  const attributes = get(value, schemaKeysMapping.attributes, []);
  const filters = useMemo(() => {
    let existing = attributes || [];
    existing = existing.filter(v => v !== null);
    return {
      ...createGroupByFilters(entityContext),
      excludeId: existing.length > 0 ? existing : undefined,
    };
  }, [entityContext, attributes]);
  const hasNull = get(value, schemaKeysMapping.attributes, []).some(v => v === null);
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.attributes, []),
    data => onChange({[schemaKeysMapping.attributes]: data}),
    () => null
  );
  const onAttributeChange = useCallback(
    (pValue: any, idx: number) => {
      if (pValue === null) {
        removeElement(idx);
        return;
      }
      const existing = get(value, schemaKeysMapping.attributes, []) || [];
      const actions = [...existing];
      actions[idx] = pValue;
      onChange({[schemaKeysMapping.attributes]: actions});
    },
    [onChange, removeElement, schemaKeysMapping, value]
  );

  const content = (
    <ItemList
      inline={variant === AnalysisSelectorVariant.INLINE}
      items={value[schemaKeysMapping.attributes]}
      renderItem={(item, idx) => (
        <SignalSmartSelector
          placeholder={'Select'}
          onChange={value => onAttributeChange(value, idx)}
          value={value[schemaKeysMapping.attributes]?.[idx]}
          error={Boolean(errors?.[schemaKeysMapping.attributes]?.[idx])}
          onSignalInfo={onSignalInfo as any}
          include={[SignalSmartSelectorKey.SIGNALS]}
          filters={filters}
          clearable={variant === AnalysisSelectorVariant.INLINE}
          disabled={disabled}
        />
      )}
      onRemoveItem={variant === AnalysisSelectorVariant.INLINE ? undefined : removeElement}
      onAddItem={hasNull ? undefined : addElement}
      disabled={disabled}
      min1Item={true}
    />
  );

  if (variant === AnalysisSelectorVariant.INLINE) {
    return content;
  }

  return (
    <ParameterInputWrapper
      title={title || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.TITLE)}
      subTitle={subTitle || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.SUB_TITLE)}
      helperText={helperText || t(TransKeys.ANALYSIS_FORMS.SHARED.ATTRIBUTED_SELECTOR.HELPER_TEXT)}
      className={className}
    >
      {content}
    </ParameterInputWrapper>
  );
};

import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AlertSolidIcon, HourGlassHalfRegularIcon, TrophyStarSolidIcon} from 'ui-components';
import {
  Experiment,
  ExperimentStatus,
} from '../../../../../../../../../../objects/models/experiment.model';

import classes from './status-icon.module.scss';

type ExperimentStatusIconProps = {
  experiment: Experiment;
};

export const ExperimentStatusIcon = (props: ExperimentStatusIconProps) => {
  const {experiment} = props;

  const {t} = useTranslation();

  const isWinner = useMemo(
    () =>
      experiment?.lastCompletedAnalysisResult?.outputs &&
      Object.values(experiment.lastCompletedAnalysisResult.outputs).some(
        output => (output as any)?.isWinner
      ),
    [experiment]
  );

  if (!experiment.lastCompletedAnalysisResult) {
    return (
      <Tooltip title={t(TransKeys.EXPERIMENTS.STATUS_ICON.NO_COMPLETED_RESULTS)}>
        <HourGlassHalfRegularIcon className={classes.Hourglass} />
      </Tooltip>
    );
  }

  const otherData = experiment.lastCompletedAnalysisResult?.outputs?.otherData;

  if (otherData?.hasValidationErrors) {
    return (
      <Tooltip title={otherData?.validationErrorDescription} interactive={false}>
        <AlertSolidIcon className={classes.Warning} />
      </Tooltip>
    );
  }

  if (isWinner) {
    return (
      <Tooltip title={t(TransKeys.EXPERIMENTS.STATUS_ICON.IS_WINNER)}>
        <div className={classes.TrophyContainer}>
          <TrophyStarSolidIcon />
        </div>
      </Tooltip>
    );
  }

  if (experiment.status === ExperimentStatus.IN_PROGRESS) {
    return (
      <Tooltip title={t(TransKeys.EXPERIMENTS.STATUS_ICON.ACTIVE)}>
        <div className={classes.GreenDot} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t(TransKeys.EXPERIMENTS.STATUS_ICON.COMPLETED_WITHOUT_WINNER)}>
      <div className={classes.GrayDot} />
    </Tooltip>
  );
};

import {createRoot} from 'react-dom/client';
import './index.scss';
import 'ui-components/dist/index.css';
import './config/i18n.config';
import {Suspense} from 'react';
import './utils/yup.utils';
import {store} from './store/app.store';
import App from './modules/root/app.component';
import {RootLoadingScreen} from './modules/root/components/root-loading-screen/root-loading-screen.component';
import {StoreConnectProvider, HttpClientContext} from 'front-core';
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from './config/mui.config';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {initApp} from './init-app';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {QueryParamProvider} from 'use-query-params';
import HttpClient from './services/http-client.service';
import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {growthbook} from './config/growthbook.config';
import {HistoryRouter} from 'redux-first-history/rr6';
import {createReduxHistory} from 'front-core';
import {BrowserRouter} from 'react-router';

const history = createReduxHistory(store);
initApp();

createRoot(document.getElementById('root')!).render(
  <Suspense fallback={<RootLoadingScreen />}>
    <HttpClientContext.Provider value={HttpClient}>
      <BrowserRouter>
        <StoreConnectProvider store={store}>
          <HistoryRouter history={history}>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <GrowthBookProvider growthbook={growthbook}>
                    <App />
                  </GrowthBookProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </QueryParamProvider>
          </HistoryRouter>
        </StoreConnectProvider>
      </BrowserRouter>
    </HttpClientContext.Provider>
  </Suspense>
);

import {
  SegmentSentenceExplainer,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease,
} from '../rca-insights.model.ts';
import sharedClasses from '../../insights.module.scss';
import {
  ChangeInShareOfEntitiesSentencePart,
  ScrollToSectionSentencePart,
  SegmentAnalyzedPeriodMeasuredValueSentenceParts,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import * as React from 'react';
import {HoverHelperTip, RCAElementType} from 'ui-components';
import {RCAExplainersIcon} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAExplainersIconVariant} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';

interface OwnProps extends SegmentSentenceExplainer {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter: React.FC<
  AllProps
> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts, onViewOnPage, explainabilityLabel} = props;
  const {
    entityChangeDescription,
    changeInShareOfEntities,
    entityChangeDescriptionSynonym,
    entity,
    presentTensePerformanceVsAvg,
    segmentChangeDescription,
    changeDescription,
  } = formattedSentenceParts;

  return (
    <SentenceContainer className={sharedClasses.Vertical}>
      <SentenceContainer className={sharedClasses.Spaced}>
        {explainabilityLabel && (
          <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
        )}
        <RCAExplainersIcon variant={RCAExplainersIconVariant.SEGMENT_EXPLAINER} />
        <SegmentAnalyzedPeriodMeasuredValueSentenceParts
          segmentViewData={sentenceParts.segmentViewData}
          conjugatedPerformance={formattedSentenceParts.pastTensePerformanceVsPriorPeriod}
          changeInValue={sentenceParts.changeInValue}
          higherIsBetter={sentenceParts.higherIsBetter}
          segmentValue={sentenceParts.value}
          segmentExpectedValue={sentenceParts.expectedValue}
        />
        <SentencePart
          text={`and the share of ${entity} from this segment ${entityChangeDescription}d by`}
        />
        <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
        <SentencePart text={'.'} className={sharedClasses.RemoveLeftGap} />
        <HoverHelperTip
          title={
            <SentenceContainer className={sharedClasses.SecondaryULContainer}>
              <SentencePart text={`This is a driver for the ${changeDescription} because:`} />
              <div className={sharedClasses.SecondaryUL}>
                <span className={sharedClasses.Bullet} />
                <SentencePart
                  text={`You had ${entityChangeDescriptionSynonym} ${entity} from a segment that is ${presentTensePerformanceVsAvg} vs the average`}
                />
                <span className={sharedClasses.Bullet} />
                <SentencePart
                  text={`The KPI value of this segment ${segmentChangeDescription}d vs the analyzed period`}
                />
              </div>
            </SentenceContainer>
          }
        />
        {onViewOnPage && (
          <ScrollToSectionSentencePart
            onViewOnPage={() => onViewOnPage(RCAElementType.SEGMENTATION)}
          />
        )}
      </SentenceContainer>
    </SentenceContainer>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter};

import classNames from 'classnames';
import classes from './segment-breakdown-selector.module.scss';
import {Accordion, useQueryArray} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component.tsx';
import {SegmentSmartSelector} from '../../../../../shared/core/smart-selector/segment-smart-selector.component.tsx';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface Props {
  breakdownSegmentIds: number[];
  onChangeBreakdownSegmentIds: (segmentIds: number[]) => void;
  disabled?: boolean;
  className?: string;
}

export const SegmentBreakdownSelector = (props: Props) => {
  const {breakdownSegmentIds, onChangeBreakdownSegmentIds, disabled, className} = props;
  const {t} = useTranslation();
  const {addElement, removeElement} = useQueryArray(
    breakdownSegmentIds || [],
    data => onChangeBreakdownSegmentIds(data),
    () => null
  );
  const subTitle = useMemo(() => {
    const actual = breakdownSegmentIds.filter(Boolean);
    if (actual.length === 0) {
      return t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SEGMENT_BREAKDOWN.SUBTITLE.NONE_SELECTED);
    }
    return t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SEGMENT_BREAKDOWN.SUBTITLE.COUNT_SELECTED, {
      count: actual.length,
    });
  }, [breakdownSegmentIds, t]);
  const onChange = useCallback(
    (value: number, idx: number) => {
      const newSegmentIds = [...breakdownSegmentIds];
      newSegmentIds[idx] = value;
      onChangeBreakdownSegmentIds(newSegmentIds);
    },
    [breakdownSegmentIds, onChangeBreakdownSegmentIds]
  );

  return (
    <Accordion
      title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SEGMENT_BREAKDOWN.TITLE)}
      subTitle={subTitle}
      disabled={disabled}
      className={classNames(classes.SegmentBreakdownSelector, className)}
    >
      <div className={classes.Content}>
        <div className={classes.Description}>
          {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SEGMENT_BREAKDOWN.DESCRIPTION)}
        </div>
        <ItemList
          items={breakdownSegmentIds}
          onRemoveItem={idx => removeElement(idx)}
          onAddItem={() => addElement()}
          disabled={disabled}
          maxItems={2}
          min1Item={false}
          withPlaceholder
          renderItem={(segmentId, idx) => (
            <SegmentSmartSelector
              placeholder={'Select segment group'}
              key={idx}
              value={segmentId}
              onChange={segmentId => onChange(segmentId, idx)}
            />
          )}
        />
      </div>
    </Accordion>
  );
};

import {ModelType} from '../../../../consts/model-type';
import {DocumentTrackingType} from './document-tracking.types';
import {DocumentElementType} from './document.types';

export enum CommandType {
  NAVIGATION = 'NAVIGATION',
  REF = 'REF',
  CREATION = 'CREATION',
  NOTIFY = 'NOTIFY',
  VIEW_ARTICLE = 'VIEW_ARTICLE',
  DOCUMENT_ANCHOR = 'DOCUMENT_ANCHOR',
  FOLLOW_UP = 'FOLLOW_UP',
  ANALYSIS_FIGURE_SWITCH_VIEW = 'ANALYSIS_FIGURE_SWITCH_VIEW',
}

export interface DocumentCommand<T> {
  type: CommandType;
  payload: T;
  // internal use
  element?: any;
}

export enum NavigationType {
  MODAL = 'MODAL',
  PAGE = 'PAGE',
  POPOVER = 'POPOVER',
}

export enum FollowUpType {
  RCA_FROM_FUNNEL_ANALYSIS_LINE_CHART = 'RCA_FROM_FUNNEL_ANALYSIS_LINE_CHART',
  RCA_FROM_KPI_ANALYSIS_LINE_CHART = 'RCA_FROM_KPI_ANALYSIS_LINE_CHART',
  FUNNEL_ANALYSIS_CONVERSION = 'FUNNEL_ANALYSIS_CONVERSION',
  GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION = 'GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION',
  RETENTION_ANALYSIS_BUCKET_INVESTIGATION = 'RETENTION_ANALYSIS_BUCKET_INVESTIGATION',
  RCA_FROM_SEGMENTATION_EXPLAINER = 'RCA_FROM_SEGMENTATION_EXPLAINER',
  RCA_SEGMENTATION_FOLLOW_UP = 'RCA_SEGMENTATION_FOLLOW_UP',
}
/**
 * Notify
 */
export interface NotifyCommandPayload<V, I> {
  id: string;
  trackingType?: DocumentTrackingType;
  elementType: DocumentElementType;
  value?: V;
  info?: I;
}

/**
 * Navigation
 */
export interface NavigationCommandPayload {
  text?: string;
  documentId: string;
  type: NavigationType;
  parameters?: any;
}

export interface NavigationCommand extends DocumentCommand<NavigationCommandPayload> {
  type: CommandType.NAVIGATION;
}

/**
 * Creation
 */
export interface CreationCommandPayload {
  text: string;
  type: ModelType;
  parameters?: any;
}

export interface CreationCommand extends DocumentCommand<CreationCommandPayload> {
  type: CommandType.CREATION;
}

/**
 * Ref
 */
export interface RefCommandPayload {
  type: ModelType;
  refId: any;
  parameters?: any;
}

export interface RefCommand extends DocumentCommand<RefCommandPayload> {
  type: CommandType.REF;
}

/**
 * View Article
 */
export interface ViewArticleCommandPayload {
  articleId: string;
  text: string;
}

export interface ViewArticleCommand extends DocumentCommand<ViewArticleCommandPayload> {
  type: CommandType.VIEW_ARTICLE;
}

/**
 * Document Anchor
 */
export interface DocumentAnchorCommandPayload {
  id: string; // can be slug or element id
}

export interface DocumentAnchorCommand extends DocumentCommand<DocumentAnchorCommandPayload> {
  type: CommandType.DOCUMENT_ANCHOR;
}

/**
 * Analysis Figure Switch View
 */
export interface AnalysisFigureSwitchViewCommandPayload {
  contentKey: string;
  text: string;
  parameters?: any;
}

export interface AnalysisFigureSwitchViewCommand
  extends DocumentCommand<AnalysisFigureSwitchViewCommandPayload> {
  type: CommandType.ANALYSIS_FIGURE_SWITCH_VIEW;
}

/**
 * Follow-Up parameters
 */
interface SharedAnalysisFollowUpParameters {
  segment?: number;
  segmentClass?: any[];
}

interface SharedRCAFromLineChartFollowUpParameters {
  fromToLabel: string;
  startDateAnomaly: number;
  timeAggregation: string;
  title: string;
}

export interface KPIAnalysisLineChartFollowUpParameters
  extends SharedAnalysisFollowUpParameters,
    SharedRCAFromLineChartFollowUpParameters {
  goal: number;
}

export interface FunnelAnalysisLineChartFollowUpParameters
  extends SharedAnalysisFollowUpParameters,
    SharedRCAFromLineChartFollowUpParameters {
  goalSignalId: number;
  refDateSignalId: number;
  signalName: string;
}

export interface FunnelAnalysisConversionFollowUpParameters
  extends SharedAnalysisFollowUpParameters {
  funnel: number;
  goal: number;
  refDate: number;
}

export interface GoalDriversAnalysisImproveFeatureAdoptionFollowUpParameters
  extends SharedAnalysisFollowUpParameters {
  featureSignal: number;
  featuresSignals?: number[];
  defaultFeatureSignalId?: number;
}

export interface RetentionAnalysisBucketInvestigationFollowUpParameters
  extends SharedAnalysisFollowUpParameters {
  bucket: number;
  numberOfBuckets: number;
}
/**
 * Follow-Up payloads
 */
export interface FollowUpCommandPayload<T> {
  type: FollowUpType;
  parameters?: T;
}

export interface KPIAnalysisLineChartFollowUpCommandPayload
  extends FollowUpCommandPayload<KPIAnalysisLineChartFollowUpParameters> {
  type: FollowUpType.RCA_FROM_KPI_ANALYSIS_LINE_CHART;
}

export interface FunnelAnalysisLineChartFollowUpCommandPayload
  extends FollowUpCommandPayload<FunnelAnalysisLineChartFollowUpParameters> {
  type: FollowUpType.RCA_FROM_FUNNEL_ANALYSIS_LINE_CHART;
}

export interface FunnelAnalysisConversionFollowUpCommandPayload
  extends FollowUpCommandPayload<FunnelAnalysisConversionFollowUpParameters> {
  type: FollowUpType.FUNNEL_ANALYSIS_CONVERSION;
}

export interface GoalDriversAnalysisImproveFeatureAdoptionFollowUpCommandPayload
  extends FollowUpCommandPayload<GoalDriversAnalysisImproveFeatureAdoptionFollowUpParameters> {
  type: FollowUpType.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION;
}

export interface RetentionAnalysisBucketInvestigationFollowUpCommandPayload
  extends FollowUpCommandPayload<RetentionAnalysisBucketInvestigationFollowUpParameters> {
  type: FollowUpType.RETENTION_ANALYSIS_BUCKET_INVESTIGATION;
}

import {useMemo, useContext} from 'react';
import {GlobalDocumentDataContext} from '../../../../../contexts/global-document-data/global-document-data.context';
import {isEqual, sortBy} from 'lodash';
import {RCASegmentationFollowUpData} from '../../../../../contexts/global-document-data/global-document-data.types';

export const useRCASegmentationFollowUpAnalysesForSegment = (
  segmentFilters: {signalId: number; classes: string[]}[]
) => {
  const {followUpAnalyses} = useContext(GlobalDocumentDataContext);
  return useMemo(() => {
    const sortedSegmentFilters = sortBy(segmentFilters, 'signalId');
    return followUpAnalyses.filter(followUpAnalysis => {
      const followUpData = followUpAnalysis.followUpData as RCASegmentationFollowUpData;
      return (
        followUpData.followUpType === 'RCASegmentationFollowUpData' &&
        isEqual(sortBy(followUpData?.segmentFilters, 'signalId'), sortedSegmentFilters)
      );
    });
  }, [followUpAnalyses, segmentFilters]);
};

import classNames from 'classnames';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import classes from './search-input.module.scss';
import {SearchIcon} from '../icons/icons.component';
import classnames from 'classnames';

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
}

type AllProps = OwnProps;

export const SearchInput: React.FC<AllProps> = (props: AllProps) => {
  const {value, onChange, placeholder, disabled, inputClassName, className} = props;

  return (
    <TextInput
      value={value}
      disabled={disabled}
      onChange={v => onChange((v || '').toString())}
      className={classnames(classes.SearchBox, className)}
      iconClassName={classes.SearchIcon}
      inputClassName={classNames(classes.SearchInput, inputClassName)}
      placeholder={placeholder}
      icon={SearchIcon}
      clearable
    />
  );
};

import {
  EntityImpactSentenceExplainer,
  RCASentence,
} from '../../../../../../../../../insights/rca/rca-insights.model.ts';
import {useCallback, useMemo} from 'react';
import {ChangeDescriptionAlias} from '../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import {RCAMode} from 'ui-components';
import * as React from 'react';
import {InterestingXoxSection} from './components/interesting-xox-section.component.tsx';
import {ExplainableSection} from './components/explainable-section.component.tsx';
import {EntityLevelExplainerSection} from './components/entity-level-explainer-section.component.tsx';

interface OwnProps {
  explainableSentences: RCASentence[];
  interestingXoxSentences: RCASentence[];
  entityExplainerSentences?: RCASentence[];
  changeDescriptionAlias: ChangeDescriptionAlias;
  mode: RCAMode;
  enableScrollToElements: boolean;
  granularity: string;
}

type AllProps = OwnProps;

export const RCAInsightsSection: React.FC<AllProps> = (props: AllProps) => {
  const {
    explainableSentences = [],
    interestingXoxSentences = [],
    entityExplainerSentences = [],
    enableScrollToElements,
    mode,
    changeDescriptionAlias,
    granularity,
  } = props;

  const onViewOnPage = useCallback((titleId: string) => {
    const elem = document.getElementById(titleId);
    if (elem) {
      elem.scrollIntoView({behavior: 'smooth'});
    }
  }, []);

  const sharedProps = useMemo(
    () => ({
      rcaMode: mode,
      granularity,
      changeDescriptionAlias,
      onViewOnPage: enableScrollToElements ? onViewOnPage : undefined,
    }),
    [mode, granularity, changeDescriptionAlias, enableScrollToElements, onViewOnPage]
  );

  return (
    <>
      {explainableSentences.length > 0 && (
        <ExplainableSection
          {...sharedProps}
          explainableSentences={explainableSentences}
          changeDescriptionAlias={changeDescriptionAlias}
        />
      )}
      {entityExplainerSentences.length > 0 && (
        <EntityLevelExplainerSection
          {...sharedProps}
          sentences={entityExplainerSentences as EntityImpactSentenceExplainer[]}
        />
      )}
      {interestingXoxSentences.length > 0 && (
        <InterestingXoxSection {...sharedProps} interestingXoxSentences={interestingXoxSentences} />
      )}
    </>
  );
};

import classes from './rca-figure-viewer.module.scss';
import {RCAElements, RCAElementType} from '../../../../types';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {useRCAElements} from './use-rca-elements.hook';
import {CSSProperties} from 'react';
import {RCASegmentationViewMode} from './rca-figure-viewer.types';

const EXTRA_PROPS = {
  [RCAElementType.SEGMENTATION]: {
    viewMode: RCASegmentationViewMode.EXPLAIN,
  },
};

type RCAFigureViewerElementsProps = {
  rcaFigureElements: RCAElements;
  elementStyle?: CSSProperties;
};

export const getHeaderId = (elementKey: RCAElementType) => {
  return `${elementKey}-header`;
};

export const RCAFigureViewerElements = (props: RCAFigureViewerElementsProps) => {
  const {rcaFigureElements, elementStyle} = props;

  const elementItems = useRCAElements(rcaFigureElements);

  return (
    <>
      {elementItems.map(elementItem => {
        const element = rcaFigureElements[elementItem.key];
        const extraProps = EXTRA_PROPS[elementItem.key] || {};

        return (
          element && (
            <DocumentTitleWrapper
              key={elementItem.key}
              title={elementItem.label}
              subtitle={elementItem.subtitle}
              icon={elementItem.icon}
              id={elementItem.key}
              headerId={getHeaderId(elementItem.key)}
              className={classes.Element}
              style={elementStyle}
            >
              <ChildRenderer children_={element} className={classes.MarginBottom} {...extraProps} />
            </DocumentTitleWrapper>
          )
        );
      })}
    </>
  );
};

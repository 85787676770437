import {useCallback, useContext, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ModelKey} from '../../../../constants/model-key';
import {Action, Subject} from '../../../../constants/permissions';
import TransKeys from 'translations';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import {getTeamsNetworkRequest} from '../../../../http/teams.network-requests';
import {GenericLoading} from '../../../../modules/shared/components/general/generic-loading/generic-loading.component';
import {GridTable} from '../../../../modules/shared/components/general/grid-table/grid-table.component';
import {TeamDTO} from '../../../../objects/dto/team.dto';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {Button} from 'ui-components';
import {TeamActionsCell} from './cells/team-actions-cell/team-actions-cell.component';
import {TeamMembersCell} from './cells/team-members-cell/team-members-cell.component';
import {TeamNameCell} from './cells/team-name-cell/team-name-cell.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {deleteTeamConfirmed} from '../../../../store/teams/teams.actions';
import {GridTableProps} from '../../../../modules/shared/components/general/grid-table/grid-table.types';
import {TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {EmptyState} from '../../../../modules/shared/components/general/override';
import classes from './teams-settings.module.scss';

const createListKey = (key: string = 'TEAM') => `TEAMS/${key}`;

export const TeamsSettings = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const canCreateTeam = can(Subject.TEAM, Action.CREATE);
  const canEditTeam = can(Subject.TEAM, Action.EDIT);
  const canDeleteTeam = can(Subject.TEAM, Action.DELETE);
  const showActions = useMemo(() => canEditTeam || canDeleteTeam, [canEditTeam, canDeleteTeam]);

  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getTeamsNetworkRequest,
      modelKey: ModelKey.TEAM,
    }),
    []
  );

  const defaultFilters = useMemo(
    () => ({
      itemsPerPage: 10,
    }),
    []
  );

  const {isLoading, listsData, onPageChange, onRefresh} = useRemoteList({
    defaultFilters,
    config,
  });

  const teamsList = (listsData?.list as TeamDTO[]) ?? [];
  const perPage = listsData?.meta?.numPerPage;
  const total = listsData?.meta?.total;
  const page = listsData?.meta?.page - 1;

  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (modelKey === ModelKey.TEAM) {
        onRefresh();
      }
    };

    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_DELETED, listener));

    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_DELETED, listener));
    };
  }, [dispatch, onRefresh]);

  const onCreateTeam = useCallback(() => {
    openPrimaryPanel(PanelKey.TEAM_FORM_PANEL);
  }, [openPrimaryPanel]);

  const onEditTeam = useCallback(
    (team: TeamDTO) => {
      openPrimaryPanel(PanelKey.TEAM_FORM_PANEL, {
        [TEAM_ID_PATH_PARAM]: team.id,
      });
    },
    [openPrimaryPanel]
  );

  const onDeleteTeam = useCallback(
    (team: TeamDTO) => {
      dispatch(deleteTeamConfirmed(team.id, team.name));
    },
    [dispatch]
  );

  const columns = useMemo<GridTableProps['columns']>(() => {
    return [
      {
        key: 'team',
        title: '',
        align: 'left',
        width: '20rem',
        render: (team: TeamDTO) => <TeamNameCell team={team} />,
      },
      {
        key: 'team-members',
        title: '',
        align: 'right',
        render: (team: TeamDTO) => <TeamMembersCell team={team} />,
      },
      {
        key: 'actions',
        title: '',
        hidden: !showActions,
        align: 'right',
        render: (team: TeamDTO) => (
          <TeamActionsCell onEdit={onEditTeam} onDelete={onDeleteTeam} team={team} />
        ),
      },
    ];
  }, [showActions, onEditTeam, onDeleteTeam]);

  return (
    <div className={classes.TeamsSettings}>
      <div className={classes.Header}>
        <div className={classes.TitleSection}>
          <div className={classes.Title}>{t(TransKeys.ACCOUNT_SETTINGS.TEAMS.TITLE)}</div>
        </div>
      </div>
      <div className={classes.GridContainer}>
        {isLoading && <GenericLoading />}
        {teamsList.length > 0 ? (
          <GridTable
            data={teamsList}
            page={page}
            order={'asc'}
            perPage={perPage}
            onPageChange={(e, page) => onPageChange(page + 1)}
            paginationMode="pages"
            pagination
            total={total}
            className={classes.Grid}
            rowClassName={classes.Row}
            isLoading={!teamsList || isLoading}
            dataKey={'id'}
            columns={columns}
          />
        ) : (
          !isLoading && (
            <EmptyState
              background={false}
              buttonVariant="contained"
              onClick={onCreateTeam}
              buttonText={t(TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.ADD_TEAM.LABEL)}
              subTitle={t(TransKeys.ACCOUNT_SETTINGS.TEAMS.EMPTY_STATE)}
            />
          )
        )}
      </div>

      <div className={classes.Actions}>
        {teamsList.length > 0 && canCreateTeam && (
          <Button
            variant="contained"
            onClick={onCreateTeam}
            size="large"
            className={classes.AddTeamBtn}
          >
            {t(TransKeys.ACCOUNT_SETTINGS.TEAMS.ACTIONS.ADD_TEAM.LABEL)}
          </Button>
        )}
      </div>
    </div>
  );
};

import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classes from './segments-main.module.scss';
import {queryFilter, tableEntityFilterGenerator} from '../../../../constants/filters';
import {ModelKey} from '../../../../constants/model-key';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Filters,
  SlidersIcon,
  TeamSelector,
  useRemoteSourceStated,
  UserGroupDuotoneIcon,
  UserGroupLightIcon,
} from 'ui-components';
import {
  getFavoritesSegmentsNetworkRequest,
  getSegmentsByCategoryNetworkRequest,
} from '../../../../http/segments.network-requests';
import {
  FavoriteSegment,
  Segment,
  SegmentCategory,
  SegmentsByCategory,
} from '../../../../objects/models/segment.model';
import {isNumber, keys} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {
  CATEGORY_ID_PATH_PARAM,
  SEGMENT_ID_PATH_PARAM,
  TEAM_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  addSegmentToTeam,
  deleteSegmentConfirmed,
  rescanSegmentConfirmed,
  validateSegment,
} from '../../../../store/segments/segments.actions';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {MainHeader} from '../../../shared/components/layout/main-header/main-header.component';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {JsonParam, useQueryParams} from 'use-query-params';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {
  deleteSegmentCategoryConfirmed,
  swapSegmentCategory,
} from '../../../../store/segment-categories/segment-categories.actions';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {Permission} from '../../../../core/components/permission.component';
import {Action, Subject} from '../../../../constants/permissions';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {SegmentCategoryTable} from './components/segment-category-table/segment-category-table.component';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {SegmentViewPanelTabs} from '../../panels/segment-view-panel/segment-view-panel.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_DATA_KEY = `SEGMENTS_MAIN/SEGMENTS`;

const LISTENER_ACTIONS = [
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_DELETED,
];

export const SegmentsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const user = useCurrentUser();
  const {productEntities, actualTeams: teams, defaultTeamId} = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const [teamId, setTeamId] = useState(defaultTeamId);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {source: favoriteSegmentsByTeam, exec: getFavoriteSegments} = useRemoteSourceStated({
    networkRequest: getFavoritesSegmentsNetworkRequest,
  });
  const favoriteSegments: FavoriteSegment[] = useMemo(() => {
    if (!teamId || !favoriteSegmentsByTeam) {
      return undefined;
    }
    if (!favoriteSegmentsByTeam[teamId]) {
      return [];
    }
    return favoriteSegmentsByTeam[teamId];
  }, [teamId, favoriteSegmentsByTeam]);
  const teamOptions = useMemo(
    () => [
      {
        id: null,
        name: 'All',
        icon: UserGroupLightIcon,
      },
      ...teams,
    ],
    [teams]
  );
  const filtersDef = useMemo(() => {
    const filters = [queryFilter()];
    if (keys(productEntities).length > 1) {
      filters.push(tableEntityFilterGenerator(productEntities));
    }
    return filters;
  }, [productEntities]);
  const defaultFilters = useMemo(() => ({}), []);
  const [filtersState, setFiltersState] = useQueryParams({
    filters: JsonParam,
  });
  const data: SegmentsByCategory = useSelector(state =>
    getSingleSelectedSelector(SELECTED_DATA_KEY, state)
  );
  const title = useMemo(
    () =>
      `${t(TransKeys.SEGMENTS.HEADER.TITLE)} ${isNumber(data?.total) ? ` (${data.total})` : ''}`,
    [t, data]
  );
  const isLoading = useSelector(state => getReducedLoadingStateSelector(SELECTED_DATA_KEY)(state));
  const categories = useMemo(() => data?.categories || [], [data]);

  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_DATA_KEY,
        actionKey: SELECTED_DATA_KEY,
        request: getSegmentsByCategoryNetworkRequest,
      })
    );
    dispatch(getSelected(SELECTED_DATA_KEY, filtersState.filters));
    getFavoriteSegments();
    return () => {
      dispatch(removeSelected(SELECTED_DATA_KEY));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getFavoriteSegments]);
  const onValidateSegment = useCallback(
    (segment: Segment) => dispatch(validateSegment(segment.id)),
    [dispatch]
  );

  const showSegment = useCallback(
    (segment: Segment) => {
      const parameters = {[SEGMENT_ID_PATH_PARAM]: segment.id};
      if (segment.signalValidationStatus === ValidationStatus.ERROR && !isDemoProduct) {
        parameters['initialTab'] = SegmentViewPanelTabs.SQL;
      }
      openPrimaryPanel(PanelKey.VIEW_SEGMENT_PANEL, parameters);
    },
    [openPrimaryPanel, isDemoProduct]
  );
  const onCreateEdit = useCallback(
    (segment?: Segment, parameters: any = {}) => {
      openPrimaryPanel(PanelKey.SEGMENT_FORM_PANEL, {
        [SEGMENT_ID_PATH_PARAM]: segment?.id,
        ...parameters,
      });
      if (!segment) {
        notify(AmplitudeEvent.SEGMENT_CREATE_CLICKED, {
          userId: user.id,
        });
      }
      if (segment && parameters.cloneMode) {
        notify(AmplitudeEvent.SEGMENT_DUPLICATE_CLICKED, {
          userId: user.id,
        });
      }
      if (segment && !parameters.cloneMode) {
        notify(AmplitudeEvent.SEGMENT_EDIT_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onCreateEditCategory = useCallback(
    (category?: SegmentCategory) => {
      openPrimaryPanel(PanelKey.SEGMENT_CATEGORY_FORM_PANEL, {
        [CATEGORY_ID_PATH_PARAM]: category?.id,
      });
      if (!category?.id) {
        notify(AmplitudeEvent.SEGMENT_CATEGORY_CREATE_CLICKED, {
          userId: user.id,
        });
      }
    },
    [openPrimaryPanel, user, notify]
  );
  const onFavoriteSegmentsClicked = useCallback(() => {
    openPrimaryPanel(PanelKey.FAVORITES_SEGMENTS_PANEL, {
      [TEAM_ID_PATH_PARAM]: teamId,
    });
    notify(AmplitudeEvent.UPDATE_FAVORITES_SEGMENTS_CLICKED, {
      userId: user.id,
    });
  }, [openPrimaryPanel, notify, user, teamId]);
  const onRescan = useCallback(
    (segment: Segment) => {
      const onSuccess = () => [getSelected(SELECTED_DATA_KEY, filtersState.filters)];
      dispatch(rescanSegmentConfirmed(segment.id, onSuccess));
    },
    [filtersState, dispatch]
  );
  const onDelete = useCallback(
    (segment: Segment) => dispatch(deleteSegmentConfirmed(segment.id)),
    [dispatch]
  );
  const onAddToTeam = useCallback(
    (segmentId: number, teamId: number) => dispatch(addSegmentToTeam(segmentId, teamId)),
    [dispatch]
  );
  const onFiltersChange = useCallback(
    (newFilters: any) => {
      const filters = {...newFilters, ...defaultFilters};
      setFiltersState({filters});
      dispatch(getSelected(SELECTED_DATA_KEY, filters));
    },
    [dispatch, defaultFilters, setFiltersState]
  );
  const onDeleteCategory = useCallback(
    (category: SegmentCategory) => dispatch(deleteSegmentCategoryConfirmed(category.id)),
    [dispatch]
  );
  const onSwapCategories = useCallback(
    (category: SegmentCategory, index: number, newIndex: number) => {
      dispatch(
        swapSegmentCategory({
          segmentCategoryId: category.id,
          index,
          newIndex,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (
        modelKey === ModelKey.SEGMENT ||
        modelKey === ModelKey.SEGMENT_CATEGORY ||
        modelKey === ModelKey.FAVORITE_SEGMENTS
      ) {
        dispatch(getSelected(SELECTED_DATA_KEY, filtersState.filters));
      }
      if (modelKey === ModelKey.FAVORITE_SEGMENTS) {
        getFavoriteSegments();
      }
    };
    dispatch(registerActionListener(LISTENER_ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(LISTENER_ACTIONS, listener));
    };
  }, [dispatch, getFavoriteSegments, filtersState]);

  return (
    <div className={classes.SegmentsMain}>
      <div className={classes.Header}>
        <MainHeader
          title={title}
          icon={UserGroupDuotoneIcon}
          helperText={t(TransKeys.SEGMENTS.HEADER.HELPER_TEXT)}
          renderRight={
            <div className={classes.ActionsWrapper}>
              {teams.length > 0 && (
                <Button
                  variant={'outlined'}
                  size={'large'}
                  onClick={() => onFavoriteSegmentsClicked()}
                  icon={SlidersIcon}
                >
                  {t(TransKeys.SEGMENTS.ACTIONS.FAVORITES_SEGMENTS)}
                </Button>
              )}
              <Button variant={'outlined'} size={'large'} onClick={() => onCreateEditCategory()}>
                {t(TransKeys.GENERAL.ACTIONS.ADD_CATEGORY)}
              </Button>
              <Permission subject={Subject.SEGMENT} action={Action.CREATE}>
                <Button size={'large'} onClick={() => onCreateEdit()}>
                  {t(TransKeys.GENERAL.ACTIONS.CREATE_SEGMENT)}
                </Button>
              </Permission>
            </div>
          }
        />
      </div>
      <div className={classes.Filters}>
        {teams.length > 0 && (
          <TeamSelector
            teams={teamOptions}
            value={teamId}
            onChange={setTeamId}
            clearable={false}
            size={'large'}
            withPrefix
          />
        )}
        <Filters
          selected={filtersState?.filters || {}}
          hideDefaultFilters
          onChange={onFiltersChange}
          onClearAll={() => onFiltersChange({})}
          freeSearchFilterKey={'q'}
          filters={filtersDef}
          maxWidth={'unset'}
          className={classes.FiltersSelector}
        />
      </div>
      <div className={classes.Body}>
        {isLoading && <GenericLoading />}
        {categories.map((category, idx) => (
          <SegmentCategoryTable
            className={classes.Category}
            key={category.id}
            category={category}
            favoriteSegments={favoriteSegments}
            showActions={category.id > 0}
            onEditCategory={() => onCreateEditCategory(category)}
            onDeleteCategory={() => onDeleteCategory(category)}
            onResetTeamFilter={() => setTeamId(null)}
            onAddToTeam={onAddToTeam}
            showTeams={teams.length > 0}
            /* length -2 because of default category (id=0)*/
            onMoveDown={
              idx < categories.length - 2 && !isDemoProduct
                ? () => onSwapCategories(category, idx, idx + 1)
                : undefined
            }
            onMoveUp={
              idx > 0 && !isDemoProduct ? () => onSwapCategories(category, idx, idx - 1) : undefined
            }
            onSegmentClicked={s => showSegment(s)}
            onEditSegment={s => onCreateEdit(s)}
            onDuplicateSegment={s => onCreateEdit(s, {cloneMode: true})}
            onRescanSegment={s => onRescan(s)}
            onValidateSegment={s => onValidateSegment(s)}
            onDeleteSegment={s => onDelete(s)}
            onCreateSegment={() =>
              onCreateEdit(undefined, {
                [CATEGORY_ID_PATH_PARAM]: category.id,
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useRemoteSource} from 'ui-components';
import {forgotPasswordNetworkRequest} from '../../../../http/auth.network-requests';
import {
  ForgotPasswordFormDto,
  ForgotPasswordFormDTOValidator,
} from '../../../../objects/dto/forgot-password-form.dto';
import AuthScopeFormContainer from '../auth-scope/auth-scope-form-container/auth-scope-form-container.component';
import TransKeys from 'translations';
import {showToastMessage} from '../../../../store/interface/interface.actions';
import {ToastType} from '../../../../objects/system/toast-type.enum';
import AuthForm from '../auth-scope/auth-scope-form/auth-form.component';
import classes from '../shared-forms.module.scss';
import {useNavigate} from 'react-router';
import {AppRoutes} from '../../../../constants/app-routes';
import {AuthFormTextInput} from '../auth-scope/inputs/auth-text-input/auth-text-input.component';
import {get} from 'lodash';

interface OwnProps {}

type AllProps = OwnProps;

const ForgotPasswordForm = (props: AllProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestSucceeded, setRequestSucceeded] = useState(false);
  const formMethods = useForm<ForgotPasswordFormDto>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(ForgotPasswordFormDTOValidator),
  });
  const {handleSubmit} = formMethods;
  const {exec: forgotPassword, isLoading} = useRemoteSource({
    networkRequest: forgotPasswordNetworkRequest,
    type: null,
    onSuccess: () => {
      dispatch(
        showToastMessage(
          t(TransKeys.AUTH_FORMS.FORGOT_PASSWORD.TOASTS_MESSAGES.HTTP.SUCCESS),
          ToastType.SUCCESS
        )
      );
      setRequestSucceeded(true);
    },
    onError: error => {
      dispatch(
        showToastMessage(
          get(
            error.data,
            'message',
            t(TransKeys.AUTH_FORMS.RESET_PASSWORD.TOASTS_MESSAGES.HTTP.ERROR)
          ),
          ToastType.ERROR
        )
      );
      setRequestSucceeded(false);
    },
  });

  return (
    <AuthScopeFormContainer>
      <FormProvider {...formMethods}>
        <AuthForm
          disableOnSubmit={isLoading || requestSucceeded}
          onSubmit={handleSubmit(forgotPassword)}
          onSubmitText={t(TransKeys.AUTH_FORMS.FORGOT_PASSWORD.LABELS.SUBMIT)}
          subTitle={t(TransKeys.AUTH_FORMS.FORGOT_PASSWORD.FORM_SUB_HEADING)}
          title={t(TransKeys.AUTH_FORMS.FORGOT_PASSWORD.FORM_HEADING)}
          renderLeftToSubmit={
            <span className={classes.Footer} onClick={() => navigate(AppRoutes.login())}>
              {t(TransKeys.AUTH_FORMS.SHARED_LABELS.FOOTER.BACK)}
            </span>
          }
        >
          <div className={classes.FormBlock}>
            <AuthFormTextInput
              required
              label={t(TransKeys.AUTH_FORMS.SHARED_LABELS.EMAIL)}
              name={'email'}
              type={'text'}
            />
          </div>
        </AuthForm>
      </FormProvider>
    </AuthScopeFormContainer>
  );
};

export default ForgotPasswordForm;

import {MainHeader} from '../../../shared/components/layout/main-header/main-header.component.tsx';
import {
  GearCodeDuotoneIcon,
  Button,
  IconButton,
  ActionsDropdown,
  EditIcon,
  TrashIcon,
  MoreIcon,
  CopyIcon,
  Checkbox,
} from 'ui-components';
import TransKeys from 'translations';
import classes from './data-pipelines-main.module.scss';
import {useTranslation} from 'react-i18next';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook.ts';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {PanelsContext} from '../../../../core/contexts/panels.context.tsx';
import {useDispatch} from 'react-redux';
import {getDataPipelinesNetworkRequest} from '../../../../http/data-pipelines.network-requests.ts';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook.ts';
import {dataPipelineToastCreator} from '../../../../store/toasts.actions.ts';
import {ModelKey} from '../../../../constants/model-key.ts';
import {isNumber} from 'lodash';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions.ts';
import {CoreActionsType} from '../../../../store/core/core.actions.ts';
import {PanelKey} from '../../../../constants/panels.ts';
import {DATA_PIPELINE_ID_PATH_PARAM} from '../../../../constants/app-routes.ts';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types.ts';
import moment from 'moment/moment';
import {Title} from '../../../shared/components/general/title/title.component.tsx';
import {FlexVertical} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component..tsx';
import {DataPipeline} from '../../../../objects/models/data-pipeline.model.ts';
import {withStopPropagation} from '../../../../utils/general.utils.ts';
import {
  deleteDataPipelineConfirmed,
  reorderDataPipeline,
} from '../../../../store/data-pipelines/data-pipelines.actions.ts';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component.tsx';

interface Props {}

const DATA_PIPELINES_LIST_KEY = 'DATA_PIPELINES_LIST_KEY';

const config = {
  listKey: DATA_PIPELINES_LIST_KEY,
  actionKey: DATA_PIPELINES_LIST_KEY,
  request: getDataPipelinesNetworkRequest,
  onError: err => [dataPipelineToastCreator('GET_ERROR')],
  modelKey: ModelKey.DATA_PIPELINE,
};

export const DataPipelinesMain = (props: Props) => {
  const {t} = useTranslation();
  const isAdmin = useIsAdmin();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const [itemsOrder, setItemsOrder] = useState<number[]>([]);
  const [allowDrag, setAllowDrag] = useState(false);

  const defaultFilters = useMemo(
    () => ({
      orderBy: ['order'],
      order: ['asc'],
      itemsPerPage: 1000,
    }),
    []
  );

  const {isLoading, listsData, onRefresh} = useRemoteList({
    defaultFilters,
    config,
  });

  const onDelete = useCallback(
    dataPipeline => dispatch(deleteDataPipelineConfirmed(dataPipeline.id)),
    [dispatch]
  );

  const onCreateEdit = useCallback(
    (dataPipeline?: DataPipeline, parameters = {}) =>
      openPrimaryPanel(PanelKey.DATA_PIPELINE_FORM_PANEL, {
        [DATA_PIPELINE_ID_PATH_PARAM]: dataPipeline?.id,
        ...parameters,
      }),
    [openPrimaryPanel]
  );
  const onView = useCallback(
    (dataPipeline?: DataPipeline, parameters = {}) =>
      openPrimaryPanel(PanelKey.DATA_PIPELINE_VIEW_PANEL, {
        [DATA_PIPELINE_ID_PATH_PARAM]: dataPipeline?.id,
      }),
    [openPrimaryPanel]
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'order',
        title: 'ORD',
        width: '6rem',
        render: (dataPipeline: any) => <span>#{dataPipeline.actualOrder + 1}</span>,
      },
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        stretch: true,
        render: (dataPipeline: DataPipeline) => (
          <Title className={classes.Title} text={dataPipeline.name} />
        ),
      },
      {
        key: 'createdOn',
        title: 'Created',
        width: '20rem',
        render: (dataPipeline: DataPipeline) => {
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(dataPipeline.createdOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        align: 'right',
        render: (dataPipeline: DataPipeline) => (
          <div className={classes.Actions}>
            <IconButton
              className={classes.Button}
              tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
              icon={EditIcon}
              onClick={withStopPropagation(() => onCreateEdit(dataPipeline))}
            />
            <IconButton
              className={classes.Button}
              tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
              icon={CopyIcon}
              onClick={withStopPropagation(() => onCreateEdit(dataPipeline, {cloneMode: true}))}
            />
            <ActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'delete',
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(dataPipeline),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [onCreateEdit, onDelete, t]
  );

  const title = useMemo(() => {
    return `${t(TransKeys.DATA_PIPELINES.HEADER.TITLE)} ${isNumber(listsData?.meta.total) ? ` (${listsData?.meta.total})` : ''}`;
  }, [t, listsData]);

  const onDragEnd = useCallback(
    data => {
      if (!data.destination) {
        return;
      }
      const startIndex = data.source.index;
      const endIndex = data.destination.index;
      const result = [...itemsOrder];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setItemsOrder(result);
      dispatch(reorderDataPipeline({orderedIds: result}));
    },
    [itemsOrder, setItemsOrder, dispatch]
  );
  const listItems = useMemo(
    () =>
      (listsData?.list || [])
        .map(item => ({
          ...item,
          actualOrder: itemsOrder.indexOf(item.id),
        }))
        .sort((a, b) => a.actualOrder - b.actualOrder),
    [itemsOrder, listsData?.list]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.DATA_PIPELINE) {
        onRefresh();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, onRefresh]);

  useEffect(() => {
    if (listsData) {
      setItemsOrder(listsData.list.map(item => item.id));
    }
  }, [listsData]);

  return (
    <div className={classes.DataPipelinesMain}>
      <div className={classes.Header}>
        <MainHeader
          title={title}
          icon={GearCodeDuotoneIcon}
          helperText={t(TransKeys.METRICS.HEADER.HELPER_TEXT)}
          renderRight={
            <div className={classes.ActionsWrapper}>
              {isAdmin && (
                <Checkbox
                  label="Change order (drag and drop)"
                  checked={allowDrag}
                  onChange={() => setAllowDrag(!allowDrag)}
                  border
                />
              )}
              {isAdmin && (
                <Button onClick={() => onCreateEdit()}>
                  {t(TransKeys.GENERAL.ACTIONS.CREATE_DATA_PIPELINE)}
                </Button>
              )}
            </div>
          }
        />
      </div>
      <div className={classes.Body}>
        {isLoading && <GenericLoading />}
        {listsData && (
          <GridTable
            className={classes.Table}
            data={listItems}
            dataKey={'id'}
            columns={columns}
            pagination={false}
            total={listsData.meta?.total}
            page={listsData.meta?.page - 1}
            onRowClicked={onView}
            draggable={allowDrag}
            onDragEnd={onDragEnd}
            emptyStateProps={
              !isLoading
                ? {
                    title: 'No Data Pipelines',
                    subTitle: 'Create a new Data Pipeline to get started',
                  }
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

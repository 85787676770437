import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {BaseRetentionBuilder} from '../../../../shared/core/query-builders/retention-builder/base-retention-builder.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {CohortsDefinitionSelector} from '../../components/ui-selectors/cohorts-definition-selector/cohorts-definition-selector.component';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {values} from 'lodash';
import {SingleDateSelector} from '../../components/ui-selectors/single-date-selector/single-date-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../shared/core/smart-selector/signal-smart-selector.component';
import {SelectorModelType} from '../../../../shared/core/smart-selector/advanced-smart-selector.component';
import {QueryBuilderFactory} from 'ui-components';

const retentionGoalSelectorMap = {
  actions: 'actions',
  ref_date: 'ref_date',
};
const cohortsDefinitionSelectorMap = {
  cohort_granularity: 'cohort_granularity',
  bucket_granularity: 'bucket_granularity',
  n_cohorts: 'n_cohorts',
  n_buckets: 'n_buckets',
  bucket_n_unit: 'bucket_n_unit',
  is_custom_buckets: 'is_custom_buckets',
};
const GROUP_BY_SIGNAL_MAPPING = {
  signal_id: 'group_by_signal',
};
const endDateSelectorSchemaMapping = {
  date_parameter: 'end_date',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};

const createGroupBySignalFilters = (entityContext = undefined) => ({
  type: SignalType.DIMENSION,
  entity_binding: TableEntityBinding.ONE_WAY,
  entityContext,
});

const createRefDateSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entity_binding: TableEntityBinding.TWO_WAY,
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entity_binding: TableEntityBinding.DEFAULT,
    entityContext,
  },
];
const GROUP_BY_INCLUDE = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.SEGMENTS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const Analysis69Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {getSignalByTag, productEntities, defaultTableEntity, defaultSource} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];

  useEffect(() => {
    registerDefaultHandler('analysis_69', parameters => {
      const defaults = {};
      const joinDateSignal = getSignalByTag('join_date', entityContext || defaultTableEntity);
      const activeSignal = getSignalByTag('active', entityContext || defaultTableEntity);

      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[retentionGoalSelectorMap.ref_date] = QueryBuilderFactory.createSignalColumn(
        joinDateSignal?.id
      );
      defaults[retentionGoalSelectorMap.actions] = [
        QueryBuilderFactory.createSignalColumn(activeSignal?.id),
      ];
      defaults[cohortsDefinitionSelectorMap.n_cohorts] = 14;
      defaults[cohortsDefinitionSelectorMap.n_buckets] = 14;
      defaults[cohortsDefinitionSelectorMap.cohort_granularity] = 'week';
      defaults[cohortsDefinitionSelectorMap.bucket_granularity] = 'week';
      const subtractFromDate = defaultSource?.lastValidDate
        ? moment(defaultSource.lastValidDate).startOf('d')
        : moment();
      defaults[endDateSelectorSchemaMapping.date_parameter] = subtractFromDate
        .subtract(1, 'days')
        .format(TIME_FORMATS.PARAMETER_DATE_FORMAT);

      return defaults;
    });

    return () => {
      removeDefaultHandler('analysis_69');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    getSignalByTag,
    entityContext,
    defaultTableEntity,
    defaultSource,
  ]);

  const maxDate = useMemo(
    () => defaultSource?.lastValidDate && new Date(defaultSource?.lastValidDate),
    [defaultSource]
  );

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([...values(retentionGoalSelectorMap), ...values(segmentFilterSchemaMapping)])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  const groupBySignalFilters = useMemo(
    () => createGroupBySignalFilters(entityContext),
    [entityContext]
  );
  const refDateFilters = useMemo(() => createRefDateSignalFilters(entityContext), [entityContext]);
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, [...values(GROUP_BY_SIGNAL_MAPPING)]);
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GOAL.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GOAL.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GOAL.HELPER_TEXT)}
        className={classes.Parameter}
        error={hasError(errors, values(retentionGoalSelectorMap))}
      >
        <BaseRetentionBuilder
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={retentionGoalSelectorMap}
          refDateSignalFilters={refDateFilters}
          errors={errors}
          refDateAllowTypes={[
            SelectorModelType.SIGNAL,
            SelectorModelType.EVENT,
            SelectorModelType.COLUMN,
          ]}
          entityContext={entityContext}
        />
      </ParameterInputWrapper>
      <CohortsDefinitionSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.COHORTS_DEFINITION.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.COHORTS_DEFINITION.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.COHORTS_DEFINITION.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={cohortsDefinitionSelectorMap}
        errors={errors}
      />
      <SingleDateSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.END_DATE.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.END_DATE.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.END_DATE.HELPER_TEXT)}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={endDateSelectorSchemaMapping}
        maxDate={maxDate}
      />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          entityContext={entityContext}
          schemaKeysMapping={segmentFilterSchemaMapping}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <ParameterInputWrapper
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GROUP_BY.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GROUP_BY.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_69.GROUP_BY.HELPER_TEXT)}
          className={classes.Parameter}
          error={hasError(errors, values(GROUP_BY_SIGNAL_MAPPING))}
        >
          <SignalSmartSelector
            placeholder={'Select'}
            onChange={value =>
              changeParametersValue({
                [GROUP_BY_SIGNAL_MAPPING.signal_id]: value,
              })
            }
            value={parameters?.[GROUP_BY_SIGNAL_MAPPING.signal_id]}
            error={Boolean(errors?.[GROUP_BY_SIGNAL_MAPPING.signal_id])}
            onSignalInfo={onSignalInfo}
            filters={groupBySignalFilters}
            include={GROUP_BY_INCLUDE}
            clearable
          />
        </ParameterInputWrapper>
      </ExtendedParameters>
    </div>
  );
};

import {
  ChartBulletDoutoneIcon,
  ChartPyramidDuotoneIcon,
  FlaskVialDoutoneIcon,
  LineChartDownDoutoneIcon,
  MagnifyingGlassChartDoutoneIcon,
  RocketLaunchDoutoneIcon,
} from 'ui-components';
import {PanelKey} from '../../../../constants/panels.ts';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../constants/app-routes.ts';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id.ts';

export enum HomepageQuickAction {
  INVESTIGATE_DROP = 'investigate_drop',
  IMPROVE_KPI = 'improve_kpi',
  RELEASE_IMPACT = 'release_impact',
  FUNNEL_ANALYSIS = 'funnel_analysis',
  KPI_ANALYSIS = 'kpi_analysis',
  AB_TEST = 'ab_test',
}

export const HOMEPAGE_QUICK_ACTION_ICON = {
  [HomepageQuickAction.INVESTIGATE_DROP]: LineChartDownDoutoneIcon,
  [HomepageQuickAction.IMPROVE_KPI]: ChartBulletDoutoneIcon,
  [HomepageQuickAction.RELEASE_IMPACT]: RocketLaunchDoutoneIcon,
  [HomepageQuickAction.FUNNEL_ANALYSIS]: ChartPyramidDuotoneIcon,
  [HomepageQuickAction.KPI_ANALYSIS]: MagnifyingGlassChartDoutoneIcon,
  [HomepageQuickAction.AB_TEST]: FlaskVialDoutoneIcon,
};

export const HOMEPAGE_QUICK_ACTION_PANEL_PARAMETERS = {
  [HomepageQuickAction.INVESTIGATE_DROP]: {
    panelKey: PanelKey.ANALYSIS_FORM_PANEL,
    parameters: {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RCA_ANALYSIS,
    },
  },
  [HomepageQuickAction.IMPROVE_KPI]: {
    panelKey: PanelKey.HOW_TO_IMPROVE_KPI_PANEL,
    parameters: {},
  },
  [HomepageQuickAction.FUNNEL_ANALYSIS]: {
    panelKey: PanelKey.ANALYSIS_FORM_PANEL,
    parameters: {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.FUNNEL_ANALYSIS,
    },
  },
  [HomepageQuickAction.KPI_ANALYSIS]: {
    panelKey: PanelKey.ANALYSIS_FORM_PANEL,
    parameters: {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.KPI_ANALYSIS,
    },
  },
  [HomepageQuickAction.RELEASE_IMPACT]: {
    panelKey: PanelKey.EXPERIMENT_FORM_PANEL,
    parameters: {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RELEASE_IMPACT,
    },
  },
  [HomepageQuickAction.AB_TEST]: {
    panelKey: PanelKey.EXPERIMENT_FORM_PANEL,
    parameters: {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.A_B_TEST,
    },
  },
};

import classes from './kpi-formula-panel.module.scss';
import {METRIC_ID_PATH_PARAM, TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes.ts';
import {SetKPITreeMetricFormulaDTO} from '../../../../objects/dto/metric-tree.dto.ts';
import {composition, OnSuccessActionHook} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc.tsx';
import {ArithmeticFormulaBuilder, Button, FancyHeader, ModalLayout} from 'ui-components';
import {useMemo, useState} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {getMetricTreeNetworkRequest} from '../../../../http/metric-tree.network-requests.ts';
import {MetricTree} from '../../../../objects/models/metric-tree.model.ts';

interface Props {
  tree: MetricTree;
  [METRIC_ID_PATH_PARAM]: number;
  [TEAM_ID_PATH_PARAM]?: number;
  onSubmit: (data: SetKPITreeMetricFormulaDTO, onSuccess?: OnSuccessActionHook) => void;
  onClose: () => void;
}

const SELECTED_KEY = 'KPI_TREE_DEFINE_FORMULA_PANEL';

const KPITreeDefineFormulaPanelComponent = (props: Props) => {
  const {
    tree,
    [METRIC_ID_PATH_PARAM]: metricId,
    [TEAM_ID_PATH_PARAM]: teamId,
    onSubmit,
    onClose,
  } = props;
  const {t} = useTranslation();
  const selectedMetric = useMemo(
    () => tree.metrics.find(metric => metric.id === metricId),
    [tree, metricId]
  );
  const [formula, setFormula] = useState(selectedMetric.formula || '');
  const variables = useMemo(
    () =>
      tree.metrics
        .filter(m => m.id !== metricId)
        .map(m => ({
          name: m.name,
          value: m.id,
        })),
    [tree, metricId]
  );
  const hasVariables = variables.length > 0;

  return (
    <div className={classes.KPIFormulaPanel}>
      <ModalLayout
        footer={
          <>
            <Button onClick={onClose} variant={'outlined'}>
              {hasVariables
                ? t(TransKeys.GENERAL.ACTIONS.CANCEL)
                : t(TransKeys.GENERAL.ACTIONS.CLOSE)}
            </Button>
            {hasVariables && (
              <Button
                onClick={() =>
                  onSubmit(
                    {
                      metricId,
                      teamId,
                      formula,
                    },
                    onClose
                  )
                }
              >
                {t(TransKeys.GENERAL.ACTIONS.SAVE)}
              </Button>
            )}
          </>
        }
      >
        <FancyHeader title={`Define Formula for ${selectedMetric.name}`} />
        <div className={classes.Content}>
          {hasVariables && (
            <ArithmeticFormulaBuilder
              value={formula}
              variables={variables}
              variableName={'KPI'}
              onChange={setFormula}
            />
          )}
          {hasVariables === false && (
            <div className={classes.NoVariablesEmptyState}>
              {t(TransKeys.KPI_TREE_FORMULA_PANEL.NO_VARIABLES_EMPTY_STATE)}
            </div>
          )}
        </div>
      </ModalLayout>
    </div>
  );
};

export const KPITreeDefineFormulaPanel = composition(
  KPITreeDefineFormulaPanelComponent,
  withLoadBefore({
    tree: {
      selectedKey: SELECTED_KEY,
      actionKey: SELECTED_KEY,
      request: getMetricTreeNetworkRequest,
      mapPayloadFromProps: props => ({
        teamId: props[TEAM_ID_PATH_PARAM],
      }),
    },
  })
);

import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PageLayout from '../../../shared/components/layout/page-layout';
import TransKeys from 'translations';
import {
  ArrowProgressRegularIcon,
  BookOpenCoverIcon,
  Button,
  IconButton,
  useLocalStorage,
  UserMagnifyingGlassLightIcon,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {workflowToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  getWorkflowCountsNetworkRequest,
  getWorkflowsNetworkRequest,
} from '../../../../http/workflows.network-requests';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {WorkflowsList} from './components/workflows-list/workflows-list.component';
import {Pagination} from '../../../shared/components/general/pagination/pagination.component';
import classes from './workflows-main.module.scss';
import {WorkflowsListFilters} from './components/workflows-list-filters/workflows-list-filters.component';
import {WorkflowCounts} from '../../../../objects/models/workflow.model';
import {HttpClientContext} from 'front-core';
import {AppRoutes} from '../../../../constants/app-routes.ts';
import {BetterNavLink} from '../../../shared/core/override/better-nav-link.component.tsx';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags.ts';
import {noop} from 'lodash';
import {WorkflowsBanner} from './components/workflows-banner/workflows-banner.component.tsx';

interface OwnProps {}

type AllProps = OwnProps;

const LIST_KEY = 'WORKFLOWS_MAIN/WORKFLOWS';

export const WorkflowsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const showExploreUsers = useFeatureIsOn(FeatureFlag.WORKFLOWS_EXPLORE_USERS as string);
  const [showWorkflowsBanner, setShowWorkflowsBanner] = useLocalStorage(
    `show-workflows-banner`,
    true
  );
  const [workflowCounts, setWorkflowCounts] = useState<WorkflowCounts>({
    type: {},
    status: {},
  } as WorkflowCounts);

  const config = useMemo(
    () => ({
      listKey: LIST_KEY,
      actionKey: LIST_KEY,
      request: getWorkflowsNetworkRequest,
      onError: err => [workflowToastCreator('GET_ERROR')],
      modelKey: ModelKey.WORKFLOW,
    }),
    []
  );
  const defaultFilters = useMemo(
    () => ({
      orderBy: ['createdOn'],
      order: ['desc'],
      itemsPerPage: 50,
    }),
    []
  );
  const {
    isLoading,
    listsData,
    onFiltersChange: onFiltersChangeFromHook,
    onPageChange,
    filters,
    onSort,
  } = useRemoteList({
    defaultFilters,
    config,
    syncQueryFilters: true,
    filterKeys: ['status', 'type', 'teamId', 'q'],
  });

  const onFiltersChange = useCallback(
    newFilters => {
      onFiltersChangeFromHook({
        ...filters,
        ...newFilters,
      });
    },
    [onFiltersChangeFromHook, filters]
  );

  const onCreateWorkflow = useCallback(
    () => openPrimaryPanel(PanelKey.WORKFLOW_FORM_PANEL),
    [openPrimaryPanel]
  );

  const handlePageChange = useCallback(
    (newPageNumber: number) => {
      onPageChange(newPageNumber + 1);
    },
    [onPageChange]
  );

  const onToggleBanner = () => {
    setShowWorkflowsBanner(open => !open);
  };

  useEffect(() => {
    // load initially or update status counts when changing team
    (async () => {
      const res: WorkflowCounts = (await http.exec(
        getWorkflowCountsNetworkRequest(filters)
      )) as unknown as WorkflowCounts;
      setWorkflowCounts(res);
    })();
  }, [filters, http]);

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.WORKFLOW) {
        dispatch(replaceList(config.listKey, {page: 1}, 'replace'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, config]);

  const perPage = listsData?.meta?.numPerPage;
  const total = listsData?.meta?.total;
  const page = listsData?.meta?.page - 1;

  return (
    <PageLayout.Layout>
      <PageLayout.Title
        title={t(TransKeys.WORKFLOWS.HEADER.TITLE)}
        icon={ArrowProgressRegularIcon}
      />
      <PageLayout.Actions>
        <IconButton
          border
          tooltipText={t(TransKeys.WORKFLOWS.HEADER.ACTIONS.OPEN_EXPLANATION.HELPER_TEXT)}
          active={showWorkflowsBanner}
          icon={BookOpenCoverIcon}
          iconClassName={classes.BookOpenIcon}
          size="large"
          onClick={onToggleBanner}
        />
        {showExploreUsers && (
          <BetterNavLink to={AppRoutes.workflowsExplore()} className={classes.Link}>
            <Button
              variant={'outlined'}
              size="large"
              onClick={noop}
              icon={UserMagnifyingGlassLightIcon}
            >
              {t(TransKeys.WORKFLOWS.HEADER.ACTIONS.OPEN_USER_LIST.LABEL)}
            </Button>
          </BetterNavLink>
        )}
        <Button icon={ArrowProgressRegularIcon} onClick={onCreateWorkflow} size={'large'}>
          {t(TransKeys.GENERAL.ACTIONS.CREATE_WORKFLOW)}
        </Button>
      </PageLayout.Actions>
      <PageLayout.Body noPadding className={classes.PageBody}>
        <div className={classes.WorkflowsMain}>
          {showWorkflowsBanner && <WorkflowsBanner onClose={onToggleBanner} />}
          <div className={classes.SectionTitle}>{t(TransKeys.WORKFLOWS.HEADER.TITLE)}</div>
          <div className={classes.FiltersStickyContainer}>
            <WorkflowsListFilters
              disabled={isLoading}
              filters={filters}
              onChangeFilter={onFiltersChange}
              counts={workflowCounts}
              onSort={onSort}
            />
          </div>
          <WorkflowsList workflows={listsData?.list || []} isLoading={isLoading} />
          <Pagination
            total={total}
            isLoading={isLoading}
            perPage={perPage}
            page={page}
            onPageChange={handlePageChange}
          />
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

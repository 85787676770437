import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {
  GlobalDocumentData,
  GlobalDocumentDataContextProvider,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useEffect, useMemo} from 'react';
import {getSegmentCategoriesMapNetworkRequest} from '../../../../../http/segment-categories.network-requests';
import {getFavoritesSegmentsNetworkRequest} from '../../../../../http/segments.network-requests';
import {ModelKey} from '../../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../store/core/core.actions';
import {useDispatch} from 'react-redux';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {NumberParam, useQueryParam} from 'use-query-params';
import {SUBSCRIPTION_ID_PATH_PARAM, TEAM_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {getSubscriptionResultDataNetworkRequest} from '../../../../../http/homepage.network-requests.ts';
import {exists} from 'front-core';

interface OwnProps {
  analysisResult: AnalysisResult;
  children: any;
}

type AllProps = OwnProps;

export const AppGlobalDocumentDataProvider = (props: AllProps) => {
  const {analysisResult, children} = props;
  const dispatch = useDispatch();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const [teamId, setTeamId_] = useQueryParam(TEAM_ID_PATH_PARAM, NumberParam);
  const [subscriptionId] = useQueryParam(SUBSCRIPTION_ID_PATH_PARAM, NumberParam);
  const {
    source: subscriptionResultData,
    exec: getSubscriptionResultData,
    isLoading: isLoadingSubscriptionResultData,
  } = useRemoteSourceStated({
    networkRequest: getSubscriptionResultDataNetworkRequest,
  });
  const {source: segmentCategoriesMap, exec: getSegmentCategoriesMap} = useRemoteSourceStated({
    networkRequest: getSegmentCategoriesMapNetworkRequest,
  });
  const {
    source: favoriteSegments,
    exec: getFavoriteSegments,
    isLoading: isLoadingFavoriteSegments,
  } = useRemoteSourceStated({
    networkRequest: getFavoritesSegmentsNetworkRequest,
  });

  useEffect(() => {
    getSegmentCategoriesMap();
    getFavoriteSegments();
    subscriptionId && getSubscriptionResultData(subscriptionId);
  }, [getSegmentCategoriesMap, getFavoriteSegments, getSubscriptionResultData, subscriptionId]);

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FAVORITE_SEGMENTS) {
        getFavoriteSegments();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getFavoriteSegments]);
  const setTeamId = useCallback(
    teamId =>
      teamId === null ? setTeamId_(undefined, 'replaceIn') : setTeamId_(teamId, 'replaceIn'),
    [setTeamId_]
  );

  const globalDocumentData: GlobalDocumentData = useMemo(
    () => ({
      analysisResultId: analysisResult.id,
      analysisId: analysisResult.analysisId,
      analysisTypeId: analysisResult.analysisTypeId,
      analysisTypeName: analysisResult.analysisTypeName,
      analysisName: analysisResult.userAnalysisName || analysisResult.analysisName,
      analysisRunParameters: analysisResult.runParameters,
      analysisParameters: analysisResult.parameters,
      analysisResultStatus: analysisResult.status,
      followUpAnalyses: analysisResult.followUpAnalyses,
      isFollowUp: analysisResult.isFollowUp,
      segmentCategoriesMap,
      favoriteSegments,
      teams,
      defaultTeamId,
      teamId,
      setTeamId,
      segmentPreferences: subscriptionId ? subscriptionResultData : null,
      teamSelectorDisabled: exists(subscriptionId),
    }),
    [
      analysisResult,
      segmentCategoriesMap,
      favoriteSegments,
      teams,
      defaultTeamId,
      teamId,
      setTeamId,
      subscriptionId,
      subscriptionResultData,
    ]
  );

  if (!favoriteSegments || isLoadingFavoriteSegments || isLoadingSubscriptionResultData) {
    return null;
  }

  return (
    <GlobalDocumentDataContextProvider {...globalDocumentData}>
      {children}
    </GlobalDocumentDataContextProvider>
  );
};

import * as React from 'react';
import {UnifiedRCASegmentationItem} from '../../../../../../rca-segmentation-extended.types';
import {withStopPropagation} from 'front-core';
import {TooltipIfOverflow} from '../../../../../../../../../../../../simple/generic/tooltips/tooltips.component';
import classes from './segment-group-name-display.module.scss';
import {CompositeDisplay} from '../../../../../../../../../shared/display-columns/composite-display.component';
import classNames from 'classnames';
import {useMemo} from 'react';
import {useDocumentTranslation} from '../../../../../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {SimilarityIndication} from '../similarity-indication/similarity-indication.component';
import {generateSegmentName} from '../../../../segment-explainers-table.utils';
import {
  ArrowRightLongLightIcon,
  FileContractLightIcon,
} from '../../../../../../../../../../../../simple/controls/icons/icons.component';
import {IconButton} from '../../../../../../../../../../../../simple/controls/icon-button/icon-button.component';

interface Props {
  item: UnifiedRCASegmentationItem;
  primary?: UnifiedRCASegmentationItem;
  showSimilarity?: boolean;
  className?: string;
  onViewFollowUpForSegment?: VoidFunction;
  onSignalClicked?: (signalId: number) => void;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const SegmentNameDisplay: React.FC<Props> = (props: Props) => {
  const {item, primary, showSimilarity, onViewFollowUpForSegment, className, onSignalClicked} =
    props;
  const isComposite = item.segmentKeys && item.segmentKeys.length > 1;
  const {t} = useDocumentTranslation();

  const similarityProps = useMemo(() => {
    let similarityProps: any;
    let helperText = '';

    if (showSimilarity) {
      similarityProps = {
        similarityType: item.similarityType,
        isPrimary: item.isPrimaryInGroup,
      };
      if (item.similarityType) {
        helperText = t(
          TRANS_KEYS_PREFIX.SIMILARITY_TYPE[item.similarityType.toUpperCase()]?.HELPER,
          {segment: generateSegmentName(primary)}
        );
      }
      if (item.isPrimaryInGroup) {
        helperText = t(TRANS_KEYS_PREFIX.SIMILARITY_TYPE.PRIMARY.HELPER);
      }
      similarityProps.helperText = helperText;
    }
    return similarityProps;
  }, [item.isPrimaryInGroup, item.similarityType, primary, showSimilarity, t]);

  const render = (item: UnifiedRCASegmentationItem) => (
    <div
      className={classNames(classes.ContentWrapper, onSignalClicked && classes.Clickable)}
      onClick={withStopPropagation(() => onSignalClicked(item.signalId))}
      key={item.key}
    >
      <TooltipIfOverflow title={item.segmentGroupName}>
        <div className={classes.SegmentGroup}>{item.segmentGroupName}</div>
      </TooltipIfOverflow>
      <ArrowRightLongLightIcon />
      <TooltipIfOverflow title={item.segmentName}>
        <div className={classes.SegmentName}>{item.segmentName}</div>
      </TooltipIfOverflow>
      {!isComposite && onViewFollowUpForSegment && (
        <IconButton
          className={classes.ViewAnalysisButton}
          border={false}
          tooltipText={'View analysis for this segment'}
          onClick={onViewFollowUpForSegment}
          icon={FileContractLightIcon}
        />
      )}
    </div>
  );

  let content = null;
  if (isComposite) {
    content = (
      <CompositeDisplay
        prefix={'AND'}
        className={classes.Composite}
        actions={
          onViewFollowUpForSegment && (
            <div className={classes.ViewAnalysisButtonWrapper}>
              <IconButton
                className={classes.ViewAnalysisButton}
                border={false}
                tooltipText={'View analysis for this composite segment'}
                onClick={onViewFollowUpForSegment}
                icon={FileContractLightIcon}
              />
            </div>
          )
        }
      >
        {item.segments.map(s => render(s))}
      </CompositeDisplay>
    );
  } else {
    content = render(item);
  }

  return (
    <div className={classNames(classes.SegmentGroupNameDisplay, className)}>
      {showSimilarity && <SimilarityIndication {...similarityProps} />}
      {content}
    </div>
  );
};

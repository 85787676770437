import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './segment-category-table.module.scss';
import {
  FavoriteSegment,
  Segment,
  SegmentCategory,
} from '../../../../../../objects/models/segment.model';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Avatar,
  Chip,
  CircleCheckRegularIcon,
  CopyIcon,
  EditIcon,
  IconButton,
  Link,
  MoreIcon,
  PlusLightIcon,
  RadarLightIcon,
  TeamIconChip,
  TrashIcon,
} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {Permission} from '../../../../../../core/components/permission.component';
import {Action, Subject} from '../../../../../../constants/permissions';
import {TitleWithIcon} from '../../../../../shared/components/general/title/title.component';
import {exists} from 'front-core';
import SampleClassesColumn from '../../../../components/sample-classes-column/sample-classes-column.component';
import {generateLastModified} from '../../../../../../utils/history.utils';
import moment from 'moment/moment';
import {withStopPropagation} from '../../../../../../utils/general.utils';
import usePermissions from '../../../../../../core/hooks/use-permissions.hook';
import {GridTable} from '../../../../../shared/components/general/grid-table/grid-table.component';
import {TableColumn} from '../../../../../shared/components/general/grid-table/grid-table.types';
import {ValidationStatus} from '../../../../../../objects/models/signal.model';
import InvalidSignalWarning from '../../../../components/invalid-signal-warning/invalid-signal-warning.component';
import {ModelActionsDropdown} from '../../../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {getEntityIcon} from '../../../../../../constants/entity.consts';

interface OwnProps {
  category: SegmentCategory;
  favoriteSegments?: FavoriteSegment[];
  onResetTeamFilter: () => void;
  onEditCategory: () => void;
  onDeleteCategory: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onCreateSegment: () => void;
  onAddToTeam: (segmentId: number, teamId: number) => void;
  onSegmentClicked: (segment: Segment) => void;
  onEditSegment: (segment: Segment) => void;
  onDuplicateSegment: (segment: Segment) => void;
  onRescanSegment: (segment: Segment) => void;
  onValidateSegment: (segment: Segment) => void;
  onDeleteSegment: (segment: Segment) => void;
  showActions: boolean;
  showTeams?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const SegmentCategoryTable = (props: AllProps) => {
  const {
    category,
    favoriteSegments,
    onEditCategory,
    onDeleteCategory,
    onMoveUp,
    onMoveDown,
    onCreateSegment,
    onSegmentClicked,
    onDuplicateSegment,
    onRescanSegment,
    onValidateSegment,
    onDeleteSegment,
    onEditSegment,
    onResetTeamFilter,
    onAddToTeam,
    showActions = true,
    showTeams,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap, actualTeams: teams} = useProductData();
  const {can} = usePermissions();
  const segments = useMemo(() => {
    if (favoriteSegments) {
      const signalIdsSet = new Set(favoriteSegments.map(s => s.signalId));
      return category.segments.filter(s => signalIdsSet.has(s.signalId));
    }
    return category.segments;
  }, [category, favoriteSegments]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '36rem',
        sticky: 'left',
        stretch: true,
        render: (segment: Segment) => (
          <div className={classes.NameColumn}>
            <span className={classes.Name}>{segment.name}</span>
            {segment.isConfounder && (
              <Chip
                size={'small'}
                label={'Confounder'}
                className={classes.Chip}
                helperText={t(TransKeys.SEGMENTS.CONFOUNDER_HELPER_TEXT)}
              />
            )}
            {segment.signalValidationStatus === ValidationStatus.ERROR && (
              <InvalidSignalWarning
                title={t(TransKeys.SEGMENTS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)}
              />
            )}
          </div>
        ),
      },
      {
        key: 'entity',
        title: t(TransKeys.GENERAL.HEADERS.ENTITY),
        width: '12rem',
        render: (segment: Segment) => (
          <TitleWithIcon
            titleClassName={classes.EntityColumn}
            text={productEntitiesMap[segment.entity].name}
            icon={getEntityIcon(segment.entity)}
          />
        ),
      },
      {
        key: 'segments',
        title: 'Segments',
        sortable: false,
        width: '40rem',
        render: (segment: Segment) => (
          <SampleClassesColumn
            sampleClasses={segment.classes}
            count={
              exists(segment.totalDistinctValues)
                ? segment.totalDistinctValues
                : segment.classes?.length
            }
            onMoreClick={() => onSegmentClicked(segment)}
            entityCreatedOn={segment.createdOn}
            className={classNames(classes.Item, classes.Classes)}
          />
        ),
      },
      {
        key: 'teams',
        title: t(TransKeys.GENERAL.HEADERS.TEAMS),
        sortable: false,
        width: '12rem',
        hidden: !showTeams,
        render: (segment: Segment) => {
          return (
            <div className={classes.Teams}>
              {segment.teams.map(t => (
                <TeamIconChip {...t} key={t.id} showTooltip />
              ))}
            </div>
          );
        },
      },
      {
        key: 'last_modified',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED),
        sortable: false,
        width: '16rem',
        render: (segment: Segment) => {
          const modifier = generateLastModified(segment.history[0], true);
          return (
            <div className={classes.Modifier}>
              {modifier?.user && (
                <Avatar name={modifier.user} size={'small'} className={classes.Avatar} />
              )}
              {modifier?.date ? modifier.date : moment.utc(segment.updatedOn).local().fromNow()}
            </div>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        sortable: false,
        width: '12rem',
        align: 'right',
        sticky: 'right',
        render: (segment: Segment) => {
          const teamOptions = teams.filter(
            t => segment.teams.find(t2 => t.id === t2.id) === undefined
          );
          return (
            <div className={classes.Actions}>
              <Permission subject={Subject.SEGMENT} action={Action.EDIT}>
                <IconButton
                  className={classes.Button}
                  tooltipText={t(TransKeys.GENERAL.ACTIONS.EDIT)}
                  icon={EditIcon}
                  onClick={withStopPropagation(() => onEditSegment(segment))}
                />
              </Permission>
              <Permission subject={Subject.SEGMENT} action={Action.CREATE}>
                <IconButton
                  className={classes.Button}
                  tooltipText={t(TransKeys.GENERAL.ACTIONS.DUPLICATE)}
                  icon={CopyIcon}
                  onClick={withStopPropagation(() => onDuplicateSegment(segment))}
                />
              </Permission>
              <ModelActionsDropdown
                actions={[
                  {
                    key: 'add-to-team',
                    title: 'Add to team',
                    icon: PlusLightIcon,
                    hide: teamOptions.length === 0,
                    children: teamOptions.map(t => ({
                      key: t.id,
                      title: t.name,
                      icon: ({className}) => <TeamIconChip {...t} className={className} />,
                      onClick: () => onAddToTeam(segment.id, t.id),
                    })),
                  },
                  {
                    key: 'rescan',
                    title: t(TransKeys.GENERAL.ACTIONS.RESCAN),
                    onClick: withStopPropagation(() => onRescanSegment(segment)),
                    icon: RadarLightIcon,
                  },
                  {
                    key: 'delete',
                    hide: !can(Subject.SEGMENT, Action.DELETE),
                    title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                    onClick: withStopPropagation(() => onDeleteSegment(segment)),
                    icon: TrashIcon,
                  },
                  {
                    key: 'validate',
                    title: t(TransKeys.GENERAL.ACTIONS.VALIDATE),
                    onClick: withStopPropagation(() => onValidateSegment(segment)),
                    icon: CircleCheckRegularIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </div>
          );
        },
      },
    ],
    [
      t,
      teams,
      showTeams,
      productEntitiesMap,
      onAddToTeam,
      onDeleteSegment,
      onEditSegment,
      onDuplicateSegment,
      onRescanSegment,
      onSegmentClicked,
      onValidateSegment,
      can,
    ]
  );

  return (
    <div className={classNames(classes.SegmentCategoryTable, className)}>
      <div className={classes.Header}>
        <div className={classes.Name}>{category.name}</div>
        <div className={classes.Count}>{segments.length} segments</div>
        <div className={classes.Spacer} />
        {showActions && (
          <div className={classes.Actions}>
            {onMoveDown && (
              <IconButton
                icon={ArrowDownIcon}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.MOVE_DOWN)}
                onClick={() => onMoveDown()}
              />
            )}
            {onMoveUp && (
              <IconButton
                icon={ArrowUpIcon}
                tooltipText={t(TransKeys.GENERAL.ACTIONS.MOVE_UP)}
                onClick={() => onMoveUp()}
              />
            )}
            <ModelActionsDropdown
              actions={[
                {
                  key: 'edit',
                  hide: !can(Subject.SEGMENT, Action.EDIT),
                  title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                  onClick: withStopPropagation(() => onEditCategory()),
                  icon: EditIcon,
                },
                {
                  key: 'delete',
                  hide: !can(Subject.SEGMENT, Action.DELETE),
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: withStopPropagation(() => onDeleteCategory()),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        )}
      </div>
      <div className={classes.Table}>
        <GridTable
          data={segments}
          dataKey={'id'}
          columns={columns}
          pagination={false}
          total={segments.length}
          onRowClicked={(segment: Segment) => onSegmentClicked(segment)}
          emptyStateRow={
            <div className={classes.EmptyState}>
              <span className={classes.Text}>
                {t(TransKeys.SEGMENTS.NO_SEGMENTS_EMPTY_STATE.TITLE)},
              </span>
              <Permission subject={Subject.SEGMENT} action={Action.CREATE}>
                <Link className={classes.Button} onClick={() => onCreateSegment()}>
                  {t(TransKeys.SEGMENTS.NO_SEGMENTS_EMPTY_STATE.BUTTON_TEXT)}
                </Link>
              </Permission>
            </div>
          }
          footer={
            favoriteSegments && segments.length < category.segments.length ? (
              <div className={classes.TeamFooterMoreSegments} onClick={onResetTeamFilter}>
                {t(TransKeys.SEGMENTS.LIST.SHOW_ALL_SEGMENTS, {
                  count: category.segments.length - segments.length,
                })}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};

import {Button} from 'ui-components';

import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classes from './error-viewer.module.scss';
import {ImagesResource} from '../../../../../../assets/images';
import {useCallback} from 'react';
import {AppRoutes} from '../../../../../../constants/app-routes';
import {useNavigate} from 'react-router';
import classNames from 'classnames';

export function Error1000Viewer(props: any) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const onBack = useCallback(() => navigate(AppRoutes.analyses()), [navigate]);

  return (
    <div className={classes.ErrorFigure}>
      <div className={classes.Error}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesResource.app.telescopeV2} alt={''} />
        </div>
        <div className={classes.Title}>{t(TransKeys.ANALYSIS_ERRORS.ERROR_1000.TITLE)}</div>
        <div className={classNames(classes.Title, classes.Light)}>
          {t(TransKeys.ANALYSIS_ERRORS.ERROR_1000.SUB_TITLE)}
        </div>
        <Button size={'large'} onClick={onBack}>
          {t(TransKeys.ANALYSIS_ERRORS.ERROR_1000.ACTION)}
        </Button>
      </div>
    </div>
  );
}

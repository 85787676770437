import {useCallback, useMemo} from 'react';
import {Button, FancyHeader, ModalLayout, TableTreeIcon} from 'ui-components';
import {
  METRIC_HEALTH_MONITOR_ID_PATH_PARAM,
  HEALTH_MONITOR_GROUP_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import classes from './health-monitor-group-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {FormProvider, useForm} from 'react-hook-form';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {composition, OnSuccessActionHook} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {TextFormInput} from '../../../shared/form/components/shared-form-input.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {HealthMonitorGroup} from '../../../../objects/models/health-monitor.model';
import {healthMonitorGroupDTOValidator} from '../../../../objects/dto/health-monitor.dto';
import {
  createHealthMonitorGroup,
  editHealthMonitorGroup,
} from '../../../../store/health-monitor/health-monitor.actions';
import {getHealthMonitorGroupNetworkRequest} from '../../../../http/health-monitor.network-requests';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  group?: HealthMonitorGroup;
  data: Partial<HealthMonitorGroup>;
  onSubmit?: (data: Partial<HealthMonitorGroup>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  disabled?: boolean;
  [HEALTH_MONITOR_GROUP_ID_PATH_PARAM]?: number;
  [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const createEmptyHealthMonitorGroup = (): Partial<HealthMonitorGroup> => ({
  name: '',
});

const SELECTED_HEALTH_MONITOR_GROUP_KEY = SharedSelectionKeys.HEALTH_MONITOR_GROUP_FORM__GROUP;
const MAX_NAME_LEN = 30;

const HealthMonitorGroupFormPanelComponent = (props: AllProps) => {
  const {
    group = {} as any,
    data = createEmptyHealthMonitorGroup(),
    onClose,
    onSuccess,
    [METRIC_HEALTH_MONITOR_ID_PATH_PARAM]: metricHealthMonitorId,
    onSubmit: onSubmitFromProps,
    disabled,
  } = props;
  const notify = useAmplitude();
  const user = useCurrentUser();
  const formMethods = useForm({
    defaultValues: {
      ...data,
      metricHealthMonitorId,
      ...group,
    } as any,
    resolver: yupResolver(healthMonitorGroupDTOValidator.noUnknown()),
  });
  const {handleSubmit, watch} = formMethods;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_HEALTH_MONITOR_GROUP,
      ActionKey.EDIT_HEALTH_MONITOR_GROUP
    )(state)
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      const action = editMode
        ? editHealthMonitorGroup(data, onActionSuccess)
        : createHealthMonitorGroup(data, onActionSuccess);
      dispatch(action);
      notify(AmplitudeEvent.HEALTH_MONITOR_GROUP_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onSuccess, onClose, user, notify]
  );
  const containerStyle = useMemo(() => ({height: '30rem', width: '54rem'}), []);

  return (
    <div style={containerStyle}>
      <ModalLayout
        footer={
          <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.HealthMonitorGroupPanel}>
          <FancyHeader
            icon={TableTreeIcon}
            title={t(
              editMode
                ? TransKeys.HEALTH_MONITOR_GROUP_FORM.TITLE_EDIT
                : TransKeys.HEALTH_MONITOR_GROUP_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.HEALTH_MONITOR_GROUP_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.Header}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      maxLength={MAX_NAME_LEN}
                      label={t(TransKeys.HEALTH_MONITOR_GROUP_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.HEALTH_MONITOR_GROUP_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const HealthMonitorGroupFormPanel = composition<AllProps>(
  HealthMonitorGroupFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    group: {
      selectedKey: SELECTED_HEALTH_MONITOR_GROUP_KEY,
      actionKey: SELECTED_HEALTH_MONITOR_GROUP_KEY,
      request: getHealthMonitorGroupNetworkRequest,
      mapPayloadFromProps: props => props[HEALTH_MONITOR_GROUP_ID_PATH_PARAM],
      shouldCall: props => props[HEALTH_MONITOR_GROUP_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default HealthMonitorGroupFormPanel;

import {useCallback, useMemo} from 'react';
import {Button, FancyHeader, ModalLayout, FolderUserIcon} from 'ui-components';
import {CATEGORY_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './metric-category-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {FormProvider, useForm} from 'react-hook-form';
import {Metric, MetricCategory} from '../../../../objects/models/metric.model';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {composition, OnSuccessActionHook} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {TextFormInput} from '../../../shared/form/components/shared-form-input.component';
import {preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {getMetricCategoryNetworkRequest} from '../../../../http/metric-categories.network-requests';
import {
  createMetricCategory,
  updateMetricCategory,
} from '../../../../store/metric-categories/metric-categories.actions';
import {metricCategoryValidator} from '../../../../objects/dto/metric.dto';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  metricCategory?: MetricCategory;
  data: Partial<MetricCategory>;
  onSubmit?: (data: Partial<MetricCategory>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  disabled?: boolean;
  [CATEGORY_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const createEmptyMetricCategory = (): Partial<Metric> => ({
  name: '',
});

const SELECTED_METRIC_KEY = SharedSelectionKeys.METRIC_CATEGORY_FORM__METRIC_CATEGORY;
const MAX_NAME_LEN = 30;

const MetricCategoryFormPanelComponent = (props: AllProps) => {
  const {
    metricCategory = {} as any,
    data = createEmptyMetricCategory(),
    onClose,
    onSubmit: onSubmitFromProps,
    onSuccess,
    disabled,
  } = props;
  const notify = useAmplitude();
  const user = useCurrentUser();
  const formMethods = useForm({
    defaultValues: {
      ...data,
      ...metricCategory,
    } as any,
    resolver: yupResolver(metricCategoryValidator.noUnknown()),
  });
  const {handleSubmit, watch} = formMethods;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_METRIC_CATEGORY,
      ActionKey.UPDATE_METRIC_CATEGORY
    )(state)
  );
  const isDisabled = useMemo(() => disabled || isLoading, [disabled, isLoading]);
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      const action = editMode
        ? updateMetricCategory(data, onActionSuccess)
        : createMetricCategory(data, onActionSuccess);
      dispatch(action);
      notify(AmplitudeEvent.METRIC_CATEGORY_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onClose, onSuccess, user, notify]
  );

  return (
    <div className={classes.CreateMetricCategoryContainer}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateMetricCategory}>
          <FancyHeader
            icon={FolderUserIcon}
            title={t(
              editMode
                ? TransKeys.METRIC_CATEGORY_FORM.TITLE_EDIT
                : TransKeys.METRIC_CATEGORY_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.METRIC_CATEGORY_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.Header}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      maxLength={MAX_NAME_LEN}
                      label={t(TransKeys.METRIC_CATEGORY_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.METRIC_CATEGORY_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const MetricCategoryFormPanel = composition<AllProps>(
  MetricCategoryFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    metricCategory: {
      selectedKey: SELECTED_METRIC_KEY,
      actionKey: SELECTED_METRIC_KEY,
      request: getMetricCategoryNetworkRequest,
      mapPayloadFromProps: props => props[CATEGORY_ID_PATH_PARAM],
      shouldCall: props => props[CATEGORY_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default MetricCategoryFormPanel;

import classNames from 'classnames';
import classes from './data-pipeline-form-panel.module.scss';
import {DATA_PIPELINE_ID_PATH_PARAM} from '../../../../constants/app-routes.ts';
import {DataPipeline} from '../../../../objects/models/data-pipeline.model.ts';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys.ts';
import {composition, OnSuccessActionHook} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc.tsx';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc.tsx';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc.tsx';
import {AppTabs, Button, FancyHeader, FunnelIcon, ModalLayout} from 'ui-components';
import TransKeys from 'translations';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {dataPipelineValidator} from '../../../../objects/dto/data-pipeline.dto.ts';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors.ts';
import {ActionKey} from '../../../../constants/action-key.ts';
import {Milestone} from '../../../../objects/models/milestone.model.ts';
import {sharedClasses} from '../../../shared';
import {preventSubmitOnEnter} from '../../../../utils/general.utils.ts';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component.tsx';
import {TextFormInput} from '../../../shared/form/components/shared-form-input.component.tsx';
import {SqlInput} from '../../../shared/form/components/sql-input/sql-input.component.tsx';
import {
  createDataPipeline,
  updateDataPipeline,
} from '../../../../store/data-pipelines/data-pipelines.actions.ts';
import {getDataPipelineNetworkRequest} from '../../../../http/data-pipelines.network-requests.ts';

interface Props {
  [DATA_PIPELINE_ID_PATH_PARAM]?: string;
  dataPipeline?: DataPipeline;
  data?: Partial<DataPipeline>;
  onSubmit?: (data: Partial<Milestone>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  cloneMode?: boolean;
  disabled?: boolean;
}

const createDataPipelineEventGroup = (): Partial<DataPipeline> => ({
  name: '',
  createSql: '',
  updateSql: '',
});

const SELECTED_DATA_PIPELINE_KEY = SharedSelectionKeys.DATA_PIPELINE_FORM__DATA_PIPELINE;

export enum DataPipelineTabKey {
  CREATE_SQL = 'create_sql',
  UPDATE_SQL = 'update_sql',
}

export const DataPipelineFormPanelComponent = (props: Props) => {
  const {
    dataPipeline,
    data,
    onSubmit: onSubmitFromProps,
    onSuccess,
    onClose,
    cloneMode,
    disabled,
  } = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(DataPipelineTabKey.CREATE_SQL);
  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);
  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...createDataPipelineEventGroup(),
      ...data,
      ...dataPipeline,
      ...overrideData,
    } as any,
    resolver: yupResolver(dataPipelineValidator.noUnknown()),
  });
  const {
    handleSubmit,
    watch,
    control,
    formState: {errors, isValidating, isSubmitting},
  } = formMethods;
  const editMode = Boolean(watch('id'));

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_DATA_PIPELINE,
      ActionKey.UPDATE_DATA_PIPELINE
    )(state)
  );
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );

  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      const action = editMode
        ? updateDataPipeline(data, onActionSuccess)
        : createDataPipeline(data, onActionSuccess);
      dispatch(action);
    },
    [dispatch, editMode, onSubmitFromProps, onClose, onSuccess]
  );

  const tabs = useMemo(() => {
    return [
      {
        key: DataPipelineTabKey.CREATE_SQL,
        label: (
          <span className={classNames(errors.createSql?.message && classes.Error)}>
            Create SQL*
          </span>
        ),
      },
      {key: DataPipelineTabKey.UPDATE_SQL, label: <span>Update SQL</span>},
    ];
  }, [errors]);

  return (
    <div className={classes.Container}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        <div className={classes.DataPipelineFormPanel}>
          <FancyHeader
            icon={FunnelIcon}
            title={editMode ? 'Edit Data Pipeline' : 'Create Data Pipeline'}
            onClose={onClose}
            className={classes.DataPipelineFormHeader}
          />
          <FormProvider {...formMethods}>
            <form className={sharedClasses.Form} onKeyDown={preventSubmitOnEnter}>
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={'Pipeline Table Name'}
                      placeholder={'Enter table name (only letters and underscores)'}
                      name={'name'}
                      autoFocus
                      required
                    />
                  </div>
                </div>
                <div className={classes.SQLInputs}>
                  <AppTabs
                    tabs={tabs}
                    selected={selectedTab}
                    onChange={setSelectedTab as any}
                    fullWidth
                  />
                  <div
                    className={classNames(
                      classes.SQLInput,
                      selectedTab === DataPipelineTabKey.UPDATE_SQL && classes.Hidden
                    )}
                  >
                    <Controller
                      render={({field}) => (
                        <SqlInput
                          value={field.value || ''}
                          placeholder={t(TransKeys.TABLE_FORM.INPUTS.DEFINITION.QUERY.PLACEHOLDER)}
                          onChange={field.onChange}
                          minHeight="14rem"
                          disabled={disabled}
                        />
                      )}
                      name={'createSql'}
                      control={control}
                    />
                  </div>
                  <div
                    className={classNames(
                      classes.SQLInput,
                      selectedTab === DataPipelineTabKey.CREATE_SQL && classes.Hidden
                    )}
                  >
                    <Controller
                      render={({field}) => (
                        <SqlInput
                          value={field.value || ''}
                          placeholder={t(TransKeys.TABLE_FORM.INPUTS.DEFINITION.QUERY.PLACEHOLDER)}
                          onChange={field.onChange}
                          minHeight="14rem"
                          disabled={disabled}
                        />
                      )}
                      name={'updateSql'}
                      control={control}
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const DataPipelineFormPanel = composition<Props>(
  DataPipelineFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    dataPipeline: {
      selectedKey: SELECTED_DATA_PIPELINE_KEY,
      actionKey: SELECTED_DATA_PIPELINE_KEY,
      request: getDataPipelineNetworkRequest,
      mapPayloadFromProps: props => props[DATA_PIPELINE_ID_PATH_PARAM],
      shouldCall: props => props[DATA_PIPELINE_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default DataPipelineFormPanel;

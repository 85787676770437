import {useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from './treatment-selector.module.scss';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {isEmpty, values} from 'lodash';
import {exists} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {hasErrors} from '../../../../../../utils/general.utils';
import {TreatmentSmartSelector} from '../../../../../shared/core/smart-selector/treatment-smart-selector.component';
import {DaysCountSelector} from '../days-count-selector/days-count-selector.component';
import {useRemoteSourceStated} from 'ui-components';
import {getSignalNetworkRequest} from '../../../../../../http/signals.network-requests';
import {AnalysisSelectorProps, AnalysisSelectorVariant} from '../analysis-selector.types';
import {TableEntity} from '../../../../../../objects/models/table.model';

export interface TreatmentSchemaKeysMapping {
  treatments_tag: string;
  treatments_signals: string;
  days_count_key?: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: TreatmentSchemaKeysMapping = {
  treatments_tag: 'treatments_tag',
  treatments_signals: 'treatments_signals',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: TreatmentSchemaKeysMapping;
  placeholder?: string;
  since?: number | string;
  warnMessage?: any;
  entityContext: TableEntity;
  signalFilters?: any;
}

type AllProps = OwnProps;

export const TreatmentSelector = (props: AllProps) => {
  const {
    title,
    subTitle,
    helperText,
    errors,
    onChange: onChange_,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    since,
    value: data,
    variant,
    warnMessage,
    entityContext,
    signalFilters,
    className,
  } = props;
  const {
    exec: getSinceSignal,
    source: sinceSignal,
    clear: clearSinceSignal,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSignalNetworkRequest,
  });
  const {t} = useTranslation();
  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const treatmentError = useMemo(
    () =>
      hasErrors(errors, [schemaKeysMapping.treatments_signals, schemaKeysMapping.treatments_tag]),
    [errors, schemaKeysMapping]
  );
  const value = useMemo(() => {
    const treatmentsTags =
      data[schemaKeysMapping.treatments_tag]?.split(',').filter(o => !!o) || [];
    return [...treatmentsTags, ...(data[schemaKeysMapping.treatments_signals] || [])];
  }, [data, schemaKeysMapping]);
  const onChange = useCallback(
    v => {
      const treatmentsTag =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'string').join(',') : undefined;
      const treatmentsSignals =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'number') : undefined;
      onChange_({
        [schemaKeysMapping.treatments_tag]: treatmentsTag,
        [schemaKeysMapping.treatments_signals]: treatmentsSignals,
      });
    },
    [onChange_, schemaKeysMapping]
  );

  useEffect(() => {
    if (since && typeof since === 'number') {
      getSinceSignal(since);
    } else {
      clearSinceSignal();
    }
  }, [since, clearSinceSignal, getSinceSignal]);

  const renderContent = (inline = false) => {
    return (
      <TreatmentSmartSelector
        value={value}
        onChange={onChange}
        entityContext={entityContext}
        signalFilters={signalFilters}
        placeholder={
          inline
            ? t(TransKeys.TREATMENT_SELECTOR.SELECT_PLACEHOLDER_INLINE)
            : t(TransKeys.TREATMENT_SELECTOR.SELECT_PLACEHOLDER)
        }
        selectedText={t(TransKeys.TREATMENT_SELECTOR.SELECT_PLACEHOLDER_INLINE)}
        className={variant === AnalysisSelectorVariant.INLINE ? className : undefined}
        error={treatmentError}
      />
    );
  };

  if (!entityContext) {
    return null;
  }

  if (variant === AnalysisSelectorVariant.INLINE) {
    return renderContent(true);
  }

  return (
    <ParameterInputWrapper
      title={
        title === undefined ? t(TransKeys.ANALYSIS_FORMS.SHARED.FEATURE_INTERACTION.TITLE) : title
      }
      subTitle={
        subTitle === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.FEATURE_INTERACTION.SUB_TITLE)
          : subTitle
      }
      className={classNames(classes.FeatureInteractionSelector, className)}
      helperText={
        helperText === undefined
          ? t(TransKeys.ANALYSIS_FORMS.SHARED.FEATURE_INTERACTION.HELPER_TEXT)
          : helperText
      }
      error={hasError}
    >
      {renderContent()}
      {warnMessage && <div className={classes.WarnMessage}>{warnMessage}</div>}
      {schemaKeysMapping.days_count_key && (
        <DaysCountSelector
          className={classes.DaysCountSelectorContent}
          value={data}
          onChange={onChange_}
          errors={errors}
          prefix={t(TransKeys.TREATMENT_SELECTOR.DAYS_SELECTOR_PREFIX)}
          suffix={t(TransKeys.TREATMENT_SELECTOR.DAYS_SELECTOR_SUFFIX, {
            name: sinceSignal?.name || since || 'starting point',
          })}
          schemaKeysMapping={schemaKeysMapping as any}
          suffixHelper={t(TransKeys.TREATMENT_SELECTOR.DAYS_SELECTOR_SUFFIX_HELPER)}
          variant={AnalysisSelectorVariant.INLINE}
          clearable={false}
        />
      )}
    </ParameterInputWrapper>
  );
};

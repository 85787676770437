import {
  AnomalyMode,
  HomepageSettings,
  HomepageSubscribedSegmentsType,
  HomepageSubscriptionPushType,
} from '../../../../objects/models/homepage.model';

export const createDefaultSubscription = (homepageSettings: HomepageSettings) => {
  const res = {
    homepageId: homepageSettings.homepageId,
    anomalyMode: AnomalyMode.LOOPS_ALGO,
    anomalyThreshold: 0.1,
    subscribedSegmentsType: HomepageSubscribedSegmentsType.ALL,
    via: [],
    isActive: true,
    isDailyActive: false,
    isWeeklyActive: true,
    isMonthlyActive: false,
    slackChannels: [],
    monthlyMetricSettings: {},
    weeklyMetricSettings: {},
    dailyMetricSettings: {},
    monthlyFunnelSettings: {},
    weeklyFunnelSettings: {},
    dailyFunnelSettings: {},
    breakdownSegmentIds: [],
    excludeCompositeSegments: false,
    excludeNonInformativeSegments: false,
  };

  homepageSettings.metrics.forEach(metricId => {
    res.monthlyMetricSettings[metricId] = HomepageSubscriptionPushType.NEVER;
    res.weeklyMetricSettings[metricId] = HomepageSubscriptionPushType.ALWAYS;
    res.dailyMetricSettings[metricId] = HomepageSubscriptionPushType.NEVER;
  });
  homepageSettings.funnels.forEach(funnelId => {
    res.monthlyFunnelSettings[funnelId] = HomepageSubscriptionPushType.NEVER;
    res.weeklyFunnelSettings[funnelId] = HomepageSubscriptionPushType.NEVER;
    res.dailyFunnelSettings[funnelId] = HomepageSubscriptionPushType.NEVER;
  });
  return res;
};

import {exists} from 'front-core';
import {
  ImpactPPDisplay,
  ImpactValueDisplay,
  RCAImpactMode,
  RCAMode,
  TrendChip,
} from 'ui-components';
import {HomepageMetricViewer} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-metric-viewer/homepage-metric-viewer.component.tsx';
import {
  ModelSample,
  ModelSeriesGranularity,
} from '../../../../../../objects/models/model-sample-series.model.ts';
import {useContext, useMemo} from 'react';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook.ts';
import {formatMetricValue} from '../../kpi-tree.utils.ts';
import {KPITreeContext} from '../../kpi-tree.context.tsx';
import {KPITreePopoverLayout} from '../kpi-tree-popover-layout/kpi-tree-popover-layout.component.tsx';
import classes from './kpi-node.module.scss';
import {Tooltip} from '@material-ui/core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useTrendForTree} from '../../hooks/use-trend-for-tree.hook.ts';
import {MetricNodeRCAData} from '../../../../../../objects/models/metric-tree.model.ts';

interface OwnProps {
  name: string;
  metricId: number;
  isPercentageValue: boolean;
  granularity: ModelSeriesGranularity;
  lastSample?: ModelSample;
  previousSample?: ModelSample;
  rcaData?: MetricNodeRCAData;
  higherIsBetter?: boolean;
  children: any;
}

type AllProps = OwnProps;

export const KPINodePopover = (props: AllProps) => {
  const {
    metricId,
    name,
    granularity,
    lastSample,
    previousSample,
    rcaData,
    isPercentageValue,
    higherIsBetter,
    children,
  } = props;
  const {t} = useTranslation();
  const {toDate, datetimeAttribute, rcaMode, impactMode} = useContext(KPITreeContext);
  const {userSettings} = useProductData();
  const trend = useTrendForTree(lastSample, previousSample, higherIsBetter, rcaMode);
  const prev = useMemo(() => {
    let label = `Previous${granularity === 'day' ? ' week  ' : ' '} ${granularity}`;
    let content = exists(previousSample?.value)
      ? formatMetricValue(previousSample.value, isPercentageValue)
      : '-';
    if (rcaMode === RCAMode.LOOPS_ALGO) {
      label = 'Expected by Loops';
      content = exists(lastSample?.expectedValue)
        ? formatMetricValue(lastSample.expectedValue, isPercentageValue)
        : '-';
    }
    return {
      label,
      content,
    };
  }, [rcaMode, granularity, isPercentageValue, lastSample, previousSample]);

  return (
    <KPITreePopoverLayout
      title={name}
      subTitleItems={[
        {
          label: `Last ${granularity}`,
          content: exists(lastSample?.value)
            ? formatMetricValue(lastSample.value, isPercentageValue)
            : '-',
        },
        prev,
        trend && {
          label: 'Change',
          content: (
            <TrendChip
              value={trend.value}
              size={'xsmall'}
              isSignificant={trend.isSignificant}
              inf={trend.inf}
              variant={'ghost'}
            />
          ),
        },
        exists(rcaData?.ctcPp) && {
          label: 'Impact',
          content: (
            <Tooltip title={t(TransKeys.KPI_TREE.EXPLAINABILITY.IMPACT_IN_PP.HELPER_TEXT)}>
              <div>
                {impactMode === RCAImpactMode.SHARE && (
                  <ImpactPPDisplay className={classes.Impact} value={rcaData.ctcPp} />
                )}
                {impactMode === RCAImpactMode.VALUE && (
                  <ImpactValueDisplay
                    isPercentage={isPercentageValue}
                    className={classes.Impact}
                    value={rcaData.ctcMetric}
                  />
                )}
              </div>
            </Tooltip>
          ),
        },
      ]}
      content={
        <HomepageMetricViewer
          metricId={metricId}
          configuration={userSettings}
          showHeader={false}
          showOptions={false}
          showLegend={false}
          toDate={toDate}
          datetimeAttribute={datetimeAttribute}
          granularity={granularity}
        />
      }
    >
      {children}
    </KPITreePopoverLayout>
  );
};

import classNames from 'classnames';
import classes from './metric-item-builder.module.scss';
import {ELEMENT_IDENTIFIER_INDEX} from '../../exploration.utils.ts';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {getMetricMenuItems} from '../../../../../dmp/panels/metric-form-panel/metric-form-panel.utils.ts';
import {MetricType} from '../../../../../../objects/models/metric.model.ts';
import {
  ChevronFullDownIcon,
  CloseRegularIcon,
  DialogLayout,
  IconButton,
  LabelWrapper,
  PopoverWrapper,
  SideMenu,
  SwitchActions,
} from 'ui-components';
import {getMetricTypeName} from '../../../../../../constants/options.ts';
import {MetricQueryBuilder} from '../../../../../dmp/panels/metric-form-panel/components/metric-query-builder/metric-query-builder.component.tsx';
import {TableEntity} from '../../../../../../objects/models/table.model.ts';
import {useSignalInitialQueryFactory} from '../../../../../dmp/panels/metric-form-panel/hooks/use-signal-initial-query-factory.hook.ts';
import {useMetricAggregationModeSwitchActions} from '../../../../../analyses/analysis-forms/hooks/use-metric-aggregation-mode-switch-actions.hook.ts';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {AggregationMode} from '../../../../../../objects/models/signal.model.ts';
import {ExploreItem} from '../../../../../../objects/dto/exploration.dto.ts';

interface Props {
  index: number;
  item: ExploreItem;
  onChangeItem: (item: ExploreItem) => void;
  onRemoveItem: () => void;
  entity: TableEntity;
  errors?: any;
  className?: string;
}

const getAggregationMode = (type: MetricType) => {
  return [MetricType.USAGE, MetricType.REVENUE].includes(type) ? AggregationMode.SUM : null;
};

export const MetricItemBuilder = (props: Props) => {
  const {index, item, entity, errors, onChangeItem, onRemoveItem, className} = props;
  const [selectedType, setSelectedType] = useState<MetricType>(null);
  const popoverRef = useRef<any>(null);
  const {createInitialQuery} = useSignalInitialQueryFactory();
  const {t} = useTranslation();
  const elementIdentifier = ELEMENT_IDENTIFIER_INDEX[index];

  const metricAggregationModeSwitchActions = useMetricAggregationModeSwitchActions({
    metricType: selectedType,
    mode: item?.aggregationMode,
    onChange: mode =>
      onChangeItem({
        ...item,
        aggregationMode: mode,
      }),
  });
  const metricMenuItems = useMemo(() => {
    return [...getMetricMenuItems(false)];
  }, []);
  const onChangeDefinition = useCallback(
    (definition: any) => {
      onChangeItem({
        ...item,
        definition,
      });
    },
    [onChangeItem, item]
  );
  const onTypeChange = useCallback(
    (type: MetricType) => {
      setSelectedType(type);
      const aggregationMode = getAggregationMode(type);
      onChangeItem({
        ...item,
        type,
        definition: createInitialQuery(type, entity),
        aggregationMode,
      });
      popoverRef.current?.close();
    },
    [createInitialQuery, entity, item, onChangeItem]
  );

  useEffect(() => {
    selectedType === null && onTypeChange(MetricType.USAGE);
  }, [selectedType, onTypeChange]);

  return (
    <div className={classNames(classes.MetricItemBuilder, className)}>
      <div className={classes.Top}>
        <div className={classes.Identifier}>{elementIdentifier}</div>
        <div className={classes.Selector}>
          <PopoverWrapper
            ref={popoverRef}
            popoverDirection={'left'}
            buttonRenderer={({onClick, isOpen}) => (
              <div
                onClick={onClick}
                className={classNames(classes.TypeSelectorButton, isOpen && classes.Open)}
              >
                <span>{getMetricTypeName(selectedType)}</span>
                <ChevronFullDownIcon className={classes.Chevron} />
              </div>
            )}
          >
            <DialogLayout className={classes.DialogLayout}>
              <SideMenu items={metricMenuItems} onSelect={onTypeChange} />
            </DialogLayout>
          </PopoverWrapper>
        </div>
        <div className={classes.Remove}>
          <IconButton
            icon={CloseRegularIcon}
            onClick={() => onRemoveItem()}
            size={'small'}
            tooltipText={'Remove metric'}
          />
        </div>
      </div>
      <div className={classes.Definition}>
        <MetricQueryBuilder
          key={selectedType}
          signalDefinition={item?.definition}
          onChangeSignalDefinition={onChangeDefinition}
          onResetSignalDefinition={() => onChangeDefinition(null)}
          errors={errors?.definition}
          entity={entity}
          withWrapper={false}
        />
        {metricAggregationModeSwitchActions.length > 0 && (
          <LabelWrapper
            label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.AGGREGATION_MODE.LABEL)}
            className={classes.AggregationModeLabel}
          >
            <SwitchActions showActionsLabel actions={metricAggregationModeSwitchActions} />
          </LabelWrapper>
        )}
      </div>
    </div>
  );
};

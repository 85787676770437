import {useCallback, useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button, FancyHeader, LabelWrapper, ModalLayout, RefreshIcon} from 'ui-components';
import {MILESTONE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './milestone-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {FormProvider, useForm} from 'react-hook-form';
import {Milestone, MilestoneType} from '../../../../objects/models/milestone.model';
import {milestoneDTOValidator} from '../../../../objects/dto/milestone.dto';
import {createMilestone, updateMilestone} from '../../../../store/milestones/milestones.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {sharedClasses} from '../../../shared';
import {
  getMilestoneNetworkRequest,
  getMilestonesStatusNetworkRequest,
} from '../../../../http/milestones.network-requests';
import {composition, OnSuccessActionHook} from 'front-core';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  OptionTab,
  OptionTabs,
} from '../../../shared/components/general/options-tabs/options-tabs.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {TableEntity, TableEntityBinding} from '../../../../objects/models/table.model';
import {EntityPicker} from '../../components/entity-picker/entity-picker.component';
import {MilestoneTabLabel} from './components/milestone-tab-label.component';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {MilestoneTabContent} from './components/milestone-tab-content.component';
import {getMilestoneTypeName} from '../../../../constants/options';
import {ActionKey} from '../../../../constants/action-key';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {MILESTONE_FORM_TABS} from './milestone-form-panel.consts.ts';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';

type MilestonesStatus = {
  [key in TableEntity]?: {
    [key in MilestoneType]?: number[];
  };
};

interface OwnProps {
  milestone?: Milestone;
  type?: MilestoneType;
  milestonesStatus: MilestonesStatus;
  data: Partial<Milestone>;
  onSubmit?: (data: Partial<Milestone>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  disabled?: boolean;
  [MILESTONE_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const MilestoneFormPanelComponent = (props: AllProps) => {
  const {
    data,
    milestone,
    type,
    milestonesStatus,
    onSubmit: onSubmitFromProps,
    onSuccess,
    onClose,
    disabled,
  } = props;
  const showBehavioralMilestoneBuilder = useFeatureIsOn(FeatureFlag.BEHAVIORAL_MILESTONE as string);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const {defaultTableEntity, getEntityName} = useProductData();
  const {demoProductValidator} = useDemoProduct();

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_MILESTONE, ActionKey.UPDATE_MILESTONE)(state)
  );

  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      entity: defaultTableEntity,
      type: type,
      name: type ? getMilestoneTypeName(type) : '',
      ...data,
      ...milestone,
    } as any,
    resolver: yupResolver(demoProductValidator || milestoneDTOValidator.noUnknown()),
  });

  const {
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: {isSubmitting, isValidating},
  } = formMethods;
  const name = watch('name');
  const editMode = Boolean(watch('id'));
  const entity = watch('entity');
  const milestoneType = watch('type');

  const signalDefaultFilters = useMemo(
    () => ({
      entityContext: entity,
      entityBinding: TableEntityBinding.DEFAULT,
    }),
    [entity]
  );

  const clearSignalDefinition = useCallback(() => {
    setValue('signalDefinition', null);
    clearErrors();
  }, [setValue, clearErrors]);

  const onSwitchEntity = useCallback(
    (entity: TableEntity) => {
      setValue('entity', entity);
      clearSignalDefinition();
    },
    [setValue, clearSignalDefinition]
  );

  const onTabChange = useCallback(
    (milestoneType_: MilestoneType) => {
      if (!name || name === getMilestoneTypeName(milestoneType)) {
        setValue('name', getMilestoneTypeName(milestoneType_));
      }
      setValue('type', milestoneType_);
      clearSignalDefinition();
    },
    [setValue, clearSignalDefinition, name, milestoneType]
  );

  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      const action = editMode
        ? updateMilestone(data, onActionSuccess)
        : createMilestone(data, onActionSuccess);
      dispatch(action);
    },
    [dispatch, editMode, onSubmitFromProps, onClose, onSuccess]
  );

  const definedMilestoneIds = useCallback(
    (entity, milestoneType) => milestonesStatus[entity]?.[milestoneType] || [],
    [milestonesStatus]
  );

  const viewMilestone = useCallback(
    (entity, milestoneType) => {
      openPrimaryPanel(
        PanelKey.VIEW_MILESTONE_PANEL,
        {
          [MILESTONE_ID_PATH_PARAM]: definedMilestoneIds(entity, milestoneType)[0],
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, definedMilestoneIds]
  );

  const editMilestone = useCallback(
    (entity, milestoneType) => {
      openPrimaryPanel(
        PanelKey.MILESTONE_FORM_PANEL,
        {
          [MILESTONE_ID_PATH_PARAM]: definedMilestoneIds(entity, milestoneType)[0],
        },
        PanelType.MODAL
      );
    },
    [openPrimaryPanel, definedMilestoneIds]
  );

  const isSubmitDisabled = useMemo(() => {
    const isTypeAlreadyDefined = definedMilestoneIds(entity, milestoneType).length > 0;
    const allowManyDefinitions = MILESTONE_FORM_TABS.find(
      tab => tab.key === milestoneType
    )?.allowManyDefinitions;

    if (disabled) {
      return true;
    }
    if (isLoading || isSubmitting || isValidating) {
      return true;
    }
    if (!milestoneType) {
      return true;
    }
    if (editMode) {
      return false;
    }
    if (isTypeAlreadyDefined && !allowManyDefinitions) {
      return true;
    }
    return false;
  }, [
    isLoading,
    milestoneType,
    editMode,
    entity,
    definedMilestoneIds,
    isSubmitting,
    isValidating,
    disabled,
  ]);

  const tabs: OptionTab[] = useMemo(
    () =>
      MILESTONE_FORM_TABS.map(tab => {
        const {key} = tab;
        const title = getMilestoneTypeName(key);
        const description = t(TransKeys.MILESTONE.TYPE[key.toUpperCase()].DESCRIPTION, {
          entityName: getEntityName(entity),
          entityNamePlural: getEntityName(entity, true),
        });
        const signalFilters = {
          ...signalDefaultFilters,
          ...(tab.signalFilters || {}),
        };
        const Builder = showBehavioralMilestoneBuilder
          ? tab.Builder
          : tab.fallbackBuilder || tab.Builder;

        return {
          key: tab.key,
          title: title,
          disabled: editMode,
          render: () => (
            <MilestoneTabContent
              {...tab}
              Builder={Builder}
              entity={entity}
              milestoneType={key}
              title={title}
              description={description}
              editMode={editMode}
              existingMilestoneIds={definedMilestoneIds(entity, key)}
              onViewMilestone={viewMilestone}
              onEditMilestone={editMilestone}
              clearSignalDefinition={clearSignalDefinition}
              signalFilters={signalFilters}
            />
          ),
        };
      }),
    [
      t,
      editMode,
      entity,
      signalDefaultFilters,
      clearSignalDefinition,
      definedMilestoneIds,
      editMilestone,
      viewMilestone,
      getEntityName,
      showBehavioralMilestoneBuilder,
    ]
  );

  return (
    <div className={classes.ModalContainer}>
      <ModalLayout
        footer={
          <div className={classes.ModalFooter}>
            <NoteText
              text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
              buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
              onButtonClicked={contactSupport}
            />
            <Button onClick={handleSubmit(onSubmit)} disabled={isSubmitDisabled}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {(isLoading || isValidating) && <GenericLoading />}
        <div className={classes.CreateMilestone}>
          <FancyHeader
            className={classes.CreateMilestoneHeader}
            icon={RefreshIcon}
            title={t(
              editMode ? TransKeys.MILESTONE_FORM.TITLE_EDIT : TransKeys.MILESTONE_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.MILESTONE_FORM.MAIN_TITLE)}
            onClose={onClose}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormHiddenInputs names={['id', 'type']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.MILESTONE_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.MILESTONE_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.MILESTONE_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.MILESTONE_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.SEGMENT_FORM.INPUTS.ENTITY)} fullWidth>
                      <EntityPicker value={entity} editMode={!editMode} onChange={onSwitchEntity} />
                    </LabelWrapper>
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.GENERAL.LABELS.DEFINE)} fullWidth>
                      <OptionTabs
                        renderTabTitle={tab => (
                          <MilestoneTabLabel
                            title={tab.title}
                            isDefined={definedMilestoneIds(entity, tab.key).length >= 1}
                          />
                        )}
                        renderEmptyState={() => (
                          <div className={classes.EmptyState}>
                            {t(TransKeys.MILESTONE_FORM.SELECT_MILESTONE_TYPE)}
                          </div>
                        )}
                        tabs={tabs}
                        selectedKey={milestoneType}
                        onTabChange={v => {
                          onTabChange(v as MilestoneType);
                        }}
                      />
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const MilestoneFormPanel = composition<AllProps>(
  MilestoneFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    milestone: {
      selectedKey: SharedSelectionKeys.MILESTONE_FORM__MILESTONE,
      actionKey: SharedSelectionKeys.MILESTONE_FORM__MILESTONE,
      request: getMilestoneNetworkRequest,
      mapPayloadFromProps: props => props[MILESTONE_ID_PATH_PARAM],
      shouldCall: props => props[MILESTONE_ID_PATH_PARAM] !== undefined,
    },
    milestonesStatus: {
      selectedKey: SharedSelectionKeys.MILESTONE_FORM__MILESTONES_STATUS,
      actionKey: SharedSelectionKeys.MILESTONE_FORM__MILESTONES_STATUS,
      request: getMilestonesStatusNetworkRequest,
    },
  })
);

export default MilestoneFormPanel;

import PageLayout from '../../../shared/components/layout/page-layout';
import TransKeys from 'translations';
import {Button, DisplayChartUpRegularIcon, useRemoteSourceStated} from 'ui-components';
import {useTranslation} from 'react-i18next';
import classes from './exploration-main.module.scss';
import {ExplorationController} from './components/exploration-controller/exploration-controller.component.tsx';
import {AggregationMode} from '../../../../objects/models/signal.model.ts';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {TableEntity} from '../../../../objects/models/table.model.ts';
import {useProductData} from '../../../../core/hooks/use-product-data.hook.ts';
import yup from '../../../../config/yup.config.ts';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {values} from 'lodash';
import {querySchemaValidator} from '../../../../objects/dto/query-builder.dto.ts';
import {nullableValues} from '../../../../utils/yup.utils.ts';
import {ExploreData} from '../../../../objects/dto/exploration.dto.ts';
import {postExplorationDataNetworkRequest} from '../../../../http/exploration.network-requests.ts';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {MetricType} from '../../../../objects/models/metric.model.ts';
import {ELEMENT_IDENTIFIER_INDEX} from './exploration.utils.ts';
import {useModelSampleTimeframe} from '../../../homepage/components/homepage-summary/hooks/homepage-summary.hooks.ts';
import {ExploreDataResponse} from '../../../../objects/models/exploration.model.ts';
import {ExploreViewer} from './components/explore-viewer/explore-viewer.component.tsx';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model.ts';

interface Props {}

export const DEFAULT_EXPLORE_DATA: any = {
  items: [],
};

const validator = yup.object().shape({
  items: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        type: yup.string().oneOf(values(MetricType)).required(),
        signalId: yup.number().nullable(),
        definition: querySchemaValidator(),
        aggregationMode: yup.string().oneOf(nullableValues(AggregationMode)).nullable(),
      })
    ),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  populationFilter: querySchemaValidator(false),
  groupBySignalIds: yup.array().of(yup.number()).nullable(),
});

export const ExplorationMain = (props: Props) => {
  const {t} = useTranslation();
  const {defaultTableEntity} = useProductData();
  const [lastSubmittedData, setLastSubmittedData] = useState<ExploreData>(null);
  const [selectedGranularity, setSelectedGranularity] = useState<ModelSeriesGranularity>(null);
  const [renderKey, setRenderKey] = useState(0);

  const defaultData: ExploreData = useMemo(
    () => ({
      ...DEFAULT_EXPLORE_DATA,
      entity: defaultTableEntity,
    }),
    [defaultTableEntity]
  );
  const timeframeProps = useModelSampleTimeframe({});
  const {filters: timeframeFilters} = timeframeProps;
  const allFilters = useMemo(
    () => ({
      ...timeframeFilters,
      granularity: selectedGranularity,
    }),
    [timeframeFilters, selectedGranularity]
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultData,
    } as any,
    resolver: yupResolver(validator.noUnknown()),
  });
  const {
    handleSubmit,
    formState: {errors},
    getValues,
    reset,
  } = formMethods;
  const {
    source: explorationResponse,
    exec: postExplorationData,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: postExplorationDataNetworkRequest,
  });
  const exploreDataResponse: ExploreDataResponse = useMemo(() => {
    if (!explorationResponse) {
      return null;
    }
    return explorationResponse;
  }, [explorationResponse]);

  const data = getValues();
  const setData = useCallback((data: ExploreData) => reset(data), [reset]);
  const getExplorationData = useCallback(
    async (data, filters) => {
      await postExplorationData({
        ...data,
        ...filters,
      });
      setRenderKey(prev => prev + 1);
    },
    [postExplorationData, setRenderKey]
  );
  const onSubmit = useCallback(
    data => {
      const fixedData = {
        ...data,
        items: data.items.map((item, idx) => ({
          ...item,
          identifier: ELEMENT_IDENTIFIER_INDEX[idx],
        })),
      };
      setLastSubmittedData(fixedData);
    },
    [setLastSubmittedData]
  );
  useEffect(() => {
    lastSubmittedData && getExplorationData(lastSubmittedData, allFilters);
  }, [lastSubmittedData, allFilters, getExplorationData]);

  return (
    <PageLayout.Layout>
      <PageLayout.Title
        title={t(TransKeys.EXPLORATION.HEADER.TITLE)}
        icon={DisplayChartUpRegularIcon}
      />
      <PageLayout.Actions>
        <Button size="large" onClick={console.log}>
          New Question
        </Button>
      </PageLayout.Actions>
      <PageLayout.Body noPadding className={classes.PageBody}>
        <div className={classes.ExplorationMain}>
          <div className={classes.Controller}>
            <ExplorationController
              exploreData={data}
              onChange={setData}
              onSubmit={handleSubmit(onSubmit)}
              errors={errors}
              disabled={isLoading}
            />
          </div>
          <div className={classes.View}>
            <div className={classes.ViewWrapper}>
              {isLoading && <GenericLoading />}
              {exploreDataResponse && (
                <ExploreViewer
                  key={renderKey}
                  granularity={selectedGranularity}
                  onChangeGranularity={setSelectedGranularity}
                  exploreData={exploreDataResponse}
                  timeframeProps={timeframeProps}
                />
              )}
            </div>
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

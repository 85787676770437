import {
  CommandType,
  DocumentCommand,
  FollowUpType,
  TreatmentAdoptionSimulationItem,
  TreatmentAdoptionSimulationItemType,
} from '../../../../../types';
import {ExtendedTreatmentAdoptionSimulationItem} from './base-treatment-adoption-simulation-viewer.component';
import {
  calculateMaxImpact,
  calculateMaxPotential,
  SimulatorItemType,
} from '../../../../../hooks/use-simulator.hook';
import {safeDivision} from 'front-core';

const TYPE_MAPPING = {
  [TreatmentAdoptionSimulationItemType.POSITIVE]: TreatmentAdoptionSimulationItemType.POSITIVE,
  [TreatmentAdoptionSimulationItemType.NEGATIVE]: TreatmentAdoptionSimulationItemType.NEGATIVE,
  [TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION]:
    TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION,
  [TreatmentAdoptionSimulationItemType.INSIGNIFICANT_POSITIVE]:
    TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
  [TreatmentAdoptionSimulationItemType.INSIGNIFICANT_NEGATIVE]:
    TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
};

export const extendTreatmentAdoptionSimulationItems = (
  items: TreatmentAdoptionSimulationItem[],
  goalValue: number,
  types?: TreatmentAdoptionSimulationItemType[]
): ExtendedTreatmentAdoptionSimulationItem[] => {
  let newItems: ExtendedTreatmentAdoptionSimulationItem[] = items as any;
  if (types) {
    newItems = newItems.filter(i => types.indexOf(i.type) > -1);
  }
  const simulatorType = (type: TreatmentAdoptionSimulationItemType) => {
    if (
      type === TreatmentAdoptionSimulationItemType.POSITIVE ||
      type === TreatmentAdoptionSimulationItemType.INSIGNIFICANT_POSITIVE
    ) {
      return SimulatorItemType.POSITIVE;
    }
    if (
      type === TreatmentAdoptionSimulationItemType.NEGATIVE ||
      type === TreatmentAdoptionSimulationItemType.INSIGNIFICANT_NEGATIVE
    ) {
      return SimulatorItemType.NEGATIVE;
    }
  };
  newItems = newItems.map(i => {
    let item = {
      ...i,
      type: TYPE_MAPPING[i.type],
      actualType: i.type,
      uplift: safeDivision(i.goalInAdopters - i.goalInNonAdopters, Math.abs(i.goalInNonAdopters)),
      // useSimulator interface
      value: i.adoption,
      insignificant: TYPE_MAPPING[i.type] === TreatmentAdoptionSimulationItemType.INSIGNIFICANT,
      simulationType: simulatorType(i.type),
    };
    item = {
      ...item,
      ...calculateMaxPotential(goalValue, item),
      ...calculateMaxImpact(item),
    };
    return item;
  });

  return newItems;
};

export const fixOptionTypes = (types: TreatmentAdoptionSimulationItemType[]) => {
  const newTypes = new Set<TreatmentAdoptionSimulationItemType>(types.map(t => TYPE_MAPPING[t]));
  // return array + remove undefined
  return Array.from(newTypes).filter(t => t);
};

import * as React from 'react';
import classNames from 'classnames';
import classes from './explainers-table.module.scss';
import {Sorting, withStopPropagation} from 'front-core';
import {ExplainersTableHeader} from './components/explainers-table-header/explainers-table-header.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../../../hooks/use-document-translation.hook';
import {
  RCAImpactMode,
  RCASegmentationUsersMode,
  RCASegmentationViewMode,
} from '../../../../../rca-figure/rca-figure-viewer.types';
import {RCASegmentationFigureOptions} from '../../../../../../../../types';
import {Tooltip} from '@material-ui/core';
import {
  HashtagIcon,
  PercentageIcon,
} from '../../../../../../../../../../simple/controls/icons/icons.component';
import pluralize from 'pluralize';

interface OwnProps {
  onSort: (columnKey: string) => void;
  onChangeUsersMode: (mode: RCASegmentationUsersMode) => void;
  onChangeImpactInPPMode: (mode: RCAImpactMode) => void;
  sorting: Sorting;
  variant?: 'default' | 'blue';
  viewMode: RCASegmentationViewMode;
  usersMode: RCASegmentationUsersMode;
  impactInPPMode: RCAImpactMode;
  options: RCASegmentationFigureOptions;
  version: number;
  children: any;
  className?: string;
}

type AllProps = OwnProps;
const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const ExplainersTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    onSort,
    sorting,
    onChangeUsersMode,
    onChangeImpactInPPMode,
    viewMode,
    usersMode,
    impactInPPMode,
    variant,
    options,
    version,
    children,
    className,
  } = props;
  const {t} = useDocumentTranslation();

  return (
    <div className={classNames(classes.ExplainersTable, classes[variant], className)}>
      <div className={classes.HeaderWrapper}>
        <div className={classes.Headers}>
          <ExplainersTableHeader
            columnKey={'segmentName'}
            title={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE)}
            subTitle={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.SUB_TITLE)}
            helperText={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.HELPER_TEXT)}
          />
          {viewMode === RCASegmentationViewMode.EXPLAIN && (
            <ExplainersTableHeader
              columnKey={'explainabilityScore'}
              title={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.TITLE)}
              subTitle={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.SUB_TITLE)}
              helperText={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.HELPER_TEXT)}
              onSort={() => onSort('explainabilityScore')}
              sorting={sorting}
            />
          )}
          {viewMode === RCASegmentationViewMode.CHANGE && (
            <ExplainersTableHeader
              columnKey={'interestScore'}
              title={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.TITLE)}
              subTitle={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.SUB_TITLE)}
              helperText={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.HELPER_TEXT)}
              onSort={() => onSort('interestScore')}
              sorting={sorting}
            />
          )}
          <ExplainersTableHeader
            columnKey={usersMode}
            title={t(TRANS_KEYS_PREFIX.TABLE.HEADERS[usersMode.toUpperCase()].TITLE, {
              entity: pluralize(options.entity),
            })}
            subTitle={t(TRANS_KEYS_PREFIX.TABLE.HEADERS[usersMode.toUpperCase()].SUB_TITLE, {
              entity: pluralize(options.entity),
            })}
            helperText={t(TRANS_KEYS_PREFIX.TABLE.HEADERS[usersMode.toUpperCase()].HELPER_TEXT, {
              entity: pluralize(options.entity),
            })}
            onSort={() => onSort(usersMode)}
            sorting={sorting}
            actions={
              <Tooltip
                placement={'top'}
                title={
                  usersMode === RCASegmentationUsersMode.COUNT
                    ? t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.TITLE, {
                        entity: pluralize(options.entity),
                      })
                    : t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.TITLE, {
                        entity: pluralize(options.entity),
                      })
                }
              >
                <div
                  className={classes.SwitchColumn}
                  onClick={withStopPropagation(() =>
                    onChangeUsersMode(
                      usersMode === RCASegmentationUsersMode.COUNT
                        ? RCASegmentationUsersMode.SHARE
                        : RCASegmentationUsersMode.COUNT
                    )
                  )}
                >
                  {usersMode === RCASegmentationUsersMode.COUNT ? (
                    <PercentageIcon />
                  ) : (
                    <HashtagIcon />
                  )}
                </div>
              </Tooltip>
            }
          />
          <ExplainersTableHeader
            columnKey={'value'}
            title={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.TITLE)}
            subTitle={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.SUB_TITLE)}
            helperText={t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT)}
            onSort={() => onSort('value')}
            sorting={sorting}
          />
          <ExplainersTableHeader
            columnKey={'ctcPp'}
            title={
              version > 1.3 && impactInPPMode === RCAImpactMode.VALUE
                ? t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_VALUE.TITLE)
                : t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.TITLE)
            }
            subTitle={
              impactInPPMode === RCAImpactMode.VALUE
                ? t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_VALUE.SUB_TITLE)
                : t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.SUB_TITLE)
            }
            helperText={
              impactInPPMode === RCAImpactMode.VALUE
                ? t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_VALUE.HELPER_TEXT)
                : t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.HELPER_TEXT)
            }
            onSort={() => onSort('ctcPp')}
            sorting={sorting}
            actions={
              version > 1.3 && (
                <Tooltip
                  placement={'top'}
                  title={
                    impactInPPMode === RCAImpactMode.VALUE
                      ? t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.TITLE)
                      : t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_VALUE.TITLE)
                  }
                >
                  <div
                    className={classes.SwitchColumn}
                    onClick={() =>
                      onChangeImpactInPPMode(
                        impactInPPMode === RCAImpactMode.SHARE
                          ? RCAImpactMode.VALUE
                          : RCAImpactMode.SHARE
                      )
                    }
                  >
                    {impactInPPMode === RCAImpactMode.VALUE ? <PercentageIcon /> : <HashtagIcon />}
                  </div>
                </Tooltip>
              )
            }
          />
        </div>
      </div>
      <div className={classes.Body}>{children}</div>
    </div>
  );
};

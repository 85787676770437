import {createRequestEpic, upsert} from 'front-core';
import {Epic, ofType} from 'redux-observable';
import {
  UserOnboardingActionsType,
  checkOnboardingCompletion,
  setDrawerCollapse,
  onUserOnboardingQuestionnaireCompleted,
  setSelectSimpleAnalysisPickerPanelData,
  exposeDrawerOnPageLoad,
  exposeDrawerOnTaskCompleted,
} from './user-onboarding.actions';
import {ActionKey} from '../../constants/action-key';

import {
  getUserOnboardingNetworkRequest,
  dismissUserOnboardingNetworkRequest,
} from '../../http/user-onboarding.network-requests';
import {USER_ONBOARDING_STORE_KEY} from './user-onboarding.store';
import HttpClient from '../../services/http-client.service';
import {getProductData, notifyEvent} from '../core/core.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {filter, map, switchMap, delay, mergeMap, catchError} from 'rxjs/operators';
import {concat, empty, from, of} from 'rxjs';
import {isEmpty} from 'lodash';
import {updateUserQuestionnaireNetworkRequest} from '../../http/user-onboarding.network-requests';
import {errorExtractor} from '../store.utils';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {
  getAnalysisTypeIdsByMetricType,
  USER_KPI_TYPE_OPTION_ORDER_FOR_SIMPLE_ANALYSIS_PICKER_PANEL,
} from '../../modules/core/pages/questionnaire/fragments/user-specific-kpis-step/user-specific-kpis-step.component';
import {getMetricNetworkRequest} from '../../http/metrics.network-requests';
import {SIGNAL_ID_PATH_PARAM} from '../../constants/app-routes';
import {MetricType} from '../../objects/models/metric.model';
import {LocalStorageManager} from 'ui-components';
import {LocalStorageKey} from '../../constants/local-storage-key';

export const getUserOnboardingEpic: Epic = createRequestEpic(
  {
    types: [UserOnboardingActionsType.GET_USER_ONBOARDING],
    actionKey: ActionKey.GET_USER_ONBOARDING,
    request: getUserOnboardingNetworkRequest,
    onSuccess: data => [
      exposeDrawerOnPageLoad(data),
      exposeDrawerOnTaskCompleted(data),
      checkOnboardingCompletion(data),
      upsert(USER_ONBOARDING_STORE_KEY, 'data', data),
    ],
  },
  HttpClient
);

export const dismissUserOnboardingEpic: Epic = createRequestEpic(
  {
    types: [UserOnboardingActionsType.DISMISS_USER_ONBOARDING],
    actionKey: ActionKey.DISMISS_USER_ONBOARDING,
    request: dismissUserOnboardingNetworkRequest,
    onSuccess: (_, {isDismissed}) => [
      notifyEvent(
        isDismissed ? AmplitudeEvent.ONBOARDING_DISMISSED : AmplitudeEvent.ONBOARDING_RESUMED
      ),
    ],
  },
  HttpClient
);

export const setOnboardingResetEpic: Epic = action$ =>
  action$.pipe(
    ofType(UserOnboardingActionsType.RESET_USER_ONBOARDING),
    filter(({payload}) => payload),
    map(() => notifyEvent(AmplitudeEvent.ONBOARDING_RESET))
  );

export const setOnboardingDrawerCollapseEpic: Epic = action$ =>
  action$.pipe(
    ofType(UserOnboardingActionsType.SET_DRAWER_COLLAPSE),
    map(({payload}) =>
      notifyEvent(
        payload ? AmplitudeEvent.ONBOARDING_DRAWER_CLOSED : AmplitudeEvent.ONBOARDING_DRAWER_OPENED
      )
    )
  );

export const exposeDrawerOnPageLoadEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UserOnboardingActionsType.EXPOSE_DRAWER_ON_PAGE_LOAD),
    filter(({payload}) => {
      const isForceCollapsed = LocalStorageManager.getItem(
        LocalStorageKey.USER_ONBOARDING_FORCE_COLLAPSE
      );
      if (!payload || isForceCollapsed) {
        return false;
      }
      const isFirstLoad = isEmpty(state$.value[USER_ONBOARDING_STORE_KEY].data);
      const currentCompletedTasksCount = payload?.tasks?.filter(t => t.completedOn)?.length;
      const isPageLoadedButNoTasksCompleted = Boolean(
        isFirstLoad && currentCompletedTasksCount < 2
      );
      return isFirstLoad && isPageLoadedButNoTasksCompleted;
    }),
    map(() => setDrawerCollapse(false))
  );

export const exposeDrawerOnCompletedTaskEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UserOnboardingActionsType.EXPOSE_DRAWER_ON_TASK_COMPLETED),
    filter(({payload}) => {
      if (!payload) {
        return false;
      }

      const previous = state$.value[USER_ONBOARDING_STORE_KEY].data;
      const isFirstLoad = isEmpty(previous);
      const currentCompletedTasksCount = payload?.tasks?.filter(t => t.completedOn)?.length;
      const previousCompletedTasksCount = previous?.tasks?.filter(t => t.completedOn)?.length;
      const isCompletedTasksCountChanged = Boolean(
        currentCompletedTasksCount !== previousCompletedTasksCount
      );

      return !isFirstLoad && isCompletedTasksCountChanged;
    }),
    map(() => setDrawerCollapse(false))
  );

export const checkOnboardingCompletionEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UserOnboardingActionsType.CHECK_ONBOARDING_COMPLETION),
    filter(({payload}) => {
      if (!payload) {
        return false;
      }
      const {completedOn} = payload;
      const isFirstLoad = isEmpty(state$.value[USER_ONBOARDING_STORE_KEY].data);
      const stateIsCompleted = state$.value[USER_ONBOARDING_STORE_KEY].data?.completedOn;
      return Boolean(completedOn && !stateIsCompleted && !isFirstLoad);
    }),
    switchMap(() =>
      concat(
        of(upsert(USER_ONBOARDING_STORE_KEY, 'showConfetti', true)),
        of(upsert(USER_ONBOARDING_STORE_KEY, 'showConfetti', false)).pipe(delay(5000))
      )
    )
  );

export const updateUserQuestionnaireEpic: Epic = createRequestEpic(
  {
    types: [UserOnboardingActionsType.UPDATE_USER_ONBOARDING_QUESTIONNAIRE],
    actionKey: ActionKey.UPDATE_USER_ONBOARDING_QUESTIONNAIRE,
    request: updateUserQuestionnaireNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.UPDATE_USER_ONBOARDING_QUESTIONNAIRE_SUCCESS, payload),
      getProductData(),
      onUserOnboardingQuestionnaireCompleted(res),
    ],
    onError: (err_, payload) => {
      const err = errorExtractor(err_);
      if (err.errorCode === 1001) {
        return [
          showToastMessage(err.message, ToastType.ERROR),
          notifyEvent(AmplitudeEvent.UPDATE_USER_ONBOARDING_QUESTIONNAIRE_ERROR),
        ];
      }
      return [
        showToastMessage(i18n.t(TransKeys.TOASTS.UPDATE_USER_QUESTIONNAIRE_ERROR), ToastType.ERROR),
        notifyEvent(AmplitudeEvent.UPDATE_USER_ONBOARDING_QUESTIONNAIRE_ERROR),
      ];
    },
  },
  HttpClient
);

export const onUserOnboardingQuestionnaireCompletedShowSimpleAnalysisModalEpic = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(UserOnboardingActionsType.ON_USER_ONBOARDING_QUESTIONNAIRE_COMPLETED),
    map(({payload}: any) => {
      const firstUserKPITypeOption =
        USER_KPI_TYPE_OPTION_ORDER_FOR_SIMPLE_ANALYSIS_PICKER_PANEL.find(
          kpiType => payload.userSpecificKpis[kpiType]
        );
      return firstUserKPITypeOption ? payload.userSpecificKpis[firstUserKPITypeOption] : null;
    }),
    filter(Boolean),
    switchMap((metricId: number) =>
      from(HttpClient.exec(getMetricNetworkRequest(metricId))).pipe(
        mergeMap((res: any) =>
          of(
            setSelectSimpleAnalysisPickerPanelData({
              [SIGNAL_ID_PATH_PARAM]: res.signalId,
              analysisTypeIds: getAnalysisTypeIdsByMetricType(res.type as MetricType),
            })
          )
        ),
        catchError(err => of(empty()))
      )
    )
  );

import {Button} from 'ui-components';
import {RunAnalysesRecommendationMessageData} from '../../../../../../../../objects/models/ai-chat.model';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import {useCallback, useContext} from 'react';
import {PanelsContext} from '../../../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../../../constants/panels';
import {
  ANALYSIS_PARAMETERS_KEY,
  ANALYSIS_TYPE_ID_PATH_PARAM,
} from '../../../../../../../../constants/app-routes';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useAmplitude} from '../../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../../constants/amplitude-event';

type AllProps = BaseMessageRendererProps<RunAnalysesRecommendationMessageData>;

export const RunAnalysesRecommendationMessage = (props: AllProps) => {
  const {message} = props;
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const onClickRunAnalysis = useCallback(() => {
    notify(AmplitudeEvent.AI_CHATBOT_USER_CLICKED_RUN_ANALYSIS, {
      analysisType: message?.content?.analysisTypeId,
    });
    openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: message?.content?.analysisTypeId,
      [ANALYSIS_PARAMETERS_KEY]: message?.content?.parameters,
    });
  }, [openPrimaryPanel, notify, message]);

  return (
    <div>
      <Button onClick={onClickRunAnalysis}>
        {t(TransKeys.GEN_AI_BOT.RUN_ANALYSIS_RECOMMENDATION_MESSAGE.CTA.LABEL, {
          analysisName: message?.content?.analysisType,
        })}
      </Button>
    </div>
  );
};

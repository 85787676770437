import {debounceTime, of, Subject} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {useEffect, useMemo} from 'react';

export const useThrottle = <T = any>(callback: (data: T) => void, debounceTimeMS: number = 250) => {
  const subject = useMemo(() => new Subject<T>(), []);

  useEffect(() => {
    const subs = subject
      .pipe(
        debounceTime(debounceTimeMS),
        switchMap(data => of(data).pipe(tap(data => callback(data))))
      )
      .subscribe();
    return () => {
      subs.unsubscribe();
    };
  }, [callback, debounceTimeMS, subject]);

  return subject;
};

export const useThrottlePromise = <T = any>(
  callback: (data: T) => Promise<any>,
  debounceTimeMS: number = 250
) => {
  const subject = useMemo(() => new Subject<T>(), []);

  useEffect(() => {
    const subs = subject
      .pipe(
        debounceTime(debounceTimeMS),
        switchMap(data => callback(data))
      )
      .subscribe();
    return () => {
      subs.unsubscribe();
    };
  }, [callback, debounceTimeMS, subject]);

  return subject;
};

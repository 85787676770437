import {EntityImpactSentenceExplainer} from '../rca-insights.model.ts';
import {
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import sharedClasses from '../../insights.module.scss';
import * as React from 'react';
import {useMemo} from 'react';
import {getPercentageValueFormatter} from 'ui-components';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';

interface OwnProps extends EntityImpactSentenceExplainer {}

type AllProps = OwnProps;

export const EntityExplainerSentenceFormatter = (props: AllProps) => {
  const {sentenceParts} = props;
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(sentenceParts.isPercentage),
    [sentenceParts.isPercentage]
  );
  const propertiesValues: string[] = useMemo(
    () =>
      sentenceParts.eventPropertyValues.map(p => {
        return `${p.name} (from ${valueFormatter(p.value)} to ${valueFormatter(p.expectedValue)} ${sentenceParts.kpiName})`;
      }),
    [sentenceParts, valueFormatter]
  );

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      <RCAExplainersExplainabilityLabelDisplay label={sentenceParts.explainabilityLabel} />
      <RCAExplainersIcon
        variant={RCAExplainersIconVariant.ENTITY_EXPLAINER}
        text={sentenceParts.entity}
      />
      <SentencePart bold text={sentenceParts.name} />
      <SentencePart text={sentenceParts.eventPropertyName} />
      {propertiesValues.map((value, idx) => (
        <SentencePart text={`${value}${idx === propertiesValues.length - 1 ? '' : ', '}`} />
      ))}
    </SentenceContainer>
  );
};

import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import {KPIGraphMessageData} from '../../../../../../../../objects/models/ai-chat.model';
import classes from './kpi-graph-message.module.scss';
import {
  ArrowRightLightIcon,
  Button,
  DatePickerInput,
  LineChart,
  RCAMode,
  Select,
  useRemoteSourceStated,
} from 'ui-components';
import {getAIChartThreadsNetworkRequest} from '../../../../../../../../http/widgets.network-requests';
import {GenericLoading} from '../../../../../../../shared/components/general/generic-loading/generic-loading.component';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import {MetricSmartSelector} from '../../../../../../../shared/core/smart-selector/metric-smart-selector/metric-smart-selector.component';
import {SegmentSmartSelector} from '../../../../../../../shared/core/smart-selector/segment-smart-selector.component';
import {Segment} from '../../../../../../../../objects/models/segment.model';
import {TableEntityBinding} from '../../../../../../../../objects/models/table.model';
import {AnalysisTypeId} from '../../../../../../../../constants/analysis-type-id';
import {Metric} from '../../../../../../../../objects/models/metric.model';
import {HttpClientContext} from 'front-core';
import {getMetricNetworkRequest} from '../../../../../../../../http/metrics.network-requests';
import {getSegmentNetworkRequest} from '../../../../../../../../http/segments.network-requests';
import {AnalysisResultStatus} from '../../../../../../../../objects/models/analysis-result.model';
import {RCAExecutiveSummery} from '../../../../../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/rca-executive-summery.component';
import moment from 'moment';
import {createStartDateComparingProps} from '../../../../../../../../utils/dates.utils';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
} from '../../../../../../../../constants/app-routes';
import {METRIC_PAGE_DATE_QUERY_PARAM} from '../../../../../../../metrics/pages/metric-page/metric-page.component';
import {NavLink} from 'react-router-dom';
import {sharedClasses} from '../../../../../../../shared';
import {ImagesResource} from '../../../../../../../../assets/images';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {SegmentPerformanceSummary} from '../../../segment-performance-summary/segment-performance-summary.component.tsx';

interface Props extends BaseMessageRendererProps<KPIGraphMessageData> {
  className?: string;
}

const BASE_SEGMENT_FILTERS = {
  useAsExplainer: true,
};

export const KPIGraphMessage = (props: Props) => {
  const {message, onRequestAnalysis, className} = props;
  const {t} = useTranslation();
  const http = useContext(HttpClientContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [maxDate, setMaxDate] = useState<Date>();
  const [filters, setFilters] = useState({
    ...message.content,
    rcaQuestion: message.content.isRcaQuestion,
  });
  const {
    source: widgetData,
    exec,
    isLoading,
  } = useRemoteSourceStated({
    type: 'source',
    networkRequest: getAIChartThreadsNetworkRequest,
    onError: e => {
      setShowErrorMessage(true);
    },
  });
  const dateSelectorRef = useRef(null);
  const onChangeDate = useCallback(
    value => {
      dateSelectorRef.current?.close();
      setFilters(filters => ({
        ...filters,
        endDate: moment.utc(value).startOf('day').toISOString(),
      }));
    },
    [setFilters]
  );
  const date = useMemo(() => {
    return widgetData?.endDate;
  }, [widgetData]);
  const chartProps = useMemo(() => {
    if (!widgetData) {
      return;
    }
    const datasets = widgetData.datasets.map(ds => ({
      id: ds.key,
      label: ds.name,
      data: ds.values.map(v => ({
        x: v.datetime,
        y: widgetData.isPercentageValue ? v.value * 100 : v.value,
        upper: widgetData.isPercentageValue ? v.upper * 100 : v.upper,
        lower: widgetData.isPercentageValue ? v.lower * 100 : v.lower,
      })),
    }));

    const displayedDatasetIds = [];
    if (widgetData?.segmentValues) {
      datasets.forEach(ds => {
        if (widgetData.segmentValues.includes(ds.label)) {
          displayedDatasetIds.push(ds.id);
        }
      });
    } else {
      displayedDatasetIds.push(datasets[0].id);
    }

    return {
      datasets: datasets,
      displayedDatasetIds: displayedDatasetIds,
      options: {
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: widgetData.granularity,
        },
        yLabelSuffix: widgetData.isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        errorBar: true,
        showQuarters: true,
        showHideCI: false,
        showHideAnnotation: false,
        xLabel: 'Date',
      },
    } as any;
  }, [widgetData]);
  const segmentFilters = useMemo(
    () => ({
      ...BASE_SEGMENT_FILTERS,
      entityContext: widgetData?.entity,
      entityBinding: TableEntityBinding.ONE_WAY,
    }),
    [widgetData]
  );
  const segmentValuesOptions = useMemo(() => {
    return (widgetData?.segmentClassesOptions || []).map(o => ({value: o, label: o}));
  }, [widgetData]);
  const datePickerProps = useMemo(
    () =>
      widgetData
        ? createStartDateComparingProps(widgetData.granularity || widgetData.defaultGranularity)
        : {},
    [widgetData]
  );
  const onFilterChanged = useCallback(
    newFilters => setFilters(filters => ({...filters, ...newFilters})),
    [setFilters]
  );
  const onMetricChange = useCallback(
    (metricId: number) => onFilterChanged({metricId}),
    [onFilterChanged]
  );
  const onChangeSegment = useCallback(
    (segment: Segment) => {
      onFilterChanged({
        segmentId: segment ? segment.id : null,
        segmentValues: null,
      });
    },
    [onFilterChanged]
  );
  const onSegmentValuesChange = useCallback(
    (segmentValues: string[]) => setFilters(filters => ({...filters, segmentValues})),
    [setFilters]
  );
  const onRunRCA = useCallback(async () => {
    const metric = (await http.exec(
      getMetricNetworkRequest(filters.metricId)
    )) as unknown as Metric;
    let segment: Segment;
    if (filters.segmentId) {
      segment = (await http.exec(
        getSegmentNetworkRequest(filters.segmentId)
      )) as unknown as Segment;
    }
    onRequestAnalysis(AnalysisTypeId.RCA_ANALYSIS, {
      entity: metric.entity,
      goal: metric.signalId,
      included_segments_signals: segment ? [segment.signalId] : undefined,
    });
  }, [onRequestAnalysis, filters, http]);
  const viewResultURL = useMemo(() => {
    if (!widgetData) {
      return;
    }
    if (widgetData.granularity === widgetData.defaultGranularity) {
      return AppRoutes.viewMetric(message.content.metricId, {
        [METRIC_PAGE_DATE_QUERY_PARAM]: moment
          .utc(widgetData.endDate)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      });
    }
    return AppRoutes.viewAnalysis(widgetData.analysisId, {
      [ANALYSIS_RESULT_ID_PATH_PARAM]: widgetData.analysisResultId,
    });
  }, [widgetData, message]);
  useEffect(() => {
    setShowErrorMessage(false);
    exec(filters);
  }, [exec, filters, setShowErrorMessage]);
  useEffect(() => {
    if (widgetData) {
      setMaxDate(moment.utc(widgetData.maxDate).toDate());
    }
  }, [widgetData, setMaxDate]);

  const showExecutiveSummary =
    !isLoading && widgetData && message.content.isRcaQuestion && !message.content.segmentId;

  const showSegmentPerformance =
    !isLoading &&
    widgetData &&
    message.content.isRcaQuestion &&
    message.content.segmentId &&
    message.content.segmentValues;

  const showFullAnalysisLink = widgetData?.analysisResultId;
  const fullAnalysisLink = (
    <div className={classes.SeeAnalysis}>
      <NavLink
        to={viewResultURL}
        className={classNames(sharedClasses.UnstyledLink, classes.Button)}
      >
        {t(TransKeys.GEN_AI_BOT.KPI_GRAPH_MESSAGE.FULL_ANALYSIS_LINK)} <ArrowRightLightIcon />
      </NavLink>
    </div>
  );
  return (
    <div className={classes.KPIGraphMessageContainer}>
      {t(TransKeys.GEN_AI_BOT.KPI_GRAPH_MESSAGE.CONTENT)}
      <div
        className={classNames(
          classes.KPIGraphMessage,
          showErrorMessage && classes.Error,
          className
        )}
      >
        {isLoading && <GenericLoading />}
        <div className={classes.Filters}>
          <span className={classes.Text}>Measuring</span>
          <MetricSmartSelector
            value={filters.metricId}
            onChange={onMetricChange}
            disabled={isLoading}
          />
          {message.content.segmentId && (
            <>
              <span className={classes.Text}>group by</span>
              <SegmentSmartSelector
                placeholder={'select group by'}
                value={filters.segmentId}
                filters={segmentFilters}
                onChange={(value, segment) => onChangeSegment(segment)}
                disabled={isLoading}
                clearable
              />
              {filters.segmentId && (
                <>
                  <span className={classes.Text}>include</span>
                  <Select
                    placeholder={'select values'}
                    value={filters.segmentValues || widgetData?.segmentValues || []}
                    options={{options: segmentValuesOptions}}
                    onChange={onSegmentValuesChange as any}
                    clearable={false}
                    searchable={segmentValuesOptions.length > 5}
                    sortValues={false}
                    capitalize={false}
                    disabled={!widgetData || isLoading}
                    multi
                  />
                </>
              )}
            </>
          )}
          <span className={classes.Text}>for date</span>
          <DatePickerInput
            className={classes.DatePickerInput}
            onChange={onChangeDate}
            value={date}
            maxDate={maxDate}
            {...datePickerProps}
            disabled={!widgetData || isLoading}
            dateFormat={TIME_FORMATS.READABLE_DATE}
          />
        </div>
        <div className={classes.ChatWrapper}>
          {chartProps && <LineChart {...chartProps} />}
          {showErrorMessage && (
            <div className={classes.Error}>
              <img
                src={ImagesResource.app.graphEmptyStateIlustration}
                className={classes.GraphEmptyStateImage}
              />
              <div className={classes.Button}>
                <Button onClick={onRunRCA}>
                  {t(TransKeys.GEN_AI_BOT.KPI_GRAPH_MESSAGE.ERROR_MESSAGE.RUN_RCA_BUTTON.LABEL)}
                </Button>
              </div>
            </div>
          )}
        </div>
        {showSegmentPerformance && (
          <SegmentPerformanceSummary
            resultId={widgetData.analysisResultId}
            targetSegmentId={message.content.segmentId}
            targetSegmentClasses={message.content.segmentValues}
            className={classes.SummaryWrapper}
          />
        )}
        {showExecutiveSummary && (
          <>
            <div className={classes.SummaryWrapper}>
              <RCAExecutiveSummery
                mode={RCAMode.LOOPS_ALGO}
                resultId={widgetData.analysisResultId}
                resultStatus={AnalysisResultStatus.COMPLETED}
                teamId={null}
                setTeamId={() => {}}
                withWrapper={false}
                teams={[]}
                enableScrollToElements={false}
              />
            </div>
            {showFullAnalysisLink && fullAnalysisLink}
          </>
        )}
      </div>
      {!showExecutiveSummary && showFullAnalysisLink && fullAnalysisLink}
      {showErrorMessage && t(TransKeys.GEN_AI_BOT.KPI_GRAPH_MESSAGE.ERROR_MESSAGE.MESSAGE)}
    </div>
  );
};

import classNames from 'classnames';
import classes from './chat-empty-state.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {AIChatMessageSuggestionDTO} from '../../../../../../objects/dto/ai-chat.dto';
import {MessageSuggestions} from '../message-suggestions/message-suggestions.component';

interface Props {
  className?: string;
  onSelectSuggestion: (suggestion: AIChatMessageSuggestionDTO) => void;
}

export const ChatEmptyState = (props: Props) => {
  const {className, onSelectSuggestion} = props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.ChatEmptyState, className)}>
      <div className={classes.Title}>{t(TransKeys.GEN_AI_BOT.CHAT_EMPTY_STATE.TITLE)}</div>
      <div className={classes.SuggestionsContainer}>
        <MessageSuggestions onSelectSuggestion={onSelectSuggestion} />
      </div>
    </div>
  );
};

import {MetricValueType} from './metric.model';
import {MetricSeries} from './metric-page.model';
import {ExplainabilityLabel, KPITreeNodeExplainer} from 'ui-components';

export enum MetricTreeRelationshipType {
  CORRELATION = 'correlation',
  NO_RELATION = 'no_relation',
}

export interface NodePosition {
  x: number;
  y: number;
}

export interface MetricNodeRCAData extends KPITreeNodeExplainer {
  populationTrend: number;
  populationImpact: number;
  ctcMetric: number;
  childrenExplainability?: ExplainabilityLabel | 'insignificant';
}

export interface MetricNode {
  id: number;
  name: string;
  signalId: number;
  valueType: MetricValueType;
  higherIsBetter: boolean;
  series: MetricSeries[];
  numberOfChildren?: number;
  numberOfParents?: number;
  suggestedCount: number;
  position: NodePosition;
  formula?: string;
  rcaData?: MetricNodeRCAData;
}

export interface MetricTreeEdge {
  rootMetricId: number;
  inputMetricId: number;
  correlation: number;
  isSignificantCorrelation: boolean;
  relationshipType: MetricTreeRelationshipType;
}

export interface MetricTree {
  rootExistsInTree: boolean;
  rootMetricId?: number;
  rootMetricIdTeams?: number[];
  metrics: MetricNode[];
  edges: MetricTreeEdge[];
  hasRcaData: boolean;
}

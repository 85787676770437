import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './annotations-main.module.scss';
import {annotationType, queryFilter} from '../../../../constants/filters';
import {annotationToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {FlexVertical} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title} from '../../../shared/components/general/title/title.component';
import {
  ActionsDropdown,
  AnalysisFileIcon,
  AnnotationDuetoneIcon,
  Button,
  Chip,
  EditIcon,
  FileCirclePlusLightIcon,
  IconButton,
  MoreIcon,
  TrashIcon,
} from 'ui-components';
import moment from 'moment';
import {MainTableStructure} from '../../../shared/infrastracture/main-table-structure/main-table-structure.component';
import {getAnnotationsNetworkRequest} from '../../../../http/annotations.network-requests';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {Annotation, AnnotationType} from '../../../../objects/models/annotation.model';
import {deleteAnnotationConfirmed} from '../../../../store/annotations/annotations.actions';
import {PanelKey} from '../../../../constants/panels';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  ANNOTATION_ID_PATH_PARAM,
  AppRoutes,
} from '../../../../constants/app-routes';
import {useDispatch} from 'react-redux';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {ANNOTATION_ICONS} from '../../../../constants/ui';
import {generateLastModified} from '../../../../utils/history.utils';
import {useNavigate} from 'react-router';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types';

interface OwnProps {}

type AllProps = OwnProps;

const createListKey = () => `AnnotationsMain/ANNOTATIONS`;

export const AnnotationsMain = (props: AllProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openPrimaryPanel} = useContext(PanelsContext);
  const navigate = useNavigate();
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'timestamp',
      order: 'desc',
      itemsPerPage: 10,
      isActive: true,
    }),
    []
  );
  const filtersDef = useMemo(() => [queryFilter(), annotationType()], []);
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getAnnotationsNetworkRequest,
      onError: err => [annotationToastCreator('GET_ERROR')],
      modelKey: ModelKey.ANNOTATION,
    }),
    []
  );
  const onDelete = useCallback(
    annotation => dispatch(deleteAnnotationConfirmed(annotation.id)),
    [dispatch]
  );
  const onCreate = useCallback(
    () => openPrimaryPanel(PanelKey.ANNOTATION_FORM_PANEL, {}, PanelType.MODAL),
    [openPrimaryPanel]
  );
  const onEdit = useCallback(
    annotation =>
      openPrimaryPanel(
        PanelKey.ANNOTATION_FORM_PANEL,
        {[ANNOTATION_ID_PATH_PARAM]: annotation.id},
        PanelType.MODAL
      ),
    [openPrimaryPanel]
  );
  const onViewExperiment = useCallback(
    (annotation: Annotation) => navigate(AppRoutes.viewExperiment(annotation.relatedExperimentId)),
    [navigate]
  );
  const onCreateExperiment = useCallback(
    (annotation: Annotation) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, {
        [ANNOTATION_ID_PATH_PARAM]: annotation.id,
        [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeId.RELEASE_IMPACT,
        parameters: {
          release_date: moment(annotation.timestamp, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).format(
            TIME_FORMATS.PARAMETER_DATE_FORMAT
          ),
        },
      }),
    [openPrimaryPanel]
  );

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'timestamp',
        title: t(TransKeys.GENERAL.HEADERS.DATE),
        width: '12rem',
        sortable: true,
        render: (annotation: Annotation) =>
          moment(annotation.timestamp).format(TIME_FORMATS.READABLE_DATE),
      },
      {
        key: 'title',
        title: t(TransKeys.GENERAL.HEADERS.TITLE),
        width: '50%',
        sortable: true,
        stretch: true,
        render: (annotation: Annotation) => (
          <Title className={classes.Title} text={annotation.title} />
        ),
      },
      {
        key: 'type',
        title: t(TransKeys.GENERAL.HEADERS.TYPE),
        width: '20rem',
        sortable: true,
        render: (annotation: Annotation) => {
          return (
            <Chip
              label={t(TransKeys.ANNOTATION.TYPE[annotation.type.toUpperCase()])}
              icon={ANNOTATION_ICONS[annotation.type]}
            />
          );
        },
      },
      {
        key: 'updatedOn',
        title: t(TransKeys.GENERAL.HEADERS.UPDATED_AT),
        sortable: true,
        width: '20rem',
        render: (annotation: Annotation) => {
          const modifier = generateLastModified(annotation.history[0], true);
          if (modifier) {
            return (
              <FlexVertical spacing verticalAlignCenter>
                {modifier.user ? t(TransKeys.GENERAL.LABELS.MODIFIED_BY, modifier) : modifier.date}
              </FlexVertical>
            );
          }
          return (
            <FlexVertical spacing verticalAlignCenter>
              {moment.utc(annotation.updatedOn).local().fromNow()}
            </FlexVertical>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        width: '12rem',
        align: 'right',
        render: (annotation: Annotation) => (
          <div className={classes.Actions}>
            {annotation.type === AnnotationType.RELEASE && (
              <IconButton
                onClick={
                  annotation.relatedExperimentId
                    ? () => onViewExperiment(annotation)
                    : () => onCreateExperiment(annotation)
                }
                icon={annotation.relatedExperimentId ? AnalysisFileIcon : FileCirclePlusLightIcon}
                tooltipText={
                  annotation.relatedExperimentId
                    ? t(TransKeys.ANNOTATIONS.ACTIONS.VIEW_RELEASE_IMPACT)
                    : t(TransKeys.ANNOTATIONS.ACTIONS.RUN_RELEASE_IMPACT)
                }
                className={annotation.relatedExperimentId ? undefined : classes.NoAnalysis}
                iconClassName={annotation.relatedExperimentId ? undefined : classes.NoAnalysisIcon}
              />
            )}
            <ActionsDropdown
              className={classes.Button}
              actions={[
                {
                  key: 'edit',
                  title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                  onClick: () => onEdit(annotation),
                  icon: EditIcon,
                },
                {
                  key: 'delete',
                  title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                  onClick: () => onDelete(annotation),
                  icon: TrashIcon,
                },
              ]}
              label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
              icon={MoreIcon}
              iconDropdown
            />
          </div>
        ),
      },
    ],
    [onDelete, onEdit, t, onCreateExperiment, onViewExperiment]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.ANNOTATION) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
    };
  }, [config, dispatch]);

  return (
    <MainTableStructure
      className={classes.AnnotationsMain}
      title={t(TransKeys.ANNOTATIONS.HEADER.TITLE)}
      icon={AnnotationDuetoneIcon}
      columns={columns}
      config={config}
      defaultFilters={defaultFilters}
      filtersDef={filtersDef}
      headerRenderRight={
        <Button onClick={onCreate}>{t(TransKeys.GENERAL.ACTIONS.CREATE_ANNOTATION)}</Button>
      }
      emptyStateTranslationPath={TransKeys.ANNOTATIONS.EMPTY_STATE}
    />
  );
};

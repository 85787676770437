import {NotSignificantChangeKPIAndUsers, InterestingXoXSentence} from '../rca-insights.model.ts';
import {
  ChangeInValueSentencePart,
  SegmentAnalyzedPeriodMeasuredValueSentenceParts,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends InterestingXoXSentence {
  formattedSentenceParts: NotSignificantChangeKPIAndUsers;
}

type AllProps = OwnProps;

const NotSignificantChangeKPIAndUsersFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {changeInShareOfEntitiesDescription, entity} = formattedSentenceParts;
  const {changeInShareOfEntities} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentAnalyzedPeriodMeasuredValueSentenceParts
        segmentViewData={sentenceParts.segmentViewData}
        conjugatedPerformance={formattedSentenceParts.presentTensePerformance}
        changeInValue={sentenceParts.changeInValue}
        higherIsBetter={sentenceParts.higherIsBetter}
        segmentValue={sentenceParts.value}
        segmentExpectedValue={sentenceParts.expectedValue}
      />
      <SentencePart text={`and the share of ${entity} ${changeInShareOfEntitiesDescription} by`} />
      <ChangeInValueSentencePart changeInValue={changeInShareOfEntities} higherIsBetter={true} />
    </SentenceContainer>
  );
};

export {NotSignificantChangeKPIAndUsersFormatter};

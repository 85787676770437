import {NRC} from './index';

import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {
  FunnelAnalysisConversionFollowUpActionsDTO,
  GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  RCAFromLineChartFollowUpActionsDTO,
  RCAFromSegmentationExplainerFollowUpActionsDTO,
} from '../objects/dto/follow-up-actions.dto';

const followUpActionsNetworkRequestFactory = (path: string, payload: any) => ({
  method: RequestType.POST,
  relativeUrl: `/app/follow-ups/${path}`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const createRCAFromLineChartFollowUpsNetworkRequest: NRC<
  RCAFromLineChartFollowUpActionsDTO,
  void
> = payload => followUpActionsNetworkRequestFactory('rca-from-line-chart', payload);

export const createFunnelAnalysisConversionFollowUpsNetworkRequest: NRC<
  FunnelAnalysisConversionFollowUpActionsDTO,
  void
> = payload => followUpActionsNetworkRequestFactory('funnel-analysis-conversion', payload);

export const createGoalDriversAnalysisImproveFeatureAdoptionFollowUpsNetworkRequest: NRC<
  GoalDriversAnalysisImproveFeatureAdoptionFollowUpActionsDTO,
  void
> = payload =>
  followUpActionsNetworkRequestFactory('goal-drivers-improve-feature-adoption', payload);

export const createRetentionAnalysisInvestigateBucketFollowUpsNetworkRequest: NRC<
  RetentionAnalysisInvestigateBucketFollowUpActionsDTO,
  void
> = payload => followUpActionsNetworkRequestFactory('retention-bucket-investigation', payload);

export const postSegmentationExplainersFollowUpNetworkRequest: NRC<
  RCAFromSegmentationExplainerFollowUpActionsDTO,
  void
> = payload => followUpActionsNetworkRequestFactory('run-rca-segmentation-explainers', payload);

export const getSegmentationExplainersFollowUpAnalaysisDataNetworkRequest: NRC<
  RCAFromSegmentationExplainerFollowUpActionsDTO,
  void
> = payload =>
  followUpActionsNetworkRequestFactory('rca-segmentation-explainers-parameters', payload);

import {
  ChartUserIcon,
  LabIcon,
  NetworkWiredRegularIcon,
  UsersIcon,
  UserCircleLightIcon,
  ChartSineLightIcon,
} from 'ui-components';
import classes from './rca-explainers-icon.module.scss';
import * as React from 'react';

export enum RCAExplainersIconVariant {
  SEGMENT_EXPLAINER = 'SEGMENT_EXPLAINER',
  POPULATION_EXPLAINER = 'POPULATION_EXPLAINER',
  EXPERIMENT_EXPLAINER = 'EXPERIMENT_EXPLAINER',
  INPUT_KPI_EXPLAINER = 'INPUT_KPI_EXPLAINER',
  ENTITY_EXPLAINER = 'ENTITY_EXPLAINER',
  SEASONALITY_EXPLAINER = 'SEASONALITY_EXPLAINER',
}

const VARIANTS_MAPPING = {
  [RCAExplainersIconVariant.SEGMENT_EXPLAINER]: {
    icon: UsersIcon,
    text: 'Segment',
  },
  [RCAExplainersIconVariant.ENTITY_EXPLAINER]: {
    icon: UserCircleLightIcon,
    text: 'Entity',
  },
  [RCAExplainersIconVariant.POPULATION_EXPLAINER]: {
    icon: ChartUserIcon,
    text: 'Population Size',
  },
  [RCAExplainersIconVariant.EXPERIMENT_EXPLAINER]: {
    icon: LabIcon,
    text: 'Experiment',
  },
  [RCAExplainersIconVariant.INPUT_KPI_EXPLAINER]: {
    icon: NetworkWiredRegularIcon,
    text: 'Input KPI',
  },
  [RCAExplainersIconVariant.SEASONALITY_EXPLAINER]: {
    icon: ChartSineLightIcon,
    text: 'Seasonality',
  },
};

interface OwnProps {
  variant: RCAExplainersIconVariant;
  text?: string;
}

type AllProps = OwnProps;

export const RCAExplainersIcon: React.FC<AllProps> = (props: AllProps) => {
  const {variant, text: textFromProps} = props;
  const {icon: Icon, text} = VARIANTS_MAPPING[variant];
  return (
    <div className={classes.RCAExplainersIconContainer}>
      <Icon className={classes.Icon} />
      <span className={classes.Text}>{textFromProps ? textFromProps : text}</span>
    </div>
  );
};

import {ReactNode, useCallback, useContext, useEffect, useMemo} from 'react';
import {getExperimentsNetworkRequest} from '../../../../http/experiments.network-requests';
import {experimentToastCreator} from '../../../../store/toasts.actions';
import {ModelKey} from '../../../../constants/model-key';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import TransKeys from 'translations';
import {Experiment} from '../../../../objects/models/experiment.model';
import {FlexVertical} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {
  AnalysisFileIcon,
  InteractionContext,
  InteractionType,
  ModelType,
  TextButton,
} from 'ui-components';
import {exists} from 'front-core';
import {toLocalTime, withStopPropagation} from '../../../../utils/general.utils';
import {useTranslation} from 'react-i18next';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {useDispatch} from 'react-redux';
import {AnalysesListPlaceholder} from '../../../analyses/pages/analyses-main/components/general/analyses-list-placeholder/analyses-list-placeholder.component';
import {sharedClasses} from '../../../shared';
import {AppRoutes} from '../../../../constants/app-routes';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import classes from './recent-experiments.module.scss';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types';
import {BetterNavLink} from '../../../shared/core/override/better-nav-link.component.tsx';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_FILTERS_FOR_RECENT = {
  itemsPerPage: 5,
  orderBy: 'startedOn',
  order: 'desc',
};

const LIST_KEY = 'recent-experiments';

export const RecentExperiments = (props: AllProps) => {
  const {className} = props;
  const {t} = useTranslation();
  const {postMessage} = useContext(InteractionContext);
  const dispatch = useDispatch();

  const config = useMemo(
    () => ({
      listKey: LIST_KEY,
      actionKey: LIST_KEY,
      request: getExperimentsNetworkRequest,
      onError: err => [experimentToastCreator('GET_ERROR')],
      modelKey: ModelKey.EXPERIMENT,
    }),
    []
  );
  const onMainKpiClick = useCallback(
    signalId =>
      postMessage({
        type: InteractionType.REFERENCE,
        payload: {
          modelId: signalId,
          modelType: ModelType.SIGNAL,
        },
      }),
    [postMessage]
  );

  const {isLoading, listsData} = useRemoteList({
    config,
    defaultFilters: DEFAULT_FILTERS_FOR_RECENT,
    syncQueryFilters: true,
  });

  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '30rem',
        stretch: true,
        sticky: 'left',
        render: (exp: Experiment) => (
          <FlexVertical spacing fullWidth verticalAlignCenter>
            <Title text={exp.name} caps={false} />
          </FlexVertical>
        ),
      },
      {
        key: 'analysisName',
        title: t(TransKeys.GENERAL.LABELS.TYPE),
        width: '20rem',
        render: (exp: Experiment) => {
          if (!exp.analysis) {
            return null;
          }
          return (
            <TitleWithIcon
              text={exp.analysis?.analysisName}
              icon={AnalysisFileIcon}
              size={'medium'}
            />
          );
        },
      },
      {
        key: 'mainKpi',
        title: t(TransKeys.EXPERIMENTS.TABLE.HEADERS.MAIN_KPI),
        width: '20rem',
        render: (exp: Experiment) => {
          if (!exists(exp?.mainKpi)) {
            return null;
          }
          return (
            <TextButton onClick={withStopPropagation(() => onMainKpiClick(exp.mainKpi.id))}>
              {exp.mainKpi.name}
            </TextButton>
          );
        },
      },
      {
        key: 'startDate',
        sortable: true,
        title: t(TransKeys.EXPERIMENTS.TABLE.HEADERS.START_DATE),
        width: '16rem',
        render: (exp: Experiment) => {
          const text = `${toLocalTime(exp.startedOn, TIME_FORMATS.READABLE_DATE)}`;
          return <span>{text}</span>;
        },
      },
    ],
    [t, onMainKpiClick]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.EXPERIMENT) {
        dispatch(replaceList(config.listKey, {page: 1}, 'append'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, config]);

  const contentWrapper = useCallback(
    (experiment: Experiment, children: ReactNode) => (
      <BetterNavLink
        key={experiment.id}
        className={sharedClasses.UnstyledLink}
        to={AppRoutes.viewExperiment(experiment.id)}
      >
        {children}
      </BetterNavLink>
    ),
    []
  );

  if ((isLoading && !exists(listsData?.meta?.total)) || !exists(listsData)) {
    return <AnalysesListPlaceholder className={className} />;
  }

  return (
    <GridTable
      data={listsData.list}
      dataKey={'id'}
      columns={columns}
      total={listsData.meta?.total}
      page={listsData.meta?.page - 1}
      perPage={listsData.meta?.numPerPage}
      isLoading={isLoading}
      contentWrapper={contentWrapper}
      emptyStateRow={
        <div className={classes.EmptyStateRow}>{t(TransKeys.EXPERIMENTS.EMPTY_STATE.TITLE)}</div>
      }
    />
  );
};

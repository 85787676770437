import {action, OnSuccessActionHook} from 'front-core';
import {TeamDTO} from '../../objects/dto/team.dto';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';
import {showConfirmationMessage} from '../interface/interface.actions';

export enum TeamsSettingsActionType {
  CREATE_TEAM = '@@TEAM_SETTINGS/CREATE_TEAM',
  UPDATE_TEAM = '@@TEAM_SETTINGS/EDIT_TEAM',
  DELETE_TEAM = '@@TEAM_SETTINGS/DELETE_TEAM',
}

export const createTeam = (payload: Omit<TeamDTO, 'id'>, onSuccess: OnSuccessActionHook) =>
  action(TeamsSettingsActionType.CREATE_TEAM, payload, {onSuccess});

export const updateTeam = (payload: TeamDTO, onSuccess: OnSuccessActionHook) =>
  action(TeamsSettingsActionType.UPDATE_TEAM, payload, {onSuccess});

export const deleteTeam = (payload: Pick<TeamDTO, 'id'>) =>
  action(TeamsSettingsActionType.DELETE_TEAM, payload);

export const deleteTeamConfirmed = (id: number, teamName: string) => {
  return showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_TEAM.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_TEAM.CONTENT, {team: teamName}),
      rejectBtn: i18n.t(TransKeys.GENERAL.ACTIONS.CANCEL),
      approveBtn: i18n.t(TransKeys.GENERAL.ACTIONS.REMOVE),
    },
    () => [deleteTeam({id})]
  );
};

import classNames from 'classnames';
import {HistoryItem} from '../../../../../objects/models/historify-model.model';
import classes from './modifiers.module.scss';
import {LabelWrapper, AvatarGroup} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {useMemo} from 'react';
import {generateLastModified} from '../../../../../utils/history.utils';
import {exists} from 'front-core';
import {some} from 'lodash';

interface OwnProps {
  history: HistoryItem[];
  className?: string;
}

type AllProps = OwnProps;

export const Modifiers = (props: AllProps) => {
  const {history, className} = props;
  const {t} = useTranslation();
  const historyItems = useMemo(() => history.map(h => generateLastModified(h)), [history]);
  const hasModifiedUsers = useMemo(() => some(historyItems, h => exists(h.user)), [historyItems]);

  const usersAvatars = useMemo(
    () =>
      historyItems
        .filter(h => exists(h.user))
        .map(h => ({
          name: h.user,
          helperText: t(TransKeys.GENERAL.LABELS.MODIFIED_BY, h),
        })),
    [historyItems, t]
  );

  const lastModifiedBottomLabel = useMemo(() => {
    const lastModifiedTxKey = historyItems[0].user ? 'LAST_MODIFIED_BY' : 'LAST_MODIFIED';
    return t(TransKeys.GENERAL.LABELS[lastModifiedTxKey], historyItems[0]);
  }, [historyItems, t]);

  if (!history || history.length === 0) {
    return null;
  }

  return (
    <div className={classNames(classes.Modifiers, className)}>
      {hasModifiedUsers && (
        <LabelWrapper label={t(TransKeys.GENERAL.LABELS.MODIFIERS)}>
          <AvatarGroup
            avatarsData={usersAvatars}
            variant={'primary'}
            size={'medium'}
            className={classes.ModifiersList}
          />
        </LabelWrapper>
      )}
      <div>{lastModifiedBottomLabel}</div>
    </div>
  );
};

export enum FeatureFlag {
  EXPOSE_ARTIFACTS = 'expose-artifacts',
  HABIT_MOMENT_FILTER_USERS_WHO_DID_NOT_DO_TREATMENT = 'habit_moment_filter_users_who_did_not_do_treatment',
  AB_TEST_KPI_BOUND = 'ab-test-bound-kpi-parameter',
  PUSHER_NOTIFICATIONS = 'pusher-notifications',
  ADD_SEGMENT_FILTER_TO_KPI = 'add-segment-filter-to-kpi',
  FAVORITE_SEGMENTS = 'favorite-segments',
  KPI_PAGE = 'kpi-page',
  SEND_KPI_INSIGHTS = 'send-kpi-insights',
  SUBSCRIPTION_DAILY_ALERT = 'subscription-daily-alert',
  FUNNEL_HOMEPAGE_SUBSCRIPTION = 'funnel-homepage-subscription',
  FUNNEL_RCA_EXEC_SUM = 'funnel-rca-exec-sum',
  BEHAVIORAL_SEGMENTS = 'behavioral-segments',
  SEGMENT_BUCKETING = 'segment-bucketing',
  BEHAVIORAL_MILESTONE = 'behavioral-milestone',
  HEALTH_MONITOR = 'health-monitor',
  HOMEPAGE_SUBSCRIPTION = 'homepage-subscription',
  EXPERIMENT_AUTOMATIONS = 'experiment-automations',
  HOMEPAGE_FUNNEL = 'homepage-funnel',
  GEN_AI_CHAT = 'gen-ai-chat',
  WORKFLOWS = 'workflows',
  HOMEPAGE_PREDICTIONS = 'homepage-predictions',
  MODEL_PROPERTY_EVENT = 'model-property-event',
  USER_MANAGEMENT = 'user-management',
  WORKFLOWS_EXPLORE_USERS = 'workflows-explore-users',
  METRIC_ADVANCED_SETTINGS = 'metric-advanced-settings',
  METRIC_FORMULA = 'metric-formula',
  WEEKLY_REPORT_PAGE = 'weekly-report-page',
  RCA_SEASONALITY_EXPLAINER = 'rca-seasonality-explainer',
  RELEASE_IMPACT_EXECUTIVE_SUMMARY = 'release-impact-executive-summary',
  GOAL_DRIVERS_FILTER_BY_REF_DATE = 'goal-drivers-filter-by-ref-date',
  SUBSCRIPTION_SEGMENT_BREAKDOWN = 'subscription-segment-breakdown',
  FUNNEL_SEGMENTATION_SIMULATION_CONTROLS = 'funnel-segmentation-simulation-controls',
  EXPLORE_MODULE = 'explore-module',
}

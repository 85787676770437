import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './rca-segmentation-composite-modal.module.scss';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {
  MetricSampleData,
  RCASegmentationFigureOptions,
  SPECIAL_SMART_TABLE_DATA_KEYS,
} from '../../../../../../types';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {useTopExplainersSmartTable} from '../../hooks/use-top-explainers-smart-table.hook';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import TransKeys from 'translations';
import {useTranslation} from '../../../../../../../../../core/translations/use-translation';
import {keyBy} from 'lodash';
import {
  ChartMode,
  useSegmentChartOvertimeChartData,
} from '../segment-explainers-modal/use-segment-chart.hook';
import pluralize from 'pluralize';
import {SwitchActions} from '../../../../../../../../simple/controls/switch-actions/switch-actions.component';
import {
  UsersIcon,
  XmarkLightIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import {RunAnalysisContainer} from '../run-analysis-container/run-analysis-container.component';
import {SegmentExplainersTableRCASegmentFollowUpData} from '../segment-explainers-table/components/explainers-table/components/explainers-table-item/explainers-table-item.component';
import {GlobalDocumentDataContext} from '../../../../../../contexts/global-document-data/global-document-data.context';
type Composite = {
  segments: UnifiedRCASegmentationItem[];
} & UnifiedRCASegmentationItem;

interface OwnProps {
  granularity: string;
  metricSamples: MetricSampleData[];
  composite: Composite;
  options: RCASegmentationFigureOptions;
  populationTrend: number;
  viewMode: RCASegmentationViewMode;
  goalValue: number;
  version: number;
  showOvertimeChart: boolean;
  onClose: () => void;
  className?: string;
  onViewFollowUpRCAForSegment: (analysisId: number, analysisResultId: number) => void;
  onRunFollowUpRCAForSegment: (data: SegmentExplainersTableRCASegmentFollowUpData) => void;
}

type AllProps = OwnProps;

const transPrefix = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.COMPOSITE_OVERTIME_MODAL;

export const RCASegmentationCompositeModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    metricSamples,
    granularity,
    composite,
    populationTrend,
    goalValue,
    onClose,
    version,
    options,
    viewMode,
    showOvertimeChart,
    onViewFollowUpRCAForSegment,
    onRunFollowUpRCAForSegment,
  } = props;

  const {t} = useTranslation();
  const {isFollowUp} = useContext(GlobalDocumentDataContext);
  const [chartMode, setChartMode] = useState(ChartMode.KPI_VALUE);
  const [selectedSegmentKeys, setSelectedSegmentKeys] = useState<string[]>([composite.key]);

  const segments = useMemo(() => {
    const res = composite.segments.map(s => ({
      ...s,
      name: `${s.segmentGroupName} → ${s.segmentName}`,
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.SegmentRow,
    }));
    res.push({
      ...composite,
      name: t(transPrefix.COMPOSITE_ITEM_TEXT), // hack to render name
      segmentName: t(transPrefix.COMPOSITE_ITEM_TEXT), // hack to render name
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.CompositeRow,
      order: -1,
    } as any);
    return res;
  }, [composite, t]);

  const segmentsByKey = useMemo(() => keyBy(segments, 'key'), [segments]);
  const selectedSegmentKeySet = useMemo(() => new Set(selectedSegmentKeys), [selectedSegmentKeys]);
  const onSelectSegment = useCallback(
    (key: string) => {
      if (selectedSegmentKeySet.has(key)) {
        selectedSegmentKeySet.delete(key);
      } else {
        selectedSegmentKeySet.add(key);
      }
      setSelectedSegmentKeys(Array.from(selectedSegmentKeySet));
    },
    [selectedSegmentKeySet, setSelectedSegmentKeys]
  );

  const chartData = useSegmentChartOvertimeChartData({
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    showOvertimeChart,
    granularity,
    showAllPopulation: true,
  });

  const chartOptions = useMemo(
    () => [
      {
        label: 'KPI Value',
        onClick: () => setChartMode(ChartMode.KPI_VALUE),
        isActive: chartMode === ChartMode.KPI_VALUE,
      },
      {
        label: `# ${pluralize(options.entity)}`,
        onClick: () => setChartMode(ChartMode.ENTITIES_COUNT),
        isActive: chartMode === ChartMode.ENTITIES_COUNT,
      },
    ],
    [chartMode, options.entity]
  );

  const tableFigure = useTopExplainersSmartTable({
    version,
    onSelectSegment,
    selectedSegmentKeys,
    groupName: true,
    options,
    goalValue,
    segments,
    populationTrend,
    viewMode,
    isCompositeMode: true,
    key: 'segment-composite-modal',
  });
  const segmentFilters = useMemo(() => {
    return composite.segments.map(segment => ({
      signalId: segment.signalId,
      classes: [segment.segmentName],
    }));
  }, [composite.segments]);

  const segmentLabel = useMemo(
    () =>
      composite.segments
        .map(segment => `${segment.segmentGroupName} > ${segment.segmentName}`)
        .join(' AND '),
    [composite.segments]
  );

  return (
    <ModalWrapper isOpen={composite} onClose={onClose} width={'100rem'} height={'80rem'}>
      <div className={classNames(classes.RCACompositeModal)}>
        <XmarkLightIcon onClick={onClose} className={classes.CloseIcon} />
        <div className={classes.Header}>
          <div className={classes.HeaderTitle}>
            {t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.TITLE, {
              kpiName: options.kpiName,
              segmentLabel,
            })}
          </div>
        </div>
        {!isFollowUp && (
          <RunAnalysisContainer
            segmentFilters={segmentFilters}
            segmentLabel={segmentLabel}
            kpiName={options.kpiName}
            onViewFollowUpRCAForSegment={onViewFollowUpRCAForSegment}
            onRunFollowUpRCAForSegment={onRunFollowUpRCAForSegment}
          />
        )}
        {showOvertimeChart && (
          <div className={classes.ChartContainer}>
            <div className={classes.ChartTitle}>
              {t(
                TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.ANALYZE_SEGMENT_MODAL.CHART_TITLE
              )}
            </div>
            <div
              className={classNames(
                classes.ChartWrapper,
                options.hasDenominator && classes.HasOptions
              )}
            >
              {options.hasDenominator && (
                <div className={classes.Options}>
                  <SwitchActions actions={chartOptions} showActionsLabel />
                </div>
              )}
              <div className={classes.Chart}>
                {chartData && <LineChart {...chartData} key={selectedSegmentKeys.join('.')} />}
                {chartData === null && (
                  <div className={classes.EmptyState}>
                    <UsersIcon className={classes.EmptyStateIcon} />
                    {t(
                      TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL
                        .EMPTY_STATE
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={classes.Table}>
          <ChildRenderer children_={tableFigure} />
        </div>
      </div>
    </ModalWrapper>
  );
};

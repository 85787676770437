import {useCallback, useContext, useEffect, useMemo} from 'react';
import {sharedClasses} from '../../../../../shared';
import {AREA_COLOR, AREA_ICONS} from '../../../../../../constants/ui';
import {Opportunity} from '../../../../../../objects/models/opportunity.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {groupBy, values} from 'lodash';
import {getOpportunitiesFeedNetworkRequest} from '../../../../../../http/opportunities.network-requests';
import {OpportunityRow} from '../../../../components/opportunity-row/opportunity-row.component';
import {EmptyState} from '../../../../../shared/components/general/override';
import classes from './opportunity-feed-tab.module.scss';
import {ListLayout} from '../../../../../shared/components/layout/list-layout/list-layout.component';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../../../store/selected/selected.actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../../../store/store.selectors';
import {ModelKey} from '../../../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../../store/core/core.actions';
import {ProductArea} from '../../../../../../objects/system/product-area.enum';
import {
  useIsDismissed,
  useIsExplored,
  useIsSnoozed,
} from '../../../../../../core/hooks/user-reactions.hook';
import {ModelDiscriminatorType} from '../../../../../../objects/models/user-reaction.model';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../constants/panels';
import {ShareResourceType} from '../../../../../../objects/models/share.model';
import {AppRoutes, METRIC_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {PanelType} from '../../../../../../objects/system/panel-type.enum';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../../../core/hooks/amplitude.hook';
import {useNavigate} from 'react-router';
import {AppSources} from '../../../../../../constants/app-sources';
import PageLayout from '../../../../../shared/components/layout/page-layout/index';
import {BetterNavLink} from '../../../../../shared/core/override/better-nav-link.component.tsx';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_OPPORTUNITIES_TAB_FEED = `OPPORTUNITIES_MAIN/TAB_FEED`;
export const OpportunityFeedTab = (props: AllProps) => {
  // Hooks
  const {openPrimaryPanel, openSecondaryPanel} = useContext(PanelsContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const opportunities = useSelector(state =>
    getSingleSelectedSelector(SELECTED_OPPORTUNITIES_TAB_FEED, state)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_OPPORTUNITIES_TAB_FEED)(state)
  );
  const notify = useAmplitude();
  const onDismiss = useIsDismissed(ModelDiscriminatorType.OPPORTUNITY);
  const onSnooze = useIsSnoozed(ModelDiscriminatorType.OPPORTUNITY);
  const onExplore = useIsExplored(ModelDiscriminatorType.OPPORTUNITY);
  // Callbacks
  const onShare = useCallback(
    (oppo: Opportunity) => {
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: oppo.id,
          type: ShareResourceType.OPPORTUNITY,
          copyLink: AppRoutes.asUrl(AppRoutes.viewOpportunity(oppo.id)),
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
        type: ShareResourceType.OPPORTUNITY,
        id: oppo.id,
      });
    },
    [openPrimaryPanel, notify]
  );
  const viewOpportunity = useCallback(
    (oppo: Opportunity) => navigate(AppRoutes.viewOpportunity(oppo.id)),
    [navigate]
  );
  // Memos
  const feedData = useMemo(() => {
    const groupedOpportunities = groupBy(opportunities, (oppo: Opportunity) => oppo.areaInProduct);
    return values(ProductArea).reduce((acc, pa) => {
      const data = groupedOpportunities[pa];
      if (!data) {
        return acc;
      }
      return [
        ...acc,
        {
          meta: {
            key: pa,
            total: data.length,
            page: 1,
            numPerPage: data.length,
            title: t(TransKeys.GENERAL.ENUMS.PRODUCT_AREA[pa.toUpperCase()]),
            icon: AREA_ICONS[pa],
            color: AREA_COLOR[pa],
          },
          list: data,
        },
      ];
    }, []);
  }, [opportunities, t]);
  const showEmptyState = opportunities?.length === 0;
  const onViewMetric = useCallback(
    (opportunity: Opportunity) =>
      openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
        [METRIC_ID_PATH_PARAM]: opportunity.metricId,
      }),
    [openSecondaryPanel]
  );
  // Effects
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_OPPORTUNITIES_TAB_FEED,
        actionKey: SELECTED_OPPORTUNITIES_TAB_FEED,
        request: getOpportunitiesFeedNetworkRequest,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_OPPORTUNITIES_TAB_FEED));
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSelected(SELECTED_OPPORTUNITIES_TAB_FEED));
  }, [dispatch]);
  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (modelKey === ModelKey.OPPORTUNITY) {
        dispatch(getSelected(SELECTED_OPPORTUNITIES_TAB_FEED));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_DELETED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_DELETED, listener));
    };
  }, [dispatch]);

  const renderOpportunityRow = item => (
    <BetterNavLink to={AppRoutes.viewOpportunity(item.id)} className={sharedClasses.UnstyledLink}>
      <OpportunityRow
        key={item.id}
        opportunity={item}
        onClick={_ => viewOpportunity(item)}
        onExplore={_ => onExplore(item)}
        onDismiss={() => onDismiss(item)}
        onSnooze={() => onSnooze(item)}
        onShare={() => onShare(item)}
        onViewMetric={() => onViewMetric(item)}
        className={sharedClasses.VerticalListItem}
        appSource={AppSources.OPPORTUNITIES_MAIN__VIEW_PARAMETERS}
      />
    </BetterNavLink>
  );

  const renderOpportunitiesLists = () =>
    feedData.map(feedList => (
      <ListLayout
        key={feedList.meta.key}
        title={feedList.meta.title}
        color={feedList.meta.color}
        icon={feedList.meta.icon}
        meta={feedList.meta}
        list={feedList.list}
        collapsible={true}
        renderItem={renderOpportunityRow}
        className={classes.List}
      />
    ));

  return (
    <PageLayout.Body className={classes.Body} isLoading={isLoading}>
      {showEmptyState && (
        <div className={classes.EmptyStateWrapper}>
          <EmptyState
            title={t(TransKeys.OPPORTUNITIES.EMPTY_STATE.TITLE)}
            subTitle={t(TransKeys.OPPORTUNITIES.EMPTY_STATE.SUB_TITLE)}
            text={t(TransKeys.OPPORTUNITIES.EMPTY_STATE.TEXT)}
            buttonText={t(TransKeys.OPPORTUNITIES.EMPTY_STATE.BUTTON_TEXT)}
          />
        </div>
      )}
      <div className={classes.ListWrapper}>{!showEmptyState && renderOpportunitiesLists()}</div>
    </PageLayout.Body>
  );
};

import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './experiment-variants-table.module.scss';
import {
  DocumentCommand,
  ReleaseImpactSummaryValidationConfidenceLevel,
  ReleaseImpactSummaryValidations,
} from '../../../../types';
import {FlexibleTable, FlexibleTableClasses} from '../flexible-table/flexible-table.component';
import {Sorting, exists, getNextSort, number2k, sortData, withStopPropagation} from 'front-core';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {
  ArrowRightLightIcon,
  StarIcon,
  TrophyStarSolidIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {getPercentageValueFormatter} from '../../../../../../../utils/formatters';
import {TooltipIfOverflow} from '../../../../../../simple/generic/tooltips/tooltips.component';
import {Chip, ChipVariant} from '../../../../../../simple/data-display/chip/chip.component';
import {Tooltip} from '@material-ui/core';
import _ from 'lodash';
import {ExperimentContext} from '../../../../contexts/experiment.context';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {IconButton} from '../../../../../../simple/controls/icon-button/icon-button.component';
import {HoverHelperTip} from '../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {ValidationsTableCell} from './components/validations-table-cell/validations-table-cell.component';

const DEFAULT_SORT: Sorting = {
  orderBy: null,
  order: 'desc',
};

const MISSING_VALUE_PLACEHOLDER = '-';

interface OwnProps {
  data: ExperimentSummaryVariantItem[];
  validations?: Record<number, ReleaseImpactSummaryValidations>;
  transKeysPrefix: any;
  transKeysModifier: 'VS_CONTROL' | 'VS_ALL' | 'VS_NAIVE' | 'VS_SYNTHETIC';
  showExpectedLoss?: boolean;
  showActions?: boolean;
  className?: string;
}

export interface ExperimentSummaryVariantItem {
  signalId: number;
  variantKey: string;
  goalValue: number;
  goalValueUpper: number;
  goalValueLower: number;
  rank?: number;
  isWinner?: boolean;
  probability?: number;
  improvement?: number;
  expectedLoss?: number;
  groupSize: number;
}

const TableCell = ({key, isWinner, children, tooltip = ''}) => (
  <div key={key} className={classNames(classes.Line, isWinner && classes.WinnerVariant)}>
    {children === null ? (
      <span className={classes.Secondary}>{MISSING_VALUE_PLACEHOLDER}</span>
    ) : (
      <Tooltip placement={'top'} title={tooltip || ''}>
        <span className={classes.TableCell}>{children}</span>
      </Tooltip>
    )}
  </div>
);

type AllProps = OwnProps;

export const ExperimentVariantsTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    data,
    validations,
    transKeysPrefix,
    transKeysModifier,
    showActions: showActionsFromProps,
    showExpectedLoss = true,
  } = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick, emitEvent} = useContext(DocumentCommandEmitterContext);
  const {metrics, variants} = useContext(ExperimentContext);
  const [sorting, setSorting] = useState<Sorting>(DEFAULT_SORT);

  const onMetricDeepDive = useCallback(
    (command: DocumentCommand<any>) => emitEvent(command),
    [emitEvent]
  );
  const onSort = useCallback(
    (columnKey: string) => setSorting(getNextSort(columnKey, sorting)),
    [sorting, setSorting]
  );

  const tableData = useMemo(
    () =>
      _(data)
        .groupBy(item => item.signalId)
        .sortBy(group => data.indexOf(group[0]))
        .value()
        .map(g => {
          const metric = metrics[g[0].signalId];
          return g.map(item => ({
            ...item,
            valueFormatter: getPercentageValueFormatter(metric.isPercentageValue),
          }));
        })
        .map(g => sortData(g, sorting)),
    [data, metrics, sorting]
  );

  const generateColumnTexts = useCallback(
    (columnName: string) => {
      const modifiedColumnName = transKeysModifier
        ? `${columnName}_${transKeysModifier}`
        : columnName;

      return {
        key: columnName,
        title:
          t(transKeysPrefix.TABLE.HEADERS[modifiedColumnName]?.LABEL) ||
          t(transKeysPrefix.TABLE.HEADERS[columnName]?.LABEL),
        helperText:
          t(transKeysPrefix.TABLE.HEADERS[modifiedColumnName]?.HELPER_TEXT) ||
          t(transKeysPrefix.TABLE.HEADERS[columnName]?.HELPER_TEXT),
        subTitle:
          t(transKeysPrefix.TABLE.HEADERS[modifiedColumnName]?.SUBTITLE) ||
          t(transKeysPrefix.TABLE.HEADERS[columnName]?.SUBTITLE),
      };
    },
    [t, transKeysPrefix, transKeysModifier]
  );

  const actionsColumnTexts = useMemo(() => generateColumnTexts('ACTIONS'), [generateColumnTexts]);
  const showActions = useMemo(() => {
    if (showActionsFromProps === false) {
      return false;
    }
    const hasMetricActions = Object.values(metrics).some(m => !!m.command);
    const columnHelperText = !!actionsColumnTexts?.helperText;
    return hasMetricActions && columnHelperText;
  }, [metrics, actionsColumnTexts, showActionsFromProps]);

  const columns = useMemo(
    () => [
      {
        ...generateColumnTexts('METRIC'),
        weight: 1,
        render: group => {
          const metric = metrics[group[0].signalId];
          const manyVariants = group.length > 2;
          return (
            <div className={classes.GroupName}>
              {metric.isPrimary && (
                <Chip
                  size={'xsmall'}
                  icon={StarIcon}
                  label={t(transKeysPrefix.PRIMARY_CHIP_LABEL)}
                  variant={ChipVariant.PRIMARY_KPI}
                  className={classNames(classes.PrimaryChip, manyVariants && classes.AbsPosition)}
                />
              )}
              <div className={classes.NameWrapper}>
                <div
                  onClick={
                    metric.signalId
                      ? withStopPropagation(() => onSignalClick(metric.signalId))
                      : undefined
                  }
                  className={classNames(classes.Name, metric.signalId && classes.Clickable)}
                >
                  {metric.name}
                </div>
                {metric.helperText && <HoverHelperTip title={metric.helperText} small />}
              </div>
            </div>
          );
        },
      },
      {
        ...generateColumnTexts('RANK'),
        width: '4rem',
        render: group => (
          <div className={classes.Column}>
            {group.map((item: ExperimentSummaryVariantItem) => (
              <TableCell
                key={item.variantKey}
                isWinner={item.isWinner}
                tooltip={item.isWinner && 'Winner variant'}
              >
                <span className={classes.Rank}>
                  {item.isWinner && <TrophyStarSolidIcon className={classes.WinnerIcon} />}
                </span>
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('VARIANT'),
        weight: 1,
        render: group => (
          <div className={classes.Column}>
            {group.map((item: ExperimentSummaryVariantItem) => {
              const variant = variants[item.variantKey];
              const helperText = t(
                transKeysPrefix.VARIANTS?.[variant.key.toUpperCase()]?.HELPER_TEXT
              );
              return (
                <TableCell key={item.variantKey} isWinner={item.isWinner}>
                  <TooltipIfOverflow title={variant.name}>
                    <div className={classes.Variant} style={{backgroundColor: variant.color}}>
                      {variant.name}
                    </div>
                  </TooltipIfOverflow>
                  {helperText && <HoverHelperTip title={helperText} small />}
                </TableCell>
              );
            })}
          </div>
        ),
      },
      {
        ...generateColumnTexts('GOAL_VALUE'),
        weight: 1,
        render: group => (
          <div className={classes.Column}>
            {group.map(item => (
              <TableCell key={item.variantKey} isWinner={item.isWinner}>
                {item.goalValue && (
                  <>
                    {item.valueFormatter(item.goalValue)}
                    {item.goalValueLower !== null && item.goalValueUpper !== null && (
                      <span
                        className={classNames(FlexibleTableClasses.ShowOnHover, classes.Secondary)}
                      >
                        {t(transKeysPrefix.TABLE.VALUE_RANGE, {
                          lower:
                            item.goalValueLower !== null
                              ? item.valueFormatter(item.goalValueLower)
                              : MISSING_VALUE_PLACEHOLDER,
                          upper:
                            item.goalValueUpper !== null
                              ? item.valueFormatter(item.goalValueUpper)
                              : MISSING_VALUE_PLACEHOLDER,
                        })}
                      </span>
                    )}
                  </>
                )}
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('IMPROVEMENT'),
        weight: 0.6,
        render: group => (
          <div className={classes.Column}>
            {group.map((item: ExperimentSummaryVariantItem) => (
              <TableCell key={item.variantKey} isWinner={item.isWinner}>
                {item.improvement && <span>{number2k(item.improvement * 100)}%</span>}
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('PROBABILITY'),
        weight: 0.6,
        render: group => (
          <div className={classes.Column}>
            {group.map((item: ExperimentSummaryVariantItem) => (
              <TableCell key={item.variantKey} isWinner={item.isWinner}>
                {item.probability && <span>{number2k(item.probability * 100)}%</span>}
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('EXPECTED_LOSS'),
        weight: 0.6,
        hidden: !showExpectedLoss,
        render: group => (
          <div className={classes.Column}>
            {group.map(item => (
              <TableCell key={item.variantKey} isWinner={item.isWinner}>
                {item.valueFormatter(item.expectedLoss)}
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('GROUP_SIZE'),
        weight: 0.5,
        render: group => (
          <div className={classes.Column}>
            {group.map((item: ExperimentSummaryVariantItem) => (
              <TableCell
                key={item.variantKey}
                isWinner={item.isWinner}
                tooltip={item.groupSize.toLocaleString()}
              >
                {number2k(item.groupSize)}
              </TableCell>
            ))}
          </div>
        ),
      },
      {
        ...generateColumnTexts('VALIDATIONS'),
        weight: 1.2,
        hidden: !exists(validations),
        render: group => (
          <div className={classes.Column}>
            {
              <TableCell key={group[0].variantKey} isWinner={group[0].isWinner}>
                {validations?.[group[0].signalId] != null && (
                  <ValidationsTableCell validations={validations?.[group[0].signalId]} />
                )}
              </TableCell>
            }
          </div>
        ),
      },
      {
        ...actionsColumnTexts,
        width: '6.8rem',
        hidden: !showActions,
        render: group => {
          const command = metrics[group[0].signalId].command;
          return (
            <div className={classNames(classes.Column, FlexibleTableClasses.ShowOnHover)}>
              {command && (
                <IconButton
                  size={'large'}
                  icon={ArrowRightLightIcon}
                  tooltipText={actionsColumnTexts.helperText}
                  onClick={withStopPropagation(() => onMetricDeepDive(command))}
                />
              )}
            </div>
          );
        },
      },
    ],
    [
      generateColumnTexts,
      showExpectedLoss,
      validations,
      actionsColumnTexts,
      showActions,
      metrics,
      t,
      transKeysPrefix,
      onSignalClick,
      variants,
      onMetricDeepDive,
    ]
  );

  return (
    <FlexibleTable
      className={classes.Container}
      columns={columns}
      data={tableData}
      sorting={sorting}
      onSort={onSort}
    />
  );
};

import {RCAMode} from 'ui-components';
import {EntityImpactSentenceExplainer} from '../../../../../../../../../../insights/rca/rca-insights.model.ts';
import TransKeys from 'translations';
import classes from '../rca-insights-section.module.scss';
import {ChangeDescriptionAlias} from '../../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import * as React from 'react';
import {RCAInsightSentenceRenderer} from './rca-insights-section-sentence-renderer.component.tsx';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import classNames from 'classnames';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import pluralize from 'pluralize';

interface OwnProps {
  sentences: EntityImpactSentenceExplainer[];
  rcaMode: RCAMode;
  granularity: string;
  changeDescriptionAlias: ChangeDescriptionAlias;
  onViewOnPage?: (titleId: string) => void;
}

type AllProps = OwnProps;

export const EntityLevelExplainerSection: React.FC<AllProps> = (props: AllProps) => {
  const {sentences, rcaMode, granularity, onViewOnPage} = props;
  const entity = sentences[0].sentenceParts.entity;
  const {t} = useTranslation();

  const partTwoText = useMemo(
    () =>
      rcaMode === RCAMode.LOOPS_ALGO
        ? t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_TWO)
        : t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.HIGHLIGHTED_TITLE.PART_TWO_NEW, {
            granularity: granularity.toUpperCase(),
          }),
    [rcaMode, t, granularity]
  );

  return (
    <div key={rcaMode} className={classes.RCAInsightsSectionContainer}>
      <SentenceContainer>
        <SentencePart
          text={t(
            TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.ENTITY_LEVEL_EXPLAINER_TITLE.PART_ONE,
            {entity: pluralize(entity)}
          ).toUpperCase()}
          className={classes.SubTitle}
        />
        <SentencePart text={partTwoText} className={classNames(classes.SubTitle, classes.Dark)} />
      </SentenceContainer>
      {sentences.map((sentence, index) => (
        <RCAInsightSentenceRenderer
          key={sentence.key}
          sentence={sentence}
          onViewOnPage={onViewOnPage}
        />
      ))}
    </div>
  );
};

import {action, OnSuccessActionHook} from 'front-core';
import {ResampleModelDTO} from '../../objects/dto/model-sample-series.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from 'translations';

export enum ModelSamplesActionType {
  RESAMPLE_MODEL = '@@model-samples/RESAMPLE_MODEL',
}

export const resampleModelConfirmed = (data: ResampleModelDTO, onSuccess?: OnSuccessActionHook) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RESMAPLE_MODEL.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RESMAPLE_MODEL.CONTENT),
    },
    () => [resampleModel(data, onSuccess)]
  );

export const resampleModel = (data: ResampleModelDTO, onSuccess?: OnSuccessActionHook) =>
  action(ModelSamplesActionType.RESAMPLE_MODEL, data, {onSuccess});

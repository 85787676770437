import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-segment-group-table.module.scss';
import TransKeys from 'translations';
import {
  ColumnType,
  DocumentCommand,
  DocumentElement,
  DocumentElementType,
  KPISegmentationUpliftMode,
} from '../../../../../../types';
import pluralize from 'pluralize';
import {ShareOfUsers} from '../../../../../shared/display-columns/share-of-users.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {withStopPropagation} from 'front-core';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {Button} from '../../../../../../../../simple/controls/button/button.component';
import {some} from 'lodash';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {Tooltip} from '@material-ui/core';
import {CircleInfoRegularIcon} from '../../../../../../../../simple/controls/icons/icons.component';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';

export interface KPISegmentGroupTableItem {
  key: string;
  name: string;
  count: number;
  goalInSegment: number;
  goalInNonSegment: number;
  goalInInformativeNonSegment?: number;
  uplift: number;
  informativeUplift?: number;
  avgUplift?: number;
  share: number;
  isSignificant: boolean;
  isInformative: boolean;
  command?: DocumentCommand<any>;
  nameHelperText?: string;
  upliftHelperText?: string;
}

interface OwnProps {
  entity: string;
  totalEntities: number;
  transKeysPrefix?: any;
  tableData: KPISegmentGroupTableItem[];
  upliftMode?: KPISegmentationUpliftMode;
  onChangeUpliftMode?: (mode: KPISegmentationUpliftMode) => void;
  selectedKeys?: string[];
  onChangedSelectedKeys?: (keys: string[]) => void;
  onClick?: (key: string) => void;
  higherIsBetter?: boolean;
  isPercentageValue?: boolean;
  isSumMode?: boolean;
  multiSelection?: boolean;
  buttonText?: string;
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_SORT = {
  orderBy: 'share',
  order: 'desc',
};

const UPLIFT_KEY_BY_MODE = {
  [KPISegmentationUpliftMode.VS_OTHERS]: 'uplift',
  [KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS]: 'informativeUplift',
  [KPISegmentationUpliftMode.VS_AVERAGE]: 'avgUplift',
};
const GOAL_IN_NON_SEGMENT_KEY_BY_MODE = {
  [KPISegmentationUpliftMode.VS_OTHERS]: 'goalInNonSegment',
  [KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS]: 'goalInInformativeNonSegment',
  [KPISegmentationUpliftMode.VS_AVERAGE]: 'goalValue',
};

export const KPISegmentGroupTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    tableData: tableDataFromProps,
    onClick,
    upliftMode,
    onChangeUpliftMode,
    selectedKeys,
    onChangedSelectedKeys,
    entity,
    higherIsBetter,
    isPercentageValue,
    isSumMode,
    totalEntities,
    transKeysPrefix,
    buttonText,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const [showInsignificant, setShowInsignificant] = useState(true);
  const comparedSegmentKeysSet = useMemo(() => new Set(selectedKeys), [selectedKeys]);
  const onChangeSegmentCompare = useCallback(
    (key: string) => {
      if (comparedSegmentKeysSet.has(key)) {
        comparedSegmentKeysSet.delete(key);
        onChangedSelectedKeys(Array.from(comparedSegmentKeysSet));
        return;
      }
      onChangedSelectedKeys([...Array.from(comparedSegmentKeysSet), key]);
    },
    [comparedSegmentKeysSet, onChangedSelectedKeys]
  );
  const upliftModeOptions = useMemo(() => {
    const hasVsInformative = some(
      tableDataFromProps.map(s => s.goalInInformativeNonSegment !== undefined)
    );
    const hasNotInformativeSegment = tableDataFromProps.find(s => s.isInformative === false);
    const options: any[] = [
      {
        value: KPISegmentationUpliftMode.VS_OTHERS,
        label: t(transKeysPrefix.UPLIFT_MODES?.[KPISegmentationUpliftMode.VS_OTHERS.toUpperCase()]),
      },
    ];
    if (hasVsInformative) {
      options.push({
        value: KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS,
        disabled: !hasNotInformativeSegment,
        helperText: !hasNotInformativeSegment ? 'All segments are informative' : undefined,
        label: t(
          transKeysPrefix.UPLIFT_MODES?.[
            KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS.toUpperCase()
          ]
        ),
      });
    }
    options.push({
      value: KPISegmentationUpliftMode.VS_AVERAGE,
      label: t(transKeysPrefix.UPLIFT_MODES?.[KPISegmentationUpliftMode.VS_AVERAGE.toUpperCase()]),
    });
    return options;
  }, [t, tableDataFromProps, transKeysPrefix.UPLIFT_MODES]);
  const GOAL_IN_NON_SEGMENT_TRANS_BY_MODE = useMemo(
    () => ({
      [KPISegmentationUpliftMode.VS_OTHERS]:
        transKeysPrefix.TABLE.HEADERS.GOAL_IN_NON_SEGMENT.LABEL,
      [KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS]:
        transKeysPrefix.TABLE.HEADERS.GOAL_IN_INFORMATIVE_NON_SEGMENT?.LABEL ||
        'Informative other segments',
      [KPISegmentationUpliftMode.VS_AVERAGE]:
        transKeysPrefix.TABLE.HEADERS.GOAL_IN_KPI_AVERAGE?.LABEL || 'Average value',
    }),
    [transKeysPrefix]
  );

  const tableColumns = useMemo(() => {
    const columns = [];
    columns.push(
      ...[
        {
          key: 'name',
          type: ColumnType._RENDER_COLUMN,
          asType: ColumnType.DEFAULT,
          title: t(transKeysPrefix.TABLE.HEADERS.SEGMENT.LABEL),
          contentClassName: classes.CheckboxCell,
          options: {
            sortable: true,
            filterable: true,
            bold: true,
          },
          render: item => (
            <div className={classes.CheckboxWrapper}>
              <Checkbox
                multi
                checked={comparedSegmentKeysSet.has(item.key)}
                onChange={() => onChangeSegmentCompare(item.key)}
              />
              <TooltipIfOverflow title={item.name}>
                <div className={classes.SegmentNameWrapper}>
                  <div
                    className={classes.SegmentName}
                    onClick={withStopPropagation(() => emitEvent(item.command))}
                  >
                    {item.name}
                  </div>
                </div>
              </TooltipIfOverflow>
              {item.nameHelperText && (
                <Tooltip title={item.nameHelperText}>
                  <CircleInfoRegularIcon className={classes.InfoIcon} />
                </Tooltip>
              )}
            </div>
          ),
        },
        {
          key: 'share',
          type: ColumnType._RENDER_COLUMN,
          asType: ColumnType.NUMERIC,
          title: t(transKeysPrefix.TABLE.HEADERS.SHARE_OF_X.LABEL, {
            entity: pluralize(entity),
          }),
          options: {
            sortable: true,
            filterable: true,
            helperText: t(transKeysPrefix.TABLE.HEADERS.SHARE_OF_X.HELPER_TEXT, {
              entity: pluralize(entity),
            }),
            unit: '%',
          },
          render: item => (
            <ShareOfUsers
              className={classes.ShareOfUsers}
              count={item.count}
              entity={entity}
              total={totalEntities}
            />
          ),
        },
        {
          key: 'goalInSegment',
          title: t(transKeysPrefix.TABLE.HEADERS.GOAL_IN_SEGMENT.LABEL),
          type: ColumnType.VERSUS,
          hidden: isSumMode,
          options: {
            sortable: true,
            filterable: true,
            unit: isPercentageValue ? '%' : undefined,
            helperText: t(transKeysPrefix.TABLE.HEADERS.GOAL_IN_SEGMENT.HELPER_TEXT),
          },
          typeOptions: {
            valueDataKey: 'goalInSegment',
            valueDataTitle: t(transKeysPrefix.TABLE.HEADERS.GOAL_IN_SEGMENT.LABEL),
            versusDataKey: GOAL_IN_NON_SEGMENT_KEY_BY_MODE[upliftMode],
            versusDataTitle: t(GOAL_IN_NON_SEGMENT_TRANS_BY_MODE[upliftMode]),
            hideIcon: true,
          },
        },
        {
          key: 'goalInSegment',
          title: t(transKeysPrefix.TABLE.HEADERS.GOAL_IN_SEGMENT.LABEL),
          type: ColumnType.NUMERIC,
          hidden: !isSumMode,
          options: {
            sortable: true,
            filterable: true,
            unit: isPercentageValue ? '%' : undefined,
            helperText: t(transKeysPrefix.TABLE.HEADERS.GOAL_IN_SEGMENT.HELPER_TEXT),
          },
        },
        {
          key: UPLIFT_KEY_BY_MODE[upliftMode],
          title: t(transKeysPrefix.TABLE.HEADERS.UPLIFT.LABEL),
          type: ColumnType.CHANGE,
          hidden: isSumMode,
          options: {
            sortable: true,
            filterable: true,
            helperText: t(transKeysPrefix.TABLE.HEADERS.UPLIFT.HELPER_TEXT),
            helperTextDataKey: 'upliftHelperText',
          },
          typeOptions: {
            higherIsBetter: higherIsBetter,
          },
        },
      ]
    );
    if (onClick) {
      columns.push({
        key: 'actions',
        type: ColumnType._RENDER_COLUMN,
        title: '',
        options: {
          showOnHover: true,
          width: '4rem',
        },
        render: item => (
          <div className={classes.RowButton}>
            <Button caps={false} onClick={withStopPropagation(() => onClick(item.key))}>
              {buttonText}
            </Button>
          </div>
        ),
      });
    }
    return columns;
  }, [
    t,
    transKeysPrefix,
    entity,
    isSumMode,
    isPercentageValue,
    upliftMode,
    GOAL_IN_NON_SEGMENT_TRANS_BY_MODE,
    higherIsBetter,
    onClick,
    comparedSegmentKeysSet,
    onChangeSegmentCompare,
    emitEvent,
    totalEntities,
    buttonText,
  ]);
  const inSignificantSegmentKeys = useMemo(
    () => tableDataFromProps.filter(s => s.isSignificant === false).map(s => s.key),
    [tableDataFromProps]
  );
  const tableData = useMemo(
    () =>
      tableDataFromProps.filter(s => {
        if (showInsignificant) {
          return true;
        }
        return s.isSignificant === true;
      }),
    [tableDataFromProps, showInsignificant]
  );
  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: `kpi_segment_group_table_${upliftMode}`,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: {
          defaultSort: DEFAULT_SORT,
          pagination: false,
          stickyFooter: true,
          significantDataKey: 'isInformative',
          data: inSignificantSegmentKeys.reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: {
                columns: {
                  [UPLIFT_KEY_BY_MODE[upliftMode]]: {
                    color: '#757C94',
                  },
                },
              },
            };
          }, {}),
        },
      }) as any,
    [tableColumns, tableData, inSignificantSegmentKeys, upliftMode]
  );

  return (
    <>
      <div className={classes.Filters}>
        {inSignificantSegmentKeys.length > 0 && (
          <Checkbox
            label={t(TransKeys.DOCUMENT_VIEWER.GENERAL.SHOW_INSIGNIFICANT_SEGMENTS)}
            checked={showInsignificant}
            onChange={setShowInsignificant}
            border
          />
        )}
        {upliftMode && onChangeUpliftMode && (
          <Select
            dropdownButtonClassName={classes.UpliftModeSelection}
            prefix={t(transKeysPrefix.UPLIFT_MODE_SELECTION.LABEL)}
            selectionHelper={t(transKeysPrefix.UPLIFT_MODE_SELECTION.DESCRIPTION)}
            value={upliftMode}
            onChange={onChangeUpliftMode}
            options={{options: upliftModeOptions}}
            capitalize={false}
            sortValues={false}
            searchable={false}
            clearable={false}
          />
        )}
      </div>
      <div className={classNames(classes.KPISegmentGroupTable, className)}>
        <ChildRenderer children_={tableFigure} />
      </div>
    </>
  );
};

KPISegmentGroupTable.defaultProps = {
  higherIsBetter: true,
  isPercentageValue: true,
  multiSelection: false,
  upliftMode: KPISegmentationUpliftMode.VS_OTHERS,
  transKeysPrefix: TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE,
  isSumMode: false,
};

import classNames from 'classnames';
import {TreeMode} from '../../kpi-tree.consts';
import {
  Button,
  DatePickerInput,
  EditIcon,
  PlusLightIcon,
  SplitIconLight,
  PopoverWrapper,
  ChevronLeftRegularIcon,
  ArrowRightLightIcon,
  RCAImpactMode,
} from 'ui-components';
import {MetricNodeSelector} from '../metric-node-selector/metric-node-selector.component.tsx';
import classes from './kpi-tree-header.module.scss';
import {useCallback, useRef} from 'react';
import {Metric} from '../../../../../../objects/models/metric.model';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {createStartDateComparingProps, dateToLabel} from '../../../../../../utils/dates.utils';
import {TeamTreeSelector} from '../tree-team-selector/tree-team-selector.component';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {NetworkWiredRegularIcon} from 'ui-components/src';
import {useNavigate} from 'react-router';
import {AppRoutes, TEAM_ID_PATH_PARAM} from '../../../../../../constants/app-routes.ts';
import {CTCModeSelector} from '../ctc-mode-selector/ctc-mode-selector.component.tsx';

interface OwnProps {
  mode: TreeMode;
  setMode: (mode: TreeMode) => void;
  onAutoLayout: () => void;
  onAddKPI: (metric: Metric) => void;
  onBack: () => void;
  impactMode: RCAImpactMode;
  onChangeImpactMode: (impactMode: RCAImpactMode) => void;
  addDisabled?: boolean;
  date: string;
  setDate: (date: string) => void;
  teamId: number | null;
  onChangeTeam: (teamId: number | null) => void;
  emptyState?: boolean;
  showBack?: boolean;
  isRCAMode?: boolean;
  hasRCAData?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const MAX_DATE = new Date();

const datePickerProps = createStartDateComparingProps('week');

export const KPITreeHeader = (props: AllProps) => {
  const {
    mode,
    setMode,
    onAutoLayout,
    onAddKPI: onAddKPIFromProps,
    date,
    setDate,
    onBack,
    addDisabled,
    emptyState,
    teamId,
    onChangeTeam,
    showBack = true,
    isRCAMode = false,
    hasRCAData,
    className,
  } = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const selectorRef = useRef<any>(null);
  const dateValue = useCallback((value: string) => dateToLabel(value, 'week'), []);
  const onAddKPI = useCallback(
    (metric: Metric) => {
      selectorRef.current?.close();
      onAddKPIFromProps(metric);
    },
    [onAddKPIFromProps]
  );
  const onSeeFullTree = useCallback(() => {
    const params: any = {};
    if (teamId) {
      params[TEAM_ID_PATH_PARAM] = teamId;
    }
    navigate(AppRoutes.kpiTree(params));
  }, [navigate, teamId]);

  return (
    <div className={classNames(classes.KPITreeHeader, className)}>
      <div className={classes.Main}>
        {showBack && (
          <>
            <div className={classes.BackButton} onClick={onBack}>
              <ChevronLeftRegularIcon />
            </div>
            <div className={classes.Separator} />
          </>
        )}
        <div className={classes.Title}>
          <NetworkWiredRegularIcon />
          <span>KPI Tree</span>
        </div>
      </div>
      {!isRCAMode && (
        <div className={classes.Right}>
          {mode === TreeMode.EDIT && (
            <>
              <Button
                onClick={() => onAutoLayout()}
                variant={'outlined'}
                icon={SplitIconLight}
                className={classes.Button}
              >
                {t(TransKeys.KPI_TREE.ACTIONS.AUTO_LAYOUT)}
              </Button>
              <PopoverWrapper
                ref={selectorRef}
                buttonRenderer={({onClick}) => (
                  <Button
                    onClick={onClick}
                    variant={'outlined'}
                    disabled={addDisabled}
                    icon={PlusLightIcon}
                    className={classes.Button}
                  >
                    {t(TransKeys.KPI_TREE.ACTIONS.ADD_KPI)}
                  </Button>
                )}
              >
                <MetricNodeSelector onChange={(metricId, metric) => onAddKPI(metric)} />
              </PopoverWrapper>
              <div className={classes.Separator} />
            </>
          )}
          {mode === TreeMode.VIEW && (
            <>
              <TeamTreeSelector teamId={teamId} onChangeTeam={onChangeTeam} />
              <DatePickerInput
                {...datePickerProps}
                className={classes.DatePickerInput}
                onChange={setDate}
                value={date}
                maxDate={MAX_DATE}
                renderValue={dateValue}
                disabled={emptyState}
                dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
                utc
              />
              <div className={classes.Separator} />
            </>
          )}
          {mode === TreeMode.VIEW && (
            <Button
              disabled={emptyState}
              onClick={() => setMode(TreeMode.EDIT)}
              icon={EditIcon}
              className={classes.Button}
            >
              {t(TransKeys.KPI_TREE.ACTIONS.EDIT)}
            </Button>
          )}
          {mode === TreeMode.EDIT && (
            <Button onClick={() => setMode(TreeMode.VIEW)} className={classes.Button}>
              {t(TransKeys.GENERAL.ACTIONS.DONE)}
            </Button>
          )}
        </div>
      )}
      {isRCAMode && (
        <div className={classes.Right}>
          {hasRCAData && (
            <CTCModeSelector
              impactMode={props.impactMode}
              onChangeImpactMode={props.onChangeImpactMode}
            />
          )}
          <span className={classes.SeeFullTree} onClick={onSeeFullTree}>
            See full tree <ArrowRightLightIcon />
          </span>
        </div>
      )}
    </div>
  );
};

import {RCAImpactMode, Select} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

interface Props {
  impactMode: RCAImpactMode;
  onChangeImpactMode: (impactMode: RCAImpactMode) => void;
  className?: string;
}

const HELPER_TEXTS = {
  [RCAImpactMode.SHARE]:
    TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.TABLE.HEADERS.CTC_PP.HELPER_TEXT,
  [RCAImpactMode.VALUE]:
    TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.TABLE.HEADERS.CTC_VALUE.HELPER_TEXT,
};

export const CTCModeSelector = (props: Props) => {
  const {impactMode, onChangeImpactMode, className} = props;
  const {t} = useTranslation();

  const ctcOptions = useMemo(
    () => [
      {value: RCAImpactMode.SHARE, label: 'PP', helperText: t(HELPER_TEXTS[RCAImpactMode.SHARE])},
      {
        value: RCAImpactMode.VALUE,
        label: 'KPI Units',
        helperText: t(HELPER_TEXTS[RCAImpactMode.VALUE]),
      },
    ],
    [t]
  );

  return (
    <Select
      prefix={'Impact in'}
      options={{options: ctcOptions}}
      value={impactMode}
      onChange={onChangeImpactMode}
      searchable={false}
      clearable={false}
      sortValues={false}
      className={className}
    />
  );
};

import {composition, exists} from 'front-core';

import {
  ModalLayout,
  FancyHeader,
  LabelWrapper,
  DatePickerInput,
  Select,
  DateIcon,
  Button,
} from 'ui-components';
import classes from './homepage-test-subscription-panel.module.scss';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc.tsx';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc.tsx';
import {getHomepageSubscriptionNetworkRequest} from '../../../../http/homepage.network-requests.ts';
import {
  HOMEPAGE_ID_PATH_PARAM,
  SUBSCRIPTION_ID_PATH_PARAM,
} from '../../../../constants/app-routes.ts';
import {
  HomepageSubscription,
  HomepageSubscriptionUnit,
} from '../../../../objects/models/homepage.model.ts';
import {useCallback, useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider, Controller} from 'react-hook-form';
import {homepageTestSubscriptionDTOValidator} from '../../../../objects/dto/homepage-subscription.dto.ts';
import {useProductData} from '../../../../core/hooks/use-product-data.hook.ts';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc.tsx';
import {TIME_FORMATS} from '../../../../constants/time-formats.ts';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {testHomepageSubscription} from '../../../../store/homepage/homepage.actions.ts';
import {HomepageSubscriptionTestDTO} from '../../../../objects/dto/homepage.dto.ts';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors.ts';
import {ActionKey} from '../../../../constants/action-key.ts';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';

const HOMEPAGE_TEST_SUBSCRIPTION_SELECTED_KEY = 'HOMEPAGE_TEST_SUBSCRIPTION/HOMEPAGE_SUBSCRIPTION';

const MESSAGE_TYPE_OPTIONS = {
  options: [
    {
      label: 'Daily',
      value: HomepageSubscriptionUnit.DAILY,
    },
    {
      label: 'Weekly',
      value: HomepageSubscriptionUnit.WEEKLY,
    },
    {
      label: 'Monthly',
      value: HomepageSubscriptionUnit.MONTHLY,
    },
  ],
};

interface OwnProps {
  onClose?: () => void;
  subscriptionId: number;
  homepageId: number;
  subscription: HomepageSubscription;
}

type AllProps = OwnProps;

const HomepageTestSubscriptionPanelComponent = (props: AllProps) => {
  const {subscription, onClose} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {lastSampledDate: maxSampleDate} = useProductData();
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.TEST_HOMEPAGE_SUBSCRIPTION)(state)
  );
  const maxDate = useMemo(
    () => (maxSampleDate ? moment.utc(maxSampleDate).toDate() : null),
    [maxSampleDate]
  );
  const formInitialValues = useMemo(
    () => ({
      homepageSubscriptionId: subscription.id,
      sampleDate: maxDate?.toISOString(),
    }),
    [subscription, maxDate]
  );
  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: formInitialValues as any,
    resolver: yupResolver(homepageTestSubscriptionDTOValidator),
  });
  const {handleSubmit, control} = formMethods;
  const onSubmit = useCallback(
    (data: HomepageSubscriptionTestDTO) =>
      dispatch(
        testHomepageSubscription(data, () => {
          onClose();
          return [];
        })
      ),
    [dispatch, onClose]
  );

  return (
    <div className={classes.HomepageTestSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        <FancyHeader title={`Test Subscription ${subscription?.id}`} />
        <FormProvider {...formMethods}>
          <div className={classes.Content}>
            {isLoading && <GenericLoading />}
            <div>Subscription owner: {subscription.user.fullName}</div>
            <LabelWrapper label="Select a sample date">
              <Controller
                name="sampleDate"
                control={control}
                render={({field}) => (
                  <DatePickerInput
                    value={field.value}
                    maxDate={maxDate}
                    onChange={date => {
                      // Set time to midnight (00:00:00)
                      const dateAtMidnight = moment.utc(date).startOf('day').toISOString();
                      field.onChange(dateAtMidnight);
                    }}
                    dateInputFormat={TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT}
                    locale={'en-GB'}
                    icon={DateIcon}
                    fullWidth={false}
                    clearable={false}
                    utc={true}
                    disabled={isLoading}
                  />
                )}
              />
            </LabelWrapper>
            <LabelWrapper label="Select message type">
              <Controller
                name="messageType"
                control={control}
                render={({field}) => (
                  <Select
                    options={MESSAGE_TYPE_OPTIONS}
                    value={field.value}
                    onChange={field.onChange}
                    searchable={false}
                    sortValues={false}
                    clearable={false}
                    disabled={isLoading}
                  />
                )}
              />
            </LabelWrapper>
          </div>
        </FormProvider>
      </ModalLayout>
    </div>
  );
};

export const HomepageTestSubscriptionPanel = composition<AllProps>(
  HomepageTestSubscriptionPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    subscription: {
      shouldCall: props =>
        [props[SUBSCRIPTION_ID_PATH_PARAM], props[HOMEPAGE_ID_PATH_PARAM]].every(exists),
      selectedKey: HOMEPAGE_TEST_SUBSCRIPTION_SELECTED_KEY,
      actionKey: HOMEPAGE_TEST_SUBSCRIPTION_SELECTED_KEY,
      request: getHomepageSubscriptionNetworkRequest,
      mapPayloadFromProps: props => ({
        homepageId: props[HOMEPAGE_ID_PATH_PARAM],
        subscriptionId: props[SUBSCRIPTION_ID_PATH_PARAM],
      }),
    },
  })
);
